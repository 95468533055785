import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetPushNotificationKey = () => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const pushNotificationKeyGql = graphql(`
    query GetPushNotificationKey {
      getPushNotificationKey {
        publicKey
      }
    }
  `);

  const getPushNotificationKey = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['pushNotificationKeyQuery'],
    queryFn: async () => {
      return graphQLClient.request(pushNotificationKeyGql);
    },
  });

  return getPushNotificationKey;
};

export default useGetPushNotificationKey;
