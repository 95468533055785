import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDashes = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={5}
    fill="none"
    viewBox="0 0 8 5"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M1.25 1.5a.503.503 0 0 1-.5-.5.5.5 0 0 1 .5-.484h5.5a.5.5 0 0 1 .5.484c0 .281-.234.5-.5.5h-5.5Zm5.5 2c.266 0 .5.219.5.5 0 .266-.234.5-.5.5h-5.5c-.281 0-.5-.25-.5-.5 0-.266.219-.5.5-.5h5.5Z"
    />
  </svg>
));
export default SvgIconDashes;
