import { Box, BoxProps, useDisclosure } from '@chakra-ui/react';
import { AidrianaDrawer } from 'components';
import { useTour } from 'context';

interface TourWrapperProps extends BoxProps {
  identifier: string;
  step: number;
}

const TourWrapper = ({
  children,
  identifier,
  step,
  ...props
}: TourWrapperProps) => {
  const { currentTour, currentStep, aidrianaTourStep, nextStep } = useTour();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const isActive = currentTour === identifier && currentStep === step;

  return (
    <Box position="relative" w="100%" h="100%">
      {isActive && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          bg="black"
          opacity={0.8}
          zIndex="10000"
          pointerEvents="none"
        >
          {isActive && aidrianaTourStep && (
            <AidrianaDrawer
              title={aidrianaTourStep.title}
              description={aidrianaTourStep.description}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          )}
        </Box>
      )}
      <Box
        position={isActive ? 'relative' : 'initial'}
        zIndex={isActive ? '10001' : 'initial'}
        w="100%"
        h="100%"
        onClick={() => nextStep(identifier)}
        {...props}
      >
        {children}
      </Box>
    </Box>
  );
};

export default TourWrapper;
