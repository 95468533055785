import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDetails = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M13.438 8.75a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H7.814c-.547 0-.938-.39-.938-.938 0-.507.39-.937.938-.937h5.625Zm-3.75 3.75a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H7.813c-.547 0-.938-.39-.938-.938 0-.507.39-.937.938-.937h1.875Zm3.75-7.5a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H7.814c-.547 0-.938-.39-.938-.938 0-.507.39-.937.938-.937h5.625Zm2.187-5c1.367 0 2.5 1.133 2.5 2.5v15c0 1.406-1.133 2.5-2.5 2.5h-10a2.468 2.468 0 0 1-2.5-2.5v-15c0-1.367 1.094-2.5 2.5-2.5h10Zm.625 17.5v-15c0-.313-.313-.625-.625-.625h-10A.642.642 0 0 0 5 2.5v15c0 .352.273.625.625.625h10a.642.642 0 0 0 .625-.625Z"
    />
  </svg>
));
export default SvgIconDetails;
