import {
  IconAustinMinimap,
  IconBarcelonaMinimap,
  IconBudapestMinimap,
  IconImolaMinimap,
  IconLusailMinimap,
  IconMelbourneMinimap,
  IconMontrealMinimap,
  IconSakhirMinimap,
  IconSilverstoneMinimap,
  IconSpaMinimap,
  IconSpielbergMinimap,
  IconSuzukaMinimap,
  IconZandvoortMinimap,
} from 'icons';
import { SVGProps } from 'react';

type RaceMinimapType = {
  [key: string]: React.ElementType;
};

export const RaceMinimapList: RaceMinimapType = {
  Belgium: IconSpaMinimap,
  Spain: IconBarcelonaMinimap,
  'United Kingdom': IconSilverstoneMinimap,
  Netherlands: IconZandvoortMinimap,
  USA: IconAustinMinimap,
  Italy: IconImolaMinimap,
  Bahrain: IconSakhirMinimap,
  Qatar: IconLusailMinimap,
  Hungary: IconBudapestMinimap,
  Austria: IconSpielbergMinimap,
  Canada: IconMontrealMinimap,
  Japan: IconSuzukaMinimap,
  Australia: IconMelbourneMinimap,
};

type MinimapProps = SVGProps<SVGSVGElement> & {
  race: string;
};

export const RaceMinimap = ({ race, ...props }: MinimapProps) => {
  const RaceMinimap = RaceMinimapList[race];

  return <RaceMinimap {...props} />;
};

export default RaceMinimap;
