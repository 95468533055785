import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import {
  IconRainConditions,
  IconTemperatureConditions,
  IconWrenchConditions,
} from 'icons';
import {
  AirTemperatureCategory,
  TrackTemperatureCategory,
} from 'api/generated/graphql';
import {
  AirTemperatureColors,
  TrackTemperatureColors,
  WeatherColors,
} from '../../RaceInfo/RaceInfoTypes';
import { useState } from 'react';

// Função auxiliar para determinar o número de boxes preenchidos
const getFilledBoxes = (
  category: string,
  type: 'air' | 'track' | 'weather'
): number => {
  const categories = {
    air: Object.keys(AirTemperatureColors),
    track: Object.keys(TrackTemperatureColors),
    weather: Object.keys(WeatherColors),
  };
  const index = categories[type].indexOf(category);
  return Math.max(1, Math.min(6, index + 1));
};

// Componente para renderizar os boxes
const ConditionBoxes = ({
  category,
  type,
}: {
  category: string;
  type: 'air' | 'track' | 'weather';
}) => {
  const filledBoxes = getFilledBoxes(category, type);
  const color = 'yellow';

  return (
    <Flex alignItems="center" gap={1}>
      {[...Array(6)].map((_, index) => (
        <Box
          key={index}
          w={2}
          h={2}
          bg={index < filledBoxes ? color : 'transparent'}
          borderRadius="2px"
          borderColor={index < filledBoxes ? 'transparent' : 'white.20'}
          borderWidth={index < filledBoxes ? 0 : 1}
        />
      ))}
    </Flex>
  );
};

const RaceConditions = ({
  airTemperature,
  trackTemperature,
  weather,
  showHover = false,
}: {
  airTemperature: AirTemperatureCategory;
  trackTemperature: TrackTemperatureCategory;
  weather: string;
  showHover?: boolean;
}) => {
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const [conditionHovered, setConditionHovered] = useState<
    'skill' | 'air' | 'track' | 'weather' | null
  >(null);

  const hoverText = {
    skill: 'The number of completed or remaining laps in the race.',
    air: 'The current air temperature, affecting engine performance and tire grip.',
    track:
      'The surface temperature of the track, influencing tire wear and traction.',
    weather:
      'The amount of rain on the track, impacting visibility and driving conditions.',
  };

  return (
    <Flex
      flexDir="column"
      gap="0.5rem"
      alignItems="center"
      justifyContent="center"
      w="full"
    >
      <Flex gap={4} w="full">
        <Flex flexDirection="column" gap={1} w="full">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            background={`linear-gradient(90deg, rgba(255, 255, 255, ${
              conditionHovered === 'skill' ? '0.3' : '0.1'
            }) 0%, rgba(255, 255, 255, 0) 100%)`}
            px="8px"
            py="4px"
            borderTopLeftRadius="8px"
            minW="150px"
            onMouseEnter={() => setConditionHovered('skill')}
            onMouseLeave={() => setConditionHovered(null)}
          >
            <Flex alignItems="center" gap={2}>
              <IconWrenchConditions />
              <Text fontSize="14px" color="white.80">
                Skill
              </Text>
            </Flex>
            <ConditionBoxes category={trackTemperature} type="track" />
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            background={`linear-gradient(90deg, rgba(255, 255, 255, ${
              conditionHovered === 'track' ? '0.3' : '0.1'
            }) 0%, rgba(255, 255, 255, 0) 100%)`}
            px="8px"
            py="4px"
            borderBottomLeftRadius="8px"
            minW="150px"
            onMouseEnter={() => setConditionHovered('track')}
            onMouseLeave={() => setConditionHovered(null)}
          >
            <Flex alignItems="center" gap={2}>
              <IconTemperatureConditions />
              <Text fontSize="14px" color="white.80">
                Track
              </Text>
            </Flex>
            <ConditionBoxes category={trackTemperature} type="track" />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap={1} w="full">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            background={`linear-gradient(90deg, rgba(255, 255, 255, ${
              conditionHovered === 'air' ? '0.3' : '0.1'
            }) 0%, rgba(255, 255, 255, 0) 100%)`}
            px="8px"
            py="4px"
            borderTopRightRadius="8px"
            minW="150px"
            onMouseEnter={() => setConditionHovered('air')}
            onMouseLeave={() => setConditionHovered(null)}
          >
            <Flex alignItems="center" gap={2}>
              <IconTemperatureConditions />
              <Text fontSize="14px" color="white.80">
                Air
              </Text>
            </Flex>
            <ConditionBoxes category={airTemperature} type="air" />
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            background={`linear-gradient(90deg, rgba(255, 255, 255, ${
              conditionHovered === 'weather' ? '0.3' : '0.1'
            }) 0%, rgba(255, 255, 255, 0) 100%)`}
            px="8px"
            py="4px"
            borderBottomRightRadius="8px"
            minW="150px"
            onMouseEnter={() => setConditionHovered('weather')}
            onMouseLeave={() => setConditionHovered(null)}
          >
            <Flex alignItems="center" gap={2}>
              <IconRainConditions />
              <Text fontSize="14px" color="white.80">
                Rain
              </Text>
            </Flex>
            <ConditionBoxes category={weather} type="weather" />
          </Flex>
        </Flex>
      </Flex>

      {showHover && (
        <Text
          fontSize="0.75rem"
          lineHeight="0.875rem"
          color="white.60"
          fontWeight={500}
        >
          {conditionHovered
            ? hoverText[conditionHovered]
            : `${isMobile ? 'Tap' : 'Hover'} insights for more info`}
        </Text>
      )}
    </Flex>
  );
};

export default RaceConditions;
