import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHeartFilled = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#E2645A"
      d="m15.75 8.469-6.031 6.25c-.406.406-1.063.406-1.438 0L2.22 8.469c-1.75-1.813-1.656-4.813.312-6.5C4.22.5 6.812.78 8.375 2.375L9 3.031l.594-.656C11.156.781 13.719.5 15.469 1.969c1.937 1.687 2.031 4.687.281 6.5Z"
    />
  </svg>
));
export default SvgIconHeartFilled;
