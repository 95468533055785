import { Flex, Text } from '@chakra-ui/react';
import { IconClock, IconCountdownTimer } from 'icons';
import { useEffect, useState } from 'react';

interface CountdownTimerProps {
  timeLeft: string;
}

export const CountdownTimer = ({ timeLeft }: CountdownTimerProps) => {
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const target = new Date(timeLeft);
      if (now > target) {
        target.setDate(target.getDate() + 1);
      }
      const difference = target.getTime() - now.getTime();
      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        if (hours > 24) {
          const days = Math.floor(hours / 24);
          const remainingHours = hours % 24;
          setRemainingTime(`${days}d ${remainingHours}h ${minutes}m`);
        } else {
          setRemainingTime(`${hours}h ${minutes}m`);
        }
      } else {
        setRemainingTime('0h 0m');
      }
    };
    updateCountdown();
    const interval = setInterval(updateCountdown, 60000); // Atualiza a cada minuto
    return () => clearInterval(interval);
  }, [timeLeft]);

  return (
    <Flex
      bg="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
      borderRadius="4px"
      p="1px 4px"
      align="center"
    >
      <IconCountdownTimer w="16px" h="16px" mr="1" />
      <Text as="i" fontWeight="bold" fontSize={{ base: '12px', md: '14px' }}>
        {remainingTime}
      </Text>
    </Flex>
  );
};
