import { Button, Flex, Text, ToastId, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Cars, SavedSetups } from "api/generated/graphql";
import { RInput, RLoadingAnimation, RModal, RToast } from "components";
import { useRemoveSavedSetups, useSaveSavedSetups } from "hooks";
import { useRef, useState } from "react";

type SavedSetupNameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  savedSetupId: string;
  carId: string;
  savedSetupName: string;
  setSelectedSavedSetup: (savedSetup: SavedSetups | null) => void;
};

const SavedSetupDeleteModal = ({ isOpen, onClose, savedSetupId, carId, savedSetupName, setSelectedSavedSetup }: SavedSetupNameModalProps) => {
  const toast = useToast();
  const toastIdRef = useRef<ToastId[]>([]);
  const queryClient = useQueryClient();
  const { mutateAsync: removeSavedSetups, isLoading: isLoadingRemove } = useRemoveSavedSetups({
    onSuccess: () => {
      const id = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="success"
            title={`Saved Setup deleted`}
            onClose={() => {
              if (toastIdRef.current.includes(id)) {
                toast.close(id);
                toastIdRef.current = toastIdRef.current.filter(
                  (toastId) => toastId !== id
                );
              }
            }}
          />
        ),
      });
      toastIdRef.current.push(id);
      onClose();
      setSelectedSavedSetup(null);
    },
    onError: (error: unknown) => {
      const id = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={`This setup could not be deleted`}
            description={`${
              error
            }`}
            onClose={() => {
              if (toastIdRef.current.includes(id)) {
                toast.close(id);
                toastIdRef.current = toastIdRef.current.filter(
                  (toastId) => toastId !== id
                );
              }
            }}
          />
        ),
      });
      toastIdRef.current.push(id);
    },
  });

  const handleRemoveSavedSetup = async (savedSetupId: string) => {
    await removeSavedSetups({ SavedSetupsInput: { id: savedSetupId, carId } });
    queryClient.invalidateQueries({
      queryKey: ['getSavedSetups'],
    });
  }

  return (
    <RModal
      title={`Delete ${savedSetupName}`}
      isOpen={isOpen}
      onClose={onClose}
      variant="darkGrey"
      size="md"
      isCentered
    >
      {isLoadingRemove ? (
        <Flex w="full" alignItems="center" justifyContent="center">
          <RLoadingAnimation />
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          p="4"
        >
          <Text
            color="paleSlate.100"
          >
            Are you sure you want to delete this car setup? This action cannot be undone.
          </Text>
          <Flex
            flexDir="row"
            gap="2"
          >
            <Button
              w="full"
              mt="2rem"
              onClick={() => handleRemoveSavedSetup(savedSetupId)}
            >
              Delete
            </Button>
            <Button
              w="full"
              mt="2rem"
              onClick={onClose}
              variant="secondary"
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      )}
    </RModal>
  )
};

export default SavedSetupDeleteModal;