import {
  Text,
  Flex,
  Box,
  FlexProps,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import RepairPartsDrawer from 'components/RepairPartsDrawer/RepairPartsDrawer';
import { RAlert } from 'components';

import {
  IconHeart,
  IconWrenchScrewdriver,
  IconWarningOutline,
  IconWarning,
  IconSmile,
  IconHeartFilled,
} from 'icons';

import { CarPart, RepairStages } from 'types';
import { useRef, useState, useEffect } from 'react';

const Container = ({
  children,
  ...rest
}: FlexProps & { children: React.ReactNode }) => {
  return (
    <Flex
      bg="black.60"
      width="fit-content"
      alignItems="center"
      gap="2"
      {...rest}
    >
      {children}
    </Flex>
  );
};

const RepairStage = ({
  timesUsed,
  maximumTimesUsed = 1000,
  currentStage,
}: {
  timesUsed: number;
  maximumTimesUsed: number;
  currentStage: 1 | 2 | 3 | 4;
}) => {
  const percentageUsed = (timesUsed / maximumTimesUsed) * 100;
  const isWarningShown = percentageUsed >= currentStage * 25;
  const stageLowerBoundary = (currentStage - 1) * 25;
  let barColorPercentage = 0;

  if (percentageUsed > stageLowerBoundary) {
    barColorPercentage = ((percentageUsed - stageLowerBoundary) / 25) * 100;
    barColorPercentage = Math.min(100, barColorPercentage);
  }

  return (
    <Flex flexGrow={1} alignItems="center">
      <Box
        borderBottom="solid 2px"
        borderColor="fieryCoral.100"
        w={`${barColorPercentage}%`}
        h="0.5rem"
      />

      <Box
        borderBottom="solid 2px"
        borderColor="white.20"
        w={`${100 - barColorPercentage}%`}
        h="0.5rem"
      />

      {isWarningShown ? (
        <IconWarning
          transform="scale(130%)"
          w="2rem"
          __css={{ path: { fill: 'fieryCoral.100' } }}
        />
      ) : (
        <IconWarningOutline transform="scale(140%)" w="2rem" opacity="0.2" />
      )}
    </Flex>
  );
};

const CarHealth = ({
  hideRepair,
  carId,
  showRepairButton,
  carParts,
  repairStage,
  timesUsed,
  isTrialCar,
  maximumTimesUsed = 1000,
  ...rest
}: FlexProps & {
  hideRepair?: boolean;
  isTrialCar?: boolean;
  carId?: string;
  showRepairButton?: boolean;
  carParts?: CarPart[];
  repairStage: RepairStages;
  timesUsed: number;
  maximumTimesUsed: number | null;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const sizeObserver = (entries: any) => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
    };

    const resizeObserver = new ResizeObserver(sizeObserver);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      {repairStage && (
        <RAlert
          position="absolute"
          bottom={{ base: '2.25rem', md: '4rem' }}
          right="0"
          maxW="23rem"
          minH="2.5rem"
          variant="error"
          description="Your car is damaged and needs to be repaired before it can be used again."
        />
      )}
      <Flex
        ref={ref}
        flexDir="row"
        width="full"
        gap="2"
        justifyContent="flex-end"
        {...rest}
      >
        {!hideRepair && (
          <Container flexGrow="1" justifyContent="space-between" padding={2}>
            <Flex
              flexDir="row"
              alignItems="center"
              width="full"
              gap="2"
              {...rest}
            >
              {/* <RepairPartsDrawer
                carId={carId}
                carParts={carParts}
                isOpen={isOpen}
                onClose={onClose}
              /> */}
              {size.width < 600 ? (
                <IconWrenchScrewdriver
                  opacity="0.4"
                  transform="scale(125%)"
                  ml="1"
                  mr="2"
                />
              ) : (
                <Button
                  margin="-2"
                  variant="tertiary"
                  onClick={onOpen}
                  isDisabled={repairStage && showRepairButton ? false : true}
                  id="repair-button"
                >
                  {isTrialCar ? 'Upgrade' : 'Repair'}{' '}
                  <IconWrenchScrewdriver
                    transform="scale(125%)"
                    ml="3"
                    mr="2"
                  />
                </Button>
              )}
              {/*
              {repairStage && showRepairButton ? (
                <Button margin="-2" variant="tertiary" onClick={onOpen}>
                  Repair <IconWrenchScrewdriver ml="2" />
                </Button>
              ) : (
                <IconWrenchScrewdriver
                  opacity="0.4"
                  transform="scale(125%)"
                  ml="1"
                  mr="2"
                />
              )} */}
              {/* <Button
                margin="-2"
                variant="tertiary"
                onClick={onOpen}
                isDisabled={repairStage && showRepairButton ? false : true}
              >
                Repair{' '}
                <IconWrenchScrewdriver transform="scale(125%)" ml="3" mr="2" />
              </Button> */}

              <RepairStage
                timesUsed={timesUsed}
                maximumTimesUsed={maximumTimesUsed as number}
                currentStage={1}
              />

              <RepairStage
                timesUsed={timesUsed}
                maximumTimesUsed={maximumTimesUsed as number}
                currentStage={2}
              />

              <RepairStage
                timesUsed={timesUsed}
                maximumTimesUsed={maximumTimesUsed as number}
                currentStage={3}
              />

              <RepairStage
                timesUsed={timesUsed}
                maximumTimesUsed={maximumTimesUsed as number}
                currentStage={4}
              />
            </Flex>
          </Container>
        )}

        <Container paddingY="1" paddingX="2">
          {timesUsed < 900 ? (
            <IconHeart transform="scale(150%)" ml="0.5" />
          ) : (
            <IconHeartFilled w="1rem" ml="0.5" />
          )}
          <Text
            fontSize="xs"
            color={timesUsed < 900 ? 'white' : 'fieryCoral.100'}
          >{`${timesUsed}/${maximumTimesUsed}`}</Text>
        </Container>
      </Flex>
    </>
  );
};

export default CarHealth;
