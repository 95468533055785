import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFlag = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M19.383.04c.703 0 1.367.468 1.367 1.17v11.798c0 .469-.313.898-.781 1.094-1.68.664-3.047.898-4.219.898-2.813 0-4.727-1.21-7.5-1.21-1.406 0-3.008.312-5 1.21v4.375a.642.642 0 0 1-.625.625h-1.29a.642.642 0 0 1-.624-.625L.75 1.25C.75.586 1.297 0 2 0c.664 0 1.25.586 1.25 1.25v.04C5.477.351 7.117.04 8.406.04c2.617 0 3.906 1.25 6.172 1.25 1.094 0 2.344-.274 4.063-1.095.234-.117.507-.156.742-.156ZM18.25 5.546V3.086c-1.133.39-2.148.625-3.125.664v2.695c-.195 0-.39.04-.547.04-.976 0-1.797-.235-2.578-.508V3.398c-.39-.117-.742-.234-1.055-.351-.742-.235-1.367-.43-2.07-.469v2.656h-.469c-.742 0-1.64.118-2.656.391V3.047c-.508.117-1.016.312-1.563.547l-.937.39v2.5c.938-.39 1.719-.664 2.5-.859v3.203a15.62 15.62 0 0 0-1.563.586l-.937.39v2.5c.82-.35 1.68-.585 2.5-.78V8.827c.977-.312 1.875-.469 2.656-.469h.469v2.97c1.133.038 2.188.233 3.125.468V9.219c.742.195 1.563.39 2.578.39.195 0 .352-.039.547-.039v2.93h.586c.82 0 1.64-.117 2.539-.43V8.906c-1.133.39-2.148.625-3.125.664V6.445c.86-.078 1.875-.351 3.125-.898Zm-9.375-.313c1.21.079 2.188.43 3.125.743v3.242c-.39-.117-.742-.235-1.055-.352-.742-.234-1.367-.43-2.07-.508V5.234Z"
    />
  </svg>
));
export default SvgIconFlag;
