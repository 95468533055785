import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTimerFill = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={12}
    fill="none"
    viewBox="0 0 11 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M6.625 0c.398 0 .75.352.75.75 0 .422-.352.75-.75.75H6.25v.82c.867.14 1.664.493 2.32 1.032l.516-.493a.723.723 0 0 1 1.055 0 .723.723 0 0 1 0 1.055l-.563.563c.492.773.797 1.687.797 2.648C10.375 9.82 8.172 12 5.5 12A4.871 4.871 0 0 1 .625 7.125 4.849 4.849 0 0 1 4.75 2.32V1.5h-.375a.74.74 0 0 1-.75-.75c0-.398.328-.75.75-.75h2.25Zm-.563 4.5a.57.57 0 0 0-.562-.563.555.555 0 0 0-.563.563v3c0 .328.235.563.563.563a.555.555 0 0 0 .563-.563v-3Z"
    />
  </svg>
));
export default SvgIconTimerFill;
