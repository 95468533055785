import {
  Box,
  Modal as ChakraModal,
  Flex,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { RacesBackground } from 'components/RaceCard/RaceCard';
import Image from 'next/image';
import RaceMinimapMarkers from '../RaceMinimapMarkers';
import RImage from 'components/Image/Image';
import overlayGradient from 'public/images/races/race-thumb-overlay.png';
import { RTiers } from 'components';
import { CarTier } from 'types';
import { rarityGradients } from 'utils/themeConstants';

interface RaceThumbnailModalProps {
  title: string;
  isOpen: boolean;
  tierName: string;
  onClose: () => void;
}

const RaceThumbnailModal = ({
  title,
  tierName,
  isOpen,
  onClose,
}: RaceThumbnailModalProps) => {
  const tier = tierName.toLowerCase() as keyof typeof rarityGradients || 'Free';
  return (
    <ChakraModal 
      isOpen={isOpen} 
      onClose={onClose}
      size="3xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={0} borderRadius="0.5rem">
        <ModalHeader 
          zIndex={100}
          background="darkVoid.100"
          borderTopLeftRadius="0.5rem"
          borderTopRightRadius="0.5rem"
        >
          <Flex
            alignItems="center"
            gap={2}
            pl={2}
          >
            <Box
              background={rarityGradients[tier] || rarityGradients['Free']}
              borderWidth="1px"
              borderColor="white.40"
              borderRadius="0.25rem"
              p="6px"
            >
              <RTiers
                tier={tierName as CarTier}
                tierType="rarity"
                __css={{
                  svg: {
                    height: '16px',
                    width: '16px',
                  },
                }}
              />
            </Box>
            <Text 
              textTransform="none"
              fontSize="16px"
              color="white.80"
            >
              {title}
            </Text>
          </Flex>
          <ModalCloseButton 
            onClick={() => onClose()}
            background="white.10"
            border="none"
            height="100%"
            borderTopRightRadius="0.5rem"
          />
        </ModalHeader>
        <ModalBody p={0} flexDir="column" borderBottomLeftRadius="0.5rem" borderBottomRightRadius="0.5rem">
          <Flex w="100%" h="460px">
            <RImage
              src={overlayGradient.src}
              width="0" // width set in style property
              height="0" // height set in style property
              objectFit="cover"
              objectPosition="top left"
              blendMode="hard-light"
              style={{
                width: '100%',
                height: '100%',
                zIndex: 10,
                position: 'absolute',
                top: 0,
                left: 0,
                overflow: 'hidden',
                borderRadius: '8px',
              }}
              alt={'background image'}
            />

            <Image
              src={RacesBackground[title]}
              alt="Race Background"
              style={{ objectFit: 'cover', opacity: 0.8 }}
              sizes="(max-width: 768px) 100vw, 50vw"
            />

            <Box
              pos="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              margin="auto"
              p="2rem"
              w="80%"
              h="80%"
              zIndex={10}
            >
              <RaceMinimapMarkers race={title} width="100%" height="100%" opacity={1} />
            </Box>            
          </Flex>
        </ModalBody>
        <ModalFooter
          background="darkVoid.100"
          p={4}
          zIndex={100}
        >
          <Button
            onClick={() => onClose()}
            color="white"
            fontSize="16px"
            borderRadius="4px"
            w="full">
            CLOSE
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
    // <RModal
    //   isOpen={isOpen}
    //   onClose={onClose}
    //   title={title}
    //   size="4xl"
    //   isCentered
    // >
    //   <Flex w="100%" h="400px">
    //     <Image
    //       src={RacesBackground[title]}
    //       alt="Race Background"
    //       style={{ objectFit: 'cover', opacity: 0.8, borderRadius: '0.5rem', zIndex: -1 }}
    //     />
    //   </Flex>
    // </RModal>
  );
};

export default RaceThumbnailModal;
