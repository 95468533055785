import { Flex, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { AnimatedTransitionProps } from 'context/animatedTransition';
import { IconCheckBadge } from 'icons';

const MotionFlex = motion(Flex);

const ANIMATION_DURATION_SECONDS = 3;

const calculateAnimationDuration = (dividedBy: number) =>
  (ANIMATION_DURATION_SECONDS / dividedBy).toFixed(1);

const textAnimation = {
  hidden: { width: 0, opacity: 0 },
  visible: {
    width: 'fit-content',
    opacity: 1,
    transition: {
      delay: calculateAnimationDuration(18),
      duration: calculateAnimationDuration(18),
      ease: 'easeOut',
    },
  },
  exit: {
    width: 0,
    opacity: 0,
    transition: {
      delay: calculateAnimationDuration(18),
      duration: calculateAnimationDuration(18),
      ease: 'easeIn',
    },
  },
};

const AnimatedTransition = ({
  bgColor = 'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
  Icon = IconCheckBadge,
  title = 'car',
  subtitle = 'purchased',
  gradientColor,
  color = 'treasuredTeal.100',
  id,
}: AnimatedTransitionProps) => {
  return (
    <Flex
      pos="absolute"
      w="100vw"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      bg="black.100"
      zIndex={1500}
    >
      <Flex
        pos="absolute"
        w="100vw"
        h="100vh"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        bg={bgColor}
      >
        <Flex
          w="40vw"
          h="10rem"
          bg={`linear-gradient(270deg, ${
            gradientColor?.from || 'rgba(72, 223, 187, 0.2)'
          } 0%, ${gradientColor?.to || 'rgba(72, 223, 187, 0)'} 100%)`}
        ></Flex>
        <Flex flexDir={["column", "row"]} align="center">
          <Icon
            w={["3.5rem", "10rem"]}
            h={["3.5rem", "10rem"]}
            mx={["1rem", "3rem"]}
            ml={["25px", ""]}
            mb={["0.5rem", ""]}
            __css={{
              path: { fill: color },
            }}
          />
          <MotionFlex
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={textAnimation}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Flex
              direction="column"
              alignItems={["center", "flex-start"]}
              justifyContent="center"
              mx={["1rem", ""]}
              mr={["", "3rem"]}
              id={id}
            >
              <Heading
                fontFamily={'"Chakra Petch", sans-serif'}
                textTransform="uppercase"
                fontStyle="italic"
                fontSize={["2rem", "2.5rem"]}
                color={color}
              >
                {title}
              </Heading>
              <Heading
                textTransform="uppercase"
                fontSize={["2.5rem", "3.75rem"]}
                color="white.80"
              >
                {subtitle}
              </Heading>
            </Flex>
          </MotionFlex>
        </Flex>
        <Flex
          w="40vw"
          h="10rem"
          bg={`linear-gradient(270deg, ${
            gradientColor?.to || 'rgba(72, 223, 187, 0.2)'
          } 0%, ${gradientColor?.from || 'rgba(72, 223, 187, 0)'} 100%)`}
        ></Flex>
      </Flex>
    </Flex>
  );
};

export default AnimatedTransition;
