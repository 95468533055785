import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWrenchTrial = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    viewBox="0 0 25 25"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#D83832"
      d="M23.766 6.531c.609 2.438-.141 5.063-1.922 6.844a7.203 7.203 0 0 1-6.75 1.922l-8.578 8.578A3.833 3.833 0 0 1 3.796 25c-.983 0-2.062-.422-2.718-1.125A3.992 3.992 0 0 1 0 21.156c0-.984.375-1.968 1.078-2.718l8.625-8.579c-.562-2.437.14-4.968 1.922-6.703 1.828-1.828 4.36-2.531 6.844-1.922.61.141 1.031.61 1.218 1.172.141.563 0 1.172-.421 1.594L16.5 6.719l.234 1.547 1.547.234 2.766-2.766c.375-.375.984-.562 1.594-.421.562.187 1.03.609 1.125 1.218Zm-3.516 5.25c.984-.937 1.5-2.25 1.5-3.562l-2.719 2.719-4.218-.75-.704-4.22 2.625-2.718h-.047a4.798 4.798 0 0 0-3.468 1.5c-1.36 1.313-1.781 3.281-1.078 5.11l.187.656-9.562 9.515c-.282.328-.47.703-.47 1.125 0 .469.188.844.47 1.125.562.61 1.64.61 2.203 0l9.562-9.515.656.234c1.782.61 3.75.14 5.063-1.219ZM4.078 20.125c.422 0 .75.375.703.75 0 .375-.328.75-.703.75-.375 0-.703-.375-.703-.75 0-.422.281-.75.703-.75Z"
    />
  </svg>
));
export default SvgIconWrenchTrial;
