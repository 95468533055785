import { Text, Flex, SimpleGrid } from '@chakra-ui/react';
import { RaceParticipants } from 'api/generated/graphql';
import { ChartData } from 'chart.js';
import { IconTyreC1, IconTyreC2, IconTyreC3, IconTyreC4, IconTyreC5, IconTyreInter, IconTyreWet } from 'icons';
import { RCarCard, RPartCard } from 'components';
import RPerformanceCarCard from 'components/PerformanceCarCard/PerformanceCarCard';
import RPerformancePartCard from 'components/PerformancePartCard/PerformancePartCard';
import RSpiderChart from 'components/SpiderChart/SpiderChart';
import { AppliedLivery, CarTier, Livery, RarityTier, Tyres } from 'types';
import { formatOrdinal } from 'utils/helpers';

interface CarPerformanceProps {
  player?: RaceParticipants | undefined;
  comparisonPlayer?: RaceParticipants | undefined;
  spiderData: ChartData<'radar', unknown, unknown>;
  currentPlayerId?: string;
  style: 'first' | 'comparisson';
}

export const CarPerformanceStats = ({
  player,
  spiderData,
  style,
  currentPlayerId,
  comparisonPlayer
}: CarPerformanceProps) => {
  const theme = {
    bg:
      style === 'first'
        ? 'linear-gradient(0deg, rgba(216, 56, 50, 0.20) 0%, rgba(216, 56, 50, 0.00) 100%)'
        : 'linear-gradient(0deg, rgba(204, 204, 204, 0.20) 0%, rgba(204, 204, 204, 0.00) 100%)',
    header:
      style === 'first'
        ? 'linear-gradient(90deg, #D83832 0%, rgba(216, 56, 50, 0) 100%)'
        : 'linear-gradient(90deg, rgba(128, 128, 128, 0.80) 0%, rgba(163, 163, 163, 0.00) 100%)',
    finishBadge: style === 'first' ? 'bloodMoon.100' : 'white',
    badgeText: style === 'first' ? 'white.80' : 'black',
    bgParts:
      'linear-gradient(0deg, rgba(204, 204, 204, 0.2) 0%, rgba(204, 204, 204, 0.00) 70%)',
  };

  const tyreIconLookup = {
    C1: IconTyreC1,
    C2: IconTyreC2,
    C3: IconTyreC3,
    C4: IconTyreC4,
    C5: IconTyreC5,
    INTER: IconTyreInter,
    WET: IconTyreWet,
  };

  const getTyreIcon = (TyreId: Tyres) => {
    return tyreIconLookup[TyreId] || <></>;
  };


  return (
    <Flex w="full" flexDir="column" bg={theme.bg}>
     <Flex gap={4} flexDir={{ base: 'column', md: 'row' }}>
      <Flex 
        w={{ base: '100%', md: '50%'}} 
        flexDir="column"
         background="linear-gradient(180deg, rgba(79, 197, 186, 0.2) 0%, rgba(79, 197, 186, 0) 100%)"
         borderRadius="8px"
      >
        <Flex 
          w="100%" 
          py="2" 
          alignItems="center" 
          justifyContent="center"
          background="linear-gradient(90deg, rgba(79, 197, 186, 0.2) 0%, rgba(79, 197, 186, 0) 100%)"
          borderTopRadius="8px"
        >
          <Text
            fontFamily={"heading"}
            color="baltic.100"
            fontSize={"lg"}
          >
            {player?.id === currentPlayerId ? 'Your car' : 'Winner'}
          </Text>
        </Flex>
      {/* <RCarCard
          locked={false}
          vehicleName={player?.car?.name as string}
          livery={(player?.car?.livery as Livery) || 'blue'}
          backgroundPreload
          tier={(player?.car?.tier?.name as CarTier) || 'bronze'}
          rarity={'common'}
          imageProps={{
            paddingY: 9,
            transform: "scale(50%)"
          }}
          bg="linear-gradient(180deg, #000 44.27%, #272727 72.92%, #353535 100%)"
        /> */}
        <RPerformanceCarCard
          livery={(player?.car?.livery as Livery) || 'blue'}
          vehicleName={player?.car?.name as string}
          tier={player?.car?.tier?.name as CarTier || 'bronze'}
          appliedLivery={player?.car?.appliedLivery as AppliedLivery}
          rarity={player?.car?.rarity as RarityTier || 'common'}
          started={formatOrdinal(player?.qualifyingGridPosition as number)}
          icon={getTyreIcon(player?.tyres?.id as Tyres || 'C1')}
          finished={formatOrdinal(player?.position as number)}
          points={player?.points || 0}
        />
      </Flex>
      <Flex
        w={{ base: '100%', md: '50%'}} 
        flexDir="column"
        background="linear-gradient(180deg, rgba(216, 56, 50, 0.2) 0%, rgba(216, 56, 50, 0) 100%)"
        borderRadius="8px"
      >
        <Flex 
          w="100%" 
          py="2" 
          alignItems="center" 
          justifyContent="center"
          background="linear-gradient(90deg, rgba(216, 56, 50, 0.2) 0%, rgba(216, 56, 50, 0) 100%)"
          borderTopRadius="8px"
        >
          <Text
            fontFamily={"heading"}
            color="bloodMoon.100"
            fontSize={"lg"}
          >
            {comparisonPlayer?.id !== currentPlayerId ? '2nd place' : 'Your car'}
          </Text>
        </Flex>
      {/* <RCarCard
          locked={false}
          vehicleName={comparisonPlayer?.car?.name as string}
          livery={(comparisonPlayer?.car?.livery as Livery) || 'blue'}
          backgroundPreload
          tier={(comparisonPlayer?.car?.tier?.name as CarTier) || 'bronze'}
          rarity={'common'}
          imageProps={{
            paddingY: 9,
            transform: "scale(50%)"
          }}
          bg="linear-gradient(180deg, #000 44.27%, #272727 72.92%, #353535 100%)"
        /> */}
        <RPerformanceCarCard 
          livery={(comparisonPlayer?.car?.livery as Livery) || 'blue'}
          points={comparisonPlayer?.points || 0}
          vehicleName={comparisonPlayer?.car?.name as string}
          appliedLivery={comparisonPlayer?.car?.appliedLivery as AppliedLivery}
          tier={comparisonPlayer?.car?.tier?.name as CarTier || 'bronze'}
          rarity={comparisonPlayer?.car?.rarity as RarityTier || 'common'}
          started={formatOrdinal(comparisonPlayer?.qualifyingGridPosition as number)}
          finished={formatOrdinal(comparisonPlayer?.position as number)}
          icon={getTyreIcon(comparisonPlayer?.tyres?.id as Tyres || 'C1')}
        />
      </Flex>
     </Flex>
    </Flex>
  );
};
