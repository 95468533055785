/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  HStack,
  Input,
  InputProps,
  useNumberInput,
} from '@chakra-ui/react';
import { useLoadingTrailer } from 'context';
import useDebounce from 'hooks/useDebounce';
import { useCallback, useState } from 'react';

interface RNumberInputProps extends InputProps {
  value: number;
  setValue: (value: number) => void;
}

const RNumberInput = ({ value, setValue, ...rest }: RNumberInputProps) => {
  const [displayValue, setDisplayValue] = useState(value);
  const { isLoadingTickets, setIsLoadingTickets } = useLoadingTrailer();

  const debouncedSetValue = useCallback(
    useDebounce((newValue: number) => {
      setIsLoadingTickets(true);
      setValue(newValue);
    }, 1000),
    [setIsLoadingTickets, setValue, isLoadingTickets]
  );

  const handleChange = useCallback(
    (valueAsString: string) => {
      const newValue = parseInt(valueAsString, 10);
      setDisplayValue(newValue);
      debouncedSetValue(newValue);
    },
    [debouncedSetValue]
  );

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 100,
      value: displayValue,
      onChange: handleChange,
      isDisabled: isLoadingTickets,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack maxW="180px">
      <Button
        {...dec}
        bg="white.10"
        color="white.95"
        isDisabled={isLoadingTickets}
      >
        -
      </Button>
      <Input
        textAlign="center"
        {...input}
        readOnly
        pointerEvents="none"
        {...rest}
      />
      <Button
        {...inc}
        bg="white.10"
        color="white.95"
        isDisabled={isLoadingTickets}
      >
        +
      </Button>
    </HStack>
  );
};

export default RNumberInput;
