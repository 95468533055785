import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconProfile = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="none"
    viewBox="0 0 12 13"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.125 7.625a4.131 4.131 0 0 1 4.125 4.125c0 .422-.352.75-.75.75h-9a.74.74 0 0 1-.75-.75 4.116 4.116 0 0 1 4.125-4.125h2.25Zm-5.25 3.75h8.227A3.01 3.01 0 0 0 7.125 8.75h-2.25a3.016 3.016 0 0 0-3 2.625ZM6 6.5a2.99 2.99 0 0 1-3-3c0-1.64 1.336-3 3-3 1.64 0 3 1.36 3 3 0 1.664-1.36 3-3 3Zm0-4.875A1.866 1.866 0 0 0 4.125 3.5c0 1.055.82 1.875 1.875 1.875 1.031 0 1.875-.82 1.875-1.875A1.88 1.88 0 0 0 6 1.625Z"
    />
  </svg>
));
export default SvgIconProfile;
