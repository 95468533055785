import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWatch = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M16.75 3.125H13v-1.25c0-.313.273-.625.625-.625h2.5c.313 0 .625.313.625.625v1.25ZM8 1.875c0-.313-.313-.625-.625-.625h-2.5a.642.642 0 0 0-.625.625v1.25H8v-1.25Zm12.5 15c0 1.055-.86 1.875-1.875 1.875h-5a1.851 1.851 0 0 1-1.875-1.875V11.25h-2.5v5.625c0 1.055-.86 1.875-1.875 1.875h-5.04C1.32 18.75.462 17.93.5 16.875v-1.719c.117-5.039 2.383-5.86 2.46-9.492.04-.703.587-1.289 1.25-1.289h3.75c.704 0 1.29.586 1.29 1.25v.625h2.5v-.625c0-.664.547-1.25 1.25-1.25h3.75c.664 0 1.21.586 1.25 1.29.078 3.632 2.344 4.452 2.46 9.491l.04 1.719ZM7.375 6.25h-2.54c-.155 1.758-.741 3.008-1.25 4.102-.585 1.289-1.17 2.539-1.21 4.882v1.68l5-.039V6.25Zm11.25 10.625-.04-1.68c-.038-2.343-.624-3.554-1.21-4.843-.508-1.094-1.094-2.344-1.25-4.102h-2.5v10.625h5Z"
    />
  </svg>
));
export default SvgIconWatch;
