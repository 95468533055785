import { Box, keyframes } from '@chakra-ui/react';

import { SVGIcon } from 'types';
import { IconRacinoLogo } from 'icons';

const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

const vminValue = 10;

const LoadingAnimation = ({
  Icon = IconRacinoLogo,
  backgroundColor = 'rgba(0, 0, 0, 1)',
}: {
  Icon?: SVGIcon;
  backgroundColor?: string;
}) => {
  const before = {
    content: "''",
    position: 'absolute',
    zIndex: -2,
    left: '-50%',
    top: '-50%',
    width: '200%',
    height: '200%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50% 50%, 50% 50%',
    backgroundPosition: '1px 1px, 100% 0, 100% 100%, 0 100%',
    // bg images are ordered clockwise starting from top left
    backgroundImage:
      'linear-gradient(#D83832, #585a5f), linear-gradient(#ad3529 40%, #D83832, #D83832), linear-gradient(#0d0d0d, #0d0d0d), linear-gradient(#585a5f, #0d0d0d, #000)',
    animation: `${rotate} 3s linear infinite`,
  };

  const after = {
    content: "''",
    position: 'absolute',
    zIndex: -1,
    left: '3px',
    top: '3px',
    width: 'calc(100% - 6px)',
    height: 'calc(100% - 6px)',
    background: backgroundColor,
  };

  return (
    <Box
      position="relative"
      height={`${vminValue * 2}vmin`}
      width={`${vminValue * 1.5}vmin`}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        marginX="auto"
        marginTop="10vmin"
        left="0"
        right="0"
        top={`calc(25% - ${vminValue}vmin)`}
        transform="rotate(45deg)"
        position="absolute"
        zIndex="0"
        width={`${vminValue}vmin`}
        height={`${vminValue}vmin`}
        overflow="hidden"
        _before={before}
        _after={after}
      ></Box>

      <Icon
        width={`${vminValue / 2}vmin`}
        height={`${vminValue / 2}vmin`}
        zIndex="2"
        position="relative"
      />
    </Box>
  );
};

export default LoadingAnimation;
