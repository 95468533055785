import { ChakraComponent, Flex, Heading, Text } from '@chakra-ui/react';
import { IconDollar } from 'icons';

export const PriceLabel = ({
  value,
  currency = 'USD',
  currencySize = 'md',
  numberSize = '2xl',
  iconSize = '2.6rem',
  icon: Icon = IconDollar,
  color = 'aphroditeAqua.100',
}: {
  value: string | number;
  currency?: string;
  numberSize?: string | { base: string; md: string };
  currencySize?: string | { base: string; md: string };
  iconSize?: string | { base: string; md: string };
  icon?: ChakraComponent<any, any>;
  color?: string;
}) => {
  // Função para formatar o valor
  const formatValue = (val: string | number) => {
    const numValue = typeof val === 'string' ? parseFloat(val) : val;
    if (isNaN(numValue)) return val;

    const [intPart, decimalPart] = numValue.toString().split('.');
    if (!decimalPart) return intPart;

    return `${intPart}.${decimalPart.slice(0, 8)}`;
  };

  return (
    <Flex gap={2} align="center" textColor={color}>
      <Icon
        width={iconSize}
        height="auto"
        __css={{
          path: {
            fill: color,
          },
        }}
      />
      <Flex flexDir="row" alignItems="baseline" gap={1}>
        <Heading fontFamily="body" fontSize={numberSize} fontWeight="500">
          {formatValue(value)}
        </Heading>
        <Text fontFamily="body" fontSize={currencySize} fontWeight="400">
          {currency}
        </Text>
      </Flex>
    </Flex>
  );
};