import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCalendar = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="m9.229 11.953-1.563 1.524a.856.856 0 0 1-1.29 0c-.39-.352-.39-.938 0-1.329l3.126-3.125a.92.92 0 0 1 1.289 0l3.125 3.125c.39.391.39.977 0 1.329a.855.855 0 0 1-1.289 0l-1.524-1.524v3.985c0 .546-.43.937-.937.937-.547 0-.937-.39-.937-.938v-3.984ZM6.416 0a.95.95 0 0 1 .938.938V2.5h5.625V.937c0-.507.39-.937.937-.937a.95.95 0 0 1 .938.938V2.5h1.562c1.367 0 2.5 1.133 2.5 2.5v12.5c0 1.406-1.133 2.5-2.5 2.5h-12.5a2.468 2.468 0 0 1-2.5-2.5V5c0-1.367 1.094-2.5 2.5-2.5h1.563V.937c0-.507.39-.937.937-.937Zm10.625 7.5H3.291v10c0 .352.273.625.625.625h12.5a.642.642 0 0 0 .625-.625v-10Z"
    />
  </svg>
));
export default SvgIconCalendar;
