import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTimer = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={32}
    fill="none"
    viewBox="0 0 27 32"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M14 20c0 .563-.5 1-1.063 1-.5 0-1-.438-1-1v-7c0-.5.5-1 1-1C13.5 12 14 12.5 14 13v7ZM6.937 1c0-.5.5-1 1-1H18c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-4v4.063c3.063.25 5.813 1.562 7.938 3.562l2.312-2.313c.375-.375 1.063-.375 1.438 0s.375 1.063 0 1.438l-2.375 2.375A12.89 12.89 0 0 1 26 19c0 7.188-5.875 13-13 13-7.188 0-13-5.813-13-13C0 12.187 5.25 6.562 12 6.062V2H8c-.563 0-1-.438-1-1h-.063Zm6 29C19.064 30 24 25.125 24 19c0-6.063-4.938-11-11.063-11-6.062 0-11 4.938-11 11 0 6.125 4.938 11 11 11Z"
    />
  </svg>
));
export default SvgIconTimer;
