import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconClose = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M12.938 11.648c.351.391.351.977 0 1.329-.391.39-.977.39-1.329 0L7 8.328l-4.648 4.649c-.391.39-.977.39-1.329 0-.39-.352-.39-.938 0-1.329L5.672 7 1.023 2.352c-.39-.391-.39-.977 0-1.329a.855.855 0 0 1 1.29 0L7 5.711l4.648-4.649a.856.856 0 0 1 1.29 0c.39.352.39.938 0 1.329L8.288 7l4.649 4.648Z"
    />
  </svg>
));
export default SvgIconClose;
