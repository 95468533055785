import { useEffect, useState } from 'react';
import { Button, FormControl, Text } from '@chakra-ui/react';

import { RInput, RModal } from 'components';
import { useUserSettings } from 'hooks';

import { profanity } from '@2toad/profanity';

interface SetAvatarNameModalProps {
  currentName?: string;
  isOpen: boolean;
  onClose: () => void;
}

const SetAvatarNameModal = ({
  currentName,
  isOpen,
  onClose,
}: SetAvatarNameModalProps) => {
  const { changeUserSettings } = useUserSettings();

  const [driverName, setDriverName] = useState(currentName || '');
  const [errorType, setErrorType] = useState<
    null | 'characters' | 'empty' | 'alpha' | 'profanity'
  >(null);

  const validateName = () => {
    if (profanity.exists(driverName)) {
      setErrorType('profanity');
      return false;
    }

    if (driverName.trim() === '') {
      setErrorType('empty');
      return false;
    }

    if (driverName.length > 50) {
      setErrorType('characters');
      return false;
    }

    if (!/^[a-zA-Z\s]*$/.test(driverName)) {
      setErrorType('alpha');
      return;
    }

    setErrorType(null);
    return true;
  };

  const handleSubmitChangeName = async () => {
    if (validateName()) {
      await changeUserSettings.mutateAsync({
        playerName: driverName,
        countryFlag: 'US', // Hard coded until implemented
      });
      onClose();
    }
  };

  const errorMessage = {
    characters: 'Name exceeds 50 characters limit.',
    empty: 'Name cannot be empty.',
    alpha: 'Name must contain only alphabetic characters.',
    profanity: 'Name cannot contain profane terms.',
  };

  useEffect(() => {
    if (!isOpen) {
      setErrorType(null);
      setDriverName(currentName || '');
    }
  }, [isOpen, currentName]);

  return (
    <RModal
      variant="darkGrey"
      title="DRIVER'S NAME"
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <FormControl
        w="full"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        p={4}
      >
        <RInput
          w="full"
          label="Enter name"
          value={driverName}
          onChange={(e) => setDriverName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSubmitChangeName();
          }}
          errorMessage={errorType ? errorMessage[errorType] : undefined}
        />
        <Text fontSize="sm" color="paleSlate.100">
          Enter standard characters with no numbers.
        </Text>
        <Button
          w="full"
          mt="2rem"
          isLoading={changeUserSettings.isLoading}
          onClick={handleSubmitChangeName}
        >
          Apply
        </Button>
      </FormControl>
    </RModal>
  );
};

export default SetAvatarNameModal;
