import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCar = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={36}
    fill="none"
    viewBox="0 0 40 36"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M6.25 21.125c0-1.016.781-1.875 1.875-1.875 1.016 0 1.875.86 1.875 1.875C10 22.219 9.14 23 8.125 23c-1.094 0-1.875-.781-1.875-1.875Zm27.5 0c0 1.094-.86 1.875-1.875 1.875C30.781 23 30 22.219 30 21.125c0-1.016.781-1.875 1.875-1.875 1.016 0 1.875.86 1.875 1.875Zm-30-7.11 2.656-8.984C7.266 2.375 9.688.5 12.422.5H27.5c2.734 0 5.156 1.875 6.016 4.531l2.656 8.985C38.438 15.344 40 17.766 40 20.5v13.75c0 .703-.625 1.25-1.25 1.25-.703 0-1.25-.547-1.25-1.25V30.5h-35v3.75c0 .703-.625 1.25-1.25 1.25-.703 0-1.25-.547-1.25-1.25V20.5c0-2.734 1.484-5.156 3.75-6.484Zm2.89-.937c.235 0 .548-.078.86-.078h25c.234 0 .547.078.781.078l-2.187-7.344A3.75 3.75 0 0 0 27.5 3H12.422a3.75 3.75 0 0 0-3.594 2.734l-2.187 7.344ZM2.5 20.5V28h35v-7.5c0-2.11-1.406-3.984-3.36-4.688-.546-.156-1.093-.312-1.64-.312h-25c-.625 0-1.172.156-1.719.313A4.986 4.986 0 0 0 2.5 20.5Z"
    />
  </svg>
));
export default SvgIconCar;
