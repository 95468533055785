import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMoneyDouble = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={18}
    fill="none"
    viewBox="0 0 25 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#4FC5BA"
      d="M20.625 16.5c.313 0 .625.313.625.625a.642.642 0 0 1-.625.625H3.75C1.64 17.75 0 16.11 0 14V3.375c0-.313.273-.625.625-.625.313 0 .625.313.625.625V14c0 1.406 1.094 2.5 2.5 2.5h16.875ZM22.5.25c1.367 0 2.5 1.133 2.5 2.5v8.75c0 1.406-1.133 2.5-2.5 2.5H6.25a2.468 2.468 0 0 1-2.5-2.5V2.75c0-1.367 1.094-2.5 2.5-2.5H22.5Zm1.25 11.25V2.75c0-.664-.586-1.25-1.25-1.25H6.25C5.547 1.5 5 2.086 5 2.75v8.75c0 .703.547 1.25 1.25 1.25H22.5c.664 0 1.25-.547 1.25-1.25Zm-9.414-8.125c1.758 0 3.125 1.68 3.125 3.75 0 2.11-1.406 3.75-3.125 3.75-1.758 0-3.125-1.64-3.125-3.75 0-2.07 1.367-3.75 3.125-3.75Zm.039 6.25c1.016 0 1.875-1.133 1.875-2.5 0-1.328-.86-2.5-1.875-2.5-1.055 0-1.875 1.172-1.875 2.5 0 1.367.82 2.5 1.875 2.5Z"
    />
  </svg>
));
export default SvgIconMoneyDouble;
