import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDollarBalance = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.11 17.688C2.828 16.15.63 11.373 2.224 7.144c.824-2.306 2.636-4.063 4.888-4.887.22-.11.33-.274.33-.549V.939c0-.22-.11-.384-.33-.439-.055 0-.165 0-.22.055C1.674 2.202-1.181 7.749.466 12.965a9.879 9.879 0 0 0 6.425 6.425c.22.11.44 0 .494-.22.055-.054.055-.11.055-.219v-.769c0-.165-.165-.384-.33-.494ZM12.933.555c-.22-.11-.44 0-.495.22-.055.054-.055.11-.055.22v.768c0 .22.165.44.33.55 4.283 1.537 6.48 6.314 4.887 10.543-.824 2.306-2.636 4.063-4.887 4.887-.22.11-.33.274-.33.549v.769c0 .22.11.384.33.439.055 0 .165 0 .22-.055 5.216-1.647 8.072-7.194 6.425-12.41a9.96 9.96 0 0 0-6.425-6.48Zm-2.856 8.512c2.306.274 3.46.933 3.46 2.855 0 1.483-1.099 2.636-2.746 2.91v1.318c-.055.275-.22.44-.44.44h-.823c-.275-.055-.44-.22-.44-.44v-1.318c-1.812-.274-2.69-1.263-2.91-2.635v-.055c0-.22.165-.385.384-.385h.934c.165 0 .33.11.384.33.165.823.66 1.428 2.087 1.428 1.043 0 1.812-.604 1.812-1.483 0-.879-.494-1.208-2.031-1.483-2.307-.274-3.405-.988-3.405-2.8 0-1.373 1.043-2.472 2.69-2.691V3.795c.056-.275.22-.44.44-.44h.824c.274.055.439.22.439.44v1.318a2.743 2.743 0 0 1 2.471 2.251v.055c0 .22-.165.384-.384.384h-.879a.413.413 0 0 1-.384-.274c-.275-.824-.824-1.153-1.812-1.153-1.099 0-1.648.494-1.648 1.263 0 .769.33 1.208 1.977 1.428Z"
    />
  </svg>
));
export default SvgIconDollarBalance;
