import { useMutation } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';
import { MutationRegisterNotificationSubscriptionArgs } from 'api/generated/graphql';

const useRegisterNotificationSubscription = (rest?: {
  [key: string]: unknown;
}) => {
  const { graphql, graphQLClient } = useGraphQLClient();

  const registerNotificationSubscriptionGQL = graphql(`
    mutation RegisterNotificationSubscription(
      $subscription: PushNotificationSubscription!
    ) {
      registerNotificationSubscription(subscription: $subscription) {
        id
        email
        walletAddress
        updatedAt
        playerName
        countryFlag
      }
    }
  `);

  const registerNotificationSubscription = useMutation({
    mutationKey: ['registerNotificationSubscription'],
    mutationFn: ({
      subscription,
    }: MutationRegisterNotificationSubscriptionArgs) => {
      return graphQLClient.request(registerNotificationSubscriptionGQL, {
        subscription,
      });
    },
    ...rest,
  });

  return registerNotificationSubscription;
};

export default useRegisterNotificationSubscription;
