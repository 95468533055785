import {
  cssVar,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/styled-system';

import { colors } from 'utils/themeConstants';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const baseStyle = defineStyle({
  [$startColor.variable]: colors.white[10],
  [$endColor.variable]: colors.white[20],
  height: '1.25rem',
});

export const skeletonTheme = defineStyleConfig({
  baseStyle,
});
