import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMarket = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M17 3.313 15.375.75A1.534 1.534 0 0 0 14.062 0H3.906c-.531 0-1.031.281-1.312.75L.969 3.313C.406 4.186.344 5.28.78 6.25A2.816 2.816 0 0 0 2.97 7.875c.156 0 .281.031.437.031.031 0 .063-.031.094-.031.625 0 1.25-.188 1.781-.563 1.063.75 2.625.75 3.688 0 1.094.75 2.656.75 3.719 0 .562.375 1.187.594 1.874.594.126 0 .282-.031.438-.031a2.816 2.816 0 0 0 2.188-1.625c.437-.969.375-2.063-.188-2.938Zm-1.156 2.28c-.219.438-.594.72-1.031.782a1.854 1.854 0 0 1-1.563-.563l-.563-.593-.562.593c-.656.75-1.906.75-2.594 0l-.562-.625-.531.625c-.688.75-1.938.75-2.626 0l-.53-.625-.563.625c-.407.438-1 .625-1.563.563-.437-.063-.812-.344-1.031-.781a1.535 1.535 0 0 1 .094-1.469L3.906 1.5l10.219.031 1.625 2.594c.281.438.313 1 .094 1.469Zm-1.281 3.313c-.032 0-.063-.031-.094-.031V10.5H3.5V8.875c-.031 0-.063.031-.094.031-.187 0-.375-.031-.562-.031-.313-.063-.594-.125-.844-.25v5.625c0 .969.781 1.75 1.75 1.75h10.469c.969 0 1.75-.781 1.75-1.75V8.625c-.25.094-.563.156-.844.219-.188.031-.375.062-.563.062Zm-.344 5.594H3.75c-.156 0-.25-.094-.25-.25V12h10.969v2.25c0 .156-.094.25-.25.25Z"
    />
  </svg>
));
export default SvgIconMarket;
