import { useEffect, useRef, useState } from 'react';
import { Flex, FlexProps, Heading } from '@chakra-ui/react';

import { IconCheckmarkSmall, IconChevron, IconClose } from 'icons';

interface SelectMultipleProps extends FlexProps {
  placeholder?: string;
  options: string[];
  selectedOptions?: string[];
  setSelectedOptions?: (value: string[]) => void;
}

const SelectMultiple = ({
  children,
  placeholder,
  options,
  selectedOptions = [],
  setSelectedOptions,
  ...props
}: SelectMultipleProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleSelectOption = (value: string) => {
    if (setSelectedOptions) {
      const updatedSelectedOptions: string[] = selectedOptions.includes(value)
        ? selectedOptions.filter((option) => option !== value)
        : [...selectedOptions, value];

      setSelectedOptions(updatedSelectedOptions);
      setIsSelected(false);
    }
  };

  const handleClearSelected = () => {
    setSelectedOptions && setSelectedOptions([]);
    setIsSelected(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsSelected(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Flex
      ref={containerRef}
      pos="relative"
      h="3rem"
      bgColor={isSelected ? 'fieryCoral.40' : 'white.10'}
      cursor="default"
      {...props}
    >
      <Flex
        w="full"
        h="full"
        alignItems="center"
        justifyContent="space-between"
        px="1.25rem"
        onClick={() => setIsSelected((oldState) => !oldState)}
      >
        <Heading textTransform="uppercase" fontWeight="400" fontSize="1rem">
          {selectedOptions.join(', ') || placeholder}
        </Heading>
        <IconChevron
          transform="rotate(90deg)"
          __css={{
            path: { fill: isSelected ? 'fieryCoral.100' : 'white.100' },
          }}
        />
      </Flex>
      {isSelected && (
        <Flex
          pos="absolute"
          overflowY="auto"
          flexDir="column"
          top={props.h || '3.5rem'}
          left="0"
          w="full"
          maxH="30rem"
          justifyContent="center"
          bgColor="neutralDark.100"
        >
          <Flex
            w="full"
            h="full"
            py="0.75rem"
            px="1.25rem"
            justifyContent="space-between"
            onClick={handleClearSelected}
          >
            <Heading
              textTransform="uppercase"
              fontWeight="400"
              fontSize="0.875rem"
              color="fieryCoral.100"
            >
              Clear Selected
            </Heading>

            <IconClose
              w="0.625rem"
              __css={{ path: { fill: 'fieryCoral.100' } }}
            />
          </Flex>

          {options.map((option, index) => (
            <Flex
              key={index}
              w="full"
              h="full"
              py="0.75rem"
              px="1.25rem"
              justifyContent="space-between"
              onClick={() => handleSelectOption(option)}
            >
              <Heading
                textTransform="uppercase"
                fontWeight="400"
                fontSize="0.875rem"
              >
                {option}
              </Heading>

              {selectedOptions.includes(option) && (
                <IconCheckmarkSmall
                  w="0.625rem"
                  __css={{ path: { stroke: 'treasuredTeal.100' } }}
                />
              )}
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default SelectMultiple;
