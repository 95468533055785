import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetCarParts = (carId: string) => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const getPartsGql = graphql(`
    query GetCarParts($carId: String!) {
      getCarParts(carId: $carId) {
        id
        name
        tunable
        rewardable
        carParts {
          id
          name
          description
          rankRequired
          lowSettingRange
          highSettingRange
          usage
          locked
          timesUsed
          maximumTimesUsed
        }
      }
    }
  `);

  const getCarParts = useQuery({
    enabled: Boolean(hasToken && carId),
    queryKey: ['partsQuery', carId],
    queryFn: async () => {
      return graphQLClient.request(getPartsGql, {
        carId,
      });
    },
  });
  return getCarParts;
};

export default useGetCarParts;
