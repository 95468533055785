import { cloneDeep } from 'lodash';
import { PartGroup, SVGIcon } from 'types';

interface PartMapping {
  name: string;
  screenName?: string;
  icon?: SVGIcon; // icon is used by tyres in part card
  path?: string;
  code?: string;
  tyreId?: string;
}

export const partsData: PartMapping[] = [
  {
    name: 'frontWing',
    screenName: 'Front Wing',
    path: '/images/parts/front-wing.png',
    code: 'FW',
  },
  {
    name: 'rearWing',
    screenName: 'Rear Wing',
    path: '/images/parts/rear-wing.png',
    code: 'RW',
  },
  {
    name: 'engineCooling',
    screenName: 'Cooling',
    path: '/images/parts/engine-cover.png',
    code: 'EC',
  },
  {
    name: 'engineMode',
    screenName: 'Engine',
    path: '/images/parts/engine.png',
    code: 'EM',
  },
  {
    name: 'brakeCooling',
    screenName: 'Braking',
    path: '/images/parts/braking.png',
    code: 'BD',
  },
  {
    name: 'transmissionRatio',
    screenName: 'Transmission',
    path: '/images/parts/transmission.png',
    code: 'UB',
  },
  {
    name: 'transmissionRatios',
    screenName: 'Transmission',
    path: '/images/parts/transmission.png',
    code: 'UB',
  },
  {
    name: 'suspensionRideHeight',
    screenName: 'Ride Height',
    path: '/images/parts/ride-height.png',
    code: 'SH',
  },
  {
    name: 'suspensionStiffness',
    screenName: 'Stability',
    path: '/images/parts/stability.png',
    code: 'SS',
  },
];

export const setAppliedParts = ({
  carouselData,
  appliedPartIds,
}: {
  carouselData: PartGroup[];
  appliedPartIds: string[];
}) => {
  const cloned = cloneDeep(carouselData);
  cloned.forEach(({ partsInGroup }) => {
    partsInGroup.forEach((part) => {
      if (appliedPartIds.includes(part.id || '')) {
        part.isApplied = true;
      }
    });
  });

  return cloned as PartGroup[];
};
