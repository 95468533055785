import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRainConditions = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M1.922 5.438a.373.373 0 0 1 .633 0C3 6.188 3.75 7.522 3.75 8.155c0 .89-.68 1.594-1.5 1.594-.844 0-1.5-.703-1.5-1.594 0-.633.727-1.969 1.172-2.719ZM6 3.655c0 .89-.68 1.594-1.5 1.594-.844 0-1.5-.703-1.5-1.594 0-.633.727-1.969 1.172-2.719a.373.373 0 0 1 .633 0C5.25 1.688 6 3.023 6 3.658Zm2.578-.469c.797 1.149 2.672 3.985 2.672 5.227 0 1.57-1.36 2.836-3 2.836-1.664 0-3-1.266-3-2.836 0-1.242 1.852-4.078 2.648-5.226a.416.416 0 0 1 .68 0Z"
    />
  </svg>
));
export default SvgIconRainConditions;
