/* eslint-disable react-hooks/exhaustive-deps */

import { useUserSettings } from 'hooks';
import useCreateRpmAuthToken from 'hooks/ReadyPlayerMe/useCreateRpmAuthToken';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { RpmAvatar } from 'utils/readyPlayerMe/responseTypes';

interface AvatarContextInterface {
  avatarJson: RpmAvatar;
  updateAvatarJson: (avatar: RpmAvatar) => void;
  avatarModel: globalThis.Blob | undefined;
  updateAvatarModel: (avatar: globalThis.Blob | undefined) => void;
  cameraZoom: {
    distance: number;
    target: number;
  };
  setCameraZoom: Dispatch<
    SetStateAction<{
      distance: number;
      target: number;
    }>
  >;
  changeAssetOption: (option: string) => void;
  assetOption: string;
  controlsOpen: boolean;
  toggleControls: () => void;
  isAvatarLoading: boolean;
  setAvatarContextLoading: (loading: boolean) => void;
  avatarGender: AvatarGender;
  updateAvatarGender: (newGender: AvatarGender) => void;
}

export type AvatarGender = 'male' | 'female';

interface AvatarProviderProps {
  children: ReactNode;
}
const Context = createContext<AvatarContextInterface>(
  {} as AvatarContextInterface
);

export function AvatarProvider({ children }: AvatarProviderProps) {
  // Control 3D Model and JSON Avatar
  const [avatarJson, setAvatarJson] = useState<RpmAvatar>({} as RpmAvatar);
  const [avatarModel, setAvatarModel] = useState<globalThis.Blob | undefined>(
    undefined
  );

  const updateAvatarModel = (avatar: globalThis.Blob | undefined) => {
    setAvatarModel(avatar);
  };

  const updateAvatarJson = (avatar: RpmAvatar) => {
    setAvatarJson(avatar);
  };

  // Control Avatar Gender
  const [gender, setGender] = useState<AvatarGender>('male');
  const updateAvatarGender = (newGender: AvatarGender) => {
    setGender(newGender);
  };

  // Control Global Loading state for Avatar
  const [isLoading, setIsLoading] = useState(false);
  const setAvatarContextLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  // Control Camera zoom
  const [cameraZoom, setCameraZoom] = useState({ distance: 1.7, target: 1.36 });
  const [controlsOpen, setControlsOpen] = useState(true);
  const toggleControls = () => {
    setControlsOpen(!controlsOpen);
  };

  // Control Asset Option [ Type | Color ]
  const [assetOption, setAssetOption] = useState('type');
  const changeAssetOption = (option: string) => {
    setAssetOption(option);
  };

  // Verify user has avatar
  const { getUserSettings, changeUserSettings } = useUserSettings();
  const { data: userData } = getUserSettings;
  const { mutateAsync: updateUserData } = changeUserSettings;

  //Create RPM AuthToken & Avatar
  const { mutateAsync: createNewAvatarAccount } = useCreateRpmAuthToken();
  const createNewRpmAuthAndAvatar = async () => {
    const { avatarModel, rpmUser, avatarJson } = await createNewAvatarAccount();
    await updateUserData({
      avatarId: avatarJson.id,
      rpmAuthToken: rpmUser.token,
      rpmUserId: rpmUser.id,
    });
    setAvatarModel(avatarModel);
    setAvatarJson({ data: avatarJson });
    setGender(avatarJson.gender as AvatarGender);
  };

  // Verify user has avatar
  useEffect(() => {
    const userHasAvatar = Boolean(
      userData?.getUser.avatarId &&
        userData?.getUser.rpmAuthToken &&
        userData?.getUser.rpmUserId
    );

    if (userData?.getUser && !userHasAvatar) {
      console.log('USER HAS NO AVATAR 🛑');
      createNewRpmAuthAndAvatar();
    } else console.log('USER HAS AVATAR ✅');
  }, [userData]);

  return (
    <Context.Provider
      value={{
        assetOption,
        avatarJson,
        updateAvatarJson,
        avatarModel,
        updateAvatarModel,
        cameraZoom,
        changeAssetOption,
        controlsOpen,
        setCameraZoom,
        toggleControls,
        isAvatarLoading: isLoading,
        setAvatarContextLoading,
        avatarGender: gender,
        updateAvatarGender,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useAvatar() {
  return useContext(Context);
}
