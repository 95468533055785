import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconNewRain = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M9 5.094C9 6.578 7.867 7.75 6.5 7.75 5.094 7.75 4 6.578 4 5.094 4 4.039 5.21 1.812 5.953.562A.623.623 0 0 1 6.5.25a.61.61 0 0 1 .508.313C7.75 1.813 9 4.038 9 5.093ZM6.5 6.5c.664 0 1.25-.625 1.25-1.406 0-.274-.313-1.172-1.25-2.93-.977 1.758-1.25 2.656-1.25 2.93 0 .781.547 1.406 1.25 1.406ZM2.75 7.75a.61.61 0 0 1 .508.313C4 9.313 5.25 11.538 5.25 12.593c0 1.485-1.133 2.657-2.5 2.657-1.406 0-2.5-1.172-2.5-2.656 0-1.055 1.21-3.281 1.953-4.531a.623.623 0 0 1 .547-.313Zm0 6.25C3.414 14 4 13.375 4 12.594c0-.274-.313-1.172-1.25-2.93-.977 1.758-1.25 2.656-1.25 2.93 0 .781.547 1.406 1.25 1.406Zm10.547-9.688c1.328 1.915 4.453 6.641 4.453 8.711 0 2.618-2.266 4.727-5 4.727-2.773 0-5-2.11-5-4.727 0-2.07 3.086-6.796 4.414-8.71A.689.689 0 0 1 12.75 4c.195 0 .43.117.547.313ZM12.75 16.5c2.031 0 3.75-1.563 3.75-3.477 0-1.093-1.484-3.945-3.75-7.343C10.445 9.078 9 11.93 9 13.023c0 1.915 1.68 3.477 3.75 3.477Z"
    />
  </svg>
));
export default SvgIconNewRain;
