import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCreditCard = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={18}
    fill="none"
    viewBox="0 0 24 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M20.75.25c1.367 0 2.5 1.133 2.5 2.5v12.5c0 1.406-1.133 2.5-2.5 2.5H3.25a2.468 2.468 0 0 1-2.5-2.5V2.75c0-1.367 1.094-2.5 2.5-2.5h17.5ZM2.625 2.75V4h18.71V2.75a.642.642 0 0 0-.624-.625H3.25a.642.642 0 0 0-.625.625Zm18.71 12.5.04-7.5H2.625v7.5c0 .352.273.625.625.625h17.46a.617.617 0 0 0 .626-.625ZM5.438 14c-.546 0-.937-.39-.937-.938 0-.507.39-.937.938-.937h1.875c.507 0 .898.43.898.938 0 .546-.39.937-.899.937H5.438Zm5 0c-.546 0-.937-.39-.937-.938 0-.507.39-.937.938-.937h4.374a.95.95 0 0 1 .938.938c0 .546-.43.937-.938.937h-4.374Z"
    />
  </svg>
));
export default SvgIconCreditCard;
