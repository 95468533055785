import {
  Flex,
  Heading,
  BoxProps,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseMutateFunction } from '@tanstack/react-query';

import {
  SetCarPartMutation,
  MutationSetCarPartsArgs,
} from 'api/generated/graphql';
import {
  IconTyreC1,
  IconTyreC2,
  IconTyreC3,
  IconTyreC4,
  IconTyreC5,
  IconTyreInter,
  IconTyreWet,
  IconThumbsUp,
} from 'icons';
import { SVGIcon, Tyres } from 'types';
import { useGetCarParts, useTuningForRace } from 'hooks';
import { useTuning } from 'context';
import { selectedTyreGradient, tyreGradient } from 'utils/themeConstants';

export const TyreCard = ({
  heading,
  subheading,
  icon: Icon,
  isSelected,
  isSuggested,
  ...rest
}: BoxProps & {
  heading: string;
  subheading?: string;
  icon: SVGIcon;
  isSelected: boolean;
  isSuggested?: boolean;
}) => {
  return (
    <GridItem>
      <Flex
        pos="relative"
        h="3.625rem"
        w="full"
        borderWidth="0.094rem"
        borderRadius="0.25rem"
        borderColor={isSelected ? 'firstDaffodil.100' : 'white.10'}
        alignItems="center"
        justifyContent="center"
        bg={isSelected ? selectedTyreGradient : tyreGradient}
        {...rest}
      >
        <Icon w="2.625rem" h="2.625rem" />
        {isSuggested && (
          <IconThumbsUp
            pos="absolute"
            top="0.25rem"
            right="0.25rem"
            w="0.75rem"
            h="0.75rem"
          />
        )}
      </Flex>
    </GridItem>
  );
};

const TyreList = ({
  setSelectedTyres,
  selectedTyres,
  carId,
  mutateCarPart,
  raceId,
}: {
  setSelectedTyres: Dispatch<SetStateAction<Tyres>>;
  selectedTyres: Tyres;
  carId: string;
  mutateCarPart: UseMutateFunction<
    SetCarPartMutation,
    unknown,
    MutationSetCarPartsArgs,
    unknown
  >;
  raceId: string;
}) => {
  const { data: carPartsData, isLoading: isLoadingCarParts } =
    useGetCarParts(carId);

  const { isLoadingTuning } = useTuning();
  const { tuningForRace } = useTuningForRace();
  const { mutateAsync: mutateTuning, isLoading: isLoadingTuningForRace } =
    tuningForRace;

  const [opmitmisticSelectedTyres, setOpmitmisticSelectedTyres] =
    useState<Tyres>(selectedTyres);

  const isLoading =
    isLoadingCarParts || isLoadingTuning || isLoadingTuningForRace;
  const tyres =
    carPartsData &&
    carPartsData.getCarParts.find((part) => part.name === 'Tyres');

  const tyreIconLookup = {
    C1: IconTyreC1,
    C2: IconTyreC2,
    C3: IconTyreC3,
    C4: IconTyreC4,
    C5: IconTyreC5,
    INTER: IconTyreInter,
    WET: IconTyreWet,
  };

  const tyreTitle = {
    C1: 'Super Hard',
    C2: 'Hard',
    C3: 'Medium',
    C4: 'Soft',
    C5: 'Supersoft',
    INTER: 'Intermediate',
    WET: 'Wet Weather',
  };

  const tyreDescription = {
    C1: 'Best for very high temps and no rain, with very low grip.',
    C2: 'Good in high temps and no rain, with low grip.',
    C3: 'Balanced for moderate temps and light rain, with medium grip.',
    C4: 'Good in low temps and light rain, with high grip.',
    C5: 'Best at very low temps or some rain, with very high grip.',
    INTER: 'Good for cool temps and moderate rain, with moderate grip.',
    WET: 'Best in heavy rain, but the car still only has low grip.',
  };

  const getTyreIcon = (TyreId: Tyres) => {
    return tyreIconLookup[TyreId] || <></>;
  };

  const handleChangeTyre = async (tyreId: Tyres) => {
    if (isLoadingTuning) return;
    try {
      await mutateTuning({
        raceTuningInput: {
          raceId,
          tyresId: tyreId,
          carId,
        },
      });
      setOpmitmisticSelectedTyres(tyreId);
    } catch (error) {
      setOpmitmisticSelectedTyres(opmitmisticSelectedTyres);
      console.error(error);
    }
  };

  useEffect(() => {
    setOpmitmisticSelectedTyres(selectedTyres);
  }, [selectedTyres]);

  return (
    <Flex w="full" flexDir="column" gap="0.75rem">
      <Flex w="full" justifyContent="space-between">
        <Heading
          fontSize="0.875rem"
          lineHeight="1.125rem"
          color="white.80"
          fontWeight={400}
        >
          {tyreTitle[opmitmisticSelectedTyres]}
        </Heading>
        <Text
          fontSize="0.75rem"
          lineHeight="0.875rem"
          color="white.60"
          fontWeight={500}
        >
          {tyreDescription[opmitmisticSelectedTyres]}
        </Text>
      </Flex>
      <Grid
        w="full"
        gridAutoFlow="column"
        gridTemplateColumns="repeat(auto-fit, minmax(0, 1fr))"
        gridAutoColumns="minmax(0, 1fr)"
        gap="0.25rem"
        mb="1.5rem"
      >
        {tyres?.carParts
          .sort((a, b) => a.id.localeCompare(b.id)) // Sorting alphabetically for now
          .map((tyre) => {
            return (
              <TyreCard
                // todo: remove array filter and add locked prop when jeremy addes designs for locked state. temporarily filtering out locked parts.
                key={tyre.id}
                heading={tyre.id}
                subheading={tyre.name}
                icon={getTyreIcon(tyre.id as Tyres)}
                isSelected={opmitmisticSelectedTyres === tyre.id}
                onClick={() => handleChangeTyre(tyre.id as Tyres)}
                opacity={isLoading ? 0.8 : 1}
                cursor={isLoading ? 'not-allowed' : 'pointer'}
              />
            );
          })}
      </Grid>
    </Flex>
  );
};

export default TyreList;
