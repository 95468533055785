import {
  AirTemperatureCategory,
  TrackTemperatureCategory,
} from 'api/generated/graphql';

type ColorDetails = {
  defaultColor: string;
  fromColor: string;
  toColor: string;
};

type AirColorsType = {
  [key in AirTemperatureCategory]: ColorDetails;
};
type TrackColorsType = {
  [key in TrackTemperatureCategory]: ColorDetails;
};

type WeatherColorsType = {
  [key: string]: ColorDetails;
};

type Level = {
  [key: string]: 0 | 1 | 2 | 3 | 4 | 5;
};

export const airTemperatureLevel: Level = {
  [AirTemperatureCategory.Cold]: 1,
  [AirTemperatureCategory.Moderate]: 2,
  [AirTemperatureCategory.Warm]: 3,
  [AirTemperatureCategory.Hot]: 4,
  [AirTemperatureCategory.ExtremelyHot]: 5,
};

export const trackTemperatureLevel: Level = {
  [TrackTemperatureCategory.ExtremelyCold]: 0,
  [TrackTemperatureCategory.Cold]: 1,
  [TrackTemperatureCategory.Moderate]: 2,
  [TrackTemperatureCategory.Warm]: 3,
  [TrackTemperatureCategory.Hot]: 4,
  [TrackTemperatureCategory.ExtremelyHot]: 5,
};

export const weatherLevel: Level = {
  dry: 0,
  light: 1,
  soft: 2,
  moderate: 3,
  heavy: 4,
  'very-heavy': 5,
};

export const AirTemperatureColors: AirColorsType = {
  [AirTemperatureCategory.Cold]: {
    defaultColor: 'rgba(121, 226, 242, 1)',
    fromColor: 'rgba(121, 226, 242, 0.1)',
    toColor: 'rgba(121, 226, 242, 0.7)',
  },
  [AirTemperatureCategory.Moderate]: {
    defaultColor: 'rgba(121, 242, 192, 1)',
    fromColor: 'rgba(121, 242, 192, 0.1)',
    toColor: 'rgba(121, 242, 192, 0.7)',
  },
  [AirTemperatureCategory.Warm]: {
    defaultColor: 'rgba(255, 227, 128, 1)',
    fromColor: 'rgba(255, 227, 128, 0.1)',
    toColor: 'rgba(255, 227, 128, 0.7)',
  },
  [AirTemperatureCategory.Hot]: {
    defaultColor: 'rgba(255, 143, 115, 1)',
    fromColor: 'rgba(255, 143, 115, 0.1)',
    toColor: 'rgba(255, 143, 115, 0.7)',
  },
  [AirTemperatureCategory.ExtremelyHot]: {
    defaultColor: 'rgba(216, 56, 50, 1)',
    fromColor: 'rgba(216, 56, 50, 0.1)',
    toColor: 'rgba(216, 56, 50, 0.7)',
  },
};

export const TrackTemperatureColors: TrackColorsType = {
  [TrackTemperatureCategory.ExtremelyCold]: {
    defaultColor: 'rgba(121, 226, 242, 1)',
    fromColor: 'rgba(121, 226, 242, 0.1)',
    toColor: 'rgba(121, 226, 242, 0.7)',
  },
  [TrackTemperatureCategory.Cold]: {
    defaultColor: 'rgba(121, 226, 242, 1)',
    fromColor: 'rgba(121, 226, 242, 0.1)',
    toColor: 'rgba(121, 226, 242, 0.7)',
  },
  [TrackTemperatureCategory.Moderate]: {
    defaultColor: 'rgba(121, 242, 192, 1)',
    fromColor: 'rgba(121, 242, 192, 0.1)',
    toColor: 'rgba(121, 242, 192, 0.7)',
  },
  [TrackTemperatureCategory.Warm]: {
    defaultColor: 'rgba(255, 227, 128, 1)',
    fromColor: 'rgba(255, 227, 128, 0.1)',
    toColor: 'rgba(255, 227, 128, 0.7)',
  },
  [TrackTemperatureCategory.Hot]: {
    defaultColor: 'rgba(255, 143, 115, 1)',
    fromColor: 'rgba(255, 143, 115, 0.1)',
    toColor: 'rgba(255, 143, 115, 0.7)',
  },
  [TrackTemperatureCategory.ExtremelyHot]: {
    defaultColor: 'rgba(216, 56, 50, 1)',
    fromColor: 'rgba(216, 56, 50, 0.1)',
    toColor: 'rgba(216, 56, 50, 0.7)',
  },
};

export const WeatherColors: WeatherColorsType = {
  dry: {
    defaultColor: 'hsla(188, 50%, 90%, 1)',
    fromColor: 'hsla(188, 60%, 80%, 0.1)',
    toColor: 'hsla(188, 60%, 80%, 0.7)',
  },
  light: {
    defaultColor: 'hsla(188, 67%, 78%, 1)',
    fromColor: 'hsla(188, 67%, 78%, 0.1)',
    toColor: 'hsla(188, 67%, 78%, 0.7)',
  },
  soft: {
    defaultColor: 'hsla(188, 82%, 71%, 1)',
    fromColor: 'hsla(188, 82%, 71%, 0.1)',
    toColor: 'hsla(188, 82%, 71%, 0.7)',
  },
  moderate: {
    defaultColor: 'hsla(155, 82%, 71%, 1)',
    fromColor: 'hsla(155, 82%, 71%, 0.1)',
    toColor: 'hsla(155, 82%, 71%, 0.7)',
  },
  heavy: {
    defaultColor: 'hsla(12, 100%, 73%, 1)',
    fromColor: 'hsla(12, 100%, 73%, 0.1)',
    toColor: 'hsla(12, 100%, 73%, 0.7)',
  },
  'very-heavy': {
    defaultColor: 'hsla(12, 100%, 66%, 1)',
    fromColor: 'hsla(12, 100%, 66%, 0.1)',
    toColor: 'hsla(12, 100%, 66%, 0.7)',
  },
};

export const RoughnessColors = {
  'very-smooth': 'black',
  smooth: 'black',
  average: 'black',
  bumpy: 'black',
  'very-bumpy': 'black',
  'severe-bumps': 'black',
};

export const WindColors = {
  calm: 'black',
  breezy: 'black',
  windy: 'black',
  blustery: 'black',
  gale: 'black',
  storm: 'black',
};

export const TarmacConditionColors = {
  dirty: 'black',
  poor: 'black',
  'below-average': 'black',
  washed: 'black',
  good: 'black',
  perfect: 'black',
};
