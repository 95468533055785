import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHourglass = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M2 15.5c.063-1.156.656-4.813 4.594-7.5C2.656 5.312 2.062 1.656 2 .531 1.969.312 2.156 0 2.469 0h.469c.25 0 .468.219.5.469.03.125.03.312.062.531h8.969c.031-.219.062-.406.062-.531.031-.25.25-.469.5-.469h.469c.313 0 .5.281.469.531-.063 1.219-.75 5.438-5.656 8.125a10.648 10.648 0 0 0-2.47 1.875h4.25c-.28-.25-.5-.5-.78-.719.468-.28.874-.562 1.28-.843 2.876 2.562 3.313 5.594 3.376 6.531a.443.443 0 0 1-.469.5h-.469a.564.564 0 0 1-.531-.438c0-.124-.031-.312-.063-.562H3.5c-.031.25-.063.438-.063.563a.505.505 0 0 1-.5.437H2.47c-.313 0-.5-.281-.469-.5Zm10.063-13H3.937c.157.469.407 1 .75 1.5h6.625c.313-.5.563-1.031.75-1.5ZM8 7.156A9.607 9.607 0 0 0 10.094 5.5H5.875A9.12 9.12 0 0 0 8 7.156ZM3.906 13.5h8.125a11.139 11.139 0 0 0-.75-1.5H4.656c-.343.531-.562 1.063-.75 1.5Z"
    />
  </svg>
));
export default SvgIconHourglass;
