export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatPriceNumber = (value: number): string =>
  currencyFormatter.format(value / 100).replace('$', '');

export const getNumericValue = (value: string): number => {
  const cleanedValue = value.replace(/\D+/g, '');
  return parseInt(cleanedValue, 10);
};

export const formatCurrencyString = (value: string): string => {
  const numericValue = getNumericValue(value);

  if (isNaN(numericValue)) return '';
  const formattedValue = currencyFormatter.format(numericValue / 100);
  return formattedValue.replace('$', '$ ');
};

type Listing = {
  id: string;
  status: string;
  endPrice: number;
  price: number;
  endsAt: string;
  startsAt: string;
};

export const getActiveListing = (carData: any): Listing => {
  const listings = (carData?.listings as any[]) || [];
  const activeListing: any[] = listings.filter(
    (listing: { status: string }) => listing.status === 'ongoing'
  );
  return activeListing[0] as Listing;
};
