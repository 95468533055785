import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLiveries = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="m17.5 2.5-1.172-.469c-.039-.039-.078-.078-.078-.156 0-.04.04-.078.078-.117L17.5 1.25l.469-1.133c.039-.078.078-.117.156-.117.04 0 .078.04.117.117l.508 1.133 1.133.508c.078.039.117.078.117.117 0 .078-.04.117-.117.156L18.75 2.5l-.508 1.172c-.039.039-.078.078-.117.078-.078 0-.117-.04-.156-.078L17.5 2.5Zm1.25 6.25 1.133.508c.078.039.117.078.117.117 0 .078-.04.117-.117.156L18.75 10l-.508 1.172c-.039.039-.078.078-.117.078-.078 0-.117-.04-.156-.078L17.5 10l-1.172-.469c-.039-.039-.078-.078-.078-.156 0-.04.04-.117.078-.117L17.5 8.75l.469-1.133c.039-.078.078-.117.156-.117.04 0 .078.04.117.117l.508 1.133Zm-3.867-6.719L13.75 2.5l-.508 1.172c-.039.039-.078.078-.117.078-.078 0-.117-.04-.156-.078L12.5 2.5l-1.172-.469c-.039-.039-.078-.078-.078-.156 0-.04.04-.078.078-.117L12.5 1.25l.469-1.133c.039-.078.078-.117.156-.117.04 0 .078.04.117.117l.508 1.133 1.133.508c.078.039.117.078.117.117 0 .078-.04.117-.117.156Zm2.5 3.75-1.133.469-.508 1.172c-.039.039-.078.078-.117.078-.078 0-.117-.04-.156-.078L15 6.25l-1.172-.469c-.039-.039-.078-.078-.078-.156 0-.04.04-.117.078-.117L15 5l.469-1.133c.039-.078.078-.117.156-.117.04 0 .078.04.117.117L16.25 5l1.133.508c.078.039.117.078.117.117 0 .078-.04.117-.117.156ZM8.75 1.25V5h-5V1.25C3.75.586 4.297 0 5 0h2.5c.664 0 1.25.586 1.25 1.25Zm-2.5 9.375c1.367 0 2.5 1.133 2.5 2.5 0 1.406-1.133 2.5-2.5 2.5a2.468 2.468 0 0 1-2.5-2.5c0-1.367 1.094-2.5 2.5-2.5Zm2.5-4.375c2.07 0 3.75 1.68 3.75 3.75v7.5c0 1.406-1.133 2.5-2.5 2.5H2.5A2.468 2.468 0 0 1 0 17.5V10c0-2.07 1.68-3.75 3.75-3.75h5Zm1.875 10.625V10c0-1.016-.86-1.875-1.875-1.875h-5c-1.055 0-1.875.86-1.875 1.875v6.875c0 .703.547 1.25 1.25 1.25h6.25c.664 0 1.25-.547 1.25-1.25Z"
    />
  </svg>
));
export default SvgIconLiveries;
