import { GraphQLClient } from 'graphql-request';

import { graphql } from 'api/generated';
import { graphqlUrl } from 'client.env';
import { useSessionData } from 'hooks';

const graphQLClient = new GraphQLClient(graphqlUrl);

const useGraphQLClient = () => {
  const { sessionData } = useSessionData();

  graphQLClient.setHeader('authorization', sessionData?.accessToken || '');

  return {
    graphql,
    graphQLClient,
    hasToken: Boolean(sessionData?.accessToken),
  };
};

export default useGraphQLClient;
