import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCup = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={21}
    fill="none"
    viewBox="0 0 24 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M23.094 3.742c0 .117.742 3.32-1.328 6.367-1.446 2.188-3.907 3.711-7.305 4.61-.742.195-1.524.86-1.524 1.601v2.305h3.086c.47 0 .899.43.899.938 0 .546-.39.937-.899.937H7.938c-.547 0-.938-.39-.938-.938 0-.507.39-.937.938-.937h3.125l-.04-2.305c0-.742-.78-1.406-1.523-1.601-3.398-.899-5.86-2.422-7.305-4.61C.125 7.063.867 3.86.867 3.742c.117-.43.508-.742.938-.742H5.75a7.09 7.09 0 0 1 0-1.29C5.75 1.048 6.297.5 7 .5h9.96c.704 0 1.25.547 1.25 1.21.04.392.04.821 0 1.29h3.946c.43 0 .82.313.938.742ZM3.758 9.094c.898 1.367 2.344 2.422 4.258 3.203C6.57 10.03 5.984 7.219 5.75 4.875H2.625c-.04.938.04 2.617 1.133 4.219ZM12 14.25c2.148-2.422 4.375-4.727 4.336-11.875H7.625C7.585 9.523 9.813 11.828 12 14.25Zm8.203-5.156c1.094-1.602 1.172-3.281 1.133-4.219H18.21c-.234 2.344-.82 5.156-2.266 7.422 1.914-.781 3.36-1.836 4.258-3.203Z"
    />
  </svg>
));
export default SvgIconCup;
