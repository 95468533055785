import { colors } from 'utils/themeConstants';

interface RaceChartData {
  data1: unknown;
  data2: unknown;
}

export const getSpiderData = ({ data1, data2 }: RaceChartData) => {
  return {
    labels: [
      'Top speed',
      'Fast Turns',
      'Slow Turns',
      'Braking',
      'Cooling',
      'Handling', //extra spaces helps center the canvas since other label is wider
    ],
    datasets: [
      {
        data: data1,
        backgroundColor: colors.bloodMoon[20],
        borderColor: colors.bloodMoon[100],
        borderWidth: 2,
        pointBackgroundColor: colors.black[100],
        pointBorderColor: colors.bloodMoon[100],
        pointBorderWidth: 2,
        pointRadius: 4,
      },
      {
        data: data2,
        backgroundColor: colors.baltic[20],
        borderColor: colors.baltic[100],
        borderWidth: 2,
        pointBorderColor: colors.baltic[100],
        pointBackgroundColor: colors.black[100],
        pointBorderWidth: 2,
        pointRadius: 4,
      },
    ],
  };
};
