import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSave = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M9 9c1.367 0 2.5 1.133 2.5 2.5 0 1.406-1.133 2.5-2.5 2.5a2.468 2.468 0 0 1-2.5-2.5C6.5 10.133 7.594 9 9 9Zm8.164-4.922c.352.352.586.86.586 1.328v9.844c0 1.406-1.133 2.5-2.5 2.5H2.75a2.468 2.468 0 0 1-2.5-2.5V2.75c0-1.367 1.094-2.5 2.5-2.5h9.844c.469 0 .976.234 1.289.547l3.281 3.281ZM5.25 2.125V5.25h5.625V2.125H5.25ZM15.875 15.25V5.523c0-.078-.04-.117-.078-.156L12.75 2.281v3.906c0 .547-.43.938-.938.938h-7.5c-.546 0-.937-.39-.937-.938V2.125H2.75a.642.642 0 0 0-.625.625v12.5c0 .352.273.625.625.625h12.5a.642.642 0 0 0 .625-.625Z"
    />
  </svg>
));
export default SvgIconSave;
