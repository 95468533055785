/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { BalanceComponent } from 'components/Balance/BalanceComponent';
import { WalletModal } from './WalletModal/WalletModal';
import { RInput } from 'components';
import { useState, useEffect } from 'react';
import {
  formatCurrencyString,
  getNumericValue,
} from 'components/Marketplace/utils/helpers';
import { WalletPopover } from './subcomponents/WalletPopover';
import { PriceLabel } from 'components/Balance/PriceLabel';
import { useFunds } from 'context/fundsContext';
import Flags from 'components/flags';

export const BuyTab = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [valueInCents, setValueInCents] = useState<number>(0);
  const [inputDisplay, setInputDisplay] = useState('');

  const [cryptoValueToReceive, setCryptoValueToReceive] = useState('');

  const { actualTokenSelected, balanceData } = useFunds();

  const onChangeCurrencyInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const usdValue = getNumericValue(e.target.value);
    setValueInCents(usdValue);

    const formattedValue = formatCurrencyString(e.target.value);
    setInputDisplay(formattedValue);
  };

  useEffect(() => {
    const cryptoValueToBuy =
      valueInCents /
      100 /
      Number(balanceData[actualTokenSelected].convertionData.ask);

    if (!isNaN(cryptoValueToBuy)) {
      setCryptoValueToReceive(cryptoValueToBuy.toFixed(2));
    } else {
      setCryptoValueToReceive('0.00');
    }
  }, [balanceData, actualTokenSelected, valueInCents]);

  return (
    <Flex flexDir={'column'} gap={6} pt={4}>
      <WalletModal
        isOpen={isOpen}
        onClose={onClose}
        token={actualTokenSelected}
        fiatAmount={valueInCents / 100}
      />

      <WalletPopover />

      <BalanceComponent />

      <Stack w="full" gap={2}>
        <Heading
          fontWeight={'normal'}
          textTransform={'uppercase'}
          fontSize={'14px'}
          my={2}
        >
          Amount to spend
        </Heading>

        <HStack h="fit-content" align={'flex-start'}>
          <RInput
            label="You pay"
            value={inputDisplay}
            onChange={onChangeCurrencyInput}
            maxLength={11}
          />
          <HStack bg="whiteAlpha.200" minW="100px" h="54px" px={4}>
            <Flags countryCode="US" />
            <Heading
              fontWeight={'normal'}
              fontSize={'14px'}
              color="whiteAlpha.700"
              w="fit-content"
            >
              USD
            </Heading>
          </HStack>
        </HStack>
        <Button variant="tertiary" onClick={onOpen}>
          Buy {actualTokenSelected}
        </Button>
      </Stack>

      <HStack justify="space-between" w="full" px="8">
        <Heading
          fontWeight={'normal'}
          fontSize={'14px'}
          color="whiteAlpha.700"
          fontFamily={'body'}
        >
          You'll receive approx.
        </Heading>

        {cryptoValueToReceive !== '' && (
          <PriceLabel
            value={cryptoValueToReceive}
            currency={balanceData[actualTokenSelected].currency}
            currencySize="16px"
            numberSize="16px"
            iconSize="16px"
            icon={balanceData[actualTokenSelected].icon}
            color={balanceData[actualTokenSelected].color}
          />
        )}
      </HStack>
    </Flex>
  );
};
