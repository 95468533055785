import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

const useScheduleCarUpgradeDiscount = () => {
  const queryClient = useQueryClient();
  const { graphql, graphQLClient } = useGraphQLClient();

  const scheduleCarUpgradeGQL = graphql(`
    mutation scheduleCarUpgradeDiscount($tokenId: String!) {
      scheduleCarUpgradeDiscount(tokenId: $tokenId) {
        id
        externalWalletAddress
        tokenId
        status
        referenceId
        createdAt
        source
      }
    }
  `);

  const ScheduleCarUpgradeDiscount = useMutation({
    mutationKey: ['scheduleCarUpgradeDiscount'],
    mutationFn: async ({ tokenId }: { tokenId: string }) => {
      return graphQLClient.request(scheduleCarUpgradeGQL, { tokenId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['garageQuery'] });
    },
  });

  return ScheduleCarUpgradeDiscount;
};

export default useScheduleCarUpgradeDiscount;
