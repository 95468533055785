import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuningLow = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    viewBox="0 0 10 10"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M7.344 3.36 4.204 6.5H6.5c.266 0 .5.234.5.5 0 .281-.234.5-.5.5H3a.494.494 0 0 1-.5-.5V3.5c0-.266.219-.5.5-.5.266 0 .5.234.5.5v2.297l3.14-3.14a.482.482 0 0 1 .704 0 .482.482 0 0 1 0 .702Z"
    />
  </svg>
));
export default SvgIconTuningLow;
