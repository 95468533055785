/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { CrossmintEvent } from '@crossmint/client-sdk-react-ui';

import { RCrossmintPayment } from 'components';
import { useTicketPaymentProcessSucceeded } from 'hooks';
import { IconTicket } from 'icons';
import { CurrencyType } from 'api/generated/graphql';
import { useQueryClient } from '@tanstack/react-query';

interface BuyTicketsProps {
  currency?: CurrencyType;
  handleNextTab: () => void;
  numberOfTickets: number;
}

const paymentEnv = process.env.NEXT_PUBLIC_ENV === 'dev' ? 'staging' : 'production';
const ticketsContractId = process.env.NEXT_PUBLIC_TICKETS_CONTRACT_ID || '';
const projectId = process.env.NEXT_PUBLIC_CROSSMINT_API_ID || '';

const BuyTickets = ({
  currency = CurrencyType.Usd,
  handleNextTab,
  numberOfTickets,
}: BuyTicketsProps) => {
  const queryClient = useQueryClient();
  const mutation = useTicketPaymentProcessSucceeded();
  const [isLoading, setIsLoading] = useState(false);

  const onEvent = async (event: CrossmintEvent) => {
    const paymentStatus = event.type;

    if (
      paymentStatus === 'payment:process.started' ||
      paymentStatus === 'order:process.started'
    ) {
      setIsLoading(true);
    }

    if (
      paymentStatus === 'payment:process.rejected' ||
      paymentStatus === 'payment:process.canceled' ||
      paymentStatus === 'payment:preparation.failed' ||
      paymentStatus === 'transaction:fulfillment.failed'
    ) {
      setIsLoading(false);
    }

    if (
      paymentStatus === 'payment:process.succeeded' ||
      paymentStatus === 'order:process.finished' ||
      paymentStatus === 'transaction:fulfillment.succeeded'
    ) {
      await mutation.mutateAsync({
        succeededInput: {
          // @ts-ignore
          orderIdentifier: event.payload.orderIdentifier,
          currency,
        },
      });

      queryClient.refetchQueries({
        queryKey: ['ticketsAvailable'],
      });
      setIsLoading(false);
    }
  };

  return (
    <Flex flexDirection="column" width="full" minHeight="60vh">
      <RCrossmintPayment
        collectionId={ticketsContractId}
        projectId={projectId}
        environment={paymentEnv}
        bundleId="1"
        totalPrice={String(numberOfTickets * 5)}
        type="erc-1155"
        SuccessIcon={IconTicket}
        quantity={numberOfTickets}
        isLoading={isLoading}
        onEvent={onEvent}
        handleContinue={handleNextTab}
      />
    </Flex>
  );
};

export default BuyTickets;
