import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLock = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M9.75 4.5c.82 0 1.5.68 1.5 1.5v4.5c0 .82-.68 1.5-1.5 1.5h-7.5c-.844 0-1.5-.68-1.5-1.5V6c0-.82.656-1.5 1.5-1.5H3V3c0-1.64 1.336-3 3-3 1.64 0 3 1.336 3 3v1.5h.75ZM4.125 3v1.5h3.75V3A1.88 1.88 0 0 0 6 1.125 1.866 1.866 0 0 0 4.125 3Zm6 7.5V6a.403.403 0 0 0-.375-.375h-7.5A.385.385 0 0 0 1.875 6v4.5c0 .21.164.375.375.375h7.5a.385.385 0 0 0 .375-.375Z"
    />
  </svg>
));
export default SvgIconLock;
