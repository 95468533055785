import { BoxProps, Flex, Box, useStyleConfig, Text, Skeleton } from '@chakra-ui/react';

import { Races } from 'api/generated/graphql';
import {
  RBaseCard,
  RBaseCardTopLeft,
  RBaseCardBottomLeft,
  RBaseCardBottomRight,
  RBaseCardGradient,
  RPrizePool,
  RTicket,
  RTiers,
  RPlacingPosition,
} from 'components';
import { RaceTier } from 'types';
import {
  IconAustinMinimap,
  IconBarcelonaMinimap,
  IconBudapestMinimap,
  IconImolaMinimap,
  IconLusailMinimap,
  IconMelbourneMinimap,
  IconMontrealMinimap,
  IconSakhirMinimap,
  IconSilverstoneMinimap,
  IconSpaMinimap,
  IconSpielbergMinimap,
  IconSuzukaMinimap,
  IconZandvoortMinimap,
} from 'icons';
import { useRaceCountdown, useSessionData } from 'hooks';
import { useResults, useRootContext } from 'context';
import IconCornerFlag from './IconCornerFlag';
import { useState } from 'react';
import PlacingResults from 'components/PlacingResults/PlacingResults';

export interface RaceCardProps {
  race: Races;
  variant?: string;
  isSelected?: boolean;
  size?: 'md' | 'lg';
}

export const RacesBackground: Record<string, any> = {
  Belgium: require(`public/images/track-backgrounds/spa.png`),
  Spain: require(`public/images/track-backgrounds/spain.jpeg`),
  'United Kingdom': require(`public/images/track-backgrounds/silverstone.jpeg`),
  Netherlands: require(`public/images/track-backgrounds/netherlands.jpeg`),
  USA: require(`public/images/track-backgrounds/usa.jpg`),
  Italy: require(`public/images/track-backgrounds/italia.jpeg`),
  Bahrain: require(`public/images/track-backgrounds/bahrain.jpg`),
  Qatar: require(`public/images/track-backgrounds/spa.png`),
  Hungary: require(`public/images/track-backgrounds/spa.png`),
  Austria: require(`public/images/track-backgrounds/austria.jpg`),
  Canada: require(`public/images/track-backgrounds/canada.jpg`),
  Japan: require(`public/images/track-backgrounds/japan.jpg`),
  Australia: require(`public/images/track-backgrounds/australia.jpeg`),
};

type RaceMinimapType = {
  [key: string]: React.ElementType;
};

export const RaceMinimap: RaceMinimapType = {
  Belgium: IconSpaMinimap,
  Spain: IconBarcelonaMinimap,
  'United Kingdom': IconSilverstoneMinimap,
  Netherlands: IconZandvoortMinimap,
  USA: IconAustinMinimap,
  Italy: IconImolaMinimap,
  Bahrain: IconSakhirMinimap,
  Qatar: IconLusailMinimap,
  Hungary: IconBudapestMinimap,
  Austria: IconSpielbergMinimap,
  Canada: IconMontrealMinimap,
  Japan: IconSuzukaMinimap,
  Australia: IconMelbourneMinimap,
};

const RaceCard = ({
  race,
  variant,
  size = 'lg',
  onClick,
  ...rest
}: BoxProps & RaceCardProps) => {
  const { showAllResults } = useResults();
  const { sessionData } = useSessionData();
  const { isDebug } = useRootContext();
  const styles = useStyleConfig('RaceCard', { variant });
  const textShadow = '2px 2px 8px black';
  const trackName = race?.track?.frontendName || '';
  const TrackMinimapIcon =
    trackName && trackName in RaceMinimap ? RaceMinimap[trackName] : null;

  const { raceStatus } = useRaceCountdown({
    currentStatus: race?.status,
    startTime: race?.startTime,
  });

  const getBottomLeftInfo = () => {
    switch (raceStatus) {
      case 'ended': {
        const currentPlayer = race.playersParticipants.find((player) => {
          if (!player.user) {
            return null;
          }

          return player.user.email === sessionData?.userAttributes?.email;
        });

        if (currentPlayer?.user?.playerName && currentPlayer?.position) {
          return <PlacingResults 
          playerName={currentPlayer?.user?.playerName} 
          alreadyOpen={showAllResults} 
          playerPosition={currentPlayer?.position}
          />;
        }

        return (
          <RTicket
            numberOfTickets={race?.numberOfTickets}
            currency={race?.currency}
            textShadow={textShadow}
          />
        );
      }

      default:
        return (
          <RTicket
            numberOfTickets={race?.numberOfTickets}
            currency={race?.currency}
            textShadow={textShadow}
          />
        );
    }
  };

  return (
    <>
      {isDebug && (
        <>
          <Text>{race.id}</Text>
          <Text>{race.status}</Text>
          <Text>{race.startTime}</Text>
        </>
      )}

      <RBaseCard
        onClick={onClick}
        backgroundSrc={RacesBackground[race?.track?.frontendName]}
        cursor="pointer"
        __css={styles}
        {...rest}
      >
        <IconCornerFlag isVisible={variant === 'userInRace'} />

        <RBaseCardTopLeft
          heading={race?.track?.frontendName}
          race={race}
          variant={variant}
          raceStatus={raceStatus}
          headingProps={{
            textShadow,
            size: size,
            px: 2,
            // move left to account for additional padding
            ml: -2,
          }}
        >
          <Flex gap="2" alignItems="center">
            <RTiers
              size={variant === 'garage' ? 'sm' : 'md'}
              tier={race.tier?.name as RaceTier}
              tierType="driver"
              showTitle
              inlineTitle
            />
          </Flex>
        </RBaseCardTopLeft>

        {trackName && TrackMinimapIcon && (
          <Box
            pos="absolute"
            w={['6.5rem', '10.5rem']}
            h={['6.5rem', '10.5rem']}
            top="0.25rem"
            right="0.25rem"
            zIndex={0}
          >
            <TrackMinimapIcon w="full" h="full" />
          </Box>
        )}

        <RBaseCardBottomRight zIndex={4}>
          <RPrizePool
            raceId={race?.id}
            size={variant === 'garage' ? 'sm' : 'lg'}
            text={
              race?.distributionStatus === 'pending'
                ? 'Payout Pending'
                : race?.distributionStatus === 'distributed'
                ? 'Earnings'
                : variant === 'garage'
                ? 'Earnings'
                : 'Prize Pool'
            }
            poolAmount={race?.prizePool}
            currency={race?.currency}
            textShadow={textShadow}
          />
        </RBaseCardBottomRight>

        <RBaseCardBottomLeft zIndex={4}>
          {getBottomLeftInfo()}
        </RBaseCardBottomLeft>

        {/* TODO: base gradient color on race tier */}
        <RBaseCardGradient
          variant="gradientToTransparent"
          gradientColor="orange"
          hasInsetBorder
        />
      </RBaseCard>
    </>
  );
};

export default RaceCard;
