import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBumpyRoad = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={20}
    fill="none"
    viewBox="0 0 24 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M4.667 2.305 10.409.78a3.802 3.802 0 0 1 4.023 1.406l2.149 2.93c.976.117 1.836.82 2.11 1.836l1.796 6.64a1.323 1.323 0 0 1-.898 1.563l-1.211.313a1.28 1.28 0 0 1-1.524-.899l-.468-1.797-10.86 2.891.469 1.836c.195.664-.235 1.328-.899 1.523l-1.21.313c-.665.195-1.329-.195-1.524-.86l-1.758-6.64c-.273-1.055.117-2.07.899-2.656l.39-3.633a3.858 3.858 0 0 1 2.774-3.242Zm.664 2.422c-.508.117-.86.546-.938 1.093L4.12 8.281l9.765-2.617-1.484-1.992c-.312-.43-.82-.625-1.328-.469L5.331 4.727Zm-.469 8.554a1.323 1.323 0 0 0 .898-1.562 1.266 1.266 0 0 0-1.562-.86c-.664.157-1.055.86-.86 1.524.157.664.86 1.055 1.524.898ZM15.096 7.93c-.664.195-1.093.86-.898 1.523.195.664.86 1.094 1.523.899a1.28 1.28 0 0 0 .899-1.524 1.28 1.28 0 0 0-1.524-.898ZM16.698 20c-.742 0-1.328-.664-.977-1.367a4.343 4.343 0 0 1 3.868-2.383c1.718 0 3.203.977 3.906 2.383.351.703-.235 1.367-1.016 1.367h-5.781Z"
    />
  </svg>
));
export default SvgIconBumpyRoad;
