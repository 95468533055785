import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';
import { MutationSetUserSettingsArgs } from 'api/generated/graphql';

const useSetUserSettings = (rest?: { [key: string]: unknown }) => {
  const { graphql, graphQLClient } = useGraphQLClient();
  const queryClient = useQueryClient();

  const setUserSettingsGQL = graphql(`
    mutation SetUserSettings($userSettingsInput: UserSettingsInput!) {
      setUserSettings(userSettingsInput: $userSettingsInput) {
        id
        raceStartingBrowserNotification
        raceStartingEmailNotification
        marketplaceBrowserNotification
        marketplaceEmailNotification
      }
    }
  `);

  const setUserSettings = useMutation({
    mutationKey: ['setUserSettings'],
    mutationFn: ({ userSettingsInput }: MutationSetUserSettingsArgs) => {
      return graphQLClient.request(setUserSettingsGQL, {
        userSettingsInput,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userSettingsQuery'] });
    },
    ...rest,
  });

  return setUserSettings;
};

export default useSetUserSettings;
