import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSakhirMinimapMarkers = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={375}
    height={375}
    fill="none"
    viewBox="0 0 375 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M42.31 154.748c-1.623 11.112-5.568 38.965-7.338 51.502-1.117 6.704-.681 12.317-.318 14.324.004.024.01.044.017.067 2.087 6.153 6.55 19.169 7.796 22.285 1.25 3.121 0 5.358-.78 6.086L28.57 263.995c-2.186 2.497-.469 5.306 4.372 5.774 2.082.202 54.194.571 113.981.624 79.198.071 171.747-.223 178.153-.312 11.242-.156 19.518-6.331 23.577-8.271 3.592-1.717 4.06-3.278 4.06-4.838 0-1.561-2.878-6.144-3.747-7.648-4.06-7.023-14.365-24.034-17.332-28.872-2.966-4.838-28.573-46.196-33.413-54.311-6.121-10.262-40.909-66.329-42.47-68.826s-8.9-17.791-17.956-18.572c-7.245-.624-13.896 7.023-17.331 13.266-2.29 4.994-3.623 7.585-6.87 14.826-3.248 7.242-3.904 7.804-6.246 14.983-3.028 9.28-.274 20.6 4.997 28.716 5.777 8.896 16.349 12.423 26.543 15.607 5.687 1.776 10.525 3.58 14.677 6.554 7.495 5.37 10.93 10.145 14.053 16.856.872 1.876 1.394 4.399 1.873 6.086 2.028 7.134.695 12.018-.937 16.231-1.873 4.838-8.067 5.411-11.242 5.463-8.067-.104-27.574-.156-37.941-.156-12.96 0-96.806.156-102.427.156-4.996 0-6.402-.055-12.335-.469-11.398-.794-27.543-2.902-29.666-3.277-2.654-.468-3.123-3.173-.937-6.087 1.73-2.304 2.374-3.571 5.777-7.179 2.03-2.341 4.372-4.994 7.183-6.398 4.24-2.119 8.145-2.927 13.427-2.654 5.92.306 13.599 1.387 16.239 1.717 4.996.624 52.931 6.243 59.801 6.867 6.87.624 10.929-2.809 11.242-5.462.25-2.123-1.666-4.11-2.655-4.838-12.491-9.364-37.454-27.04-41.064-29.965-5.777-4.682-7.789-9.676-7.495-17.168.313-7.959 1.891-9.872.781-17.635-.937-6.555-5.74-9.92-11.086-12.173-2.187-.922-4.986-1.785-6.87-2.341-6.87-2.029-10.064-5.775-12.023-7.492-3.383-2.965-10.148-9.02-13.271-12.017-3.904-3.745-17.175-18.884-19.986-22.317-1.61-1.967-4.528-5.15-11.242-5.15-3.423 0-5.57 1.547-6.714 3.433-1.704 2.81-1.405 7.179-2.498 12.173-1.191 5.442-9.212 61.959-11.242 75.849Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={10}
      d="M42.31 154.748c-1.623 11.112-5.568 38.965-7.338 51.502-1.124 6.742-.676 12.381-.312 14.358 2.082 6.139 6.558 19.196 7.807 22.318 1.25 3.121 0 5.358-.78 6.086L28.57 263.995c-2.186 2.497-.469 5.306 4.372 5.774 2.082.202 54.194.571 113.981.624 79.198.071 171.747-.223 178.153-.312 11.242-.156 19.518-6.331 23.577-8.271 3.592-1.717 4.06-3.278 4.06-4.838 0-1.561-2.878-6.144-3.747-7.648-4.06-7.023-14.365-24.034-17.332-28.872-2.966-4.838-28.573-46.196-33.413-54.311-6.121-10.262-40.909-66.329-42.47-68.826s-8.9-17.791-17.956-18.572c-7.245-.624-13.896 7.023-17.331 13.266-2.29 4.994-3.623 7.585-6.87 14.826-3.248 7.242-3.904 7.804-6.246 14.983-3.028 9.28-.274 20.6 4.997 28.716 5.777 8.896 16.349 12.423 26.543 15.607 5.687 1.776 10.525 3.58 14.677 6.554 7.495 5.37 10.93 10.145 14.053 16.856.872 1.876 1.394 4.399 1.873 6.086 2.028 7.134.695 12.018-.937 16.231-1.873 4.838-8.067 5.411-11.242 5.463-8.067-.104-27.574-.156-37.941-.156-12.96 0-96.806.156-102.427.156-4.996 0-6.402-.055-12.335-.469-11.398-.794-27.543-2.902-29.666-3.277-2.654-.468-3.123-3.173-.937-6.087 1.73-2.304 2.374-3.571 5.777-7.179 2.03-2.341 4.372-4.994 7.183-6.398 4.24-2.119 8.145-2.927 13.427-2.654 5.92.306 13.599 1.387 16.239 1.717 4.996.624 52.931 6.243 59.801 6.867 6.87.624 10.929-2.809 11.242-5.462.25-2.123-1.666-4.11-2.655-4.838-12.491-9.364-37.454-27.04-41.064-29.965-5.777-4.682-7.789-9.676-7.495-17.168.313-7.959 1.891-9.872.781-17.635-.937-6.555-5.74-9.92-11.086-12.173-2.187-.922-4.986-1.785-6.87-2.341-6.87-2.029-10.064-5.775-12.023-7.492-3.383-2.965-10.148-9.02-13.271-12.017-3.904-3.745-17.175-18.884-19.986-22.317-1.61-1.967-4.528-5.15-11.242-5.15-3.423 0-5.57 1.547-6.714 3.433-1.704 2.81-1.405 7.179-2.498 12.173-1.191 5.442-9.212 61.959-11.242 75.849Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={4}
      d="M42.31 154.748c-1.623 11.112-5.568 38.965-7.338 51.502-1.124 6.742-.676 12.381-.312 14.358 2.082 6.139 6.558 19.196 7.807 22.318 1.25 3.121 0 5.358-.78 6.086L28.57 263.995c-2.186 2.497-.469 5.306 4.372 5.774 2.082.202 54.194.571 113.981.624 79.198.071 171.747-.223 178.153-.312 11.242-.156 19.518-6.331 23.577-8.271 3.592-1.717 4.06-3.278 4.06-4.838 0-1.561-2.878-6.144-3.747-7.648-4.06-7.023-14.365-24.034-17.332-28.872-2.966-4.838-28.573-46.196-33.413-54.311-6.121-10.262-40.909-66.329-42.47-68.826s-8.9-17.791-17.956-18.572c-7.245-.624-13.896 7.023-17.331 13.266-2.29 4.994-3.623 7.585-6.87 14.826-3.248 7.242-3.904 7.804-6.246 14.983-3.028 9.28-.274 20.6 4.997 28.716 5.777 8.896 16.349 12.423 26.543 15.607 5.687 1.776 10.525 3.58 14.677 6.554 7.495 5.37 10.93 10.145 14.053 16.856.872 1.876 1.394 4.399 1.873 6.086 2.028 7.134.695 12.018-.937 16.231-1.873 4.838-8.067 5.411-11.242 5.463-8.067-.104-27.574-.156-37.941-.156-12.96 0-96.806.156-102.427.156-4.996 0-6.402-.055-12.335-.469-11.398-.794-27.543-2.902-29.666-3.277-2.654-.468-3.123-3.173-.937-6.087 1.73-2.304 2.374-3.571 5.777-7.179 2.03-2.341 4.372-4.994 7.183-6.398 4.24-2.119 8.145-2.927 13.427-2.654 5.92.306 13.599 1.387 16.239 1.717 4.996.624 52.931 6.243 59.801 6.867 6.87.624 10.929-2.809 11.242-5.462.25-2.123-1.666-4.11-2.655-4.838-12.491-9.364-37.454-27.04-41.064-29.965-5.777-4.682-7.789-9.676-7.495-17.168.313-7.959 1.891-9.872.781-17.635-.937-6.555-5.74-9.92-11.086-12.173-2.187-.922-4.986-1.785-6.87-2.341-6.87-2.029-10.064-5.775-12.023-7.492-3.383-2.965-10.148-9.02-13.271-12.017-3.904-3.745-17.175-18.884-19.986-22.317-1.61-1.967-4.528-5.15-11.242-5.15-3.423 0-5.57 1.547-6.714 3.433-1.704 2.81-1.405 7.179-2.498 12.173-1.191 5.442-9.212 61.959-11.242 75.849Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={6.537}
      y={271.295}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m16.764 279.371-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={50.812}
      y={238.015}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m58.217 251.251 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252H64v1.188h-5.784v-1.764Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={6.537}
      y={207.112}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M13.724 220.876v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236H14.96l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={43.383}
      y={34.469}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M54.49 47.861h-4.08v-1.572l3.685-5.22h1.788v5.592h1.02v1.2h-1.02v1.608H54.49v-1.608Zm.049-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={93.007}
      y={112.322}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M100.296 126.086v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={145.305}
      y={109.35}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M152.614 123.114v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={113.807}
      y={150.951}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M125.368 158.979v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936h-1.464l3.372-6.972Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={194.631}
      y={186.311}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M201.787 200.075v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={69.235}
      y={166.402}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M76.576 180.166v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={45.761}
      y={197.9}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m52.001 205.976-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.263 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736L60.22 212.9h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={276.347}
      y={196.711}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m284.205 204.787-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.738 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={181.854}
      y={145.602}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m188.558 153.678-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.07 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={213.649}
      y={51.109}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m220.183 59.185-1.836.948V58.85l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456V62.93l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={348.257}
      y={266.541}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m354.931 274.617-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={312.302}
      y={276.941}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m318.818 285.017-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.142 5.688v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M178.073 249.901h4.028v4.526h-4.028zM178.073 258.952h4.028v4.526h-4.028zM178.073 268.004h4.028v4.526h-4.028zM178.073 277.056h4.028v4.526h-4.028zM178.073 286.107h4.028v4.526h-4.028zM182.101 254.427h4.028v4.526h-4.028zM182.101 263.478h4.028v4.526h-4.028zM182.101 272.53h4.028v4.526h-4.028zM182.101 281.581h4.028v4.526h-4.028z"
    />
    <path
      fill="#000"
      d="M182.101 249.901h4.028v4.526h-4.028zM182.101 258.952h4.028v4.526h-4.028zM182.101 268.004h4.028v4.526h-4.028zM182.101 277.056h4.028v4.526h-4.028zM182.101 286.107h4.028v4.526h-4.028zM178.073 254.427h4.028v4.526h-4.028zM178.073 263.478h4.028v4.526h-4.028zM178.073 272.53h4.028v4.526h-4.028zM178.073 281.581h4.028v4.526h-4.028z"
    />
    <path
      fill="#E2645A"
      d="m152.14 296.283 14.82 9.856v-19.761l-14.82 9.905Zm-2.263 0c0-.728.299-1.408.897-1.797l15.545-10.341c1.196-.826 2.691.145 2.691 1.748v20.78c0 1.554-1.495 2.573-2.691 1.797l-15.545-10.391a2.207 2.207 0 0 1-.897-1.796Z"
    />
  </svg>
));
export default SvgIconSakhirMinimapMarkers;
