import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { IconWarningOutline } from 'icons';

const BlockPaidRacesModal = ({
  isOpen = false,
  onClose = () => null,
}: UseDisclosureProps) => {
  return (
    <Modal isOpen={isOpen} onClose={() => null} variant="darkGrey" isCentered>
      <ModalOverlay bg="black.90" />
      <ModalContent p={0}>
        <ModalBody px={4} py={3} bg="black.100" flexDir="column">
          <Heading
            display="flex"
            fontSize="lg"
            fontWeight={400}
            color="paleSlate.100"
          >
            <IconWarningOutline
              width="1.25rem"
              height="1.25rem"
              mr="0.5rem"
              __css={{
                path: { fill: 'paleSlate.100' },
              }}
            />
            Location Restriction
          </Heading>
          <Text fontSize="sm" color="paleSlate.100">
            Our full gaming services are not available in your region due to
            legal limitations, but you can still enjoy other parts of our
            platform as we work to expand access.
          </Text>
          <Text fontSize="sm" color="paleSlate.100">
            Stay tuned for updates and thank you for your patience.
          </Text>
          <Button w="full" mt="1rem" onClick={onClose}>
            I Understand
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BlockPaidRacesModal;
