import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuningSpeed = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    viewBox="0 0 13 13"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="m10.576 7.586-5.977 5.226a.781.781 0 0 1-.516.188.722.722 0 0 1-.445-.14.728.728 0 0 1-.258-.891L5.185 7.75H2.584c-.329 0-.61-.188-.704-.469a.753.753 0 0 1 .188-.843l6-5.227c.258-.234.633-.234.914-.024.305.188.398.563.258.891L7.435 6.273h2.625c.305 0 .586.211.703.493a.726.726 0 0 1-.187.82Z"
    />
  </svg>
));
export default SvgIconTuningSpeed;
