import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

export const refreshToken = async ({
  email,
  refreshToken,
}: {
  refreshToken: string;
  email: string;
}) => {
  if (!refreshToken) {
    return; // Might want to throw an error or return a specific object indicating failure
  }

  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: process.env.USER_POOL_ID || '',
    ClientId: process.env.USER_POOL_CLIENT_ID || '',
  });

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  const parsedRefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: refreshToken,
  });

  try {
    const session: AmazonCognitoIdentity.CognitoUserSession = await new Promise(
      (resolve, reject) => {
        cognitoUser.refreshSession(
          parsedRefreshToken,
          (err, sessionResult: AmazonCognitoIdentity.CognitoUserSession) => {
            if (err) {
              reject(err);
            } else {
              resolve(sessionResult);
            }
          }
        );
      }
    );

    const updatedRefreshToken = session.getRefreshToken().getToken();
    const updatedAccessToken = session.getAccessToken().getJwtToken();

    return {
      refreshToken: updatedRefreshToken,
      accessToken: updatedAccessToken,
    };
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error; // rethrow the error after logging, or handle it as needed
  }
};
