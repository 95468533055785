import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBarcelonaMinimapMarkers = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={376}
      height={375}
      fill="none"
      viewBox="0 0 376 375"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#000"
        fillOpacity={0.2}
        d="M56.304 234.301c1.782 3.447 6.091 5.646 9.063 5.794 88.252-.297 266.274-.832 274.118-.594 9.806.297 12.332-1.783 18.869-6.389 5.23-3.684 7.33-11.539 7.726-15.006.198-12.777.565-39.609.446-44.72-.149-6.389-2.823-11.292-6.24-14.709-3.715-3.715-9.361-5.646-15.601-7.726-4.636-1.546-10.258-3.163-17.383-5.646-4.613-1.608-10.069-3.666-13.223-4.308-8.023-1.635-12.777 3.12-15.154 6.388-2.378 3.269-4.458 10.549 0 17.235 2.674 4.16 7.428 5.943 11.143 6.834 2.971.713 10.449 3.467 13.817 4.754 15.1 6.472 18.126 19.15 18.126 25.258 0 5.2-.743 8.023-4.903 10.846-4.459 3.025-9.652.412-11.737-.892-4.755-2.971-22.732-13.571-26.446-15.748-4.309-2.526-78.893-46.95-85.876-50.664-5.794-3.293-9.955-4.754-13.52-5.349-3.269-.544-6.241.05-7.875.297-11.589 2.972-14.263 9.188-18.869 15.749-7.23 10.301-21.256 29.836-23.623 34.321-2.971 6.388-2.674 9.954-2.526 13.966.119 3.209-.049 5.992-.148 6.983-1.07 5.348-6.785 7.379-9.509 7.726-4.408.099-15.838.237-26.297 0-13.075-.298-22.88-5.646-27.338-8.469-4.457-2.823-25.406-17.383-26.743-18.126-1.288-.644-4.28-2.407-5.943-4.309-1.664-1.902-2.08-3.665-2.08-4.308-.297-4.903.891-6.278 2.674-8.172 2.377-2.526 5.943-2.823 8.023-2.823 2.972.149 43.681.149 47.841-.148 3.328-.238 6.24-1.189 7.28-1.635 9.509-3.268 13.718-9.827 14.709-11.589 1.323-2.352 2.355-5.578 2.52-7.516.004-.041.011-.08.016-.121.057-.437.17-2.974-.308-6.031-.743-4.755-4.932-8.212-7.428-9.658-2.823-1.634-6.538-1.634-7.429-1.634-.713 0-6.537.099-9.657.149H59.721c-8.766 0-17 1.607-22.286 3.565-8.023 2.972-16.344 6.835-24.218 17.086-7.56 9.842-6.24 20.949-4.605 26.149 3.714 10.252 10.102 15.452 16.937 18.424 4.011 1.584 12.153 4.902 16.789 6.685 5.794 2.229 9.1 4.803 11.142 9.361 1.932 4.308 1.486 4.754 1.486 8.914 0 2.975-.891 5.497 1.338 9.806Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.2}
        strokeWidth={6}
        d="M56.304 234.301c1.782 3.447 6.091 5.646 9.063 5.794 88.252-.297 266.274-.832 274.118-.594 9.806.297 12.332-1.783 18.869-6.389 5.23-3.684 7.33-11.539 7.726-15.006.198-12.777.565-39.609.446-44.72-.149-6.389-2.823-11.292-6.24-14.709-3.715-3.715-9.361-5.646-15.601-7.726-4.636-1.546-10.258-3.163-17.383-5.646-4.613-1.608-10.069-3.666-13.223-4.308-8.023-1.635-12.777 3.12-15.154 6.388-2.378 3.269-4.458 10.549 0 17.235 2.674 4.16 7.428 5.943 11.143 6.834 2.971.713 10.449 3.467 13.817 4.754 15.1 6.472 18.126 19.15 18.126 25.258 0 5.2-.743 8.023-4.903 10.846-4.459 3.025-9.652.412-11.737-.892-4.755-2.971-22.732-13.571-26.446-15.748-4.309-2.526-78.893-46.95-85.876-50.664-5.794-3.293-9.955-4.754-13.52-5.349-3.269-.544-6.241.05-7.875.297-11.589 2.972-14.263 9.188-18.869 15.749-7.23 10.301-21.256 29.836-23.623 34.321-2.971 6.388-2.674 9.954-2.526 13.966.119 3.209-.049 5.992-.148 6.983-1.07 5.348-6.785 7.379-9.509 7.726-4.408.099-15.838.237-26.297 0-13.075-.298-22.88-5.646-27.338-8.469-4.457-2.823-25.406-17.383-26.743-18.126-1.288-.644-4.28-2.407-5.943-4.309-1.664-1.902-2.08-3.665-2.08-4.308-.297-4.903.891-6.278 2.674-8.172 2.377-2.526 5.943-2.823 8.023-2.823 2.972.149 43.681.149 47.841-.148 3.328-.238 6.24-1.189 7.28-1.635 9.509-3.268 13.718-9.827 14.709-11.589 1.337-2.377 2.377-5.645 2.525-7.577.05-.148.214-2.823-.297-6.091-.743-4.755-4.932-8.212-7.428-9.658-2.823-1.634-6.538-1.634-7.429-1.634-.713 0-6.537.099-9.657.149H59.721c-8.766 0-17 1.607-22.286 3.565-8.023 2.972-16.344 6.835-24.218 17.086-7.56 9.842-6.24 20.949-4.605 26.149 3.714 10.252 10.102 15.452 16.937 18.424 4.011 1.584 12.153 4.902 16.789 6.685 5.794 2.229 9.1 4.803 11.142 9.361 1.932 4.308 1.486 4.754 1.486 8.914 0 2.975-.891 5.497 1.338 9.806Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.95}
        strokeWidth={2}
        d="M56.304 234.301c1.782 3.447 6.091 5.646 9.063 5.794 88.252-.297 266.274-.832 274.118-.594 9.806.297 12.332-1.783 18.869-6.389 5.23-3.684 7.33-11.539 7.726-15.006.198-12.777.565-39.609.446-44.72-.149-6.389-2.823-11.292-6.24-14.709-3.715-3.715-9.361-5.646-15.601-7.726-4.636-1.546-10.258-3.163-17.383-5.646-4.613-1.608-10.069-3.666-13.223-4.308-8.023-1.635-12.777 3.12-15.154 6.388-2.378 3.269-4.458 10.549 0 17.235 2.674 4.16 7.428 5.943 11.143 6.834 2.971.713 10.449 3.467 13.817 4.754 15.1 6.472 18.126 19.15 18.126 25.258 0 5.2-.743 8.023-4.903 10.846-4.459 3.025-9.652.412-11.737-.892-4.755-2.971-22.732-13.571-26.446-15.748-4.309-2.526-78.893-46.95-85.876-50.664-5.794-3.293-9.955-4.754-13.52-5.349-3.269-.544-6.241.05-7.875.297-11.589 2.972-14.263 9.188-18.869 15.749-7.23 10.301-21.256 29.836-23.623 34.321-2.971 6.388-2.674 9.954-2.526 13.966.119 3.209-.049 5.992-.148 6.983-1.07 5.348-6.785 7.379-9.509 7.726-4.408.099-15.838.237-26.297 0-13.075-.298-22.88-5.646-27.338-8.469-4.457-2.823-25.406-17.383-26.743-18.126-1.288-.644-4.28-2.407-5.943-4.309-1.664-1.902-2.08-3.665-2.08-4.308-.297-4.903.891-6.278 2.674-8.172 2.377-2.526 5.943-2.823 8.023-2.823 2.972.149 43.681.149 47.841-.148 3.328-.238 6.24-1.189 7.28-1.635 9.509-3.268 13.718-9.827 14.709-11.589 1.337-2.377 2.377-5.645 2.525-7.577.05-.148.214-2.823-.297-6.091-.743-4.755-4.932-8.212-7.428-9.658-2.823-1.634-6.538-1.634-7.429-1.634-.713 0-6.537.099-9.657.149H59.721c-8.766 0-17 1.607-22.286 3.565-8.023 2.972-16.344 6.835-24.218 17.086-7.56 9.842-6.24 20.949-4.605 26.149 3.714 10.252 10.102 15.452 16.937 18.424 4.011 1.584 12.153 4.902 16.789 6.685 5.794 2.229 9.1 4.803 11.142 9.361 1.932 4.308 1.486 4.754 1.486 8.914 0 2.975-.891 5.497 1.338 9.806Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={34.018}
        y={240.689}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m44.245 248.765-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={25.4}
        y={213.946}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m32.805 227.182 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={15}
        y={158.082}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M22.187 171.846v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={104.145}
        y={139.362}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M115.252 152.754h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={41.743}
        y={147.682}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M49.032 161.446v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={85.127}
        y={187.5}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M92.437 201.264v-5.928l1.236-1.236H97.2l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={157.631}
        y={183.934}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M164.787 197.698v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={121.082}
        y={193.146}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M132.643 201.174v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936h-1.464l3.372-6.972Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={186.157}
        y={146.197}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M193.498 159.961v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={304.124}
        y={212.163}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m310.365 220.239-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={321.953}
        y={152.734}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m329.811 160.81-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.738 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={274.707}
        y={129.853}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m281.411 137.929-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.07 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={344.239}
        y={128.962}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m350.773 137.038-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={345.725}
        y={244.255}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m352.399 252.331-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="M224.753 219.295h4.028v4.526h-4.028zM224.753 228.346h4.028v4.526h-4.028zM224.753 237.398h4.028v4.526h-4.028zM224.753 246.449h4.028v4.526h-4.028zM224.753 255.501h4.028v4.526h-4.028zM228.781 223.821h4.028v4.526h-4.028zM228.781 232.872h4.028v4.526h-4.028zM228.781 241.924h4.028v4.526h-4.028zM228.781 250.975h4.028v4.526h-4.028z"
      />
      <path
        fill="#000"
        d="M228.781 219.295h4.028v4.526h-4.028zM228.781 228.346h4.028v4.526h-4.028zM228.781 237.398h4.028v4.526h-4.028zM228.781 246.449h4.028v4.526h-4.028zM228.781 255.501h4.028v4.526h-4.028zM224.753 223.821h4.028v4.526h-4.028zM224.753 232.872h4.028v4.526h-4.028zM224.753 241.924h4.028v4.526h-4.028zM224.753 250.975h4.028v4.526h-4.028z"
      />
      <path
        fill="#E2645A"
        d="m198.82 265.677 14.82 9.856v-19.761l-14.82 9.905Zm-2.263 0c0-.729.299-1.408.896-1.797l15.546-10.342c1.196-.825 2.691.146 2.691 1.748v20.781c0 1.554-1.495 2.573-2.691 1.796l-15.546-10.39a2.21 2.21 0 0 1-.896-1.796Z"
      />
    </svg>
  )
);
export default SvgIconBarcelonaMinimapMarkers;
