import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSpoilerFront = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={20}
    fill="none"
    viewBox="0 0 22 20"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="M11.388 18v-.801a2 2 0 0 1 1.057-1.764L17 13m-5.612 5 8-2.51c.607-.19 1.051-.71 1.144-1.339.197-1.327-1.181-2.326-2.381-1.726L17 13m-5.612 5L1 8.678m0 0v-.995A2 2 0 0 1 2.036 5.93l5.578-3.068c1.332-.732 2.91.433 2.601 1.921a1.78 1.78 0 0 1-1.282 1.358l-.801.215M1 8.678l5.095-2.582a1.83 1.83 0 0 1 2.037.26m0 0L17 13"
    />
  </svg>
));
export default SvgIconSpoilerFront;
