import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGenders = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={12}
    fill="none"
    viewBox="0 0 16 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M6.453 9.188a.57.57 0 0 1 .563.562.555.555 0 0 1-.563.563H5.328v1.124a.555.555 0 0 1-.562.563.542.542 0 0 1-.563-.563v-1.124H3.078a.542.542 0 0 1-.562-.563c0-.305.234-.563.562-.563h1.125v-.96A4.126 4.126 0 0 1 .641 4.125 4.116 4.116 0 0 1 4.766 0 4.131 4.131 0 0 1 8.89 4.125c0 2.086-1.57 3.82-3.563 4.102v.96h1.125ZM4.766 7.124c1.64 0 3-1.336 3-3 0-1.64-1.36-3-3-3-1.664 0-3 1.36-3 3 0 1.664 1.336 3 3 3ZM15.078 0a.57.57 0 0 1 .563.563v2.625a.555.555 0 0 1-.563.562.542.542 0 0 1-.562-.563V1.923l-1.243 1.242a4.084 4.084 0 0 1-.351 5.39 4.098 4.098 0 0 1-2.906 1.196 4.112 4.112 0 0 1-2.93-1.195c-.024-.024-.047-.07-.07-.094.328-.188.656-.399.937-.656.563.539 1.29.82 2.063.82a2.96 2.96 0 0 0 2.109-.867 2.999 2.999 0 0 0 0-4.242 2.982 2.982 0 0 0-2.11-.891c-.21 0-.421.023-.609.07a5.075 5.075 0 0 0-.445-1.03c.328-.095.68-.142 1.031-.142.89 0 1.758.282 2.485.844l1.242-1.242h-1.266a.542.542 0 0 1-.562-.563c0-.304.234-.562.562-.562h2.625Z"
    />
  </svg>
));
export default SvgIconGenders;
