import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconOctagonCheck = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90}
    height={91}
    fill="none"
    viewBox="0 0 90 91"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#4FC5BA"
      d="M87.375 27.125c1.688 1.5 2.625 3.563 2.625 5.813v25.124c0 2.25-.938 4.5-2.813 6.188L63.376 88.063c-1.688 1.687-3.75 2.437-6 2.437H32.25c-2.063 0-4.313-.75-6-2.625L2.437 64.062C.75 62.376 0 60.313 0 58.063V32.939c0-2.25.75-4.313 2.438-5.813l24-24C27.938 1.437 30.188.5 32.25.5h25.313c2.062 0 4.312.938 6 2.625l23.812 24ZM87 58.063V32.936c0-1.312-.75-2.812-1.875-3.75l-23.813-24c-.937-.937-2.437-1.687-3.75-1.687H32.25c-1.313 0-2.813.75-3.75 1.688L4.5 29C3.562 30.125 3 31.438 3 32.938V58.25c0 1.313.563 2.813 1.5 3.75l23.813 24c1.125.938 2.437 1.5 3.937 1.5h25.313c1.312 0 2.812-.563 3.75-1.688l24-23.812C86.25 60.875 87 59.562 87 58.062Zm-22.125-28.5a1.471 1.471 0 0 1 2.063 0c.562.562.562 1.5 0 2.062l-27 27C39.75 59 39.374 59 39 59c-.563 0-.938 0-1.125-.375l-15-15a1.471 1.471 0 0 1 0-2.063 1.471 1.471 0 0 1 2.063 0L39 55.438l25.875-25.876Z"
    />
  </svg>
));
export default SvgIconOctagonCheck;
