import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCheckSquare = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#4FC5BA"
      d="M13 1c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H3c-1.125 0-2-.875-2-2V3c0-1.094.875-2 2-2h10Zm.5 12V3c0-.25-.25-.5-.5-.5H3c-.281 0-.5.25-.5.5v10c0 .281.219.5.5.5h10c.25 0 .5-.219.5-.5Zm-3.031-7.531A.709.709 0 0 1 11.5 5.5a.684.684 0 0 1 0 1.031l-4 4a.622.622 0 0 1-.5.219.753.753 0 0 1-.563-.219l-2-2c-.312-.281-.312-.75 0-1.062.282-.282.75-.282 1.063 0l1.5 1.5 3.469-3.5Z"
    />
  </svg>
));
export default SvgIconCheckSquare;
