import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCheckeredFlag = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={32}
    fill="none"
    viewBox="0 0 33 32"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M30.5 0c1.313 0 2.438.813 2.5 2.125V22.5c0 .875-.563 1.625-1.438 1.938C28.5 25.625 26 26 23.813 26c-5.062 0-8.625-2.125-13.687-2.125-2.063 0-4.375.375-7.188 1.375V31c0 .563-.437 1-1 1-.5 0-1-.438-1-1V1c0-.5.5-1 1-1 .563 0 1 .5 1 1v.5C6.063.437 8.438.062 10.438.062c4.75 0 7.188 2.126 11.313 2.126 1.938 0 4.25-.438 7.438-1.876C29.625.126 30.063 0 30.5 0ZM30 2.125a20.798 20.798 0 0 1-4 1.438v3.812a25.86 25.86 0 0 0 4.938-1.125V2.5c0-.375-.375-.625-.938-.375Zm.938 9.625V8.375c-1.875.563-3.5.875-4.938 1v3.5a25.86 25.86 0 0 0 4.938-1.125Zm0 5.5v-3.375c-1.875.563-3.5.875-4.938 1v3.5a25.86 25.86 0 0 0 4.938-1.125ZM24 13V9.5c-2.313.063-4.25-.313-6-.688v3.438c1.875.438 3.75.75 6 .75Zm-8-1.25V8.312c-1.938-.437-3.813-.75-6-.75v3.5c2.188 0 4.125.313 6 .688Zm0 2.063c-1.938-.438-3.813-.75-6-.75v3.5c2.188-.063 4.125.312 6 .687v-3.438Zm2 .5v3.374c1.875.438 3.75.813 6 .813V15c-2.313.063-4.25-.313-6-.688ZM24 4c-.813.125-1.563.188-2.25.188-1.438 0-2.625-.188-3.75-.5V6.75c1.875.438 3.75.75 6 .75V4Zm-8-.875c-1.75-.563-3.438-1.063-5.563-1.063H10v3.5c2.188 0 4.125.313 6 .688V3.125Zm-13.063 20C4.688 22.562 6.376 22.187 8 22v-3.313c-1.5.125-3.125.5-5 1.063v-2.063c1.813-.562 3.438-.875 5-1v-3.5c-1.5.188-3.125.5-5 1.126v-2.126c1.813-.562 3.438-.874 5-1v-3.5c-1.5.188-3.125.5-5 1.125V6.688c1.813-.563 3.438-.875 5-1V2.25c-1.563.25-3.188.688-5.063 1.375v19.5Zm7.188-1.25c2.188 0 4.063.375 5.875.813v-3.375c-1.938-.438-3.813-.813-6-.75v3.312h.125ZM23.813 24H24v-3.5c-2.313 0-4.25-.313-6-.75v3.375c1.875.5 3.75.875 5.813.875Zm7.125-1.5v-3.188c-1.875.563-3.5.875-4.938 1.063v3.5c2.625-.375 4.938-1.25 4.938-1.375Z"
    />
  </svg>
));
export default SvgIconCheckeredFlag;
