import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBullhorn = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    fill="none"
    viewBox="0 0 18 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M17.25 0a.74.74 0 0 0-.75.75v.125L1.437 5.094C1.375 4.78 1.095 4.5.75 4.5a.74.74 0 0 0-.75.75v3.5c0 .438.313.75.75.75.344 0 .625-.25.688-.563L5.217 10c-.155.344-.218.75-.218 1.125A2.867 2.867 0 0 0 7.875 14a2.834 2.834 0 0 0 2.813-2.469l5.812 1.625v.094c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75V.75a.76.76 0 0 0-.75-.75Zm-8 11.125c0 .781-.625 1.375-1.375 1.375A1.348 1.348 0 0 1 6.5 11.125c0-.25.063-.5.219-.719l2.5.719h.031ZM1.5 7.375v-.719l15-4.218v9.156l-15-4.219Z"
    />
  </svg>
));
export default SvgIconBullhorn;
