import { type ComponentStyleConfig } from '@chakra-ui/react';

import { defaultTransition, colors } from 'utils/themeConstants';

const activeLabelStyles = {
  transform: 'scale(0.9) translateY(-1rem)',
};

// using defineStyleConfig here creates a superfluous TS error
export const formTheme: Partial<ComponentStyleConfig> = {
  variants: {
    // see https://chakra-ui.com/community/recipes/floating-labels
    floating: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
          {
            ...activeLabelStyles,
          },
        label: {
          color: 'white.40',
          fontSize: 'sm',
          left: 0,
          mx: 4,
          my: 5,
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          transformOrigin: 'left top',
          zIndex: 2,
        },
        input: {
          bg: 'darkVoid.40',
          border: 'solid 1px',
          borderColor: 'white.40',
          borderRadius: 'sm',
          caretColor: colors.fieryCoral[100],
          color: 'white.100',
          fontSize: 'md',
          ':hover, :focus': {
            bg: 'caviar.100',
            borderColor: 'white.100',
          },
          paddingBottom: 5,
          paddingTop: 8,
          // paddingY: 6,
          transition: defaultTransition,
        },
        '& .visibilityToggle': {
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          height: '100%',
          marginRight: 4,
          width: 'fit-content',
        },
      },
    },
  },
};
