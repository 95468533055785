import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBudapestMinimapMarkers = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={376}
      height={375}
      fill="none"
      viewBox="0 0 376 375"
      aria-hidden="true"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#000"
          fillOpacity={0.2}
          d="M106.663 132.825c.832 6.181 5.101 24.861 7.132 33.429 2.878 13.226 7.338 33.647 11.086 50.664 2.731 14.263 6.233 28.143 6.742 30.011 1.07 3.923 5.498 6.488 7.578 7.281 8.914 3.466 27.605 10.816 31.052 12.48 4.308 2.08 12.777 7.874 9.508 20.8-2.615 10.341-13.669 11.836-18.869 11.292h-46.503c-10.846 0-18.423-1.337-35.51 2.228-17.085 3.566-35.211 17.235-38.034 19.166-2.823 1.932-9.954 4.903-9.509 13.075.357 6.537 7.677 7.874 11.292 7.726 83.251-.347 251.832-1.011 260.153-.892 10.4.149 15.748-6.24 16.491-7.429.743-1.188 9.36-9.657 2.972-22.88-6.389-13.223-21.989-11.143-24.515-11.143s-30.755.594-37.886 0c-7.132-.594-9.212-4.297-10.846-6.686-1.635-2.388-2.526-6.983-.297-12.628 1.773-4.493 8.104-6.81 11.096-7.42a.501.501 0 0 1 .095-.009H279.9c4.16 0 8.964.594 10.846.891 8.419 1.437 26.327 4.428 30.606 4.903 5.349.595 6.072-1.004 7.28-3.12 1.189-2.08 2.08-11.737 2.229-13.966.149-2.228 1.634-33.132 1.931-38.926.298-5.794 2.562-50.503 2.823-55.715.198-3.566.594-9.36.594-11.738 0-2.228-.099-4.605-.297-5.942-1.188-9.955-9.211-15.483-13.52-17.384-5.052-2.228-23.873-7.865-31.498-10.548-8.023-2.823-9.284-4.021-13.223-7.28-4.309-3.566-8.765-10.846-9.36-12.183-.594-1.338-15.897-27.338-18.126-31.2-2.228-3.864-4.308-6.39-8.32-8.47-4.247-2.202-8.172-1.931-11.886-1.04-3.8.913-16.492 9.063-20.652 11.59-3.1 1.882-5.942 4.16-10.697 4.308-3.783.118-4.891-.587-8.12-2.642l-.051-.033c-11.787-8.617-34.113-25.227-36.846-27.486-3.418-2.823-3.269-4.16-2.823-7.725.445-3.566.26-5.631-1.486-7.726-2.229-2.675-6.24-2.823-8.023-3.12-1.783-.298-22.732-3.418-39.669-5.795-17.384-2.44-27.635-1.337-37.292 1.04-9.674 2.381-14.56 10.232-14.56 19.166 0 10.252 6.537 14.412 8.766 16.492 2.228 2.08 36.994 26.446 42.046 30.606 5.051 4.16 4.903 6.835 4.457 14.412-.446 7.577.594 21.84 1.634 29.566Z"
        />
        <path
          stroke="#fff"
          strokeOpacity={0.2}
          strokeWidth={10}
          d="M106.663 132.825c.832 6.181 5.101 24.861 7.132 33.429 2.878 13.226 7.338 33.647 11.086 50.664 2.731 14.263 6.233 28.143 6.742 30.011 1.07 3.923 5.498 6.488 7.578 7.281 8.914 3.466 27.605 10.816 31.052 12.48 4.308 2.08 12.777 7.874 9.508 20.8-2.615 10.341-13.669 11.836-18.869 11.292h-46.503c-10.846 0-18.423-1.337-35.51 2.228-17.085 3.566-35.211 17.235-38.034 19.166-2.823 1.932-9.954 4.903-9.509 13.075.357 6.537 7.677 7.874 11.292 7.726 83.251-.347 251.832-1.011 260.153-.892 10.4.149 15.748-6.24 16.491-7.429.743-1.188 9.36-9.657 2.972-22.88-6.389-13.223-21.989-11.143-24.515-11.143s-30.755.594-37.886 0c-7.132-.594-9.212-4.297-10.846-6.686-1.635-2.388-2.526-6.983-.297-12.628 1.783-4.517 8.171-6.835 11.143-7.429H279.9c4.16 0 8.964.594 10.846.891 8.419 1.437 26.327 4.428 30.606 4.903 5.349.595 6.072-1.004 7.28-3.12 1.189-2.08 2.08-11.737 2.229-13.966.149-2.228 1.634-33.132 1.931-38.926.298-5.794 2.562-50.503 2.823-55.715.198-3.566.594-9.36.594-11.738 0-2.228-.099-4.605-.297-5.942-1.188-9.955-9.211-15.483-13.52-17.384-5.052-2.228-23.873-7.865-31.498-10.548-8.023-2.823-9.284-4.021-13.223-7.28-4.309-3.566-8.765-10.846-9.36-12.183-.594-1.338-15.897-27.338-18.126-31.2-2.228-3.864-4.308-6.39-8.32-8.47-4.247-2.202-8.172-1.931-11.886-1.04-3.8.913-16.492 9.063-20.652 11.59-3.1 1.882-5.942 4.16-10.697 4.308-3.783.118-4.891-.587-8.12-2.642l-.051-.033c-11.787-8.617-34.113-25.227-36.846-27.486-3.418-2.823-3.269-4.16-2.823-7.725.445-3.566.26-5.631-1.486-7.726-2.229-2.675-6.24-2.823-8.023-3.12-1.783-.298-22.732-3.418-39.669-5.795-17.384-2.44-27.635-1.337-37.292 1.04-9.674 2.381-14.56 10.232-14.56 19.166 0 10.252 6.537 14.412 8.766 16.492 2.228 2.08 36.994 26.446 42.046 30.606 5.051 4.16 4.903 6.835 4.457 14.412-.446 7.577.594 21.84 1.634 29.566Z"
        />
        <path
          stroke="#fff"
          strokeOpacity={0.95}
          strokeWidth={4}
          d="M106.663 132.825c.832 6.181 5.101 24.861 7.132 33.429 2.878 13.226 7.338 33.647 11.086 50.664 2.731 14.263 6.233 28.143 6.742 30.011 1.07 3.923 5.498 6.488 7.578 7.281 8.914 3.466 27.605 10.816 31.052 12.48 4.308 2.08 12.777 7.874 9.508 20.8-2.615 10.341-13.669 11.836-18.869 11.292h-46.503c-10.846 0-18.423-1.337-35.51 2.228-17.085 3.566-35.211 17.235-38.034 19.166-2.823 1.932-9.954 4.903-9.509 13.075.357 6.537 7.677 7.874 11.292 7.726 83.251-.347 251.832-1.011 260.153-.892 10.4.149 15.748-6.24 16.491-7.429.743-1.188 9.36-9.657 2.972-22.88-6.389-13.223-21.989-11.143-24.515-11.143s-30.755.594-37.886 0c-7.132-.594-9.212-4.297-10.846-6.686-1.635-2.388-2.526-6.983-.297-12.628 1.783-4.517 8.171-6.835 11.143-7.429H279.9c4.16 0 8.964.594 10.846.891 8.419 1.437 26.327 4.428 30.606 4.903 5.349.595 6.072-1.004 7.28-3.12 1.189-2.08 2.08-11.737 2.229-13.966.149-2.228 1.634-33.132 1.931-38.926.298-5.794 2.562-50.503 2.823-55.715.198-3.566.594-9.36.594-11.738 0-2.228-.099-4.605-.297-5.942-1.188-9.955-9.211-15.483-13.52-17.384-5.052-2.228-23.873-7.865-31.498-10.548-8.023-2.823-9.284-4.021-13.223-7.28-4.309-3.566-8.765-10.846-9.36-12.183-.594-1.338-15.897-27.338-18.126-31.2-2.228-3.864-4.308-6.39-8.32-8.47-4.247-2.202-8.172-1.931-11.886-1.04-3.8.913-16.492 9.063-20.652 11.59-3.1 1.882-5.942 4.16-10.697 4.308-3.783.118-4.891-.587-8.12-2.642l-.051-.033c-11.787-8.617-34.113-25.227-36.846-27.486-3.418-2.823-3.269-4.16-2.823-7.725.445-3.566.26-5.631-1.486-7.726-2.229-2.675-6.24-2.823-8.023-3.12-1.783-.298-22.732-3.418-39.669-5.795-17.384-2.44-27.635-1.337-37.292 1.04-9.674 2.381-14.56 10.232-14.56 19.166 0 10.252 6.537 14.412 8.766 16.492 2.228 2.08 36.994 26.446 42.046 30.606 5.051 4.16 4.903 6.835 4.457 14.412-.446 7.577.594 21.84 1.634 29.566Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={5.635}
          y={331.022}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m15.862 339.098-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={187.785}
          y={278.427}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m195.19 291.663 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={101.613}
          y={232.667}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M108.8 246.431v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={109.934}
          y={78.15}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M121.041 91.542h-4.08V89.97l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={57.338}
          y={32.092}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M64.627 45.856v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={120.334}
          y={33.281}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M127.644 47.044v-5.927l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.255l.792-.78h2.628l1.236 1.236v2.773l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.037.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008V46.6l.48.48h2.304Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={183.627}
          y={82.31}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M190.783 96.074v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48V90.59l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={159.26}
          y={27.041}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M170.821 35.069v-.229h-4.248v-1.2h5.688v1.465l-3.348 6.936h-1.464l3.372-6.972Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={223.146}
          y={68.938}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M230.487 82.702v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={279.01}
          y={88.55}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m285.25 96.626-1.836.948V96.29l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.263 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={305.754}
          y={138.768}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m313.612 146.844-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.738 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={303.377}
          y={248.415}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m310.081 256.491-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.07 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={220.77}
          y={265.353}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m227.303 273.429-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={292.977}
          y={307.845}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m299.651 315.921-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
        />
        <path
          fill="#fff"
          fillOpacity={0.95}
          d="M231.238 319.73h4.028v4.526h-4.028zM231.238 328.782h4.028v4.526h-4.028zM231.238 337.833h4.028v4.526h-4.028zM231.238 346.885h4.028v4.526h-4.028zM231.238 355.937h4.028v4.526h-4.028zM235.266 324.256h4.028v4.526h-4.028zM235.266 333.308h4.028v4.526h-4.028zM235.266 342.359h4.028v4.526h-4.028zM235.266 351.411h4.028v4.526h-4.028z"
        />
        <path
          fill="#000"
          d="M235.266 319.73h4.028v4.526h-4.028zM235.266 328.782h4.028v4.526h-4.028zM235.266 337.833h4.028v4.526h-4.028zM235.266 346.885h4.028v4.526h-4.028zM235.266 355.937h4.028v4.526h-4.028zM231.238 324.256h4.028v4.526h-4.028zM231.238 333.308h4.028v4.526h-4.028zM231.238 342.359h4.028v4.526h-4.028zM231.238 351.411h4.028v4.526h-4.028z"
        />
        <path
          fill="#E2645A"
          d="m205.306 311.803 14.82 9.856v-19.761l-14.82 9.905Zm-2.263 0c0-.728.299-1.408.897-1.796l15.545-10.342c1.196-.825 2.691.146 2.691 1.748v20.78c0 1.554-1.495 2.574-2.691 1.797L203.94 313.6a2.21 2.21 0 0 1-.897-1.797Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.285 0h375v375h-375z" />
        </clipPath>
      </defs>
    </svg>
  )
);
export default SvgIconBudapestMinimapMarkers;
