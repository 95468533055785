import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetPrizePool = ({
  raceId,
  carId,
  userId,
}: {
  raceId: string;
  carId?: string;
  userId?: string;
}) => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const useGetPrizePoolGql = graphql(`
    query GetPrizePool($raceId: String!, $carId: String, $userId: String) {
      getPrizePool(raceId: $raceId, carId: $carId, userId: $userId) {
        id
        gridSize
        entrySingle
        rake
        pool
        createdAt
        prizePoolDistributions {
          id
          prizePoolId
          gridPosition
          points
          earnings
        }
      }
    }
  `);

  const getPrizePool = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['prizePoolQuery', { raceId, carId, userId }],
    retry: 5,
    retryDelay: 1000,
    queryFn: async () => {
      return graphQLClient.request(useGetPrizePoolGql, {
        raceId,
        carId,
        userId,
      });
    },
  });
  return getPrizePool;
};

export default useGetPrizePool;
