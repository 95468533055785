import {
  List,
  ListItem,
  Flex,
  ListItemProps,
  Text,
  FlexProps,
  Box,
} from '@chakra-ui/react';
import startCase from 'lodash.startcase';

import {
  IconRaincloud,
  IconWind,
  IconRoadway,
  IconFlag,
  IconWindsock,
  IconBumpyRoad,
  IconWheel,
  IconTemperature,
} from 'icons';
import { RTooltip } from 'components';
import {
  AirTemperatureCategory,
  Races,
  TrackTemperatureCategory,
} from 'api/generated/graphql';
import { RecursivePartial, SVGIcon } from 'types';
import { admissionTicketBgGradient } from 'utils/themeConstants';
import { airTemperatureLevel, trackTemperatureLevel } from './RaceInfoTypes';

const Item = ({
  title,
  description,
  Icon,
  tooltip,
  level = 1,
  containerProps,
  ...rest
}: ListItemProps & {
  title: string;
  description: string;
  Icon: SVGIcon;
  tooltip: string;
  level?: 0 | 1 | 2 | 3 | 4 | 5;
  containerProps?: FlexProps;
}) => {
  const bars = Array(6)
    .fill('')
    .map((_, index) => (
      <Box
        key={index}
        w="0.5rem"
        h="0.5rem"
        borderRadius="0.125rem"
        borderWidth="0.063rem"
        bg={index < level ? 'secondaryYellow.100' : 'transparent'}
        borderColor={index < level ? 'secondaryYellow.100' : 'white.20'}
      />
    ));

  return (
    <ListItem {...rest}>
      <RTooltip label={tooltip}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="full"
          px="0.5rem"
          py="0.25rem"
          bg={admissionTicketBgGradient}
          flexGrow={1}
          {...containerProps}
        >
          <Flex>
            <Icon
              h="0.75rem"
              __css={{
                path: { fill: 'white.40' },
              }}
            />
            <Text
              fontSize="0.75rem"
              fontFamily="body"
              color="white.80"
              lineHeight="0.875rem"
              fontWeight={500}
              noOfLines={1}
            >
              {title}
            </Text>
          </Flex>

          <Flex alignItems="center" gap="0.25rem">
            {bars}
          </Flex>
        </Flex>
      </RTooltip>
    </ListItem>
  );
};

const RaceInfo = ({
  race,
  hasDetails = false,
  ...props
}: {
  race: RecursivePartial<Races>;
  hasDetails?: boolean;
} & FlexProps) => {
  if (hasDetails && race) {
    return (
      <Flex flexDirection="column" w="full" pt="6" {...props}>
        <List
          display="grid"
          gridTemplateRows="repeat(2, 1fr)"
          w="full"
          gridGap="0.125rem"
        >
          <Item
            tooltip="Track Temp"
            title="Track Temp"
            description={startCase(race.trackTemperatureCategory || '')}
            Icon={IconTemperature}
            level={
              trackTemperatureLevel[
                race.trackTemperatureCategory || TrackTemperatureCategory.Cold
              ]
            }
          />
          <Item
            tooltip="Air Temp"
            title="Air Temp"
            description={startCase(race.airTemperatureCategory || '')}
            Icon={IconWind}
            level={
              airTemperatureLevel[
                race.airTemperatureCategory || AirTemperatureCategory.Cold
              ]
            }
          />

          <Item
            tooltip="Wind Speed"
            title="Wind"
            description={startCase(race.windCategory || '')}
            Icon={IconWindsock}
          />

          <Item
            tooltip="Precipitation"
            title="Rainfall"
            description={startCase(race.weatherCategory || '')}
            Icon={IconRaincloud}
          />

          <Item
            tooltip="Tarmac Condition"
            title="Track Grip"
            description={startCase(race.tarmacConditionCategory || '')}
            Icon={IconBumpyRoad}
          />

          <Item
            tooltip="Bumpiness"
            title="Roughness"
            description={startCase(race.trackRoughnessCategory || '')}
            Icon={IconWheel}
          />
        </List>
      </Flex>
    );
  }
  if (!hasDetails && race) {
    return (
      <List
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gridGap="0.125rem"
        w="full"
        justifyContent="space-between"
        pt="4"
      >
        <Item
          tooltip="Number of Laps"
          title="Skill"
          description={`${race?.laps}`}
          Icon={IconFlag}
          level={4}
          containerProps={{
            borderTopLeftRadius: '0.5rem',
          }}
        />
        <Item
          tooltip="Air Temp"
          title="Air Temp"
          description={startCase(race.airTemperatureCategory || '')}
          Icon={IconWind}
          level={
            airTemperatureLevel[
              race.airTemperatureCategory || AirTemperatureCategory.Cold
            ]
          }
        />
        <Item
          tooltip="Track Temp"
          title="Track Temp"
          description={startCase(race.trackTemperatureCategory || '')}
          Icon={IconRoadway}
          level={
            trackTemperatureLevel[
              race.trackTemperatureCategory || TrackTemperatureCategory.Cold
            ]
          }
          containerProps={{
            borderBottomLeftRadius: '0.5rem',
          }}
        />
        <Item
          tooltip="Precipitation"
          title="Rainfall"
          description={startCase(race.weatherCategory || '')}
          Icon={IconRaincloud}
          level={3}
        />
      </List>
    );
  }

  return null;
};

export default RaceInfo;
