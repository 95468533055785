import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWalletTicket = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={16}
    fill="none"
    viewBox="0 0 23 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M22.271 6.438c-.898 0-1.563.78-1.563 1.601 0 .86.665 1.524 1.563 1.524h.313V13c0 1.406-1.133 2.5-2.5 2.5h-17.5a2.468 2.468 0 0 1-2.5-2.5V9.562h.312c.86 0 1.563-.664 1.563-1.562 0-.86-.704-1.563-1.563-1.563H.083V3c0-1.367 1.094-2.5 2.5-2.5h17.5c1.368 0 2.5 1.133 2.5 2.5v3.438h-.312Zm-1.563-1.485V3c0-.313-.312-.625-.624-.625h-17.5A.642.642 0 0 0 1.958 3v1.953C3.053 5.54 3.833 6.672 3.833 8c0 1.367-.78 2.5-1.875 3.086V13c0 .352.274.625.625.625h17.5A.642.642 0 0 0 20.71 13v-1.914A3.42 3.42 0 0 1 18.834 8c0-1.328.742-2.46 1.875-3.047Zm-4.374-.703c.664 0 1.25.586 1.25 1.25v5c0 .703-.586 1.25-1.25 1.25h-10c-.704 0-1.25-.547-1.25-1.25v-5c0-.664.546-1.25 1.25-1.25h10Zm-.625 5.625v-3.75h-8.75v3.75h8.75Z"
    />
  </svg>
));
export default SvgIconWalletTicket;
