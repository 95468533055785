import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIcon3DBox = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M15.344 2.688c.375.156.656.53.656.937v8.781c0 .406-.281.781-.656.938l-6.656 2.531A1.78 1.78 0 0 1 8 16c-.25 0-.5-.031-.719-.125L.625 13.344A1.024 1.024 0 0 1 0 12.406V3.625c0-.406.25-.781.625-.938L7.281.158C7.5.061 7.75 0 8 0c.219 0 .469.063.688.156l6.656 2.531ZM7.813 1.53 1.718 3.875 8 6.219l6.25-2.344-6.094-2.344c-.062 0-.125-.031-.156-.031-.063 0-.125.031-.188.031ZM1.5 5.375v6.688l5.75 2.187V7.531L1.5 5.375Zm7.25 8.875 5.75-2.188V5.375L8.75 7.531v6.719Z"
    />
  </svg>
));
export default SvgIcon3DBox;
