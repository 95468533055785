import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTicketUsdc = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={34}
    fill="none"
    viewBox="0 0 33 34"
    aria-hidden="true"
    {...props}
  >
    <g filter="url(#a)">
      <path
        stroke="#2775CA"
        d="M12.94 28.808h2.457l2.456-2.456 1.228-1.228 9.416-9.416v-2.456l-.41-.41a1.158 1.158 0 1 1-1.637-1.637l-1.023-1.023a1.158 1.158 0 1 1-1.638-1.638l-1.023-1.023a1.158 1.158 0 1 1-1.638-1.638l-.409-.41h-2.456L8.847 14.89l-1.228 1.229-2.456 2.456v2.456l.409.41a1.158 1.158 0 1 1 1.638 1.637L8.233 24.1a1.158 1.158 0 1 1 1.637 1.638l1.024 1.023A1.158 1.158 0 1 1 12.53 28.4l.41.41Z"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      fill="#2775CA"
      fillRule="evenodd"
      d="M18.599 20.922c-2.118.999-4.656.06-5.615-2.059a4.18 4.18 0 0 1 0-3.556c.04-.12.02-.22-.08-.32l-.28-.28c-.08-.08-.18-.1-.28-.04-.02.02-.06.06-.06.1a5.077 5.077 0 0 0 2.178 6.854c1.48.76 3.198.76 4.676 0 .12-.04.16-.16.1-.26 0-.04-.02-.06-.06-.1l-.28-.28c-.06-.06-.2-.08-.3-.06Zm-4.117-8.353c-.12.04-.16.16-.1.26 0 .04.02.06.06.1l.28.28c.08.08.22.1.32.08 2.118-1 4.656-.06 5.615 2.058a4.18 4.18 0 0 1 0 3.557c-.04.12-.02.22.08.32l.28.279c.08.08.18.1.28.04.02-.02.06-.06.06-.1a5.077 5.077 0 0 0-2.179-6.854 5.125 5.125 0 0 0-4.696-.02Zm2.059 4.136c.939-.739 1.598-.919 2.298-.22.54.54.56 1.36.06 2.059l.48.48c.08.12.08.24 0 .32l-.3.299c-.12.08-.24.08-.32 0l-.48-.48c-.76.56-1.439.52-2.018.1l-.02-.02a.193.193 0 0 1 0-.28l.34-.34c.06-.06.16-.08.26-.02.36.24.759.28 1.278-.239.38-.38.44-.88.12-1.199-.32-.32-.62-.26-1.279.2-.939.74-1.598.88-2.258.22-.5-.5-.52-1.28 0-1.959l-.46-.46c-.08-.12-.08-.239 0-.319l.3-.3c.12-.08.24-.08.32 0l.48.48c.5-.42 1.199-.44 1.718-.08l.02.02c.08.08.08.2 0 .28l-.32.32c-.06.06-.16.08-.24.04-.399-.2-.719-.12-1.078.24-.4.399-.42.778-.14 1.058.28.28.56.32 1.239-.2Z"
      clipRule="evenodd"
    />
    <defs>
      <filter
        id="a"
        width={32.334}
        height={32.334}
        x={0.663}
        y={0.974}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10057_172606"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_10057_172606"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
));
export default SvgIconTicketUsdc;
