import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBumpsConditions = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={12}
    fill="none"
    viewBox="0 0 16 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M15.787 6.984c-.117.422-1.36 4.266-4.102 4.266s-3.96-4.547-4.101-5.063C7.279 4.899 6.201 2.25 4.959 2.25c-1.383 0-2.414 2.39-2.672 3.234a.746.746 0 0 1-.937.493.746.746 0 0 1-.493-.938C.975 4.617 2.217.75 4.96.75s3.96 4.57 4.102 5.086c.304 1.266 1.382 3.914 2.624 3.914 1.383 0 2.391-2.367 2.649-3.21a.746.746 0 0 1 .938-.493c.421.117.632.539.515.937Z"
    />
  </svg>
));
export default SvgIconBumpsConditions;
