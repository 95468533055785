import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGraphics = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M15.25 13.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H1c-.563 0-1-.438-1-1V1.75A.74.74 0 0 1 .75 1a.76.76 0 0 1 .75.75V13.5h13.75Zm-10-2.5a.722.722 0 0 1-.75-.75v-1.5A.74.74 0 0 1 5.25 8a.76.76 0 0 1 .75.75v1.5a.74.74 0 0 1-.75.75Zm3 0a.722.722 0 0 1-.75-.75v-5.5A.74.74 0 0 1 8.25 4a.76.76 0 0 1 .75.75v5.5a.74.74 0 0 1-.75.75Zm3 0a.722.722 0 0 1-.75-.75v-3.5a.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75v3.5a.74.74 0 0 1-.75.75Zm3 0a.722.722 0 0 1-.75-.75v-6.5a.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75v6.5a.74.74 0 0 1-.75.75Z"
    />
  </svg>
));
export default SvgIconGraphics;
