import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUpload = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={19}
    fill="none"
    viewBox="0 0 15 19"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M14.063 16.375a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H.938c-.547 0-.938-.39-.938-.938 0-.546.39-.937.938-.937h13.124ZM3.164 7.665a.968.968 0 0 1-1.328.038.932.932 0 0 1-.04-1.328l5-5.313c.352-.39 1.016-.39 1.368 0l5 5.313a.932.932 0 0 1-.039 1.328c-.195.156-.43.234-.625.234-.273 0-.508-.078-.703-.273L8.437 4.07v9.492c0 .547-.43.938-.937.938-.547 0-.938-.39-.938-.938V4.07L3.164 7.664Z"
    />
  </svg>
));
export default SvgIconUpload;
