import { useMutation } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';
import { MutationRepairCarPartsArgs } from 'api/generated/graphql';

const useRepairCarParts = (rest?: { [key: string]: unknown }) => {
  const { graphql, graphQLClient } = useGraphQLClient();

  const repairCarPartsGQL = graphql(`
    mutation RepairCarParts($carId: String!, $partName: partTypeName) {
      repairCarParts(carId: $carId, partName: $partName) {
        id
        nftId
        name
        points
        locked
        livery
        frontWingTuning
        rearWingTuning
        engineModeTuning
        transmissionTuning
        brakeCoolingTuning
        suspensionRideHeightTuning
        suspensionStiffnessTuning
        engineCoolingTuning
        frontWingId
        rearWingId
        brakeCoolingId
        engineCoolingId
        tyresId
        transmissionRatioId
      }
    }
  `);

  const repairCarParts = useMutation({
    mutationKey: ['repairCarParts'],
    mutationFn: ({ carId, partName }: MutationRepairCarPartsArgs) => {
      return graphQLClient.request(repairCarPartsGQL, {
        carId,
        partName,
      });
    },
    ...rest,
  });

  return repairCarParts;
};

export default useRepairCarParts;
