import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconImolaMinimap = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="m178.699 187.5-40.412 3.12c-2.525.446-3.417-1.188-3.714-2.228-.297-1.04-1.29-2.964-2.228-3.121-1.783-.297-3.121.595-3.863 1.041-.686.411-7.875 4.011-9.806 5.051-1.932 1.04-15.749 7.577-20.058 10.103-4.992 2.927-16.343 12.48-17.234 13.52-3.686 3.269-12.035 12.926-14.115 15.452-1.664 2.02-6.388 7.429-10.549 12.183-6.537 9.36-23.177 15.749-28.526 18.869-.573.335-6.388 2.971-7.725 4.754-1.337 1.783-.843 3.863-.446 4.903 2.08 4.259 6.626 12.867 7.577 14.412 1.189 1.931 3.227 2.861 4.16 2.971 2.526.297 4.755-.445 5.795-1.04 2.08-1.188 23.44-10.746 33.577-15.6 10.549-5.052 18.423-8.172 24.663-9.063 6.241-.891 29.418-1.783 37.887-2.526l.603-.053c8.096-.711 12.812-1.125 20.643-.69 7.011.39 6.388.892 29.269 4.309 23.421 3.498 30.606 1.443 38.629.297 6.24-.891 26.66-4.408 31.497-5.497 5.944-1.337 13.075-4.16 15.749-4.903 2.675-.743 4.649-1.203 5.646-2.823 1.189-1.931.743-1.486 1.783-7.429.832-4.754 2.823-5.348 4.754-6.685 3.863-2.18 12.569-6.757 15.452-9.063 3.714-2.972 4.754-7.132 5.349-9.212.248-.869 21.246-66.264 21.988-68.047.743-1.783.298-4.754.149-5.497-.148-.743-1.579-5.576-3.268-9.657-1.783-4.309.941-6.464 1.188-6.686 1.486-1.337 9.806-9.955 15.6-15.155 4.903-4.803 15.957-15.422 17.384-17.68 1.931-2.971.148-5.943-.595-6.983s-2.674-2.377-4.754-2.526c-1.664-.118-5.646.743-7.429 1.189-9.954 2.08-31.052 6.24-42.938 8.766-9.836 2.09-16.343 2.648-23.92 2.377-6.092-.218-25.555-3.12-29.715-3.12s-7.428 3.12-8.468 4.903c-1.04 1.783-.743 1.189-4.755 11.886-4.234 11.289-2.377 17.977-1.931 21.394.445 3.418 9.048 30.68 9.508 32.984.595 2.971-.445 4.308-1.04 5.348-1.436 2.774-3.981 7.34-4.457 8.172-.594 1.04-2.971 6.24-5.051 7.28s-6.983-1.783-9.063-2.377c-2.08-.594-4.903-.594-6.686-.446-1.783.149-32.241 2.526-36.104 2.823Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={10}
      d="m178.699 187.5-40.412 3.12c-2.525.446-3.417-1.188-3.714-2.228-.297-1.04-1.29-2.964-2.228-3.121-1.783-.297-3.121.595-3.863 1.041-.686.411-7.875 4.011-9.806 5.051-1.932 1.04-15.749 7.577-20.058 10.103-4.992 2.927-16.343 12.48-17.234 13.52-3.686 3.269-12.035 12.926-14.115 15.452-1.664 2.02-6.388 7.429-10.549 12.183-6.537 9.36-23.177 15.749-28.526 18.869-.573.335-6.388 2.971-7.725 4.754-1.337 1.783-.843 3.863-.446 4.903 2.08 4.259 6.626 12.867 7.577 14.412 1.189 1.931 3.227 2.861 4.16 2.971 2.526.297 4.755-.445 5.795-1.04 2.08-1.188 23.44-10.746 33.577-15.6 10.549-5.052 18.423-8.172 24.663-9.063 6.241-.891 29.418-1.783 37.887-2.526l.603-.053c8.096-.711 12.812-1.125 20.643-.69 7.011.39 6.388.892 29.269 4.309 23.421 3.498 30.606 1.443 38.629.297 6.24-.891 26.66-4.408 31.497-5.497 5.944-1.337 13.075-4.16 15.749-4.903 2.675-.743 4.649-1.203 5.646-2.823 1.189-1.931.743-1.486 1.783-7.429.832-4.754 2.823-5.348 4.754-6.685 3.863-2.18 12.569-6.757 15.452-9.063 3.714-2.972 4.754-7.132 5.349-9.212.248-.869 21.246-66.264 21.988-68.047.743-1.783.298-4.754.149-5.497-.148-.743-1.579-5.576-3.268-9.657-1.783-4.309.941-6.464 1.188-6.686 1.486-1.337 9.806-9.955 15.6-15.155 4.903-4.803 15.957-15.422 17.384-17.68 1.931-2.971.148-5.943-.595-6.983s-2.674-2.377-4.754-2.526c-1.664-.118-5.646.743-7.429 1.189-9.954 2.08-31.052 6.24-42.938 8.766-9.836 2.09-16.343 2.648-23.92 2.377-6.092-.218-25.555-3.12-29.715-3.12s-7.428 3.12-8.468 4.903c-1.04 1.783-.743 1.189-4.755 11.886-4.234 11.289-2.377 17.977-1.931 21.394.445 3.418 9.048 30.68 9.508 32.984.595 2.971-.445 4.308-1.04 5.348-1.436 2.774-3.981 7.34-4.457 8.172-.594 1.04-2.971 6.24-5.051 7.28s-6.983-1.783-9.063-2.377c-2.08-.594-4.903-.594-6.686-.446-1.783.149-32.241 2.526-36.104 2.823Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={4}
      d="m178.699 187.5-40.412 3.12c-2.525.446-3.417-1.188-3.714-2.228-.297-1.04-1.29-2.964-2.228-3.121-1.783-.297-3.121.595-3.863 1.041-.686.411-7.875 4.011-9.806 5.051-1.932 1.04-15.749 7.577-20.058 10.103-4.992 2.927-16.343 12.48-17.234 13.52-3.686 3.269-12.035 12.926-14.115 15.452-1.664 2.02-6.388 7.429-10.549 12.183-6.537 9.36-23.177 15.749-28.526 18.869-.573.335-6.388 2.971-7.725 4.754-1.337 1.783-.843 3.863-.446 4.903 2.08 4.259 6.626 12.867 7.577 14.412 1.189 1.931 3.227 2.861 4.16 2.971 2.526.297 4.755-.445 5.795-1.04 2.08-1.188 23.44-10.746 33.577-15.6 10.549-5.052 18.423-8.172 24.663-9.063 6.241-.891 29.418-1.783 37.887-2.526l.603-.053c8.096-.711 12.812-1.125 20.643-.69 7.011.39 6.388.892 29.269 4.309 23.421 3.498 30.606 1.443 38.629.297 6.24-.891 26.66-4.408 31.497-5.497 5.944-1.337 13.075-4.16 15.749-4.903 2.675-.743 4.649-1.203 5.646-2.823 1.189-1.931.743-1.486 1.783-7.429.832-4.754 2.823-5.348 4.754-6.685 3.863-2.18 12.569-6.757 15.452-9.063 3.714-2.972 4.754-7.132 5.349-9.212.248-.869 21.246-66.264 21.988-68.047.743-1.783.298-4.754.149-5.497-.148-.743-1.579-5.576-3.268-9.657-1.783-4.309.941-6.464 1.188-6.686 1.486-1.337 9.806-9.955 15.6-15.155 4.903-4.803 15.957-15.422 17.384-17.68 1.931-2.971.148-5.943-.595-6.983s-2.674-2.377-4.754-2.526c-1.664-.118-5.646.743-7.429 1.189-9.954 2.08-31.052 6.24-42.938 8.766-9.836 2.09-16.343 2.648-23.92 2.377-6.092-.218-25.555-3.12-29.715-3.12s-7.428 3.12-8.468 4.903c-1.04 1.783-.743 1.189-4.755 11.886-4.234 11.289-2.377 17.977-1.931 21.394.445 3.418 9.048 30.68 9.508 32.984.595 2.971-.445 4.308-1.04 5.348-1.436 2.774-3.981 7.34-4.457 8.172-.594 1.04-2.971 6.24-5.051 7.28s-6.983-1.783-9.063-2.377c-2.08-.594-4.903-.594-6.686-.446-1.783.149-32.241 2.526-36.104 2.823Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="m116.627 281.741-4.024.18-.202-4.521 4.024-.18zM116.223 272.699l-4.024.18-.202-4.521 4.024-.18zM115.818 263.656l-4.024.18-.202-4.521 4.024-.18zM115.413 254.614l-4.024.18-.202-4.521 4.024-.18zM115.009 245.571l-4.024.18-.202-4.521 4.024-.18zM112.4 277.4l-4.024.18-.202-4.521 4.024-.18zM111.996 268.357l-4.024.18-.202-4.521 4.024-.18zM111.592 259.315l-4.024.18-.202-4.521 4.024-.18zM111.188 250.272l-4.024.18-.202-4.521 4.024-.18z"
    />
    <path
      fill="#000"
      d="m112.603 281.921-4.024.18-.202-4.521 4.024-.18zM112.198 272.879l-4.024.18-.202-4.521 4.024-.18zM111.794 263.836l-4.024.18-.202-4.521 4.024-.18zM111.39 254.794l-4.024.18-.202-4.521 4.024-.18zM110.984 245.751l-4.024.18-.202-4.521 4.024-.18z"
    />
    <path
      fill="#000"
      d="m116.425 277.22-4.024.18-.202-4.521 4.024-.18zM116.021 268.177l-4.024.18-.202-4.521 4.024-.18zM115.615 259.135l-4.024.18-.202-4.521 4.024-.18zM115.211 250.092l-4.024.18-.202-4.521 4.024-.18z"
    />
    <path
      fill="#E2645A"
      d="m140.46 234.246-15.245-9.184.883 19.742 14.362-10.558Zm2.262-.101c.032.728-.236 1.42-.816 1.835l-15.068 11.026c-1.158.878-2.694-.025-2.766-1.626l-.929-20.759c-.069-1.553 1.379-2.638 2.608-1.915l15.994 9.685a2.21 2.21 0 0 1 .977 1.754Z"
    />
  </svg>
));
export default SvgIconImolaMinimap;
