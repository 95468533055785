import { loginCognito } from 'utils/cognito/loginWithCognito';
import { loginGoogle } from 'utils/cognito/loginWithGoogle';
// import { loginFacebook } from 'utils/cognito/loginWithFacebook';
import { AuthRequest } from 'types';

const loginRequest = async ({ authProvider, credentials }: AuthRequest) => {
  // if (authProvider === 'facebook') {
  //   return loginFacebook();
  // }

  if (authProvider === 'google') {
    return loginGoogle();
  }

  if (credentials) {
    return loginCognito(credentials);
  }
};

export { loginRequest };
