import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconArrow = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={20}
    fill="none"
    viewBox="0 0 22 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M21.453 10c0 .656-.515 1.172-1.125 1.172H4.391l6.234 5.906c.469.422.469 1.172.047 1.594-.422.469-1.125.469-1.594.047l-8.25-7.875C.594 10.609.5 10.328.5 10c0-.281.094-.563.328-.797l8.25-7.875c.469-.422 1.172-.422 1.594.047a1.119 1.119 0 0 1-.047 1.594L4.391 8.875h15.937A1.11 1.11 0 0 1 21.453 10Z"
    />
  </svg>
));
export default SvgIconArrow;
