import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGoogle = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M15.875 8.188c0 4.437-3.031 7.562-7.5 7.562A7.709 7.709 0 0 1 .625 8 7.729 7.729 0 0 1 8.375.25c2.063 0 3.844.781 5.188 2.031l-2.126 2.031C8.688 1.656 3.564 3.656 3.564 8c0 2.719 2.156 4.906 4.812 4.906 3.063 0 4.219-2.187 4.375-3.344H8.375V6.906h7.375c.063.407.125.782.125 1.282Z"
    />
  </svg>
));
export default SvgIconGoogle;
