import { chakra } from '@chakra-ui/react';
import Image from 'next/image';

// Next.js Image component styleable via Chakra
const RImage = chakra(Image, {
  shouldForwardProp: (prop) => {
    return [
      'height',
      'width',
      'quality',
      'src',
      'alt',
      'sizes',
      'style',
      'priority',
    ].includes(prop);
  },
});

export default RImage;
