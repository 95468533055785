import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconInfinity = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={12}
    fill="none"
    viewBox="0 0 16 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M12.078 2.273c1.875 0 3.422 1.524 3.422 3.399v.68c0 1.875-1.547 3.398-3.422 3.421-.89 0-1.758-.375-2.39-1.007L8 7.078 6.29 8.766A3.4 3.4 0 0 1 .5 6.352v-.68c0-1.875 1.523-3.399 3.398-3.422.891 0 1.758.375 2.391 1.008L8 4.945l1.688-1.687a3.389 3.389 0 0 1 2.39-.985ZM5.234 7.711l1.688-1.688-1.688-1.71a1.898 1.898 0 0 0-1.336-.54A1.89 1.89 0 0 0 2 5.672v.68c0 1.054.844 1.898 1.898 1.921.493 0 .985-.21 1.336-.562ZM14 6.35v-.68c0-1.054-.867-1.921-1.922-1.898-.492 0-.984.188-1.336.54l-1.687 1.71 1.687 1.688a1.92 1.92 0 0 0 1.336.562A1.93 1.93 0 0 0 14 6.352Z"
    />
  </svg>
));
export default SvgIconInfinity;
