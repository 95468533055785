import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMarketCar = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M1.875 6.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75 0 .422-.352.75-.75.75a.74.74 0 0 1-.75-.75Zm8.25 0c0 .422-.352.75-.75.75a.74.74 0 0 1-.75-.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75Zm-9-2.32.797-2.297A2.073 2.073 0 0 1 3.867.75H8.11c.868 0 1.664.563 1.946 1.383l.797 2.297C11.532 4.828 12 5.555 12 6.375v4.313a.555.555 0 0 1-.563.562.542.542 0 0 1-.562-.563V9.376h-9.75v1.313a.555.555 0 0 1-.563.562.542.542 0 0 1-.562-.563V6.375c0-.82.445-1.547 1.125-1.945Zm1.29-.305h7.147L9 2.508a.96.96 0 0 0-.89-.633H3.866a.96.96 0 0 0-.89.633l-.563 1.617ZM9.75 5.25h-7.5c-.633 0-1.125.516-1.125 1.125V8.25h9.75V6.375A1.14 1.14 0 0 0 9.75 5.25Z"
    />
  </svg>
));
export default SvgIconMarketCar;
