import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconImolaMinimapMarkers = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="m178.699 187.5-40.412 3.12c-2.525.446-3.417-1.188-3.714-2.228-.297-1.04-1.29-2.964-2.228-3.121-1.783-.297-3.121.595-3.863 1.041-.686.411-7.875 4.011-9.806 5.051-1.932 1.04-15.749 7.577-20.058 10.103-4.992 2.927-16.343 12.48-17.234 13.52-3.686 3.269-12.035 12.926-14.115 15.452-1.664 2.02-6.388 7.429-10.549 12.183-6.537 9.36-23.177 15.749-28.526 18.869-.573.335-6.388 2.971-7.725 4.754-1.337 1.783-.843 3.863-.446 4.903 2.08 4.259 6.626 12.867 7.577 14.412 1.189 1.931 3.227 2.861 4.16 2.971 2.526.297 4.755-.445 5.795-1.04 2.08-1.188 23.44-10.746 33.577-15.6 10.549-5.052 18.423-8.172 24.663-9.063 6.241-.891 29.418-1.783 37.887-2.526l.603-.053c8.096-.711 12.812-1.125 20.643-.69 7.011.39 6.388.892 29.269 4.309 23.421 3.498 30.606 1.443 38.629.297 6.24-.891 26.66-4.408 31.497-5.497 5.944-1.337 13.075-4.16 15.749-4.903 2.675-.743 4.649-1.203 5.646-2.823 1.189-1.931.743-1.486 1.783-7.429.832-4.754 2.823-5.348 4.754-6.685 3.863-2.18 12.569-6.757 15.452-9.063 3.714-2.972 4.754-7.132 5.349-9.212.248-.869 21.246-66.264 21.988-68.047.743-1.783.298-4.754.149-5.497-.148-.743-1.579-5.576-3.268-9.657-1.783-4.309.941-6.464 1.188-6.686 1.486-1.337 9.806-9.955 15.6-15.155 4.903-4.803 15.957-15.422 17.384-17.68 1.931-2.971.148-5.943-.595-6.983s-2.674-2.377-4.754-2.526c-1.664-.118-5.646.743-7.429 1.189-9.954 2.08-31.052 6.24-42.938 8.766-9.836 2.09-16.343 2.648-23.92 2.377-6.092-.218-25.555-3.12-29.715-3.12s-7.428 3.12-8.468 4.903c-1.04 1.783-.743 1.189-4.755 11.886-4.234 11.289-2.377 17.977-1.931 21.394.445 3.418 9.048 30.68 9.508 32.984.595 2.971-.445 4.308-1.04 5.348-1.436 2.774-3.981 7.34-4.457 8.172-.594 1.04-2.971 6.24-5.051 7.28s-6.983-1.783-9.063-2.377c-2.08-.594-4.903-.594-6.686-.446-1.783.149-32.241 2.526-36.104 2.823Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={10}
      d="m178.699 187.5-40.412 3.12c-2.525.446-3.417-1.188-3.714-2.228-.297-1.04-1.29-2.964-2.228-3.121-1.783-.297-3.121.595-3.863 1.041-.686.411-7.875 4.011-9.806 5.051-1.932 1.04-15.749 7.577-20.058 10.103-4.992 2.927-16.343 12.48-17.234 13.52-3.686 3.269-12.035 12.926-14.115 15.452-1.664 2.02-6.388 7.429-10.549 12.183-6.537 9.36-23.177 15.749-28.526 18.869-.573.335-6.388 2.971-7.725 4.754-1.337 1.783-.843 3.863-.446 4.903 2.08 4.259 6.626 12.867 7.577 14.412 1.189 1.931 3.227 2.861 4.16 2.971 2.526.297 4.755-.445 5.795-1.04 2.08-1.188 23.44-10.746 33.577-15.6 10.549-5.052 18.423-8.172 24.663-9.063 6.241-.891 29.418-1.783 37.887-2.526l.603-.053c8.096-.711 12.812-1.125 20.643-.69 7.011.39 6.388.892 29.269 4.309 23.421 3.498 30.606 1.443 38.629.297 6.24-.891 26.66-4.408 31.497-5.497 5.944-1.337 13.075-4.16 15.749-4.903 2.675-.743 4.649-1.203 5.646-2.823 1.189-1.931.743-1.486 1.783-7.429.832-4.754 2.823-5.348 4.754-6.685 3.863-2.18 12.569-6.757 15.452-9.063 3.714-2.972 4.754-7.132 5.349-9.212.248-.869 21.246-66.264 21.988-68.047.743-1.783.298-4.754.149-5.497-.148-.743-1.579-5.576-3.268-9.657-1.783-4.309.941-6.464 1.188-6.686 1.486-1.337 9.806-9.955 15.6-15.155 4.903-4.803 15.957-15.422 17.384-17.68 1.931-2.971.148-5.943-.595-6.983s-2.674-2.377-4.754-2.526c-1.664-.118-5.646.743-7.429 1.189-9.954 2.08-31.052 6.24-42.938 8.766-9.836 2.09-16.343 2.648-23.92 2.377-6.092-.218-25.555-3.12-29.715-3.12s-7.428 3.12-8.468 4.903c-1.04 1.783-.743 1.189-4.755 11.886-4.234 11.289-2.377 17.977-1.931 21.394.445 3.418 9.048 30.68 9.508 32.984.595 2.971-.445 4.308-1.04 5.348-1.436 2.774-3.981 7.34-4.457 8.172-.594 1.04-2.971 6.24-5.051 7.28s-6.983-1.783-9.063-2.377c-2.08-.594-4.903-.594-6.686-.446-1.783.149-32.241 2.526-36.104 2.823Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={4}
      d="m178.699 187.5-40.412 3.12c-2.525.446-3.417-1.188-3.714-2.228-.297-1.04-1.29-2.964-2.228-3.121-1.783-.297-3.121.595-3.863 1.041-.686.411-7.875 4.011-9.806 5.051-1.932 1.04-15.749 7.577-20.058 10.103-4.992 2.927-16.343 12.48-17.234 13.52-3.686 3.269-12.035 12.926-14.115 15.452-1.664 2.02-6.388 7.429-10.549 12.183-6.537 9.36-23.177 15.749-28.526 18.869-.573.335-6.388 2.971-7.725 4.754-1.337 1.783-.843 3.863-.446 4.903 2.08 4.259 6.626 12.867 7.577 14.412 1.189 1.931 3.227 2.861 4.16 2.971 2.526.297 4.755-.445 5.795-1.04 2.08-1.188 23.44-10.746 33.577-15.6 10.549-5.052 18.423-8.172 24.663-9.063 6.241-.891 29.418-1.783 37.887-2.526l.603-.053c8.096-.711 12.812-1.125 20.643-.69 7.011.39 6.388.892 29.269 4.309 23.421 3.498 30.606 1.443 38.629.297 6.24-.891 26.66-4.408 31.497-5.497 5.944-1.337 13.075-4.16 15.749-4.903 2.675-.743 4.649-1.203 5.646-2.823 1.189-1.931.743-1.486 1.783-7.429.832-4.754 2.823-5.348 4.754-6.685 3.863-2.18 12.569-6.757 15.452-9.063 3.714-2.972 4.754-7.132 5.349-9.212.248-.869 21.246-66.264 21.988-68.047.743-1.783.298-4.754.149-5.497-.148-.743-1.579-5.576-3.268-9.657-1.783-4.309.941-6.464 1.188-6.686 1.486-1.337 9.806-9.955 15.6-15.155 4.903-4.803 15.957-15.422 17.384-17.68 1.931-2.971.148-5.943-.595-6.983s-2.674-2.377-4.754-2.526c-1.664-.118-5.646.743-7.429 1.189-9.954 2.08-31.052 6.24-42.938 8.766-9.836 2.09-16.343 2.648-23.92 2.377-6.092-.218-25.555-3.12-29.715-3.12s-7.428 3.12-8.468 4.903c-1.04 1.783-.743 1.189-4.755 11.886-4.234 11.289-2.377 17.977-1.931 21.394.445 3.418 9.048 30.68 9.508 32.984.595 2.971-.445 4.308-1.04 5.348-1.436 2.774-3.981 7.34-4.457 8.172-.594 1.04-2.971 6.24-5.051 7.28s-6.983-1.783-9.063-2.377c-2.08-.594-4.903-.594-6.686-.446-1.783.149-32.241 2.526-36.104 2.823Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={156.266}
      y={234.747}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m166.493 242.823-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={251.353}
      y={228.506}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m258.757 241.742 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={280.771}
      y={245.741}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M287.957 259.505v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={277.799}
      y={201.763}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M288.906 215.155h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={297.41}
      y={134.608}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M304.699 148.372v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={330.394}
      y={127.476}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M337.703 141.24v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={273.342}
      y={73.395}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M280.498 87.16v-2.34l.756-.756-.636-.636V81.23l1.236-1.235h3.528l1.236 1.236v2.195l-.636.636.756.757v2.34l-1.236 1.236h-3.768l-1.236-1.237Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.391l.48.48h2.256Zm.12 3.647.48-.48v-1.523l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={318.211}
      y={94.196}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M329.772 102.224v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936H326.4l3.372-6.972Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={240.952}
      y={103.11}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M248.292 116.874v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={202.918}
      y={120.642}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m209.159 128.718-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={248.381}
      y={158.38}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m256.239 166.456-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.738 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={221.341}
      y={196.117}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m228.044 204.193-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.071 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={202.918}
      y={159.568}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m209.452 167.644-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={135.465}
      y={164.025}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m142.139 172.101-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={107.236}
      y={164.025}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m113.752 172.101-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.143 5.688v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={32.058}
      y={224.049}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m38.474 232.125-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.688v-5.928l1.236-1.236H46.5l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={6.206}
      y={236.827}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m13.226 244.903-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.91-.048v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936h-1.464l3.372-6.972Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={27.007}
      y={295.365}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m33.33 303.441-1.836.948v-1.284l2.076-1.14h1.152v8.4H33.33v-6.924Zm3.202 5.688v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48H40.9Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={74.253}
      y={239.798}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m80.64 247.874-1.836.948v-1.284l2.076-1.14h1.152v8.4H80.64v-6.924Zm3.322 5.688v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="m116.627 281.741-4.024.18-.202-4.521 4.024-.18zM116.223 272.699l-4.024.18-.202-4.521 4.024-.18zM115.818 263.656l-4.024.18-.202-4.521 4.024-.18zM115.413 254.614l-4.024.18-.202-4.521 4.024-.18zM115.009 245.571l-4.024.18-.202-4.521 4.024-.18zM112.4 277.4l-4.024.18-.202-4.521 4.024-.18zM111.996 268.357l-4.024.18-.202-4.521 4.024-.18zM111.592 259.315l-4.024.18-.202-4.521 4.024-.18zM111.188 250.272l-4.024.18-.202-4.521 4.024-.18z"
    />
    <path
      fill="#000"
      d="m112.603 281.921-4.024.18-.202-4.521 4.024-.18zM112.198 272.879l-4.024.18-.202-4.521 4.024-.18zM111.794 263.836l-4.024.18-.202-4.521 4.024-.18zM111.39 254.794l-4.024.18-.202-4.521 4.024-.18zM110.984 245.751l-4.024.18-.202-4.521 4.024-.18z"
    />
    <path
      fill="#000"
      d="m116.425 277.22-4.024.18-.202-4.521 4.024-.18zM116.021 268.177l-4.024.18-.202-4.521 4.024-.18zM115.615 259.135l-4.024.18-.202-4.521 4.024-.18zM115.211 250.092l-4.024.18-.202-4.521 4.024-.18z"
    />
    <path
      fill="#E2645A"
      d="m140.46 234.246-15.245-9.184.883 19.742 14.362-10.558Zm2.262-.101c.032.728-.236 1.42-.816 1.835l-15.068 11.026c-1.158.878-2.694-.025-2.766-1.626l-.929-20.759c-.069-1.553 1.379-2.638 2.608-1.915l15.994 9.685a2.21 2.21 0 0 1 .977 1.754Z"
    />
  </svg>
));
export default SvgIconImolaMinimapMarkers;
