import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSuit = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={16}
    fill="none"
    viewBox="0 0 21 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M20.219 5.688c.343.656.562 1.375.562 2.093v4.469c0 .969-.812 1.75-1.75 1.75h-1c-.968 0-1.75-.781-1.75-1.75V8.375l-.5-.781v6.656c0 .969-.812 1.75-1.75 1.75h-6.5c-.968 0-1.75-.781-1.75-1.75V7.594l-.5.781v3.875c0 .969-.812 1.75-1.75 1.75h-1c-.969 0-1.75-.781-1.75-1.75V7.781c0-.718.156-1.437.531-2.093l2.22-3.938C4.124.687 5.25 0 6.468 0H8.28a.76.76 0 0 1 .75.75c0 .969.781 1.75 1.75 1.75.938 0 1.75-.781 1.75-1.75a.74.74 0 0 1 .75-.75h1.781c1.22 0 2.344.688 2.938 1.75l2.219 3.938Zm-.938 6.562V7.781c0-.468-.125-.937-.375-1.343l-2.218-3.97a1.831 1.831 0 0 0-1.625-.968h-1.126A3.248 3.248 0 0 1 10.781 4a3.257 3.257 0 0 1-3.187-2.5H6.469c-.688 0-1.313.375-1.625.969L2.625 6.438c-.25.406-.344.875-.344 1.343v4.469c0 .156.094.25.25.25h1c.125 0 .25-.094.25-.25V8.156c0-.125.031-.281.094-.406l2-3.125c.156-.219.375-.375.656-.375.532 0 .75.531.75.75v9.25c0 .156.094.25.25.25h6.5c.125 0 .25-.094.25-.25V5c0-.219.188-.75.719-.719.281 0 .5.125.656.344l2 3.125c.063.125.125.25.125.406v4.094c0 .156.094.25.25.25h1c.125 0 .25-.094.25-.25Z"
    />
  </svg>
));
export default SvgIconSuit;
