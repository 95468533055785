import { useMutation } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';
import { MutationSalvageCarsArgs } from 'api/generated/graphql';

const useSalvageCars = (rest?: { [key: string]: unknown }) => {
  const { graphql, graphQLClient } = useGraphQLClient();

  const salvageCarsGQL = graphql(`
    mutation SalvageCars($carIds: [String!]!) {
      salvageCars(carIds: $carIds) {
        actionId
      }
    }
  `);

  const salvageCars = useMutation({
    mutationKey: ['salvageCars'],
    mutationFn: ({ carIds }: MutationSalvageCarsArgs) => {
      return graphQLClient.request(salvageCarsGQL, { carIds });
    },
    ...rest,
  });

  return salvageCars;
};

export default useSalvageCars;
