import { useQuery } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

const useGetAvailableTrailers = (walletAddress?: string, source?: string) => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const getAvailableTrailersGql = graphql(`
    query getTrailers($walletAddress: String, $source: String) {
      getAvailableTrailers(walletAddress: $walletAddress, source: $source) {
        tokenId
      }
    }
  `);

  const getAvailableTrailers = useQuery({
    enabled: Boolean(hasToken && (walletAddress || source)),
    queryKey: ['trailersQuery'],
    queryFn: async () => {
      return graphQLClient.request(getAvailableTrailersGql, {
        walletAddress,
        source,
      });
    },
  });
  return getAvailableTrailers;
};

export default useGetAvailableTrailers;
