import { useState, useEffect } from 'react';

interface LazyImageLoaderProps {
  placeholder: string;
  src: string;
}

const useLazyImageLoader = ({ placeholder, src }: LazyImageLoaderProps) => {
  const [currentSrc, setCurrentSrc] = useState(placeholder);

  useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      setCurrentSrc(src);
    };

    imageLoader.onerror = (err) => {
      console.error('Failed to load image', err);
    };

    return () => {
      imageLoader.onload = null;
      imageLoader.onerror = null;
    };
  }, [src, placeholder]);

  return currentSrc;
};

export default useLazyImageLoader;
