import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRace = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    viewBox="0 0 17 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M15.375 0c.594 0 1.125.375 1.125.969v9.437c0 .375-.25.719-.688.875-1.343.531-2.468.719-3.468.719-2.313 0-3.906-.969-6.219-.969-1.125 0-2.469.25-4.125.969v3.25a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75V.75A.74.74 0 0 1 1.25 0 .76.76 0 0 1 2 .75v.281c1.844-.75 3.188-1 4.281-1 2.157 0 3.25 1 5.125 1 .875 0 1.938-.218 3.344-.875.219-.093.406-.156.625-.156ZM15 10V1.687c-1.344.563-2.5.844-3.594.844-1.156 0-2.062-.312-2.844-.562-.75-.219-1.406-.438-2.28-.438-1.032 0-2.25.282-3.72.875L2 2.656v7.719c1.438-.563 2.813-.844 4.125-.844 1.344 0 2.469.281 3.469.531.937.22 1.781.438 2.75.438.812 0 1.687-.156 2.656-.5Z"
    />
  </svg>
));
export default SvgIconRace;
