import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetVextMessageForCars = ({
  vextRate,
  deadline,
  bundleId,
}: {
  vextRate: string;
  deadline: string;
  bundleId: number;
}) => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();

  const getVextMessageFn = graphql(`
    query getVextMessageForTickets(
      $vextMessageReq: VextMessageReq!
      $bundleId: Float!
    ) {
      getVextMessageForTickets(
        vextMessageReq: $vextMessageReq
        bundleId: $bundleId
      ) {
        tokenPriceVEXT
        readablePriceVEXT
        r
        s
        v
      }
    }
  `);

  const getVextMessageForTickets = useQuery({
    enabled: Boolean(hasToken) && !!vextRate,
    queryKey: ['getVextMessageQuery'],
    queryFn: async () => {
      return await graphQLClient.request(getVextMessageFn, {
        vextMessageReq: { vextRate, deadline: Number(deadline) },
        bundleId,
      });
    },
  });

  return getVextMessageForTickets;
};

export default useGetVextMessageForCars;
