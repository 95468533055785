import { Box, Flex, Text } from "@chakra-ui/react"

interface PartInfoProps {
  title1: string;
  status1: string;
  title2?: string;
  status2?: string;
}

const PartInfo = ({ title1, title2, status1, status2 }: PartInfoProps) => {
  const getBoxConfig = (status: string) => {
    const boxes = [
      { color: "carbon.100", content: "" },
      { color: "carbon.100", content: "" },
      { color: "white.80", content: "=", rounded: true },
      { color: "carbon.100", content: "" },
      { color: "carbon.100", content: "" },
    ];

    if (status === "negative-1") {
      boxes[1].color = "bloodMoon.100";
      boxes[2].color = "bloodMoon.100";
      boxes[2].content = "-"
    } else if (status === "negative-2") {
      boxes[0].color = "bloodMoon.100";
      boxes[1].color = "bloodMoon.100";
      boxes[2].color = "bloodMoon.100";
      boxes[2].content = "-"
    } else if (status === "positive-1") {
      boxes[2].color = "baltic.100";
      boxes[2].content = "+"
      boxes[3].color = "baltic.100";
    } else if (status === "positive-2") {
      boxes[2].color = "baltic.100";
      boxes[3].color = "baltic.100";
      boxes[2].content = "+"
      boxes[4].color = "baltic.100";
    }

    return boxes;
  };

  const boxConfig = getBoxConfig(status1);
  const boxConfig2 = getBoxConfig(status2 || "");

  return (
    <Flex px="1" flexDir="column" zIndex={9999} justifyContent="flex-start">
       <Flex justifyContent="space-between" flexDir="column">
          <Text
            textTransform="uppercase"
            color={"white.80"}
            fontFamily="heading"
            fontSize="md"
          >
            {title1}
          </Text>
        <Flex gap={1} alignItems="center" w="100%">
          {boxConfig.map((box, index) => (
            <Flex 
              key={index} 
              justifyContent="center" 
              alignItems="center"
              color="black.60"
              fontSize="md" 
              w={box.rounded ? "24" : "100%"}
              h={box.rounded ? "" : "1"} 
              bg={box.color} 
              rounded={box.rounded ? "full" : "undefined"}
            >
              {box.content}
            </Flex>
          ))}
        </Flex>
      </Flex>
       {title2 && (
        <Flex justifyContent="space-between" flexDir="column">
          <Text 
            textTransform="uppercase"
            color={"white.80"}
            fontFamily="heading"
            fontSize="md"
          >
            {title2}
          </Text>
          <Flex gap={1} alignItems="center" w="100%">
            {boxConfig2.map((box, index) => (
              <Flex 
                key={index} 
                justifyContent="center" 
                alignItems="center"
                color="black.60"
                fontSize="md" 
                w={box.rounded ? "24" : "100%"}
                h={box.rounded ? "" : "1"} 
                bg={box.color} 
                rounded={box.rounded ? "full" : "undefined"}
              >
                {box.content}
              </Flex>
            ))}
          </Flex>
        </Flex>
       )}
    </Flex>
  )
}

export default PartInfo;