import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSettingsWrench = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    viewBox="0 0 21 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M19.805 5.61c.508 2.03-.117 4.218-1.602 5.702a6.002 6.002 0 0 1-5.625 1.602L5.43 20.063A3.194 3.194 0 0 1 3.164 21c-.82 0-1.719-.352-2.266-.938A3.326 3.326 0 0 1 0 17.797c0-.82.313-1.64.898-2.266l7.188-7.148c-.469-2.031.117-4.14 1.601-5.586 1.524-1.524 3.633-2.11 5.704-1.602.507.117.859.508 1.015.977.117.469 0 .976-.351 1.328L13.75 5.766l.195 1.289 1.29.195 2.304-2.305c.313-.312.82-.468 1.328-.351.469.156.86.508.938 1.015Zm-2.93 4.374c.82-.78 1.25-1.875 1.25-2.968L15.859 9.28l-3.515-.625-.586-3.515 2.187-2.266h-.039c-1.054 0-2.11.43-2.89 1.25-1.133 1.094-1.485 2.734-.899 4.258l.156.547-7.968 7.93c-.235.273-.39.585-.39.937 0 .39.155.703.39.937.468.508 1.367.508 1.836 0l7.968-7.93.547.196a4.038 4.038 0 0 0 4.219-1.016ZM3.398 16.938c.352 0 .625.312.586.625 0 .312-.273.625-.586.625-.312 0-.586-.313-.586-.625 0-.352.235-.625.586-.625Z"
    />
  </svg>
));
export default SvgIconSettingsWrench;
