import { createContext, useContext, useState } from 'react';

interface UpgradeModalContextProps {
  isUpgradeDrawerOpen: boolean;
  onUpgradeDrawerOpen: () => void;
  onUpgradeDrawerClose: () => void;
  isRenewDrawerOpen: boolean;
  onRenewDrawerOpen: () => void;
  onRenewDrawerClose: () => void;
}

const UpgradeModalContext = createContext<UpgradeModalContextProps | undefined>(undefined);

export const UpgradeModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isUpgradeDrawerOpen, setIsUpgradeDrawerOpen] = useState(false);
  const [isRenewDrawerOpen, setIsRenewDrawerOpen] = useState(false);

  const onUpgradeDrawerOpen = () => setIsUpgradeDrawerOpen(true);
  const onUpgradeDrawerClose = () => setIsUpgradeDrawerOpen(false);
  const onRenewDrawerOpen = () => setIsRenewDrawerOpen(true);
  const onRenewDrawerClose = () => setIsRenewDrawerOpen(false);

  return <UpgradeModalContext.Provider value={{ isUpgradeDrawerOpen, onUpgradeDrawerOpen, onUpgradeDrawerClose, isRenewDrawerOpen, onRenewDrawerOpen, onRenewDrawerClose }}>{children}</UpgradeModalContext.Provider>;
};

export const useUpgradeModal = () => {
  const context = useContext(UpgradeModalContext);
  if (!context) {
    throw new Error('useUpgradeModal must be used within an UpgradeModalProvider');
  }
  return context;
};
