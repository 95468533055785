import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRenew = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="m6.563 6.055.898 3.633a.942.942 0 0 1-.703 1.132c-.078.04-.156.04-.235.04a.95.95 0 0 1-.898-.704L5.312 8.79l-2.656 4.414c-.234.39-.234.86-.039 1.211l.078.234c.235.43.664.665 1.133.665h1.485c.507 0 .937.43.937.976 0 .508-.43.898-.938.898H3.829a3.184 3.184 0 0 1-2.812-1.718l-.079-.196c-.468-.976-.43-2.109.118-3.007l2.5-4.22-1.094.43c-.508.157-1.016-.078-1.211-.546-.195-.508.078-1.055.547-1.211L5.313 5.39c.273-.079.546-.079.78.039.235.117.391.351.47.625Zm.39-1.797c-.469-.274-.586-.82-.351-1.29l.664-1.093A3.164 3.164 0 0 1 10 .312c1.094 0 2.11.586 2.695 1.563l2.11 3.516L15 4.258a.892.892 0 0 1 1.094-.742c.508.078.82.546.742 1.054l-.625 3.711a.958.958 0 0 1-.43.664 1.04 1.04 0 0 1-.469.117h-.273L11.406 7.97c-.469-.117-.781-.664-.625-1.133a.978.978 0 0 1 1.172-.664l1.367.43-2.265-3.79c-.43-.742-1.72-.742-2.149 0l-.664 1.133c-.273.43-.86.586-1.289.313Zm11.953 8.008c.547.898.586 2.03.117 3.007l-.078.196a3.184 3.184 0 0 1-2.812 1.719H11.21l.937.898c.391.312.43.937.079 1.289-.157.234-.43.313-.665.313a.887.887 0 0 1-.624-.235l-2.813-2.5c-.234-.156-.313-.43-.313-.703 0-.234.079-.508.313-.664l2.813-2.5c.351-.352.976-.313 1.328.078.312.352.273.977-.079 1.29l-.976.858h4.922c.469 0 .898-.234 1.133-.664l.078-.195c.195-.39.195-.86-.04-1.25l-.898-1.445c-.234-.469-.117-1.016.352-1.29.43-.273 1.015-.116 1.289.313l.86 1.485Z"
    />
  </svg>
));
export default SvgIconRenew;
