import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSuccessCircle = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    viewBox="0 0 20 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M13.281 7.57 8.75 12.14l-2.07-2.07a.662.662 0 0 0-.899 0 .662.662 0 0 0 0 .899l2.5 2.5c.117.117.274.156.469.156.156 0 .313-.04.43-.156l5-5a.662.662 0 0 0 0-.899.662.662 0 0 0-.899 0ZM10 .5C4.453.5 0 4.992 0 10.5a9.97 9.97 0 0 0 10 10c5.508 0 10-4.453 10-10 0-5.508-4.492-10-10-10Zm0 18.75a8.736 8.736 0 0 1-8.75-8.75c0-4.805 3.906-8.75 8.75-8.75 4.805 0 8.75 3.945 8.75 8.75 0 4.844-3.945 8.75-8.75 8.75Z"
    />
  </svg>
));
export default SvgIconSuccessCircle;
