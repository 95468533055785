import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGameplay = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M17.5 4c.25 0 .5.25.5.5v4c0 .25-.25.5-.5.5H16v2.5c0 .25-.25.5-.5.5H14v2.5c0 .25-.25.5-.5.5h-3a.494.494 0 0 1-.5-.5V14c0-.219.219-.469.5-.469h2v-1.5h-7v1.5h2c.25 0 .5.219.5.469v.5c0 .25-.25.5-.5.5h-3c-.281 0-.5-.25-.5-.5V12H2.5c-.281 0-.5-.25-.5-.5V9H.5C.219 9 0 8.75 0 8.5v-4c0-.25.219-.5.5-.5H1c.25 0 .5.25.5.5v3H3v-2c0-.25.219-.5.5-.5h1V3h-1a.494.494 0 0 1-.5-.5v-1c0-.25.219-.5.5-.5l.5.031c.25 0 .5.219.5.469V2h1c.25 0 .5.25.5.5V4h6V2.5c0-.25.219-.5.5-.5h1v-.5c0-.25.219-.5.5-.5h.5c.25 0 .5.25.5.5v1c0 .281-.25.5-.5.5h-1v2h1c.25 0 .5.25.5.5v2h1.5v-3c0-.25.219-.5.5-.5h.5Zm-3 6.5V9h-1V6.5H12v-1H6v1H4.5V9h-1v1.5h11ZM7.5 7c.25 0 .5.25.5.5V9c0 .281-.25.5-.5.5h-1A.494.494 0 0 1 6 9V7.5c0-.25.219-.5.5-.5h1Zm4 0c.25 0 .5.25.5.5V9c0 .281-.25.5-.5.5h-1A.494.494 0 0 1 10 9V7.5c0-.25.219-.5.5-.5h1Z"
    />
  </svg>
));
export default SvgIconGameplay;
