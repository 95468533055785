import { Flex, Link, Text } from "@chakra-ui/react"
import RImage from "components/Image/Image"

export const DesktopMenu = () => {
  return (
    <>
      <Flex display={["none", "flex"]}>
        <RImage 
          src={require("public/images/Logo.png")}
          alt="racino logo"
          width="75%"
        />
      </Flex>
      <Flex gap={8} display={["none", "none", "flex", "flex"]}>
        <Link>
          <Text>
            GETTING STARTED
          </Text>
        </Link>
        <Link>
          <Text>
            SUPPORT
          </Text>
        </Link>
      </Flex>
    </>
  )
}