import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

const useRedeemSingleTrailerSchedule = () => {
  const { graphQLClient, graphql } = useGraphQLClient();
  const queryClient = useQueryClient()

  const redeemSingleTrailerScheduleGQL = graphql(`
    mutation RedeemSingleTrailerSchedule(
      $walletAddress: String
      $tokenId: String!
      $source: String
    ) {
      redeemSingleTrailerSchedule(
        walletAddress: $walletAddress
        tokenId: $tokenId
        source: $source
      ) {
        externalWalletAddress
        tokenId
        status
        referenceId
      }
    }
  `);

  const RedeemSingleTrailerSchedule = useMutation({
    mutationKey: ['redeemSingleTrailerSchedule'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['garageQuery'] })
    },
    mutationFn: async ({
      walletAddress,
      tokenId,
      source,
    }: {
      walletAddress?: string;
      tokenId: string;
      source?: string;
    }) => {
      return graphQLClient.request(redeemSingleTrailerScheduleGQL, {
        walletAddress,
        tokenId,
        source,
      });
    },
  });

  return RedeemSingleTrailerSchedule;
};

export default useRedeemSingleTrailerSchedule;
