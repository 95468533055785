import { Box, Button, Heading, Text, useDisclosure } from '@chakra-ui/react';
import { CrossmintEvent } from '@crossmint/client-sdk-react-ui';
import { useQueryClient } from '@tanstack/react-query';

import { CurrencyType, MutationTicketPaymentProcessSucceededEventArgs } from 'api/generated/graphql';
import { RAlert, RTicketCard } from 'components';
import { CheckoutModal } from 'components/CheckoutModal/CheckoutModal';
import { CryptoTabTicket } from 'components/CheckoutModal/Crypto/CryptoTabTicket';
import { useAnimatedTransition, useLoadingTrailer } from 'context';
import { useGetTicketPrice, useTicketPaymentProcessSucceeded } from 'hooks';
import { IconUnlocked } from 'icons';
import { useEffect, useState } from 'react';

interface AdmissionUseProps {
  admissionFee: number;
  currency?: CurrencyType;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const AdmissionUse = ({
  admissionFee,
  currency = CurrencyType.Usd,
  isOpen,
  onOpen,
  onClose,
}: AdmissionUseProps) => {
  const { setIsLoadingTickets } = useLoadingTrailer();
  const queryClient = useQueryClient();
  
  const paymentEnv =
  process.env.NEXT_PUBLIC_ENV === 'dev' ? 'staging' : 'production';
  const ticketsContractId = process.env.NEXT_PUBLIC_TICKETS_CONTRACT_ID || '';
  const projectId = process.env.NEXT_PUBLIC_CROSSMINT_API_ID || '';

  const { showAnimatedTransition } = useAnimatedTransition();
  const { mutateAsync } = useTicketPaymentProcessSucceeded();

  const { data: bundle1Data, isLoading: isLoadingBundle1 } =
    useGetTicketPrice(1);

  const bundle1 = bundle1Data?.ticketPrice;

  const isLoading = isLoadingBundle1

  const onSuccessBuy = async (e: CrossmintEvent, bundleId: number) => {
    showAnimatedTransition({
      Icon: IconUnlocked,
      title: 'Ticket',
      id: 'ticket-purchased-success',
      subtitle: 'Purchased',
      color: 'treasuredTeal.100',
      bgColor:
        'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
      gradientColor: {
        from: 'rgba(72, 223, 187, 0.2)',
        to: 'rgba(72, 223, 187, 0)',
      },
    });

    console.log('==> Mutate ticket events <==');
    const input = {
      succeededInput: {
        currency: CurrencyType.Usd,
        bundleId,
      },
    } as MutationTicketPaymentProcessSucceededEventArgs;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const orderIdentifier = e?.payload?.orderIdentifier;
    if (orderIdentifier) {
      console.log('orderIdentifier', orderIdentifier);
      //todo: cannot use individual fields as they are not included with crossmint types; need different type wrappers?
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if (uuidRegex.test(orderIdentifier)) {
        input.succeededInput.orderIdentifier = orderIdentifier;
      } else {
        input.succeededInput.actionId = orderIdentifier;
      }
    }
    await mutateAsync(input);
    await queryClient.refetchQueries({
      queryKey: ['ticketsAvailable'],
    });
    onClose()
  };


  return (
    <Box height="fit-content" pb="20">
      <Heading
        fontWeight="light"
        as="h1"
        size="lg"
        textTransform="uppercase"
        mb="3"
      >
        Admission Fee
      </Heading>

      <Box mb="8">
        <Text mb="4">The cost of admissision into the race is:</Text>
      </Box>

      <RAlert
        variant="info"
        description="Please note: If the race gets cancelled we will refund your ticket."
        mb="1rem"
      />

      <RTicketCard fee={admissionFee} currency={currency} />

      <CheckoutModal
        isOpen={isOpen}
        onClose={onClose}
        checkoutValue={'100'}
        collectionId={ticketsContractId}
        environment={paymentEnv}
        mintConfig={{
          totalPrice: String((bundle1?.price || 5) * admissionFee),
          bundleId: 1,
          quantity: admissionFee,
        }}
        CryptoTab={CryptoTabTicket}
        cryptoTabProps={{
          onSuccess: (e) => onSuccessBuy(e, 1),
          bundleId: 1,
        }}
        projectId={projectId}
        title="Buy Racino Tickets"
        onSuccessfulPurchase={(e) => onSuccessBuy(e, 1)}
      />
    </Box>
  );
};

export default AdmissionUse;
