import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDnaFusion = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={20}
    fill="none"
    viewBox="0 0 16 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M1 19.375c.078-1.445.82-6.016 5.742-9.375C1.82 6.64 1.078 2.07 1 .664.96.391 1.195 0 1.586 0h.586c.312 0 .586.273.625.586.039.156.039.39.078.664h11.21c.04-.273.08-.508.08-.664.038-.313.312-.586.624-.586h.586c.39 0 .625.352.586.664-.078 1.524-.938 6.797-7.07 10.156-1.29.703-2.266 1.524-3.086 2.344h5.312c-.351-.312-.625-.625-.976-.898a28.436 28.436 0 0 0 1.601-1.055c3.594 3.203 4.14 6.992 4.219 8.164a.553.553 0 0 1-.586.625h-.586c-.312 0-.586-.234-.664-.547 0-.156-.04-.39-.078-.703H2.875c-.04.313-.078.547-.078.703a.632.632 0 0 1-.625.547h-.586c-.39 0-.625-.352-.586-.625Zm12.578-16.25H3.422A7.64 7.64 0 0 0 4.359 5h8.282c.39-.625.703-1.29.937-1.875ZM8.5 8.945a12.006 12.006 0 0 0 2.617-2.07H5.844A11.4 11.4 0 0 0 8.5 8.945Zm-5.117 7.93h10.156A13.97 13.97 0 0 0 12.602 15H4.32c-.43.664-.703 1.328-.937 1.875Z"
    />
  </svg>
));
export default SvgIconDnaFusion;
