import { Flex, ModalProps } from '@chakra-ui/react';

import { RModal, RLoadingAnimation } from 'components';
import { useGetRaceResultsById, useGetRaceTips, useSessionData } from 'hooks';
import { RaceParticipants } from 'api/generated/graphql';
import { CarPerformanceStats } from './CarPerformanceStats';
import { getSpiderData } from './ChartData';
import { AidrianaTips } from './AidrianaTips';

interface PerformanceModalProps
  extends Omit<ModalProps, 'children' | 'onOpen'> {
  raceId: string;
}

const RPerformanceModal = ({
  isOpen,
  onClose,
  raceId,
}: PerformanceModalProps) => {
  const { sessionData } = useSessionData();

  const { data: raceData, isLoading: isLoadingRaceResults } =
    useGetRaceResultsById(raceId);
  const results = raceData?.getRaceResults;

  const currentPlayer = (
    results?.playersParticipants as RaceParticipants[]
  )?.find((player) => {
    if (!player.user) {
      return null;
    }

    return player.user.email === sessionData?.userAttributes?.email;
  });

  const playerAtPosition = (position: number) => {
    return results?.playersParticipants?.find((p) => p.position === position);
  };
  const firstPlacePlayer = playerAtPosition(1);

  // if the current user placed 1st, compare to 2nd place
  // if the current user didn't place first, compare to 1st place
  const comparisonPlayer =
    firstPlacePlayer?.id === currentPlayer?.id
      ? playerAtPosition(2)
      : results?.playersParticipants.find((p) => p.id === currentPlayer?.id);

  const { data: raceTipsData, isLoading: isLoadingRaceTips } =
    useGetRaceTips(raceId);

  const isLoading = isLoadingRaceResults || isLoadingRaceTips;

  const getScores = (participant: RaceParticipants) => {
    return [
      participant?.topSpeedScore,
      participant?.highSpeedScore,
      participant?.lowSpeedScore,
      participant?.brakingScore,
      participant?.coolingScore,
    ];
  };

  const chartData = getSpiderData({
    data1: getScores(firstPlacePlayer as RaceParticipants),
    data2: getScores(comparisonPlayer as RaceParticipants),
  });

  return (
    <RModal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      isCentered
      variant="feedbackMOdal"
      title="Car Feedback"
    >
      <Flex
        w="full"
        gap="4"
        maxH={'80vh'}
        flexDir={'column'}
        overflowY={'scroll'}
        className="red-scrollbar"
        pr={4}
        pb={10}
      >
        {isLoading && (
          <Flex justifyContent="center" width="full">
            <RLoadingAnimation />
          </Flex>
        )}

        {!isLoading && (
          <>
            <AidrianaTips tips={raceTipsData?.getRaceTips as string[]} />
            <Flex gap={4} w="full" px={4}>
              <CarPerformanceStats
                player={firstPlacePlayer as RaceParticipants}
                spiderData={chartData}
                style="first"
              />

              <CarPerformanceStats
                comparisonPlayer={comparisonPlayer as RaceParticipants}
                spiderData={chartData}
                style="comparisson"
              />
            </Flex>
          </>
        )}
      </Flex>
    </RModal>
  );
};

export default RPerformanceModal;
