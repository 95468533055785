import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWrenchUpgrade = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    viewBox="0 0 17 17"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M15.844 4.688c.406 1.625-.094 3.375-1.281 4.562a4.801 4.801 0 0 1-4.5 1.281l-5.72 5.719c-.5.5-1.155.75-1.812.75-.656 0-1.375-.281-1.812-.75A2.66 2.66 0 0 1 0 14.437c0-.656.25-1.312.719-1.812l5.75-5.719c-.375-1.625.093-3.312 1.281-4.468a4.764 4.764 0 0 1 4.563-1.282c.406.094.687.407.812.782.094.375 0 .78-.281 1.062L11 4.813l.156 1.03L12.187 6l1.844-1.844a1.15 1.15 0 0 1 1.063-.281c.375.125.687.406.75.813Zm-2.344 3.5c.656-.626 1-1.5 1-2.376l-1.813 1.813-2.812-.5-.469-2.813 1.75-1.812h-.031c-.844 0-1.688.344-2.313 1-.906.875-1.187 2.188-.718 3.406l.125.438-6.375 6.343c-.188.22-.313.47-.313.75 0 .313.125.563.313.75.375.407 1.093.407 1.468 0l6.376-6.343.437.156a3.23 3.23 0 0 0 3.375-.813ZM2.719 13.75c.281 0 .5.25.469.5 0 .25-.22.5-.47.5-.25 0-.468-.25-.468-.5 0-.281.188-.5.469-.5Z"
    />
  </svg>
));
export default SvgIconWrenchUpgrade;
