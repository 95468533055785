import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRoadway = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={18}
    fill="none"
    viewBox="0 0 24 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M23.383 15.25c.43 1.25-.469 2.5-1.797 2.5H13.5v-3.125c0-.313-.313-.625-.625-.625h-1.25a.642.642 0 0 0-.625.625v3.125H2.875c-1.328 0-2.227-1.25-1.797-2.5L6 1.5A1.857 1.857 0 0 1 7.758.29H11v3.124c0 .313.273.625.625.625h1.25c.313 0 .625-.312.625-.625V.29h3.203c.781 0 1.485.469 1.758 1.211l4.922 13.75ZM13.5 10.875v-3.75c0-.313-.313-.625-.625-.625h-1.25a.642.642 0 0 0-.625.625v3.75c0 .352.273.625.625.625h1.25a.642.642 0 0 0 .625-.625Z"
    />
  </svg>
));
export default SvgIconRoadway;
