import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCountdownTimer = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M6.938.75c.332 0 .625.293.625.625A.63.63 0 0 1 6.938 2h-.313v.684c.723.117 1.387.41 1.934.859l.43-.41a.602.602 0 0 1 .878 0 .602.602 0 0 1 0 .879l-.469.468c.41.645.665 1.407.665 2.207A4.072 4.072 0 0 1 6 10.75a4.06 4.06 0 0 1-4.063-4.063 4.04 4.04 0 0 1 3.438-4.003V2h-.313a.617.617 0 0 1-.625-.625.63.63 0 0 1 .625-.625h1.875Zm-.47 3.75A.475.475 0 0 0 6 4.031a.463.463 0 0 0-.469.469V7c0 .273.196.469.469.469A.463.463 0 0 0 6.469 7V4.5Z"
    />
  </svg>
));
export default SvgIconCountdownTimer;
