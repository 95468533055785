import { useState } from 'react';
import { GetTierUpdatedCarsQuery } from 'api/generated/graphql';

export type TierUpdatedCars = {
  data: GetTierUpdatedCarsQuery | undefined;
};

export type TierUpdatedCar = {
  __typename?: 'Cars' | undefined;
  id: string;
  name?: string | null | undefined;
  points: number;
  livery?: string | null | undefined;
  tier: {
    __typename?: 'Tiers' | undefined;
    id: string;
    name: string;
    rank: number;
    pointsRequired: number;
  };
};

const useCarTierLocalStore = () => {
  const [tierUpdatedCars, setTierUpdatedCars] = useState<TierUpdatedCar[]>();

  const saveTierUpdatedCars = (TierUpdatedCarsResponse: TierUpdatedCars) => {
    if (
      TierUpdatedCarsResponse?.data?.getTierUpdatedCars &&
      TierUpdatedCarsResponse?.data?.getTierUpdatedCars.length > 0
    ) {
      setTierUpdatedCars(TierUpdatedCarsResponse?.data?.getTierUpdatedCars);
    }
  };

  const clearTierUpdatedCars = () => {
    setTierUpdatedCars([]);
  };

  return { tierUpdatedCars, saveTierUpdatedCars, clearTierUpdatedCars };
};

export default useCarTierLocalStore;
