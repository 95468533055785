import { useQuery } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

const useGetSavedSetups = ({ carId }: { carId: string }) => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();

  const getSavedSetupsFn = graphql(`
    query GetSavedSetups($carId: String!) {
      getSavedSetups(carId: $carId) {
        id
        name
        car {
          id
          name
        }
        frontWingId
        frontWingTuning
        rearWingId
        rearWingTuning
        engineModeId
        engineModeTuning
        transmissionId
        transmissionTuning
        brakeCoolingId
        brakeCoolingTuning
        suspensionRideHeightId
        suspensionRideHeightTuning
        suspensionStiffnessId
        suspensionStiffnessTuning
        engineCoolingId
        engineCoolingTuning
        tyresId
      }
    }
  `);

  const getSavedSetups = useQuery({
    enabled: Boolean(hasToken && carId),
    queryKey: ['getSavedSetups', carId],
    queryFn: async () => {
      return graphQLClient.request(getSavedSetupsFn, {
        carId,
      });
    },
  });

  return getSavedSetups;
};

export default useGetSavedSetups;
