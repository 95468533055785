import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconVextTrial = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#7A7AFF"
      fillRule="evenodd"
      d="M21.9 12c0 5.468-4.432 9.9-9.9 9.9S2.1 17.468 2.1 12 6.532 2.1 12 2.1s9.9 4.432 9.9 9.9Zm1.5 0c0 6.296-5.104 11.4-11.4 11.4S.6 18.296.6 12 5.704.6 12 .6 23.4 5.704 23.4 12ZM4.2 7.8l7.8 2.29 7.8-2.29-5.86 6.336 2.352 3.885L12 16.056l-4.292 1.965 2.352-3.885L4.2 7.8Zm2.559 1.61L12 11.212l5.241-1.802L12 12.66 6.759 9.41ZM12 13.353 6.484 9.44l4.567 4.638-2.614 3.22L12 14.742l3.563 2.555-2.614-3.22 4.567-4.638L12 13.353Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconVextTrial;
