import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { buttonTheme } from 'components/Button/buttonTheme';
import { defaultGradient } from 'utils/themeConstants';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const primary = definePartsStyle({
  button: buttonTheme,
  list: {
    bg: defaultGradient(100),
    borderRadius: '0',
    borderColor: 'white.40',
    p: 0,
  },
  item: {
    bg: defaultGradient(100),
    border: '1px solid',
    borderRadius: '0',
    borderColor: 'white.20',
  },
  groupTitle: {},
  command: {},
  divider: {},
});

const secondary = definePartsStyle({
  button: {
    bg: 'white.10',
    color: 'white.80',
    borderRadius: '0',
    border: 'none',
    fontFamily: 'heading',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
    w: 'fit-content',
    p: 2,
    px: 4,
    _hover: {
      bgColor: 'white.20',
      _disabled: {
        bgColor: 'white.10',
      },
    },
  },
  list: {
    bg: 'eerieBlack.100',
    border: '1px solid',
    borderColor: 'caviar.100',
    borderRadius: '8px',
    zIndex: 9999,
  },
  item: {
    bg: 'eerieBlack.100',
    px: 6,
    py: 3,
    textTransform: 'uppercase',
    fontFamily: 'heading',
    color: 'white.80',
    _hover: {
      cursor: 'pointer',
      bg: 'caviar.100',
    },
  },
});

const tertiary = definePartsStyle({
  list: {
    bgColor: 'eerieBlack.100',
    border: '1px solid',
    borderColor: 'caviar.100',
    borderRadius: '1px',
    zIndex: 9999,
  },
  item: {
    bgColor: 'eerieBlack.100',
    px: 6,
    py: 3,
    textTransform: 'uppercase',
    fontFamily: 'heading',
    color: 'white.80',
    _hover: {
      cursor: 'pointer',
      bg: 'caviar.100',
    },
  },
});

export const menuTheme = defineMultiStyleConfig({
  variants: { primary, secondary, tertiary },
});
