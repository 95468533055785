import { Box, BoxProps, Heading, Text } from '@chakra-ui/react';

import { IconCar } from 'icons';

const SalvageEmptyCard = ({
  isSelected,
  ...rest
}: BoxProps & {
  isSelected?: boolean;
}) => {
  return (
    <Box
      w={["20rem", "33.75rem"]}
      h={["10.5rem", "17.188rem"]}
      borderWidth="0.063rem"
      borderColor="white.60"
      borderStyle="dashed"
      cursor="pointer"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      {...rest}
    >
      <IconCar mb="2" />
      <Heading fontWeight="normal" fontSize="1.5rem" textTransform="uppercase">
        Empty
      </Heading>
      <Text color="white.60" fontSize="0.75rem" textTransform="uppercase">
        select a car from the carousal below
      </Text>
    </Box>
  );
};

export default SalvageEmptyCard;
