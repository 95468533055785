import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIcon2Cars = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    viewBox="0 0 20 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M3.875 0h4.219C9 0 9.78.594 10.03 1.469l.656 2.375c.344.156.594.406.813.687a3.257 3.257 0 0 0-1.063.344c-.25-.219-.593-.375-.937-.375h-7A1.5 1.5 0 0 0 1 6v2h7.594l-.188.688c-.125.093-.25.218-.375.312H1v1c0 .281-.25.5-.5.5A.494.494 0 0 1 0 10V6c0-.938.5-1.75 1.281-2.156l.657-2.375A2.007 2.007 0 0 1 3.875 0Zm0 1c-.469 0-.844.313-.969.75l-.5 1.75h7.157l-.5-1.75c-.126-.438-.5-.75-.97-.75H3.876ZM1.75 6.25a.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75Zm8 5.5a.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75Zm8.5 0a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75ZM11.875 5.5h4.219c.906 0 1.687.594 1.937 1.469l.657 2.375A2.428 2.428 0 0 1 20 11.5v4c0 .281-.25.5-.5.5a.494.494 0 0 1-.5-.5v-1H8.969v1c0 .281-.219.5-.5.5-.25 0-.5-.219-.5-.5v-4c0-.938.531-1.75 1.312-2.156l.656-2.375A2.007 2.007 0 0 1 11.876 5.5Zm0 1c-.469 0-.844.313-.969.75l-.5 1.75h7.157l-.5-1.75c-.125-.438-.5-.75-.97-.75h-4.218Zm7.125 5c0-.813-.688-1.5-1.5-1.5h-7A1.5 1.5 0 0 0 9 11.5v2h10v-2Z"
    />
  </svg>
));
export default SvgIcon2Cars;
