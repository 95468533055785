import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWarningOutline = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    fill="none"
    viewBox="0 0 18 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M17.063 12.031c.5.875-.125 1.969-1.157 1.969H2.562a1.34 1.34 0 0 1-1.187-1.969L8.063.656A1.384 1.384 0 0 1 9.25 0c.438 0 .875.219 1.156.656l6.656 11.375ZM2.874 12.5h12.719L9.219 1.656 2.875 12.5ZM9.25 9.562c.531 0 .969.438.969.97a.974.974 0 0 1-.969.968.98.98 0 0 1-1-.969.98.98 0 0 1 1-.969ZM8.5 4.75A.74.74 0 0 1 9.25 4a.76.76 0 0 1 .75.75v3a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75v-3Z"
    />
  </svg>
));
export default SvgIconWarningOutline;
