import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import { BuyTab } from './BuyTab';
import { ActivityTab } from './ActivityTab';
import { FundsProvider } from 'context/fundsContext';
import { SendTab } from './SendTab';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  defaultIndex?: number;
}

export const WalletDrawer = ({
  isOpen,
  onClose,
  defaultIndex = 0,
}: DrawerProps) => {
  return (
    <FundsProvider>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay ml="3.5rem" />
        <DrawerContent ml="3.5rem" zIndex={99} width={'400px'}>
          <DrawerHeader
            bg="linear-gradient(180deg, #121212 0%, #1E1E1E 100%)"
            p={4}
          >
            <Flex w="full" justifyContent="space-between">
              <Heading
                as="h1"
                size="md"
                display="flex"
                alignItems="center"
                fontWeight="normal"
                color="whiteAlpha.700"
              >
                Wallet
              </Heading>
              <Flex align="center" gap={2}>
                <DrawerCloseButton />
              </Flex>
            </Flex>

            <Flex w="full"></Flex>
          </DrawerHeader>

          <DrawerBody
            id="drawerBody"
            className="red-scrollbar"
            overflowX="hidden"
          >
            <Tabs w="100%" mt={4} defaultIndex={defaultIndex}>
              <TabList>
                <Tab fontFamily="heading" fontWeight="normal">
                  Buy
                </Tab>
                <Tab fontFamily="heading" fontWeight="normal">
                  Send
                </Tab>
                <Tab fontFamily="heading" fontWeight="normal">
                  Activity
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <BuyTab />
                </TabPanel>
                <TabPanel>
                  <SendTab />
                </TabPanel>
                <TabPanel>
                  <ActivityTab />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </FundsProvider>
  );
};
