import { Heading, Box, Flex, IconButton } from '@chakra-ui/react';

import { SVGIcon } from 'types';
import { defaultTransition } from 'utils/themeConstants';

type HeadingProps = {
  children: React.ReactNode;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
};

const headingHeight = 10;

const RHeading = ({ children, leftButton, rightButton }: HeadingProps) => {
  let textMargin = 0;
  if (leftButton) {
    textMargin = 10;
  }
  if (rightButton) {
    textMargin = -10;
  }

  return (
    <Flex w="full" mb="2">
      {leftButton}

      <Heading
        as="h2"
        textTransform="uppercase"
        bgColor="white.05"
        borderBottom="1px solid"
        borderBottomColor="white.20"
        backdropFilter="blur(10px)"
        color="white.60"
        fontSize="md"
        height={headingHeight}
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="full"
      >
        <Box marginRight={textMargin}>{children}</Box>
      </Heading>

      {rightButton}
    </Flex>
  );
};

export default RHeading;

type IconProps = {
  Icon: SVGIcon;
  alt: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const RHeadingButton = ({ Icon, alt, onClick }: IconProps) => {
  return (
    <IconButton
      aria-label={alt}
      border="1px solid"
      borderColor="white.20"
      background="white.05"
      onClick={onClick}
      height={headingHeight}
      width={headingHeight}
      variant="unstyled"
      tabIndex={0}
      _focus={{
        borderColor: 'fieryCoral.40',
      }}
      icon={
        <Icon
          transition={defaultTransition}
          width="100%"
          height="100%"
          padding="3"
          opacity="0.6"
          _hover={{
            opacity: 1,
          }}
        />
      }
    />
  );
};

export { RHeadingButton };
