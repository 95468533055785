import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderColor: 'white.100',
    _before: {
      content: '""',
      bg: 'transparent',
      transform: 'scale(0)',
      transition: '150ms transform ease-in-out',
    },
    _hover: {
      _before: {
        bg: 'transparent',
        borderColor: 'white.100',
      },
      bg: 'transparent',
      borderColor: 'white.100',
    },
    _checked: {
      _before: {
        bg: 'treasuredTeal.100',
        width: '48%',
        height: '48%',
        borderColor: 'treasuredTeal.100',
        transform: 'scale(1)',
      },
      bg: 'transparent',
      borderColor: 'treasuredTeal.100',

      _hover: {
        _before: {
          bg: 'treasuredTeal.100',
          borderColor: 'treasuredTeal.100',
        },

        bg: 'transparent',
        borderColor: 'treasuredTeal.100',
      },
    },
  },
});

const variants = {
  outline: {
    control: {
      width: '5',
      height: '5',
    },
  },
};

export const radioTheme = defineMultiStyleConfig({
  defaultProps: {
    variant: 'outline',
  },
  baseStyle,
  variants,
});
