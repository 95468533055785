import { createContext, useContext, useEffect, useState } from 'react';

interface ResultsContextProps {
  showAllResults: boolean;
  setShowAllResults: React.Dispatch<React.SetStateAction<boolean>>;
}

const resultsContext = createContext<ResultsContextProps>(
  {} as ResultsContextProps
);

export const ResultsProvider = ({ children }: any) => {
  const [showAllResults, setShowAllResults] = useState<boolean>(false);

  return (
    <resultsContext.Provider
      value={{
        showAllResults,
        setShowAllResults,
      }}
    >
      {children}
    </resultsContext.Provider>
  );
};

export const useResults = () => {
  return useContext(resultsContext); 
};