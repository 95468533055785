import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const primary = definePartsStyle({
  container: {
    bg: 'perrywinkle.100',
  },
});

const baseStyle = definePartsStyle({
  container: {
    borderRadius: 0,
    color: 'black.100',
    fontWeight: 'bold',
  },
});

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    primary,
  },
  defaultProps: {
    variant: 'primary',
  },
});
