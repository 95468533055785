import { Box } from '@chakra-ui/react';

import { IconRacinoLogo } from 'icons';
import { SVGIcon } from 'types';

const vminValue = 18;

const ProgressIndicator = ({ Icon = IconRacinoLogo }: { Icon?: SVGIcon }) => {
  return (
    <Box
      position="relative"
      height={`${vminValue * 2}vmin`}
      width={`${vminValue * 1.5}vmin`}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        marginX="auto"
        marginTop="10vmin"
        left="0"
        right="0"
        top={`calc(50% - ${vminValue}vmin)`}
        transform="rotate(45deg)"
        position="absolute"
        zIndex="0"
        width={`${vminValue}vmin`}
        height={`${vminValue}vmin`}
        overflow="hidden"
        borderColor="white.10"
        borderWidth="0.125rem"
      ></Box>

      <Icon
        width={`${vminValue / 2}vmin`}
        height={`${vminValue / 2}vmin`}
        zIndex="2"
        position="relative"
        __css={{
          path: {
            fill: 'white.80',
          },
        }}
      />
    </Box>
  );
};

export default ProgressIndicator;
