import { CarStats } from 'components/Salvage/new/FusionCarStats';
import useSimulateCarTuning from 'hooks/Race/useSimulateCarTuning';
import { ReactNode, createContext, useContext, useState } from 'react';

interface TuningContextProps {
  brakeCoolingTuning: number | null;
  engineCoolingTuning: number | null;
  frontWingTuning: number | null;
  rearWingTuning: number | null;
  transmissionTuning: number | null;
  handling: number;
  cooling: number;
  speed: number;
  braking: number;
  fastTurns: number;
  slowTurns: number;
  isLoadingTuning: boolean;
  carId: string;
  winnerDiffs: CarStats;
  refetch: () => void;
  setCarId: React.Dispatch<React.SetStateAction<string>>;
  setBrakeCoolingTuning: (value: number) => void;
  setEngineCoolingTuning: (value: number) => void;
  setFrontWingTuning: (value: number) => void;
  setRearWingTuning: (value: number) => void;
  setTransmissionTuning: (value: number) => void;
  setEngineModeTuning: (value: number) => void;
  setSuspensionRideHeightTuning: (value: number) => void;
  setSuspensionStiffnessTuning: (value: number) => void;
}

const tuningContext = createContext<TuningContextProps>(
  {} as TuningContextProps
);

export const TuningProvider = ({ children }: { children: ReactNode }) => {
  const [carId, setCarId] = useState<string>('');
  const [brakeCoolingTuning, setBrakeCoolingTuning] = useState<number | null>(
    50
  );
  const [engineCoolingTuning, setEngineCoolingTuning] = useState<number | null>(
    50
  );
  const [frontWingTuning, setFrontWingTuning] = useState<number | null>(50);
  const [rearWingTuning, setRearWingTuning] = useState<number | null>(50);
  const [transmissionTuning, setTransmissionTuning] = useState<number | null>(
    50
  );
  const [engineModeTuning, setEngineModeTuning] = useState<number | null>(50);
  const [suspensionRideHeightTuning, setSuspensionRideHeightTuning] = useState<
    number | null
  >(50);
  const [suspensionStiffnessTuning, setSuspensionStiffnessTuning] = useState<
    number | null
  >(50);

  const { data, isLoading, isRefetching, refetch } = useSimulateCarTuning({
    carId,
    tunings: {
      brakeCoolingTuning,
      engineCoolingTuning,
      frontWingTuning,
      rearWingTuning,
      transmissionTuning,
      engineModeTuning,
      suspensionRideHeightTuning,
      suspensionStiffnessTuning,
    },
  });

  const getCarStats = () => {
    const [handling, speed, fastTurns, slowTurns, braking, cooling] =
      data?.simulateCarTuning?.stats?.map((stat) => stat.bars) || [];

    return { handling, speed, fastTurns, slowTurns, braking, cooling };
  };

  const getCarWinnerDiff = () => {
    const [handling, speed, fastTurns, slowTurns, braking, cooling] =
      data?.simulateCarTuning?.stats?.map((stat) => stat.winnerDiff) || [];

    return {
      handling,
      speed,
      fastTurns,
      slowTurns,
      braking,
      cooling,
    } as CarStats;
  };

  return (
    <tuningContext.Provider
      value={{
        brakeCoolingTuning,
        engineCoolingTuning,
        frontWingTuning,
        rearWingTuning,
        transmissionTuning,
        handling: getCarStats().handling,
        cooling: getCarStats().cooling,
        speed: getCarStats().speed,
        braking: getCarStats().braking,
        fastTurns: getCarStats().fastTurns,
        slowTurns: getCarStats().slowTurns,
        winnerDiffs: getCarWinnerDiff(),
        isLoadingTuning: isLoading || isRefetching || !carId,
        carId,
        refetch,
        setCarId,
        setBrakeCoolingTuning,
        setEngineCoolingTuning: setEngineCoolingTuning,
        setFrontWingTuning: setFrontWingTuning,
        setRearWingTuning: setRearWingTuning,
        setTransmissionTuning: setTransmissionTuning,
        setEngineModeTuning: setEngineModeTuning,
        setSuspensionRideHeightTuning: setSuspensionRideHeightTuning,
        setSuspensionStiffnessTuning: setSuspensionStiffnessTuning,
      }}
    >
      {children}
    </tuningContext.Provider>
  );
};

export const useTuning = () => useContext(tuningContext);
