import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconInfoCircle = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    viewBox="0 0 20 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M10 .5C4.453.5 0 4.992 0 10.5a9.97 9.97 0 0 0 10 10c5.508 0 10-4.453 10-10 0-5.508-4.492-10-10-10Zm0 18.75a8.736 8.736 0 0 1-8.75-8.75c0-4.805 3.906-8.75 8.75-8.75 4.805 0 8.75 3.945 8.75 8.75 0 4.844-3.945 8.75-8.75 8.75Zm0-6.875a.642.642 0 0 0 .625-.625V5.5c0-.313-.313-.625-.625-.625a.642.642 0 0 0-.625.625v6.25c0 .352.273.625.625.625Zm0 1.563a.925.925 0 0 0-.938.937c0 .547.391.938.938.938.508 0 .938-.391.938-.938a.95.95 0 0 0-.938-.938Z"
    />
  </svg>
));
export default SvgIconInfoCircle;
