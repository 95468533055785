import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHaircut = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="m13.773 11.125 4.75 4c.125.094.188.25.188.375 0 .344-.313.5-.531.5a.813.813 0 0 1-.344-.094l-4.719-4a.488.488 0 0 1-.187-.375c0-.344.312-.5.5-.5a.63.63 0 0 1 .343.094Zm4.75-6.219L9.148 12.75c.313.531.5 1.125.5 1.75 0 1.938-1.562 3.5-3.468 3.5-1.907 0-3.47-1.563-3.47-3.5 0-1.906 1.532-3.5 3.47-3.5.937 0 1.78.375 2.406.969L10.898 10 8.555 8.062A3.437 3.437 0 0 1 6.18 9C4.273 9 2.71 7.437 2.71 5.5c0-1.906 1.595-3.5 3.5-3.5 1.876 0 3.47 1.594 3.47 3.5 0 .656-.188 1.25-.47 1.781l2.438 2.094 6.22-5.25a.603.603 0 0 1 .312-.094c.218 0 .53.157.53.469a.504.504 0 0 1-.187.406ZM6.18 8c1.406 0 2.468-1.094 2.468-2.5C8.648 4.125 7.523 3 6.18 3a2.507 2.507 0 0 0-2.5 2.5c0 1.406 1.125 2.5 2.5 2.5Zm0 9c1.406 0 2.468-1.094 2.468-2.5 0-1.375-1.125-2.5-2.468-2.5-1.375 0-2.47 1.125-2.47 2.5 0 1.406 1.063 2.5 2.47 2.5Z"
    />
  </svg>
));
export default SvgIconHaircut;
