import { Box, BoxProps, Flex, AspectRatio } from '@chakra-ui/react';
import {
  useState,
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
} from 'react';

import { RImage } from 'components';

const BackgroundLayer = ({
  backgroundColor,
  backgroundSrc,
  backgroundPreload,
}: BoxProps & {
  backgroundSrc?: string;
  backgroundPreload?: boolean;
}) => {
  return (
    <Box position="relative" background={backgroundColor} zIndex="1">
      {backgroundSrc && (
        <RImage
          h="full"
          objectFit="cover"
          src={backgroundSrc}
          alt=""
          priority={backgroundPreload}
        />
      )}
    </Box>
  );
};

const ContentLayer = ({
  children,
  isHovering,
}: {
  children?: React.ReactNode;
  isHovering?: boolean;
}) => {
  // renderChildren is used to pass hover state to BaseCardGradient, if present
  // otherwise it loses hover state when a sibling with higher zIndex is hovered
  const renderChildren = () => {
    return Children.map<ReactNode, ReactNode>(children, (child) => {
      if (
        isValidElement(child) &&
        typeof child.type !== 'string' &&
        child.type?.name === 'BaseCardGradient'
      ) {
        return cloneElement(child, { ...child.props, isHovering });
      }

      return child;
    });
  };

  return (
    <Flex
      position="relative"
      zIndex="2"
      width="full"
      height="full"
      __css={{
        '> *': {
          zIndex: 5, // all other children should be layered above BaseCardGradient, if present
        },
      }}
    >
      {children && renderChildren()}
    </Flex>
  );
};

type BaseCardProps = {
  outsetSideBorderColor?: string | null;
  backgroundSrc?: string;
  backgroundPreload?: boolean;
};

const BaseCard = ({
  children,
  aspectRatio,
  outsetSideBorderColor,
  backgroundColor,
  backgroundSrc,
  backgroundPreload, // use when the image is likely to appear "above the fold"
  ...rest
}: BaseCardProps & BoxProps) => {
  let aspectRatioProps = {};
  if (aspectRatio) {
    aspectRatioProps = {
      as: AspectRatio,
      ratio: aspectRatio,
      height: 'full',
    };
  }

  const [isHovering, setIsHovering] = useState(false);

  return (
    <Box
      position="relative"
      w="full"
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      {...rest}
    >
      <Box position="relative" h="full" {...aspectRatioProps}>
        <>
          <ContentLayer isHovering={isHovering}>{children}</ContentLayer>

          <BackgroundLayer
            backgroundColor={backgroundColor}
            backgroundSrc={backgroundSrc}
            backgroundPreload={backgroundPreload}
          />
        </>
      </Box>
    </Box>
  );
};

export default BaseCard;
