import { useDisclosure } from '@chakra-ui/react';
import { ReactNode, createContext, useContext } from 'react';

interface ProviderProps {
  children: ReactNode;
}

const HubContext = createContext<HubContextProps>({} as HubContextProps);

interface HubContextProps {
  isOpenHub: boolean;
  onToggleHub: () => void;
  onCloseHub: () => void;
}

export const HubProvider = ({ children }: ProviderProps) => {
  const {
    isOpen: isOpenHub,
    onToggle: onToggleHub,
    onClose: onCloseHub,
  } = useDisclosure();

  return (
    <HubContext.Provider value={{ isOpenHub, onToggleHub, onCloseHub }}>
      {children}
    </HubContext.Provider>
  );
};

export const useHub = () => {
  return useContext(HubContext);
};
