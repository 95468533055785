import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTicketVext = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={34}
    fill="none"
    viewBox="0 0 33 34"
    aria-hidden="true"
    {...props}
  >
    <g filter="url(#a)">
      <path
        stroke="#7A7AFF"
        d="M12.94 28.808h2.457l2.456-2.456 1.228-1.228 9.416-9.416v-2.456l-.41-.41a1.158 1.158 0 1 1-1.637-1.637l-1.023-1.024a1.158 1.158 0 1 1-1.638-1.637L22.766 7.52a1.158 1.158 0 0 1-1.638-1.637l-.409-.41h-2.456L8.847 14.89l-1.228 1.228-2.456 2.457v2.456l.409.41a1.158 1.158 0 1 1 1.638 1.637L8.233 24.1a1.158 1.158 0 1 1 1.637 1.638l1.024 1.023A1.158 1.158 0 1 1 12.53 28.4l.41.409Z"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      fill="#7A7AFF"
      fillRule="evenodd"
      d="m15.66 15.88-4.68 2.597 5.616.15.67 2.864 1.109-2.896 2.895-1.109-2.864-.67-.149-5.616-2.597 4.68Zm.51.51-3.264 1.626 3.923-.967.967-3.923-1.625 3.265Zm-3.38 1.768 4.355-.793.793-4.354-.021 4.24 2.684.246-2.825.5-.5 2.824-.245-2.684-4.24.021Z"
      clipRule="evenodd"
    />
    <defs>
      <filter
        id="a"
        width={32.334}
        height={32.334}
        x={0.663}
        y={0.974}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10057_188717"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_10057_188717"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
));
export default SvgIconTicketVext;
