import { defineStyleConfig } from '@chakra-ui/react';

export const progressTheme = defineStyleConfig({
  baseStyle: {
    filledTrack: {
      bgColor: 'treasuredTeal.100',
    },
    track: {
      bgColor: 'carbon.100',
    },
  },
  variants: {},
  defaultProps: {
    size: 'xs',
  },
});
