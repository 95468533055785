import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconShuffle = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="m16.563 11.21 3.125 3.126c.351.39.351.976 0 1.328l-3.125 3.125c-.157.195-.391.273-.625.273-.274 0-.508-.078-.665-.273a.856.856 0 0 1 0-1.289l1.524-1.563H12.5c-.313 0-.586-.117-.742-.351L9.53 12.617l1.133-1.562 2.305 3.008h3.828l-1.563-1.524a.856.856 0 0 1 0-1.289c.352-.39.938-.39 1.329-.04ZM.938 5.938C.39 5.938 0 5.548 0 5c0-.508.39-.938.938-.938H5c.273 0 .547.157.742.391L7.93 7.422 6.797 8.984 4.53 5.938H.938Zm15.859 0h-3.828l-7.227 9.649a.962.962 0 0 1-.742.351H.937C.391 15.938 0 15.547 0 15c0-.508.39-.938.938-.938H4.53l7.188-9.609c.195-.234.469-.39.781-.39h4.297l-1.524-1.524a.856.856 0 0 1 0-1.289.856.856 0 0 1 1.29 0l3.125 3.125c.351.352.351.938 0 1.328L16.563 8.79c-.157.195-.391.274-.625.274-.274 0-.508-.079-.665-.274a.856.856 0 0 1 0-1.289l1.524-1.563Z"
    />
  </svg>
));
export default SvgIconShuffle;
