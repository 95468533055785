import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconShirt = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={13}
    fill="none"
    viewBox="0 0 15 13"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M14.977 4.675c0 .304-.094.585-.305.82L13.5 6.948c-.258.305-.633.492-1.008.492a1.19 1.19 0 0 1-.82-.305l-.445-.351v4.195c0 .938-.774 1.688-1.688 1.688H5.438a1.68 1.68 0 0 1-1.688-1.688V6.784l-.469.351a1.273 1.273 0 0 1-.797.282c-.398 0-.773-.164-1.03-.47L.28 5.496A1.29 1.29 0 0 1 0 4.675c0-.375.164-.75.492-1.008l3.164-2.508c.399-.305.89-.492 1.406-.492h.563a.57.57 0 0 1 .563.562A1.31 1.31 0 0 0 7.5 2.542c.703 0 1.313-.586 1.313-1.313a.55.55 0 0 1 .539-.562h.562c.516 0 1.008.187 1.383.492l3.164 2.508a1.3 1.3 0 0 1 .516 1.008Zm-1.102 0c0-.047-.047-.094-.094-.141l-3.14-2.484a1.195 1.195 0 0 0-.727-.258h-.047A2.436 2.436 0 0 1 7.5 3.667a2.443 2.443 0 0 1-2.39-1.875h-.048c-.257 0-.515.093-.75.258l-3.14 2.484c-.047.047-.07.094-.07.14 0 .047.023.094.046.118l1.172 1.43c.024.023.094.07.164.07.024 0 .07 0 .118-.024l1.664-1.312c.07-.07.14-.094.21-.094.212 0 .399.164.399.375v5.742c0 .328.234.563.563.563h4.101a.542.542 0 0 0 .563-.563V5.214c0-.211.187-.375.398-.375.07 0 .14.023.21.093l1.665 1.313c.047.023.094.047.117.047.094 0 .14-.07.164-.07l1.172-1.454a.177.177 0 0 0 .047-.093Z"
    />
  </svg>
));
export default SvgIconShirt;
