import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFullScreen = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M5.25 1a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H2.5v2.75a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75v-3.5A.74.74 0 0 1 1.75 1h3.5Zm9 0a.76.76 0 0 1 .75.75v3.5a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75V2.5h-2.75a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75h3.5Zm-9 12.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-3.5a.722.722 0 0 1-.75-.75v-3.5a.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75v2.75h2.75Zm9-3.5a.76.76 0 0 1 .75.75v3.5a.74.74 0 0 1-.75.75h-3.5a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75h2.75v-2.75a.74.74 0 0 1 .75-.75Z"
    />
  </svg>
));
export default SvgIconFullScreen;
