import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTicketCardBg = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={372}
    height={513}
    fill="none"
    viewBox="0 0 372 513"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="url(#a)"
      fillOpacity={0.2}
      d="M0 513h513v372H0z"
      transform="rotate(-90 0 513)"
    />
    <path stroke="#FFE380" strokeWidth={1.5} d="M4 504v5h364v-5" />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        x2={513}
        y1={700.806}
        y2={700.806}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF181" />
        <stop offset={1} stopColor="#FFF181" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
));
export default SvgIconTicketCardBg;
