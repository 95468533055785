import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCheckmarkSmall = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path stroke="#fff" strokeWidth={2} d="m1 8.802 4.308 4.38L15 3" />
  </svg>
));
export default SvgIconCheckmarkSmall;
