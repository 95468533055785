import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFilterLines = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill="none"
    viewBox="0 0 14 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M8.25 10.25A.76.76 0 0 1 9 11c0 .438-.344.75-.781.75h-2.5C5.312 11.75 5 11.437 5 11c0-.406.313-.75.719-.75H8.25Zm5-10c.406 0 .75.406.75.781 0 .407-.344.719-.75.719H.719C.313 1.75 0 1.375 0 1 0 .594.313.25.719.25H13.25Zm-2 5A.76.76 0 0 1 12 6c0 .438-.344.75-.781.75h-8.5C2.313 6.75 2 6.437 2 6c0-.406.313-.75.719-.75h8.531Z"
    />
  </svg>
));
export default SvgIconFilterLines;
