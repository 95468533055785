import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuningFastTurns = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={12}
    fill="none"
    viewBox="0 0 15 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M7.667.75a6.752 6.752 0 0 1 6.75 6.75 6.877 6.877 0 0 1-.915 3.398.777.777 0 0 1-.656.375H2.463a.711.711 0 0 1-.632-.375A6.64 6.64 0 0 1 .917 7.5 6.752 6.752 0 0 1 7.667.75Zm4.593 8.063c.305-.047.492-.329.446-.633a.556.556 0 0 0-.633-.47l-3.14.517A1.498 1.498 0 0 0 7.666 7.5c-.844 0-1.5.68-1.5 1.5 0 .281.07.54.187.75h2.602a1.76 1.76 0 0 0 .164-.422l3.14-.515Z"
    />
  </svg>
));
export default SvgIconTuningFastTurns;
