import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconKnobGrip = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={8}
    fill="none"
    viewBox="0 0 14 8"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#000"
      strokeOpacity={0.4}
      d="M7.5 1 1 7.5M10.5 1 4 7.5M13.5 1 7 7.5"
    />
  </svg>
));
export default SvgIconKnobGrip;
