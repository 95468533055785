import { defineStyleConfig } from '@chakra-ui/react';

import { defaultTransition } from 'utils/themeConstants';

export const linkTheme = defineStyleConfig({
  baseStyle: {
    position: 'relative',
    _hover: {
      textDecoration: 'none',
    },
    '&[aria-disabled="true"]': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
    '&[aria-disabled="true"]:active': {
      pointerEvents: 'none',
    },
    // enable underline effect on hover if not disabled
    ':not([aria-disabled="true"]):before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '1px',
      bottom: 0,
      left: 0,
      transform: 'scaleX(0)',
      transition: defaultTransition,
    },
    ':hover::before, :focus::before': {
      transform: 'scaleX(1)',
    },
  },
  variants: {
    primary: {
      color: 'white.100',
      _before: {
        backgroundColor: 'white.60',
      },
    },
    secondary: {
      color: 'fieryCoral.100',
      _before: {
        backgroundColor: 'fieryCoral.40',
      },
    },
    unstyled: {
      ':not([aria-disabled="true"]):before': {
        display: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
});
