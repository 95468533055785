import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUploadBadge = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    aria-hidden="true"
    {...props}
  >
    <path fill="#fff" fillOpacity={0.1} d="M28 0H0v12l16 16h12V0Z" />
    <path
      fill="#FF7043"
      d="M22.25 16.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-10.5a.722.722 0 0 1-.75-.75c0-.438.313-.75.75-.75h10.5Zm-8.719-6.969a.774.774 0 0 1-1.062.031.746.746 0 0 1-.031-1.062l4-4.25a.755.755 0 0 1 1.093 0l4 4.25a.746.746 0 0 1-.031 1.063.831.831 0 0 1-.5.187.753.753 0 0 1-.563-.219L17.75 6.656v7.594A.74.74 0 0 1 17 15a.722.722 0 0 1-.75-.75V6.656l-2.719 2.875Z"
    />
  </svg>
));
export default SvgIconUploadBadge;
