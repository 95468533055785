import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGoldenTicket = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    viewBox="0 0 40 40"
    aria-hidden="true"
    {...props}
  >
    <g filter="url(#a)">
      <path
        fill="url(#b)"
        fillRule="evenodd"
        d="M17.863 35.744h-3.275l-.546-.545a1.544 1.544 0 0 0-2.184-2.184l-1.364-1.364a1.544 1.544 0 1 0-2.183-2.184l-1.365-1.364a1.544 1.544 0 1 0-2.183-2.184l-.546-.546V22.1l3.275-3.276 1.637-1.637L21.684 4.632h3.275l.545.546a1.544 1.544 0 0 0 2.184 2.183l1.364 1.364a1.544 1.544 0 0 0 2.184 2.184l1.364 1.364a1.544 1.544 0 0 0 2.184 2.184l.546.546v3.275L22.775 30.832l-1.637 1.637-3.275 3.275Zm2.104-19.151c.64-.64 1.695-.647 2.327-.015l-.002-.003a1.651 1.651 0 0 1-.014 2.328l-.339.339 3.258.586.947-.947-2.381-.428a2.74 2.74 0 0 0 .091-.514 2.706 2.706 0 0 0-.782-2.143c-1.058-1.058-2.807-1.048-3.878.024l-6.029 6.028-1.306-.235 6.563-6.564c1.502-1.501 3.947-1.516 5.43-.032a3.777 3.777 0 0 1 1.106 2.535l2.133.384.235 1.307-1.768 1.769-4.565-.821-.605-.937 1.12-1.12a.55.55 0 0 0 .005-.775.55.55 0 0 0-.776.005L15.78 22.32l-1.306-.235 5.493-5.493Zm3.339 5.13.6.942-2.393 2.394-2.115-3.273-1.003 1.004-1.308-.234 2.505-2.504 2.113 3.272.798-.798-.692-1.07 1.495.268Zm-2.362-9.195-.068.068.491.491.065-.064-.359-.359.001-.001.436.17.047-.047-.17-.436.001-.001.359.359.064-.065-.491-.49-.068.067.206.513-.002.001-.512-.206Zm-.442.558.148-.147.433.433.067-.067-.433-.433.147-.148-.058-.058-.362.362.058.058Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={35.33}
        x2={4.217}
        y1={4.632}
        y2={35.744}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCF4BF" />
        <stop offset={0.18} stopColor="#EFC862" />
        <stop offset={0.73} stopColor="#CD9832" />
        <stop offset={1} stopColor="#BB811B" />
      </linearGradient>
      <filter
        id="a"
        width={39.113}
        height={39.113}
        x={0.217}
        y={0.632}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9198_211611"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_9198_211611"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
));
export default SvgIconGoldenTicket;
