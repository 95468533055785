import { defineStyleConfig } from '@chakra-ui/react';
import { baseThemeType } from 'utils/baseTheme';
import { defaultTransition } from 'utils/themeConstants';

const defaultProps = {
  '.raceTrack': {
    top: '-6',
    right: '0',
    transform: 'scale(85%)',
  },
};

const gradientColoredBorder = (theme: baseThemeType) => ({
  ...defaultProps,
  position: 'relative',
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: '0.25rem',
    background: `linear-gradient(to bottom, ${theme?.colors?.bloodMoon?.[100]} 0%, transparent 100%)`,
    zIndex: 5,
  },
  '::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '0.25rem',
    background: `linear-gradient(to right, transparent 0%, transparent 40%, ${theme?.colors?.bloodMoon?.[100]} 75%, ${theme?.colors?.bloodMoon?.[100]} 100%)`,
    zIndex: 5,
  },
});

export const raceCardTheme = defineStyleConfig({
  // style object for base or default style
  baseStyle: {
    img: {
      transition: defaultTransition,
      opacity: 0.4,
    },
    _hover: {
      img: {
        transform: 'scale(115%)',
        opacity: 0.6,
      },
    },
    '.raceTrack': {
      position: 'absolute',
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    default: defaultProps,
    selected: ({ theme }: { theme: baseThemeType }) =>
      gradientColoredBorder(theme),
    userInRace: ({ theme }: { theme: baseThemeType }) =>
      gradientColoredBorder(theme),
    garage: {
      '.raceTrack': {
        top: '-12',
        right: '-8',
        transform: 'scale(50%)',
      },
      '.BaseCardTopLeft': {
        top: '4',
        left: '4',
        h1: {
          fontSize: '1.25em',
        },
      },
      '.BaseCardTopRight': {
        top: '4',
        right: '4',
      },
      '.BaseCardBottomLeft': {
        bottom: '4',
        left: '4',
      },
      '.BaseCardBottomRight': {
        bottom: '4',
        right: '4',
      },
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    variant: 'default',
    colorScheme: '',
  },
});
