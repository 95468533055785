import { defineStyleConfig } from '@chakra-ui/react';

export const switchTheme = defineStyleConfig({
  baseStyle: {
    track: {
      bg: 'transparent',
      borderColor: 'white.60',
      borderWidth: '0.188rem',
      _checked: {
        borderColor: 'treasuredTeal.100',
      },
    },
    thumb: {
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 0.188rem rgba(255, 255, 255, 0.6)',
      _checked: {
        boxShadow: 'inset 0 0 0 0 rgba(255, 255, 255, 0)',
        bg: 'treasuredTeal.100',
      },
    },
  },
});
