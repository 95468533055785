import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHelp = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M8.031 3H6.406C5.22 3 4.25 3.969 4.25 5.188c0 .406.344.75.75.75a.76.76 0 0 0 .75-.75c0-.375.281-.688.656-.688h1.625c.375 0 .719.313.719.688a.67.67 0 0 1-.344.593L6.625 6.844a.77.77 0 0 0-.375.656V8c0 .406.344.75.75.75A.76.76 0 0 0 7.75 8v-.063l1.406-.875a2.19 2.19 0 0 0 1.094-1.875C10.25 3.97 9.281 3 8.031 3ZM7 9.5c-.563 0-1 .438-1 1 0 .563.406 1 1 1 .531 0 1-.438 1-1 0-.563-.469-1-1-1ZM12 0H2C.875 0 0 .906 0 2v10c0 1.125.875 2 2 2h10c1.094 0 2-.875 2-2V2c0-1.094-.906-2-2-2Zm.5 12c0 .281-.25.5-.5.5H2a.494.494 0 0 1-.5-.5V2c0-.25.219-.5.5-.5h10c.25 0 .5.25.5.5v10Z"
    />
  </svg>
));
export default SvgIconHelp;
