import { useMutation } from '@tanstack/react-query';
import { RpmAvatar, RpmUser } from 'utils/readyPlayerMe/responseTypes';

// Props and Constants
const templateId = '645cd1eff23d0562d3f9d290';
const maleTemplate = {
  beardStyle: '',
  outfit: '148336687',
};
const femaleTemplate = {};

// Hook
const useCreateRpmAuthToken = () => {
  const createRpmAuthTokenFn = async () => {
    const response = await fetch('https://racino.readyplayer.me/api/users', {
      method: 'POST',
    });

    const data: RpmUser = await response.json();
    console.log('NEW RPM API TOKEN => ' + data.data.token);
    const authUser = data.data;

    console.log('Create Draft Avatar');
    const createAvatarResponse = await fetch(
      `https://api.readyplayer.me/v2/avatars/templates/${templateId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authUser.token}`,
        },
        body: JSON.stringify({
          data: {
            partner: 'racino',
            bodyType: 'fullbody',
          },
        }),
      }
    );
    const createdAvatar: RpmAvatar = await createAvatarResponse.json();

    const editedAvatar = await fetch(
      `https://api.readyplayer.me/v2/avatars/${createdAvatar.data.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authUser.token}`,
        },
        body: JSON.stringify({
          data: {
            assets:
              createdAvatar.data.gender === 'male'
                ? maleTemplate
                : femaleTemplate,
          },
        }),
      }
    );

    // Save avatar
    await fetch(
      `https://api.readyplayer.me/v2/avatars/${createdAvatar.data.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authUser.token}`,
        },
      }
    );

    const avatarModel: Blob = await editedAvatar.blob();

    return { rpmUser: authUser, avatarModel, avatarJson: createdAvatar.data };
  };

  return useMutation({
    mutationKey: ['rpmUserAuthToken'],
    mutationFn: async () => {
      return createRpmAuthTokenFn();
    },
  });
};

export default useCreateRpmAuthToken;
