import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBrakeCooling = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="M4.133 11.371c0 3.937 3.104 7.129 6.934 7.129 3.829 0 6.933-3.192 6.933-7.129 0-3.468-2.409-6.358-5.6-6.997M4.133 11.37c0-3.937 3.104-7.13 6.934-7.13.456 0 .902.046 1.333.133M4.133 11.37l-.469-.643a.474.474 0 0 0-.722-.051.474.474 0 0 1-.809-.265l-.103-.744a.84.84 0 0 1 .05-.42L3.902 4.56a2 2 0 0 1 .76-.943L7.34 1.848c.332-.22.747-.272 1.124-.143.134.046.275.07.417.07h1.332a2 2 0 0 1 1.081.317l.513.33c.65.417.9 1.243.593 1.952m.267 6.997c0 1.211-.836 2.194-1.867 2.194-1.03 0-1.867-.983-1.867-2.194 0-1.212.836-2.194 1.867-2.194 1.03 0 1.867.982 1.867 2.194Zm2.133 0c0 3.029-2.03 4.993-4.533 4.993C7.763 16.364 6 14.4 6 11.371c0-3.029 1.763-5.216 4.267-5.216 2.503 0 4.533 2.187 4.533 5.216Z"
    />
  </svg>
));
export default SvgIconBrakeCooling;
