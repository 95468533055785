import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

interface TourProviderProps {
  children: ReactNode;
}

type AidrianaTourStep = {
  title: string;
  description: string;
};

interface TourContextProps {
  currentTour: string | null;
  currentStep: number;
  aidrianaTourStep: AidrianaTourStep | null;
  tour: (props: {
    identifier: string;
    step?: number;
    aidrianaTourStep?: AidrianaTourStep;
  }) => void;
  nextStep: (identifier: string) => void;
  previousStep: (identifier: string) => void;
  stop: (identifier: string) => void;
}

const TourContext = createContext<TourContextProps>({
  currentTour: null,
  currentStep: 0,
  aidrianaTourStep: null,
  tour: () => null,
  nextStep: () => null,
  previousStep: () => null,
  stop: () => null,
});

export const TourProvider = ({ children }: TourProviderProps) => {
  const [currentTour, setCurrentTour] = useState<string | null>(null);
  const [aidrianaTourStep, setAidrianaTourStep] =
    useState<AidrianaTourStep | null>(null);
  const [currentStep, setCurrentStep] = useState(0);

  const tour = useCallback(
    ({
      identifier,
      step = 0,
      aidrianaTourStep,
    }: {
      identifier: string;
      step?: number;
      aidrianaTourStep?: AidrianaTourStep;
    }) => {
      setCurrentTour(identifier);
      setCurrentStep(step);

      if (aidrianaTourStep) {
        setAidrianaTourStep(aidrianaTourStep);
      } else {
        setAidrianaTourStep(null);
      }
    },
    []
  );

  const nextStep = useCallback(
    (identifier: string) => {
      if (currentTour === identifier) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    },
    [currentTour]
  );

  const previousStep = useCallback(
    (identifier: string) => {
      if (currentTour === identifier) {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
      }
    },
    [currentTour]
  );

  const stop = useCallback(
    (identifier: string) => {
      if (currentTour === identifier) {
        setCurrentTour(null);
        setCurrentStep(0);
      }
    },
    [currentTour]
  );

  return (
    <TourContext.Provider
      value={{
        currentTour,
        currentStep,
        aidrianaTourStep,
        tour,
        nextStep,
        previousStep,
        stop,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
