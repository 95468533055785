import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconChart = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M15.25.25c1.367 0 2.5 1.133 2.5 2.46v12.5c0 1.368-1.133 2.5-2.5 2.5H2.75a2.492 2.492 0 0 1-2.5-2.5V2.71C.25 1.345 1.344.25 2.75.25h12.5Zm.625 15V2.75c0-.313-.313-.625-.625-.625H2.75a.642.642 0 0 0-.625.625v12.5c0 .352.273.625.625.625h12.5a.642.642 0 0 0 .625-.625ZM9 4a.95.95 0 0 1 .938.938v8.125c0 .546-.43.937-.938.937-.547 0-.938-.39-.938-.938V4.938C8.063 4.43 8.454 4 9 4Zm3.75 6.25a.95.95 0 0 1 .938.938v1.874c0 .547-.43.938-.938.938-.547 0-.938-.39-.938-.938v-1.874c0-.508.391-.938.938-.938Zm-7.5-2.5a.95.95 0 0 1 .938.938v4.374c0 .547-.43.938-.938.938-.547 0-.938-.39-.938-.938V8.689c0-.508.391-.938.938-.938Z"
    />
  </svg>
));
export default SvgIconChart;
