import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMagnifyingGlass = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    viewBox="0 0 17 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M15.75 14.719c.313.312.313.781 0 1.062a.622.622 0 0 1-.5.219c-.219 0-.406-.063-.531-.219l-4.188-4.187A6.388 6.388 0 0 1 6.5 13 6.495 6.495 0 0 1 0 6.5C0 2.937 2.906 0 6.5 0 10.063 0 13 2.938 13 6.5a6.287 6.287 0 0 1-1.438 4.031l4.188 4.188ZM6.5 11.5c2.75 0 5-2.219 5-5 0-2.75-2.25-5-5-5-2.781 0-5 2.25-5 5 0 2.781 2.219 5 5 5ZM9 5.75a.76.76 0 0 1 .75.75c0 .438-.344.75-.813.75h-1.75V9c0 .438-.312.75-.718.75-.407 0-.719-.313-.719-.75V7.25H3.969c-.406 0-.719-.313-.719-.75 0-.406.313-.75.719-.75H5.75V4a.76.76 0 0 1 .75-.75c.375 0 .719.344.719.75v1.75H9Z"
    />
  </svg>
));
export default SvgIconMagnifyingGlass;
