export type Subset<K> = {
  // Partial for nested objects
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr];
};

const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export const formatOrdinal = (n: number): string => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export const isMobileDevice = () => {
  const userAgent =
    navigator?.userAgent || navigator?.vendor || window?.opera || '';

  return /android|avantgo|blackberry|bb|meego|iemobile|opera mini|phone|ipad|ipod|iphone|mobi|mobile|palm|pie|tablet|up.browser|up.link|webos|wos/i.test(
    userAgent
  );
};

export const generateSlugFromDate = ({
  name,
  isoDate,
}: {
  name: string;
  isoDate: string;
}) => {
  const normalizedTrackName = name.toLowerCase().replace(/\s+/g, '-');

  const timestamp = new Date(isoDate).getTime() / 1000;

  const slug = `${normalizedTrackName}-${timestamp}`;

  return slug;
};

export const getRaceDataFromSlug = (slug: string) => {
  const [name, timestamp] = slug.split('-');

  return {
    name,
    isoDate: new Date(Number(timestamp) * 1000).toISOString(),
  };
};
