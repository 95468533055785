import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHome = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={17}
    fill="none"
    viewBox="0 0 20 17"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M18.719 8.188a.78.78 0 0 1 .093 1.062.779.779 0 0 1-1.062.094L17 8.688V14.5c0 1.406-1.125 2.5-2.5 2.5h-9A2.468 2.468 0 0 1 3 14.5V8.687l-.781.657a.779.779 0 0 1-1.063-.094.779.779 0 0 1 .094-1.063l8.25-7a.797.797 0 0 1 .969 0l8.25 7ZM5.5 15.5H7v-4.75c0-.688.531-1.25 1.25-1.25h3.5c.688 0 1.25.563 1.25 1.25v4.75h1.5c.531 0 1-.438 1-1V7.406L10 2.75 4.5 7.406V14.5c0 .563.438 1 1 1Zm3 0h3V11h-3v4.5Z"
    />
  </svg>
));
export default SvgIconHome;
