import { Avatar, Box } from '@chakra-ui/react';
import { colors } from 'utils/themeConstants';

export const AvatarPicture = ({
  avatarId,
}: {
  avatarId: string | undefined;
}) => {
  return (
    <Box
      position="relative"
      borderRadius="full"
      overflow="hidden"
      width="2rem"
      height="2rem"
      mx="3"
      bg={`linear-gradient(180deg, ${colors.black[100]} 44.27%, ${colors.grapeBlue[100]} 72.92%, ${colors.yankeesBlue[100]} 100%)`}
    >
      <Avatar
        border={`1px solid ${colors.white[40]}`}
        position="absolute"
        width="4.5rem"
        height="4.5rem"
        left="-1.2rem"
        size="full"
        top="-0.5rem"
        src={
          avatarId
            ? `https://models.readyplayer.me/${avatarId}.png?blendShapes[mouthSmile]=0.4&blendShapes[browInnerUp]=0.2`
            : '/images/home/driver.png'
        }
        objectFit="cover"
        objectPosition="center"
      />
    </Box>
  );
};
