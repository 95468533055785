import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMaticBorder = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    viewBox="0 0 40 40"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#7E3DFE"
      fillRule="evenodd"
      d="M5.5 28.442V11.558L20 2.755l14.5 8.803v16.884L20 37.245 5.5 28.442ZM4 10.714 20 1l16 9.714v18.572L20 39 4 29.286V10.714Zm20.28 5.093c.459-.225.976-.225 1.378 0l4.653 2.65c.46.282.689.733.689 1.184v5.356c0 .507-.287.959-.69 1.184l-4.652 2.65c-.46.225-.977.225-1.379 0l-4.652-2.65c-.46-.282-.69-.733-.69-1.184v-1.86l2.183-1.24V23.7c0 .508.287.959.69 1.184l2.47 1.41c.459.225.976.225 1.378 0l2.412-1.41c.46-.282.69-.733.69-1.184v-2.819a1.37 1.37 0 0 0-.69-1.184l-2.47-1.41c-.459-.225-.976-.225-1.378 0l-3.16 1.805-2.182 1.24-3.16 1.805c-.459.225-.976.225-1.378 0l-4.653-2.65C9.23 20.205 9 19.754 9 19.303v-5.3c0-.451.23-.902.69-1.128l4.71-2.706c.459-.225.976-.225 1.378 0l4.595 2.65c.46.282.69.733.69 1.184v1.917l-2.183 1.24V15.3a1.37 1.37 0 0 0-.69-1.184l-2.47-1.466c-.459-.226-.976-.226-1.378 0l-2.47 1.41c-.46.281-.69.732-.69 1.183v2.82c0 .507.288.958.69 1.183l2.47 1.466c.46.226.976.226 1.379 0l3.159-1.86 2.183-1.184 3.216-1.86Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconMaticBorder;
