import { useEffect, useRef, useState } from 'react';
import { Flex, FlexProps, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IconCheckmarkSmall, IconChevron, IconClose } from 'icons';

export type SelectOptionType = {
  component?: React.ReactNode;
  value: string | any;
  displayValue?: string;
};

interface RSelectProps extends FlexProps {
  placeholder?: string;
  options: SelectOptionType[];
  selectedOption?: string | any;
  setSelectedOption?: (value?: string | any) => void;
}

const RSelect = ({
  children,
  placeholder,
  options,
  selectedOption,
  setSelectedOption,
  ...props
}: RSelectProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { ease: 'easeInOut', duration: 0.3 },
      display: 'block',
    },
    closed: {
      opacity: 0,
      y: -10,
      transition: { ease: 'easeInOut', duration: 0.3, display: { delay: 0.3 } },
      display: 'none',
    },
  };

  const handleSelectOption = (value: string | any) => {
    setSelectedOption && setSelectedOption(value);
    setIsSelected(false);
  };

  const handleClearSelected = () => {
    setSelectedOption && setSelectedOption(undefined);
    setIsSelected(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsSelected(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Flex
      ref={containerRef}
      pos="relative"
      h="3rem"
      bgColor={isSelected ? 'fieryCoral.40' : 'white.10'}
      cursor="default"
      userSelect="none"
      {...props}
    >
      <Flex
        w="full"
        h="full"
        alignItems="center"
        justifyContent="space-between"
        px="1.25rem"
        onClick={() => setIsSelected((oldState) => !oldState)}
      >
        <Heading
          textTransform="uppercase"
          fontWeight="400"
          fontSize="1rem"
          color={isSelected ? 'fieryCoral.100' : 'white.100'}
        >
          {selectedOption
            ? options.map((option, index) => {
                if (option.value === selectedOption)
                  return option.component ? (
                    <Flex flexDir="row" key={index}>
                      {option.component}
                    </Flex>
                  ) : (
                    option.displayValue || option.value
                  );
              })
            : placeholder}
        </Heading>
        <IconChevron
          transform="rotate(90deg)"
          __css={{
            path: { fill: isSelected ? 'fieryCoral.100' : 'white.100' },
          }}
        />
      </Flex>
      <Flex
        as={motion.div}
        initial="closed"
        animate={isSelected ? 'open' : 'closed'}
        variants={variants}
        pos="absolute"
        overflowY="auto"
        flexDir="column"
        top={props.h || '3.5rem'}
        left="0"
        w="full"
        maxH="30rem"
        justifyContent="center"
        bgColor="neutralDark.100"
        zIndex={2}
      >
        <Flex
          w="full"
          h="full"
          py="0.75rem"
          px="1.25rem"
          justifyContent="space-between"
          onClick={handleClearSelected}
        >
          <Heading
            textTransform="uppercase"
            fontWeight="400"
            fontSize="0.875rem"
            color="fieryCoral.100"
          >
            Clear Selected
          </Heading>
          <IconClose
            w="0.625rem"
            __css={{ path: { fill: 'fieryCoral.100' } }}
          />
        </Flex>
        {options.map((option, index) => (
          <Flex
            key={index}
            w="full"
            h="full"
            py="0.75rem"
            px="1.25rem"
            justifyContent="space-between"
            onClick={() => handleSelectOption(option.value)}
          >
            <Heading
              display="flex"
              flexDir="row"
              textTransform="uppercase"
              fontWeight="400"
              fontSize="0.875rem"
            >
              {option.component || option.value}
            </Heading>
            {selectedOption === option.value && (
              <IconCheckmarkSmall
                w="0.625rem"
                __css={{ path: { stroke: 'treasuredTeal.100' } }}
              />
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default RSelect;
