import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBarsMenuHamburger = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M16.813 13.813c.507 0 .937.43.937.976 0 .508-.43.899-.938.899H1.149c-.507 0-.898-.391-.898-.938 0-.508.39-.938.898-.938h15.664Zm0-12.5c.507 0 .937.507.937.976 0 .508-.43.898-.938.898H1.149C.641 3.188.25 2.72.25 2.25c0-.508.39-.938.898-.938h15.664Zm0 6.25a.95.95 0 0 1 .937.937c0 .547-.43.938-.938.938H1.149C.641 9.438.25 9.046.25 8.5c0-.508.39-.938.898-.938h15.664Z"
    />
  </svg>
));
export default SvgIconBarsMenuHamburger;
