import { useRef } from 'react';
import {
  Button,
  Flex,
  Heading,
  Text,
  ToastId,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { camelCase, startCase } from 'lodash';

import { partsData } from 'utils/partData';
import { colors } from 'utils/themeConstants';
import { SetCarPartsInput } from 'api/generated/graphql';
import { IconCheckmarkSmall } from 'icons';
import { RSlider, RImage, RDrawer, RToast } from 'components';
import { CarPart } from 'types';
import { useSetCarPart } from 'hooks';
import PartInfo from './PartInfo';

interface PartInfoDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  isPartApplied?: boolean;
  carPart: Partial<CarPart> | undefined;
  carId: string;
}

const RPartInfoDrawer = ({
  isOpen,
  onClose,
  isPartApplied,
  carPart,
  carId,
}: PartInfoDrawerProps) => {
  const toast = useToast();
  const toastIdRef = useRef<ToastId[]>([]);
  const queryClient = useQueryClient();
  const setCarPart = useSetCarPart({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['garageQuery'] });
      const id = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="success"
            title={`${startCase(carPart?.partType)} ${carPart?.id} Applied`}
            onClose={() => {
              if (toastIdRef.current.includes(id)) {
                toast.close(id);
                toastIdRef.current = toastIdRef.current.filter(
                  (toastId) => toastId !== id
                );
              }
            }}
          />
        ),
      });
      toastIdRef.current.push(id);
      onClose();
    },
    onError: (error: unknown) => {
      const id = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={`Car Part ${carPart?.id} Could Not Be Applied`}
            description={`${
              'Request error' //error?.response?.errors?.[0]?.message
            }`}
            onClose={() => {
              if (toastIdRef.current.includes(id)) {
                toast.close(id);
                toastIdRef.current = toastIdRef.current.filter(
                  (toastId) => toastId !== id
                );
              }
            }}
          />
        ),
      });
      toastIdRef.current.push(id);
    },
  });

  const partInfos: any = {
    "frontWing": { title1: "HANDLING" },
    "rearWing": { title1: "GRIP", title2: "SPEED" },
    "engineCooling": { title1: "COOLING", title2: "GRIP" },
    "brakeCooling": { title1: "BRAKING", title2: "GRIP" },
    "transmissionRatios": { title1: "SPEED", title2: "ACCELL" }
  }
  
  const getStatus = (partType: string, highSetting: number) => {
    switch (partType) {
      case "frontWing":
        if (highSetting > 99) 
          return { status1: "positive-1"};
        else if (highSetting > 59)
          return { status1: "neutral"}
        else return { status1: "negative-1" }
        break
      
      case "rearWing":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59) 
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }
  
      case "engineCooling":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59) 
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }
  
        case "brakeCooling":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59) 
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }
  
        case "transmissionRatios":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59) 
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }
    } 
    return "neutral"
  }

  const partsName: any = {
    "frontWing": "Front Wing",
    "rearWing": "Rear Wing",
    "engineCooling": "Engine Cooling",
    "brakeCooling": "Brake Cooling",
    "transmissionRatios": "Transmission Ratios"
  }

  const handleApplyPart = () => {
    const carParts: SetCarPartsInput = {
      [camelCase(carPart?.partType)]: carPart?.id,
    };
    setCarPart.mutate({ carId, carParts });
  };
  
  const partMetadata = partsData.find((p) => p.name === carPart?.partType);

  const partInfo = partInfos[carPart?.partType || "frontWing"] || { title1: "", title2: "" };
  const { status1, status2 }: any = getStatus(carPart?.partType || "", carPart?.highSettingRange || 100);

  return (
    <RDrawer
      isOpen={isOpen}
      onClose={onClose}
      heading="Part Info"
      drawerBodyProps={{ background: "linear-gradient(192.24deg, #151517 14.84%, #1c766d 61.48%, #387771 91.09%);" }}
      drawerFooterProps={{ background: "#387771" }}
      drawerHeaderProps={{ background: "#151517", color: "white.60" }}
      drawerBody={
        <Flex
          flexDirection="column"
          justifyContent="center"
          gap={8}
          alignItems="center"
          flex="1 0 0"
          px="8"
          pt={{ base: '20', md: '2' }}
        >
          <Flex flexDir="column" alignItems="center">
            <Text
              textTransform="uppercase"
              color="white.60"
              fontFamily="heading"
              fontSize="1.5rem"
            >
              {partsName[carPart?.partType || "frontWing"]}
            </Text>
            <Heading
              as="h1"
              fontSize="4.5rem"
              textTransform="uppercase"
              noOfLines={2}
              mb="2"
            >
              {carPart?.id}
            </Heading>
          </Flex>

          <Flex w="full" justify="center" maxH="50%">
            <RImage
              src={partMetadata?.path || ''}
              alt={carPart?.name || ''}
              filter={carPart?.locked ? 'blur(2px)' : ''}
              opacity={carPart?.locked ? '0.5' : ''}
              width="0"
              height="0"
              style={{ width: '15rem', height: '100%' }}
            />
          </Flex>
          <Flex w="full" gap="4" flexDir="column" pb="8">
            <Flex flexDir="column">
              <PartInfo title1={partInfo.title1} status1={status1} title2={partInfo.title2} status2={status2} />
            </Flex>
            <Flex flexDir="column">
              <Text
                textTransform="uppercase"
                color={colors.white[80]}
                fontFamily="heading"
                fontSize="md"
                mb="0.5rem"
              >
                Setting Range
              </Text>
              <RSlider
                isReadOnly
                suggestedMin={carPart?.lowSettingRange || 0}
                suggestedMax={carPart?.highSettingRange || 100}
              />
            </Flex>
            <Text fontSize="md">{carPart?.description}</Text>
          </Flex>
        </Flex>
      }
      drawerFooter={
        <>
          {isPartApplied ? (
            <Flex alignItems="center" justifyContent="center" p="2" bg="rgba(255, 255, 255, 0.1)" w="100%">
              <Heading
                color="white.100"
                as="h3"
                size="md"
                textTransform="uppercase"
              >
                Applied
              </Heading>

              <IconCheckmarkSmall
                ml="0.5rem"
                __css={{ path: { stroke: 'white.100' } }}
              />
            </Flex>
          ) : (
            <Button
              w="100%"
              variant="tertiary"
              onClick={handleApplyPart}
              loadingText="Applying..."
              isLoading={setCarPart.isLoading}
              id="apply-part-button"
            >
              Apply
            </Button>
          )}
        </>
      }
    />
  );
};

export default RPartInfoDrawer;
