import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSmile = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M10.5 7.5c-.563 0-1.031-.438-1.031-1 0-.531.469-1 1.031-1 .531 0 .969.469.969 1a.98.98 0 0 1-.969 1Zm-5.031 0c-.532 0-1-.438-1-1 0-.531.437-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1Zm5.156 2.281c.25-.312.719-.344 1.031-.094.344.282.375.75.094 1.063A4.899 4.899 0 0 1 8 12.531a4.914 4.914 0 0 1-3.781-1.781c-.282-.313-.219-.813.093-1.063.313-.25.782-.218 1.032.094A3.448 3.448 0 0 0 8 11c1 0 1.969-.438 2.625-1.219ZM8 0c4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8Zm0 14.5c3.563 0 6.5-2.906 6.5-6.5 0-3.563-2.938-6.5-6.5-6.5-3.594 0-6.5 2.938-6.5 6.5 0 3.594 2.906 6.5 6.5 6.5Z"
    />
  </svg>
));
export default SvgIconSmile;
