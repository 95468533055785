import {
  Flex,
  Box,
  Text,
  Tag,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import {
  RAlert,
  RBaseCardTopLeft,
  RBaseCardTopRight,
  RCarCard,
  RTiers,
  RTooltip,
} from 'components';
import { RaceParticipants, Races } from 'api/generated/graphql';
import { AppliedLivery, Livery, RarityTier, Tyres } from 'types';
import Flags, { FlagsProps } from 'components/flags';
import { AvatarPicture } from 'components/Avatar/AvatarPicture';
import { useEffect, useState } from 'react';
import {
  IconTyreC1,
  IconTyreC2,
  IconTyreC3,
  IconTyreC4,
  IconTyreC5,
  IconTyreInter,
  IconTyreWet,
} from 'icons';
import DrawerPages from '.';

const tyreIcons: Record<Tyres, JSX.Element> = {
  C1: <IconTyreC1 h="2rem" w="auto" />,
  C2: <IconTyreC2 h="2rem" w="auto" />,
  C3: <IconTyreC3 h="2rem" w="auto" />,
  C4: <IconTyreC4 h="2rem" w="auto" />,
  C5: <IconTyreC5 h="2rem" w="auto" />,
  INTER: <IconTyreInter h="2rem" w="auto" />,
  WET: <IconTyreWet h="2rem" w="auto" />,
};

export const LiveRace = ({ race }: { race: Races }) => {
  console.log(race);

  const [participants, setParticipants] = useState<RaceParticipants[]>([]);

  useEffect(() => {
    setParticipants(
      race?.playersParticipants.sort(
        (a, b) =>
          (a.qualifyingGridPosition ?? 0) - (b.qualifyingGridPosition ?? 0)
      ) as RaceParticipants[]
    );
  }, [race]);

  console.log(participants);

  return (
    <Flex flexDirection="column" width="full" h="full">
      <Tabs width="full" h="full" p="4">
        <TabList mb="1rem">
          <Tab zIndex={10}>Details</Tab>
          <Tab zIndex={10}>Entries</Tab>
        </TabList>
        <TabPanels width="full" h="full">
          <TabPanel w="full" h="full">
            <DrawerPages.RaceDetails race={race} isLive />
          </TabPanel>
          <TabPanel>
            <Box pb="20" height="calc(100% - 2rem)">
              <Text
                color="white.60"
                fontSize="sm"
                mb="2"
              >
                Here's the entries for the upcoming race.
              </Text>
              <RAlert
                mb="6"
                variant="info"
                description={
                  <Text
                    color="white.80"
                    fontSize="sm"
                  >
                    In qualifying, grid positions are determined by a single-lap
                    simulation with race setups on Very Soft Tyres. Cooling isn't a
                    factor. Downforce-intensive setups excel, but a good position
                    doesn't guarantee race success.
                  </Text>
                }
              />
              {participants?.map((participant) => (
                <Flex flexDirection="column" key={participant.id}>
                  <RCarCard
                    key={participant.id}
                    hasBackground
                    backgroundPreload
                    rarity={participant?.car.rarity as RarityTier}
                    playersParticipant={participant}
                    livery={participant?.car.livery as Livery}
                    appliedLivery={participant?.car.appliedLivery as AppliedLivery}
                    mb="6"
                    showResultsFooter={false}
                    isEntries
                    imageProps={{
                      transform: 'scale(70%)',
                      marginBottom: '-1rem',
                    }}
                  >
                    <RBaseCardTopLeft>
                      <Flex
                        flexDirection="column"
                        gap="10px"
                        justifyContent="center"
                      >
                        <Flex alignItems="center" gap={{ base: '2', md: '3' }}>
                          {participant?.qualifyingGridPosition && (
                            <RTooltip label="Qualifying Position">
                              <Tag bg="white.80" color="black.80">
                                {participant.qualifyingGridPosition || '--'}
                              </Tag>
                            </RTooltip>
                          )}

                          <Text
                            fontSize={['sm', 'md']}
                            maxW="5rem"
                            noOfLines={1}
                          >
                            {participant.car?.name || 'New Car'}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" h="1rem" gap="1">
                          <RTiers
                            tierType="rarity"
                            tier={participant.car.rarity as RarityTier}
                          />
                          {tyreIcons[participant.tyresId as Tyres]}
                        </Flex>
                      </Flex>
                    </RBaseCardTopLeft>
                    <RBaseCardTopRight maxW="50%">
                      <Flex alignItems="center" h="1.5rem">
                        <Flags
                          countryCode={
                            (participant?.user
                              ?.countryFlag as FlagsProps['countryCode']) ||
                            'default'
                          }
                        />

                        <AvatarPicture
                          key={participant.user.avatarId}
                          avatarId={participant.user.avatarId || ''}
                        />
                        <Text fontSize={['sm', 'md']} noOfLines={1}>
                          {participant?.user?.playerName || 'Owner Name'}
                        </Text>
                      </Flex>
                    </RBaseCardTopRight>
                  </RCarCard>
                </Flex>
              ))}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
