import EngineAndTuning from './EngineAndTuning';
import RaceDetails from './RaceDetails';
import SelectCar from './SelectCar';
import SelectTyres from './SelectTyres';
import AdmissionUse from './AdmissionUse';
import BuyTickets from './BuyTickets';
import { LiveRace } from './LiveRace';

const DrawerPages = {
  RaceDetails,
  SelectCar,
  SelectTyres,
  EngineAndTuning,
  AdmissionUse,
  BuyTickets,
  LiveRace,
};

export default DrawerPages;
