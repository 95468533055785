import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCheckBadge = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    aria-hidden="true"
    {...props}
  >
    <path fill="#fff" fillOpacity={0.1} d="M28 0H0v12l16 16h12V0Z" />
    <path
      fill="#4FC5BA"
      d="m24 7.031-8.5 8.5a.622.622 0 0 1-.5.219c-.219 0-.406-.063-.531-.219l-4.5-4.5a.684.684 0 0 1 0-1.031A.684.684 0 0 1 11 10l4 3.969L22.969 6A.684.684 0 0 1 24 6a.684.684 0 0 1 0 1.031Z"
    />
  </svg>
));
export default SvgIconCheckBadge;
