import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconJcb = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={20}
    fill="none"
    viewBox="0 0 24 20"
    aria-hidden="true"
    {...props}
  >
    <rect width={24} height={20} fill="#fff" rx={2} />
    <path
      fill="#fff"
      d="M22 14.603a3.11 3.11 0 0 1-3.105 3.105H2V5.41a3.11 3.11 0 0 1 3.105-3.105H22v12.298Z"
    />
    <path
      fill="url(#a)"
      d="M17.636 3.344c-1.223 0-2.225.99-2.225 2.225v2.31h3.141c.074 0 .16 0 .22.012.71.037 1.235.404 1.235 1.04 0 .5-.354.929-1.014 1.014v.025c.72.049 1.271.452 1.271 1.075 0 .673-.611 1.113-1.418 1.113h-3.448v4.523h3.265c1.222 0 2.224-.99 2.224-2.225V3.344h-3.251Z"
    />
    <path
      fill="url(#b)"
      d="M16.499 11.449h1.283a.972.972 0 0 0 .08-.006c.03-.003.06-.006.08-.006a.573.573 0 0 0 .451-.575.593.593 0 0 0-.452-.574c-.036-.013-.11-.013-.159-.013H16.5v1.174Z"
    />
    <path
      fill="url(#c)"
      d="M17.782 8.552a.52.52 0 0 1 .453.525.52.52 0 0 1-.453.526c-.012.012-.085.012-.122.012H16.5V8.54h1.161c.02 0 .048.004.073.007.02.003.038.006.05.006Z"
    />
    <path
      fill="url(#d)"
      d="M5.337 3.344c-1.222 0-2.225.99-2.225 2.225v5.489c.624.305 1.272.501 1.92.501.77 0 1.185-.465 1.185-1.1V7.867h1.908v2.58c0 1.002-.624 1.821-2.739 1.821-1.283 0-2.286-.281-2.286-.281v4.682h3.264c1.223 0 2.225-.99 2.225-2.225v-11.1H5.337Z"
    />
    <path
      fill="url(#e)"
      d="M11.487 3.344c-1.223 0-2.225.99-2.225 2.225v2.91c.562-.477 1.54-.783 3.117-.71.844.037 1.748.27 1.748.27v.94a4.232 4.232 0 0 0-1.687-.488c-1.198-.086-1.92.5-1.92 1.528 0 1.039.722 1.626 1.92 1.528.697-.05 1.235-.27 1.687-.49V12s-.892.232-1.748.269c-1.577.073-2.555-.232-3.117-.709v5.134h3.264c1.222 0 2.225-.99 2.225-2.225V3.345h-3.264Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={15.408}
        x2={20.902}
        y1={9.075}
        y2={9.075}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007940" />
        <stop offset={0.229} stopColor="#00873F" />
        <stop offset={0.743} stopColor="#40A737" />
        <stop offset={1} stopColor="#5CB531" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={15.408}
        x2={20.902}
        y1={9.075}
        y2={9.075}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007940" />
        <stop offset={0.229} stopColor="#00873F" />
        <stop offset={0.743} stopColor="#40A737" />
        <stop offset={1} stopColor="#5CB531" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={15.408}
        x2={20.902}
        y1={9.075}
        y2={9.075}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007940" />
        <stop offset={0.229} stopColor="#00873F" />
        <stop offset={0.743} stopColor="#40A737" />
        <stop offset={1} stopColor="#5CB531" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={3.11}
        x2={8.688}
        y1={10.007}
        y2={10.007}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1F286F" />
        <stop offset={0.475} stopColor="#004E94" />
        <stop offset={0.826} stopColor="#0066B1" />
        <stop offset={1} stopColor="#006FBC" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={9.23}
        x2={14.648}
        y1={10.007}
        y2={10.007}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C2C2F" />
        <stop offset={0.173} stopColor="#882730" />
        <stop offset={0.573} stopColor="#BE1833" />
        <stop offset={0.859} stopColor="#DC0436" />
        <stop offset={1} stopColor="#E60039" />
      </linearGradient>
    </defs>
  </svg>
));
export default SvgIconJcb;
