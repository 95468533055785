import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWallet = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={14}
    fill="none"
    viewBox="0 0 16 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M14 2c1.094 0 2 .906 2 2v8c0 1.125-.906 2-2 2H3c-1.688 0-3-1.313-3-3V3c0-1.656 1.313-3 3-3h11.5c.25 0 .5.25.5.5 0 .281-.25.5-.5.5H3c-1.125 0-2 .906-2 2v8c0 1.125.875 2 2 2h11c.531 0 1-.438 1-1V4c0-.531-.469-1-1-1H3.5a.494.494 0 0 1-.5-.5c0-.25.219-.5.5-.5H14Zm-1.25 6a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75Z"
    />
  </svg>
));
export default SvgIconWallet;
