import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHub = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={19}
    fill="none"
    viewBox="0 0 16 19"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M1.25 8.625c.598 0 1.125.527 1.125 1.125 0 .633-.527 1.125-1.125 1.125A1.11 1.11 0 0 1 .125 9.75c0-.598.492-1.125 1.125-1.125ZM6.875 9.75c0-.598.492-1.125 1.125-1.125.598 0 1.125.527 1.125 1.125 0 .633-.527 1.125-1.125 1.125A1.11 1.11 0 0 1 6.875 9.75Zm.527-2.496-2.53-2.531c-.177-.141-.247-.352-.247-.598 0-.422.352-.844.844-.844.21 0 .422.106.562.281l1.125 1.09V1.594c0-.457.352-.844.844-.844.457 0 .844.387.844.844v3.058l1.09-1.09a.751.751 0 0 1 .597-.28c.422 0 .844.386.844.843a.751.751 0 0 1-.281.598l-2.531 2.53C8.492 7.36 8.28 7.5 8 7.5c-.316 0-.527-.14-.598-.246Zm3.973 1.371c.598 0 1.125.527 1.125 1.125 0 .633-.527 1.125-1.125 1.125A1.11 1.11 0 0 1 10.25 9.75c0-.598.492-1.125 1.125-1.125Zm3.375 0c.598 0 1.125.527 1.125 1.125 0 .633-.527 1.125-1.125 1.125a1.11 1.11 0 0 1-1.125-1.125c0-.598.492-1.125 1.125-1.125Zm-6.188 3.621 2.532 2.566c.176.141.281.352.281.563 0 .246-.21.844-.844.844-.246 0-.457-.07-.597-.246l-1.09-1.09v3.023A.833.833 0 0 1 8 18.75c-.492 0-.844-.352-.844-.844v-3.023l-1.125 1.09c-.14.175-.351.246-.562.246a.833.833 0 0 1-.844-.844c0-.21.07-.422.246-.563l2.531-2.53A.766.766 0 0 1 8 12c.105 0 .352.035.563.246ZM5.75 9.75c0 .633-.527 1.125-1.125 1.125A1.11 1.11 0 0 1 3.5 9.75c0-.598.492-1.125 1.125-1.125.598 0 1.125.527 1.125 1.125Z"
    />
  </svg>
));
export default SvgIconHub;
