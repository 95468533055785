import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDoubleArrow = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    fill="none"
    viewBox="0 0 14 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M6.625 1.844a.465.465 0 0 1 .031-.688.465.465 0 0 1 .688.032l6 6.5a.453.453 0 0 1 0 .656l-6 6.5a.465.465 0 0 1-.688.031.446.446 0 0 1-.187-.375c0-.094.062-.219.156-.313L12.281 8 6.625 1.844Zm.719 6.5-6 6.5a.465.465 0 0 1-.688.031C.531 14.781.5 14.656.5 14.5c0-.094.031-.219.125-.313L6.281 8 .625 1.844a.465.465 0 0 1 .031-.688.465.465 0 0 1 .688.032l6 6.5a.453.453 0 0 1 0 .656Z"
    />
  </svg>
));
export default SvgIconDoubleArrow;
