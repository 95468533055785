import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetTierUpdatedCars = () => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const getTierUpdatedCarsGql = graphql(`
    query GetTierUpdatedCars {
      getTierUpdatedCars {
        id
        name
        points
        livery
        tier {
          id
          name
          rank
          pointsRequired
        }
      }
    }
  `);

  const getTierUpdatedCars = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['getTierUpdatedCars'],
    queryFn: async () => {
      return graphQLClient.request(getTierUpdatedCarsGql);
    },
  });

  return getTierUpdatedCars;
};

export default useGetTierUpdatedCars;
