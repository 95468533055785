import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetTicketPrice = (bundleId: number) => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();
  const getTicketPriceFn = graphql(`
    query GetTicketPrice($bundleId: Float!) {
      ticketPrice(TicketPriceInput: { bundleId: $bundleId }) {
        price
        vextPrice
        maticPrice
        usdcPrice
        readableVextPrice
        readableMaticPrice
        readableUsdcPrice
      }
    }
  `);

  return useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['getTicketPrice'],
    queryFn: async () => {
      return await graphQLClient.request(getTicketPriceFn, { bundleId });
    },
  });
};

export default useGetTicketPrice;
