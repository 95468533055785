import { Text, Flex, FlexProps, ResponsiveValue, Box } from '@chakra-ui/react';

import isNumber from 'lodash.isnumber';

const PositionAndPoints = ({
  fontFamily = 'body',
  carPosition,
  points,
  variant = 'purple',
  ...rest
}: FlexProps & {
  fontFamily?: ResponsiveValue<'body' | 'heading' | 'mono'> | undefined;
  carPosition?: number;
  points?: number;
  variant?: 'white' | 'purple';
}) => {
  const color = variant === 'white' ? 'white.100' : 'perrywinkle.100';
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      gap={1}
      alignItems="center"
      {...rest}
    >
      {carPosition && (
        <Box
          bgColor={color}
          fontWeight="extrabold"
          color="black.60"
          minW="1.25rem"
          fontSize={{ base: '0.75rem', md: '0.875rem' }}
          fontFamily="body"
          textAlign="center"
        >
          {carPosition}
        </Box>
      )}

      {isNumber(points) && (
        <Text
          color={color}
          fontFamily={fontFamily}
          fontWeight="extrabold"
          fontSize={{ base: '0.875rem', md: 'lg' }}
          lineHeight="1"
        >
          {points}
          <Text
            ml="1"
            as="span"
            fontSize={{ base: '0.75rem', md: '0.875rem' }}
            fontWeight="normal"
          >
            PTS
          </Text>
        </Text>
      )}
    </Flex>
  );
};

export default PositionAndPoints;
