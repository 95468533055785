import { useEffect, useState } from 'react';
import { Flex, FlexProps, Heading, Text, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Maybe } from 'graphql/jsutils/Maybe';

import { CarParts, RaceParticipants } from 'api/generated/graphql';
import RSlider from 'components/Slider/Slider';
import {
  IconBrakeCooling,
  IconEngineCooling,
  IconEngineMode,
  IconFrontWing,
  IconRearWing,
  IconSuspensionRide,
  IconSuspensionStifness,
  IconTransmissionRatio,
} from 'icons';
import { PartTypes } from 'types';
import { partsSliderGradient } from 'utils/themeConstants';

export type PartInfosType = {
  [key in PartTypes]: {
    title1: string;
    title2?: string;
  };
};

const partTypeToIcon = {
  'Engine Mode': IconEngineMode,
  'Engine Cooling': IconEngineCooling,
  'Front Wing': IconFrontWing,
  'Rear Wing': IconRearWing,
  'Brake Cooling': IconBrakeCooling,
  'Suspension Ride Height': IconSuspensionRide,
  'Suspension Stiffness': IconSuspensionStifness,
  Transmission: IconTransmissionRatio,
};

const partText = {
  'Front Wing': {
    pro: 'Handling',
    con: null,
  },
  'Rear Wing': {
    pro: 'Turns',
    con: 'Speed',
  },
  'Engine Mode': {
    pro: 'Speed',
    con: 'Turns',
  },
  'Engine Cooling': {
    pro: 'Cooling',
    con: 'Turns',
  },
  'Brake Cooling': {
    pro: 'Braking',
    con: 'Turns',
  },
  'Suspension Ride Height': {
    pro: 'Fast Turns',
    con: null,
  },
  'Suspension Stiffness': {
    pro: 'Speed',
    con: 'Turns',
  },
};

interface PartSliderProps extends FlexProps {
  part?: Maybe<CarParts>;
  partInfos: PartInfosType;
  userRaceData?: RaceParticipants;
  handleSliderOnChangeEnd: (tunedPart: {
    name: string;
    val: number;
  }) => Promise<void>;
  getPartName: (name: string) => string;
  getMinSuggestion: (part: CarParts) => number;
  getMaxSuggestion: (part: CarParts) => number;
  sliderValue: number;
  isLoading?: boolean;
}

const getIconForPartType = (partType: string) => {
  const IconComponent = partTypeToIcon[partType as keyof typeof partTypeToIcon];
  return IconComponent ? <IconComponent w="1.5rem" h="1.5rem" /> : null;
};

export const PartSlider = ({
  part,
  partInfos,
  userRaceData,
  getMaxSuggestion,
  getMinSuggestion,
  getPartName,
  handleSliderOnChangeEnd,
  sliderValue,
  isLoading = false,
  ...props
}: PartSliderProps) => {
  const [value, setValue] = useState(sliderValue);

  const highSettingRange = part?.highSettingRange || 0;
  const lowSettingRange = part?.lowSettingRange || 0;
  const valueInPercentage =
    ((value - lowSettingRange) / (highSettingRange - lowSettingRange)) * 100;

  const proText = partText[part?.type.name as keyof typeof partText]?.pro;
  const conText = partText[part?.type.name as keyof typeof partText]?.con;

  const partsDescription = {
    'Front Wing':
      'Adjust the balance, more is needed for fast corners and soft tyres.',
    'Rear Wing': 'Adjust the downforce, less is better for long straights.',
    'Engine Mode':
      'Adjust the engine power and fuel usage, more is better for long straights.',
    'Engine Cooling':
      'Adjust the engine cooling, more is needed when the air temp and the engine mode are high.',
    'Brake Cooling':
      'Adjust the brake performance, more is needed on tracks with lots of big stops.',
    'Suspension Ride Height':
      'Adjust the ride height of the car to clear large bumps, but lower is better for slow corners.',
    'Suspension Stiffness':
      'Adjust the suspension stiffness, softer is better for slow corners.',
  };

  useEffect(() => {
    setValue(sliderValue);
  }, [sliderValue]);

  return (
    <Flex
      pos="relative"
      w={{ base: '9.25rem', md: '17.5rem' }}
      flexDir="column"
      bg={partsSliderGradient}
      py="0.75rem"
      alignItems="center"
      justifyContent="center"
      borderRadius="0.5rem"
      borderColor="white.10"
      borderWidth="0.063rem"
      {...props}
    >
      <Tooltip
        label={
          partsDescription[part?.type.name as keyof typeof partsDescription]
        }
        openDelay={500}
      >
        <Flex
          flexDir="column"
          w="full"
          alignItems="center"
          justifyContent="center"
          gap="0.75rem"
        >
          {getIconForPartType(part?.type.name || '')}
          <Heading
            fontFamily="heading"
            color="white.80"
            fontSize="0.75rem"
            lineHeight="0.875rem"
            textTransform="capitalize"
            fontWeight={400}
          >
            {part?.type.name}
          </Heading>
        </Flex>
      </Tooltip>

      <Flex mt="0.75rem" mb="0.5rem" justifyContent="space-between">
        <motion.div
          style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
          animate={{ opacity: valueInPercentage > 50 ? 1 : 0.4 }}
        >
          {valueInPercentage === 50 ? (
            <Text
              color="white.100"
              fontSize="0.625rem"
              lineHeight="0.75rem"
              textTransform="capitalize"
              fontFamily="body"
              fontWeight={500}
            >
              More +
            </Text>
          ) : (
            <>
              <Text
                fontSize="0.625rem"
                lineHeight="0.75rem"
                textTransform="capitalize"
                fontFamily="body"
                color="treasuredTeal.100"
                fontWeight={500}
              >
                {proText} +
              </Text>
              {conText && (
                <Text
                  fontSize="0.625rem"
                  lineHeight="0.75rem"
                  textTransform="capitalize"
                  fontFamily="body"
                  color="fieryCoral.100"
                  fontWeight={500}
                >
                  {conText} -
                </Text>
              )}
            </>
          )}
        </motion.div>
      </Flex>

      <RSlider
        onChange={(value) => setValue(value)}
        value={value}
        onChangeEnd={(val) => {
          handleSliderOnChangeEnd({
            name: getPartName(part?.type.name || ''),
            val,
          });
        }}
        defaultValue={
          Number(
            userRaceData?.[
              getPartName(part?.type.name || '') as keyof RaceParticipants
            ]
          ) || 50
        }
        min={part?.lowSettingRange || 0}
        max={part?.highSettingRange || 0}
        suggestedMin={getMinSuggestion(part as CarParts)}
        suggestedMax={getMaxSuggestion(part as CarParts)}
        cursor={isLoading ? 'not-allowed' : 'pointer'}
        opacity={isLoading ? 0.8 : 1}
      />

      <Flex mt="0.5rem" justifyContent="space-between">
        <motion.div
          style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
          animate={{ opacity: valueInPercentage < 50 ? 1 : 0.4 }}
        >
          {valueInPercentage === 50 ? (
            <Text
              color="white.100"
              fontSize="0.625rem"
              lineHeight="0.75rem"
              textTransform="capitalize"
              fontFamily="body"
              fontWeight={500}
            >
              Less
            </Text>
          ) : (
            <>
              {conText && (
                <Text
                  fontSize="0.625rem"
                  lineHeight="0.75rem"
                  textTransform="capitalize"
                  fontFamily="body"
                  color="treasuredTeal.100"
                  fontWeight={500}
                >
                  {conText} +
                </Text>
              )}
              <Text
                fontSize="0.625rem"
                lineHeight="0.75rem"
                textTransform="capitalize"
                fontFamily="body"
                color="fieryCoral.100"
                fontWeight={500}
              >
                {proText} -
              </Text>
            </>
          )}
        </motion.div>
      </Flex>
    </Flex>
  );
};
