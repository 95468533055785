import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTick = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={6}
    fill="none"
    viewBox="0 0 8 6"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M7.297.238a.71.71 0 0 0-1.031-.03l-3.47 3.5-1.5-1.5c-.312-.282-.78-.282-1.062 0-.312.312-.312.78 0 1.062l2 2a.753.753 0 0 0 .563.218.622.622 0 0 0 .5-.218l4-4a.684.684 0 0 0 0-1.032Z"
    />
  </svg>
));
export default SvgIconTick;
