import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTone = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={21}
    fill="none"
    viewBox="0 0 22 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="m8.305 9.008 4.687 4.687c.235.235.235.625 0 .899L8.656 18.89a2.832 2.832 0 0 1-2.11.898H5.063l-1.875 1.133C3.07 21 2.954 21 2.837 21a.588.588 0 0 1-.43-.156l-1.25-1.25c-.195-.196-.234-.508-.078-.782l1.133-1.875v-1.523c0-.781.312-1.523.86-2.11l4.335-4.296a.595.595 0 0 1 .899 0Zm-.547 9.023 3.906-3.906-3.828-3.79-3.867 3.868c-.313.352-.508.781-.508 1.211v1.68c0 .117-.04.234-.078.312l-.977 1.64.547.548 1.602-.977c.117-.078.234-.078.351-.078h1.64c.43 0 .86-.195 1.212-.508Zm12.031-15.82c1.602 1.602 1.602 4.219-.039 5.86l-4.453 4.492.508.507a.613.613 0 0 1 0 .86c-.117.156-.274.195-.43.195-.195 0-.352-.04-.469-.195L8.031 7.055c-.234-.235-.234-.625 0-.899a.662.662 0 0 1 .899 0l.508.508 4.492-4.453c1.562-1.602 4.258-1.602 5.86 0Zm-.898 4.96a2.9 2.9 0 0 0 0-4.1c-1.094-1.094-3.008-1.094-4.102 0l-4.453 4.492 4.062 4.101 4.493-4.492Z"
    />
  </svg>
));
export default SvgIconTone;
