import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWind = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M2.083 7.5c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h12.5c.664 0 1.25-.547 1.25-1.25 0-.664-.586-1.25-1.25-1.25h-1.25c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h1.25c2.032 0 3.75 1.719 3.75 3.75 0 2.07-1.718 3.75-3.75 3.75h-12.5Zm5 5c2.032 0 3.75 1.719 3.75 3.75 0 2.07-1.718 3.75-3.75 3.75h-1.25c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h1.25c.664 0 1.25-.547 1.25-1.25 0-.664-.586-1.25-1.25-1.25h-5c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h5Zm10-3.75c2.032 0 3.75 1.719 3.75 3.75 0 2.07-1.718 3.75-3.75 3.75h-1.25c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h1.25c.664 0 1.25-.547 1.25-1.25 0-.664-.586-1.25-1.25-1.25h-15c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h15Z"
    />
  </svg>
));
export default SvgIconWind;
