import { useMutation } from '@tanstack/react-query';

import { MutationTuningForRaceArgs } from 'api/generated/graphql';
import { useGraphQLClient } from 'hooks';

const useTuningForRace = () => {
  const { graphql, graphQLClient } = useGraphQLClient();

  const tuningForRaceGql = graphql(`
    mutation TuningForRace($raceTuningInput: RaceTuningInput!) {
      tuningForRace(raceTuningInput: $raceTuningInput) {
        id
        status
      }
    }
  `);

  const tuningForRace = useMutation({
    mutationKey: ['TuningForRace'],
    mutationFn: ({ raceTuningInput }: MutationTuningForRaceArgs) => {
      return graphQLClient.request(tuningForRaceGql, {
        raceTuningInput,
      });
    },
  });

  return { tuningForRace };
};

export default useTuningForRace;
