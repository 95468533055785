import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTyreC2 = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    viewBox="0 0 80 80"
    aria-hidden="true"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={80} height={80} fill="#000" fillOpacity={0.8} rx={40} />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M22.477 13.64C13.82 19.47 8.127 29.359 8.127 40.579a32.31 32.31 0 0 0 6.768 19.838l1.572-1.237a30.318 30.318 0 0 1-6.34-18.601c0-10.52 5.334-19.793 13.445-25.263l-1.095-1.674Zm41.469 46.462a30.327 30.327 0 0 0 7.082-19.524c0-11.194-6.04-20.976-15.038-26.268l1.036-1.711c9.576 5.642 16.002 16.06 16.002 27.979 0 7.91-2.83 15.16-7.535 20.792l-1.547-1.268Z"
        clipRule="evenodd"
      />
      <circle
        cx={40.201}
        cy={39.996}
        r={22.167}
        stroke="#fff"
        transform="rotate(-90 40.201 39.996)"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="M35.983 38.547h8.025v-5.15h2.285V46h-2.285v-5.15h-8.025V46h-2.285V33.397h2.285v5.15Z"
      />
      <path
        stroke="#fff"
        d="M13.601 13.601c14.58-14.58 38.218-14.58 52.798 0m0 52.798c-14.58 14.58-38.218 14.58-52.798 0"
        opacity={0.2}
      />
      <path
        fill="#999"
        fillRule="evenodd"
        d="m47.762 8.967-.37 1.454a30.785 30.785 0 0 0-2.45-.462l.11-.742.11-.742c.878.129 1.746.293 2.6.492Zm-5.807-.052.036-.75a32.989 32.989 0 0 0-3.186 0l.036.75.036.75a31.484 31.484 0 0 1 3.041 0l.037-.75Zm-6.211.302-.11-.742a32.29 32.29 0 0 0-3.124.618l.182.727.182.728c.975-.244 1.969-.44 2.979-.59l-.11-.741Zm-14.23 56.825-.448.602a32.517 32.517 0 0 0 2.647 1.772l.386-.643.386-.643a31.007 31.007 0 0 1-2.524-1.69l-.447.602Zm5.328 3.2-.322.677c.956.453 1.938.86 2.942 1.22l.253-.706.253-.706a30.739 30.739 0 0 1-2.805-1.163l-.321.678Zm5.85 2.093-.182.728c1.023.255 2.066.462 3.125.618l.109-.742.109-.742a30.796 30.796 0 0 1-2.98-.59l-.181.728Zm6.15.906-.037.749a33.002 33.002 0 0 0 3.186 0l-.036-.75-.037-.748a31.552 31.552 0 0 1-3.04 0l-.037.749Zm6.21-.302.11.742a32.293 32.293 0 0 0 3.123-.618l-.181-.728-.182-.727c-.975.243-1.97.44-2.98.589l.11.742Zm9.223-2.02c-.956.453-1.937.86-2.941 1.22l-.253-.706-.253-.706a30.739 30.739 0 0 0 2.805-1.163l.321.678.321.677Zm2.422-2.146.386.643a32.501 32.501 0 0 0 2.646-1.772l-.447-.602-.447-.602a31.007 31.007 0 0 1-2.525 1.69l.387.643Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={80} height={80} fill="#fff" rx={40} />
      </clipPath>
    </defs>
  </svg>
));
export default SvgIconTyreC2;
