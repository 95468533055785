import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMexicoCityMinimapMarkers = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={375}
      height={375}
      fill="none"
      viewBox="0 0 375 375"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#000"
        fillOpacity={0.2}
        d="M103.998 169.523c-11.292-.514-22.485-.842-34.47-1.189-8.17 0-7.577-3.417-9.063-14.56-1.485-11.143-4.16-18.275-4.605-19.761-.446-1.485-1.635-7.131-4.903-7.131-3.269 0-4.606 5.349-6.538 7.131-.787.727-3.12 3.418-7.725 1.189-3.653-1.768-5.347-1.562-8.32-1.634-3.418-.099-10.4-.297-10.995-.297-.743 0-3.312 0-4.16-2.378-.742-2.08-.446-3.565-.297-4.903 2.674-12.331 7.875-16.788 12.183-20.8 5.107-4.754 14.115-4.903 16.64-4.754 2.526.148 35.658 1.486 40.264 1.634 3.28.106 46.644 1.594 49.92 1.783 7.726.446 32.241 1.783 45.316 1.486 2.376-.054 52.743 0 62.697.594 3.859.23 41.75 1.783 46.058 1.783 4.309 0 52.298 2.971 53.635 3.12 1.338.148 4.458.594 5.646 2.08 1.189 1.486.595 5.497.595 7.131-.099 3.121-.297 9.539-.297 10.252 0 .892.148 2.377.594 2.823.356.357 1.634 1.128 2.377 1.486 1.337.644 3.863 1.693 6.24 2.525.899.298 2.663 1.486 2.526 3.863l-.446 7.726c-.594 14.263-7.875 29.269-9.509 33.429-1.634 4.16-30.012 64.036-31.052 66.561-1.04 2.526-18.869 36.549-20.206 39.967-1.069 2.733.446 4.209 1.337 4.605 3.319 1.684 10.282 5.23 11.589 5.943 1.268.692.776 2.649.339 3.625a.452.452 0 0 1-.113.154c-6.117 5.57-18.505 16.878-19.689 18.062-1.486 1.485-2.526 1.485-3.269 1.485-.742 0-5.2-1.782-5.497-3.268-.297-1.486.149-12.48.297-19.166.149-6.686 1.486-45.464 1.486-51.852 0-5.111-3.467-7.479-5.2-8.023-1.436-.545-5.765-2.467-11.589-5.795-7.28-4.16-7.131-6.24-11.588-14.56-4.458-8.32-14.709-8.914-17.384-9.063-2.674-.149-21.988-.743-27.783-.891-5.794-.149-7.874-5.646-8.171-6.092-.298-.446-2.675-6.091-4.012-10.4-1.337-4.309-6.24-6.835-9.806-8.023-2.852-.951-17.036-6.934-23.771-9.806-9.495-3.709-18.545-4.011-23.01-4.159l-.019-.001c-1.788-.06-8.618-.415-16.641-.743-7.28-.297-13.074-.891-19.611-1.188Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.2}
        strokeWidth={6}
        d="M103.998 169.523c-11.292-.514-22.485-.842-34.47-1.189-8.17 0-7.577-3.417-9.063-14.56-1.485-11.143-4.16-18.275-4.605-19.761-.446-1.485-1.635-7.131-4.903-7.131-3.269 0-4.606 5.349-6.538 7.131-.787.727-3.12 3.418-7.725 1.189-3.653-1.768-5.347-1.562-8.32-1.634-3.418-.099-10.4-.297-10.995-.297-.743 0-3.312 0-4.16-2.378-.742-2.08-.446-3.565-.297-4.903 2.674-12.331 7.875-16.788 12.183-20.8 5.107-4.754 14.115-4.903 16.64-4.754 2.526.148 35.658 1.486 40.264 1.634 3.28.106 46.644 1.594 49.92 1.783 7.726.446 32.241 1.783 45.316 1.486 2.376-.054 52.743 0 62.697.594 3.859.23 41.75 1.783 46.058 1.783 4.309 0 52.298 2.971 53.635 3.12 1.338.148 4.458.594 5.646 2.08 1.189 1.486.595 5.497.595 7.131-.099 3.121-.297 9.539-.297 10.252 0 .892.148 2.377.594 2.823.356.357 1.634 1.128 2.377 1.486 1.337.644 3.863 1.693 6.24 2.525.899.298 2.663 1.486 2.526 3.863l-.446 7.726c-.594 14.263-7.875 29.269-9.509 33.429-1.634 4.16-30.012 64.036-31.052 66.561-1.04 2.526-18.869 36.549-20.206 39.967-1.069 2.733.446 4.209 1.337 4.605 3.319 1.684 10.282 5.23 11.589 5.943 1.308.713.743 2.774.297 3.715-6.091 5.546-18.571 16.937-19.76 18.126-1.486 1.485-2.526 1.485-3.269 1.485-.742 0-5.2-1.782-5.497-3.268-.297-1.486.149-12.48.297-19.166.149-6.686 1.486-45.464 1.486-51.852 0-5.111-3.467-7.479-5.2-8.023-1.436-.545-5.765-2.467-11.589-5.795-7.28-4.16-7.131-6.24-11.588-14.56-4.458-8.32-14.709-8.914-17.384-9.063-2.674-.149-21.988-.743-27.783-.891-5.794-.149-7.874-5.646-8.171-6.092-.298-.446-2.675-6.091-4.012-10.4-1.337-4.309-6.24-6.835-9.806-8.023-2.852-.951-17.036-6.934-23.771-9.806-9.495-3.709-18.545-4.011-23.01-4.159l-.019-.001c-1.788-.06-8.618-.415-16.641-.743-7.28-.297-13.074-.891-19.611-1.188Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.95}
        strokeWidth={2}
        d="M103.998 169.523c-11.292-.514-22.485-.842-34.47-1.189-8.17 0-7.577-3.417-9.063-14.56-1.485-11.143-4.16-18.275-4.605-19.761-.446-1.485-1.635-7.131-4.903-7.131-3.269 0-4.606 5.349-6.538 7.131-.787.727-3.12 3.418-7.725 1.189-3.653-1.768-5.347-1.562-8.32-1.634-3.418-.099-10.4-.297-10.995-.297-.743 0-3.312 0-4.16-2.378-.742-2.08-.446-3.565-.297-4.903 2.674-12.331 7.875-16.788 12.183-20.8 5.107-4.754 14.115-4.903 16.64-4.754 2.526.148 35.658 1.486 40.264 1.634 3.28.106 46.644 1.594 49.92 1.783 7.726.446 32.241 1.783 45.316 1.486 2.376-.054 52.743 0 62.697.594 3.859.23 41.75 1.783 46.058 1.783 4.309 0 52.298 2.971 53.635 3.12 1.338.148 4.458.594 5.646 2.08 1.189 1.486.595 5.497.595 7.131-.099 3.121-.297 9.539-.297 10.252 0 .892.148 2.377.594 2.823.356.357 1.634 1.128 2.377 1.486 1.337.644 3.863 1.693 6.24 2.525.899.298 2.663 1.486 2.526 3.863l-.446 7.726c-.594 14.263-7.875 29.269-9.509 33.429-1.634 4.16-30.012 64.036-31.052 66.561-1.04 2.526-18.869 36.549-20.206 39.967-1.069 2.733.446 4.209 1.337 4.605 3.319 1.684 10.282 5.23 11.589 5.943 1.308.713.743 2.774.297 3.715-6.091 5.546-18.571 16.937-19.76 18.126-1.486 1.485-2.526 1.485-3.269 1.485-.742 0-5.2-1.782-5.497-3.268-.297-1.486.149-12.48.297-19.166.149-6.686 1.486-45.464 1.486-51.852 0-5.111-3.467-7.479-5.2-8.023-1.436-.545-5.765-2.467-11.589-5.795-7.28-4.16-7.131-6.24-11.588-14.56-4.458-8.32-14.709-8.914-17.384-9.063-2.674-.149-21.988-.743-27.783-.891-5.794-.149-7.874-5.646-8.171-6.092-.298-.446-2.675-6.091-4.012-10.4-1.337-4.309-6.24-6.835-9.806-8.023-2.852-.951-17.036-6.934-23.771-9.806-9.495-3.709-18.545-4.011-23.01-4.159l-.019-.001c-1.788-.06-8.618-.415-16.641-.743-7.28-.297-13.074-.891-19.611-1.188Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={344.391}
        y={91.224}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m354.618 99.3-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392V99.3Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={320.322}
        y={122.722}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m327.727 135.958 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={352.117}
        y={114.996}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M359.304 128.76v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={306.059}
        y={276.05}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M317.166 289.442h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={314.676}
        y={300.119}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M321.965 313.883v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={267.43}
        y={326.862}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M274.739 340.626v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={237.418}
        y={230.289}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M244.574 244.053v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={285.258}
        y={224.346}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M296.819 232.374v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936h-1.464l3.372-6.972Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={243.064}
        y={189.283}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M250.405 203.047v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={187.496}
        y={211.569}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m193.737 219.645-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={196.412}
        y={168.483}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m204.27 176.559-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.738 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={44.272}
        y={168.483}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m50.975 176.559-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.07 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={54.672}
        y={108.756}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m61.206 116.832-1.837.948v-1.284l2.077-1.14h1.151v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456H66.16l-.468.456v.768H64.3v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={27.334}
        y={140.848}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m34.008 148.924-1.836.948v-1.284l2.076-1.14H35.4v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608H40.81v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.773Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={22.877}
        y={109.053}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m29.393 117.129-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.142 5.688v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={1.186}
        y={136.094}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m7.602 144.17-1.836.948v-1.284l2.076-1.14h1.152v8.4H7.602v-6.924Zm3.262 5.688v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236H12.1l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={8.912}
        y={79.338}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m15.932 87.414-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.91-.048v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936H19.47l3.372-6.972Z"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="m122.447 123.893-4.025-.165.185-4.522 4.025.165zM122.816 114.849l-4.025-.165.185-4.522 4.025.165zM123.188 105.805l-4.025-.165.185-4.522 4.025.165zM123.557 96.761l-4.025-.164.185-4.522 4.025.164zM123.926 87.718l-4.025-.165.185-4.522 4.025.164z"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="m118.607 119.207-4.025-.165.185-4.522 4.025.165zM118.977 110.163l-4.025-.165.185-4.522 4.025.165zM119.348 101.119l-4.025-.165.185-4.522 4.025.165zM119.717 92.074l-4.025-.164.185-4.522 4.025.164z"
      />
      <path
        fill="#000"
        d="m118.422 123.729-4.025-.165.185-4.522 4.025.165zM118.793 114.685l-4.025-.165.185-4.522 4.025.165zM119.162 105.641l-4.025-.165.185-4.522 4.025.165zM119.531 96.596l-4.025-.164.185-4.522 4.025.164zM119.902 87.552l-4.025-.164.185-4.522 4.025.164z"
      />
      <path
        fill="#000"
        d="m122.633 119.371-4.025-.165.185-4.522 4.025.165zM123.002 110.327l-4.025-.165.185-4.522 4.025.165zM123.371 101.283l-4.025-.165.185-4.522 4.025.165zM123.742 92.24l-4.025-.165.185-4.522 4.025.164z"
      />
      <path
        fill="#E2645A"
        d="m150.253 78.61-14.404-10.454-.808 19.744 15.212-9.29Zm2.262.092c-.03.728-.357 1.395-.97 1.758l-15.955 9.698c-1.229.776-2.682-.255-2.617-1.856l.849-20.763c.064-1.553 1.599-2.51 2.762-1.685l15.108 11.017c.579.46.85 1.152.823 1.831Z"
      />
    </svg>
  )
);
export default SvgIconMexicoCityMinimapMarkers;
