import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSearch = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    viewBox="0 0 17 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M15.75 14.719c.313.312.313.781 0 1.062a.622.622 0 0 1-.5.219.753.753 0 0 1-.563-.219L10.5 11.594A6.389 6.389 0 0 1 6.469 13C2.906 13 0 10.094 0 6.5 0 2.937 2.875 0 6.469 0c3.562 0 6.5 2.938 6.5 6.5 0 1.531-.5 2.938-1.406 4.031l4.187 4.188ZM1.5 6.5c0 2.781 2.219 5 5 5 2.75 0 5-2.219 5-5 0-2.75-2.25-5-5-5-2.781 0-5 2.25-5 5Z"
    />
  </svg>
));
export default SvgIconSearch;
