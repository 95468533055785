import {
  Box,
  Collapse,
  Divider,
  Flex,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Switch,
  SwitchProps,
  useDisclosure,
} from '@chakra-ui/react';
import { RModal } from 'components';

interface SetCarNameModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AudioSettingRow = ({
  title,
  isChecked,
  ...props
}: { title: string } & SwitchProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex flexDirection="column" bg="white.10">
      <Flex
        w="100%"
        h="3.125rem"
        alignItems="center"
        justifyContent="space-between"
        p="1rem"
      >
        <Heading
          size="md"
          textTransform="uppercase"
          fontWeight="400"
          color="white.80"
        >
          {title}
        </Heading>
        <Switch size="sm" isChecked={isOpen} onChange={onToggle} {...props} />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box>
          <Box p="2">
            <Divider borderColor="zincDust.100" />
          </Box>
          <Box w="100%" h="3.125rem" p="4">
            <Slider
              h="0.5rem"
              aria-label="tuning-slider"
              defaultValue={50}
              // onChangeEnd={onChangeEnd}
            >
              <SliderTrack bg="white.10">
                <SliderFilledTrack bg="baltic.100" />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        </Box>
      </Collapse>
    </Flex>
  );
};

const AudioSettingsModal = ({ isOpen, onClose }: SetCarNameModalProps) => {
  return (
    <RModal
      variant="darkGrey"
      title="Audio Settings"
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <Flex flexDirection="column" w="full" gap="4">
        <AudioSettingRow title="Sound FX" />
        <AudioSettingRow title="Music" />
      </Flex>
    </RModal>
  );
};

export default AudioSettingsModal;
