/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  ToastId,
  useToast,
} from '@chakra-ui/react';
import { PriceLabel } from 'components/Balance/PriceLabel';
import useWalletBalance from 'hooks/useWalletBalance';

import {
  IconChevron,
  IconDollarBalance,
  IconMatic,
  IconVextBorder,
} from 'icons';
import { useEffect, useRef, useState } from 'react';
import RToast from 'components/Toast/Toast';
import { useCryptoConvervtion } from 'hooks/Crypto/useCryptoConvertion';
import { useWallet } from 'context';
import { BalanceComponent } from '../BalanceComponent';
import useGetTicketPrice from 'hooks/Crypto/useGetTicketPrice';
import { CrossmintEvent } from '@crossmint/client-sdk-react-ui';
import { TERMS_URL } from 'pages/login';
import { useGetVextMessageForTickets } from 'hooks';

const tokenList = {
  usdc: {
    currency: 'USDC',
    icon: IconDollarBalance,
    color: 'usdc.100',
  },
  vext: {
    currency: 'VEXT',
    icon: IconVextBorder,
    color: 'vext.100',
  },
  matic: {
    currency: 'MATIC',
    icon: IconMatic,
    color: 'matic.100',
  },
};

type CryptoTabCarProps = {
  onSuccess: (e: CrossmintEvent) => void;
  bundleId: number;
  quantity?: number;
  setQuantity?: (value: number) => void;
};

export const CryptoTabTicket = ({
  onSuccess,
  bundleId,
  quantity = 1,
  setQuantity = () => null,
}: CryptoTabCarProps) => {
  const { data: balance } = useWalletBalance();
  const { data: ticketPriceData } = useGetTicketPrice(bundleId);
  const userBalance = balance?.getUserBalance;
  const ticketPrice = ticketPriceData?.ticketPrice;

  // const { data: ticketCryptoAllowance } = useGetCryptoAllowance(Asset.Ticket);
  // const vextAllowance = ticketCryptoAllowance?.getUserAllowance?.vext;
  // const usdcAllowance = ticketCryptoAllowance?.getUserAllowance?.usdc;

  const { wallet } = useWallet();

  const [selectedCrypto, setSelectedCrypto] = useState<
    'matic' | 'vext' | 'usdc'
  >('matic');

  const [cryptoPrice, setCryptoPrice] = useState<{
    price: number;
    readablePrice: number;
  }>({ price: 0, readablePrice: 0 });

  const [usdPrice, setUsdPrice] = useState<number>(0);

  const { data: convertion } = useCryptoConvervtion(selectedCrypto);

  const [isLoading, setIsLoading] = useState(false);


  const [deadline] = useState<string>(
    (Math.floor(Date.now() / 1000) + 60 * 30).toString()
  );
  const { data: vextConvertionData } = useCryptoConvervtion('vext');

  const { data: signature, refetch: refetchSignature } = useGetVextMessageForTickets({
    vextRate: vextConvertionData?.last,
    deadline,
    bundleId,
  });

  useEffect(() => {
    // convert crypto to usd
    if (convertion?.last) {
      if (selectedCrypto === 'vext') {
        const usdPrice = (cryptoPrice.readablePrice / 100) * Number(convertion?.last);
        usdPrice > 5 ? setUsdPrice(usdPrice) : setUsdPrice(5);
      } else {
      const usdPrice = cryptoPrice.readablePrice * Number(convertion?.last);
      usdPrice > 5 ? setUsdPrice(usdPrice) : setUsdPrice(5);
      }
    }
  }, [cryptoPrice, convertion]);

  useEffect(() => {
    setCryptoPrice({
      price: ticketPrice?.maticPrice || 0,
      readablePrice: ticketPrice?.readableMaticPrice || 0,
    });
  }, [ticketPrice]);

  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);
  const sendToast = (varaint: string, text: string) => {
    toastIdRef.current = toast({
      position: 'top',
      render: () => (
        <RToast
          variant={varaint as 'success' | 'error' | 'warning' | 'info'}
          title={text}
          onClose={() => toast.close(toastIdRef.current as ToastId)}
        />
      ),
    });
  };

  const handleCryptoPayment = async () => {
    setIsLoading(true);
    console.log(`Init Crypto payment with: ${selectedCrypto}`);
    console.log({
      ticketPrice,
      userBalance,
    });

    if (!wallet) {
      sendToast('error', 'Wallet not connected');
      return;
    }

    switch (selectedCrypto) {
      case 'matic':
        await handleMaticPayment();
        break;
      case 'vext':
        await handleVextPayment();
        break;
      case 'usdc':
        await handleUsdcPayment();
        break;
    }
    setIsLoading(false);
  };

  const handleMaticPayment = async () => {
    if (!wallet) {
      return;
    }
    if (userBalance?.matic! < ticketPrice?.maticPrice! * quantity) {
      sendToast('error', 'Insufficient funds');
      return;
    }
    try {
      const { buyWithMatic } = await import('utils/crossmint/buy-ticket');
      const payment = await buyWithMatic({
        aaWallet: wallet,
        price: cryptoPrice.price * quantity,
        bundleId,
        quantity,
      });
      console.log(payment);
      onSuccess({
        type: 'payment:process.succeeded',
        payload: { orderIdentifier: payment },
      });
    } catch (error) {
      console.log(error);
      sendToast('error', 'Payment failed');
    }
  };

  const handleVextPayment = async () => {
    if (!wallet) {
      return;
    }
    console.log('envs', process.env.NEXT_PUBLIC_TICKETS_CONTRACT_ADDRESS);

    if (userBalance?.vext! < (Number(signature?.getVextMessageForTickets?.tokenPriceVEXT || 0) / 1e8 ) * quantity) {
      sendToast('error', 'Insufficient balance');
      return;
    }


    // if (vextAllowance && vextAllowance < ticketPrice?.vextPrice!) {
    console.log('APPROVE VEXT CALLED');
    const { approveVext } = await import('utils/crossmint/vext');
    const approval = await approveVext({
      aaWallet: wallet,
      spenderAddress: process.env.NEXT_PUBLIC_TICKETS_CONTRACT_ADDRESS || '',
    });
    console.log('APPROVE VEXT RESULT: ', approval);
    // }

    try {
      const { buyWithVext } = await import('utils/crossmint/buy-ticket');
      const payment = await buyWithVext({
        aaWallet: wallet,
        bundleId,
        tokenPriceVEXT: signature?.getVextMessageForTickets?.tokenPriceVEXT || '',
        deadline,
        r: signature?.getVextMessageForTickets?.r || '',
        s: signature?.getVextMessageForTickets?.s || '',
        v: signature?.getVextMessageForTickets?.v || 0,
      });
      console.log(payment);
      onSuccess({
        type: 'payment:process.succeeded',
        payload: { orderIdentifier: payment },
      });
      sendToast('success', 'Payment successful');
    } catch (error) {
      console.log(error);
      sendToast('error', 'Payment failed');
    }
  };

  const handleUsdcPayment = async () => {
    if (!wallet) {
      return;
    }
    if (userBalance?.usdc! < ticketPrice?.usdcPrice! * quantity) {
      sendToast('error', 'Insufficient funds');
      return;
    }

    // if (usdcAllowance && usdcAllowance < ticketPrice?.usdcPrice!) {
    const { approveUsdc } = await import('utils/crossmint/usdc');
    console.log('APPROVE USDC CALLED');
    const approval = await approveUsdc({
      aaWallet: wallet,
      spenderAddress: process.env.NEXT_PUBLIC_TICKETS_CONTRACT_ADDRESS || '',
    });
    console.log('APPROVE RESULT: ', approval);
    // }

    try {
      const { buyWithUsdc } = await import('utils/crossmint/buy-ticket');
      const payment = await buyWithUsdc({
        aaWallet: wallet,
        bundleId,
        quantity,
      });
      console.log(payment);
      onSuccess({
        type: 'payment:process.succeeded',
        payload: { orderIdentifier: payment },
      });
      sendToast('success', 'Payment successful');
    } catch (error) {
      console.log(error);
      sendToast('error', 'Payment failed');
    }
  };

  const convertedPrice = (price: number) => {
    if (selectedCrypto === 'usdc') return price / 1e6;
    if (selectedCrypto === 'vext') return price / 1e26;

    return price / 1e18;
  };
  const convertedPriceBalance = (price: number) => {
    if (selectedCrypto === 'usdc') return price / 1e6;

    return price / 1e18;
  }
  function formatCryptoDecimals(number: number) {
    if (Number.isInteger(number)) {
      return number.toString();
    } else {
      return number.toFixed(5);
    }
  }

  return (
    <Flex h="100%" minH="300px" justifyContent="space-between" flexDir="column">
      <Stack w="full" gap={1} align="center">
        <HStack>
          <Heading
            fontWeight={'normal'}
            textTransform={'uppercase'}
            fontSize={'16px'}
            my={2}
          >
            Cost
          </Heading>

          <Menu variant="tertiary">
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<IconChevron style={{ transform: 'rotate(90deg)' }} />}
              variant="secondary"
              w="40px"
              h="30px"
            />
            <MenuList>
              <MenuItem
                icon={<IconMatic />}
                onClick={() => {
                  setSelectedCrypto('matic');
                  setCryptoPrice({
                    price: ticketPrice?.maticPrice || 0,
                    readablePrice: ticketPrice?.readableMaticPrice || 0,
                  });
                }}
              >
                Matic
              </MenuItem>

              <MenuItem
                icon={<IconVextBorder />}
                onClick={() => {
                  setSelectedCrypto('vext');
                  setCryptoPrice({
                    price: Number(signature?.getVextMessageForTickets?.tokenPriceVEXT) || 0,
                    readablePrice: Number(signature?.getVextMessageForTickets?.readablePriceVEXT) || 0,
                  });
                }}
              >
                Vext
              </MenuItem>

              <MenuItem
                icon={<IconDollarBalance />}
                onClick={() => {
                  setSelectedCrypto('usdc');
                  setCryptoPrice({
                    price: ticketPrice?.usdcPrice || 0,
                    readablePrice: ticketPrice?.readableUsdcPrice || 0,
                  });
                }}
              >
                Usdc
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>

        <PriceLabel
          value={formatCryptoDecimals(
            convertedPrice(cryptoPrice.price) * quantity
          )}
          currency={tokenList[selectedCrypto].currency}
          color={tokenList[selectedCrypto].color}
          numberSize={{ base: '2rem', md: '2.5rem' }}
          iconSize={{ base: '2.1rem', md: '2.6rem' }}
          icon={tokenList[selectedCrypto].icon}
          currencySize={{ base: '14px', md: '18px' }}
        />
        <PriceLabel
          value={(usdPrice * quantity).toFixed(2)}
          currency="USD"
          numberSize="1.2rem"
          iconSize="0.75rem"
          currencySize="12px"
        />
      </Stack>
      <BalanceComponent
        balance={convertedPriceBalance(userBalance?.[selectedCrypto] || 0)}
        color={tokenList[selectedCrypto].color}
        currency={tokenList[selectedCrypto].currency}
        icon={tokenList[selectedCrypto].icon}
      />

      <Text w="full" textAlign="center" my="0.5rem">
        By purchasing, you agree to our{' '}
        <Link color="fieryCoral.100" href={TERMS_URL}>
          Terms And Conditions
        </Link>
      </Text>
      <Button
        variant="tertiary"
        w="full"
        onClick={handleCryptoPayment}
        isLoading={isLoading}
        disabled={isLoading || !wallet}
      >
        Pay
      </Button>
    </Flex>
  );
};
