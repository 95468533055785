import {
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
} from '@chakra-ui/react';

import {
  IconChevron,
  IconDollar,
  IconDollarBalance,
  IconMatic,
  IconVextBorder,
} from 'icons';
import { PriceLabel } from './PriceLabel';
import { useFunds } from 'context/fundsContext';

export const BalanceComponent = () => {
  const { actualTokenSelected, changeSelectedToken, balanceData, isLoading } =
    useFunds();

  if (isLoading) {
    return (
      <Stack w="full" align="center" gap={0}>
        <Skeleton h="30px" w="100px" />
      </Stack>
    );
  }

  return (
    <Stack w="full" align="center" gap={0}>
      <Heading
        fontWeight={'normal'}
        textTransform={'uppercase'}
        fontSize={'14px'}
        my={2}
      >
        Balance
      </Heading>
      <Flex w="full" align="center" justify="center" gap={4}>
        <PriceLabel
          value={balanceData[actualTokenSelected].readableBalance.toString()}
          currency={balanceData[actualTokenSelected].currency}
          currencySize="20px"
          numberSize="32px"
          iconSize="40px"
          icon={balanceData[actualTokenSelected].icon}
          color={balanceData[actualTokenSelected].color}
        />

        <Menu variant="tertiary">
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<IconChevron style={{ transform: 'rotate(90deg)' }} />}
            variant="secondary"
            w="40px"
            h="30px"
          />
          <MenuList>
            <MenuItem
              icon={<IconMatic />}
              onClick={() => changeSelectedToken('matic')}
            >
              Matic
            </MenuItem>
            <MenuItem
              icon={<IconVextBorder />}
              onClick={() => changeSelectedToken('vext')}
            >
              Vext
            </MenuItem>
            <MenuItem
              icon={<IconDollarBalance />}
              onClick={() => changeSelectedToken('usdc')}
            >
              Usdc
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <PriceLabel
        value={balanceData[actualTokenSelected].usdValue.toFixed(2)}
        currency={'USD'}
        currencySize="9px"
        numberSize="14px"
        iconSize="8px"
        icon={IconDollar}
        color={'treasuredTeal.100'}
      />
    </Stack>
  );
};
