import { Box, Flex, Text, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import {
  IconClose,
  IconSuccessCircle,
  IconInfoCircle,
  IconErrorCircle,
} from 'icons';
export interface ToastProps {
  variant: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  description?: string;
  actionTitle?: string;
  action?: () => void;
  showClose?: boolean;
  onClose?: () => void;
}
const RToast = ({
  variant = 'success',
  title,
  description,
  actionTitle,
  action,
  showClose = true,
  onClose,
}: ToastProps) => {
  const getIcon = () => {
    switch (variant) {
      case 'success':
        return <IconSuccessCircle />;
      case 'error':
        return <IconErrorCircle />;
      case 'warning': //uses same icon as info
      case 'info':
        return <IconInfoCircle />;
      default:
        return <IconSuccessCircle />;
    }
  };

  const styles = useStyleConfig('Toast', { variant: variant });

  return (
    <Flex __css={styles} className="toast">
      <Flex className="leftBox">{getIcon()}</Flex>
      <Flex className="rightBox">
        <Box className="message">
          {title && <p>{title}</p>}
          {description && <p>{description}</p>}
        </Box>
        <Flex className="lastContainer">
          {actionTitle && (
            <Text className="action" onClick={action}>
              {actionTitle}
            </Text>
          )}
          {showClose && (
            <IconClose
              cursor="pointer"
              sx={{
                '& path': {
                  fill: 'white.80',
                },
              }}
              onClick={onClose}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RToast;
