import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPlus = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    viewBox="0 0 40 40"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M36.25 20c0 .703-.625 1.25-1.25 1.25H21.25V35c0 .703-.625 1.328-1.25 1.328-.703 0-1.25-.625-1.25-1.328V21.25H5c-.703 0-1.25-.547-1.25-1.172 0-.703.547-1.328 1.25-1.328h13.75V5c0-.625.547-1.172 1.25-1.172.625 0 1.25.547 1.25 1.172v13.75H35c.625 0 1.25.625 1.25 1.25Z"
    />
  </svg>
));
export default SvgIconPlus;
