import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPerformance = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M14.438 5.625a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H6.313c-.547 0-.938-.39-.938-.938 0-.507.39-.937.938-.937h8.125Zm-3.75 3.75a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H6.314c-.547 0-.938-.39-.938-.938 0-.507.39-.937.938-.937h4.375ZM17.835 0c1.406 0 2.5 1.133 2.5 2.5v11.172c0 1.328-1.133 2.46-2.5 2.46H12.21l-4.883 3.673c-.312.195-.742 0-.742-.39v-3.243h-3.75c-1.406 0-2.5-1.094-2.5-2.461V2.5c0-1.367 1.094-2.5 2.5-2.5h15Zm.664 13.75V2.5c0-.313-.313-.625-.625-.625h-15a.642.642 0 0 0-.625.625v11.25c0 .352.273.625.625.625H8.5v2.344l3.125-2.344h6.25a.642.642 0 0 0 .625-.625Z"
    />
  </svg>
));
export default SvgIconPerformance;
