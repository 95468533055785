import { useMutation } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

import { MutationRemoveSavedSetupArgs } from 'api/generated/graphql';

const useRemoveSavedSetups = (rest?: { [key: string]: unknown }) => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();

  const removeSavedSetupsFn = graphql(`
    mutation RemoveSavedSetups($SavedSetupsInput: SavedSetupsInput!) {
      removeSavedSetup(SavedSetupsInput: $SavedSetupsInput)
    }
  `);

  const removeSavedSetups = useMutation({
    mutationFn: async ({ SavedSetupsInput }: MutationRemoveSavedSetupArgs) => {
      return graphQLClient.request(removeSavedSetupsFn, {
        SavedSetupsInput,
      });
    },
    ...rest,
  });

  return removeSavedSetups;
};

export default useRemoveSavedSetups;
