import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMoneyBills = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={18}
    fill="none"
    viewBox="0 0 26 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M15.135 3.375c-1.758 0-3.125 1.68-3.125 3.75 0 2.11 1.367 3.75 3.125 3.75 1.719 0 3.125-1.64 3.125-3.75 0-2.07-1.367-3.75-3.125-3.75Zm10.664-.625v8.75c0 1.406-1.133 2.5-2.5 2.5H7.049a2.468 2.468 0 0 1-2.5-2.5V2.75c0-1.367 1.094-2.5 2.5-2.5h16.25c1.367 0 2.5 1.133 2.5 2.5Zm-1.875 6.914V4.625h-.313a2.184 2.184 0 0 1-2.187-2.188v-.312H8.884c0 .117.04.234.04.313 0 1.21-1.016 2.187-2.188 2.187h-.312v5.04c.078 0 .195-.04.312-.04 1.172 0 2.188 1.016 2.188 2.188 0 .117-.04.234-.04.312h12.54v-.313c0-1.171.976-2.187 2.187-2.187.078 0 .196.04.313.04Zm-3.125 8.086H5.486A4.652 4.652 0 0 1 .8 13.062V3.688c0-.508.39-.938.937-.938a.95.95 0 0 1 .938.938v9.374a2.8 2.8 0 0 0 2.812 2.813H20.8a.95.95 0 0 1 .937.938c0 .546-.43.937-.937.937Z"
    />
  </svg>
));
export default SvgIconMoneyBills;
