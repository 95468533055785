import { defineStyleConfig } from '@chakra-ui/react';

import { colors } from 'utils/themeConstants';

export const partCardTheme = defineStyleConfig({
  // style object for base or default style
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    bg: 'darkVoid.80',
    p: '1',
    h: '9.25rem',
    flexBasis: '12.5rem',
    _before: {
      p: '-4',
      ml: '-1',
      content: '""',
      position: 'absolute',
      h: 'full',
      w: 'full',
      bottom: '0',
      zIndex: '-2',
      bg: 'black.100',
    },
    '.border-bottom': {
      border: '2px solid',
      position: 'absolute',
      bottom: '4px',
      left: '4px',
      width: 'calc(100% - 8px)',
      opacity: '0.5',
      height: '4px',
      borderTop: 'transparent',
    },
    '.icon': {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
    },
    '.tier': {
      position: 'absolute',
      top: '2',
      left: '2',
    },
    h3: {
      fontSize: 'sm',
      color: colors.white[95],
      position: 'absolute',
      bottom: '3',
      left: '3',
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    default: {
      bg: `linear-gradient(180deg, ${colors.black[100]} 44.27%, ${colors.zincDust[100]} 72.92%, ${colors.jet[100]} 100%)`,
    },
    success: {
      bg: `linear-gradient(0deg, ${colors.treasuredTeal[20]} 0%, ${colors.black[100]} 100%)`,
      '.border-bottom': {
        borderColor: 'treasuredTeal.100',
      },
    },
    fail: {
      bg: `linear-gradient(0deg, ${colors.bloodMoon[20]} 0%, transparent 100%)`,
      '.border-bottom': {
        borderColor: 'fieryCoral.100',
      },
    },
    risk: {
      bg: `linear-gradient(0deg, ${colors.bloodMoon[20]} 0%, transparent 100%)`,
      '.border-bottom': {
        borderColor: 'bloodMoon.100',
      },
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    variant: 'default',
    colorScheme: '',
  },
});
