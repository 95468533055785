import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLive = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={16}
    fill="none"
    viewBox="0 0 26 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M21.477.813c3.671 3.984 3.671 10.43 0 14.414-.157.195-.43.273-.665.273a1.06 1.06 0 0 1-.664-.234.968.968 0 0 1-.039-1.329c3.008-3.242 3.008-8.593 0-11.835a.968.968 0 0 1 .04-1.329c.39-.351.976-.351 1.328.04Zm-4.415 3.046c.391-.312.977-.273 1.329.079 1.953 2.226 1.953 5.859 0 8.085a.946.946 0 0 1-.703.352c-.235 0-.47-.117-.625-.234a.933.933 0 0 1-.079-1.329c1.368-1.562 1.368-4.101 0-5.664-.351-.351-.312-.976.078-1.289ZM5.813.773c.352.352.391.938.04 1.329-3.008 3.242-3.008 8.593 0 11.836a.968.968 0 0 1-.04 1.328 1.06 1.06 0 0 1-.664.234c-.234 0-.507-.078-.664-.273C.813 11.242.813 4.797 4.484.812c.352-.39.938-.39 1.329-.039ZM8.899 3.86c.391.352.43.938.079 1.329-1.368 1.562-1.368 4.101 0 5.664a.933.933 0 0 1-.079 1.328.887.887 0 0 1-.625.234.946.946 0 0 1-.703-.351c-1.953-2.227-1.953-5.86 0-8.086a.974.974 0 0 1 1.328-.118ZM13 6.438c.86 0 1.563.78 1.563 1.601 0 .86-.704 1.524-1.563 1.524-.898 0-1.602-.743-1.602-1.563 0-.86.704-1.563 1.602-1.563Z"
    />
  </svg>
));
export default SvgIconLive;
