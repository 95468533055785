import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGlasses = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={15}
    fill="none"
    viewBox="0 0 19 15"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M18.29 8.281c.03.156.062.313.03.469v2.219c0 1.937-1.593 3.531-3.593 3.531H13.57c-1.875 0-3.437-1.438-3.593-3.281L9.883 10H8.789l-.094 1.219C8.54 13.063 6.977 14.5 5.102 14.5H3.945c-2 0-3.593-1.594-3.593-3.531V8.75c0-.156 0-.313.03-.469L1.82 2.594A2.714 2.714 0 0 1 3.164.844C3.82.5 4.633.406 5.352.656l.468.156a.477.477 0 0 1 .313.625l-.156.47c-.063.28-.375.405-.625.343l-.407-.156C4.602 2 4.227 2 3.915 2.156c-.345.156-.563.438-.657.813L2.07 7.78a8.415 8.415 0 0 1 2.532-.406 8.01 8.01 0 0 1 4.156 1.156h1.156a8.01 8.01 0 0 1 4.156-1.156c.97 0 1.844.188 2.532.406L15.414 2.97c-.094-.375-.312-.656-.656-.813C14.445 2 14.07 2 13.727 2.094l-.407.156c-.25.063-.562-.063-.625-.344l-.156-.468a.477.477 0 0 1 .313-.625l.468-.157a2.792 2.792 0 0 1 2.188.188c.687.375 1.156 1 1.344 1.75l1.437 5.687ZM7.194 11.094l.125-1.625a6.37 6.37 0 0 0-2.718-.594c-1.157 0-2.157.313-2.782.563v1.5c0 1.124.97 2.062 2.125 2.062h1.157c1.093 0 2-.813 2.093-1.906Zm9.657-.125V9.438c-.625-.25-1.625-.563-2.782-.563-.937 0-1.843.188-2.718.594l.125 1.625A2.081 2.081 0 0 0 13.57 13h1.157c1.156 0 2.125-.906 2.125-2.031Z"
    />
  </svg>
));
export default SvgIconGlasses;
