import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDollar = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={47}
    fill="none"
    viewBox="0 0 27 47"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#4FC5BA"
      d="M26.245 25.85v10.2L21.295 41h-5.35v5.5h-4.25V41h-5.9l-4.85-4.85v-3.8h4.75v2.25l2.15 2.2h3.85V25.2h-5.8l-4.85-4.9v-9.45L5.895 6h5.8V.5h4.25V6h5.05l4.85 4.85v3.85h-4.7v-2.3l-2.2-2.2h-3V21h5.45l4.85 4.85ZM11.695 21V10.2h-3.7l-2.2 2.2v6.4l2.2 2.2h3.7Zm9.8 6.35-2.2-2.15h-3.35v11.6h3.35l2.2-2.25v-7.2Z"
    />
  </svg>
));
export default SvgIconDollar;
