import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconEllipsesVertical = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M10.5 8c0 1.406-1.125 2.5-2.5 2.5A2.468 2.468 0 0 1 5.5 8c0-1.375 1.094-2.5 2.5-2.5 1.375 0 2.5 1.125 2.5 2.5ZM7 8c0 .563.438 1 1 1 .531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1Zm3.5 5.5c0 1.406-1.125 2.5-2.5 2.5a2.468 2.468 0 0 1-2.5-2.5C5.5 12.125 6.594 11 8 11c1.375 0 2.5 1.125 2.5 2.5Zm-3.5 0c0 .563.438 1 1 1 .531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1Zm3.5-11C10.5 3.906 9.375 5 8 5a2.468 2.468 0 0 1-2.5-2.5C5.5 1.125 6.594 0 8 0c1.375 0 2.5 1.125 2.5 2.5ZM7 2.5c0 .563.438 1 1 1 .531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1Z"
    />
  </svg>
));
export default SvgIconEllipsesVertical;
