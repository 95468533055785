import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconShapes = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M5 10c2.734 0 5 2.266 5 5 0 2.773-2.266 5-5 5-2.773 0-5-2.227-5-5 0-2.734 2.227-5 5-5Zm0 8.125A3.11 3.11 0 0 0 8.125 15 3.134 3.134 0 0 0 5 11.875 3.11 3.11 0 0 0 1.875 15c0 1.758 1.367 3.125 3.125 3.125ZM7.54 8.75c-1.017 0-1.642-1.016-1.134-1.875l3.711-6.25C10.352.273 10.781 0 11.25 0c.43 0 .86.273 1.094.625l3.672 6.25c.507.86-.118 1.875-1.094 1.875H7.539Zm3.71-6.328L8.594 6.875h5.273L11.25 2.422Zm7.5 8.203c.664 0 1.25.586 1.25 1.25v6.25c0 .703-.586 1.25-1.25 1.25H12.5c-.703 0-1.25-.547-1.25-1.25v-6.25c0-.664.547-1.25 1.25-1.25h6.25Zm-.625 6.875v-5h-5v5h5Z"
    />
  </svg>
));
export default SvgIconShapes;
