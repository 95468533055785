import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGift = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M17.5 5C18.867 5 20 6.133 20 7.5V10c0 .703-.586 1.25-1.25 1.25v6.25c0 1.406-1.133 2.5-2.5 2.5H3.75a2.468 2.468 0 0 1-2.5-2.5v-6.25C.547 11.25 0 10.703 0 10V7.5C0 6.133 1.094 5 2.5 5h.469c-.508-.938-.625-2.11.117-3.36C3.672.587 4.805 0 6.016.04c1.601 0 2.656.82 4.023 2.655C11.367.86 12.383.04 13.984 0c1.25 0 2.383.586 2.97 1.64.741 1.25.624 2.422.116 3.36h.43Zm-3.438-3.125c-.82 0-1.367.156-3.398 3.125h3.399c.82 0 1.562-.703 1.562-1.563 0-.859-.742-1.562-1.563-1.562Zm-8.085 0c-.86 0-1.563.703-1.563 1.563C4.414 4.296 5.117 5 5.977 5h3.398C7.344 2.031 6.758 1.875 5.977 1.875Zm2.773 16.25V11.25H3.125v6.25c0 .352.273.625.625.625h5Zm8.125-.625v-6.25H11.25v6.875h5a.642.642 0 0 0 .625-.625Zm1.25-8.125V7.5c0-.313-.313-.625-.625-.625h-15a.642.642 0 0 0-.625.625v1.875h16.25Z"
    />
  </svg>
));
export default SvgIconGift;
