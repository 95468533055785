import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTag = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    viewBox="0 0 19 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M17.39 7.281a2.9 2.9 0 0 1 0 4.102l-5.546 5.547c-.547.547-1.29.82-2.031.82a3.026 3.026 0 0 1-2.07-.82L1.57 10.758C1.102 10.25.71 9.39.71 8.688V2.202C.71 1.11 1.57.25 2.626.25h6.523c.704 0 1.563.39 2.07.86l6.173 6.171Zm-1.328 2.774c.391-.39.391-1.055 0-1.446L9.892 2.438c-.157-.118-.547-.274-.743-.274H2.625l-.04.04v6.483c0 .196.196.586.313.743l6.172 6.172c.39.39 1.055.39 1.446 0l5.546-5.547ZM6.024 4.625a.925.925 0 0 0-.937-.938.95.95 0 0 0-.938.938c0 .547.391.938.938.938.508 0 .937-.391.937-.938Z"
    />
  </svg>
));
export default SvgIconTag;
