import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTrashCan = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M14.25 2.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H14l-.688 10.594c-.03.812-.687 1.406-1.5 1.406H4.157c-.812 0-1.468-.594-1.5-1.406L2 4h-.281C1.312 4 1 3.625 1 3.25c0-.406.313-.75.719-.75h2.343L5.125.75C5.406.281 5.875 0 6.406 0h3.157c.53 0 1 .281 1.28.75l1.063 1.75h2.344Zm-7.844-1-.593 1h4.343l-.594-1H6.406Zm5.407 13L12.469 4H3.5l.656 10.5h7.657Z"
    />
  </svg>
));
export default SvgIconTrashCan;
