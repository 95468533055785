import {
  Text,
  Heading,
  Box,
  Flex,
  Menu,
  Button,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { Dispatch, SetStateAction, useState } from 'react';
import { UseMutateFunction } from '@tanstack/react-query';

import { RTyreList, RToast } from 'components';
import { Tyres } from 'types';
import {
  SetCarPartMutation,
  MutationSetCarPartsArgs,
  Races,
  SavedSetups,
} from 'api/generated/graphql';
import EngineAndTuning, { EngineAndTuningProps } from './EngineAndTuning';
import {
  useGetRaceById,
  useGetSavedSetups,
  useTuningForRace,
  useUserSettings,
} from 'hooks';
import {
  IconCheckGreen,
  IconChevron,
  IconEmptySet,
  IconPlusCircle,
  IconTrashCan,
} from 'icons';
import SavedSetupCarModal from 'components/SavedSetups/SavedSetupCarModal';
import SavedSetupDeleteModal from 'components/SavedSetups/SavedSetupDeleteModal';

const SelectTyres = ({
  setSelectedTyres,
  selectedTyres,
  carId,
  mutateCarPart,
  ...engineAndTuningProps
}: EngineAndTuningProps & {
  setSelectedTyres: Dispatch<SetStateAction<Tyres>>;
  selectedTyres: Tyres;
  carId: string;
  mutateCarPart: UseMutateFunction<
    SetCarPartMutation,
    unknown,
    MutationSetCarPartsArgs,
    unknown
  >;
}) => {
  const { getUserSettings } = useUserSettings();
  const toast = useToast();
  const [selectedSavedSetup, setSelectedSavedSetup] =
    useState<SavedSetups | null>(null);
  const {
    isOpen: isOpenSavedSetupModal,
    onOpen: onOpenSavedSetupModal,
    onClose: onCloseSavedSetupModal,
  } = useDisclosure();

  const {
    isOpen: isOpenSavedSetupDeleteModal,
    onOpen: onOpenSavedSetupDeleteModal,
    onClose: onCloseSavedSetupDeleteModal,
  } = useDisclosure();

  const { data: raceByIdData } = useGetRaceById({
    raceId: engineAndTuningProps.tuningSelections.raceId,
  });
  const race = raceByIdData?.getRaceById?.race as Races;
  const userRaceData = race?.playersParticipants?.find((player) => {
    const isPlayerMatch = player.user.id === getUserSettings.data?.getUser.id;
    const isCarMatch = !carId || player.car.id === carId;

    return isPlayerMatch && isCarMatch;
  });

  const { data: savedSetupsData, isLoading: isLoadingSavedSetupsData } =
    useGetSavedSetups({ carId });
  const { tuningForRace } = useTuningForRace();
  const { mutateAsync: mutateTuning } = tuningForRace;

  const filteredSavedSetups = savedSetupsData?.getSavedSetups.filter(
    (setup) => {
      const conditions = [
        setup.frontWingTuning !== null,
        setup.rearWingTuning !== null,
        setup.frontWingId === engineAndTuningProps?.parts?.frontWing?.id,
        setup.rearWingId === engineAndTuningProps?.parts?.rearWing?.id,
      ];

      if (engineAndTuningProps?.parts?.brakeCooling?.id) {
        conditions.push(
          setup.brakeCoolingId === engineAndTuningProps?.parts?.brakeCooling.id
        );
      }

      if (engineAndTuningProps?.parts?.engineCooling?.id) {
        conditions.push(
          setup.engineCoolingId ===
            engineAndTuningProps?.parts?.engineCooling.id
        );
      }

      if (engineAndTuningProps?.parts?.transmissionRatio?.id) {
        conditions.push(
          setup.transmissionId ===
            engineAndTuningProps?.parts?.transmissionRatio.id
        );
      }

      return conditions.every((condition) => condition);
    }
  );

  const handleApplySavedSetup = async (savedSetup: SavedSetups) => {
    const setupParts = {
      frontWingTuning: savedSetup?.frontWingTuning,
      rearWingTuning: savedSetup?.rearWingTuning,
      brakeCoolingTuning: savedSetup?.brakeCoolingTuning,
      engineCoolingTuning: savedSetup?.engineCoolingTuning,
      transmissionTuning: savedSetup?.transmissionTuning,
      tyresId: savedSetup?.tyresId,
    };

    const filteredSetupParts = Object.fromEntries(
      Object.entries(setupParts).filter(([_, value]) => value !== undefined)
    );

    try {
      await mutateTuning({
        raceTuningInput: {
          ...filteredSetupParts,
          raceId: engineAndTuningProps.tuningSelections.raceId,
          carId: engineAndTuningProps.tuningSelections.carId,
        },
      });
      toast({
        position: 'bottom-right',
        render: () => (
          <RToast variant="success" title={`Saved Setup applied`} />
        ),
      });
    } catch (error) {
      console.error('Error applying saved setup:', error);
      toast({
        position: 'bottom-right',
        render: () => (
          <RToast variant="error" title={`Error applying saved setup`} />
        ),
      });
    }
  };

  const handleSelectSavedSetup = async (savedSetup: SavedSetups) => {
    setSelectedSavedSetup(savedSetup);
    await handleApplySavedSetup(savedSetup);
  };

  return (
    <Box height="fit-content" pb="20">
      <Flex justify="space-between" align="center" mb="6">
        <Heading
          as="h1"
          fontSize="1.125rem"
          fontWeight={400}
          textTransform="uppercase"
          color="white.80"
        >
          Tyres & Tuning
        </Heading>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<IconChevron __css={{ transform: 'rotate(90deg)' }} />}
            variant="secondary"
            textTransform="none"
            h="1.75rem"
            fontSize="0.875rem"
          >
            {selectedSavedSetup ? selectedSavedSetup.name : 'Saved Setups'}
          </MenuButton>
          <MenuList
            w={{ base: '20rem', md: 'full' }}
            bg="darkVoid.100"
            p={{ base: '0.25rem', md: '4' }}
            zIndex={10}
          >
            <MenuItem bg="darkVoid.100">
              <Flex flexDir="column" gap="2">
                <Flex
                  align="center"
                  justify="space-between"
                  color="white.80"
                  w={{ base: '18rem', md: 'full' }}
                  minW={{ base: 'auto', md: '35rem' }}
                  onClick={onOpenSavedSetupModal}
                >
                  <Flex align="center" gap={{ base: '0.25rem', md: '4' }}>
                    <IconPlusCircle
                      __css={{
                        path: { fill: 'white.80' },
                      }}
                    />
                    <Text fontFamily="heading">New Saved Setup</Text>
                  </Flex>
                  <IconChevron />
                </Flex>
                <Flex mt="1rem" w="full" maxW="30rem" margin="auto">
                  <Text fontSize="14px" color="white.60" fontFamily="body">
                    New Saved Setups only apply to the currently selected car
                    and parts within Parts Setup
                  </Text>
                </Flex>
              </Flex>
            </MenuItem>
            <MenuDivider />
            {isLoadingSavedSetupsData ? (
              <MenuItem bg="darkVoid.100">
                <Flex align="center" justify="center" w="full">
                  <Spinner color="white.80" size="md" />
                </Flex>
              </MenuItem>
            ) : filteredSavedSetups?.length === 0 ? (
              <MenuItem bg="darkVoid.100">
                <Flex align="center" gap="4" color="white.80">
                  <IconEmptySet
                    width="20px"
                    height="20px"
                    __css={{
                      path: { fill: 'white.80' },
                    }}
                  />
                  <Text fontFamily="heading">No Saved Setups</Text>
                </Flex>
              </MenuItem>
            ) : (
              filteredSavedSetups?.map((savedSetup, index) => (
                <MenuItem key={index} bg="darkVoid.100">
                  <Flex align="center" justify="space-between">
                    <Flex
                      align="center"
                      gap="2"
                      color="white.80"
                      w={{ base: '20rem', md: 'full' }}
                      minW={{ base: 'auto', md: '34rem' }}
                      onClick={() =>
                        handleSelectSavedSetup(savedSetup as SavedSetups)
                      }
                    >
                      <Flex
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedSavedSetup(savedSetup as SavedSetups);
                          onOpenSavedSetupDeleteModal();
                        }}
                      >
                        <IconTrashCan
                          __css={{
                            path: { fill: 'white.80' },
                          }}
                        />
                      </Flex>
                      <Text fontFamily="heading">{savedSetup.name}</Text>
                    </Flex>
                    {selectedSavedSetup?.id === savedSetup.id && (
                      <IconCheckGreen />
                    )}
                  </Flex>
                </MenuItem>
              ))
            )}
          </MenuList>
        </Menu>
      </Flex>

      <RTyreList
        raceId={engineAndTuningProps.tuningSelections.raceId}
        setSelectedTyres={setSelectedTyres}
        selectedTyres={(userRaceData?.tyres?.id || 'C2') as Tyres}
        carId={engineAndTuningProps.tuningSelections.carId}
        mutateCarPart={mutateCarPart}
      />

      <EngineAndTuning
        selectedTyres={selectedTyres}
        carId={carId}
        {...engineAndTuningProps}
      />

      <SavedSetupCarModal
        isOpen={isOpenSavedSetupModal}
        onClose={onCloseSavedSetupModal}
        tuningSelections={engineAndTuningProps.tuningSelections}
        carId={carId}
        userRaceData={userRaceData}
      />
      <SavedSetupDeleteModal
        isOpen={isOpenSavedSetupDeleteModal}
        onClose={onCloseSavedSetupDeleteModal}
        savedSetupId={selectedSavedSetup?.id as string}
        carId={carId}
        savedSetupName={selectedSavedSetup?.name as string}
        setSelectedSavedSetup={setSelectedSavedSetup}
      />
    </Box>
  );
};

export default SelectTyres;
