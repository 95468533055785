import { Flex } from "@chakra-ui/react"
import { DesktopMenu } from "./DesktopMenu"
import { MobileMenu } from "./MobileMenu"

const LoginHeader = () => {
  return (
    <Flex
      position="fixed"
      w="full"
      h="3.5rem"
      bgColor="black.60"
      justifyContent="center"
      textTransform="uppercase"
      color="white.95"
      fontSize="0.875rem"
      zIndex={5}
    >
      <Flex
        h="full"
        w="full"
        maxW={["60rem", "75rem"]}
        justifyContent="space-between"
        alignItems="center"
        px="1rem"
      >
        <DesktopMenu />
        <MobileMenu />
      </Flex>
    </Flex>
  )
}

export default LoginHeader