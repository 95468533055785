import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTooltipArrow = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={8}
    fill="none"
    viewBox="0 0 17 8"
    aria-hidden="true"
    {...props}
  >
    <path fill="#4FC5BA" d="m8.5 0-8 8h16l-8-8Z" />
  </svg>
));
export default SvgIconTooltipArrow;
