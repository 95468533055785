import { Box, Flex } from "@chakra-ui/react"
import RImage from "components/Image/Image"
import { IconMenuHamburger } from "icons"
import { useState } from "react"

export const MobileMenu = () => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)
  return (
    <>
      <Flex
        display={["flex", "flex", "none", "none"]}
        px="1rem"
        w="full"
        alignItems="center"
        justifyContent="space-between"
      > 
        <RImage 
          src={require("public/images/Logo.png")}
          alt="racino logo"
          width="60%"
        />
        {/* <Box
          cursor="pointer"
          onClick={() => setIsMenuOpened((oldState) => !oldState)}
        >
          <IconMenuHamburger />
        </Box> */}
      </Flex>
    </>
  )
}