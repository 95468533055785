import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGarage = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={17}
    fill="none"
    viewBox="0 0 21 17"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M16.75 7H5.25C4.531 7 4 7.563 4 8.25v8c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75V8.5h11v7.75c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75v-8C18 7.562 17.437 7 16.75 7Zm-2.313 5-.5-1.531A1.784 1.784 0 0 0 12.25 9.25H9.719c-.781 0-1.438.5-1.688 1.219L7.531 12c-.625.313-1.031.969-1.031 1.719v.5c0 .437.188.844.5 1.125v.906c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75v-.5h5v.5c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75v-.906c.281-.281.5-.688.5-1.125v-.5A1.93 1.93 0 0 0 14.437 12ZM9.47 10.937a.272.272 0 0 1 .25-.187h2.531c.125 0 .219.094.25.188l.281.812H9.187l.282-.813ZM14 14.22c0 .031-.031.031-.063.031L8 14.219v-.5c0-.25.188-.469.438-.469h5.093a.48.48 0 0 1 .469.469v.5Zm5.656-9.75-7.843-3.282a2.172 2.172 0 0 0-1.657 0L2.312 4.47A2.03 2.03 0 0 0 1 6.437v9.813c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75V6.437c0-.25.125-.5.375-.593l7.875-3.282a.61.61 0 0 1 .469 0l7.875 3.282c.25.093.406.312.406.593v9.813c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75V6.437c0-.875-.531-1.656-1.344-1.968Z"
    />
  </svg>
));
export default SvgIconGarage;
