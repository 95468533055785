import { useQuery } from '@tanstack/react-query';
export interface ConvertionResponse {
  timestamp: string;
  open: string;
  high: string;
  low: string;
  last: string;
  volume: string;
  vwap: string;
  bid: string;
  ask: string;
  side: string;
  open_24: string;
  percent_change_24: string;
}

export const useCryptoConvervtion = (token: string) => {
  const fetchConvertionDataFN = async () => {
    const response = await fetch(
      `https://www.bitstamp.net/api/v2/ticker/${token}usd/`
    );
    return await response.json();
  };

  return useQuery({
    enabled: !!token,
    queryKey: ['crypto-convertion', token],
    queryFn: fetchConvertionDataFN,
  });
};
