import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useWalletBalance = () => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();
  const getBalanceFn = graphql(`
    query GetBalances {
      getUserBalance {
        usdc
        vext
        matic
        readableUsdc
        readableVext
        readableMatic
      }
    }
  `);

  return useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['useWalletBalance'],
    queryFn: async () => {
      return graphQLClient.request(getBalanceFn);
    },
  });
};

export default useWalletBalance;
