import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPriceTag = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M14.313 6.625a2.32 2.32 0 0 1 0 3.281l-4.438 4.438A2.293 2.293 0 0 1 8.25 15a2.421 2.421 0 0 1-1.656-.656L1.656 9.406C1.281 9 .97 8.312.97 7.75V2.562C.969 1.688 1.656 1 2.5 1h5.219c.562 0 1.25.313 1.656.688l4.938 4.937ZM13.25 8.844a.83.83 0 0 0 0-1.156L8.312 2.75c-.124-.094-.437-.219-.593-.219H2.5l-.031.031V7.75c0 .156.156.469.25.594l4.937 4.937a.83.83 0 0 0 1.157 0l4.437-4.437ZM5.219 4.5a.74.74 0 0 0-.75-.75.76.76 0 0 0-.75.75c0 .438.312.75.75.75a.74.74 0 0 0 .75-.75Z"
    />
  </svg>
));
export default SvgIconPriceTag;
