import { useMutation } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';
import { MutationSetCarPartsArgs } from 'api/generated/graphql';

const useSetCarPart = (rest?: { [key: string]: unknown }) => {
  const { graphql, graphQLClient } = useGraphQLClient();

  const setCarPartGQL = graphql(`
    mutation SetCarPart($carId: String!, $carParts: SetCarPartsInput!) {
      setCarParts(carId: $carId, carParts: $carParts) {
        id
        name
      }
    }
  `);

  const setCarPart = useMutation({
    mutationKey: ['setCarPart'],
    mutationFn: ({ carId, carParts }: MutationSetCarPartsArgs) => {
      return graphQLClient.request(setCarPartGQL, {
        carId,
        carParts,
      });
    },
    ...rest,
  });

  return setCarPart;
};

export default useSetCarPart;
