import { Box, Button, Flex, Show, Text, UseDisclosureProps } from '@chakra-ui/react';
import { RDrawer, RSelect } from 'components';
import { SelectOptionType } from 'components/RSelect/RSelect';

export type FilterType = {
  label: string;
  values: SelectOptionType[];
  selectedValue?: string | any;
  setSelectedValue: (value?: string | any) => void;
};

interface FiltersDrawerProps extends UseDisclosureProps {
  filters: FilterType[];
  handleClear?: () => void;
  handleApply?: () => void;
}

const FiltersDrawer = ({
  filters,
  isOpen = false,
  onClose = () => null,
  handleClear,
  handleApply,
}: FiltersDrawerProps) => {
  return (
    <RDrawer
      heading={
        <Text color="white.60" textTransform="capitalize">
          Filters
        </Text>
      }
      drawerBody={
        <Flex flexDir="column" py="6" overflowY="auto" w="full">
          {filters.map((filter, index) => (
            <Flex flexDir="column" w="full" mb="1rem" key={index}>
              <Text mb="0.5rem">{filter.label}</Text>
              <RSelect
                w="full"
                placeholder="Select"
                options={filter.values}
                selectedOption={filter.selectedValue}
                setSelectedOption={filter.setSelectedValue}
              />
            </Flex>
          ))}
        </Flex>
      }
      drawerFooter={
        <Flex justifyContent="space-between" w="full">
          <Button
            variant="secondary"
            w="6.5rem"
            mr="0.75rem"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button w="full" onClick={handleApply}>
            Apply
          </Button>
          <Show below="md">
            <Box h="5rem" />
          </Show>
        </Flex>
      }
      drawerFooterProps={{
        background: "linear-gradient(180deg, #1E1E1E 0%, #121212 100%)",
      }}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default FiltersDrawer;
