import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { useUserSettings } from 'hooks';
import { TokenPossibilities } from 'context/fundsContext';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  token: TokenPossibilities;
  fiatAmount: number;
}

export type Token = 'MATIC' | 'VEXT' | 'USDC';

export const transakUrl = ({
  token,
  walletAddress,
  email,
  fiatAmount,
}: {
  walletAddress: string;
  email: string;
  token: Token;
  fiatAmount?: number;
}) => {
  const url = new URL(
    process.env.NEXT_PUBLIC_ENV === 'prod'
      ? 'https://global.transak.com/'
      : 'https://global-stg.transak.com/'
  );

  url.searchParams.set('apiKey', process.env.NEXT_PUBLIC_TRANSAK_API_KEY || '');
  url.searchParams.set('productsAvailed', 'buy');
  url.searchParams.set('cryptoCurrencyList', 'VEXT,MATIC,USDC');
  url.searchParams.set('cryptoCurrencyCode', token);
  url.searchParams.set('network', 'Polygon');
  url.searchParams.set('exchangeScreenTitle', `Buy ${token}`);
  url.searchParams.set('fiatCurrency', 'USD');
  url.searchParams.set('defaultFiatCurrency', 'USD');
  url.searchParams.set('fiatAmount', fiatAmount?.toString() || '1');
  url.searchParams.set(
    'environment',
    process.env.NEXT_PUBLIC_ENV === 'prod' ? 'PRODUCTION' : 'STAGING'
  );
  url.searchParams.set('themeColor', '26a69a'); // treasuredTeal
  url.searchParams.set('walletAddress', walletAddress);
  url.searchParams.set('email', email);
  url.searchParams.set('hideMenu', 'true');

  return url.toString();
};

export const WalletModal = ({ isOpen, onClose, token, fiatAmount }: Props) => {
  const { getUserSettings } = useUserSettings();
  const { data: user } = getUserSettings;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      variant="feedbackMOdal"
    >
      <ModalOverlay />
      <ModalContent>
        <iframe
          id="transak-iframe"
          src={transakUrl({
            token: token.toUpperCase() as Token,
            email: user?.getUser.email || '',
            walletAddress: user?.getUser.walletAddress || '',
            fiatAmount: fiatAmount,
          })}
          allow="camera;microphone;fullscreen;payment"
          style={{
            width: '100%',
            height: '80vh',
          }}
        ></iframe>
      </ModalContent>
    </Modal>
  );
};
