import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSuspensionStifness = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <g stroke="#fff" strokeOpacity={0.95} opacity={0.6}>
      <path d="M13.1 12c0 .162-.075.359-.296.583-.223.226-.572.453-1.041.655-.938.401-2.268.662-3.763.662s-2.825-.26-3.763-.662c-.47-.202-.818-.429-1.041-.655-.221-.224-.296-.42-.296-.583 0-.162.075-.359.296-.583.223-.226.571-.453 1.041-.654C5.175 10.36 6.505 10.1 8 10.1s2.825.26 3.763.663c.47.2.818.428 1.041.654.221.224.296.42.296.583Z" />
      <path d="M13.1 9.6c0 .162-.075.359-.296.583-.223.226-.572.453-1.041.655-.938.401-2.268.662-3.763.662s-2.825-.26-3.763-.662c-.47-.202-.818-.429-1.041-.655-.221-.224-.296-.42-.296-.583 0-.162.075-.359.296-.583.223-.226.571-.453 1.041-.654C5.175 7.96 6.505 7.7 8 7.7s2.825.26 3.763.663c.47.2.818.428 1.041.654.221.224.296.42.296.583Z" />
      <path d="M13.1 7.2c0 .162-.075.359-.296.583-.223.226-.572.453-1.041.655-.938.401-2.268.662-3.763.662s-2.825-.26-3.763-.662c-.47-.202-.818-.429-1.041-.655-.221-.224-.296-.42-.296-.583 0-.162.075-.359.296-.583.223-.226.571-.453 1.041-.654C5.175 5.56 6.505 5.3 8 5.3s2.825.26 3.763.663c.47.2.818.428 1.041.654.221.224.296.42.296.583Z" />
      <path d="M13.1 4.8c0 .162-.075.359-.296.583-.223.226-.572.453-1.041.655-.938.401-2.268.662-3.763.662s-2.825-.26-3.763-.662c-.47-.202-.818-.429-1.041-.655-.221-.224-.296-.42-.296-.583 0-.162.075-.359.296-.583.223-.226.571-.453 1.041-.654C5.175 3.16 6.505 2.9 8 2.9s2.825.26 3.763.663c.47.2.818.428 1.041.654.221.224.296.42.296.583Z" />
    </g>
  </svg>
));
export default SvgIconSuspensionStifness;
