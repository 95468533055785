import { useRouter } from 'next/router';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';

import { IconArrow } from 'icons';

const SalvageHeader = () => {
  const router = useRouter();

  return (
    <Box w="100%" pb="6">
      <Flex
        justify="space-between"
        borderBottom="1px solid"
        borderColor="zincDust.100"
        alignItems="center"
      >
        <Flex alignItems="center" gap="4" p="1rem">
          <Button variant="unstyled" onClick={() => router.back()}>
            <IconArrow />
          </Button>

          <Flex gap="2" h="100%" alignItems="flex-start" flexDir="column">
            <Heading
              fontSize={["2xl", "3xl"]}
              lineHeight="6"
              textTransform="uppercase"
              fontWeight="normal"
            >
              Salvage Cars
            </Heading>
            <Text>
              Select two cars that have both exceeded their Race Limit by the end of a season that you want to
              salvage. Salvage cars to create rare vehicles.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SalvageHeader;
