import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconEyeHidden = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={17}
    fill="none"
    viewBox="0 0 21 17"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="m20.188 14.688-3.22-2.563a11.545 11.545 0 0 0 2.407-3.563c.063-.124.094-.374.094-.53 0-.157-.032-.376-.094-.532-1.688-3.844-5.063-6.438-8.875-6.438-1.969 0-3.813.688-5.344 1.875L1.688.188A.617.617 0 0 0 1.25 0a.711.711 0 0 0-.594.313c-.281.312-.219.78.125 1.03l18.469 14.47c.313.28.781.218 1.031-.126.313-.28.25-.75-.093-1Zm-7.375-5.813L10.25 6.906c.125-.281.219-.562.219-.906 0-.156-.031-.313-.094-.469.063 0 .094-.031.125-.031 1.375 0 2.5 1.125 2.5 2.531 0 .281-.094.594-.188.844Zm1.218.969c.281-.563.469-1.156.469-1.813C14.5 5.812 12.687 4 10.5 4c-1.031 0-1.938.406-2.625 1l-1.5-1.156C7.625 3 9.031 2.5 10.5 2.5c3.094 0 5.969 2.125 7.469 5.5-.563 1.25-1.313 2.313-2.219 3.156l-1.719-1.312ZM10.5 12c.406 0 .813-.063 1.188-.188l-1.75-1.374c-.72-.157-1.344-.657-1.688-1.313L6.5 7.75V8c0 2.219 1.781 4 4 4Zm0 1.5c-3.125 0-6-2.094-7.5-5.469.344-.75.75-1.437 1.219-2.031L3.03 5.031A13.512 13.512 0 0 0 1.594 7.47 1.643 1.643 0 0 0 1.469 8c0 .156.031.406.093.563C3.25 12.405 6.625 15 10.47 15c1.406 0 2.75-.375 4-1.031l-1.344-1.031a6.291 6.291 0 0 1-2.625.562Z"
    />
  </svg>
));
export default SvgIconEyeHidden;
