import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRearWing = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      d="m5.419 4-2.744.171A2 2 0 0 0 .8 6.167v2.265a2 2 0 0 0 2.792 1.836l.618-.267A2 2 0 0 0 5.42 8.165v-.473m0-3.692 9.781.288M5.419 4l-.509.687a.866.866 0 0 0 .509 1.361m9.781-1.76-2.22.486a2 2 0 0 0-1.569 1.83l-.016.252M15.2 4.288l-.945 7.346c-.04.308-.168.597-.371.831-.988 1.142-2.862.377-2.768-1.13l.146-2.345M5.42 7.692c-.869-.205-.87-1.445 0-1.644m0 1.644 5.843 1.298M5.42 6.048l5.976.808m-.133 2.134.133-2.134"
      opacity={0.6}
    />
  </svg>
));
export default SvgIconRearWing;
