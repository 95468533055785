import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPencil = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#F2F2F2"
      d="m15.219.594 1.187 1.187a1.98 1.98 0 0 1 0 2.813L6.375 14.625c-.281.281-.594.469-.969.531-1.187.281-3.812.844-3.812.844h-.125A.498.498 0 0 1 1 15.406l.844-3.812c.093-.375.281-.719.562-1l10-10A2.044 2.044 0 0 1 13.812 0c.5 0 1.032.219 1.407.594ZM5.313 13.563 12.905 6 11 4.094l-7.531 7.562c-.094.063-.125.156-.156.25l-.5 2.313c.78-.188 1.687-.375 2.25-.5.093-.031.187-.063.25-.156Z"
    />
  </svg>
));
export default SvgIconPencil;
