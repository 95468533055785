import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHead = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="M5.931 11.5c0 5.6 3.334 7.333 5 7.5 4 0 5-5 5-7.5m-10 0V9c1.334 0 4.2-.6 5-3 .167 1-.1 3.3-2.5 4.5-1 .5 4.5.5 6-4.5.667 1.167 1.9 3.9 1.5 5.5m-10 0c-3.2-7.2.334-9.333 2.5-9.5.4-.8 2.167-1 3-1 6.8 0 5.834 7 4.5 10.5"
    />
  </svg>
));
export default SvgIconHead;
