import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBlock = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    viewBox="0 0 40 40"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M20 0c11.016 0 20 8.984 20 20 0 11.094-8.984 20-20 20C8.906 40 0 31.094 0 20 0 8.984 8.906 0 20 0ZM3.75 20c0 8.984 7.266 16.25 16.25 16.25 3.75 0 7.266-1.328 10.078-3.438L7.266 10C5 12.734 3.75 16.25 3.75 20Zm28.906 10.078c2.266-2.734 3.594-6.25 3.594-10.078 0-8.906-7.344-16.25-16.25-16.25-3.828 0-7.344 1.328-10.078 3.594l22.734 22.734Z"
    />
  </svg>
));
export default SvgIconBlock;
