import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetWalletAddress = () => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const getWalletAddressGql = graphql(`
    query GetWalletAddress {
      getWalletAddress {
        email
        walletAddress
      }
    }
  `);

  const getWalletAddress = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['walletAddressQuery'],
    queryFn: async () => {
      return graphQLClient.request(getWalletAddressGql);
    },
  });

  return getWalletAddress;
};

export default useGetWalletAddress;
