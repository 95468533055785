/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Heading, Skeleton, Stack } from '@chakra-ui/react';
import { ActivityItem } from 'components/UserActivity/ActivityItem';
import { BalanceComponent } from 'components/Balance/BalanceComponent';
import { WalletPopover } from './subcomponents/WalletPopover';
import useUserActivity from 'hooks/useUserActivity';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

export const ActivityTab = () => {
  const { data: activities, isLoading, isRefetching } = useUserActivity({});

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(['userActivity']);
  }, []);

  return (
    <Flex flexDir={'column'} gap={8} pt={4}>
      <WalletPopover />

      <BalanceComponent />

      <Stack w="full" gap={2}>
        <Heading
          fontWeight={'normal'}
          textTransform={'uppercase'}
          fontSize={'14px'}
          my={2}
        >
          Activity
        </Heading>

        {(isLoading || isRefetching) && <Skeleton w="full" h="64px" />}

        {!isLoading &&
          !isRefetching &&
          activities?.getUserActivity.map((item, index) => (
            <ActivityItem
              key={index}
              message={item.message || ''}
              timestamp={item.timestamp}
              value={item.value}
              action={item.action}
              currency={item.currency || ''}
            />
          ))}
      </Stack>
    </Flex>
  );
};
