import NextLink from 'next/link';
import { Collapse, Link } from '@chakra-ui/react';

import { RAlert } from 'components';
import routes from 'routes';

const GarageEmptyAlert = ({ show }: { show?: boolean }) => {
  return (
    <Collapse in={show}>
      {/* <RAlert
        mb="6"
        variant="info"
        description={
          <>
            Your garage is empty!{' '}
            <Link
              as={NextLink}
              href={routes.buyCar()}
              color="iceColdStare.60"
              variant="unstyled"
              borderBottom="solid 1px"
              borderColor="transparent"
              _hover={{
                borderColor: 'iceColdStare.20',
              }}
            >
              Let&apos;s go fix that.
            </Link>
          </>
        }
      /> */}
    </Collapse>
  );
};

export default GarageEmptyAlert;
