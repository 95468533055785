import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconEmptySet = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={31}
    fill="none"
    viewBox="0 0 32 31"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M30.688 1.313c.375.375.375 1.062 0 1.437l-4.875 4.813C27.813 9.874 29 12.813 29 16c0 7.188-5.875 13-13 13-3.25 0-6.188-1.188-8.5-3.125L2.687 30.75C2.5 30.938 2.25 31 2 31c-.313 0-.563-.063-.75-.25-.375-.375-.375-1.063 0-1.438L6.125 24.5C4.125 22.187 3 19.25 3 16 3 8.875 8.813 3 16 3c3.188 0 6.125 1.188 8.438 3.188l4.812-4.875c.375-.375 1.063-.375 1.438 0ZM5 16c0 2.688.938 5.125 2.5 7.063L23 7.563C21.125 6 18.625 5 16 5 9.875 5 5 9.938 5 16Zm22 0c0-2.625-1-5.125-2.563-7l-15.5 15.5C10.813 26.063 13.314 27 16 27c6.063 0 11-4.875 11-11Z"
    />
  </svg>
));
export default SvgIconEmptySet;
