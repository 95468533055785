import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTicketCardBase = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={372}
    height={513}
    fill="none"
    viewBox="0 0 372 513"
    aria-hidden="true"
    {...props}
  >
    <g opacity={0.2}>
      <mask
        id="a"
        width={372}
        height={519}
        x={0}
        y={-6}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path
          fill="url(#paint0_linear_6424_192064)"
          d="M.713-5.13H372V513H.713z"
        />
      </mask>
      <g mask="url(#a)">
        <path fill="#666" d="M227.814 131.67v-10.26h7.426v10.26h-7.426Z" />
        <path
          fill="#E8E8E8"
          d="M227.814 324.045v-10.26h7.426v10.26h-7.426ZM227.814 67.545v-10.26h7.426v10.26h-7.426Z"
        />
        <path fill="#666" d="M227.814 259.92v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#E8E8E8" d="M227.814 195.795v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#666" d="M227.814 388.17v-10.26h7.426v10.26h-7.426Z" />
        <path
          fill="#E8E8E8"
          d="M227.814 452.295v-10.26h7.426v10.26h-7.426ZM88.582 131.67v-10.26h7.426v10.26h-7.426Z"
        />
        <path
          fill="#666"
          d="M88.582 324.045v-10.26h7.426v10.26h-7.426ZM88.582 67.545v-10.26h7.426v10.26h-7.426Z"
        />
        <path fill="#E8E8E8" d="M88.582 259.92v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#666" d="M88.582 195.795v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#E8E8E8" d="M88.582 388.17v-10.26h7.426v10.26h-7.426Z" />
        <path
          fill="#666"
          d="M88.582 452.295v-10.26h7.426v10.26h-7.426ZM320.637 131.67v-10.26h7.425v10.26h-7.425Z"
        />
        <path
          fill="#E8E8E8"
          d="M320.637 324.045v-10.26h7.425v10.26h-7.425ZM320.637 67.545v-10.26h7.425v10.26h-7.425Z"
        />
        <path fill="#666" d="M320.637 259.92v-10.26h7.425v10.26h-7.425Z" />
        <path fill="#E8E8E8" d="M320.637 195.795v-10.26h7.425v10.26h-7.425Z" />
        <path fill="#666" d="M320.637 388.17v-10.26h7.425v10.26h-7.425Z" />
        <path
          fill="#E8E8E8"
          d="M320.637 452.295v-10.26h7.425v10.26h-7.425ZM181.404 131.67v-10.26h7.426v10.26h-7.426Z"
        />
        <path
          fill="#666"
          d="M181.404 324.045v-10.26h7.426v10.26h-7.426ZM181.404 67.545v-10.26h7.426v10.26h-7.426Z"
        />
        <path fill="#E8E8E8" d="M181.404 259.92v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#666" d="M181.404 195.795v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#E8E8E8" d="M181.404 388.17v-10.26h7.426v10.26h-7.426Z" />
        <path
          fill="#666"
          d="M181.404 452.295v-10.26h7.426v10.26h-7.426ZM42.172 131.67v-10.26h7.426v10.26h-7.426Z"
        />
        <path
          fill="#E8E8E8"
          d="M42.172 324.045v-10.26h7.426v10.26h-7.426ZM42.172 67.545v-10.26h7.426v10.26h-7.426Z"
        />
        <path fill="#666" d="M42.172 259.92v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#E8E8E8" d="M42.172 195.795v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#666" d="M42.172 388.17v-10.26h7.426v10.26h-7.426Z" />
        <path
          fill="#E8E8E8"
          d="M42.172 452.295v-10.26h7.426v10.26h-7.426ZM274.225 131.67v-10.26h7.425v10.26h-7.425Z"
        />
        <path
          fill="#666"
          d="M274.225 324.045v-10.26h7.425v10.26h-7.425ZM274.225 67.545v-10.26h7.425v10.26h-7.425Z"
        />
        <path fill="#E8E8E8" d="M274.225 259.92v-10.26h7.425v10.26h-7.425Z" />
        <path fill="#666" d="M274.225 195.795v-10.26h7.425v10.26h-7.425Z" />
        <path fill="#E8E8E8" d="M274.225 388.17v-10.26h7.425v10.26h-7.425Z" />
        <path
          fill="#666"
          d="M274.225 452.295v-10.26h7.425v10.26h-7.425ZM134.992 131.67v-10.26h7.426v10.26h-7.426Z"
        />
        <path
          fill="#E8E8E8"
          d="M134.992 324.045v-10.26h7.426v10.26h-7.426ZM134.992 67.545v-10.26h7.426v10.26h-7.426Z"
        />
        <path fill="#666" d="M134.992 259.92v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#E8E8E8" d="M134.992 195.795v-10.26h7.426v10.26h-7.426Z" />
        <path fill="#666" d="M134.992 388.17v-10.26h7.426v10.26h-7.426Z" />
        <path
          fill="#E8E8E8"
          d="M134.992 452.295v-10.26h7.426v10.26h-7.426ZM4.426 0v5.13H.713V0h3.713Z"
        />
        <path fill="#666" d="M.713 67.545v-10.26h3.713v10.26H.713Z" />
        <path fill="#E8E8E8" d="M.713 131.67v-10.26h3.713v10.26H.713Z" />
        <path fill="#666" d="M.713 195.795v-10.26h3.713v10.26H.713Z" />
        <path fill="#E8E8E8" d="M.713 259.92v-10.26h3.713v10.26H.713Z" />
        <path fill="#666" d="M.713 324.045v-10.26h3.713v10.26H.713Z" />
        <path fill="#E8E8E8" d="M.713 388.17v-10.26h3.713v10.26H.713Z" />
        <path fill="#666" d="M.713 452.295v-10.26h3.713v10.26H.713Z" />
        <path fill="#E8E8E8" d="M4.426 513H.713v-5.13h3.713V513Z" />
        <path fill="#666" d="M49.598 513h-7.426v-5.13h7.426V513Z" />
        <path fill="#E8E8E8" d="M96.008 513h-7.426v-5.13h7.426V513Z" />
        <path fill="#666" d="M142.418 513h-7.426v-5.13h7.426V513Z" />
        <path fill="#E8E8E8" d="M188.83 513h-7.426v-5.13h7.426V513Z" />
        <path fill="#666" d="M235.24 513h-7.426v-5.13h7.426V513Z" />
        <path fill="#E8E8E8" d="M281.65 513h-7.425v-5.13h7.425V513Z" />
        <path fill="#666" d="M328.062 513h-7.425v-5.13h7.425V513Z" />
        <path fill="#E8E8E8" d="M372 507.87V513h-3.713v-5.13H372Z" />
        <path fill="#666" d="M372 442.035v10.26h-3.713v-10.26H372Z" />
        <path fill="#E8E8E8" d="M372 377.91v10.26h-3.713v-10.26H372Z" />
        <path fill="#666" d="M372 313.785v10.26h-3.713v-10.26H372Z" />
        <path fill="#E8E8E8" d="M372 249.66v10.26h-3.713v-10.26H372Z" />
        <path fill="#666" d="M372 185.535v10.26h-3.713v-10.26H372Z" />
        <path fill="#E8E8E8" d="M372 121.41v10.26h-3.713v-10.26H372Z" />
        <path fill="#666" d="M372 57.285v10.26h-3.713v-10.26H372Z" />
        <path fill="#E8E8E8" d="M368.287 0v5.13H372V0h-3.713Z" />
        <path fill="#666" d="M320.637 0v5.13h7.425V0h-7.425Z" />
        <path fill="#E8E8E8" d="M274.225 0v5.13h7.425V0h-7.425Z" />
        <path fill="#666" d="M227.814 0v5.13h7.426V0h-7.426Z" />
        <path fill="#E8E8E8" d="M181.404 0v5.13h7.426V0h-7.426Z" />
        <path fill="#666" d="M134.992 0v5.13h7.426V0h-7.426Z" />
        <path fill="#E8E8E8" d="M88.582 0v5.13h7.426V0h-7.426Z" />
        <path fill="#666" d="M42.172 0v5.13h7.426V0h-7.426Z" />
      </g>
    </g>
  </svg>
));
export default SvgIconTicketCardBase;
