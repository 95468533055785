import { Flex, Grid, GridProps, Skeleton } from '@chakra-ui/react';
import { LevelBar } from '../LevelBar/LevelBar';
import {
  IconTuningBraking,
  IconTuningCooling,
  IconTuningFastTurns,
  IconTuningHandling,
  IconTuningSlowTurns,
  IconTuningSpeed,
  IconUnlockedUpgrade,
} from 'icons';

export interface CarStats {
  handling: number;
  speed: number;
  fastTurns: number;
  slowTurns: number;
  braking: number;
  cooling: number;
}

interface FusionCarStatsProps extends GridProps {
  stats: CarStats | null;
  isLoading?: boolean;
  isLocked?: boolean;
}

const LevelBarSkeleton = () => (
  <Skeleton
    height="18px"
    startColor="whiteAlpha.100"
    endColor="whiteAlpha.300"
    borderRadius="md"
  />
);

export const FusionCarStats = ({
  stats,
  isLoading = false,
  isLocked = false,
  ...props
}: FusionCarStatsProps) => {
  if (isLoading || !stats) {
    return (
      <Grid
        templateColumns="repeat(3, 1fr)"
        templateRows="repeat(3, auto)"
        w="full"
        gap={4}
      >
        {[...Array(6)].map((_, index) => (
          <LevelBarSkeleton key={index} />
        ))}
      </Grid>
    );
  }

  return (
    <Grid
      pos="relative"
      templateColumns="repeat(3, 1fr)"
      templateRows="repeat(3, auto)"
      w="full"
      gap={4}
      {...props}
    >
      {isLocked && (
        <Flex
          position="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          zIndex="10"
          background="black.80"
          alignItems="center"
          justifyContent="center"
          w="full"
          h="full"
          rounded="0.25rem"
          borderWidth="1.5px"
          borderColor="white.20"
        >
          <IconUnlockedUpgrade
            __css={{
              width: '1.5rem',
              height: '1.5rem',
            }}
          />
        </Flex>
      )}
      <LevelBar
        maxValue={6}
        currentValue={isLocked ? 2 : stats.handling}
        title="Handling"
        icon={<IconTuningHandling />}
      />

      <LevelBar
        maxValue={6}
        currentValue={isLocked ? 3 : stats.speed}
        title="Speed"
        icon={<IconTuningSpeed />}
      />

      <LevelBar
        maxValue={6}
        currentValue={isLocked ? 6 : stats.fastTurns}
        numberOfBars={6}
        title="Fast Turns"
        icon={<IconTuningFastTurns />}
      />

      <LevelBar
        maxValue={6}
        currentValue={isLocked ? 1 : stats.slowTurns}
        title="Slow Turns"
        icon={<IconTuningSlowTurns />}
      />

      <LevelBar
        maxValue={6}
        currentValue={isLocked ? 5 : stats.braking}
        title="Braking"
        icon={<IconTuningBraking />}
      />

      <LevelBar
        maxValue={6}
        currentValue={isLocked ? 4 : stats.cooling}
        title="Cooling"
        icon={<IconTuningCooling />}
      />
    </Grid>
  );
};
