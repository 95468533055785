import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import {
  IconBumpsConditions,
  IconGripConditions,
  IconRainConditions,
  IconTemperatureConditions,
  IconWindConditions,
} from 'icons';
import {
  AirTemperatureCategory,
  TrackTemperatureCategory,
  WindCategories,
  TarmacConditionCategories,
  WeatherCategories,
} from 'api/generated/graphql';
import {
  AirTemperatureColors,
  TrackTemperatureColors,
  WeatherColors,
  RoughnessColors,
  TarmacConditionColors,
  WindColors,
} from '../../RaceInfo/RaceInfoTypes';
import { useState } from 'react';

// Função auxiliar para determinar o número de boxes preenchidos
const getFilledBoxes = (
  category: string,
  type: 'air' | 'track' | 'weather' | 'roughness' | 'tarmac' | 'wind'
): number => {
  const categories = {
    air: Object.keys(AirTemperatureColors),
    track: Object.keys(TrackTemperatureColors),
    weather: Object.keys(WeatherColors),
    roughness: Object.keys(RoughnessColors),
    tarmac: Object.keys(TarmacConditionColors),
    wind: Object.keys(WindColors),
  };
  const index = categories[type].indexOf(category);
  return Math.max(1, Math.min(6, index + 1));
};

// Componente para renderizar os boxes
const ConditionBoxes = ({
  category,
  type,
}: {
  category: string;
  type: 'air' | 'track' | 'weather' | 'roughness' | 'tarmac' | 'wind';
}) => {
  const filledBoxes = getFilledBoxes(category, type);
  const color = 'yellow';

  return (
    <Flex alignItems="center" gap={1}>
      {[...Array(6)].map((_, index) => (
        <Box
          key={index}
          w={2}
          h={2}
          bg={index < filledBoxes ? color : 'transparent'}
          borderRadius="2px"
          borderColor={index < filledBoxes ? 'transparent' : 'white.20'}
          borderWidth={index < filledBoxes ? 0 : 1}
        />
      ))}
    </Flex>
  );
};

const RaceConditions = ({
  airTemperature,
  trackTemperature,
  weather,
  wind,
  roughness,
  tarmac,
  showHover = false,
}: {
  airTemperature: AirTemperatureCategory;
  trackTemperature: TrackTemperatureCategory;
  weather: WeatherCategories;
  wind: WindCategories;
  roughness: string;
  tarmac: TarmacConditionCategories;
  showHover?: boolean;
}) => {
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const [conditionHovered, setConditionHovered] = useState<
    'air' | 'track' | 'weather' | 'wind' | 'roughness' | 'tarmac' | null
  >(null);

  const hoverText = {
    skill: 'The number of completed or remaining laps in the race.',
    air: 'The current air temperature, affecting engine performance and tire grip.',
    track:
      'The surface temperature of the track, influencing tire wear and traction.',
    weather:
      'The amount of rain on the track, impacting visibility and driving conditions.',
    wind: 'The speed and direction of wind, affecting aerodynamics and car stability.',
    roughness:
      'The level of traction available on the track, influenced by surface conditions and temp.',
    tarmac:
      'The texture of the track surface, impacting tire wear and suspension handling.',
  };

  return (
    <Flex
      gap={4}
      w="full"
      alignItems="center"
      flexDir="column"
      justifyContent="flex-end"
    >
      {!showHover && (
        <Text
          fontSize="16px"
          color="white.80"
          fontWeight={400}
          fontFamily="heading"
        >
          TRACK CONDITIONS
        </Text>
      )}
      <Flex w="full" gap={2}>
        {isMobile ? (
          // Mobile layout (2 columns)
          <>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'air' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderTopLeftRadius="8px"
                onMouseEnter={() => setConditionHovered('air')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconTemperatureConditions />
                  <Text fontSize="14px" color="white.80">
                    Air
                  </Text>
                </Flex>
                <ConditionBoxes category={airTemperature} type="air" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'track' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                onMouseEnter={() => setConditionHovered('track')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconTemperatureConditions />
                  <Text fontSize="14px" color="white.80">
                    Track
                  </Text>
                </Flex>
                <ConditionBoxes category={trackTemperature} type="track" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'roughness' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderBottomLeftRadius="8px"
                onMouseEnter={() => setConditionHovered('roughness')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconGripConditions />
                  <Text fontSize="14px" color="white.80">
                    Grip
                  </Text>
                </Flex>
                <ConditionBoxes category={roughness} type="roughness" />
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'wind' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderTopRightRadius="8px"
                onMouseEnter={() => setConditionHovered('wind')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconWindConditions />
                  <Text fontSize="14px" color="white.80">
                    Wind
                  </Text>
                </Flex>
                <ConditionBoxes category={wind} type="wind" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'weather' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
              >
                <Flex alignItems="center" gap={2}>
                  <IconRainConditions />
                  <Text fontSize="14px" color="white.80">
                    Rain
                  </Text>
                </Flex>
                <ConditionBoxes category={weather} type="weather" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'tarmac' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderBottomRightRadius="8px"
                onMouseEnter={() => setConditionHovered('tarmac')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconBumpsConditions />
                  <Text fontSize="14px" color="white.80">
                    Bumps
                  </Text>
                </Flex>
                <ConditionBoxes category={tarmac} type="tarmac" />
              </Flex>
            </Flex>
          </>
        ) : (
          // Desktop layout (3 columns)
          <>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'air' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderTopLeftRadius="8px"
                onMouseEnter={() => setConditionHovered('air')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconTemperatureConditions />
                  <Text fontSize="14px" color="white.80">
                    Air
                  </Text>
                </Flex>
                <ConditionBoxes category={airTemperature} type="air" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'track' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderBottomLeftRadius="8px"
                onMouseEnter={() => setConditionHovered('track')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconTemperatureConditions />
                  <Text fontSize="14px" color="white.80">
                    Track
                  </Text>
                </Flex>
                <ConditionBoxes category={trackTemperature} type="track" />
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'wind' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderTopRightRadius="8px"
                onMouseEnter={() => setConditionHovered('wind')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconWindConditions />
                  <Text fontSize="14px" color="white.80">
                    Wind
                  </Text>
                </Flex>
                <ConditionBoxes category={wind} type="wind" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'roughness' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderBottomRightRadius="8px"
                onMouseEnter={() => setConditionHovered('roughness')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconGripConditions />
                  <Text fontSize="14px" color="white.80">
                    Grip
                  </Text>
                </Flex>
                <ConditionBoxes category={roughness} type="roughness" />
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'weather' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderTopRightRadius="8px"
                onMouseEnter={() => setConditionHovered('weather')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconRainConditions />
                  <Text fontSize="14px" color="white.80">
                    Rain
                  </Text>
                </Flex>
                <ConditionBoxes category={weather} type="weather" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'tarmac' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderBottomRightRadius="8px"
                onMouseEnter={() => setConditionHovered('tarmac')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconBumpsConditions />
                  <Text fontSize="14px" color="white.80">
                    Bumps
                  </Text>
                </Flex>
                <ConditionBoxes category={tarmac} type="tarmac" />
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      {showHover && (
        <Text
          fontSize="0.75rem"
          lineHeight="0.875rem"
          color="white.60"
          fontWeight={500}
        >
          {conditionHovered
            ? hoverText[conditionHovered]
            : `${isMobile ? 'Tap' : 'Hover'} insights for more info`}
        </Text>
      )}
    </Flex>
  );
};

export default RaceConditions;
