import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuningCooling = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={14}
    fill="none"
    viewBox="0 0 13 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M8.916 4c1.922 0 3.493 1.453 3.727 3.328.047.234-.164.445-.398.422l-2.883-.281c.187.539.304 1.148.304 1.804 0 1.922-1.453 3.493-3.328 3.727-.234.047-.445-.164-.421-.398l.28-2.883A5.5 5.5 0 0 1 4.394 10 3.734 3.734 0 0 1 .667 6.695c-.047-.234.164-.468.398-.422l2.86.282a5.5 5.5 0 0 1-.282-1.805c0-1.922 1.453-3.492 3.328-3.75.235-.047.446.164.422.398l-.281 2.883A5.5 5.5 0 0 1 8.916 4ZM6.643 7.75c.422 0 .727-.352.727-.75a.75.75 0 0 0-.727-.75.736.736 0 0 0-.726.75c0 .398.304.75.726.75Z"
    />
  </svg>
));
export default SvgIconTuningCooling;
