import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFrontWing = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    viewBox="0 0 17 16"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      d="M8.695 14.4v-.43a2 2 0 0 1 1.019-1.744L12.96 10.4m-4.265 4 6.06-2.001c.474-.157.818-.568.887-1.062.15-1.06-.963-1.842-1.909-1.344l-.773.407m-4.265 4L.8 6.943m0 0v-.59a2 2 0 0 1 .998-1.73l3.92-2.27c1.046-.606 2.316.302 2.083 1.487-.102.519-.488.936-.997 1.08l-.584.165M.8 6.943l3.862-2.06a1.354 1.354 0 0 1 1.558.202m0 0 6.74 5.315"
      opacity={0.6}
    />
  </svg>
));
export default SvgIconFrontWing;
