import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCheckRed = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#D83832"
      d="m13.125 3.277-7.438 7.438a.544.544 0 0 1-.437.191c-.191 0-.355-.054-.465-.191L.848 6.777a.599.599 0 0 1 0-.902.599.599 0 0 1 .902 0l3.5 3.473 6.973-6.973a.599.599 0 0 1 .902 0 .599.599 0 0 1 0 .902Z"
    />
  </svg>
));
export default SvgIconCheckRed;
