import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRacinoLogo = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#D83832"
      fillRule="evenodd"
      d="M22.907 7.42c0-1.737-1.57-3.178-3.467-3.178H3.173L4.759 6.36h14.68c.637 0 1.156.478 1.156 1.06 0 .581-.522 1.058-1.156 1.058h-3.315l-.492 2.119 5.544 7.4h5.237l1.586-2.12-2.59-3.458c1.316-1.319 2.12-3.074 2.12-5.003 0-4.078-3.643-7.416-8.09-7.416H0l1.586 2.118H19.44c3.173 0 5.779 2.39 5.779 5.297 0 1.607-.782 3.047-2.015 4.019a5.76 5.76 0 0 1-.897.58l2.891 3.86h-2.804l-3.956-5.282h1.004c1.914 0 3.467-1.439 3.467-3.178l-.002.007ZM16.284 18l.504-2.12-1.816-2.422-.561 2.422h-2.363l1.715-7.4H6.347l1.588 2.118h2.973L9.192 18h7.091ZM25.844.152h-.436V0h1.071v.152h-.436v1.13h-.199V.153ZM26.687 0h.2l.453.938h.004L27.8 0h.2v1.283h-.19V.346h-.004l-.393.792h-.14L26.88.346h-.004v.937h-.19V0Z"
      clipRule="evenodd"
    />
    <path
      fill="#999"
      fillRule="evenodd"
      d="M6.55 26.965c.053-.179.079-.346.079-.503v-.244c0-.247-.026-.473-.079-.678a2.35 2.35 0 0 0-.213-.552 1.977 1.977 0 0 0-.314-.43 2.076 2.076 0 0 0-.38-.317c.158-.23.272-.475.343-.732.07-.26.106-.503.106-.727v-.24c0-.283-.05-.522-.148-.717a1.381 1.381 0 0 0-.365-.473 1.384 1.384 0 0 0-.472-.269A1.542 1.542 0 0 0 4.63 21H.606a.592.592 0 0 0-.435.186.677.677 0 0 0-.171.454v5.72a.685.685 0 0 0 .171.46c.056.055.12.099.194.131A.592.592 0 0 0 .606 28h4.566c.268 0 .495-.05.68-.151.185-.105.334-.233.448-.386.118-.153.2-.319.25-.498Zm-1.198-1.19c.05.13.074.277.074.443v.235c0 .068-.01.122-.028.16a.198.198 0 0 1-.064.084.184.184 0 0 1-.084.029.756.756 0 0 1-.078.005h-3.97v-4.462H4.63c.167 0 .25.091.25.274v.239c0 .166-.024.315-.074.449a.925.925 0 0 1-.527.556 1.174 1.174 0 0 1-.426.073h-2.17v1.28h2.712c.157 0 .299.026.425.078.127.048.234.12.324.214.09.095.159.209.208.342Zm7.78-.635H9.334v-1.28h3.798v1.28Zm.541 2.86H9.334c-.166 0-.348-.03-.545-.093a1.625 1.625 0 0 1-.551-.302 1.779 1.779 0 0 1-.42-.552c-.112-.228-.167-.506-.167-.835V21.64a.672.672 0 0 1 .17-.454.595.595 0 0 1 .436-.186h5.416v1.27h-4.82v3.948c0 .166.042.293.125.381.083.088.205.132.366.132h4.33V28Zm4.511-5.73h2.65V21h-6.508v1.27h2.655V28h1.203v-5.73ZM26.798 28H28v-6.36a.67.67 0 0 0-.176-.454.596.596 0 0 0-.43-.186h-2.711c-.478 0-.92.086-1.323.259a3.147 3.147 0 0 0-1.05.722 3.31 3.31 0 0 0-.694 1.108c-.166.43-.25.9-.25 1.411V28h1.203v-1.718h4.228V28Zm0-2.987H22.57V24.5c0-.355.046-.656.139-.903.095-.25.215-.459.36-.625.145-.166.304-.296.477-.39.173-.095.336-.165.49-.21a2.845 2.845 0 0 1 .648-.102h2.114v2.743Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconRacinoLogo;
