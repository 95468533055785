import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWithdraw = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#4FC5BA"
      d="M15.75 7.547c.375 0 .75.328.75.75v5.953c0 .422-.375.75-.75.75a.74.74 0 0 1-.75-.75v-4.172l-6.234 6.235c-.141.14-.329.187-.516.187a.942.942 0 0 1-.61-.188c-.28-.28-.28-.796 0-1.078l6.235-6.187L9.703 9c-.375 0-.75-.328-.703-.75 0-.375.328-.75.75-.75l6 .047ZM12 0c6.61 0 12 5.39 12 12 0 6.656-5.39 12-12 12-6.656 0-12-5.344-12-12C0 5.39 5.344 0 12 0Zm0 22.5c5.766 0 10.5-4.688 10.5-10.5 0-5.766-4.734-10.5-10.5-10.5C6.187 1.5 1.5 6.234 1.5 12c0 5.813 4.688 10.5 10.5 10.5Z"
    />
  </svg>
));
export default SvgIconWithdraw;
