import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPartTransmissionRatios = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={13}
      fill="none"
      viewBox="0 0 20 13"
      aria-hidden="true"
      {...props}
    >
      <path
        stroke="#fff"
        d="M16.454 4.208c-.3.583-.485 1.394-.485 2.292 0 .898.186 1.71.485 2.292m0-4.584c.291-.567.69-.916 1.13-.916.893 0 1.616 1.436 1.616 3.208 0 1.772-.723 3.208-1.616 3.208-.44 0-.839-.35-1.13-.916m0-4.584h-2.562c-.637 0-1.154 1.026-1.154 2.292 0 1.266.517 2.292 1.154 2.292h2.562m-1.66-4.584C14.358 2.315 13.395 1 12.278 1c-.824 0-1.563.713-2.07 1.846m4.588 5.946c-.438 1.893-1.4 3.208-2.518 3.208-1.53 0-2.77-2.462-2.77-5.5 0-1.403.265-2.683.7-3.654m.223 7.779c-.504 0-.977-.19-1.384-.521m1.16-7.258c-.42.047-.814.227-1.16.509m0 0c-.407-.332-.88-.522-1.385-.522-1.53 0-2.769 1.745-2.769 3.896 0 2.152 1.24 3.896 2.77 3.896.504 0 .977-.19 1.384-.521m0-6.75C8.218 4.029 7.66 5.288 7.66 6.73s.557 2.701 1.385 3.375m-2.77.063c-.946 0-1.759-.849-2.115-2.063m2.116-4.812c-.947 0-1.76.848-2.116 2.062m0 0a4.9 4.9 0 0 0-.192 1.375c0 .489.068.954.192 1.375m0-2.75-2.269.23c-.382 0-.692.512-.692 1.145s.31 1.146.692 1.146l2.269.23"
      />
    </svg>
  )
);
export default SvgIconPartTransmissionRatios;
