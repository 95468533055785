/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useDisclosure } from '@chakra-ui/react';

import {
  useCarTierLocalStore,
  useGetTierUpdatedCars,
  useLazyImageLoader,
  useUserSettings,
} from 'hooks';
import { RBlockPaidRacesModal } from 'components';
import { TierUpdatedCar, TierUpdatedCars } from 'hooks/useCarTierLocalStore';

interface UserProviderProps {
  children: ReactNode;
}

interface UserContextProps {
  avatarImgSrc: string;
  avatarFullbodyImgSrc: string;
  setAvatarVersion: React.Dispatch<React.SetStateAction<number>>;
  tierUpdatedCars?: TierUpdatedCar[];
  saveTierUpdatedCars: (TierUpdatedCarsResponse: TierUpdatedCars) => void;
  clearTierUpdatedCars: () => void;
}

const UserContext = createContext<UserContextProps>({
  avatarImgSrc: '',
  avatarFullbodyImgSrc: '',
  setAvatarVersion: () => null,
  saveTierUpdatedCars: () => null,
  clearTierUpdatedCars: () => null,
});

export const UserProvider = ({ children }: UserProviderProps) => {
  const [avatarVersion, setAvatarVersion] = useState(Date.now());
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { tierUpdatedCars, saveTierUpdatedCars, clearTierUpdatedCars } =
    useCarTierLocalStore();

  const { getUserSettings } = useUserSettings();
  const { data: carTierData } = useGetTierUpdatedCars();

  const avatarImgSrc = useLazyImageLoader({
    placeholder: '/images/home/driver.png',
    src: `https://models.readyplayer.me/${getUserSettings.data?.getUser?.avatarId}.png?blendShapes[mouthSmile]=0.4&blendShapes[browInnerUp]=0.2&v=${avatarVersion}`,
  });

  const avatarFullbodyImgSrc = useLazyImageLoader({
    placeholder: '/images/home/full_body_driver.png',
    src: `https://models.readyplayer.me/${getUserSettings.data?.getUser?.avatarId}.png?blendShapes[mouthSmile]=0.4&blendShapes[browInnerUp]=0.2&v=${avatarVersion}&camera=fullbody`,
  });

  const handleBlockPaidRaces = () => {
    const isBlockWarned = Boolean(localStorage.getItem('blockPaidRaces'));

    if (!isBlockWarned) onOpen();
  };

  useEffect(() => {
    if (getUserSettings.data?.getUser?.blockPaidRaces) handleBlockPaidRaces();
  }, [getUserSettings.data?.getUser]);

  useEffect(() => {
    if (
      carTierData?.getTierUpdatedCars &&
      carTierData?.getTierUpdatedCars.length > 0
    ) {
      saveTierUpdatedCars({ data: carTierData });
    }
  }, [carTierData?.getTierUpdatedCars]);

  return (
    <UserContext.Provider
      value={{
        avatarImgSrc,
        tierUpdatedCars,
        setAvatarVersion,
        saveTierUpdatedCars,
        clearTierUpdatedCars,
        avatarFullbodyImgSrc,
      }}
    >
      {children}
      <RBlockPaidRacesModal
        onOpen={onOpen}
        onClose={() => {
          localStorage.setItem('blockPaidRaces', 'true');
          onClose();
        }}
        isOpen={isOpen}
      />
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
