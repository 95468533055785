import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMaticReward = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    fill="none"
    viewBox="0 0 100 100"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#7E3DFD"
      fillRule="evenodd"
      d="M54.716 16.222 54.337 16l-.379.222-3.942 2.31-3.976-2.311-.378-.22-.378.22-28.913 16.94-.371.217v33.244l.37.217 28.914 16.94.379.22.378-.22 3.967-2.315 3.95 2.314.38.222.378-.222L83.63 66.84l.371-.217V33.378l-.37-.217-28.914-16.94Zm-9.051 1.515 2.864 1.665-23.484 13.759-.371.217v33.244l.37.217 23.479 13.755-2.859 1.668-28.164-16.5V34.238l28.165-16.5ZM26.174 65.762V34.238l28.163-16.5 28.163 16.5v31.524l-28.163 16.5-28.163-16.5Zm34.537-21.128c.685-.328 1.455-.328 2.053 0l6.93 3.85c.685.41 1.027 1.066 1.027 1.721v7.783c0 .737-.428 1.393-1.026 1.72l-6.93 3.851c-.685.328-1.455.328-2.054 0l-6.93-3.85c-.684-.41-1.027-1.066-1.027-1.721v-2.704l3.252-1.802v2.622c0 .737.427 1.392 1.026 1.72l3.68 2.048c.684.328 1.454.328 2.052 0l3.594-2.048c.684-.41 1.027-1.065 1.027-1.72v-4.097c0-.737-.428-1.392-1.027-1.72l-3.679-2.048c-.684-.328-1.455-.328-2.053 0L55.92 50.86l-3.251 1.803-4.706 2.621c-.684.328-1.454.328-2.053 0l-6.93-3.85c-.685-.41-1.027-1.065-1.027-1.72v-7.702c0-.655.342-1.31 1.027-1.638l7.015-3.933c.685-.327 1.455-.327 2.054 0l6.844 3.85c.685.41 1.027 1.066 1.027 1.721v2.786L52.669 46.6v-2.703c0-.738-.428-1.393-1.027-1.72l-3.679-2.13c-.684-.329-1.454-.329-2.053 0l-3.68 2.047c-.684.41-1.026 1.065-1.026 1.72v4.097c0 .737.428 1.393 1.027 1.72l3.679 2.13c.684.328 1.454.328 2.053 0l4.706-2.703 3.251-1.72 4.791-2.704Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconMaticReward;
