import { Flex, FlexProps } from '@chakra-ui/react';

const BaseCardTopRight = ({ children, ...rest }: FlexProps) => {
  return (
    <Flex
      className="BaseCardTopRight"
      maxWidth="4rem"
      maxHeight="42%"
      position="absolute"
      top="6"
      right="6"
      gap="2"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default BaseCardTopRight;
