import { Flex, FlexProps, Box, Text } from '@chakra-ui/react';

import { IconVextBorder, IconMaticBorder } from 'icons';

const Token = ({
  token,
  amount,
  size = 'lg',
  ...rest
}: FlexProps & {
  token: 'vext' | 'matic';
  amount: string | number;
  size?: 'lg' | 'sm';
}) => {
  return (
    <Flex alignItems="center" justifyContent="center" {...rest}>
      <Box
        height={size === 'lg' ? '2.5rem' : '1.75rem'}
        width={size === 'lg' ? '2.5rem' : '1.75rem'}
        marginRight="1"
      >
        {token === 'vext' && (
          <IconVextBorder
            __css={{
              path: { fill: 'stargateShimmer.100' },
            }}
            width="100%"
            height="auto"
          />
        )}

        {token === 'matic' && (
          <IconMaticBorder
            __css={{
              path: { fill: 'maticPolygonPurple.100' },
            }}
            width="100%"
            height="auto"
          />
        )}
      </Box>

      <Text
        color={
          token === 'vext' ? 'stargateShimmer.100' : 'maticPolygonPurple.100'
        }
        fontSize={size === 'lg' ? '3rem' : '1.875rem'}
        fontWeight="medium"
        lineHeight="1"
      >
        {amount}
        <span
          style={{
            fontSize: '0.5em',
            textTransform: 'uppercase',
            marginLeft: '.1rem',
          }}
        >
          {token}
        </span>
      </Text>
    </Flex>
  );
};

export default Token;
