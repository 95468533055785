import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSort = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={15}
    fill="none"
    viewBox="0 0 18 15"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M9.75 3A.74.74 0 0 1 9 2.25a.74.74 0 0 1 .75-.75h1.5c.375 0 .719.344.719.75s-.313.75-.719.75h-1.5Zm0 8a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75h5.469a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H9.75Zm0-4A.722.722 0 0 1 9 6.25a.74.74 0 0 1 .75-.75h3.469a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H9.75Zm7.469 6.5a.74.74 0 0 1 .75.75.74.74 0 0 1-.75.75H9.75a.74.74 0 0 1-.75-.75c0-.438.313-.75.75-.75h7.469ZM4.53 1.25l3 3.25c.282.313.282.781-.031 1.063a.774.774 0 0 1-1.063-.032L4.75 3.688V14.25A.74.74 0 0 1 4 15a.722.722 0 0 1-.75-.75V3.687L1.531 5.532A.732.732 0 0 1 1 5.75a.849.849 0 0 1-.531-.188C.156 5.282.156 4.813.438 4.5l3-3.25a.755.755 0 0 1 1.093 0Z"
    />
  </svg>
));
export default SvgIconSort;
