import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSilverstoneMinimapMarkers = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={375}
      height={375}
      fill="none"
      viewBox="0 0 375 375"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#000"
        fillOpacity={0.2}
        d="M93.155 208.449c-20.479-10.593-34.766-19.76-46.652-27.932-1.526-1.263-5.174-4.031-6.389-5.497-2.213-2.672-3.417-5.805-3.417-10.697 0-3.689 1.651-7.075 3.863-9.509 2.607-2.869 6.584-4.681 8.915-5.646 4.308-1.783 10.944-5.906 17.68-10.846 4.457-3.268 11.568-10.161 17.977-15.897 4.869-4.358 8.716-7.591 9.806-8.617 2.02-1.902 1.189-3.59.594-4.309-.94-1.139-3.15-3.804-4.457-5.349-1.634-1.931-1.486-4.011 0-6.24 8.915-10.103 16.581-10.901 20.355-11.886 3.417-.891 5.2-.297 6.388.594 21.544 16.492 65.165 49.832 67.304 51.258 2.675 1.783 4.458 4.309 5.052 9.361.594 5.051-1.189 14.56-2.526 23.177-1.337 8.617 2.526 15.303 3.417 16.789.892 1.486 14.561 18.72 15.749 20.057 1.189 1.338 5.943 6.983 6.983 10.252 1.04 3.269-2.08 4.309-2.971 4.754-.892.446-10.401 3.418-13.372 4.16-2.971.743-5.794 2.378-5.794 4.755s.697 3.004 3.12 4.308c9.657 5.2 21.842 6.231 26.297 6.092 4.755-.149 8.617-5.646 10.103-7.28 1.16-1.276 40.195-43.577 59.578-65.373 5.447-6.124 11.383-13.104 12.035-14.114 2.875-4.457 2.77-9.971.297-13.966-1.932-3.12-5.646-3.566-7.577-3.714-1.334-.103-4.755-.298-9.212-.892-4.624-.616-8.32-5.051-8.171-9.509.203-6.104 4.658-8.04 7.28-8.914 2.674-.891 4.903.149 6.091.446 1.189.297 4.903 2.228 7.577 3.714 2.675 1.486 10.252 4.903 15.749 8.023s9.212 8.32 12.629 12.48 5.646 10.4 6.686 18.721c1.04 8.32 5.64 59.572 5.943 68.492.297 8.766-6.983 14.115-10.252 15.452-3.268 1.337-8.469 3.12-20.503 6.091-9.628 2.377-23.227 3.665-28.823 4.012-7.379.346-23.356 1.129-28.229 1.485-6.092.446-12.035 4.16-13.075 4.903-1.04.743-4.606 3.269-8.023 3.269s-9.063-2.526-11.44-3.417c-2.377-.892-8.023-3.566-13.52-3.566s-9.36 2.674-11.143 4.309c-1.783 1.634-6.537 5.2-9.657 5.348-2.393.114-5.851.439-9.955-2.674-4.309-3.269-5.2-10.549-5.497-13.074-.297-2.526-3.714-4.606-8.469-6.983-4.754-2.378-36.632-19.59-39.818-21.247-3.714-1.931-15.6-8.468-28.526-15.154Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.2}
        strokeWidth={10}
        d="M93.155 208.449c-20.479-10.593-34.766-19.76-46.652-27.932-1.526-1.263-5.174-4.031-6.389-5.497-2.213-2.672-3.417-5.805-3.417-10.697 0-3.689 1.651-7.075 3.863-9.509 2.607-2.869 6.584-4.681 8.915-5.646 4.308-1.783 10.944-5.906 17.68-10.846 4.457-3.268 11.568-10.161 17.977-15.897 4.869-4.358 8.716-7.591 9.806-8.617 2.02-1.902 1.189-3.59.594-4.309-.94-1.139-3.15-3.804-4.457-5.349-1.634-1.931-1.486-4.011 0-6.24 8.915-10.103 16.581-10.901 20.355-11.886 3.417-.891 5.2-.297 6.388.594 21.544 16.492 65.165 49.832 67.304 51.258 2.675 1.783 4.458 4.309 5.052 9.361.594 5.051-1.189 14.56-2.526 23.177-1.337 8.617 2.526 15.303 3.417 16.789.892 1.486 14.561 18.72 15.749 20.057 1.189 1.338 5.943 6.983 6.983 10.252 1.04 3.269-2.08 4.309-2.971 4.754-.892.446-10.401 3.418-13.372 4.16-2.971.743-5.794 2.378-5.794 4.755s.697 3.004 3.12 4.308c9.657 5.2 21.842 6.231 26.297 6.092 4.755-.149 8.617-5.646 10.103-7.28 1.16-1.276 40.195-43.577 59.578-65.373 5.447-6.124 11.383-13.104 12.035-14.114 2.875-4.457 2.77-9.971.297-13.966-1.932-3.12-5.646-3.566-7.577-3.714-1.334-.103-4.755-.298-9.212-.892-4.624-.616-8.32-5.051-8.171-9.509.203-6.104 4.658-8.04 7.28-8.914 2.674-.891 4.903.149 6.091.446 1.189.297 4.903 2.228 7.577 3.714 2.675 1.486 10.252 4.903 15.749 8.023s9.212 8.32 12.629 12.48 5.646 10.4 6.686 18.721c1.04 8.32 5.64 59.572 5.943 68.492.297 8.766-6.983 14.115-10.252 15.452-3.268 1.337-8.469 3.12-20.503 6.091-9.628 2.377-23.227 3.665-28.823 4.012-7.379.346-23.356 1.129-28.229 1.485-6.092.446-12.035 4.16-13.075 4.903-1.04.743-4.606 3.269-8.023 3.269s-9.063-2.526-11.44-3.417c-2.377-.892-8.023-3.566-13.52-3.566s-9.36 2.674-11.143 4.309c-1.783 1.634-6.537 5.2-9.657 5.348-2.393.114-5.851.439-9.955-2.674-4.309-3.269-5.2-10.549-5.497-13.074-.297-2.526-3.714-4.606-8.469-6.983-4.754-2.378-36.632-19.59-39.818-21.247-3.714-1.931-15.6-8.468-28.526-15.154Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.95}
        strokeWidth={4}
        d="M93.155 208.449c-20.479-10.593-34.766-19.76-46.652-27.932-1.526-1.263-5.174-4.031-6.389-5.497-2.213-2.672-3.417-5.805-3.417-10.697 0-3.689 1.651-7.075 3.863-9.509 2.607-2.869 6.584-4.681 8.915-5.646 4.308-1.783 10.944-5.906 17.68-10.846 4.457-3.268 11.568-10.161 17.977-15.897 4.869-4.358 8.716-7.591 9.806-8.617 2.02-1.902 1.189-3.59.594-4.309-.94-1.139-3.15-3.804-4.457-5.349-1.634-1.931-1.486-4.011 0-6.24 8.915-10.103 16.581-10.901 20.355-11.886 3.417-.891 5.2-.297 6.388.594 21.544 16.492 65.165 49.832 67.304 51.258 2.675 1.783 4.458 4.309 5.052 9.361.594 5.051-1.189 14.56-2.526 23.177-1.337 8.617 2.526 15.303 3.417 16.789.892 1.486 14.561 18.72 15.749 20.057 1.189 1.338 5.943 6.983 6.983 10.252 1.04 3.269-2.08 4.309-2.971 4.754-.892.446-10.401 3.418-13.372 4.16-2.971.743-5.794 2.378-5.794 4.755s.697 3.004 3.12 4.308c9.657 5.2 21.842 6.231 26.297 6.092 4.755-.149 8.617-5.646 10.103-7.28 1.16-1.276 40.195-43.577 59.578-65.373 5.447-6.124 11.383-13.104 12.035-14.114 2.875-4.457 2.77-9.971.297-13.966-1.932-3.12-5.646-3.566-7.577-3.714-1.334-.103-4.755-.298-9.212-.892-4.624-.616-8.32-5.051-8.171-9.509.203-6.104 4.658-8.04 7.28-8.914 2.674-.891 4.903.149 6.091.446 1.189.297 4.903 2.228 7.577 3.714 2.675 1.486 10.252 4.903 15.749 8.023s9.212 8.32 12.629 12.48 5.646 10.4 6.686 18.721c1.04 8.32 5.64 59.572 5.943 68.492.297 8.766-6.983 14.115-10.252 15.452-3.268 1.337-8.469 3.12-20.503 6.091-9.628 2.377-23.227 3.665-28.823 4.012-7.379.346-23.356 1.129-28.229 1.485-6.092.446-12.035 4.16-13.075 4.903-1.04.743-4.606 3.269-8.023 3.269s-9.063-2.526-11.44-3.417c-2.377-.892-8.023-3.566-13.52-3.566s-9.36 2.674-11.143 4.309c-1.783 1.634-6.537 5.2-9.657 5.348-2.393.114-5.851.439-9.955-2.674-4.309-3.269-5.2-10.549-5.497-13.074-.297-2.526-3.714-4.606-8.469-6.983-4.754-2.378-36.632-19.59-39.818-21.247-3.714-1.931-15.6-8.468-28.526-15.154Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={107.864}
        y={58.241}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m118.091 66.317-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={61.212}
        y={85.876}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M68.399 99.64v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456H70.33l-.468.456v.768H68.47v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
      />
      <rect width={20.8} height={20.8} x={79} y={63} fill="#26A69A" rx={10.4} />
      <path
        fill="#fff"
        d="m86.405 76.236 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356V78h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={18.72}
        y={131.042}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M29.827 144.434h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={10.994}
        y={164.917}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M18.283 178.681v-1.224h1.392v.804l.468.456H22.4l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236H19.52l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={191.362}
        y={117.076}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M202.923 125.104v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936h-1.464l3.372-6.972Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={157.785}
        y={169.968}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m164.202 178.044-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.688v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236H168.7l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={216.323}
        y={195.226}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m222.998 203.302-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608H229.8v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={163.431}
        y={211.866}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m169.947 219.942-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.142 5.688v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={237.123}
        y={229.101}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M244.463 242.865v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={268.026}
        y={141.442}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m274.56 149.518-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={249.307}
        y={106.676}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m256.01 114.752-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.071 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={328.051}
        y={110.836}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m335.909 118.912-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.738 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={344.988}
        y={238.312}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m351.229 246.388-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={180.963}
        y={273.376}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M192.524 281.404v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936h-1.464l3.372-6.972Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={222.563}
        y={270.701}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M229.719 284.465v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={142.631}
        y={259.113}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M149.94 272.877v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="m162.338 95.06 3.093 2.58-2.899 3.476-3.093-2.58zM156.54 102.011l3.093 2.58-2.899 3.476-3.093-2.58zM150.742 108.962l3.093 2.58-2.899 3.476-3.093-2.58zM144.945 115.913l3.093 2.58-2.899 3.476-3.093-2.58zM139.147 122.865l3.093 2.58-2.899 3.476-3.093-2.58z"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="m162.532 101.115 3.093 2.58-2.899 3.476-3.093-2.58zM156.734 108.066l3.093 2.58-2.899 3.476-3.093-2.58zM150.938 115.018l3.093 2.58-2.899 3.476-3.093-2.58zM145.14 121.969l3.093 2.58-2.899 3.476-3.093-2.58z"
      />
      <path
        fill="#000"
        d="m165.432 97.64 3.093 2.58-2.899 3.476-3.093-2.58zM159.634 104.591l3.093 2.58-2.899 3.476-3.093-2.58zM153.836 111.542l3.093 2.58-2.899 3.476-3.093-2.58zM148.038 118.493l3.093 2.58-2.899 3.476-3.093-2.58zM142.24 125.444l3.093 2.58-2.899 3.476-3.093-2.58zM159.44 98.535l3.093 2.58-2.9 3.476-3.093-2.58z"
      />
      <path
        fill="#000"
        d="m153.642 105.487 3.093 2.58-2.899 3.476-3.093-2.58zM147.844 112.438l3.093 2.58-2.899 3.476-3.093-2.58zM142.046 119.389l3.093 2.58-2.899 3.476-3.093-2.58z"
      />
      <path
        fill="#E2645A"
        d="m112.715 114.069 5.067 17.061 12.657-15.176-17.724-1.885Zm-1.739-1.45c.467-.559 1.132-.89 1.84-.805l18.562 2.015c1.447.132 1.973 1.835.947 3.066l-13.311 15.958c-.995 1.193-2.796 1.019-3.216-.344l-5.284-17.936a2.21 2.21 0 0 1 .462-1.954Z"
      />
    </svg>
  )
);
export default SvgIconSilverstoneMinimapMarkers;
