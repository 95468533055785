/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import { useState, useEffect, useRef } from 'react';

import { RaceStatus } from 'types';

const CountdownTimer = ({
  time,
  customText,
  status,
}: {
  time: string | number | Date;
  customText?: string;
  status?: RaceStatus;
}) => {
  const queryClient = useQueryClient();
  const targetTime = new Date(time).getTime();
  const isAboveMd = window.innerWidth > 768;

  const shouldRefetch = useRef(false);
  const [timeLeft, setTimeLeft] = useState<{
    minutes: number;
    seconds: number;
  } | null>(null);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = targetTime - now;

      if (difference <= 0) {
        shouldRefetch.current = true;
        return null;
      }

      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        minutes,
        seconds,
      };
    };

    const updateTimeLeft = () => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
    };

    const interval = setInterval(updateTimeLeft, 1000);
    return () => clearInterval(interval);
  }, [targetTime]);

  useEffect(() => {
    if (shouldRefetch.current) {
      queryClient.refetchQueries({
        queryKey: ['nextUpRacesByTierAndStatusQuery'],
      });
    }
  }, [queryClient]);

  if (!timeLeft || (status !== 'closed' && status !== 'qualifying'))
    return (
      <>
        {customText || 'Deadline:'}
        <strong>
          {new Date(time).toLocaleString([], {
            dateStyle: isAboveMd ? 'medium' : 'short',
            timeStyle: 'short',
          })}
        </strong>
      </>
    );

  return (
    <>
      Starts:{' '}
      <strong>
        <strong>
          {timeLeft.minutes} Mins
          <span
            style={{
              display: 'inline-block',
              textAlign: 'center',
              margin: '0 0.35rem',
            }}
          >
            {timeLeft.seconds}
          </span>
          Sec
        </strong>
      </strong>
    </>
  );
};

export default CountdownTimer;
