import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTiersCar = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={30}
    fill="none"
    viewBox="0 0 26 30"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeOpacity={0.95}
      strokeWidth={1.25}
      d="M8.385 5.571 1 2.143S1.4 13.5 2.385 17.57C3.369 21.643 5.123 23 7.462 25 9.8 27 13 29 13 29s3.2-2 5.538-4c2.339-2 4.093-3.357 5.077-7.429C24.6 13.5 25 2.143 25 2.143L17.615 5.57m-9.23 0L13 13l4.615-7.429m-9.23 0L13 1l4.615 4.571"
    />
  </svg>
));
export default SvgIconTiersCar;
