import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHexagonCheck = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    fill="none"
    viewBox="0 0 18 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M16.781 6.313c.282.437.282.968 0 1.406L13.5 13.312a1.39 1.39 0 0 1-1.219.688H5.687c-.5 0-.968-.25-1.218-.688L1.187 7.72a1.277 1.277 0 0 1 0-1.407L4.47.72C4.719.28 5.187 0 5.687 0h6.594c.5 0 .969.281 1.219.719l3.281 5.593ZM12.22 12.5l3.25-5.5-3.219-5.5h-6.5L2.5 7l3.25 5.5h6.469Zm-.75-8.031A.709.709 0 0 1 12.5 4.5a.684.684 0 0 1 0 1.031l-4 4a.622.622 0 0 1-.5.219.753.753 0 0 1-.563-.219l-2-2c-.312-.281-.312-.75 0-1.062.282-.282.75-.282 1.063 0l1.5 1.5 3.469-3.5Z"
    />
  </svg>
));
export default SvgIconHexagonCheck;
