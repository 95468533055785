import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTicket = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={41}
    fill="none"
    viewBox="0 0 41 41"
    aria-hidden="true"
    {...props}
  >
    <g filter="url(#a)">
      <path
        stroke="#D83832"
        strokeWidth={0.75}
        d="M15.09 35.744h3.274l3.275-3.275 1.638-1.637L35.83 18.278v-3.275l-.546-.546a1.544 1.544 0 0 1-2.183-2.184l-1.365-1.364a1.544 1.544 0 0 1-2.183-2.183L28.189 7.36a1.544 1.544 0 1 1-2.183-2.183l-.546-.546h-3.275L9.63 17.186l-1.638 1.637L4.718 22.1v3.275l.546.545a1.544 1.544 0 0 1 2.183 2.184l1.365 1.364a1.544 1.544 0 0 1 2.183 2.184l1.365 1.364a1.544 1.544 0 0 1 2.183 2.184l.546.545Z"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      fill="#D83832"
      fillRule="evenodd"
      d="M20.472 16.588c.641-.64 1.696-.647 2.328-.015l-.002-.003a1.652 1.652 0 0 1-.015 2.328l-.339.34 3.26.585.947-.948-2.382-.428a2.737 2.737 0 0 0-.69-2.657c-1.058-1.058-2.808-1.047-3.88.025l-6.033 6.033-1.307-.235 6.569-6.568c1.503-1.503 3.948-1.519 5.432-.035a3.775 3.775 0 0 1 1.105 2.536l2.133.384.236 1.307-1.77 1.77-4.566-.82-.605-.937 1.12-1.12a.551.551 0 0 0 .005-.776.55.55 0 0 0-.776.005l-4.96 4.96-1.307-.234 5.497-5.497Zm3.339 5.131.6.942-2.395 2.396-2.114-3.273-1.005 1.005-1.307-.235 2.506-2.505 2.113 3.272.798-.798-.692-1.071 1.496.267Z"
      clipRule="evenodd"
    />
    <defs>
      <filter
        id="a"
        width={39.859}
        height={39.863}
        x={0.344}
        y={0.257}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4373_1633"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4373_1633"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
));
export default SvgIconTicket;
