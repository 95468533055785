import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
  Flex,
  ModalBodyProps,
  ModalHeaderProps,
  ModalFooterProps,
  DrawerContentProps,
  HeadingProps,
  ModalCloseButtonProps,
} from '@chakra-ui/react';

type Props = {
  heading: React.ReactNode | string | undefined;
  headerContent?: React.ReactNode;
  drawerBody: React.ReactNode;
  drawerHeaderProps?: ModalHeaderProps;
  drawerBodyProps?: ModalBodyProps;
  drawerFooter?: React.ReactNode;
  drawerFooterProps?: ModalFooterProps;
  drawerContentProps?: DrawerContentProps;
  closeButtonProps?: ModalCloseButtonProps;
  headingProps?: HeadingProps;
  headingUppercase?: boolean;
};

const Drawer = ({
  heading,
  headerContent,
  drawerHeaderProps,
  isOpen = false,
  onClose,
  drawerBody,
  drawerBodyProps,
  drawerFooter,
  drawerFooterProps,
  drawerContentProps,
  closeButtonProps,
  headingProps,
  size = 'lg',
  headingUppercase = false,
  ...rest
}: Omit<DrawerProps, 'children'> & Props) => {
  return (
    <ChakraDrawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={size}
      {...rest}
    >
      <DrawerOverlay />
      <DrawerContent {...drawerContentProps}>
        <DrawerHeader {...drawerHeaderProps}>
          <Flex w="full" justifyContent="space-between">
            <Heading
              as="h1"
              size="md"
              textTransform={headingUppercase ? 'none' : 'uppercase'}
              display="flex"
              alignItems="center"
              {...headingProps}
            >
              {heading}
            </Heading>
            <DrawerCloseButton id="drawer-close-button" {...closeButtonProps} />
          </Flex>

          <Flex w="full">{headerContent}</Flex>
        </DrawerHeader>
        {/* TODO Progress */}

        <DrawerBody id="drawerBody" {...drawerBodyProps}>
          {drawerBody}
        </DrawerBody>

        <DrawerFooter {...drawerFooterProps}>{drawerFooter}</DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
