import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';
import { MutationTicketPaymentProcessSucceededEventArgs } from 'api/generated/graphql';

const useTicketPaymentProcessSucceededEvent = (rest?: {
  [key: string]: unknown;
}) => {
  const queryClient = useQueryClient();
  const { graphql, graphQLClient } = useGraphQLClient();

  const ticketPaymentProcessSucceededEventGQL = graphql(`
    mutation TicketPaymentProcessSucceededEvent(
      $succeededInput: PaymentProcessSucceededInput!
    ) {
      ticketPaymentProcessSucceededEvent(succeededInput: $succeededInput)
    }
  `);

  const ticketPaymentProcessSucceededEvent = useMutation({
    mutationKey: ['ticketPaymentProcessSucceededEvent'],
    mutationFn: ({
      succeededInput,
    }: MutationTicketPaymentProcessSucceededEventArgs) => {
      return graphQLClient.request(ticketPaymentProcessSucceededEventGQL, {
        succeededInput,
      });
    },
    onSuccess: () => {
      // Timeout is a workaround due to BE delay
      setTimeout(
        () => queryClient.invalidateQueries({ queryKey: ['ticketsAvailable'] }),
        2000
      );
    },
    ...rest,
  });

  return ticketPaymentProcessSucceededEvent;
};

export default useTicketPaymentProcessSucceededEvent;
