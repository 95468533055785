 import { createContext, useContext, useState } from 'react';

 interface LoadingTrailerContextType {
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   isLoadingTickets: boolean;
   setIsLoadingTickets: React.Dispatch<React.SetStateAction<boolean>>;
 }

 const LoadingTrailerContext = createContext<LoadingTrailerContextType>({} as LoadingTrailerContextType);

 export const LoadingTrailerProvider = ({ children }: any) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isLoadingTickets, setIsLoadingTickets] = useState(false);


   return (
     <LoadingTrailerContext.Provider value={{
       isLoading,
       setIsLoading,
       isLoadingTickets,
       setIsLoadingTickets,
     }}>
       {children}
     </LoadingTrailerContext.Provider>
   );
 };

 export const useLoadingTrailer = () => useContext(LoadingTrailerContext);