import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSpray = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="#fff"
    viewBox="0 0 32 32"
    aria-hidden="true"
    {...props}
  >
    <path d="M10 15c2.75 0 5 2.25 5 5 0 2.813-2.25 5-5 5-2.813 0-5-2.188-5-5 0-2.75 2.188-5 5-5Zm0 8a3 3 0 0 0 3-3c0-1.625-1.375-3-3-3a3 3 0 0 0-3 3c0 1.688 1.313 3 3 3Zm4-15c3.313 0 6 2.688 6 6v14c0 2.25-1.813 4-4 4H4c-2.25 0-4-1.75-4-4V14c0-3.313 2.688-6 6-6V2c0-1.063.875-2 2-2h4c1.063 0 2 .938 2 2v6ZM8 2v6h4V2H8Zm10 26V14c0-2.188-1.813-4-4-4H6c-2.25 0-4 1.813-4 4v14c0 1.125.875 2 2 2h12c1.063 0 2-.875 2-2Zm13-14c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-1v1c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-1h-1c-.563 0-1-.438-1-1 0-.5.438-1 1-1h1v-1c0-.5.438-1 1-1 .5 0 1 .5 1 1v1h1ZM27 4c-.563 0-1-.438-1-1 0-.5.438-1 1-1h1V1c0-.5.438-1 1-1 .5 0 1 .5 1 1v1h1c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-1v1c0 .563-.5 1-1 1-.563 0-1-.438-1-1V4h-1Zm-1 6v1c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-1h-1c-.563 0-1-.438-1-1 0-.5.438-1 1-1h1V7c0-.5.438-1 1-1 .5 0 1 .5 1 1v1h1c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-1Zm-4-6v1c0 .563-.5 1-1 1-.563 0-1-.438-1-1V4h-1c-.563 0-1-.438-1-1 0-.5.438-1 1-1h1V1c0-.5.438-1 1-1 .5 0 1 .5 1 1v1h1c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-1Z" />
  </svg>
));
export default SvgIconSpray;
