/* eslint-disable react-hooks/exhaustive-deps */
import { MarketplaceFilterProps } from 'hooks/useGetListedCars';
import { usePathname } from 'next/navigation';
import {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface ProviderProps {
  children: JSX.Element;
}

const mktplaceContext = createContext<MarketplaceContextProps>(
  {} as MarketplaceContextProps
);

export enum MarketplacePages {
  CARS = 'cars',
  LIVERIES = 'liveries',
  ACCESSORIES = 'accessories',
  TICKETS = 'tickets',
}

export enum MarketplaceOperations {
  BUY = 'buy',
  SELL = 'sell',
}

interface MarketplaceContextProps {
  page: MarketplacePages;
  changePage: (P: MarketplacePages) => void;

  operation: MarketplaceOperations;
  changeOperation: (p: MarketplaceOperations) => void;

  filter: MarketplaceFilterProps;
  setFilters: Dispatch<any>;

  sort: SortProps;
  changeSort: (a: SortProps) => void;
}

type SortProps = 'default' | 'highest' | 'lowest';

export const MarketPlaceProvider = ({ children }: ProviderProps) => {
  const [page, setPage] = useState<MarketplacePages>(MarketplacePages.CARS);
  const [operation, setOperation] = useState<MarketplaceOperations>(
    MarketplaceOperations.BUY
  );
  const pathname = usePathname();

  const changeOperation = (newOperation: MarketplaceOperations) => {
    setOperation(newOperation);
  };
  const changePage = (newPage: MarketplacePages) => {
    setPage(newPage);
  };

  const [sort, setSort] = useState<SortProps>('default');
  const changeSort = (newSort: SortProps) => {
    setSort(newSort);
  };

  const [filter, setFilters] = useState<MarketplaceFilterProps>(
    {} as MarketplaceFilterProps
  );

  useEffect(() => {
    if (pathname !== null) {
      const path = pathname.replace(/^\//, '').replace(/\/$/, '');
      const paths = path.split('/');
      if (paths[0] === 'marketplace' && paths.length > 2) {
        if (
          Object.values(MarketplacePages).includes(paths[1] as MarketplacePages)
        ) {
          console.log('Path', paths[1]);
          setPage(paths[1] as MarketplacePages);
        }
        setOperation(paths[2] as MarketplaceOperations);
      }
    }
  }, [pathname]);

  return (
    <mktplaceContext.Provider
      value={
        {
          page,
          changePage,
          operation,
          changeOperation,
          filter,
          setFilters,
          sort,
          changeSort,
        } as MarketplaceContextProps
      }
    >
      {children}
    </mktplaceContext.Provider>
  );
};

export const useMarketplace = () => {
  const context = useContext(mktplaceContext);
  if (!context) {
    throw new Error('Cannot call useMarketplace without a MarketplaceProvider');
  }
  return context;
};
