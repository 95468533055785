import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { IconTimerFill } from 'icons';
import { admissionTicketBgGradient } from 'utils/themeConstants';

interface RaceTimerProps {
  dateTime: string;
}

export const RaceTimer = ({ dateTime }: RaceTimerProps) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const updateCountdown = () => {
      const now = dayjs();
      const targetTime = dayjs(dateTime);
      const diff = targetTime.diff(now, 'second');

      if (diff <= 0) {
        setTimeLeft('0h 0m');
        return;
      }

      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff % 3600) / 60);
      setTimeLeft(`${hours}h ${minutes}m`);
    };

    updateCountdown();

    const intervalId = setInterval(updateCountdown, 60000);

    return () => clearInterval(intervalId);
  }, [dateTime]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      borderLeftRadius="0.25rem"
      gap="0.25rem"
      px="0.25rem"
      py="0.125rem"
      bg={admissionTicketBgGradient}
    >
      <IconTimerFill h="0.813rem" w="0.688rem" />
      <Text
        fontSize="0.875rem"
        fontStyle="italic"
        lineHeight="1.125rem"
        fontFamily="body"
        fontWeight={600}
      >
        {timeLeft}
      </Text>
    </Flex>
  );
};
