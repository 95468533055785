import { createContext, useContext } from 'react';

const context: {
  isDebug?: boolean;
  subdomain?: string;
} = {
  isDebug: false,
  subdomain: '',
};

export const RootContext = createContext(context);

export const useRootContext = () => useContext(RootContext);
