import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMontrealMinimapMarkers = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={376}
      height={375}
      fill="none"
      viewBox="0 0 376 375"
      aria-hidden="true"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#000"
          fillOpacity={0.2}
          d="M275.68 299.864c-19.064-13.495-27.945-19.541-33.093-24.899l-56.205-54.525-43.914-43.073c-2.858-2.858-1.786-4.518-.736-5.989 1.681-2.353.63-4.412-1.366-6.408-5.463-5.253-21.639-21.359-43.914-42.443-27.525-26.055-29.1-28.891-34.879-39.502l-.131-.241c-2.452-4.502-3.685-6.768-5.647-11-2.264-4.566-4.55-9.382-6.198-11.031-1.716-1.716-5.251-.813-8.72-.105-1.452.296-3.103.581-4.728.525-3.046-.105-4.307-2.626-4.832-5.043-.526-3.467 1.05-5.252 2.416-6.618 3.047-3.047 10.296-5.463 22.693-8.615 3.365-.855 7.324-1.226 11.66-1.156 14.197.231 31.205 3.51 33.619 3.993 3.152.63 4.938 1.365 5.989 7.038.84 4.539 3.361 6.444 4.727 6.83 5.008 1.575 15.372 4.692 19.646 5.988 2.551.773 5.253 1.89 8.825 2.1 5.042 0 7.669-.104 10.505.42 6.094.631 13.133 5.99 18.07 13.238 4.098 6.549 10.191 16.494 14.708 22.903 2.104 2.984 3.94 4.262 5.567 4.518 4.519.524 6.067-3.601 8.721-5.989 2.212-1.99 5.883-3.152 12.397-1.05 4.938 1.995 9.862 6.702 16.704 15.233 7.092 8.713 14.518 17.768 22.482 29.836 3.771 6.284 7.436 12.112 11.347 19.33 2.301 4.248 5.276 9.84 6.828 13.973 8.09 21.537 11.343 32.239 12.082 35.194 1.051 4.203-2.521 4.203-5.043 6.724-2.521 2.521-3.992 7.354-1.366 15.864 4.232 14.53 8.441 23.989 13.238 35.929 5.901 14.69 14.011 28.438 18.175 34.144 5.673 7.774 9.875 11.346 15.443 16.914 3.677 3.677 7.354 6.934 9.455 9.035 2.627 2.627 5.778 4.938 2.732 8.195-1.934 1.837-4.623 1.05-6.126-.598-2.24-2.24-5.416-5.994-8.116-9.185l-.256-.303c-4.623-5.463-6.998-7.29-9.56-8.72-3.012-1.68-14.498-8.194-33.199-21.431Z"
        />
        <path
          stroke="#fff"
          strokeOpacity={0.2}
          strokeWidth={10}
          d="M36.15 61.173c-3.047-.105-4.308-2.626-4.833-5.043-.526-3.467 1.05-5.252 2.416-6.618 3.047-3.047 10.296-5.463 22.693-8.615 3.365-.855 7.324-1.226 11.66-1.156 14.197.231 31.205 3.51 33.619 3.993 3.152.63 4.938 1.365 5.989 7.038.84 4.539 3.361 6.444 4.727 6.83 5.008 1.575 15.372 4.692 19.646 5.988 2.551.773 5.253 1.89 8.825 2.1 5.042 0 7.669-.104 10.505.42 6.094.631 13.133 5.99 18.07 13.238 4.098 6.549 10.191 16.494 14.708 22.903 2.104 2.984 3.94 4.262 5.567 4.518 4.519.524 6.067-3.601 8.721-5.989 2.212-1.99 5.883-3.152 12.397-1.05 4.938 1.995 9.862 6.702 16.704 15.233 7.092 8.713 14.518 17.768 22.482 29.836 3.771 6.284 7.435 12.112 11.347 19.33 2.301 4.248 5.276 9.84 6.828 13.973 8.09 21.537 11.343 32.239 12.082 35.194 1.051 4.203-2.521 4.203-5.043 6.724-2.521 2.521-3.992 7.354-1.366 15.864 4.232 14.53 8.441 23.989 13.238 35.929 5.901 14.69 14.011 28.438 18.175 34.144 5.673 7.774 9.875 11.346 15.443 16.914 3.677 3.677 7.354 6.934 9.455 9.035 2.627 2.627 5.778 4.938 2.732 8.195-1.934 1.837-4.623 1.05-6.126-.598-2.312-2.311-5.619-6.234-8.372-9.488-4.623-5.463-6.998-7.29-9.56-8.72-3.012-1.68-14.498-8.194-33.199-21.431-19.064-13.495-27.945-19.541-33.093-24.899l-56.205-54.525-43.914-43.073c-2.858-2.858-1.786-4.518-.736-5.989 1.681-2.353.63-4.412-1.366-6.408-5.463-5.253-21.639-21.359-43.914-42.443-27.525-26.055-29.1-28.891-34.879-39.502-2.54-4.664-3.782-6.934-5.778-11.241-2.264-4.566-4.55-9.382-6.198-11.031-1.716-1.716-5.251-.813-8.72-.105m-4.728.525c1.625.056 3.276-.229 4.728-.525m-4.728.525 4.728-.525"
        />
        <path
          stroke="#fff"
          strokeOpacity={0.95}
          strokeWidth={4}
          d="M36.15 61.173c-3.047-.105-4.308-2.626-4.833-5.043-.526-3.467 1.05-5.252 2.416-6.618 3.047-3.047 10.296-5.463 22.693-8.615 3.365-.855 7.324-1.226 11.66-1.156 14.197.231 31.205 3.51 33.619 3.993 3.152.63 4.938 1.365 5.989 7.038.84 4.539 3.361 6.444 4.727 6.83 5.008 1.575 15.372 4.692 19.646 5.988 2.551.773 5.253 1.89 8.825 2.1 5.042 0 7.669-.104 10.505.42 6.094.631 13.133 5.99 18.07 13.238 4.098 6.549 10.191 16.494 14.708 22.903 2.104 2.984 3.94 4.262 5.567 4.518 4.519.524 6.067-3.601 8.721-5.989 2.212-1.99 5.883-3.152 12.397-1.05 4.938 1.995 9.862 6.702 16.704 15.233 7.092 8.713 14.518 17.768 22.482 29.836 3.771 6.284 7.435 12.112 11.347 19.33 2.301 4.248 5.276 9.84 6.828 13.973 8.09 21.537 11.343 32.239 12.082 35.194 1.051 4.203-2.521 4.203-5.043 6.724-2.521 2.521-3.992 7.354-1.366 15.864 4.232 14.53 8.441 23.989 13.238 35.929 5.901 14.69 14.011 28.438 18.175 34.144 5.673 7.774 9.875 11.346 15.443 16.914 3.677 3.677 7.354 6.934 9.455 9.035 2.627 2.627 5.778 4.938 2.732 8.195-1.934 1.837-4.623 1.05-6.126-.598-2.312-2.311-5.619-6.234-8.372-9.488-4.623-5.463-6.998-7.29-9.56-8.72-3.012-1.68-14.498-8.194-33.199-21.431-19.064-13.495-27.945-19.541-33.093-24.899l-56.205-54.525-43.914-43.073c-2.858-2.858-1.786-4.518-.736-5.989 1.681-2.353.63-4.412-1.366-6.408-5.463-5.253-21.639-21.359-43.914-42.443-27.525-26.055-29.1-28.891-34.879-39.502-2.54-4.664-3.782-6.934-5.778-11.241-2.264-4.566-4.55-9.382-6.198-11.031-1.716-1.716-5.251-.813-8.72-.105m-4.728.525c1.625.056 3.276-.229 4.728-.525m-4.728.525 4.728-.525"
        />
        <rect
          width={20.8}
          height={20.8}
          x={55.102}
          y={48.435}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m65.33 56.51-1.836.949v-1.284l2.076-1.14h1.152v8.4H65.33V56.51Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={6.965}
          y={51.704}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m14.37 64.94 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188H14.37V64.94Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={110.075}
          y={29.418}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M117.262 43.181v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.649v2.34l-1.236 1.235h-3.528l-1.236-1.236Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={99.378}
          y={62.104}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M110.485 75.496h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164V70.18h-.06l-2.712 3.876v.276h2.772Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={171.882}
          y={60.618}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M179.171 74.382v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={179.607}
          y={111.133}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M186.917 124.897v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={214.671}
          y={79.635}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M226.232 87.663v-.227h-4.248v-1.2h5.688v1.463l-3.348 6.936h-1.464l3.372-6.972Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={285.986}
          y={199.98}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M293.142 213.744v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={245.277}
          y={220.78}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="M252.618 234.544v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={336.204}
          y={344.988}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m342.445 353.064-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={291.038}
          y={328.645}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m298.896 336.721-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.738 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={245.277}
          y={294.473}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m251.981 302.549-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.07 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={119.584}
          y={176.802}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m126.118 184.878-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
        />
        <rect
          width={20.8}
          height={20.8}
          x={145.732}
          y={147.385}
          fill="#26A69A"
          rx={10.4}
        />
        <path
          fill="#fff"
          d="m152.407 155.461-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
        />
        <path
          fill="#fff"
          fillOpacity={0.95}
          d="m94.165 91.938 2.848 2.848-3.2 3.2-2.849-2.848zM87.765 98.338l2.848 2.848-3.2 3.2-2.849-2.847zM81.364 104.738l2.848 2.848-3.2 3.2-2.848-2.848zM74.964 111.14l2.848 2.847-3.2 3.2-2.848-2.848zM68.564 117.54l2.848 2.848-3.2 3.2-2.849-2.848zM93.814 97.986l2.848 2.849-3.2 3.2-2.849-2.848zM87.413 104.386l2.848 2.848-3.2 3.2-2.848-2.848zM81.012 110.787l2.848 2.848-3.2 3.2-2.848-2.847zM74.611 117.187l2.848 2.848-3.2 3.2-2.848-2.848z"
        />
        <path
          fill="#000"
          d="m97.014 94.786 2.848 2.848-3.2 3.2-2.849-2.848zM90.613 101.186l2.848 2.848-3.2 3.2-2.848-2.848zM84.213 107.587l2.848 2.848-3.2 3.2-2.848-2.848zM77.811 113.987l2.848 2.848-3.2 3.2-2.848-2.848zM71.411 120.387l2.848 2.848-3.2 3.2-2.848-2.848z"
        />
        <path
          fill="#000"
          d="m90.965 95.138 2.848 2.848-3.2 3.2-2.849-2.848zM84.564 101.538l2.848 2.848-3.2 3.2-2.848-2.848zM78.164 107.938l2.848 2.848-3.2 3.2-2.848-2.848zM71.764 114.34l2.848 2.847-3.2 3.2-2.848-2.847z"
        />
        <path
          fill="#E2645A"
          d="m43.031 106.398 3.51 17.448 13.973-13.973-17.483-3.475Zm-1.6-1.601c.515-.515 1.207-.784 1.904-.636l18.305 3.68c1.43.262 1.8 2.005.667 3.138l-14.694 14.694c-1.099 1.099-2.877.763-3.173-.632l-3.645-18.339a2.21 2.21 0 0 1 .636-1.905Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.428 0h375v375h-375z" />
        </clipPath>
      </defs>
    </svg>
  )
);
export default SvgIconMontrealMinimapMarkers;
