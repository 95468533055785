import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPurchaseFail = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={52}
    height={36}
    fill="none"
    viewBox="0 0 52 36"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M18.5 9.25c0-.625.547-1.25 1.25-1.25.625 0 1.25.625 1.25 1.25v10c0 .703-.625 1.25-1.25 1.25-.703 0-1.328-.547-1.328-1.25l.078-10Zm-5 0c0-.625.547-1.25 1.25-1.25.625 0 1.25.625 1.25 1.25l-.078 10c0 .703-.547 1.25-1.172 1.25-.703 0-1.328-.547-1.328-1.25l.078-10ZM19.203 33c.156.313.235.625.39.86.313.624.782 1.171 1.25 1.64H5.923c-2.734 0-5-2.188-5-5v-25c0-2.734 2.265-5 5-5H31c2.656 0 4.922 2.266 4.922 5-.781 0-1.64.156-2.422.39V5.5C33.5 4.172 32.328 3 31 3H6C4.594 3 3.5 4.172 3.5 5.5v25C3.5 31.906 4.594 33 6 33h13.203Zm30.938-6.172c1.093 1.797 1.093 3.985.078 5.781-1.094 1.797-2.969 2.891-5.078 2.891H26.78c-2.11 0-3.984-1.094-5-2.89a5.503 5.503 0 0 1 0-5.782l9.14-15.86A5.878 5.878 0 0 1 36 8c2.031 0 3.984 1.172 4.922 2.969l9.219 15.86Zm-5 6.172c2.578 0 4.14-2.734 2.89-4.922l-9.218-15.86C38.266 11.204 37.172 10.5 36 10.5a3.29 3.29 0 0 0-2.89 1.719l-9.22 15.86C22.64 30.265 24.204 33 26.782 33h18.36ZM8.5 9.25C8.5 8.625 9.047 8 9.75 8c.625 0 1.25.625 1.25 1.25l-.078 10c0 .703-.547 1.25-1.172 1.25-.703 0-1.328-.547-1.328-1.25l.078-10ZM36 27.375c1.016 0 1.875.86 1.875 1.875 0 1.094-.86 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875 0-1.016.781-1.875 1.875-1.875Zm-.078-1.875c-.625 0-1.172-.547-1.172-1.25v-7.5c0-.625.547-1.25 1.25-1.25.625 0 1.25.625 1.25 1.25v7.5c0 .703-.625 1.25-1.328 1.25Z"
    />
  </svg>
));
export default SvgIconPurchaseFail;
