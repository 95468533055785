import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFeatureLock = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M16.25 7.5c1.367 0 2.5 1.133 2.5 2.5v7.5c0 1.367-1.133 2.5-2.5 2.5H3.75a2.492 2.492 0 0 1-2.5-2.5V10c0-1.367 1.094-2.5 2.5-2.5H5V5c0-2.734 2.227-5 5-5 2.734 0 5 2.227 5 5v2.5h1.25ZM6.875 5v2.5h6.25V5A3.134 3.134 0 0 0 10 1.875 3.11 3.11 0 0 0 6.875 5Zm10 12.5V10c0-.313-.313-.625-.625-.625H3.75a.642.642 0 0 0-.625.625v7.5c0 .352.273.625.625.625h12.5a.642.642 0 0 0 .625-.625Z"
    />
  </svg>
));
export default SvgIconFeatureLock;
