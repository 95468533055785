import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRetain = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M11.75 2C12.969 2 14 3.031 14 4.281v5.5c0 1.563-.75 3.031-2 3.969v1.5a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75v-1.906c0-.25.125-.5.344-.625 1.031-.656 1.656-1.75 1.656-2.969V8.375a2.1 2.1 0 0 1-.75.125c-.469 0-.906-.125-1.25-.375-.375.25-.813.375-1.25.375a2.22 2.22 0 0 1-.656-.094A2.328 2.328 0 0 1 6.375 10H4.75A.722.722 0 0 1 4 9.25a.74.74 0 0 1 .75-.75h1.625a.873.873 0 0 0 .875-.875.894.894 0 0 0-.875-.875H3.75c-.719 0-1.25.563-1.25 1.25v2.781c0 .375.156.75.469.969l1.062.844c.063.031.469.219.469.687v1.969a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75v-1.563l-.969-.75C1.375 12.438 1 11.626 1 10.781V8c0-.875.406-1.656 1.063-2.156C2.03 5.656 2 5.469 2 5.25v-2C2 2.031 3 1 4.25 1c.188 0 .375.031.563.094A2.284 2.284 0 0 1 6.75 0c.781 0 1.5.438 1.906 1.094A1.82 1.82 0 0 1 9.25 1c.781 0 1.5.438 1.906 1.094A1.82 1.82 0 0 1 11.75 2ZM6 2.25v3h.375c.375 0 .719.125 1.063.281.03-.093.062-.156.062-.281v-3a.76.76 0 0 0-.75-.75.74.74 0 0 0-.75.75Zm-2.5 3v.031c.063 0 .156-.031.25-.031H5v-2a.76.76 0 0 0-.75-.75.74.74 0 0 0-.75.75v2Zm6.5 1v-3a.76.76 0 0 0-.75-.75.74.74 0 0 0-.75.75v3c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75Zm1.75.75a.74.74 0 0 0 .75-.75v-2a.76.76 0 0 0-.75-.75.74.74 0 0 0-.75.75v2c0 .438.313.75.75.75Z"
    />
  </svg>
));
export default SvgIconRetain;
