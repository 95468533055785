import { Flex, Heading, Skeleton, Text } from '@chakra-ui/react';

import { CurrencyType, Races } from 'api/generated/graphql';
import { RCurrency, RRaceStatus } from 'components';
import RTiers from 'components/Tiers/Tiers';
import { RaceStatus, RaceTier } from 'types';
import { admissionTicketBgGradient } from 'utils/themeConstants';
import { RaceTimer } from './RaceTimer';
import { IconInfo } from 'icons';
import TournamentBadge from 'components/Tiers/TournamentBadge';

interface RaceHeaderProps {
  race?: Races;
  raceStatus: RaceStatus;
}

export const RaceHeader = ({ race, raceStatus }: RaceHeaderProps) => {
  return (
    <Flex
      w={{ base: 'full', md: '36rem' }}
      alignItems="center"
      justifyContent="space-between"
      flexDir="row"
      mb="0.5rem"
    >
      <Flex
        alignItems="center"
        fontSize="1.25rem"
        textTransform="capitalize"
        fontWeight={400}
      >
        {race?.tier?.name.toLowerCase() === 'tournament' ? (
          <TournamentBadge mr="0.75rem" />
        ) : (
          <RTiers
            tier={race?.tier?.name as RaceTier}
            tierType="rarity"
            mr="0.75rem"
          />
        )}
        <Flex flexDir="column" gap="0.25rem">
          <Heading fontSize="1.125rem" lineHeight="1.5rem" fontWeight={400}>
            {race?.track?.frontendName || (
              <Skeleton height="1rem" width="7rem" />
            )}
          </Heading>
          <Flex alignItems="center" gap="0.25rem" fontFamily="body">
            <Text
              fontSize="0.875rem"
              lineHeight="1.125rem"
              color="white.60"
              fontFamily="body"
              fontWeight={500}
            >
              LAPS
            </Text>
            <Flex
              w="1.438rem"
              h="0.875rem"
              mr="0.25rem"
              alignItems="center"
              justifyContent="center"
              borderLeftRadius="0.25rem"
              bg={admissionTicketBgGradient}
            >
              <Text
                fontSize="0.875rem"
                lineHeight="1.125rem"
                color="white.80"
                fontStyle="italic"
                textStyle="center"
                fontFamily="body"
                fontWeight={600}
              >
                {race?.laps || 1}
              </Text>
            </Flex>
            <RRaceStatus type="simple" status={raceStatus} />
            <RaceTimer dateTime={race?.startTime as string} />
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDir="column" alignItems="flex-end">
        <Text
          fontFamily="heading"
          textTransform="uppercase"
          fontSize="0.625rem"
          lineHeight="1.0.75rem"
          color="white.60"
          fontWeight={400}
        >
          Prize Pool
        </Text>
        <Flex alignItems="center" gap="0.25rem">
          <RCurrency
            amount={race?.prizePool || 0}
            currency={(race?.currency as CurrencyType) || CurrencyType.Usd}
            justifyContent="flex-end"
            fontWeight={500}
            hasCurrencyCode={false}
          />
          <IconInfo
            h="1rem"
            w="1rem"
            __css={{
              path: {
                fill: 'treasuredTeal.100',
              },
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
