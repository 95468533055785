import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSend = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={18}
    fill="none"
    viewBox="0 0 21 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M19.719 7.867c.468.196.742.664.781 1.133 0 .508-.313.977-.781 1.172l-17.5 7.5a1.04 1.04 0 0 1-.469.117c-.352 0-.703-.156-.938-.43-.351-.351-.43-.937-.195-1.406L4.25 9 .617 2.086A1.31 1.31 0 0 1 .813.64C1.046.367 1.397.25 1.75.25c.156 0 .313.04.469.117l17.5 7.5ZM3.079 2.79l2.773 5.274h9.609L3.078 2.788Zm2.773 7.149L3.078 15.25l12.383-5.313H5.85Z"
    />
  </svg>
));
export default SvgIconSend;
