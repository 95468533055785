import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHouse = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={21}
    fill="none"
    viewBox="0 0 24 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M22.898 9.984c.391.352.43.938.118 1.329a.974.974 0 0 1-1.328.117l-.938-.82v7.265A3.11 3.11 0 0 1 17.625 21H6.375c-1.758 0-3.125-1.367-3.125-3.125v-7.266l-.977.82a.974.974 0 0 1-1.328-.117.974.974 0 0 1 .118-1.328l10.312-8.75a.996.996 0 0 1 1.21 0l10.313 8.75ZM6.375 19.125H8.25v-5.938c0-.859.664-1.562 1.563-1.562h4.374c.86 0 1.563.703 1.563 1.563v5.937h1.875c.664 0 1.25-.547 1.25-1.25V9.008L12 3.188l-6.875 5.82v8.867c0 .703.547 1.25 1.25 1.25Zm3.75 0h3.75V13.5h-3.75v5.625Z"
    />
  </svg>
));
export default SvgIconHouse;
