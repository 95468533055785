import RImage from 'components/Image/Image';

type ImageProps = React.ComponentProps<typeof RImage>;

type AidrianaImageProps = {
  body?: 'full' | 'half';
} & ImageProps;

const fullBodySrc = [
  '/images/aidriana/RPM_Body1.png',
  '/images/aidriana/RPM_Body2.png',
  '/images/aidriana/RPM_Body3.png',
  '/images/aidriana/RPM_Body4.png',
  '/images/aidriana/RPM_Body5.png',
];

const halfBodySrc = [
  '/images/aidriana/RPM_Torso1.png',
  '/images/aidriana/RPM_Torso2.png',
  '/images/aidriana/RPM_Torso3.png',
  '/images/aidriana/RPM_Torso4.png',
  '/images/aidriana/RPM_Torso5.png',
];

export const getRandomAidrianaImg = (body: 'full' | 'half') => {
  if (body === 'full') {
    return fullBodySrc[Math.floor(Math.random() * fullBodySrc.length)];
  }

  return halfBodySrc[Math.floor(Math.random() * halfBodySrc.length)];
};

const AidrianaImage = ({
  body = 'half',
  alt,
  src,
  ...props
}: AidrianaImageProps) => {
  return (
    <RImage
      src={getRandomAidrianaImg(body)}
      objectFit="cover"
      alt="Aidriana"
      {...props}
    />
  );
};

export default AidrianaImage;
