import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCamera = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
    viewBox="0 0 20 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M5.625 10.25c0-2.383 1.953-4.375 4.375-4.375 2.383 0 4.375 1.992 4.375 4.375 0 2.422-1.992 4.375-4.375 4.375a4.368 4.368 0 0 1-4.375-4.375ZM10 7.75a2.492 2.492 0 0 0-2.5 2.5c0 1.406 1.094 2.5 2.5 2.5 1.367 0 2.5-1.094 2.5-2.5 0-1.367-1.133-2.5-2.5-2.5Zm4.14-6.21.43 1.21h2.93c1.367 0 2.5 1.133 2.5 2.5v10c0 1.406-1.133 2.5-2.5 2.5h-15a2.468 2.468 0 0 1-2.5-2.5v-10c0-1.367 1.094-2.5 2.5-2.5h2.89l.43-1.21A1.853 1.853 0 0 1 7.578.25h4.805c.82 0 1.523.547 1.758 1.29ZM2.5 4.624a.642.642 0 0 0-.625.625v10c0 .352.273.625.625.625h15a.642.642 0 0 0 .625-.625v-10c0-.313-.313-.625-.625-.625h-4.297l-.82-2.5H7.578l-.82 2.5H2.5Z"
    />
  </svg>
));
export default SvgIconCamera;
