import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPartFrontWing = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={17}
    fill="none"
    viewBox="0 0 20 17"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="M10.668 16v-.83a2 2 0 0 1 1.02-1.743L16 11m-5.332 5 7.576-2.502a1.64 1.64 0 0 0 1.109-1.327c.186-1.324-1.204-2.302-2.387-1.68L16 11m-5.332 5L.8 6.678m0 0V5.653a2 2 0 0 1 .998-1.73L6.948.94C8.254.184 9.843 1.32 9.551 2.8a1.756 1.756 0 0 1-1.246 1.35l-.73.206M.8 6.678l4.827-2.575a1.692 1.692 0 0 1 1.948.253m0 0L16 11"
    />
  </svg>
));
export default SvgIconPartFrontWing;
