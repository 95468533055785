import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCoins = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M6 4.063c3 0 6 .906 6 2.687v6.5C12 15.063 8.969 16 6 16c-3 0-6-.938-6-2.719v-6.5C0 4.97 3 4.063 6 4.063Zm4.5 9.187v-1.344c-1.156.563-2.844.844-4.5.844-1.688 0-3.375-.281-4.5-.844v1.344c0 .344 1.531 1.25 4.5 1.25 2.938 0 4.5-.906 4.5-1.25Zm0-3.25V8.656C9.344 9.22 7.656 9.5 6 9.5c-1.688 0-3.375-.281-4.5-.844V10c0 .344 1.531 1.25 4.5 1.25 2.938 0 4.5-.906 4.5-1.25ZM6 8c2.938 0 4.5-.906 4.5-1.25 0-.313-1.563-1.25-4.5-1.25-2.969 0-4.5.938-4.5 1.25C1.5 7.094 3.031 8 6 8Zm4-8c2.969 0 6 .969 6 2.75v6.5c0 1.594-2.313 2.25-2.5 2.25a.74.74 0 0 1-.75-.75.77.77 0 0 1 .5-.719c1.031-.344 1.25-.719 1.25-.781V7.906c-.125.032-.75.375-1.031.375a.74.74 0 0 1-.75-.75c0-.343.187-.625.5-.718C14.25 6.468 14.5 6.063 14.5 6V4.656c-.156.063-.75.344-1 .344-.438 0-.781-.344-.781-.75 0-.313.219-.594.531-.719 1.031-.344 1.25-.719 1.25-.781 0-.313-1.563-1.25-4.5-1.25-4.094 0-4.313 1.406-5.031 1.406-.5 0-.75-.437-.75-.75C4.219 1.25 6.406 0 10 0Z"
    />
  </svg>
));
export default SvgIconCoins;
