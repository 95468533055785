import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuningHigh = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    viewBox="0 0 10 10"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M7.5 3v3.5c0 .281-.234.5-.5.5a.494.494 0 0 1-.5-.5V4.219l-3.156 3.14A.485.485 0 0 1 3 7.5a.495.495 0 0 1-.36-.14.482.482 0 0 1 0-.704L5.782 3.5H3.5A.494.494 0 0 1 3 3c0-.266.219-.5.5-.5H7c.266 0 .5.234.5.5Z"
    />
  </svg>
));
export default SvgIconTuningHigh;
