import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUnlockBadge = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    aria-hidden="true"
    {...props}
  >
    <path fill="#fff" fillOpacity={0.1} d="M28 0H0v12l16 16h12V0Z" />
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M22 9c1.094 0 2 .906 2 2v6.031C24 18.125 23.094 19 22 19H12c-1.125 0-2-.875-2-2v-6c0-1.094.875-2 2-2h1V7c0-2.188 1.781-4 4-4a3.986 3.986 0 0 1 3.781 2.781c.125.407-.062.813-.468.938a.746.746 0 0 1-.938-.469C19.031 5.219 18.062 4.5 17 4.5A2.487 2.487 0 0 0 14.5 7v2H22Zm.5 8v-6c0-.25-.25-.5-.5-.5H12c-.281 0-.5.25-.5.5v6c0 .281.219.5.5.5h10c.25 0 .5-.219.5-.5Z"
    />
  </svg>
));
export default SvgIconUnlockBadge;
