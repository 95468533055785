import type { ReactNode } from 'react';
import '@rainbow-me/rainbowkit/styles.css';
import {
  RainbowKitProvider,
  getDefaultWallets,
  getDefaultConfig,
} from '@rainbow-me/rainbowkit';
import {
  braveWallet,
  phantomWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { WagmiProvider } from 'wagmi';
import { polygon, polygonAmoy } from 'viem/chains';

const { wallets } = getDefaultWallets({
  appName: 'Racino-External-Wallets',
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID || '',
});

const config = getDefaultConfig({
  appName: 'Racino-External-Wallets',
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID || '',
  chains: [process.env.NEXT_PUBLIC_ENV === 'prod' ? polygon : polygonAmoy],
  ssr: true,
  wallets: [
    ...wallets,
    {
      groupName: 'Other',
      wallets: [braveWallet, phantomWallet, walletConnectWallet],
    },
  ],
});

interface RainbowKitProviderProps {
  children: ReactNode;
}

const RainbowKitWagmiConfig = ({ children }: RainbowKitProviderProps) => {
  return (
    <WagmiProvider config={config}>
      <RainbowKitProvider>{children}</RainbowKitProvider>
    </WagmiProvider>
  );
};

export default RainbowKitWagmiConfig;
