import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUsdCircle = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#4FC5BA"
      d="M11.169 10.367c1.476.422 3.543 1.013 3.122 3.249-.17 1.18-1.182 1.94-2.616 2.109v1.35c0 .38-.338.675-.675.675a.666.666 0 0 1-.675-.675v-1.392h-.127a11.925 11.925 0 0 1-1.856-.549l-.295-.084a.707.707 0 0 1-.422-.886c.127-.338.548-.548.886-.422l.253.127c.548.168 1.14.38 1.645.464 1.055.168 2.405.042 2.574-.97.168-.886-.38-1.182-2.194-1.688l-.338-.127c-1.139-.295-3.164-.928-2.784-3.121.169-1.182 1.181-1.941 2.658-2.11V4.925c0-.338.295-.675.675-.675.338 0 .675.337.675.675v1.434h.084c.422.085.886.211 1.477.422.337.085.548.506.422.844a.707.707 0 0 1-.886.422c-.506-.169-.886-.295-1.224-.338-1.054-.168-2.404 0-2.573.97-.127.802.253 1.14 1.856 1.604l.338.084ZM11 .2C16.948.2 21.8 5.052 21.8 11c0 5.99-4.852 10.8-10.8 10.8C5.01 21.8.2 16.99.2 11 .2 5.052 5.01.2 11 .2Zm0 20.25c5.189 0 9.45-4.219 9.45-9.45 0-5.19-4.261-9.45-9.45-9.45-5.231 0-9.45 4.26-9.45 9.45A9.434 9.434 0 0 0 11 20.45Z"
    />
  </svg>
));
export default SvgIconUsdCircle;
