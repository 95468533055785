import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWheel = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M8.39 11.367c.313.508.86.899 1.485 1.055v2.539c-1.758-.234-3.203-1.29-3.906-2.813l2.422-.78ZM8 10v.156l-2.422.781C5.5 10.665 5.5 10.352 5.5 10a5.02 5.02 0 0 1 1.563-3.633l1.484 2.07C8.195 8.867 8 9.415 8 10Zm2.5-2.5a2.36 2.36 0 0 0-.937.195l-1.485-2.03C8.781 5.272 9.602 5 10.5 5c.86 0 1.68.273 2.383.625l-1.484 2.031c-.274-.117-.586-.156-.899-.156Zm1.914.938 1.485-2.07C14.875 7.304 15.5 8.593 15.5 10c0 .352-.039.664-.117.938l-2.422-.782c0-.039.039-.078.039-.156 0-.586-.234-1.133-.586-1.563ZM10.5 0c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10a9.97 9.97 0 0 1-10-10c0-5.508 4.453-10 10-10Zm0 16.25A6.243 6.243 0 0 0 16.75 10c0-3.438-2.813-6.25-6.25-6.25A6.243 6.243 0 0 0 4.25 10a6.219 6.219 0 0 0 6.25 6.25Zm.625-3.828c.586-.156 1.133-.547 1.445-1.055l2.422.781c-.703 1.524-2.148 2.579-3.867 2.813v-2.54ZM11.75 10c0 .703-.586 1.25-1.25 1.25-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25.664 0 1.25.586 1.25 1.25Z"
    />
  </svg>
));
export default SvgIconWheel;
