import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFlagSquares = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M19.438 0c.82 0 1.523.508 1.562 1.328v12.735c0 .546-.352 1.015-.898 1.21-1.915.743-3.477.977-4.844.977-3.164 0-5.39-1.328-8.555-1.328-1.289 0-2.734.234-4.492.86v3.593a.617.617 0 0 1-.625.625.642.642 0 0 1-.625-.625V.625C.96.312 1.273 0 1.586 0c.351 0 .625.313.625.625v.313C4.164.272 5.648.038 6.898.038c2.97 0 4.493 1.328 7.07 1.328 1.212 0 2.657-.273 4.65-1.172.273-.117.546-.195.82-.195Zm-.313 1.328c-.898.43-1.719.703-2.5.899v2.382a16.16 16.16 0 0 0 3.086-.703V1.562c0-.234-.234-.39-.586-.234Zm.586 6.016v-2.11c-1.172.352-2.188.547-3.086.625v2.188a16.16 16.16 0 0 0 3.086-.703Zm0 3.437v-2.11c-1.172.352-2.188.548-3.086.626v2.187a16.157 16.157 0 0 0 3.086-.703Zm-4.336-2.656V5.937c-1.445.04-2.656-.195-3.75-.43v2.15c1.172.273 2.344.468 3.75.468Zm-5-.781V5.195c-1.21-.273-2.383-.468-3.75-.468v2.187c1.367 0 2.578.195 3.75.43Zm0 1.289c-1.21-.274-2.383-.469-3.75-.469v2.188c1.367-.04 2.578.195 3.75.43v-2.15Zm1.25.312v2.11c1.172.273 2.344.508 3.75.508V9.374c-1.445.04-2.656-.195-3.75-.43Zm3.75-6.445a9.296 9.296 0 0 1-1.406.117 8.51 8.51 0 0 1-2.344-.312v1.914c1.172.273 2.344.468 3.75.468V2.5Zm-5-.547c-1.094-.351-2.148-.664-3.477-.664h-.273v2.188c1.367 0 2.578.195 3.75.43V1.952Zm-8.164 12.5a16.561 16.561 0 0 1 3.164-.703v-2.07c-.938.078-1.953.312-3.125.664v-1.29a14.263 14.263 0 0 1 3.125-.624V8.242c-.938.117-1.953.313-3.125.703V7.617a14.266 14.266 0 0 1 3.125-.625V4.805c-.938.117-1.953.312-3.125.703V4.18a14.266 14.266 0 0 1 3.125-.625V1.406c-.977.157-1.992.43-3.164.86v12.187Zm4.492-.781c1.367 0 2.54.234 3.672.508v-2.11c-1.21-.273-2.383-.508-3.75-.468v2.07h.078ZM15.258 15h.117v-2.188a15.12 15.12 0 0 1-3.75-.468v2.11c1.172.312 2.344.546 3.633.546Zm4.453-.938V12.07a19.274 19.274 0 0 1-3.086.664v2.188c1.64-.235 3.086-.781 3.086-.86Z"
    />
  </svg>
));
export default SvgIconFlagSquares;
