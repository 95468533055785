import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconVextReward = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    fill="none"
    viewBox="0 0 100 100"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#5B5BFF"
      fillRule="evenodd"
      d="M50 83.244c18.36 0 33.244-14.884 33.244-33.244S68.36 16.756 50 16.756 16.756 31.64 16.756 50 31.64 83.244 50 83.244ZM50 84c18.778 0 34-15.222 34-34S68.778 16 50 16 16 31.222 16 50s15.222 34 34 34Z"
      clipRule="evenodd"
    />
    <path
      fill="#5B5BFF"
      fillRule="evenodd"
      d="M90.246 50c0 22.2-17.025 40.198-38.025 40.198S14.196 72.2 14.196 50c0-22.2 17.025-40.197 38.025-40.197S90.246 27.799 90.246 50Zm.864 0c0 22.705-17.411 41.111-38.89 41.111-21.477 0-38.888-18.406-38.888-41.111S30.743 8.889 52.221 8.889 91.11 27.295 91.11 50ZM31.047 34.47l21.12 6.957 21.122-6.958L57.42 53.726l6.37 11.805-11.622-5.97-11.622 5.97 6.37-11.805-15.87-19.257Zm6.93 4.892 14.192 5.476 14.192-5.476-14.192 9.875-14.192-9.875Zm14.191 11.985L37.231 39.452l12.367 14.094-7.078 9.785 9.648-7.765 9.648 7.765-7.078-9.785 12.367-14.094-14.937 11.895Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconVextReward;
