import { Button, Flex, Text, ToastId, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Cars, RaceParticipants, RaceTuningInput } from "api/generated/graphql";
import { RInput, RLoadingAnimation, RModal, RToast } from "components";
import { useSaveSavedSetups } from "hooks";
import { useRef, useState } from "react";

type SavedSetupNameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  tuningSelections: RaceTuningInput;
  carId: string;
  userRaceData: RaceParticipants | undefined;
};

const SavedSetupCarModal = ({ isOpen, onClose, tuningSelections, carId, userRaceData }: SavedSetupNameModalProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const toastIdRef = useRef<ToastId[]>([]);
  const { mutateAsync: saveSavedSetups, isLoading: isLoadingSave } = useSaveSavedSetups({
    onSuccess: () => {
      const id = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="success"
            title={`Setup successfully saved`}
            onClose={() => {
              if (toastIdRef.current.includes(id)) {
                toast.close(id);
                toastIdRef.current = toastIdRef.current.filter(
                  (toastId) => toastId !== id
                );
              }
            }}
          />
        ),
      });
      toastIdRef.current.push(id);
      onClose();
    },
    onError: (error: unknown) => {
      const id = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={`Error saving setup`}
            description={`${
              error
            }`}
            onClose={() => {
              if (toastIdRef.current.includes(id)) {
                toast.close(id);
                toastIdRef.current = toastIdRef.current.filter(
                  (toastId) => toastId !== id
                );
              }
            }}
          />
        ),
      });
      toastIdRef.current.push(id);
      onClose();
    },
  });
  const [setupName, setSetupName] = useState("");

  const handleSave = async () => {
    if (!tuningSelections) return;

    const setupParts = {
      frontWingId: userRaceData?.frontWing?.id,
      frontWingTuning: tuningSelections?.frontWingTuning,
      rearWingId: userRaceData?.rearWing?.id,
      rearWingTuning: tuningSelections?.rearWingTuning,
      brakeCoolingId: userRaceData?.brakeCooling?.id,
      brakeCoolingTuning: tuningSelections?.brakeCoolingTuning,
      engineCoolingId: userRaceData?.engineCooling?.id,
      engineCoolingTuning: tuningSelections?.engineCoolingTuning,
      transmissionId: userRaceData?.transmissionRatio?.id,
      transmissionTuning: tuningSelections?.transmissionTuning,
      tyresId: userRaceData?.tyres?.id,
    };

    const filteredSetupParts = Object.fromEntries(
      Object.entries(setupParts).filter(([_, value]) => value !== undefined)
    );

    try {
      await saveSavedSetups({
        SavedSetupsInput: {
          name: setupName,
          carId,
          ...filteredSetupParts,
        },
      });
      queryClient.invalidateQueries({ queryKey: ['getSavedSetups'] });
      onClose();
    } catch (error) {
      console.error("Error saving setup:", error);
    }
  };

  return (
    <RModal
      title="New Saved Setup"
      isOpen={isOpen}
      onClose={onClose}
      variant="darkGrey"
      size="md"
      isCentered
    >
      {isLoadingSave ? (
        <Flex w="full" alignItems="center" justifyContent="center">
          <RLoadingAnimation />
        </Flex>
      ) : (
        <Flex
        flexDir="column"
        p="4"
      >
        <RInput
          value={setupName}
          onChange={(e) => setSetupName(e.target.value)}
          label="Name"
        />
        <Text
          color="paleSlate.100"
        >
          New Saved Setups only apply to the currently selected car within Car Setup
        </Text>
        <Button
          w="full"
          mt="2rem" 
          onClick={handleSave}
          isDisabled={!setupName.trim()}
        >
          SAVE
        </Button>
        </Flex>
      )}
    </RModal>
  )
};

export default SavedSetupCarModal;