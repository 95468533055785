import NextLink from 'next/link';
import {
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  Show,
  Box,
  Divider,
  Tooltip,
} from '@chakra-ui/react';

import routes from 'routes';
import {
  IconBarsFilter,
  IconChevron,
  IconCalendar,
  IconLeaderboard,
  IconCheckeredFlag,
  IconInfo,
} from 'icons';
import { useRouter } from 'next/router';
import { RTiers } from 'components';
import { CarTier } from 'types';
import { RFiltersDrawer } from 'components';
import { FilterType } from 'components/FiltersDrawer/FiltersDrawer';
import { useState } from 'react';

const RacesNav = ({
  activeItem = 'nextUp',
  filters = [],
  tier,
  raceStatus,
}: {
  activeItem?: 'nextUp' | 'results' | 'leaderboard';
  filters?: FilterType[];
  tier?: string;
  raceStatus?: string;
}) => {
  const { push, query } = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [carTier, setTier] = useState('any');
  const [period, setPeriod] = useState('daily');

  const changePeriod = (period: string) => {
    setPeriod(period);
    push(
      {
        query: {
          ...query,
          when: period,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const changeTier = (tier: string) => {
    setTier(tier);
    if (tier === 'any') {
      push(
        {
          query: {
            ...query,
            tier: '',
          },
        },
        undefined,
        {
          shallow: true,
        }
      );
    } else {
      push(
        {
          query: {
            ...query,
            tier: tier,
          },
        },
        undefined,
        {
          shallow: true,
        }
      );
    }
  };

  const dropdownIcon = <IconChevron transform="rotate(90deg)" />;

  const handleSetFilters = ({
    tier,
    raceStatus,
  }: {
    tier?: string;
    raceStatus?: string;
  }) => {
    push(
      {
        query: {
          ...query,
          tier: tier || undefined,
          raceStatus: raceStatus || undefined,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );

    if (!tier && !raceStatus)
      filters.map((filter) => filter.setSelectedValue(undefined));
    onClose();
  };

  const secondaryButtons = {
    nextUp: (
      <Flex justify="end" gap="4" alignItems="center">
        <Flex
          onClick={() => window.open("https://guide.racino.io/info/games/formula/tracks", "_blank")}
          cursor="pointer"
        >
          <IconInfo w="1.5rem" h="1.5rem" />
        </Flex>
        <Button variant="secondary-outlined" onClick={onOpen} id="filters-button">
          <Show above="lg">
            Filters <IconBarsFilter ml="2" />
          </Show>
          <Show below="lg">
            <IconBarsFilter />
          </Show>
        </Button>

        <RFiltersDrawer
          isOpen={isOpen}
          onClose={onClose}
          filters={filters}
          handleClear={() => {
            handleSetFilters({});
          }}
          handleApply={() => {
            handleSetFilters({
              tier,
              raceStatus,
            });
          }}
        />
      </Flex>
    ),
    leaderboard: (
      <Flex
        justify="end"
        gap="4"
        flexDir={{ base: 'column', md: 'row' }}
        mt={{ base: '4', md: '0' }}
      >
        <Flex
          alignItems={{ base: 'left', md: 'center' }}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Tooltip
            label={period === 'daily' ? 'Sum of 10 Best Points Finishes' : period === 'weekly' ? 'Sum of 7 Daily Leaderboard Points' : 'Sum of 8 Best Daily Leaderboard Points in a Season'}
          >
            <Flex mr="2">
              <IconInfo w="1.5rem" h="1.5rem" />
            </Flex>
          </Tooltip>
          <Text mr="2" as="label" htmlFor="tierFilter">
            Tier
          </Text>
          <Menu variant="secondary">
            <MenuButton id="tierFilter" w="full">
              <Flex
                align="center"
                gap={4}
                w={{ base: 'full', md: 150 }}
                justifyContent="space-between"
              >
                <RTiers
                  tierType="driver"
                  tier={carTier as CarTier}
                  size={{ sm: 'sm', md: 'md' }}
                />
                {carTier} {dropdownIcon}
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => changeTier('any')}>Any</MenuItem>
              <MenuItem onClick={() => changeTier('trial')} gap={2}>
                <RTiers
                  tierType="driver"
                  tier="trial"
                  size={{ sm: 'sm', md: 'md' }}
                />
                Trial
              </MenuItem>
              <MenuItem onClick={() => changeTier('bronze')} gap={2}>
                <RTiers
                  tierType="driver"
                  tier="bronze"
                  size={{ sm: 'sm', md: 'md' }}
                />
                Bronze
              </MenuItem>
              <MenuItem onClick={() => changeTier('silver')} gap={2}>
                <RTiers
                  tierType="driver"
                  tier="silver"
                  size={{ sm: 'sm', md: 'md' }}
                />
                Silver
              </MenuItem>
              <MenuItem onClick={() => changeTier('gold')} gap={2}>
                <RTiers
                  tierType="driver"
                  tier="gold"
                  size={{ sm: 'sm', md: 'md' }}
                />
                Gold
              </MenuItem>
              <MenuItem onClick={() => changeTier('platinum')} gap={2}>
                <RTiers
                  tierType="driver"
                  tier="platinum"
                  size={{ sm: 'sm', md: 'md' }}
                />
                Platinum
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Divider orientation="vertical" />
        <Flex
          alignItems={{ base: 'left', md: 'center' }}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Text mr="2" as="label" htmlFor="whenFilter">
            When
          </Text>
          <Menu variant="secondary">
            <MenuButton id="tierFilter" w="full">
              <Flex
                align="center"
                gap={4}
                w={{ base: 'full', md: 120 }}
                justifyContent={{ base: 'center', md: 'space-between' }}
                position="relative"
              >
                {period}
                <Box position="absolute" right={0}>
                  {dropdownIcon}
                </Box>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => changePeriod('daily')}>Daily</MenuItem>
              <MenuItem onClick={() => changePeriod('weekly')}>Weekly</MenuItem>
              <MenuItem onClick={() => changePeriod('season')}>Season</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    ),
    results: <></>,
  };

  return (
    <Flex
      w="full"
      justifyContent="space-between"
      flexDir={activeItem !== 'nextUp' ? { base: 'column', lg: 'row' } : 'row'}
    >
      <ButtonGroup as="nav">
        <Button
          variant={
            activeItem === 'nextUp' ? 'primary-ghost' : 'secondary-outlined'
          }
          as={NextLink}
          p="2"
          href={routes.races()}
          id="next-up-button"
        >
          <Show above="md">NEXT UP</Show>
          <Show below="md">
            <IconCalendar h="1.25rem" w="3.25rem" />
          </Show>
        </Button>
        <Button
          variant={
            activeItem === 'results' ? 'primary-ghost' : 'secondary-outlined'
          }
          as={NextLink}
          p="2"
          href={routes.raceResults()}
          id="results-button"
        >
          <Show above="md">Race Results</Show>
          <Show below="md">
            <IconCheckeredFlag h="1.25rem" w="3.25rem" />
          </Show>
        </Button>
        <Button
          variant={
            activeItem === 'leaderboard'
              ? 'primary-ghost'
              : 'secondary-outlined'
          }
          as={NextLink}
          p="2"
          href={routes.leaderboard()}
          id="leaderboard-button"
        >
          <Show above="md">Leaderboard</Show>
          <Show below="md">
            <IconLeaderboard h="1.25rem" w="3.25rem" />
          </Show>
        </Button>
      </ButtonGroup>

      <Show below="md">
        <Divider orientation="vertical" />
      </Show>
      {secondaryButtons[activeItem]}
    </Flex>
  );
};

export default RacesNav;
