import React, { createContext, useContext, useState, useEffect } from 'react';

interface VideoContextProps {
  isAllVideosPreloaded: boolean;
}

interface VideoProviderProps {
  children: React.ReactNode;
  videoUrls: string[];
}

const VideoContext = createContext<VideoContextProps>({
  isAllVideosPreloaded: false,
});

export const VideoProvider = ({ children, videoUrls }: VideoProviderProps) => {
  const [isAllVideosPreloaded, setIsAllVideosPreloaded] = useState(false);

  useEffect(() => {
    let preloadedCount = 0;

    const handleVideoLoaded = () => {
      preloadedCount++;
      if (preloadedCount === videoUrls.length) {
        setIsAllVideosPreloaded(true);
      }
    };

    const videoElements = videoUrls.map((url) => {
      const video = document.createElement('video');
      video.src = url;
      video.preload = 'auto';
      video.style.display = 'none';
      video.oncanplaythrough = handleVideoLoaded;
      document.body.appendChild(video);
      return video;
    });

    return () => {
      videoElements.forEach((video) => {
        document.body.removeChild(video);
      });
    };
  }, [videoUrls]);

  return (
    <VideoContext.Provider value={{ isAllVideosPreloaded }}>
      {children}
    </VideoContext.Provider>
  );
};

export const useVideo = () => useContext(VideoContext);
