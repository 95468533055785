import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPartBrakeCooling = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="M2.733 10.371c0 3.937 3.104 7.129 6.933 7.129 3.83 0 6.934-3.192 6.934-7.129 0-3.468-2.41-6.358-5.6-6.997M2.733 10.37c0-3.937 3.104-7.13 6.933-7.13.456 0 .902.046 1.334.133M2.733 10.37l-.47-.643a.474.474 0 0 0-.721-.051.474.474 0 0 1-.809-.265L.63 8.668a.84.84 0 0 1 .049-.42L2.502 3.56a2 2 0 0 1 .761-.943L5.94.848c.333-.22.748-.272 1.125-.143.134.046.275.07.416.07h1.332a2 2 0 0 1 1.082.317l.512.33c.65.417.901 1.243.594 1.952m.266 6.997c0 1.211-.835 2.194-1.866 2.194-1.031 0-1.867-.983-1.867-2.194 0-1.212.836-2.194 1.867-2.194 1.03 0 1.866.982 1.866 2.194Zm2.134 0c0 3.029-2.03 4.993-4.534 4.993-2.503 0-4.266-1.964-4.266-4.993 0-3.029 1.763-5.216 4.266-5.216 2.504 0 4.534 2.187 4.534 5.216Z"
    />
  </svg>
));
export default SvgIconPartBrakeCooling;
