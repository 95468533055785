import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWrench = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={30}
    fill="none"
    viewBox="0 0 40 30"
    aria-hidden="true"
    {...props}
  >
    <g fill="#fff" filter="url(#a)">
      <path fillOpacity={0.1} d="M0 0h40v30H0z" />
      <path d="M27.844 10.688c.406 1.624-.094 3.374-1.282 4.562a4.801 4.801 0 0 1-4.5 1.281l-5.718 5.719c-.5.5-1.157.75-1.813.75-.656 0-1.375-.281-1.812-.75A2.66 2.66 0 0 1 12 20.437c0-.656.25-1.312.719-1.812l5.75-5.719c-.375-1.625.093-3.312 1.281-4.469a4.764 4.764 0 0 1 4.563-1.28c.406.093.687.405.812.78.094.375 0 .782-.281 1.063L23 10.813l.156 1.03 1.032.157 1.843-1.844a1.15 1.15 0 0 1 1.063-.281c.375.125.687.406.75.813Zm-2.344 3.5c.656-.626 1-1.5 1-2.376l-1.813 1.813-2.812-.5-.469-2.813 1.75-1.812h-.031c-.844 0-1.688.344-2.313 1-.906.875-1.187 2.188-.718 3.406l.125.438-6.375 6.344c-.188.218-.313.468-.313.75 0 .312.125.562.313.75.375.406 1.094.406 1.469 0l6.374-6.344.438.156a3.23 3.23 0 0 0 3.375-.813ZM14.719 19.75c.281 0 .5.25.469.5 0 .25-.22.5-.47.5-.25 0-.468-.25-.468-.5 0-.281.188-.5.469-.5Z" />
    </g>
    <defs>
      <filter
        id="a"
        width={80}
        height={70}
        x={-20}
        y={-20}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={10} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_529_334122"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_529_334122"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
));
export default SvgIconWrench;
