/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';

import { RLoadingAnimation, RProgressIndicator } from 'components';
import { useWallet } from 'context';
import { useSessionData } from 'hooks';
import { IconWarning } from 'icons';
import { defaultGradient, modalHeaderGradient } from 'utils/themeConstants';
import routes from 'routes';

const ignoreWallet = true;

const WalletLoading = () => {
  const router = useRouter();
  const {
    isWalletCreated,
    isCreatingWallet,
    isOperationBlocked,
    handleSaveNewWallet,
    handleClearWalletData,
  } = useWallet();
  const { sessionData, isTokensUpdated, clearSessionData } = useSessionData();

  const isLoading = isCreatingWallet || !isTokensUpdated;

  const handleRetry = () => {
    handleSaveNewWallet({
      email: sessionData?.userAttributes?.email || '',
      accessToken: sessionData?.accessToken || '',
    });
  };

  const handleLogout = () => {
    clearSessionData();
    handleClearWalletData();
    router.push(routes.login());
  };

  useEffect(() => {
    if (
      !sessionData?.accessToken ||
      !sessionData?.userAttributes?.email ||
      !isTokensUpdated
    )
      return;

    handleSaveNewWallet({
      email: sessionData?.userAttributes?.email,
      accessToken: sessionData?.accessToken,
    });
  }, [sessionData, isTokensUpdated]);

  if (
    !isOperationBlocked ||
    ignoreWallet ||
    isWalletCreated ||
    !sessionData?.accessToken
  )
    return null;

  return (
    <Flex
      w="100%"
      h="100%"
      pos="absolute"
      alignItems="center"
      justifyContent="center"
      zIndex={10000}
      bg={defaultGradient(100)}
    >
      <Flex w="37rem" maxW="90%" flexDir="column" alignItems="center">
        <Flex w="full" py="0.625rem" px="1rem" bg={modalHeaderGradient}>
          <Heading fontSize="1.125rem" fontWeight={400}>
            {isLoading
              ? 'Authorizing request'
              : !isCreatingWallet && !isWalletCreated && 'Error'}
          </Heading>
        </Flex>
        {isLoading ? (
          <Flex flexDir="column" alignItems="center" justifyContent="center">
            <RLoadingAnimation />
            <Text textAlign="center" maxW="90%">
              We&apos;re connecting to your Wallet
            </Text>
          </Flex>
        ) : (
          !isCreatingWallet &&
          !isWalletCreated && (
            <Flex flexDir="column" alignItems="center" justifyContent="center">
              <RProgressIndicator Icon={IconWarning} />
              <Text textAlign="center" maxW="90%">
                There was an error creating your Wallet... please try again.
              </Text>
              <Button
                variant="primary"
                w="full"
                mt="1.5rem"
                onClick={handleRetry}
              >
                Retry
              </Button>
              <Button
                variant="secondary"
                w="full"
                mt="0.75rem"
                onClick={handleLogout}
              >
                Log Out
              </Button>
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  );
};

export default WalletLoading;
