import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconClock = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M12 6c0 3.328-2.695 6-6 6-3.328 0-6-2.672-6-6 0-1.242.375-2.39 1.031-3.352a.563.563 0 0 1 .774-.164c.258.188.328.54.14.797A4.91 4.91 0 0 0 1.102 6c0 2.695 2.203 4.875 4.875 4.875 2.695 0 4.898-2.18 4.898-4.875 0-2.484-1.898-4.547-4.313-4.828v1.266c0 .328-.257.562-.585.562a.555.555 0 0 1-.563-.563V.563A.57.57 0 0 1 5.977 0C9.305 0 12 2.695 12 6Zm-5.625-.398c.234.234.234.585 0 .796a.513.513 0 0 1-.773 0L3.727 4.523c-.235-.21-.235-.562 0-.796.21-.211.562-.211.773 0l1.875 1.875Z"
    />
  </svg>
));
export default SvgIconClock;
