import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

const useRenewTrialCar = () => {
  const queryClient = useQueryClient();
  const { graphql, graphQLClient } = useGraphQLClient();

  const renewTrialCarGQL = graphql(`
    mutation RenewTrialCar {
      renewTrialCar
    }
  `);

  const renewTrialCar = useMutation({
    mutationKey: ['renewTrialCar'],
    mutationFn: async () => {
      return graphQLClient.request(renewTrialCarGQL);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ticketsAvailable'],
      });
      queryClient.invalidateQueries({
        queryKey: ['garageQuery'],
      });
    },
  });

  return renewTrialCar;
};

export default useRenewTrialCar;
