import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHelpGarry = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path fill="#D83832" d="M1 1h23v9.857L10.857 24H1V1Z" />
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M7.832 8.188c.352 0 .606.292.606.624a.613.613 0 0 1-.606.626.613.613 0 0 1-.605-.626c0-.332.253-.624.605-.624Zm2.188 0c.351 0 .605.292.605.624 0 .352-.254.626-.605.626a.617.617 0 0 1-.625-.626.63.63 0 0 1 .625-.624Zm2.187 0c.352 0 .605.292.605.624a.613.613 0 0 1-.605.626.613.613 0 0 1-.605-.626c0-.332.253-.624.605-.624ZM13.73 4.75c.704 0 1.25.566 1.25 1.25v5.586c0 .664-.566 1.23-1.25 1.23h-2.812l-2.441 1.836c-.157.098-.372 0-.372-.195v-1.621H6.23a1.23 1.23 0 0 1-1.25-1.23V6c0-.684.547-1.25 1.25-1.25h7.5Zm.332 6.875V6a.336.336 0 0 0-.312-.313h-7.5A.321.321 0 0 0 5.937 6v5.625c0 .176.137.313.313.313h2.813v1.171l1.562-1.171h3.125a.321.321 0 0 0 .313-.313Z"
    />
  </svg>
));
export default SvgIconHelpGarry;
