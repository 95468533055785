import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRLogo = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={58}
    height={40}
    fill="none"
    viewBox="0 0 58 40"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#D83832"
      fillRule="evenodd"
      d="M40.242 9.427c3.926 0 7.177 3.201 7.177 7.064l.004-.016c0 3.865-3.216 7.063-7.178 7.063h-2.077l8.188 11.739h5.805l-5.984-8.58c.659-.37 1.282-.802 1.855-1.289 2.554-2.16 4.171-5.358 4.171-8.93 0-6.461-5.393-11.771-11.961-11.771H3.284L0 0h40.239c9.206 0 16.745 7.418 16.745 16.481 0 4.285-1.663 8.186-4.386 11.116l5.361 7.686-3.284 4.71h-10.84L32.36 23.548l1.017-4.707h6.861c1.315 0 2.394-1.06 2.394-2.354 0-1.292-1.073-2.353-2.393-2.353H9.852L6.568 9.427h33.674ZM34.75 35.29 33.707 40H19.028l3.552-16.449h-6.154l-3.287-4.707h15.35L24.937 35.29h4.892l1.161-5.384 3.76 5.384ZM55.28 0h.416l.938 2.194h.008L57.585 0H58v3h-.394V.81h-.009l-.813 1.851h-.291L55.682.81h-.008V3h-.394V0Zm-2.648.356h.904V3h.41V.356h.904V0h-2.218v.356Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconRLogo;
