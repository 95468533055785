import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconStopWatchTimer = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={12}
    fill="none"
    viewBox="0 0 10 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M5.563 7.5A.555.555 0 0 1 5 8.063a.542.542 0 0 1-.563-.563V5.062c0-.304.235-.562.563-.562a.57.57 0 0 1 .563.563V7.5ZM3.124.562c0-.304.234-.562.563-.562h2.624a.57.57 0 0 1 .563.563.555.555 0 0 1-.563.562h-.75v1.172a4.75 4.75 0 0 1 2.649 1.195l.703-.703c.211-.21.563-.21.774 0 .234.234.234.586 0 .797l-.727.727c.562.796.914 1.78.914 2.812C9.875 9.82 7.672 12 5 12a4.868 4.868 0 0 1-.563-9.703V1.125h-.75a.542.542 0 0 1-.562-.563ZM5 10.876a3.746 3.746 0 0 0 3.75-3.75A3.761 3.761 0 0 0 5 3.375a3.746 3.746 0 0 0-3.75 3.75A3.731 3.731 0 0 0 5 10.875Z"
    />
  </svg>
));
export default SvgIconStopWatchTimer;
