import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPlay = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={36}
    fill="none"
    viewBox="0 0 30 36"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M28.203 14.797C29.297 15.5 30 16.75 30 18c0 1.328-.703 2.578-1.797 3.203l-22.5 13.75a3.71 3.71 0 0 1-1.953.547C1.562 35.5 0 33.781 0 31.75V4.25C0 2.297 1.563.578 3.672.5c.703 0 1.406.234 2.031.547l22.5 13.75ZM3.75 31.75 26.25 18 3.672 4.328 3.75 31.75Z"
    />
  </svg>
));
export default SvgIconPlay;
