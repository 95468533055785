/* eslint-disable react-hooks/exhaustive-deps */
import { SVGProps, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  Box,
  Text,
  Heading,
  Link,
  ChakraComponent,
} from '@chakra-ui/react';
import {
  CrossmintEvent,
  CrossmintPaymentElement,
} from '@crossmint/client-sdk-react-ui';

import { colors } from 'utils/themeConstants';
import {
  IconDollar,
  IconVext,
  IconMatic,
  IconPurchaseFail,
  IconPlusCircle,
} from 'icons';
import {
  RCurrency,
  RLoadingAnimation,
  RToken,
  RTooltip,
  RLink,
} from 'components';
import { useGetWalletAddress } from 'hooks';
import { useWallet } from 'context';

interface CrossmintPaymentProps {
  SuccessIcon: ChakraComponent<
    (props: SVGProps<SVGSVGElement>) => React.JSX.Element
  >;
  type?: 'erc-721' | 'erc-1155';
  environment?: 'staging' | 'production';
  collectionId: string;
  projectId: string;
  bundleId?: string;
  totalPrice?: string;
  quantity?: number;
  isLoading?: boolean;
  onEvent?: (event: CrossmintEvent) => void;
  handleError?: () => void;
  handleContinue: () => void;
}

const CrossmintPayment = ({
  SuccessIcon,

  type = 'erc-1155',
  environment = 'staging',
  collectionId,
  projectId,
  bundleId,
  totalPrice = '1',
  quantity = 1,
  isLoading,
  onEvent,
  handleError,
  handleContinue,
}: CrossmintPaymentProps) => {
  const { setIsOperationBlocked } = useWallet();
  const [currentTab, setCurrentTab] = useState<'usd' | 'vext' | 'matic'>('usd');
  const [price, setPrice] = useState(0);
  const [purchaseStatus, setPurchaseStatus] = useState<
    'purchaseStarted' | 'purchaseSucceeded' | 'purchaseFailed'
  >();

  const handlePaymentError = () => {
    setPurchaseStatus(undefined);
    setCurrentTab('usd');
    if (handleError) handleError();
  };

  const { data: walletData, isLoading: isLoadingWalletData } =
    useGetWalletAddress();
  const { email = '', walletAddress = '' } = walletData
    ? walletData.getWalletAddress
    : {};

  useEffect(() => {
    setIsOperationBlocked(true);
  }, []);

  return (
    <Flex width="full" flexDirection="column" height="full">
      {purchaseStatus === 'purchaseStarted' || isLoading ? (
        <Flex
          alignItems="center"
          height="100%"
          justifyContent="center"
          flexDirection="column"
        >
          <RLoadingAnimation backgroundColor="eerieBlack.100" />

          <Text textAlign="center">Processing your order, hang on!!</Text>
          <Text textAlign="center">This may take a few minutes.</Text>
        </Flex>
      ) : (
        !purchaseStatus && (
          <ButtonGroup width="full" mb="4">
            <Button
              onClick={() => setCurrentTab('usd')}
              variant={
                currentTab === 'usd' ? 'primary-ghost' : 'secondary-outlined'
              }
              opacity={currentTab === 'usd' ? 1 : 0.6}
              flexGrow="1"
              leftIcon={<IconDollar opacity={0.8} />}
            >
              USD
            </Button>
            <Button
              onClick={() => setCurrentTab('vext')}
              variant={
                currentTab === 'vext' ? 'primary-ghost' : 'secondary-outlined'
              }
              opacity={currentTab === 'vext' ? 1 : 0.6}
              flexGrow="1"
              leftIcon={<IconVext opacity={0.8} />}
            >
              VEXT
            </Button>

            <Button
              onClick={() => setCurrentTab('matic')}
              variant={
                currentTab === 'matic' ? 'primary-ghost' : 'secondary-outlined'
              }
              opacity={currentTab === 'matic' ? 1 : 0.6}
              flexGrow="1"
              leftIcon={<IconMatic opacity={0.8} />}
            >
              MATIC
            </Button>
          </ButtonGroup>
        )
      )}

      <Box height="100%">
        {currentTab === 'usd' && (
          <Flex flexDirection="column" h="full">
            <Flex w="full" h="full" flexDirection="column" alignItems="center">
              {isLoadingWalletData ? (
                <Flex w="full" h="full" justify="center" alignItems="center">
                  <RLoadingAnimation backgroundColor="eerieBlack.100" />
                </Flex>
              ) : (
                <Flex
                  w="full"
                  flexDirection="column"
                  alignItems="center"
                  mb="4"
                  display={!purchaseStatus ? 'flex' : 'none'}
                >
                  <Text textTransform="uppercase" fontFamily="heading">
                    Cost
                  </Text>


                  <RCurrency amount={price.toString()} size="xl" />

                  <CrossmintPaymentElement
                    collectionId={collectionId}
                    projectId={projectId}
                    mintConfig={{
                      type,
                      bundleId,
                      totalPrice,
                      to: walletAddress,
                      quantity,
                    }}
                    environment={environment}
                    recipient={{
                      email,
                      wallet: walletAddress,
                    }}
                    currency="USD"
                    uiConfig={{
                      colors: {
                        background: 'rgba(0,0,0,0)',
                        backgroundSecondary: '#151517',
                        backgroundTertiary: colors.baltic[100],
                        textPrimary: '#FFF',
                        textSecondary: '#FFF',
                        accent: '#E2645A',
                        danger: '#E2645A',
                        textLink: '#E2645A',
                      },
                      fontSizeBase: '0.875rem',
                      spacingUnit: '0.5rem',
                      borderRadius: '0',
                      fontWeightPrimary: '400',
                      fontWeightSecondary: '500',
                    }}
                    onEvent={(event) => {
                      if (onEvent) onEvent(event);

                      const paymentStatus = event.type;
                      if (
                        paymentStatus === 'payment:process.started' ||
                        paymentStatus === 'order:process.started'
                      ) {
                        setPurchaseStatus('purchaseStarted');
                      }
                      if (paymentStatus === 'quote:status.changed') {
                        console.log(event);
                        setPrice(parseFloat(event.payload.totalPrice.amount));
                      }
                      if (
                        paymentStatus === 'payment:process.succeeded' ||
                        paymentStatus === 'order:process.finished' ||
                        paymentStatus === 'transaction:fulfillment.succeeded'
                      ) {
                        setPurchaseStatus('purchaseSucceeded');
                      }
                      if (
                        paymentStatus === 'payment:process.rejected' ||
                        paymentStatus === 'payment:process.canceled' ||
                        paymentStatus === 'payment:preparation.failed' ||
                        paymentStatus === 'transaction:fulfillment.failed'
                      ) {
                        setPurchaseStatus('purchaseFailed');
                      }
                    }}
                  />
                </Flex>
              )}

              {purchaseStatus === 'purchaseSucceeded' && (
                <Flex
                  flexDirection="column"
                  h="full"
                  justifyContent="space-between"
                >
                  <Flex
                    alignItems="center"
                    w="full"
                    height="full"
                    gap="4"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <SuccessIcon w="4.5rem" h="4.5rem" />

                    <Heading size="lg" textAlign="center">
                      Congratulations on your purchase!
                    </Heading>
                    <Text textAlign="center">
                      We have sent an email receipt to{' '}
                      <Link color={colors.fieryCoral[100]}>{email}</Link> for
                      your records.
                    </Text>
                    <Text textAlign="center">
                      Your purchase may take a few minutes to appear on the
                      platform.
                    </Text>
                  </Flex>
                  <Button
                    w="full"
                    textTransform="uppercase"
                    onClick={() => {
                      handleContinue();
                    }}
                  >
                    Continue
                  </Button>
                </Flex>
              )}

              {purchaseStatus === 'purchaseFailed' && (
                <Flex
                  flexDirection="column"
                  h="full"
                  justifyContent="space-between"
                >
                  <Flex
                    alignItems="center"
                    w="full"
                    height="full"
                    gap="4"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <IconPurchaseFail />

                    <Heading size="lg" textAlign="center">
                      Your Purchase Has Failed
                    </Heading>
                    <Text textAlign="center">
                      please try again or double check your details.
                    </Text>
                  </Flex>
                  <Button
                    w="full"
                    textTransform="uppercase"
                    onClick={() => {
                      handlePaymentError();
                    }}
                  >
                    Close
                  </Button>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}

        {!purchaseStatus && currentTab === 'vext' && (
          <Flex flexDirection="column" alignItems="center" height="full">
            <Text textTransform="uppercase" fontFamily="heading">
              Cost
            </Text>

            <RToken token="vext" amount="50" />

            <RCurrency amount="5.00" size="md" />

            <Flex width="full" flexDirection="column" mt="10">
              <Text textTransform="uppercase" fontFamily="heading" mb="2">
                Balance
              </Text>

              <Flex
                background="white.10"
                width="full"
                padding="3"
                alignItems="center"
              >
                <RToken token="vext" amount="10" size="sm" mr="auto" />

                <RTooltip label="Buy VEXT" shouldWrapChildren placement="top">
                  <RLink variant="unstyled">
                    <IconPlusCircle />
                  </RLink>
                </RTooltip>
              </Flex>
            </Flex>
          </Flex>
        )}

        {!purchaseStatus && currentTab === 'matic' && (
          <Flex flexDirection="column" alignItems="center" height="full">
            <Text textTransform="uppercase" fontFamily="heading">
              Cost
            </Text>

            <RToken token="matic" amount="9.3" />

            <RCurrency amount="5.00" size="md" />

            <Flex width="full" flexDirection="column" mt="10">
              <Text textTransform="uppercase" fontFamily="heading" mb="2">
                Balance
              </Text>

              <Flex
                background="white.10"
                width="full"
                padding="3"
                alignItems="center"
              >
                <RToken token="matic" amount="5" size="sm" mr="auto" />

                <RTooltip label="Buy MATIC" shouldWrapChildren placement="top">
                  <RLink variant="unstyled">
                    <IconPlusCircle />
                  </RLink>
                </RTooltip>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default CrossmintPayment;
