import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLink = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={21}
    fill="none"
    viewBox="0 0 26 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M23.92 1.68c1.094 1.093 1.68 2.5 1.68 3.945-.04 1.445-.586 2.89-1.68 3.984l-4.414 4.414c-1.055 1.094-2.461 1.641-3.985 1.641a5.557 5.557 0 0 1-3.945-1.64 5.46 5.46 0 0 1-1.68-3.985c0-.898.235-1.758.664-2.539h.079c.625 0 1.25.234 1.68.664a3.537 3.537 0 0 0-.548 1.914c0 .977.391 1.914 1.133 2.617 1.406 1.446 3.867 1.446 5.274 0l4.414-4.414a3.679 3.679 0 0 0 0-5.273 3.645 3.645 0 0 0-2.617-1.133c-.977 0-1.954.39-2.657 1.133L15.326 4.96c-.469-.43-1.016-.781-1.562-1.094l2.265-2.187C17.123.547 18.607 0 20.092.04c1.406 0 2.773.546 3.828 1.64ZM8.842 17.03l1.992-1.953c.469.43 1.016.781 1.563 1.094l-2.227 2.187A5.609 5.609 0 0 1 5.912 20c-1.367-.04-2.656-.625-3.672-1.64A5.595 5.595 0 0 1 .6 14.413c0-1.445.546-2.89 1.64-3.984l4.414-4.453a5.58 5.58 0 0 1 3.985-1.641c1.484 0 2.89.586 3.945 1.64a5.46 5.46 0 0 1 1.68 3.985c0 .898-.235 1.758-.664 2.539h-.079c-.625 0-1.25-.234-1.68-.625.352-.586.548-1.25.548-1.914 0-1.016-.391-1.953-1.133-2.656-1.406-1.446-3.867-1.446-5.274 0l-4.414 4.414c-1.484 1.445-1.484 3.828 0 5.312a3.766 3.766 0 0 0 2.618 1.094c.976 0 1.953-.39 2.656-1.094Z"
    />
  </svg>
));
export default SvgIconLink;
