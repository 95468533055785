import { BoxProps, Box, Heading, Flex, Image } from '@chakra-ui/react';

import { CurrencyType } from 'api/generated/graphql';
import { ticketCardBgGradient } from 'utils/themeConstants';
import { RCurrency } from 'components';

interface TicketCardProps extends BoxProps {
  fee?: number;
  currency: CurrencyType;
  cost?: number;
}

const TicketCard = ({
  fee = 0,
  currency,
  cost = 5,
  ...rest
}: TicketCardProps) => {
  return (
    <Box
      pos="relative"
      bg={ticketCardBgGradient}
      rotate="90deg"
      h="16.5rem"
      w="full"
      margin="auto"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Heading
        pos="absolute"
        fontWeight="light"
        top="1.25rem"
        left="1.25rem"
        as="h1"
        fontSize="lg"
        textTransform="uppercase"
        mb="3"
      >
        {fee} Ticket
      </Heading>
      {currency === CurrencyType.Vext ? (
        <Image
          src="/images/icons/icon-packs-generic-vext.svg"
          alt="Ticket Pack"
          w="15rem"
          h="15rem"
          zIndex={2}
        />
      ) : (
        <Image
          src="/images/icons/icon-packs-generic.svg"
          alt="Ticket Pack"
          w="10rem"
          h="10rem"
          zIndex={2}
        />
      )}

      <Flex
        pos="absolute"
        alignItems="flex-end"
        flexDir="column"
        bottom="1.25rem"
        right="1.25rem"
      >
        <Flex alignItems="center" justifyContent="center">
          <Heading
            fontWeight="light"
            as="h1"
            fontSize="xs"
            textTransform="uppercase"
            mr="0.25rem"
          >
            Cost
          </Heading>
          <Heading
            display="flex"
            alignItems="center"
            justifyContent="center"
            rounded="full"
            textTransform="lowercase"
            w="1rem"
            h="1rem"
            fontWeight="light"
            as="h1"
            fontSize="xs"
            border="0.125rem solid white"
          >
            i
          </Heading>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          color="treasuredTeal.100"
        >
          <RCurrency amount={String(cost * fee)} currency={currency} size="lg" />
        </Flex>
      </Flex>

      <Box
        pos="absolute"
        bottom="0.25rem"
        w={{ base: "20rem", md: "38rem" }}
        h="0.313rem"
        borderColor="white.80"
        borderBottomWidth="0.094rem"
        borderLeftWidth="0.094rem"
        borderRightWidth="0.094rem"
      />
    </Box>
  );
};

export default TicketCard;
