import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@chakra-ui/react';
import router from 'next/router';

import { Cars } from 'api/generated/graphql';
import { IconArrow } from 'icons';
import { RAlert, RImage, RLoadingAnimation } from 'components';
import RCarDetails from 'components/Garage/CarDetails';
import routes from 'routes';
import { Subset } from 'utils/helpers';
import { CarTier } from 'types';

interface NewCarProps {
  newCar?: Subset<Cars>;
  loadingText?: string;
}

const NewCar = ({
  newCar,
  loadingText = 'Thank you for your patience as we craft your high-performance racing machine, it will be delivered to your garage shortly.',
}: NewCarProps) => {
  if (!newCar)
    return (
      <Flex
        alignItems="center"
        height="90vh"
        justifyContent="center"
        flexDirection="column"
        gap="4"
        px="4"
      >
        <RLoadingAnimation backgroundColor="eerieBlack.100" />

        <Text>{loadingText}</Text>
        <Text>Closing this window won&apos;t interrupt the process.</Text>
      </Flex>
    );

  return (
    <Flex justifyContent="center" w="full">
      <Flex
        flexDirection="column"
        gap={{ base: '4', md: '8' }}
        pt={{ base: '6', md: '12' }}
        w="full"
        maxW="120rem"
        height="100vh"
        overflowX="hidden"
      >
        <Flex
          justify="space-between"
          alignItems="center"
          px={{ base: '4', md: '16' }}
        >
          <Flex alignItems="center" gap="4">
            <Button variant="unstyled" onClick={() => router.back()}>
              <IconArrow />
            </Button>

            <Flex gap="2" h="fit-content" fontSize="3xl" alignItems="center">
              New Car
            </Flex>
          </Flex>
        </Flex>

        <Box px={{ base: '0', lg: '10' }}>
          <Divider borderColor="zincDust.100" />
        </Box>

        <Flex
          h="full"
          flexDirection="column"
          gap="4"
          px={{ base: '4', md: '16' }}
          pb="8"
        >
          <Flex gap="4" justify="space-between" align="center" flexWrap="wrap">
            <Heading fontWeight="normal" size="3xl">
              {newCar?.name}
            </Heading>
            <RCarDetails
              tier={'bronze' as CarTier}
              earnings={0}
              wins={0}
              points={0}
              rarity={'common'}
            />
          </Flex>

          <Flex flexDirection="column" gap="2" w={{ base: 'full', lg: '66%' }}>
            <Heading
              as="h2"
              fontWeight="normal"
              size="lg"
              textTransform="uppercase"
              mb="1"
            >
              Characteristics
            </Heading>
            {newCar?.description
              ?.slice(0, 3) // assumes first 3 items in description array are for: car, frontwing, and rearwing
              .map((d) => (
                <RAlert key={d?.part} variant="success" description={d?.description} />
              ))}
          </Flex>
          <Spacer order="5" />
          <Button
            order={{ base: '6', lg: 'initial' }}
            w={{ base: 'full', lg: '14rem' }}
            size="lg"
            onClick={() => router.replace(routes.garage() + `/${newCar.id}`)}
            rightIcon={<IconArrow transform="rotate(180deg)" />}
          >
            View In Garage
          </Button>

          <Box
            position="relative"
            zIndex="-1"
            right={{ base: '4', lg: '-32' }}
            bottom={{ base: '0', lg: '16' }}
            w={{ base: '140vw', md: '100vw', lg: '80vw' }}
            maxW="60rem"
            marginX="auto"
          >
            <Flex justifyContent="center" h="fit-content" w="full">
              <RImage
                src={
                  newCar.livery
                    ? require(`public/images/cars/livery-${newCar.livery}.png`)
                    : ''
                }
                alt=""
                priority={true}
                zIndex="2"
              />

              <RImage
                src={require(`public/images/cars/livery-shadow.png`)}
                alt=""
                position="absolute"
                zIndex="1"
                filter="blur(2px)"
              />

              <RImage
                src={require(`public/images/home/car-platform.png`)}
                alt=""
                w="full"
                bottom="10%"
                transform="scale(140%)"
                position="absolute"
                zIndex="0"
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NewCar;
