import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSpielbergMinimap = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M49.892 80.527c-13.075 0-27.833 2.08-33.578 3.12-6.686.743-5.2 4.606-4.16 5.943 1.04 1.337 3.824 5.098 24.663 25.109 22.435 21.543 28.08 31.349 34.023 40.709 5.943 9.36 16.195 35.806 27.784 58.835 11.588 23.029 38.183 58.241 44.275 65.967 6.091 7.726 10.251 15.749 12.48 20.057 1.783 3.447 4.408 3.616 5.497 3.269 9.311-2.526 28.764-7.815 32.092-8.766 4.16-1.188 124.356-33.875 140.997-38.926 16.64-5.052 24.514-12.035 27.931-14.56 2.734-2.021 2.625-5.696 2.229-7.281-3.368-11.935-10.371-36.43-11.44-38.926-1.337-3.12-1.189-6.24-7.875-11.589-6.685-5.348-18.571-3.12-23.92-3.12s-98.207 1.783-115.888 2.823c-17.68 1.04-31.646 16.492-36.103 22.881-4.457 6.388-9.36 10.846-21.097 10.103-9.39-.595-15.006-7.677-16.641-11.143-5.051-8.964-15.986-28.348-19.314-34.172-4.16-7.28-4.361-14.754-1.337-20.801 4.011-8.023 7.577-10.515 13.52-12.331 5.349-1.635 7.131-1.294 11.143-.743 6.488.891 22.435 3.714 38.183 5.943 18.816 2.662 26.149.594 37.887-4.903 11.737-5.497 23.474-19.018 25.851-27.486.743-2.972-.736-5.343-2.525-7.132-2.08-2.08-5.2-2.228-5.795-2.228-.475 0-7.924-.298-11.588-.446-5.052 0-34.47-.892-54.081-3.566-19.612-2.674-68.196-12.777-78.001-14.412-9.806-1.634-18.87-2.228-35.212-2.228Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={10}
      d="M49.892 80.527c-13.075 0-27.833 2.08-33.578 3.12-6.686.743-5.2 4.606-4.16 5.943 1.04 1.337 3.824 5.098 24.663 25.109 22.435 21.543 28.08 31.349 34.023 40.709 5.943 9.36 16.195 35.806 27.784 58.835 11.588 23.029 38.183 58.241 44.275 65.967 6.091 7.726 10.251 15.749 12.48 20.057 1.783 3.447 4.408 3.616 5.497 3.269 9.311-2.526 28.764-7.815 32.092-8.766 4.16-1.188 124.356-33.875 140.997-38.926 16.64-5.052 24.514-12.035 27.931-14.56 2.734-2.021 2.625-5.696 2.229-7.281-3.368-11.935-10.371-36.43-11.44-38.926-1.337-3.12-1.189-6.24-7.875-11.589-6.685-5.348-18.571-3.12-23.92-3.12s-98.207 1.783-115.888 2.823c-17.68 1.04-31.646 16.492-36.103 22.881-4.457 6.388-9.36 10.846-21.097 10.103-9.39-.595-15.006-7.677-16.641-11.143-5.051-8.964-15.986-28.348-19.314-34.172-4.16-7.28-4.361-14.754-1.337-20.801 4.011-8.023 7.577-10.515 13.52-12.331 5.349-1.635 7.131-1.294 11.143-.743 6.488.891 22.435 3.714 38.183 5.943 18.816 2.662 26.149.594 37.887-4.903 11.737-5.497 23.474-19.018 25.851-27.486.743-2.972-.736-5.343-2.525-7.132-2.08-2.08-5.2-2.228-5.795-2.228-.475 0-7.924-.298-11.588-.446-5.052 0-34.47-.892-54.081-3.566-19.612-2.674-68.196-12.777-78.001-14.412-9.806-1.634-18.87-2.228-35.212-2.228Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={4}
      d="M49.892 80.527c-13.075 0-27.833 2.08-33.578 3.12-6.686.743-5.2 4.606-4.16 5.943 1.04 1.337 3.824 5.098 24.663 25.109 22.435 21.543 28.08 31.349 34.023 40.709 5.943 9.36 16.195 35.806 27.784 58.835 11.588 23.029 38.183 58.241 44.275 65.967 6.091 7.726 10.251 15.749 12.48 20.057 1.783 3.447 4.408 3.616 5.497 3.269 9.311-2.526 28.764-7.815 32.092-8.766 4.16-1.188 124.356-33.875 140.997-38.926 16.64-5.052 24.514-12.035 27.931-14.56 2.734-2.021 2.625-5.696 2.229-7.281-3.368-11.935-10.371-36.43-11.44-38.926-1.337-3.12-1.189-6.24-7.875-11.589-6.685-5.348-18.571-3.12-23.92-3.12s-98.207 1.783-115.888 2.823c-17.68 1.04-31.646 16.492-36.103 22.881-4.457 6.388-9.36 10.846-21.097 10.103-9.39-.595-15.006-7.677-16.641-11.143-5.051-8.964-15.986-28.348-19.314-34.172-4.16-7.28-4.361-14.754-1.337-20.801 4.011-8.023 7.577-10.515 13.52-12.331 5.349-1.635 7.131-1.294 11.143-.743 6.488.891 22.435 3.714 38.183 5.943 18.816 2.662 26.149.594 37.887-4.903 11.737-5.497 23.474-19.018 25.851-27.486.743-2.972-.736-5.343-2.525-7.132-2.08-2.08-5.2-2.228-5.795-2.228-.475 0-7.924-.298-11.588-.446-5.052 0-34.47-.892-54.081-3.566-19.612-2.674-68.196-12.777-78.001-14.412-9.806-1.634-18.87-2.228-35.212-2.228Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="m237.845 261.464 3.835-1.232 1.384 4.31-3.835 1.231zM240.613 270.082l3.835-1.232 1.384 4.31-3.835 1.231zM243.381 278.699l3.835-1.232 1.384 4.31-3.835 1.231zM246.149 287.317l3.835-1.232 1.384 4.31-3.835 1.231zM248.918 295.935l3.835-1.232 1.384 4.31-3.835 1.231z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="m243.063 264.541 3.835-1.232 1.384 4.31-3.835 1.231zM245.832 273.159l3.835-1.232 1.384 4.31-3.835 1.231zM248.601 281.777l3.835-1.232 1.384 4.31-3.835 1.231zM251.369 290.395l3.835-1.232 1.384 4.31-3.835 1.231z"
    />
    <path
      fill="#000"
      d="m241.68 260.232 3.835-1.232 1.384 4.31-3.835 1.231zM244.448 268.85l3.835-1.232 1.384 4.31-3.835 1.231zM247.216 277.468l3.835-1.232 1.384 4.31-3.835 1.231zM249.984 286.085l3.835-1.232 1.384 4.31-3.835 1.231zM252.753 294.703l3.835-1.232 1.384 4.31-3.835 1.231zM239.229 265.773l3.835-1.232 1.384 4.31-3.835 1.231zM241.997 274.391l3.835-1.232 1.384 4.31-3.835 1.231z"
    />
    <path
      fill="#000"
      d="m244.766 283.009 3.835-1.232 1.384 4.31-3.835 1.231zM247.534 291.626l3.835-1.232 1.384 4.31-3.835 1.231z"
    />
    <path
      fill="#E2645A"
      d="m210.73 261.847 17.124 4.852-6.044-18.814-11.08 13.962Zm-2.155.693c-.223-.694-.146-1.432.305-1.985l11.637-14.601c.887-1.151 2.607-.684 3.097.842l6.355 19.784c.475 1.48-.636 2.908-2.012 2.534l-17.979-5.138a2.213 2.213 0 0 1-1.403-1.436Z"
    />
  </svg>
));
export default SvgIconSpielbergMinimap;
