import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTransmissionRatio = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={20}
    fill="none"
    viewBox="0 0 22 20"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="M17.949 6.792c-.333.688-.539 1.647-.539 2.708 0 1.061.206 2.02.539 2.708m0-5.416c.324-.67.767-1.084 1.256-1.084C20.196 5.708 21 7.406 21 9.5s-.804 3.792-1.795 3.792c-.489 0-.932-.413-1.256-1.084m0-5.416h-2.846c-.708 0-1.283 1.212-1.283 2.708 0 1.496.575 2.708 1.283 2.708h2.846m-1.843-5.416C15.619 4.554 14.549 3 13.308 3c-.915 0-1.736.843-2.3 2.181m5.098 7.027C15.619 14.446 14.549 16 13.308 16c-1.7 0-3.077-2.91-3.077-6.5 0-1.658.293-3.17.777-4.319m.248 9.194c-.56 0-1.085-.224-1.538-.616m1.29-8.578c-.467.056-.905.268-1.29.602m0 0c-.453-.392-.978-.616-1.539-.616-1.699 0-3.076 2.061-3.076 4.604s1.377 4.604 3.076 4.604c.56 0 1.086-.224 1.539-.616m0-7.976c-.92.796-1.539 2.284-1.539 3.988 0 1.704.62 3.192 1.539 3.988m-3.077.074c-1.051 0-1.955-1.002-2.35-2.437m2.35-5.688c-1.051 0-1.955 1.003-2.35 2.438m0 0a6.135 6.135 0 0 0-.214 1.625c0 .578.076 1.127.213 1.625m0-3.25-2.52.27c-.426 0-.77.607-.77 1.355s.344 1.354.77 1.354l2.52.27"
    />
  </svg>
));
export default SvgIconTransmissionRatio;
