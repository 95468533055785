import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconShare = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M12.25 10a.76.76 0 0 1 .75.75v3.5c0 .969-.813 1.75-1.75 1.75h-9.5C.781 16 0 15.219 0 14.25v-9.5C0 3.812.781 3 1.75 3h3.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-3.5a.246.246 0 0 0-.25.25v9.5c0 .156.094.25.25.25h9.5c.125 0 .25-.094.25-.25v-3.5a.74.74 0 0 1 .75-.75Zm3-10a.76.76 0 0 1 .75.75V6a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75V2.562l-7.75 7.72a.622.622 0 0 1-.5.218c-.219 0-.406-.063-.531-.219-.313-.281-.313-.75 0-1.062L13.437 1.5H10a.722.722 0 0 1-.75-.75A.74.74 0 0 1 10 0h5.25Z"
    />
  </svg>
));
export default SvgIconShare;
