import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWindsock = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M5.167 3.75V2.031l3.125.625v10.977l-3.125.625V12.5h-2.5v6.875a.642.642 0 0 1-.625.625H.792a.617.617 0 0 1-.625-.625V1.25C.167.586.714 0 1.417 0c.664 0 1.25.586 1.25 1.25v2.5h2.5Zm0 6.875v-5h-2.5v5h2.5Zm5.625 2.5v-10l3.75.781v8.438l-3.75.781Zm8.593-8.242c.43.078.782.469.782.898v4.727c0 .43-.352.82-.782.86l-2.343.507v-7.5l2.343.508Z"
    />
  </svg>
));
export default SvgIconWindsock;
