import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCornerCheckmarkDark = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    aria-hidden="true"
    {...props}
  >
    <path fill="#000" fillOpacity={0.6} d="M28 4H0v12l16 16h12V4Z" />
    <path
      fill="#4FC5BA"
      d="m24 11.031-8.5 8.5a.622.622 0 0 1-.5.219c-.219 0-.406-.063-.531-.219l-4.5-4.5a.684.684 0 0 1 0-1.031A.684.684 0 0 1 11 14l4 3.969L22.969 10A.684.684 0 0 1 24 10a.684.684 0 0 1 0 1.031Z"
    />
  </svg>
));
export default SvgIconCornerCheckmarkDark;
