/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HStack, Heading, Stack, Text } from '@chakra-ui/react';
import { RInput } from 'components';
import { useFunds } from 'context/fundsContext';
import { useEffect, useState } from 'react';

export const TransferInput = ({
  value,
  onChange,
  setCanSubmit,
}: {
  value: string;
  onChange: (e: string) => void;
  setCanSubmit: (e: boolean) => void;
}) => {
  const { balanceData, actualTokenSelected } = useFunds();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [usdValueToSend, setUsdValueToSend] = useState<number>(0);

  console.log('BALANCE DATA: ', balanceData);
  useEffect(() => {
    const usdValue =
      parseFloat(value) *
      Number(balanceData[actualTokenSelected].convertionData.last);
    setUsdValueToSend(usdValue);
  }, [actualTokenSelected, balanceData, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    setErrorMessage(undefined);
    const typedValue = parseFloat(e.target.value);
    const balance = balanceData[actualTokenSelected].readableBalance;

    const invalidChars = /[^0-9.,]/;
    if (invalidChars.test(e.target.value)) {
      setErrorMessage('Invalid input');
      setCanSubmit(false);
      return;
    }

    if (isNaN(typedValue) && e.target.value !== '') {
      setErrorMessage('Invalid input');
      setCanSubmit(false);
      return;
    }

    if (!isNaN(typedValue) && typedValue > balance) {
      setErrorMessage('Insufficient balance');
      setCanSubmit(false);
      return;
    }

    setCanSubmit(true);
  };

  const handleMax = () => {
    const max = balanceData[actualTokenSelected].readableBalance;
    onChange(max.toString());
    setCanSubmit(true);
    setErrorMessage(undefined);
  };

  useEffect(() => {
    handleChange({
      target: { value: '' },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [actualTokenSelected]);

  return (
    <Stack>
      <HStack h="fit-content" align={'flex-start'}>
        <RInput
          label="You're sending"
          value={value}
          onChange={handleChange}
          errorMessage={errorMessage}
          isInvalid={errorMessage !== undefined}
        />
        <HStack minW="fit-content" h="54px">
          <Heading
            fontWeight={'normal'}
            fontSize={'14px'}
            color="whiteAlpha.700"
            w="fit-content"
          >
            {actualTokenSelected.toUpperCase()}
          </Heading>
          <Button minW="80px" variant="secondary" onClick={handleMax}>
            Max
          </Button>
        </HStack>
      </HStack>
      <Text fontSize={'14px'} color="whiteAlpha.800">
        {usdValueToSend > 0 && !errorMessage
          ? `Approx. ${usdValueToSend.toFixed(2)} USD`
          : ''}
      </Text>
    </Stack>
  );
};
