import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

const useScheduleCarUpgrade = () => {
  const queryClient = useQueryClient();
  const { graphql, graphQLClient } = useGraphQLClient();

  const scheduleCarUpgradeGQL = graphql(`
    mutation scheduleCarUpgrade($tokenId: String!) {
      scheduleCarUpgrade(tokenId: $tokenId) {
        id
        externalWalletAddress
        tokenId
        status
        referenceId
        createdAt
        source
      }
    }
  `);

  const ScheduleCarUpgrade = useMutation({
    mutationKey: ['scheduleCarUpgrade'],
    mutationFn: async ({ tokenId }: { tokenId: string }) => {
      return graphQLClient.request(scheduleCarUpgradeGQL, { tokenId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['garageQuery'] });
    },
  });

  return ScheduleCarUpgrade;
};

export default useScheduleCarUpgrade;
