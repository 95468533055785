import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWrenchConditions = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill="none"
    viewBox="0 0 14 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M12.883 2.883c.14.562.14 1.172-.047 1.804a3.741 3.741 0 0 1-3.024 2.79 3.476 3.476 0 0 1-1.78-.141l-4.173 4.172A1.79 1.79 0 0 1 2.664 12c-.422 0-.844-.164-1.148-.516a1.629 1.629 0 0 1 0-2.367l4.171-4.172c-.187-.562-.257-1.172-.117-1.78C5.781 1.71 6.906.491 8.36.14 8.968 0 9.577 0 10.14.14c.282.07.376.421.165.632L8.5 2.555v1.968h1.969L12.25 2.72a.374.374 0 0 1 .633.164ZM2.875 10.687a.555.555 0 0 0 .563-.562.57.57 0 0 0-.563-.563.555.555 0 0 0-.563.563c0 .328.235.563.563.563Z"
    />
  </svg>
));
export default SvgIconWrenchConditions;
