import { Text } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';

import { RAlert } from 'components';

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>
  );
};

const Fallback = () => {
  return (
    <RAlert
      title="Oops"
      description={
        <>
          <Text mb="4">
            Something went wrong. Our team has been notified and will
            investigate as soon as possible.
          </Text>
          <Text>
            If the problem persists, please reach out on our Discord server.
          </Text>
        </>
      }
      variant="error"
      margin="0 auto"
      maxWidth="36rem"
      marginTop="20vh"
    />
  );
};

export default ErrorBoundary;
