import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBarcelonaMinimap = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M56.304 234.301c1.782 3.447 6.091 5.646 9.063 5.794 88.252-.297 266.274-.832 274.118-.594 9.806.297 12.332-1.783 18.869-6.389 5.23-3.684 7.33-11.539 7.726-15.006.198-12.777.565-39.609.446-44.72-.149-6.389-2.823-11.292-6.24-14.709-3.715-3.715-9.361-5.646-15.601-7.726-4.636-1.546-10.258-3.163-17.383-5.646-4.613-1.608-10.069-3.666-13.223-4.308-8.023-1.635-12.777 3.12-15.154 6.388-2.378 3.269-4.458 10.549 0 17.235 2.674 4.16 7.428 5.943 11.143 6.834 2.971.713 10.449 3.467 13.817 4.754 15.1 6.472 18.126 19.15 18.126 25.258 0 5.2-.743 8.023-4.903 10.846-4.459 3.025-9.652.412-11.737-.892-4.755-2.971-22.732-13.571-26.446-15.748-4.309-2.526-78.893-46.95-85.876-50.664-5.794-3.293-9.955-4.754-13.52-5.349-3.269-.544-6.241.05-7.875.297-11.589 2.972-14.263 9.188-18.869 15.749-7.23 10.301-21.256 29.836-23.623 34.321-2.971 6.388-2.674 9.954-2.526 13.966.119 3.209-.049 5.992-.148 6.983-1.07 5.348-6.785 7.379-9.509 7.726-4.408.099-15.838.237-26.297 0-13.075-.298-22.88-5.646-27.338-8.469-4.457-2.823-25.406-17.383-26.743-18.126-1.288-.644-4.28-2.407-5.943-4.309-1.664-1.902-2.08-3.665-2.08-4.308-.297-4.903.891-6.278 2.674-8.172 2.377-2.526 5.943-2.823 8.023-2.823 2.972.149 43.681.149 47.841-.148 3.328-.238 6.24-1.189 7.28-1.635 9.509-3.268 13.718-9.827 14.709-11.589 1.323-2.352 2.355-5.578 2.52-7.516.004-.041.011-.08.016-.121.057-.437.17-2.974-.308-6.031-.743-4.755-4.932-8.212-7.428-9.658-2.823-1.634-6.538-1.634-7.429-1.634-.713 0-6.537.099-9.657.149H59.721c-8.766 0-17 1.607-22.286 3.565-8.023 2.972-16.344 6.835-24.218 17.086-7.56 9.842-6.24 20.949-4.605 26.149 3.714 10.252 10.102 15.452 16.937 18.424 4.011 1.584 12.153 4.902 16.789 6.685 5.794 2.229 9.1 4.803 11.142 9.361 1.932 4.308 1.486 4.754 1.486 8.914 0 2.975-.891 5.497 1.338 9.806Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={6}
      d="M56.304 234.301c1.782 3.447 6.091 5.646 9.063 5.794 88.252-.297 266.274-.832 274.118-.594 9.806.297 12.332-1.783 18.869-6.389 5.23-3.684 7.33-11.539 7.726-15.006.198-12.777.565-39.609.446-44.72-.149-6.389-2.823-11.292-6.24-14.709-3.715-3.715-9.361-5.646-15.601-7.726-4.636-1.546-10.258-3.163-17.383-5.646-4.613-1.608-10.069-3.666-13.223-4.308-8.023-1.635-12.777 3.12-15.154 6.388-2.378 3.269-4.458 10.549 0 17.235 2.674 4.16 7.428 5.943 11.143 6.834 2.971.713 10.449 3.467 13.817 4.754 15.1 6.472 18.126 19.15 18.126 25.258 0 5.2-.743 8.023-4.903 10.846-4.459 3.025-9.652.412-11.737-.892-4.755-2.971-22.732-13.571-26.446-15.748-4.309-2.526-78.893-46.95-85.876-50.664-5.794-3.293-9.955-4.754-13.52-5.349-3.269-.544-6.241.05-7.875.297-11.589 2.972-14.263 9.188-18.869 15.749-7.23 10.301-21.256 29.836-23.623 34.321-2.971 6.388-2.674 9.954-2.526 13.966.119 3.209-.049 5.992-.148 6.983-1.07 5.348-6.785 7.379-9.509 7.726-4.408.099-15.838.237-26.297 0-13.075-.298-22.88-5.646-27.338-8.469-4.457-2.823-25.406-17.383-26.743-18.126-1.288-.644-4.28-2.407-5.943-4.309-1.664-1.902-2.08-3.665-2.08-4.308-.297-4.903.891-6.278 2.674-8.172 2.377-2.526 5.943-2.823 8.023-2.823 2.972.149 43.681.149 47.841-.148 3.328-.238 6.24-1.189 7.28-1.635 9.509-3.268 13.718-9.827 14.709-11.589 1.337-2.377 2.377-5.645 2.525-7.577.05-.148.214-2.823-.297-6.091-.743-4.755-4.932-8.212-7.428-9.658-2.823-1.634-6.538-1.634-7.429-1.634-.713 0-6.537.099-9.657.149H59.721c-8.766 0-17 1.607-22.286 3.565-8.023 2.972-16.344 6.835-24.218 17.086-7.56 9.842-6.24 20.949-4.605 26.149 3.714 10.252 10.102 15.452 16.937 18.424 4.011 1.584 12.153 4.902 16.789 6.685 5.794 2.229 9.1 4.803 11.142 9.361 1.932 4.308 1.486 4.754 1.486 8.914 0 2.975-.891 5.497 1.338 9.806Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={2}
      d="M56.304 234.301c1.782 3.447 6.091 5.646 9.063 5.794 88.252-.297 266.274-.832 274.118-.594 9.806.297 12.332-1.783 18.869-6.389 5.23-3.684 7.33-11.539 7.726-15.006.198-12.777.565-39.609.446-44.72-.149-6.389-2.823-11.292-6.24-14.709-3.715-3.715-9.361-5.646-15.601-7.726-4.636-1.546-10.258-3.163-17.383-5.646-4.613-1.608-10.069-3.666-13.223-4.308-8.023-1.635-12.777 3.12-15.154 6.388-2.378 3.269-4.458 10.549 0 17.235 2.674 4.16 7.428 5.943 11.143 6.834 2.971.713 10.449 3.467 13.817 4.754 15.1 6.472 18.126 19.15 18.126 25.258 0 5.2-.743 8.023-4.903 10.846-4.459 3.025-9.652.412-11.737-.892-4.755-2.971-22.732-13.571-26.446-15.748-4.309-2.526-78.893-46.95-85.876-50.664-5.794-3.293-9.955-4.754-13.52-5.349-3.269-.544-6.241.05-7.875.297-11.589 2.972-14.263 9.188-18.869 15.749-7.23 10.301-21.256 29.836-23.623 34.321-2.971 6.388-2.674 9.954-2.526 13.966.119 3.209-.049 5.992-.148 6.983-1.07 5.348-6.785 7.379-9.509 7.726-4.408.099-15.838.237-26.297 0-13.075-.298-22.88-5.646-27.338-8.469-4.457-2.823-25.406-17.383-26.743-18.126-1.288-.644-4.28-2.407-5.943-4.309-1.664-1.902-2.08-3.665-2.08-4.308-.297-4.903.891-6.278 2.674-8.172 2.377-2.526 5.943-2.823 8.023-2.823 2.972.149 43.681.149 47.841-.148 3.328-.238 6.24-1.189 7.28-1.635 9.509-3.268 13.718-9.827 14.709-11.589 1.337-2.377 2.377-5.645 2.525-7.577.05-.148.214-2.823-.297-6.091-.743-4.755-4.932-8.212-7.428-9.658-2.823-1.634-6.538-1.634-7.429-1.634-.713 0-6.537.099-9.657.149H59.721c-8.766 0-17 1.607-22.286 3.565-8.023 2.972-16.344 6.835-24.218 17.086-7.56 9.842-6.24 20.949-4.605 26.149 3.714 10.252 10.102 15.452 16.937 18.424 4.011 1.584 12.153 4.902 16.789 6.685 5.794 2.229 9.1 4.803 11.142 9.361 1.932 4.308 1.486 4.754 1.486 8.914 0 2.975-.891 5.497 1.338 9.806Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M224.753 219.295h4.028v4.526h-4.028zM224.753 228.346h4.028v4.526h-4.028zM224.753 237.398h4.028v4.526h-4.028zM224.753 246.449h4.028v4.526h-4.028zM224.753 255.501h4.028v4.526h-4.028zM228.781 223.821h4.028v4.526h-4.028zM228.781 232.872h4.028v4.526h-4.028zM228.781 241.924h4.028v4.526h-4.028zM228.781 250.975h4.028v4.526h-4.028z"
    />
    <path
      fill="#000"
      d="M228.781 219.295h4.028v4.526h-4.028zM228.781 228.346h4.028v4.526h-4.028zM228.781 237.398h4.028v4.526h-4.028zM228.781 246.449h4.028v4.526h-4.028zM228.781 255.501h4.028v4.526h-4.028zM224.753 223.821h4.028v4.526h-4.028zM224.753 232.872h4.028v4.526h-4.028zM224.753 241.924h4.028v4.526h-4.028zM224.753 250.975h4.028v4.526h-4.028z"
    />
    <path
      fill="#E2645A"
      d="m198.82 265.677 14.82 9.856v-19.761l-14.82 9.905Zm-2.263 0c0-.729.299-1.408.896-1.797l15.546-10.342c1.196-.825 2.691.146 2.691 1.748v20.781c0 1.554-1.495 2.573-2.691 1.796l-15.546-10.39a2.21 2.21 0 0 1-.896-1.796Z"
    />
  </svg>
));
export default SvgIconBarcelonaMinimap;
