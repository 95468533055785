import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconEyeShow = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    fill="none"
    viewBox="0 0 18 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M17.875 6.469C16.187 2.625 12.812 0 9 0 5.156 0 1.781 2.625.094 6.469A1.587 1.587 0 0 0 0 7c0 .156.031.406.094.563C1.78 11.405 5.156 14 9 14c3.813 0 7.188-2.594 8.875-6.438.063-.156.125-.406.125-.562 0-.156-.063-.375-.125-.531ZM9 12.5c-3.125 0-6-2.094-7.5-5.469C3.031 3.625 5.875 1.5 9 1.5c3.094 0 5.969 2.125 7.469 5.5-1.532 3.406-4.375 5.5-7.469 5.5ZM9 3C6.781 3 5 4.813 5 7c0 2.219 1.781 4 4 4 2.188 0 4-1.781 4-3.969C13 4.813 11.187 3 9 3Zm0 6.5A2.468 2.468 0 0 1 6.5 7v-.063C6.656 7 6.813 7 7 7c1.094 0 2-.875 2-2 0-.156-.031-.313-.094-.469.031 0 .063-.031.094-.031 1.375 0 2.5 1.125 2.5 2.531C11.5 8.406 10.375 9.5 9 9.5Z"
    />
  </svg>
));
export default SvgIconEyeShow;
