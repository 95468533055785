import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconList = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={18}
    fill="none"
    viewBox="0 0 21 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M9.438 3.688c-.547 0-.938-.391-.938-.938 0-.508.39-.938.938-.938h10.624A.95.95 0 0 1 21 2.75c0 .547-.43.938-.938.938H9.438Zm10.624 10.624a.95.95 0 0 1 .938.938c0 .547-.43.938-.938.938H9.438c-.546 0-.937-.391-.937-.938 0-.508.39-.938.938-.938h10.624Zm0-6.25A.95.95 0 0 1 21 9c0 .547-.43.938-.938.938H9.438c-.546 0-.937-.391-.937-.938 0-.508.39-.938.938-.938h10.624Zm-17.5-.312c-.546 0-.937-.39-.937-.938 0-.507.39-.937.938-.937h.624V2.828c-.43.156-.898-.039-1.132-.43-.274-.43-.118-1.015.312-1.289l1.25-.703c.313-.195.664-.195.938 0a.898.898 0 0 1 .508.781v4.688h.625c.507 0 .937.352.937.938 0 .546-.43.937-.938.937H2.563Zm3.75 8.125c.508 0 .938.43.938.977 0 .507-.43.898-.938.898H1.899c-.351 0-.703-.234-.859-.586-.117-.352-.039-.781.274-1.016l3.242-2.93c.43-.35.468-.937.117-1.327a.767.767 0 0 0-.664-.352.864.864 0 0 0-.742.234l-.508.43a.974.974 0 0 1-1.328-.117.974.974 0 0 1 .117-1.328l.469-.43a2.804 2.804 0 0 1 2.109-.664 2.7 2.7 0 0 1 1.992 1.016 2.828 2.828 0 0 1-.312 3.945l-1.446 1.25h1.954Z"
    />
  </svg>
));
export default SvgIconList;
