import {
  Alert as ChakraAlert,
  AlertTitle,
  AlertDescription,
  Flex,
  Box,
  AlertProps,
} from '@chakra-ui/react';

import { IconInfo, IconWarningOutline, IconCheckmarkCircle } from 'icons';

const Alert = ({
  title,
  description,
  variant,
  ...rest
}: AlertProps & {
  title?: string;
  description: React.ReactNode;
  variant: 'info' | 'success' | 'warning' | 'error';
}) => {
  return (
    <ChakraAlert variant={variant} {...rest}>
      <Flex flexDirection="row" alignItems="center">
        <Box>
          {variant === 'success' && (
            <IconCheckmarkCircle
              transform="scale(140%)"
              ml="1"
              mr="6"
              __css={{
                path: { fill: 'mintie.100' },
              }}
            />
          )}

          {(variant === 'error' || variant === 'warning') && (
            <IconWarningOutline
              transform="scale(140%)"
              ml="1"
              mr="6"
              __css={{
                path: {
                  fill:
                    variant === 'error' ? 'fieryCoral.100' : 'alexandria.100',
                },
              }}
            />
          )}

          {variant === 'info' && (
            <IconInfo
              transform="scale(140%)"
              ml="1"
              mr="6"
              __css={{
                path: {
                  fill: 'iceColdStare.100',
                },
              }}
            />
          )}
        </Box>

        <Flex flexDirection="column" alignItems="flex-start">
          {title && <AlertTitle>{title}</AlertTitle>}
          {description && (
            <AlertDescription
              lineHeight="normal"
              fontSize={{ base: 'xs', md: 'sm' }}
            >
              {description}
            </AlertDescription>
          )}
        </Flex>
      </Flex>
    </ChakraAlert>
  );
};

export default Alert;
