import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSkinTone = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M17.543 2.961c1.281 1.282 1.281 3.377-.031 4.69l-3.562 3.596.406.406a.49.49 0 0 1 0 .688c-.094.125-.219.156-.344.156-.156 0-.28-.031-.374-.156l-5.5-5.503c-.187-.187-.187-.5 0-.719a.53.53 0 0 1 .72 0l.405.407 3.594-3.565c1.25-1.281 3.405-1.281 4.686 0Zm-.718 3.971a2.322 2.322 0 0 0 0-3.283c-.875-.875-2.406-.875-3.281 0L9.982 7.245l3.25 3.283 3.593-3.596ZM11.7 11.997a.56.56 0 0 1 .407.156c.187.188.187.5 0 .72l-3.468 3.439a2.265 2.265 0 0 1-1.688.719H5.764l-1.5.907c-.093.062-.187.062-.28.062a.47.47 0 0 1-.345-.125l-1-1c-.156-.157-.187-.407-.062-.626l.906-1.5v-1.22c0-.625.25-1.22.688-1.688L7.639 8.4a.475.475 0 0 1 .718 0 .49.49 0 0 1 0 .688l-2.905 2.908H11.7Zm-3.78 3.627 2.624-2.595H4.608a.99.99 0 0 0-.125.5v1.345a.59.59 0 0 1-.062.25l-.782 1.313.438.438 1.28-.782c.095-.063.188-.063.282-.063h1.312c.344 0 .688-.156.969-.406Z"
    />
  </svg>
));
export default SvgIconSkinTone;
