import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuningBraking = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={12}
    fill="none"
    viewBox="0 0 13 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M6.333 0c3.305 0 6 2.695 6 6 0 3.328-2.695 6-6 6-3.328 0-6-2.672-6-6 0-3.305 2.672-6 6-6ZM2.208 6c.188 0 .352-.14.375-.328.165-1.805 1.594-3.258 3.399-3.399.187-.023.351-.187.351-.375 0-.234-.187-.398-.398-.375a4.486 4.486 0 0 0-4.101 4.079c-.024.21.14.398.351.398h.023Zm4.125 2.25A2.25 2.25 0 1 0 4.083 6c0 1.242.985 2.25 2.25 2.25Zm0-3c.399 0 .75.352.75.75 0 .422-.351.75-.75.75a.74.74 0 0 1-.75-.75c0-.398.329-.75.75-.75Z"
    />
  </svg>
));
export default SvgIconTuningBraking;
