import {
  FormLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Box,
  InputGroup,
  InputRightElement,
  type InputProps as ChakraInputProps,
} from '@chakra-ui/react';
import { useState } from 'react';

import { IconEyeShow, IconEyeHidden } from 'icons';
import { defaultTransition } from 'utils/themeConstants';

type InputProps = {
  label: string;
  errorMessage?: string;
  helpText?: string;
  showHelpTextOnFocus?: boolean;
};

const RInputField = ({
  label,
  value,
  onChange,
  errorMessage,
  id,
  helpText,
  onFocus,
  onBlur,
  type = 'text',
  spellCheck = false,
  showHelpTextOnFocus = true,
  ...rest
}: ChakraInputProps & InputProps) => {
  const [showMaskedValue, setShowMaskedValue] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <Box w="full">
      <FormControl
        isInvalid={Boolean(errorMessage)}
        // isReadOnly={isReadOnly} // todo
        // isRequired={isRequired}
        variant="floating"
      >
        <InputGroup>
          <Input
            type={showMaskedValue ? 'text' : type}
            value={value}
            onChange={(event) => {
              if (onChange) {
                onChange(event);
              }
            }}
            id={id}
            placeholder=" " // Necessary for floating labels
            spellCheck={spellCheck}
            onFocus={(e) => {
              if (onFocus) {
                onFocus(e);
              }

              if (showHelpTextOnFocus) {
                setHasFocus(true);
              }
            }}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }

              if (showHelpTextOnFocus) {
                setHasFocus(false);
              }
            }}
            {...rest}
          />
          <FormLabel>{label}</FormLabel>
          {type === 'password' && (
            <InputRightElement
              className="visibilityToggle"
              onClick={() => setShowMaskedValue(!showMaskedValue)}
            >
              {showMaskedValue ? (
                <IconEyeShow opacity={0.4} />
              ) : (
                <IconEyeHidden
                  opacity={0.4}
                  transform="translateY(1px) translateX(1px)"
                />
              )}
            </InputRightElement>
          )}
        </InputGroup>

        <FormErrorMessage>{errorMessage}</FormErrorMessage>

        <FormHelperText
          transition={defaultTransition}
          opacity={hasFocus || !showHelpTextOnFocus ? 1 : 0}
        >
          {helpText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RInputField;
