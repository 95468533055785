import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDownload = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M12.5 9.125c.82 0 1.5.68 1.5 1.5V12.5c0 .844-.68 1.5-1.5 1.5h-9A1.48 1.48 0 0 1 2 12.5v-1.875c0-.82.656-1.5 1.5-1.5h1.242l1.125 1.125H3.5a.385.385 0 0 0-.375.375V12.5c0 .21.164.375.375.375h9a.385.385 0 0 0 .375-.375v-1.875a.403.403 0 0 0-.375-.375h-2.39l1.124-1.125H12.5Zm-.375 2.438a.555.555 0 0 1-.563.562.542.542 0 0 1-.562-.563c0-.304.234-.562.563-.562a.57.57 0 0 1 .562.563Zm-4.523-.915L4.39 7.461c-.235-.211-.235-.563 0-.797.21-.21.562-.21.796 0l2.25 2.25V2.563C7.438 2.257 7.673 2 8 2a.57.57 0 0 1 .563.563v6.351l2.226-2.226a.513.513 0 0 1 .774 0 .513.513 0 0 1 0 .773l-3.188 3.187a.466.466 0 0 1-.375.165c-.164 0-.305-.047-.398-.165Z"
    />
  </svg>
));
export default SvgIconDownload;
