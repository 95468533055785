import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLusailMinimap = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M50.494 69.83c-4.154-1.9-9.542-1.29-11.727-.746l-.016.004c-.213.064-6.64 1.97-13.068 4.308-6.155 2.238-9.15 3.422-9.479 3.553a.491.491 0 0 0-.06.03c-5.471 3.113-6.363 5.935-8.29 11.125-1.546 4.16.247 9.658 1.337 11.886 7.676 22.336 23.98 69.741 27.783 80.676 4.754 13.669 11.589 15.451 13.372 16.789 1.783 1.337 24.96 13.223 31.646 17.234 6.686 4.012 9.063 8.617 8.914 17.532-.119 7.131-8.27 12.777-12.331 14.709-6.934 3.863-16.343 9.211-23.03 12.777-5.2 2.773-9.656 4.16-16.342 10.252-4.912 4.474-4.359 13.124-3.418 16.491 4.606 12.332 15.155 11.886 19.464 11.886 4.308 0 282.735.594 292.987.594 10.251 0 13.372-5.2 14.412-6.388 1.04-1.189 2.971-3.566 3.714-10.103.594-5.23-5.596-14.858-8.766-19.018-16.195-22.88-43.086-64.332-46.949-69.829-3.863-5.498-5.052-7.578-5.795-14.115-.594-5.23 3.219-10.697 5.2-12.777 12.679-14.808 38.6-45.107 40.858-47.841 2.823-3.417 4.606-6.983 5.497-11.589.714-3.684-1.386-8.666-2.525-10.697-5.844-10.598-17.799-32.27-18.869-34.172-1.337-2.377-3.154-4.881-6.537-6.686-4.457-2.377-8.073-2.179-9.955-2.228h-22.286c-4.16 0-9.459.891-11.589 1.337-8.914 1.783-15.303 11.291-17.086 15.897-1.783 4.606-10.4 25.852-15.006 37.738-4.605 11.886-13.223 33.429-30.606 47.543-17.383 14.115-29.715 16.789-35.657 18.572-5.943 1.783-9.361 1.635-12.184 1.04-2.822-.594-5.348-1.931-7.725-5.497-2.802-4.203-1.783-8.469-1.486-10.697 2.873-10.599 8.587-32.033 9.063-33.578.594-1.931 1.783-6.091 1.189-10.846-.595-4.754-2.378-7.726-9.212-13.371-6.834-5.646-10.846-10.55-16.046-18.126-5.2-7.578-8.914-19.76-9.806-23.475-.891-3.715-1.931-9.212-4.903-12.777-2.971-3.566-8.171-6.092-13.223-6.092-4.041 0-7.825 2.08-9.211 3.12-5.111 3.21-7.082 10.747-7.429 14.115-3.17 25.059-9.568 75.386-9.806 76.218l-.006.021c-.311 1.091-1.818 6.369-7.868 6.516-4.874.119-7.677-3.714-8.47-5.646-6.14-17.779-18.303-54.25-19.76-57.052-1.93-3.714-3.714-6.24-8.914-8.617Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={6}
      d="M50.494 69.83c-4.16-1.902-9.558-1.288-11.737-.743 0 0-6.537 1.931-13.074 4.308-6.538 2.378-9.51 3.566-9.51 3.566-5.497 3.12-6.388 5.943-8.32 11.143-1.545 4.16.248 9.658 1.338 11.886 7.676 22.336 23.98 69.741 27.783 80.676 4.754 13.669 11.589 15.451 13.372 16.789 1.783 1.337 24.96 13.223 31.646 17.234 6.686 4.012 9.063 8.617 8.914 17.532-.119 7.131-8.27 12.777-12.331 14.709-6.934 3.863-16.343 9.211-23.03 12.777-5.2 2.773-9.656 4.16-16.342 10.252-4.912 4.474-4.359 13.124-3.418 16.491 4.606 12.332 15.155 11.886 19.464 11.886 4.308 0 282.735.594 292.987.594 10.251 0 13.372-5.2 14.412-6.388 1.04-1.189 2.971-3.566 3.714-10.103.594-5.23-5.596-14.858-8.766-19.018-16.195-22.88-43.086-64.332-46.949-69.829-3.863-5.498-5.052-7.578-5.795-14.115-.594-5.23 3.219-10.697 5.2-12.777 12.679-14.808 38.6-45.107 40.858-47.841 2.823-3.417 4.606-6.983 5.497-11.589.714-3.684-1.386-8.666-2.525-10.697-5.844-10.598-17.799-32.27-18.869-34.172-1.337-2.377-3.154-4.881-6.537-6.686-4.457-2.377-8.073-2.179-9.955-2.228h-22.286c-4.16 0-9.459.891-11.589 1.337-8.914 1.783-15.303 11.291-17.086 15.897-1.783 4.606-10.4 25.852-15.006 37.738-4.605 11.886-13.223 33.429-30.606 47.543-17.383 14.115-29.715 16.789-35.657 18.572-5.943 1.783-9.361 1.635-12.184 1.04-2.822-.594-5.348-1.931-7.725-5.497-2.802-4.203-1.783-8.469-1.486-10.697 2.873-10.599 8.587-32.033 9.063-33.578.594-1.931 1.783-6.091 1.189-10.846-.595-4.754-2.378-7.726-9.212-13.371-6.834-5.646-10.846-10.55-16.046-18.126-5.2-7.578-8.914-19.76-9.806-23.475-.891-3.715-1.931-9.212-4.903-12.777-2.971-3.566-8.171-6.092-13.223-6.092-4.041 0-7.825 2.08-9.211 3.12-5.111 3.21-7.082 10.747-7.429 14.115-3.17 25.059-9.568 75.386-9.806 76.218l-.006.021c-.311 1.091-1.818 6.369-7.868 6.516-4.874.119-7.677-3.714-8.47-5.646-6.14-17.779-18.303-54.25-19.76-57.052-1.93-3.714-3.714-6.24-8.914-8.617Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={2}
      d="M50.494 69.83c-4.16-1.902-9.558-1.288-11.737-.743 0 0-6.537 1.931-13.074 4.308-6.538 2.378-9.51 3.566-9.51 3.566-5.497 3.12-6.388 5.943-8.32 11.143-1.545 4.16.248 9.658 1.338 11.886 7.676 22.336 23.98 69.741 27.783 80.676 4.754 13.669 11.589 15.451 13.372 16.789 1.783 1.337 24.96 13.223 31.646 17.234 6.686 4.012 9.063 8.617 8.914 17.532-.119 7.131-8.27 12.777-12.331 14.709-6.934 3.863-16.343 9.211-23.03 12.777-5.2 2.773-9.656 4.16-16.342 10.252-4.912 4.474-4.359 13.124-3.418 16.491 4.606 12.332 15.155 11.886 19.464 11.886 4.308 0 282.735.594 292.987.594 10.251 0 13.372-5.2 14.412-6.388 1.04-1.189 2.971-3.566 3.714-10.103.594-5.23-5.596-14.858-8.766-19.018-16.195-22.88-43.086-64.332-46.949-69.829-3.863-5.498-5.052-7.578-5.795-14.115-.594-5.23 3.219-10.697 5.2-12.777 12.679-14.808 38.6-45.107 40.858-47.841 2.823-3.417 4.606-6.983 5.497-11.589.714-3.684-1.386-8.666-2.525-10.697-5.844-10.598-17.799-32.27-18.869-34.172-1.337-2.377-3.154-4.881-6.537-6.686-4.457-2.377-8.073-2.179-9.955-2.228h-22.286c-4.16 0-9.459.891-11.589 1.337-8.914 1.783-15.303 11.291-17.086 15.897-1.783 4.606-10.4 25.852-15.006 37.738-4.605 11.886-13.223 33.429-30.606 47.543-17.383 14.115-29.715 16.789-35.657 18.572-5.943 1.783-9.361 1.635-12.184 1.04-2.822-.594-5.348-1.931-7.725-5.497-2.802-4.203-1.783-8.469-1.486-10.697 2.873-10.599 8.587-32.033 9.063-33.578.594-1.931 1.783-6.091 1.189-10.846-.595-4.754-2.378-7.726-9.212-13.371-6.834-5.646-10.846-10.55-16.046-18.126-5.2-7.578-8.914-19.76-9.806-23.475-.891-3.715-1.931-9.212-4.903-12.777-2.971-3.566-8.171-6.092-13.223-6.092-4.041 0-7.825 2.08-9.211 3.12-5.111 3.21-7.082 10.747-7.429 14.115-3.17 25.059-9.568 75.386-9.806 76.218l-.006.021c-.311 1.091-1.818 6.369-7.868 6.516-4.874.119-7.677-3.714-8.47-5.646-6.14-17.779-18.303-54.25-19.76-57.052-1.93-3.714-3.714-6.24-8.914-8.617Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M147.777 278.13h4.028v4.526h-4.028zM147.777 287.181h4.028v4.526h-4.028zM147.777 296.233h4.028v4.526h-4.028zM147.777 305.285h4.028v4.526h-4.028zM147.777 314.336h4.028v4.526h-4.028zM151.805 282.656h4.028v4.526h-4.028zM151.805 291.707h4.028v4.526h-4.028zM151.805 300.759h4.028v4.526h-4.028zM151.805 309.81h4.028v4.526h-4.028z"
    />
    <path
      fill="#000"
      d="M151.805 278.13h4.028v4.526h-4.028zM151.805 287.181h4.028v4.526h-4.028zM151.805 296.233h4.028v4.526h-4.028zM151.805 305.285h4.028v4.526h-4.028zM151.805 314.336h4.028v4.526h-4.028zM147.777 282.656h4.028v4.526h-4.028zM147.777 291.707h4.028v4.526h-4.028zM147.777 300.759h4.028v4.526h-4.028zM147.777 309.81h4.028v4.526h-4.028z"
    />
    <path
      fill="#E2645A"
      d="m121.846 324.512 14.819 9.856v-19.761l-14.819 9.905Zm-2.264 0c0-.728.299-1.408.897-1.797l15.545-10.341c1.196-.826 2.691.145 2.691 1.748v20.78c0 1.554-1.495 2.574-2.691 1.797l-15.545-10.391a2.207 2.207 0 0 1-.897-1.796Z"
    />
  </svg>
));
export default SvgIconLusailMinimap;
