import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconThumbsUp = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    viewBox="0 0 10 10"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M2.5 8.5c0 .352-.293.625-.625.586H.625A.613.613 0 0 1 0 8.48V4.125A.63.63 0 0 1 .625 3.5h1.25a.63.63 0 0 1 .625.625V8.5ZM10 4.145c0 .488-.39.898-.879.918.156.175.254.39.254.644 0 .45-.332.82-.781.918a.795.795 0 0 1 .156.469c0 .43-.273.781-.664.898.02.059.039.137.039.215 0 .508-.43.918-.938.918H6.035A3.093 3.093 0 0 1 4.16 8.5l-.664-.488a.935.935 0 0 1-.371-.762V4.125c0 .02 0 0 0 0 0-.273.117-.527.352-.723l.312-.254C5.391 1.878 4.668.375 5.9.375c.605 0 .976.488.976.957 0 .293-.234 1.133-.684 1.875h2.872c.507 0 .937.41.937.938Z"
    />
  </svg>
));
export default SvgIconThumbsUp;
