import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHubRaces = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M10.938 12.5c0 .547-.43.938-.938.938-.547 0-.938-.391-.938-.938V8.437c0-.507.391-.937.938-.937a.95.95 0 0 1 .938.938V12.5ZM6.875.937c0-.507.39-.937.938-.937h4.375a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937h-1.25v1.953a7.916 7.916 0 0 1 4.415 1.992l1.171-1.172a.92.92 0 0 1 1.29 0c.39.391.39.977 0 1.329l-1.211 1.21c.937 1.329 1.523 2.97 1.523 4.688C18.125 16.367 14.453 20 10 20a8.119 8.119 0 0 1-8.125-8.125c0-4.14 3.125-7.578 7.188-8.047V1.875h-1.25c-.547 0-.938-.39-.938-.938ZM10 18.125a6.243 6.243 0 0 0 6.25-6.25c0-3.438-2.813-6.25-6.25-6.25a6.243 6.243 0 0 0-6.25 6.25 6.219 6.219 0 0 0 6.25 6.25Z"
    />
  </svg>
));
export default SvgIconHubRaces;
