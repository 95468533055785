import {
  Popover,
  PopoverTrigger,
  HStack,
  Heading,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import { RInput } from 'components';
import { useUserSettings } from 'hooks';
import { IconInfo, IconChevron, IconCopyPaste } from 'icons';
import { useState } from 'react';

export const WalletPopover = () => {
  const { getUserSettings } = useUserSettings();
  const { data } = getUserSettings;

  const [copied, setCopied] = useState<boolean>(false);

  const handleCopyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Falha ao copiar:', err);
      setCopied(false);
    }
  };

  return (
    <Popover
      arrowShadowColor="fieryCoral.100"
      offset={[0, 15]}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Button variant="ghost" w="fit-content" px={4}>
          <HStack gap={3} align={'center'} color={'whiteAlpha.600'}>
            <IconInfo opacity={0.6} h="12px" width="auto" />

            <Heading fontWeight={'normal'} fontSize={'14px'}>
              Wallet Address
            </Heading>
            <IconChevron
              style={{ transform: 'rotate(90deg)' }}
              opacity={0.6}
              h="12px"
              width="auto"
            />
          </HStack>
        </Button>
      </PopoverTrigger>

      <PopoverContent
        marginRight="1.5rem"
        border="0"
        borderRadius="0"
        borderTop="solid 1px"
        borderBottom="solid 1px"
        borderColor="fieryCoral.100"
        width={{ base: '100vw', md: '460px' }}
      >
        <PopoverArrow
          bg="fieryCoral.100"
          clipPath="polygon(100% 0%, 100% 0%, 0% 100%, 0% 0%)" // hides overflow that leaks through semi transparent background
          transform="scale(175%) rotate(45deg) !important"
        />
        <PopoverBody
          bg="blackAlpha.900"
          p="4"
          boxShadow="dark-lg"
          backdropFilter="blur(10px)"
          w="full"
        >
          <Heading fontWeight={'normal'} fontSize={'14px'} my={2}>
            Your wallet address is:
          </Heading>

          <Flex w="full" gap={4} h="fit-content">
            <RInput
              style={{
                color: copied ? 'var(--chakra-colors-jitteryJade-100)' : '',
              }}
              isDisabled
              label="Wallet Address"
              value={
                copied === true
                  ? 'Copied!'
                  : data?.getUser.walletAddress || 'Loading wallet...'
              }
            />
            <Button
              variant="secondary"
              h="54px"
              onClick={() =>
                handleCopyToClipboard(data?.getUser.walletAddress || '')
              }
            >
              <IconCopyPaste w={6} h={6} />
            </Button>
          </Flex>
          <Text fontSize="14px" lineHeight="18px" color="whiteAlpha.800">
            To send funds from elsewhere, simply copy your Racino wallet address
            and paste it in for your transaction.
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
