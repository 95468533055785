// this component enhances the Chakra Link component with:
// - automatic addition of the Link component from Next.js when passed an href
// - an isDisabled prop used for a11y (and styling via link theme)

import { Link, LinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';

const RLink = ({
  children,
  isDisabled,
  href,
  tabIndex = 0,
  ...rest
}: LinkProps & { isDisabled?: boolean }) => {
  const linkProps: LinkProps = {
    tabIndex,
  };

  if (href) {
    linkProps.href = href;
    linkProps.as = NextLink;
  }

  if (isDisabled) {
    linkProps['aria-disabled'] = 'true';
    linkProps.tabIndex = -1;
  }

  return (
    <Link {...linkProps} {...rest}>
      {children}
    </Link>
  );
};

export default RLink;
