import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUncheckGrey = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M12 0c1.094 0 2 .906 2 1.969v10c0 1.094-.906 2-2 2H2c-1.125 0-2-.906-2-2v-10C0 .875.875 0 2 0h10Zm.5 12V2c0-.25-.25-.5-.5-.5H2c-.281 0-.5.25-.5.5v10c0 .281.219.5.5.5h10c.25 0 .5-.219.5-.5Z"
    />
  </svg>
));
export default SvgIconUncheckGrey;
