import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLeaderboard = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M9.27 4.688c-.547 0-.938-.391-.938-.938 0-.508.39-.938.938-.938h10.625a.95.95 0 0 1 .937.938c0 .547-.43.938-.938.938H9.27Zm10.625 10.625a.95.95 0 0 1 .937.937c0 .547-.43.938-.938.938H9.27c-.547 0-.938-.391-.938-.938 0-.508.39-.938.938-.938h10.625Zm0-6.25a.95.95 0 0 1 .937.937c0 .547-.43.938-.938.938H9.27c-.547 0-.938-.391-.938-.938 0-.508.39-.938.938-.938h10.625Zm-17.5-.313c-.547 0-.938-.39-.938-.938 0-.507.39-.937.938-.937h.625V3.828c-.43.156-.899-.039-1.133-.43-.274-.43-.117-1.015.312-1.289l1.25-.703c.313-.195.664-.195.938 0a.898.898 0 0 1 .508.782v4.687h.625c.507 0 .937.352.937.938 0 .546-.43.937-.937.937H2.395Zm3.75 8.125c.507 0 .937.43.937.977 0 .507-.43.898-.937.898H1.73c-.351 0-.703-.234-.859-.586-.117-.352-.039-.781.274-1.016l3.242-2.93c.43-.35.468-.937.117-1.327a.767.767 0 0 0-.664-.352.864.864 0 0 0-.742.234l-.508.43a.974.974 0 0 1-1.328-.117.974.974 0 0 1 .117-1.328l.469-.43a2.804 2.804 0 0 1 2.109-.664 2.7 2.7 0 0 1 1.992 1.016 2.828 2.828 0 0 1-.312 3.945l-1.446 1.25h1.954Z"
    />
  </svg>
));
export default SvgIconLeaderboard;
