import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuningSlowTurns = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill="none"
    viewBox="0 0 14 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M7 .75a6.752 6.752 0 0 1 6.75 6.75 6.877 6.877 0 0 1-.914 3.398.777.777 0 0 1-.656.375H1.797a.711.711 0 0 1-.633-.375A6.64 6.64 0 0 1 .25 7.5 6.752 6.752 0 0 1 7 .75Zm1.29 9c.116-.21.21-.469.21-.75 0-.82-.68-1.5-1.5-1.5-.54 0-1.031.305-1.29.727L2.595 7.71c-.328-.047-.61.14-.656.445a.566.566 0 0 0 .468.656l3.14.54c.024.14.071.28.141.398H8.29Z"
    />
  </svg>
));
export default SvgIconTuningSlowTurns;
