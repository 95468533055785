import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGear = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.969 16c-1.75 0-2.375-.344-2.375-1.094v-1.25c-.5-.187-.938-.469-1.344-.75l-1.063.594a.881.881 0 0 1-.468.125c-1.156 0-2.406-2.875-2.406-3.375 0-.344.187-.688.5-.844l1.062-.625c0-.25-.031-.531-.031-.781 0-.281.031-.531.062-.781L.844 6.625c-.313-.156-.5-.5-.5-.844 0-.375 1.187-3.406 2.375-3.406.187 0 .344.063.5.156l1.062.594c.407-.281.844-.563 1.313-.75v-1.25C5.594.156 6.719 0 8 0c1.219 0 2.344.156 2.344 1.125v1.25c.469.188.906.438 1.312.75l1.063-.594a.917.917 0 0 1 .5-.125c1.125 0 2.375 2.875 2.375 3.375 0 .344-.188.688-.5.844l-1.063.625c.031.25.063.5.063.75s-.031.531-.063.781l1.063.625c.312.156.5.5.5.844 0 .375-1.188 3.375-2.375 3.375a.916.916 0 0 1-.5-.125l-1.063-.594c-.406.281-.844.563-1.312.75v1.25c.031.75-.594 1.094-2.375 1.094Zm-3.594-4.313c1.125.97 1.656 1.063 2.219 1.282v1.937c.468.063.906.094 1.375.094.468 0 .937-.031 1.406-.125l-.031-1.906c.531-.188 1.125-.344 2.219-1.281l1.687.968a6.742 6.742 0 0 0 1.344-2.406l-1.625-.938c.062-.468.125-.812.125-1.25 0-.312-.031-.718-.125-1.343l1.656-.938a7.491 7.491 0 0 0-1.406-2.406l-1.656.969c-1.094-.969-1.626-1.063-2.22-1.282V1.125A9.384 9.384 0 0 0 8 1.031c-.469 0-.938.032-1.438.125l.032 1.907c-.5.187-1.094.312-2.219 1.28l-1.656-.968a7.808 7.808 0 0 0-1.375 2.438L3 6.718c-.063.468-.125.812-.125 1.218 0 .344.031.75.125 1.344l-1.656.969a7.696 7.696 0 0 0 1.406 2.375l1.625-.938ZM8 11a3 3 0 0 1 0-6c1.625 0 3 1.344 3 3s-1.375 3-3 3Zm0-5c-1.125 0-2 .875-2 2 0 1.094.875 2 2 2 1.094 0 2-.906 2-2 0-1.125-.906-2-2-2Z"
    />
  </svg>
));
export default SvgIconGear;
