import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMiamiMinimap = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M70.253 267.284c.327-.002 155.86-.809 161.796-.891 5.943-.083 17.621-.208 31.052.148 16.789.446 21.622-5.794 26.001-11.886 6.834-9.508 4.506-24.762 3.12-33.132-.446-4.209-2.348-17.353-6.389-36.252-5.052-23.623-3.714-33.726-2.972-44.72a20.453 20.453 0 0 0-4.457-14.263c-3.139-3.912-7.476-5.586-11.143-5.943-5.2-.508-4.16-.149-10.103-3.12-3.268-1.932-7.28-4.755-11.291-6.983-5.171-2.873-11.143-5.498-16.195-5.052-9.136.806-17.334 16.096-20.355 24.515-9.36 21.394-17.977 29.863-38.332 41.006-6.89 3.772-21.129 8.263-30.606 9.806-19.265 3.136-40.412 2.526-56.458 3.269-29.269 1.355-57.795-.446-64.63-.743-6.834-.297-3.12-7.875-1.485-10.252 1.634-2.377 3.714-7.726 4.16-13.371.357-4.517 2.922-8.122 4.16-9.361 2.13-1.931 7.726-8.617 13.669-14.411 10.697-10.43 37.44-18.721 43.086-20.652 6.686-2.287 19.761-4.339 33.875-16.937 17.977-16.046 45.612-46.504 51.109-52.595 1.635-1.783 5.498-5.468 7.875-5.943.951-.12 1.486-.093 1.931 0 3.566.742 7.726 8.171 11.589 13.074 2.815 3.574 7.478 5.794 9.806 6.834 1.684.694 5.765 2.526 10.994 4.31 6.538 2.228 19.612 3.862 33.578-3.567 13.966-7.428 23.029-9.211 31.349-9.657 8.32-.446 20.32-.328 26.149 1.337 1.659.474 3.628 1.486 4.606 3.12 1.276 2.133 1.575 4.9 1.486 6.538.099 5.992.237 21.424.594 35.212.446 17.234-.297 49.475-.743 58.537-.446 9.063-2.823 17.681 4.754 37.293 7.578 19.611 17.086 32.389 31.349 45.166 14.263 12.777 14.412 14.56 16.641 18.72 2.228 4.16-.892 9.36-1.783 11.143-.713 1.427-4.557 6.438-6.389 8.766-5.497 6.834-5.794 16.789-6.389 24.218-.594 7.428-1.931 12.628-5.348 19.166-2.734 5.229-16.69 6.141-23.326 5.943-19.315 0-219.444 1.485-230.29 2.228-8.676.594-16.194.248-18.868 0-13.966-2.08-29.864-16.64-34.767-23.029-4.902-6.388-1.485-16.937 1.189-21.989 2.674-5.051 6.24-9.36 13.223-12.926 5.581-2.85 17.758-2.971 23.163-2.675l.015.001Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={6}
      d="M70.252 267.284s155.854-.809 161.797-.891c5.943-.083 17.621-.208 31.052.148 16.789.446 21.622-5.794 26.001-11.886 6.834-9.508 4.506-24.762 3.12-33.132-.446-4.209-2.348-17.353-6.389-36.252-5.052-23.623-3.714-33.726-2.972-44.72a20.453 20.453 0 0 0-4.457-14.263c-3.139-3.912-7.476-5.586-11.143-5.943-5.2-.508-4.16-.149-10.103-3.12-3.268-1.932-7.28-4.755-11.291-6.983-5.171-2.873-11.143-5.498-16.195-5.052-9.136.806-17.334 16.096-20.355 24.515-9.36 21.394-17.977 29.863-38.332 41.006-6.89 3.772-21.129 8.263-30.606 9.806-19.265 3.136-40.412 2.526-56.458 3.269-29.269 1.355-57.795-.446-64.63-.743-6.834-.297-3.12-7.875-1.485-10.252 1.634-2.377 3.714-7.726 4.16-13.371.357-4.517 2.922-8.122 4.16-9.361 2.13-1.931 7.726-8.617 13.669-14.411 10.697-10.43 37.44-18.721 43.086-20.652 6.686-2.287 19.761-4.339 33.875-16.937 17.977-16.046 45.612-46.504 51.109-52.595 1.635-1.783 5.498-5.468 7.875-5.943.951-.12 1.486-.093 1.931 0 3.566.742 7.726 8.171 11.589 13.074 2.815 3.574 7.478 5.794 9.806 6.834 1.684.694 5.765 2.526 10.994 4.31 6.538 2.228 19.612 3.862 33.578-3.567 13.966-7.428 23.029-9.211 31.349-9.657 8.32-.446 20.32-.328 26.149 1.337 1.659.474 3.628 1.486 4.606 3.12 1.276 2.133 1.575 4.9 1.486 6.538.099 5.992.237 21.424.594 35.212.446 17.234-.297 49.475-.743 58.537-.446 9.063-2.823 17.681 4.754 37.293 7.578 19.611 17.086 32.389 31.349 45.166 14.263 12.777 14.412 14.56 16.641 18.72 2.228 4.16-.892 9.36-1.783 11.143-.713 1.427-4.557 6.438-6.389 8.766-5.497 6.834-5.794 16.789-6.389 24.218-.594 7.428-1.931 12.628-5.348 19.166-2.734 5.229-16.69 6.141-23.326 5.943-19.315 0-219.444 1.485-230.29 2.228-8.676.594-16.194.248-18.868 0-13.966-2.08-29.864-16.64-34.767-23.029-4.902-6.388-1.485-16.937 1.189-21.989 2.674-5.051 6.24-9.36 13.223-12.926 5.586-2.852 17.78-2.971 23.177-2.674Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={2}
      d="M70.252 267.284s155.854-.809 161.797-.891c5.943-.083 17.621-.208 31.052.148 16.789.446 21.622-5.794 26.001-11.886 6.834-9.508 4.506-24.762 3.12-33.132-.446-4.209-2.348-17.353-6.389-36.252-5.052-23.623-3.714-33.726-2.972-44.72a20.453 20.453 0 0 0-4.457-14.263c-3.139-3.912-7.476-5.586-11.143-5.943-5.2-.508-4.16-.149-10.103-3.12-3.268-1.932-7.28-4.755-11.291-6.983-5.171-2.873-11.143-5.498-16.195-5.052-9.136.806-17.334 16.096-20.355 24.515-9.36 21.394-17.977 29.863-38.332 41.006-6.89 3.772-21.129 8.263-30.606 9.806-19.265 3.136-40.412 2.526-56.458 3.269-29.269 1.355-57.795-.446-64.63-.743-6.834-.297-3.12-7.875-1.485-10.252 1.634-2.377 3.714-7.726 4.16-13.371.357-4.517 2.922-8.122 4.16-9.361 2.13-1.931 7.726-8.617 13.669-14.411 10.697-10.43 37.44-18.721 43.086-20.652 6.686-2.287 19.761-4.339 33.875-16.937 17.977-16.046 45.612-46.504 51.109-52.595 1.635-1.783 5.498-5.468 7.875-5.943.951-.12 1.486-.093 1.931 0 3.566.742 7.726 8.171 11.589 13.074 2.815 3.574 7.478 5.794 9.806 6.834 1.684.694 5.765 2.526 10.994 4.31 6.538 2.228 19.612 3.862 33.578-3.567 13.966-7.428 23.029-9.211 31.349-9.657 8.32-.446 20.32-.328 26.149 1.337 1.659.474 3.628 1.486 4.606 3.12 1.276 2.133 1.575 4.9 1.486 6.538.099 5.992.237 21.424.594 35.212.446 17.234-.297 49.475-.743 58.537-.446 9.063-2.823 17.681 4.754 37.293 7.578 19.611 17.086 32.389 31.349 45.166 14.263 12.777 14.412 14.56 16.641 18.72 2.228 4.16-.892 9.36-1.783 11.143-.713 1.427-4.557 6.438-6.389 8.766-5.497 6.834-5.794 16.789-6.389 24.218-.594 7.428-1.931 12.628-5.348 19.166-2.734 5.229-16.69 6.141-23.326 5.943-19.315 0-219.444 1.485-230.29 2.228-8.676.594-16.194.248-18.868 0-13.966-2.08-29.864-16.64-34.767-23.029-4.902-6.388-1.485-16.937 1.189-21.989 2.674-5.051 6.24-9.36 13.223-12.926 5.586-2.852 17.78-2.971 23.177-2.674Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M130.16 287.639h-4.028v-4.526h4.028zM130.16 278.587h-4.028v-4.526h4.028zM130.16 269.536h-4.028v-4.526h4.028zM130.16 260.484h-4.028v-4.526h4.028zM130.16 251.432h-4.028v-4.526h4.028zM126.132 283.113h-4.028v-4.526h4.028z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M126.132 274.061h-4.028v-4.526h4.028zM126.132 265.01h-4.028v-4.526h4.028zM126.132 255.958h-4.028v-4.526h4.028z"
    />
    <path
      fill="#000"
      d="M126.132 287.639h-4.028v-4.526h4.028zM126.132 278.587h-4.028v-4.526h4.028zM126.132 269.536h-4.028v-4.526h4.028zM126.132 260.484h-4.028v-4.526h4.028zM126.132 251.432h-4.028v-4.526h4.028zM130.16 283.113h-4.028v-4.526h4.028z"
    />
    <path
      fill="#000"
      d="M130.16 274.061h-4.028v-4.526h4.028zM130.16 265.01h-4.028v-4.526h4.028zM130.16 255.958h-4.028v-4.526h4.028z"
    />
    <path
      fill="#E2645A"
      d="m156.093 241.257-14.82-9.857v19.761l14.82-9.904Zm2.263 0c0 .728-.299 1.408-.896 1.796l-15.546 10.342c-1.196.825-2.691-.146-2.691-1.748v-20.781c0-1.553 1.495-2.573 2.691-1.796l15.546 10.39c.597.437.896 1.117.896 1.797Z"
    />
  </svg>
));
export default SvgIconMiamiMinimap;
