import { defineStyleConfig, cssVar } from '@chakra-ui/react';

import { colors } from 'utils/themeConstants';

const $arrowBg = cssVar('popper-arrow-bg');

export const tooltipTheme = defineStyleConfig({
  baseStyle: ({ placement }) => {
    // tooltip defaults to bottom placement, with arrow at the top
    let arrowStyles: any = {
      '[data-popper-arrow=true]': {
        top: '-5px !important',
      },
      '[data-popper-arrow-inner]': {
        clipPath: 'polygon(100% 0%, 100% 0%, 0% 100%, 0% 0%)', // hides overflow that leaks through semi transparent background
      },
    };

    // top placement, arrow at bottom
    if (placement === 'top') {
      arrowStyles = {
        '[data-popper-arrow=true]': {
          bottom: '-5px !important',
        },
        '[data-popper-arrow-inner]': {
          clipPath: 'polygon(100% 0%, 100% 100%, 0% 100%, 0% 100%)',
        },
      };
    }

    return {
      bg: colors.black[80],
      backdropFilter: 'blur(3px)',
      color: colors.white[80],
      borderRadius: 0,
      border: `1px solid ${colors.fieryCoral[100]}`,
      [$arrowBg.variable]: colors.fieryCoral[100],

      ...arrowStyles,
    };
  },
});
