import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCrypto = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M11.438 12.813c0 .546-.43.937-.938.937-.547 0-.938-.39-.938-.938V7.189c0-.508.391-.938.938-.938a.95.95 0 0 1 .938.938v5.625ZM3.625 10A6.86 6.86 0 0 1 10.5 3.125c3.79 0 6.875 3.086 6.875 6.875a6.86 6.86 0 0 1-6.875 6.875A6.836 6.836 0 0 1 3.625 10Zm6.875 5c2.734 0 5-2.227 5-5 0-2.734-2.266-5-5-5-2.773 0-5 2.266-5 5 0 2.773 2.227 5 5 5Zm-10-5c0-5.508 4.453-10 10-10 5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10a9.97 9.97 0 0 1-10-10Zm10 8.125c4.453 0 8.125-3.633 8.125-8.125 0-4.453-3.672-8.125-8.125-8.125-4.492 0-8.125 3.672-8.125 8.125a8.119 8.119 0 0 0 8.125 8.125Z"
    />
  </svg>
));
export default SvgIconCrypto;
