/**
 * Color values come from Figma, with names from https://colornamer.robertcooper.me/
 * Numeric keys are based on the color's alpha value
 * Each color should have a 100 alpha value as a visual baseline, even if not used in code
 * Install the colorize extension to see colors in VSCode https://marketplace.visualstudio.com/items?itemName=kamikillerto.vscode-colorize
 **/

import { css } from '@emotion/react';

export const colors = {
  pickledPink: {
    // depracated light
    10: 'rgba(223, 72, 127, 0.1)',
    20: 'rgba(223, 72, 127, 0.2)',
    40: 'rgba(223, 72, 127, 0.4)',
    60: 'rgba(223, 72, 127, 0.6)',
    100: 'rgba(223, 72, 127, 1)',
  },
  dogwoodRose: {
    //depracated primary
    20: 'rgba(216, 27, 96, 0.2)',
    40: 'rgba(216, 27, 96, 0.4)',
    80: 'rgba(216, 27, 96, 0.8)',
    100: 'rgba(216, 27, 96, 1)',
  },
  bloodMoon: {
    //primary #d83832
    0: 'rgba(216, 56, 50, 0)',
    '05': 'rgba(216, 56, 50, 0.05)',
    20: 'rgba(216, 56, 50, 0.2)',
    30: 'rgba(216, 56, 50, 0.3)',
    40: 'rgba(216, 56, 50, 0.4)', //ghost
    60: 'rgba(216, 56, 50, 0.6)', //disabled
    80: 'rgba(216, 56, 50, 0.8)',
    100: 'rgba(216, 56, 50, 1)',
  },
  fieryCoral: {
    //light #e2645a
    10: 'rgba(226, 100, 90, 0.1)',
    20: 'rgba(226, 100, 90, 0.2)',
    40: 'rgba(226, 100, 90, 0.4)',
    60: 'rgba(226, 100, 90, 0.6)',
    80: 'rgba(226, 100, 90, 0.8)',
    100: 'rgba(226, 100, 90, 1)', //hover
  },
  rooibosTea: {
    // dark #ad3529
    20: 'rgba(173, 53, 41, 0.2)',
    40: 'rgba(173, 53, 41, 0.4)',
    80: 'rgba(173, 53, 41, 0.8)',
    100: 'rgba(173, 53, 41, 1)',
  },
  cuteCrab: {
    40: 'rgba(223, 72, 72, 0.4)',
    100: 'rgba(223, 72, 72, 1)',
  },
  freshAuburn: {
    10: 'rgba(166, 38, 38, 0.1)',
    100: 'rgba(166, 38, 38, 1)',
  },
  diabloRed: {
    40: 'rgba(206, 12, 0, 0.4)',
    60: 'rgba(206, 12, 0, 0.6)',
    80: 'rgba(206, 12, 0, 0.4)',
    100: 'rgba(206, 12, 0, 1)',
  },
  alexandria: {
    0: 'rgba(255, 143, 115, 0)',
    40: 'rgba(255, 143, 115, 0.4)',
    100: 'rgba(255, 143, 115, 1)',
  },
  coralQuartz: {
    '05': 'rgba(246, 104, 94, 0.05)',
    20: 'rgba(246, 104, 94, 0.2)',
    30: 'rgba(246, 104, 94, 0.3)',
    40: 'rgba(246, 104, 94, 0.4)',
    80: 'rgba(246, 104, 94, 0.8)',
    100: 'rgba(246, 104, 94, 1)',
  },
  creamyOrangeBlush: {
    100: 'rgba(255, 155, 124, 1)',
  },
  preciousPersimmon: {
    20: 'rgba(255, 112, 67, 0.2)',
    100: 'rgba(255, 112, 67, 1)',
  },
  firstDaffodil: {
    100: 'rgba(255, 243, 140, 1)',
  },
  cowbell: {
    '0': 'rgba(255, 227, 128, 0)',
    '05': 'rgba(255, 227, 128, 0.05)',
    20: 'rgba(255, 227, 128, 0.2)',
    30: 'rgba(255, 227, 128, 0.3)',
    40: 'rgba(255, 227, 128, 0.4)',
    80: 'rgba(255, 227, 128, 0.8)',
    100: 'rgba(255, 227, 128, 1)',
  },
  confetti: {
    100: 'rgba(223, 208, 72, 1)',
  },
  firstColorsOfSpring: {
    40: 'rgba(220, 223, 72, 0.4)',
    100: 'rgba(220, 223, 72, 1)',
  },
  rapeseedOil: {
    10: 'rgba(166, 153, 38, 0.1)',
    100: 'rgba(166, 153, 38, 1)',
  },
  mintie: {
    20: 'rgba(171, 245, 209, 0.2)',
    60: 'rgba(171, 245, 209, 0.6)',
    100: 'rgba(171, 245, 209, 1)',
  },
  diroset: {
    20: 'rgba(87, 217, 163, 0.2)',
    100: 'rgba(87, 217, 163, 1)',
  },
  jitteryJade: {
    100: 'rgba(121, 242, 192, 1)',
  },
  aphroditeAqua: {
    '05': 'rgba(72, 223, 187, 0.05)',
    20: 'rgba(72, 223, 187, 0.2)',
    30: 'rgba(72, 223, 187, 0.3)',
    40: 'rgba(72, 223, 187, 0.4)',
    80: 'rgba(72, 223, 187, 0.8)',
    100: 'rgba(72, 223, 187, 1)',
  },
  teal: {
    0: 'rgba(113, 211, 255, 0)',
    40: 'rgba(113, 211, 255, 0.4)',
    100: 'rgba(113, 211, 255, 100)',
  },
  treasuredTeal: {
    20: 'rgba(79, 197, 186, 0.2)',
    40: 'rgba(79, 197, 186, 0.4)',
    80: 'rgba(79, 197, 186, 0.8)',
    100: 'rgba(79, 197, 186, 1)',
  },
  secondaryGreen: {
    20: 'rgba(26, 116, 107, 0.2)',
    40: 'rgba(26, 116, 107, 0.4)',
    80: 'rgba(26, 116, 107, 0.8)',
    100: 'rgba(26, 116, 107, 1)',
  },
  baltic: {
    10: 'rgba(38, 166, 154, 0.1)',
    20: 'rgba(38, 166, 154, 0.2)',
    100: 'rgba(38, 166, 154, 1)',
  },
  darkCyan: {
    40: 'rgba(0, 138, 138, 0.4)',
    60: 'rgba(0, 138, 138, 0.6)',
    80: 'rgba(0, 138, 138, 0.8)',
    100: 'rgba(0, 138, 138, 1)',
  },
  bluePaisley: {
    10: 'rgba(38, 135, 166, 0.1)',
    100: 'rgba(38, 135, 166, 1)',
  },
  glideTime: {
    20: 'rgba(96, 112, 128, 0.2)',
    100: 'rgba(96, 112, 128, 1)',
  },
  thalassophile: {
    40: 'rgba(72, 178, 223, 0.4)',
    100: 'rgba(72, 178, 223, 1)',
  },
  bleuchatel: {
    '05': 'rgba(67, 143, 255, 0.05)',
    20: 'rgba(67, 143, 255, 0.2)',
    30: 'rgba(67, 143, 255, 0.3)',
    40: 'rgba(67, 143, 255, 0.4)',
    80: 'rgba(67, 143, 255, 0.8)',
    100: 'rgba(67, 143, 255, 1)',
  },
  skyDancer: {
    100: 'rgba(76, 154, 255, 1)',
  },
  ionizedAirGlow: {
    20: 'rgba(82, 224, 255, 0.2)',
    100: 'rgba(82, 224, 255, 1)',
  },
  grapeBlue: {
    100: 'rgba(35, 71, 107, 1)',
  },
  yankeesBlue: {
    100: 'rgba(23, 43, 63, 1)',
  },
  iceColdStare: {
    20: 'rgba(179, 212, 255, 0.2)',
    60: 'rgba(179, 212, 255, 0.6)',
    100: 'rgba(179, 212, 255, 1)',
  },
  fuchsiaPheromone: {
    100: 'rgba(171, 71, 188, 1)',
  },
  perrywinkle: {
    '05': 'rgba(153, 141, 217, 0.05)',
    20: 'rgba(153, 141, 217, 0.2)',
    30: 'rgba(153, 141, 217, 0.3)',
    40: 'rgba(153, 141, 217, 0.4)',
    80: 'rgba(153, 141, 217, 0.8)',
    100: 'rgba(153, 141, 217, 1)',
  },
  stargateShimmer: {
    100: 'rgba(122, 122, 255, 1)',
  },
  maticPolygonPurple: {
    100: 'rgba(126, 61, 253, 1)',
  },
  white: {
    '05': 'rgba(255, 255, 255, 0.05)',
    10: 'rgba(255, 255, 255, 0.1)',
    20: 'rgba(255, 255, 255, 0.2)',
    30: 'rgba(255, 255, 255, 0.3)',
    40: 'rgba(255, 255, 255, 0.4)',
    60: 'rgba(255, 255, 255, 0.6)',
    80: 'rgba(255, 255, 255, 0.8)',
    95: 'rgba(255, 255, 255, 0.95)',
    100: 'rgba(255, 255, 255, 1)',
  },
  cerebralGrey: {
    100: 'rgba(204, 204, 204, 1)',
  },
  secondaryGrey: {
    100: 'rgba(217, 217, 217, 1)',
  },
  darkVoid: {
    20: 'rgba(21, 21, 23, 0.2)',
    40: 'rgba(21, 21, 23, 0.4)',
    80: 'rgba(21, 21, 23, 0.8)',
    100: 'rgba(21, 21, 23, 1)',
  },
  carbon: {
    60: 'rgba(51, 51, 51, 60)',
    100: 'rgba(51, 51, 51, 100)',
  },
  zincDust: {
    '05': 'rgba(91, 91, 91, 0.2)',
    20: 'rgba(91, 91, 91, 0.2)',
    30: 'rgba(91, 91, 91, 0.3)',
    100: 'rgba(91, 91, 91, 1)',
  },
  jet: {
    100: 'rgba(53, 53, 53, 1)',
  },
  caviar: {
    100: 'rgba(44, 44, 46, 1)',
  },
  eerieBlack: {
    100: 'rgba(26, 26, 26, 1)',
  },
  black: {
    10: 'rgba(0, 0, 0, 0.1)',
    20: 'rgba(0, 0, 0, 0.2)',
    40: 'rgba(0, 0, 0, 0.4)',
    50: 'rgba(0, 0, 0, 0.5)',
    60: 'rgba(0, 0, 0, 0.6)',
    80: 'rgba(0, 0, 0, 0.8)',
    90: 'rgba(0, 0, 0, 0.9)',
    95: 'rgba(0, 0, 0, 0.95)',
    100: 'rgba(0, 0, 0, 1)',
  },
  paleSlate: {
    100: 'rgba(162, 170, 173, 1)',
  },
  gold: {
    20: 'rgba(158, 138, 68, 0.2)',
    60: 'rgba(158, 138, 68, 0.6)',
    100: 'rgba(158, 138, 68, 1)',
  },
  neutralDark: {
    80: 'rgba(26, 26, 26, 0.8)',
    100: 'rgba(26, 26, 26, 1)',
  },
  secondaryYellow: {
    40: 'rgba(255, 238, 88, 0.4)',
    70: 'rgba(255, 238, 88, 0.7)',
    100: 'rgba(255, 238, 88, 1)',
  },
  knobGrey: {
    100: 'rgba(153, 153, 153, 1)',
  },
  usdc: {
    100: 'rgba(39, 117, 202, 1)',
  },
  vext: {
    100: 'rgba(122, 122, 255, 1)',
  },
  mclaren: {
    100: 'rgba(255, 128, 0, 1)',
  },
  matic: {
    100: 'rgba(134, 63, 248, 1)',
  },
};

export const defaultTransition = 'all 0.3s ease';

export const defaultGradient = (alpha: 100 | 80 | 60 | 40) => {
  return `linear-gradient(81.73deg, ${colors.darkCyan[alpha]} -122.13%, ${colors.black[alpha]} 46.69%, ${colors.black[alpha]} 57.14%, ${colors.bloodMoon[alpha]} 212.04%)`;
};

export const tierGradientCommon = `linear-gradient(180deg, ${colors.black[100]} 44.27%, ${colors.zincDust[100]} 72.92%, ${colors.jet[100]} 100%)`;

export const racePreviewGradient =
  'linear-gradient(180deg, #121212 0%, #1E1E1E 100%)';

export const tuningSliderGradient =
  'linear-gradient(90deg, rgba(255, 238, 88, 0.04) 0%, rgba(255, 238, 88, 0.2) 50.52%, rgba(255, 238, 88, 0.04) 100%)';

export const ticketRacinoBgGradient =
  'linear-gradient(180deg, rgba(238, 0, 0, 0.30) 0%, rgba(216, 56, 50, 0.00) 100%)';

export const ticketVextBgGradient =
  'linear-gradient(180deg, rgba(142, 130, 212, 0.3) 0%, rgba(142, 130, 212, 0) 100%)';

export const ticketCardBgGradient =
  'linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)';

export const admissionTicketBgGradient =
  'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)';

export const carSelectBgGradient =
  'linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)';

export const activeCarSelectBgGradient =
  'linear-gradient(0deg, rgba(79, 197, 186, 0.2) 0%, rgba(79, 197, 186, 0) 100%)';

export const modalHeaderGradient =
  'linear-gradient(90deg, rgba(128, 128, 128, 0.8) 0%, rgba(163, 163, 163, 0) 100%)';

export const rewardCardBgGradient = `linear-gradient(192.24deg, rgba(21, 21, 23, 0.2) 11.84%, rgba(216, 56, 50, 0.2) 61.48%, rgba(226, 100, 90, 0.2) 91.09%)`;

export const darkGradient =
  'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)';

export const treasuredTealGradient =
  'linear-gradient(90deg, rgba(79, 197, 186, 0) 0%, rgba(79, 197, 186, 0.2) 100%)';

export const bloodMoonGradient =
  'linear-gradient(90deg, rgba(216, 56, 50, 0) 0%, rgba(216, 56, 50, 0.48) 100%)';

export const bloodMoonDarkGradient =
  'linear-gradient(0deg, rgba(216, 56, 50, 0.4), rgba(216, 56, 50, 0.4)), linear-gradient(90deg, rgba(216, 56, 50, 0) 0%, #D83832 100%)';

export const partsSliderGradient =
  'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.04) 100%)';

export const trialCarUpgradeModalGradient =
  'linear-gradient(192.24deg, #151517 11.84%, #999999 61.48%, #CCCCCC 91.09%)';

export const darkGreyGradient =
  'linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)';

export const rarityGradients = {
  Free: 'linear-gradient(180deg, #000000 44.27%, #20252D 72.92%, #17293F 100%);',
  bronze:
    'linear-gradient(180deg, #000000 44.27%, #2B2322 72.92%, #3F2517 100%);',
  silver:
    'linear-gradient(180deg, #000000 44.27%, #272727 72.92%, #353535 100%);',
  gold: 'linear-gradient(180deg, #000000 44.27%, #2C2B26 72.92%, #3F3B17 100%);',
  platinum:
    'linear-gradient(180deg, #000000 44.27%, #24242A 72.92%, #21173F 100%);',
  tournament:
    'linear-gradient(225deg, #FCF4BF 0%, #EFC862 18%, #CD9832 73%, #BB811B 100%)',
};

export const tyreGradient =
  'linear-gradient(0deg, rgba(255, 238, 88, 0.05) 0%, rgba(255, 238, 88, 0.0025) 100%)';

export const selectedTyreGradient =
  'linear-gradient(0deg, rgba(255, 238, 88, 0.2) 0%, rgba(255, 238, 88, 0.0025) 100%)';

export const sliderGradient =
  'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.04) 50%, rgba(255, 255, 255, 0.12) 100%)';

export const getTuningSliderGradient = ({
  value,
  isInsideSuggestion = false,
}: {
  value: number;
  isInsideSuggestion?: boolean;
}) => {
  const angle = value < 50 ? 180 : 0;

  if (isInsideSuggestion) {
    return `linear-gradient(${angle}deg, rgba(26, 116, 107, 0) 0%, rgba(79, 197, 186, 0.3) 100%)`;
  }

  return `linear-gradient(${angle}deg, rgba(255, 238, 88, 0) 0%, rgba(255, 238, 88, 0.3) 100%)`;
};

export const cssScrollBarStyle = css({
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    height: '1px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '1px',
  },
  scrollbarWidth: 'thin',
});
