import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRuler = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M20.25 5.703c0 .313-.156.625-.39.86L6.772 19.648a1.212 1.212 0 0 1-.859.352c-.351 0-.664-.117-.898-.352L.602 15.234a1.238 1.238 0 0 1-.352-.898c0-.313.117-.625.352-.86l2.109-2.148 2.227 2.227a.627.627 0 0 0 .43.195.642.642 0 0 0 .624-.625.721.721 0 0 0-.195-.469L3.609 10.47 5.367 8.71l2.227 2.187a.627.627 0 0 0 .43.196.617.617 0 0 0 .624-.625c0-.156-.078-.313-.195-.469L6.266 7.812l1.757-1.757 2.227 2.187a.627.627 0 0 0 .43.195.617.617 0 0 0 .625-.624.627.627 0 0 0-.196-.43L8.922 5.156l1.758-1.758 2.187 2.188a.72.72 0 0 0 .469.195.617.617 0 0 0 .625-.625.627.627 0 0 0-.195-.43L11.539 2.5 13.688.39c.234-.234.546-.39.859-.39.351 0 .664.156.898.39l4.414 4.415c.235.234.391.547.391.898Z"
    />
  </svg>
));
export default SvgIconRuler;
