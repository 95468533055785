import {
  Button,
  DrawerProps,
  Flex,
  Heading,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';

import { IconHelp } from 'icons';
import { RDrawer } from 'components';
import AidrianaImage from './Image';
import { racePreviewGradient } from 'utils/themeConstants';

interface AidrianaHelpButtonProps {
  title: string;
  description: string;
}

const AidrianaHelpButton = ({
  title,
  description,
  isOpen = false,
  onOpen,
  onClose = () => null,
}: AidrianaHelpButtonProps & UseDisclosureProps) => {
  return (
    <Flex
      pos="absolute"
      w="4rem"
      h="4rem"
      bg="bloodMoon.100"
      bottom="2rem"
      right="4rem"
      zIndex={100001}
      cursor="pointer"
      clipPath="polygon(30% 0, 100% 0, 100% 100%, 0 100%, 0 30%)"
      onClick={onOpen}
    >
      <Flex pos="relative" w="full" h="full">
        <Flex
          position="absolute"
          w="full"
          bottom="0"
          py="0.25rem"
          alignItems="center"
          justifyContent="center"
          bg="linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #D83832 100%)"
        >
          <IconHelp mr="0.25rem" />
          <Text fontSize="0.625rem">HELP</Text>
        </Flex>

        <AidrianaImage body="half" width={64} height={16} />
      </Flex>

      <AidrianaDrawer
        title={title}
        description={description}
        isOpen={isOpen}
        onClose={onClose}
      >
        <></>
      </AidrianaDrawer>
    </Flex>
  );
};

const AidrianaDrawer = ({
  title,
  description,
  ...props
}: AidrianaHelpButtonProps & DrawerProps) => {
  return (
    <RDrawer
      {...props}
      drawerHeaderProps={{ bg: racePreviewGradient, zIndex: 100002 }}
      drawerFooterProps={{ bg: racePreviewGradient, zIndex: 100002 }}
      heading={
        <Flex alignItems="center" gap="3">
          <Heading
            fontSize="1.125rem"
            color="white.60"
            fontWeight="400"
            textTransform="none"
          >
            Help
          </Heading>
        </Flex>
      }
      drawerBodyProps={{ p: '0', zIndex: 100002 }}
      drawerBody={
        <Flex w="full" h="full" flexDir="column">
          <Flex w="full" h="21.75rem" bg="black.100">
            <Flex
              w="full"
              h="21.75rem"
              bg="linear-gradient(-123.99deg, #5B5A5F -31.65%, rgba(91, 90, 95, 0) 50.56%);"
            >
              <Flex
                w="full"
                justifyItems="center"
                overflow="hidden"
                h="21.75rem"
                bg="linear-gradient(-102.18deg, #D83832 -21.88%, rgba(216, 56, 50, 0) 58.12%);"
              >
                <AidrianaImage
                  body="half"
                  width={540}
                  height={348}
                  objectFit="contain"
                  mx="auto"
                />
              </Flex>
            </Flex>
          </Flex>

          <Flex p="1rem" flexDir="column">
            <Heading
              fontSize="1.125rem"
              fontWeight="500"
              textTransform="uppercase"
            >
              {title}
            </Heading>

            <Text
              fontSize="0.875rem"
              color="white.60"
              mt="0.5rem"
              whiteSpace="pre-line"
            >
              {description}
            </Text>
          </Flex>
        </Flex>
      }
      drawerFooter={
        <Button w="90%" variant="primary" onClick={props.onClose}>
          OK
        </Button>
      }
    />
  );
};

export default AidrianaHelpButton;
