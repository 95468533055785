import {
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  ToastId,
  useToast,
} from '@chakra-ui/react';
import { PriceLabel } from 'components/Balance/PriceLabel';
import useGetCarPrices from 'hooks/Crypto/useGetCarPrices';
import useWalletBalance from 'hooks/useWalletBalance';

import {
  IconChevron,
  IconDollarBalance,
  IconMatic,
  IconVextBorder,
} from 'icons';
import { useEffect, useRef, useState } from 'react';
import RToast from 'components/Toast/Toast';
import { useCryptoConvervtion } from 'hooks/Crypto/useCryptoConvertion';
import { useWallet } from 'context';
import { BalanceComponent } from '../BalanceComponent';
import { useGetVextMessageForCars } from 'hooks';
import { CrossmintEvent } from '@crossmint/client-sdk-react-ui';

const tokenList = {
  usdc: {
    currency: 'USDC',
    icon: IconDollarBalance,
    color: 'usdc.100',
  },
  vext: {
    currency: 'VEXT',
    icon: IconVextBorder,
    color: 'vext.100',
  },
  matic: {
    currency: 'MATIC',
    icon: IconMatic,
    color: 'matic.100',
  },
};

type CryptoTabCarProps = {
  onSuccess: (e: CrossmintEvent) => void;
};

export const CryptoTabCar = ({ onSuccess }: CryptoTabCarProps) => {
  const { data: balance } = useWalletBalance();
  const { data: carPrices } = useGetCarPrices();
  const userBalance = balance?.getUserBalance;
  const carPrice = carPrices?.getCarPrice;

  const { wallet } = useWallet();

  const [selectedCrypto, setSelectedCrypto] = useState<
    'matic' | 'vext' | 'usdc'
  >('matic');

  const [cryptoPrice, setCryptoPrice] = useState<{
    price: number;
    readablePrice: number;
  }>({ price: 0, readablePrice: 0 });
  const [usdPrice, setUsdPrice] = useState<number>(0);

  const { data: convertion } = useCryptoConvervtion(selectedCrypto);

  const [deadline] = useState<string>(
    (Math.floor(Date.now() / 1000) + 60 * 30).toString()
  );
  const { data: vextConvertionData } = useCryptoConvervtion('vext');

  const { data: signature, refetch: refetchVextSignature } =
    useGetVextMessageForCars({
      vextRate: vextConvertionData?.last,
      deadline,
    });

  const [isLoading, setIsLoading] = useState(false);

  const [searchNewCar, setSearchNewCar] = useState(false);

  useEffect(() => {
    // convert crypto to usd
    if (convertion?.last) {
      if (selectedCrypto === 'vext') {
        const usdPrice = (cryptoPrice.readablePrice / 100) * Number(convertion?.last);
        setUsdPrice(usdPrice);
      } else {
      const usdPrice = (cryptoPrice.readablePrice * Number(convertion?.last));
      setUsdPrice(usdPrice);
      }
    }
  }, [cryptoPrice, convertion, selectedCrypto]);

  useEffect(() => {
    setCryptoPrice({
      price: carPrice?.maticPrice || 0,
      readablePrice: 0,
    });
  }, [carPrice]);

  useEffect(() => {
    if (selectedCrypto === 'vext' && vextConvertionData?.last) {
      refetchVextSignature();
    }
  }, [selectedCrypto, vextConvertionData?.last, refetchVextSignature]);

  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);
  const sendToast = (varaint: string, text: string) => {
    toastIdRef.current = toast({
      position: 'top',
      render: () => (
        <RToast
          variant={varaint as 'success' | 'error' | 'warning' | 'info'}
          title={text}
          onClose={() => toast.close(toastIdRef.current as ToastId)}
        />
      ),
    });
    setSearchNewCar(true);
  };

  const handleCryptoPayment = async () => {
    setIsLoading(true);
    console.log('Init Crypto payment with: ', selectedCrypto);
    console.log({
      carPrice,
      userBalance,
    });


    if (!wallet) {
      sendToast('error', 'Wallet not connected');
      return;
    }

    switch (selectedCrypto) {
      case 'matic':
        await handleMaticPayment();
        break;
      case 'vext':
        await handleVextPayment();
        break;
      case 'usdc':
        await handleUsdcPayment();
        break;
    }
    setIsLoading(false);
  };

  const handleMaticPayment = async () => {
    if (!wallet) {
      return;
    }

    if (!carPrice?.maticPrice || !userBalance?.matic) {
      sendToast('error', 'Error to get price or balance');
      return;
    }

    if (userBalance?.matic < carPrice?.maticPrice) {
      sendToast('error', 'Insufficient balance');
      return;
    }

    try {
      const { buyWithMatic } = await import('utils/crossmint/buy-car');
      console.log('VALUES', {
        wallet,
        price: cryptoPrice,
      });
      const payment: any = await buyWithMatic({
        aaWallet: wallet,
        price: cryptoPrice.price,
      });
      console.log(payment);
      onSuccess({
        type: 'payment:process.succeeded',
        payload: { orderIdentifier: payment },
      });
    } catch (error) {
      console.log(error);
      sendToast('error', 'Payment failed');
    }
  };

  const handleVextPayment = async () => {
    if (!wallet) {
      return;
    }
    try {
      const { approveVext } = await import('utils/crossmint/vext');
      const approval = await approveVext({
        aaWallet: wallet,
        spenderAddress: process.env.NEXT_PUBLIC_CARS_CONTRACT_ADDRESS || '',
      });
      console.log({ approval });

      const { buyWithVext } = await import('utils/crossmint/buy-car');
      const payment = await buyWithVext({
        aaWallet: wallet,
        tokenPriceVEXT: signature?.getVextMessageForCars?.tokenPriceVEXT || '',
        deadline,
        r: signature?.getVextMessageForCars.r || '',
        s: signature?.getVextMessageForCars.s || '',
        v: signature?.getVextMessageForCars.v || 0,
      });
      console.log(payment);
      sendToast('success', 'Payment successful');
    } catch (error) {
      console.log(error);
      sendToast('error', 'Payment failed');
    }
  };

  const handleUsdcPayment = async () => {
    if (!wallet) {
      return;
    }

    if (!carPrice?.usdcPrice || !userBalance?.usdc) {
      sendToast('error', 'Error to get price or balance');
      return;
    }

    if ((userBalance?.usdc / 1e6) < (carPrice?.usdcPrice / 1e26)) {
      sendToast('error', 'Insufficient balance');
      return;
    }

    try {
      const { approveUsdc } = await import('utils/crossmint/usdc');
      const approval = await approveUsdc({
        aaWallet: wallet,
        spenderAddress: process.env.NEXT_PUBLIC_CARS_CONTRACT_ADDRESS || '',
      });
      console.log('APPROVE USDC RESULT: ', approval);

      const { buyWithUsdc } = await import('utils/crossmint/buy-car');
      const payment: any = await buyWithUsdc({
        aaWallet: wallet,
      });
      console.log(payment);
      onSuccess({
        type: 'payment:process.succeeded',
        payload: { orderIdentifier: payment },
      });
      sendToast('success', 'Payment successful');
    } catch (error) {
      console.log(error);
      sendToast('error', 'Payment failed');
    }
  };

  const convertedPrice = (price: number) => {
    if (selectedCrypto === 'usdc') return price / 1e26;
    if (selectedCrypto === 'vext') return price / 1e26;

    return price / 1e18;
  };


  const convertedPriceBalance = (price: number) => {
    if (selectedCrypto === 'usdc') return price / 1e6;

    return price / 1e18;
  }

  const formatCryptoValue = (value: number) => {
    if (selectedCrypto === 'usdc') return value / 1e6;

    return value / 1e18;
  };

  return (
    <Flex h="100%" minH="300px" justifyContent="space-between" flexDir="column">
      <Stack w="full" gap={1} align="center">
        <HStack>
          <Heading
            fontWeight={'normal'}
            textTransform={'uppercase'}
            fontSize={'16px'}
            my={2}
          >
            Cost
          </Heading>

          <Menu variant="tertiary">
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<IconChevron style={{ transform: 'rotate(90deg)' }} />}
              variant="secondary"
              w="40px"
              h="30px"
            />
            <MenuList>
              <MenuItem
                icon={<IconMatic />}
                onClick={() => {
                  setSelectedCrypto('matic');
                  setCryptoPrice({
                    price: carPrice?.maticPrice || 0,
                    readablePrice: carPrice?.readableMaticPrice || 0,
                  });
                }}
              >
                Matic
              </MenuItem>
              <MenuItem
                icon={<IconVextBorder />}
                onClick={() => {
                  setSelectedCrypto('vext');
                  setCryptoPrice({
                    price: carPrice?.vextPrice || 0,
                    readablePrice: 0,
                  });
                }}
              >
                Vext
              </MenuItem>
              <MenuItem
                icon={<IconDollarBalance />}
                onClick={() => {
                  setSelectedCrypto('usdc');
                  setCryptoPrice({
                    price: carPrice?.usdcPrice || 0,
                    readablePrice: carPrice?.readableUsdcPrice || 0,
                  });
                }}
              >
                Usdc
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>

        <PriceLabel
          value={convertedPrice(cryptoPrice.price).toString()}
          currency={tokenList[selectedCrypto].currency}
          color={tokenList[selectedCrypto].color}
          numberSize="2.5rem"
          iconSize="2.6rem"
          icon={tokenList[selectedCrypto].icon}
          currencySize="18px"
        />
        <PriceLabel
          value={usdPrice.toFixed(2).toString()}
          currency="USD"
          numberSize="1.2rem"
          iconSize="1.2rem"
          currencySize="12px"
        />
      </Stack>
      <BalanceComponent
        balance={convertedPriceBalance(userBalance?.[selectedCrypto] || 0)}
        color={tokenList[selectedCrypto].color}
        currency={tokenList[selectedCrypto].currency}
      />

      <Text w="full" textAlign="center">
        By paying you accept Racino&apos;s terms
      </Text>
      <Button
        variant="tertiary"
        w="full"
        onClick={handleCryptoPayment}
        isLoading={isLoading}
        disabled={isLoading || !wallet}
      >
        Pay
      </Button>
    </Flex>
  );
};
