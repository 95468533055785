import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMail = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={13}
    fill="none"
    viewBox="0 0 16 13"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M14 .8c1.094 0 2 .906 2 2v8c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2v-8c0-1.094.875-2 2-2h12ZM2 2.3c-.281 0-.5.25-.5.5v.719L6.688 7.83c.718.594 1.875.594 2.593 0L14.5 3.52V2.8c0-.25-.25-.5-.5-.5H2Zm12 9c.25 0 .5-.219.5-.5V5.456L10.219 9.02c-.625.5-1.406.781-2.219.781a3.577 3.577 0 0 1-2.25-.781L1.5 5.456V10.8c0 .281.219.5.5.5h12Z"
    />
  </svg>
));
export default SvgIconMail;
