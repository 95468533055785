import { Text, Flex, Box } from '@chakra-ui/react';

import { Races } from 'api/generated/graphql';
import { RPrizePool, RTicket, RImage, RTiers, RRaceInfo } from 'components';
import {
  IconAustinMinimapMarkers,
  IconBarcelonaMinimapMarkers,
  IconBudapestMinimapMarkers,
  IconImolaMinimapMarkers,
  IconLusailMinimapMarkers,
  IconMelbourneMinimapMarkers,
  IconMontrealMinimapMarkers,
  IconSakhirMinimapMarkers,
  IconSilverstoneMinimapMarkers,
  IconSpaMinimapMarkers,
  IconSpielbergMinimapMarkers,
  IconSuzukaMinimapMarkers,
  IconZandvoortMinimapMarkers,
} from 'icons';
import { RaceTier, RecursivePartial } from 'types';
import { RacesBackground } from 'components/RaceCard/RaceCard';
import { defaultGradient } from 'utils/themeConstants';

type Props = {
  race: RecursivePartial<Races>;
  isLive?: boolean;
  hasDetails?: boolean;
};

type RaceMinimapMarkersType = {
  [key: string]: React.ElementType;
};

export const RaceMinimapMarkers: RaceMinimapMarkersType = {
  Belgium: IconSpaMinimapMarkers,
  Spain: IconBarcelonaMinimapMarkers,
  'United Kingdom': IconSilverstoneMinimapMarkers,
  Netherlands: IconZandvoortMinimapMarkers,
  USA: IconAustinMinimapMarkers,
  Italy: IconImolaMinimapMarkers,
  Bahrain: IconSakhirMinimapMarkers,
  Qatar: IconLusailMinimapMarkers,
  Hungary: IconBudapestMinimapMarkers,
  Austria: IconSpielbergMinimapMarkers,
  Canada: IconMontrealMinimapMarkers,
  Japan: IconSuzukaMinimapMarkers,
  Australia: IconMelbourneMinimapMarkers,
};

const RaceDetails = ({ race, isLive, hasDetails = false }: Props) => {
  const trackName = race?.track?.frontendName || '';
  const TrackMinimapMarkersIcon =
    trackName && trackName in RaceMinimapMarkers
      ? RaceMinimapMarkers[trackName]
      : null;

  const isAboveSm = window.innerWidth > 425;
  return (
    <Box height="full">
      <Flex w="full" h="full" flexDir="column">
        {trackName && (
          <>
            <RImage
              pos="absolute"
              top={0}
              left={0}
              w="full"
              h="full"
              objectFit="cover"
              src={RacesBackground[trackName]}
              alt="Race BG image"
              priority={true}
              zIndex={0}
            />
            <Box
              pos="absolute"
              top={0}
              left={0}
              opacity={0.7}
              w="full"
              h="full"
              zIndex={1}
              bg={defaultGradient(100)}
            />
          </>
        )}
        <Flex
          pos="relative"
          w="full"
          h="full"
          flexDir="column"
          justifyContent={isLive ? 'none' : 'space-between'}
          py="0.5rem"
          px={{ base: '0rem', md: '1.5rem' }}
          zIndex={2}
        >
          <Flex
            flexDir="column"
            zIndex={2}
            alignItems="center"
            justifyContent="center"
          >
            <RTiers
              tier={race?.tier?.name as RaceTier}
              tierType="driver"
              size={{ base: 'lg', lg: 'xl' }}
            />
            <Text
              fontFamily={'heading'}
              as="h2"
              color="white.80"
              fontSize={{ base: '3xl', md: '4xl' }}
            >
              {race?.track?.frontendName || ''}
            </Text>
          </Flex>
          <RRaceInfo race={race} hasDetails={hasDetails} />
          <Flex
            pos="relative"
            alignItems="center"
            w="full"
            justifyContent="center"
          >
            {trackName && TrackMinimapMarkersIcon && (
              <TrackMinimapMarkersIcon
                mt={{ base: 0, lg: '0rem' }}
                w={{ base: '11rem', md: '16rem' }}
                h={{ base: '11rem', md: '16rem' }}
                zIndex={1}
              />
            )}
          </Flex>
          <Flex w="full" zIndex={2} alignItems="center">
            <RTicket
              numberOfTickets={race?.numberOfTickets}
              marginRight="auto"
              size="3xl"
              currency={race?.currency}
            />
            <RPrizePool
              raceId={race?.id ? race.id : ''}
              poolAmount={race?.prizePool}
              size="xl"
              currency={race?.currency}
            />
          </Flex>
        </Flex>

        <Text zIndex={2} fontSize="md">
          {race?.track?.trackName}
        </Text>
      </Flex>
    </Box>
  );
};

export default RaceDetails;
