import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCrown = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M25 12.25c0 .719-.563 1.25-1.25 1.25h-.031l-1.594 8.688c-.063.468-.5.812-.969.812H10.813c-.47 0-.907-.344-.97-.813L8.25 13.5h-.031c-.688 0-1.25-.531-1.25-1.25 0-.688.562-1.25 1.25-1.25.656 0 1.25.563 1.25 1.25 0 .281-.125.531-.281.75L12 15.25a1 1 0 0 0 1.5-.344l1.813-3.594a1.248 1.248 0 0 1-.594-1.062C14.719 9.562 15.28 9 16 9c.688 0 1.219.563 1.219 1.25 0 .469-.25.844-.625 1.063l1.812 3.593c.282.563 1.032.719 1.532.344l2.78-2.25a1.505 1.505 0 0 1-.218-.75c0-.688.531-1.25 1.25-1.25.688 0 1.25.563 1.25 1.25Z"
    />
  </svg>
));
export default SvgIconCrown;
