import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconReplay = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M10 0c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10-2.07 0-3.945-.586-5.547-1.64a.939.939 0 0 1-.273-1.29c.312-.43.898-.547 1.328-.273 1.289.86 2.812 1.328 4.492 1.328 4.453 0 8.125-3.633 8.125-8.125 0-4.453-3.672-8.125-8.125-8.125-2.89 0-5.43 1.523-6.875 3.75h2.188a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H.938C.391 7.5 0 7.11 0 6.562V2.188c0-.507.39-.937.938-.937a.95.95 0 0 1 .937.938V4.18C3.672 1.68 6.641 0 10 0Zm0 5a.95.95 0 0 1 .938.938v3.71l2.5 2.5c.39.391.39.977 0 1.329a.856.856 0 0 1-1.29 0l-2.812-2.813c-.195-.156-.274-.39-.274-.664V5.937C9.063 5.43 9.454 5 10 5Z"
    />
  </svg>
));
export default SvgIconReplay;
