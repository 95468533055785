import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { colors, defaultGradient } from 'utils/themeConstants';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {},
  header: {
    w: 'full',
    p: '2',
    position: 'relative',
    fontFamily: 'heading',
    fontSize: 'lg',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'white.60',
    borderBottom: `1px solid ${colors.white[20]}`,
    bg: 'white.05',
  },
  closeButton: {
    h: '2.75rem',
    w: '2.75rem',
    border: '1px solid',
    borderBottom: 'none',
    borderRadius: '0',
    borderColor: 'white.20',
    position: 'absolute',
    top: '0',
    right: '0',
    marginLeft: 'auto',
  },
  dialog: {
    borderRadius: '0',
    pt: '0',
    bg: defaultGradient(100),
    backdropFilter: 'blur(10px)',
  },
  body: {
    p: '4',
    display: 'flex',
    gap: '4',
  },
});

const grey = definePartsStyle({
  dialog: {
    bg: `eerieBlack.100`,
  },
});

const tickets = definePartsStyle({
  header: {
    textTransform: 'none',
    paddingLeft: '1rem',
    textAlign: 'left',
    borderBottom: 'none',
  },
  closeButton: {
    border: 'none',
    bg: 'white.10',
  },
});

const vext = definePartsStyle({
  ...tickets,
  dialog: {
    bg: 'linear-gradient(81.73deg, #7873FC -122.13%, #000000 46.69%, #000000 57.14%, #3D06BC 212.04%)',
  },
});

const darkGrey = definePartsStyle({
  dialog: {
    bg: `darkVoid.100`,
  },
  header: {
    bg: `darkVoid.100`,
    border: 'none',
    textTransform: 'none',
    paddingLeft: '1rem',
    textAlign: 'left',
    color: 'white.95',
  },
  closeButton: {
    border: 'none',
    bg: 'white.10',
  },
  body: {
    borderBottom: `1px solid ${colors.bloodMoon[100]}`,
  },
});

const feedbackMOdal = definePartsStyle({
  dialog: {
    bg: `rgba(0, 0, 0, 0.6)`,
    backdropFilter: 'blur(20px)',
    borderRadius: '8px',
  },
  header: {
    bg: `linear-gradient(90deg, rgba(128, 128, 128, 0.80) 0%, rgba(163, 163, 163, 0.00) 100%)`,
    border: 'none',
    textAlign: 'left',
    color: 'white.90',
    borderRadius: '8px',
    textTransform: 'none',
  },
  closeButton: {
    borderRadius: '8px',
    border: 'none',
    bg: 'rgba(255, 255, 255, 0.10)',
  },
  body: {
    borderBottom: `none`,
  },
  overlay: {
    backdropFilter: 'blur(20px)',
  },
  footer: {
    borderRadius: '8px',
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { grey, darkGrey, feedbackMOdal, vext, tickets },
});
