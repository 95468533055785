import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPartEngineCooling = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={17}
    fill="none"
    viewBox="0 0 18 17"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="m7.304 16-4.895-3.443a2 2 0 0 1 .053-3.308l.956-.626M7.304 16l8.45-5.828a2 2 0 0 0 .092-3.225L13 4.734M7.304 16l-.648-6.136M3.418 1h4.447a2 2 0 0 1 1.294.475L13 4.734M3.418 1C2.166 1 1.15 2.27 1.15 3.836c0 1.567 1.015 3.014 2.267 3.014m0-5.85C4.67 1 5.685 2.27 5.685 3.836c0 1.567-1.015 3.014-2.267 3.014m0 0v1.773m0-1.773 3.238.177M3.418 8.623l3.238 1.24m0-2.836v2.837m0-2.837L13 4.734"
    />
  </svg>
));
export default SvgIconPartEngineCooling;
