import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMelbourneMinimapMarkers = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={376}
      height={375}
      fill="none"
      viewBox="0 0 376 375"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#000"
        fillOpacity={0.2}
        d="m67 106.5-16.731 3.354c-4.903 1.486-3.764 4.094-3.269 5.646 8.526 18.24 19.582 34.995 30.5 48 14.345 17.086 24.094 24.217 27.084 26.971 7.696 7.089 8.411 10.503 9.496 15.689l.013.06c1.04 6.835-2.796 13.351-3.984 15.728-1.189 2.378-.446 3.863.891 5.052 1.337 1.189 45.232 45.083 48.5 48.5 3.269 3.417 45.314 45.517 52 52.5 2.863 2.99 4.606 5.394 9.509 6.092 4.651.661 8.333-2.689 8.823-3.218 3.714-4.012 7.699-8.7 10.671-12.117 2.971-3.417 5.2.297 5.497.743.297.446 6.118 9.294 7.752 12.265 1.635 2.972 5.943 11.292 9.657 17.086 1.293 2.016 3.179 4.489 5.646 5.497 3.206 1.311 7.087.801 8.766.297C288.214 351.764 309.8 345.9 313 345.5c3.158-.395 3.654-2.445 3.506-3.46a.477.477 0 0 1-.006-.076c-.041-4.622-8.018-35.021-15-56.964-7-22-21-28.5-22.5-30s-7-5-15.5-13.5c-6.76-6.76-14.49-7.427-17.568-6.911a.499.499 0 0 1-.109.006c-2.847-.117-8.918 2.069-14.823.003-1.333-.533-5.2-2.698-10-7.098s-11-9.833-13.5-12c-8.171-7.429-14.271-15.554-18.5-28-10.873-32 5.532-57.5 11.5-73.5 5.832-15.635 2.068-32.206 2.001-32.496l-.002-.01c-.051-.292-2.012-11.566-2.999-16.994-1-5.5-4.5-14-13-21-11.194-7.842-15.652-6.802-25.309-7.842-9.657-1.04-15.314-7.578-17.691-9.658-1.783-1.56-5.366-.855-7 .5-6.092 5.052-16.491 8.731-26 12-7.607 2.615-14.667 6.564-16.5 8C88.871 49.918 77.8 58 75 60c-2.8 2-3.166 6.167-3 8l1 31.5c.238 4.873-3.87 6.752-6 7Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.2}
        strokeWidth={10}
        d="m67 106.5-16.731 3.354c-4.903 1.486-3.764 4.094-3.269 5.646 8.526 18.24 19.582 34.995 30.5 48 14.345 17.086 24.094 24.217 27.084 26.971 7.696 7.089 8.411 10.503 9.496 15.689l.013.06c1.04 6.835-2.796 13.351-3.984 15.728-1.189 2.378-.446 3.863.891 5.052 1.337 1.189 45.232 45.083 48.5 48.5 3.269 3.417 45.314 45.517 52 52.5 2.863 2.99 4.606 5.394 9.509 6.092 4.651.661 8.333-2.689 8.823-3.218 3.714-4.012 7.699-8.7 10.671-12.117 2.971-3.417 5.2.297 5.497.743.297.446 6.118 9.294 7.752 12.265 1.635 2.972 5.943 11.292 9.657 17.086 1.293 2.016 3.179 4.489 5.646 5.497 3.206 1.311 7.087.801 8.766.297C288.214 351.764 309.8 345.9 313 345.5c3.2-.4 3.667-2.5 3.5-3.5 0-4.5-8-35-15-57s-21-28.5-22.5-30-7-5-15.5-13.5c-6.8-6.8-14.581-7.435-17.622-6.902-2.823-.149-8.935 2.08-14.878 0-1.333-.533-5.2-2.698-10-7.098s-11-9.833-13.5-12c-8.171-7.429-14.271-15.554-18.5-28-10.873-32 5.532-57.5 11.5-73.5 5.884-15.774 2-32.5 2-32.5s-2-11.5-3-17-4.5-14-13-21c-11.194-7.842-15.652-6.802-25.309-7.842-9.657-1.04-15.314-7.578-17.691-9.658-1.783-1.56-5.366-.855-7 .5-6.092 5.052-16.491 8.731-26 12-7.607 2.615-14.667 6.564-16.5 8C88.871 49.918 77.8 58 75 60c-2.8 2-3.166 6.167-3 8l1 31.5c.238 4.873-3.87 6.752-6 7Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.95}
        strokeWidth={4}
        d="m67 106.5-16.731 3.354c-4.903 1.486-3.764 4.094-3.269 5.646 8.526 18.24 19.582 34.995 30.5 48 14.345 17.086 24.094 24.217 27.084 26.971 7.696 7.089 8.411 10.503 9.496 15.689l.013.06c1.04 6.835-2.796 13.351-3.984 15.728-1.189 2.378-.446 3.863.891 5.052 1.337 1.189 45.232 45.083 48.5 48.5 3.269 3.417 45.314 45.517 52 52.5 2.863 2.99 4.606 5.394 9.509 6.092 4.651.661 8.333-2.689 8.823-3.218 3.714-4.012 7.699-8.7 10.671-12.117 2.971-3.417 5.2.297 5.497.743.297.446 6.118 9.294 7.752 12.265 1.635 2.972 5.943 11.292 9.657 17.086 1.293 2.016 3.179 4.489 5.646 5.497 3.206 1.311 7.087.801 8.766.297C288.214 351.764 309.8 345.9 313 345.5c3.2-.4 3.667-2.5 3.5-3.5 0-4.5-8-35-15-57s-21-28.5-22.5-30-7-5-15.5-13.5c-6.8-6.8-14.581-7.435-17.622-6.902-2.823-.149-8.935 2.08-14.878 0-1.333-.533-5.2-2.698-10-7.098s-11-9.833-13.5-12c-8.171-7.429-14.271-15.554-18.5-28-10.873-32 5.532-57.5 11.5-73.5 5.884-15.774 2-32.5 2-32.5s-2-11.5-3-17-4.5-14-13-21c-11.194-7.842-15.652-6.802-25.309-7.842-9.657-1.04-15.314-7.578-17.691-9.658-1.783-1.56-5.366-.855-7 .5-6.092 5.052-16.491 8.731-26 12-7.607 2.615-14.667 6.564-16.5 8C88.871 49.918 77.8 58 75 60c-2.8 2-3.166 6.167-3 8l1 31.5c.238 4.873-3.87 6.752-6 7Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={85.567}
        y={221.969}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m95.794 230.045-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={116.768}
        y={177.1}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m124.172 190.336 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={19.898}
        y={101.922}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M27.084 115.686v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236H28.32l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={80.218}
        y={96.573}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M91.326 109.965h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
      />
      <rect width={20.8} height={20.8} x={41} y={41} fill="#26A69A" rx={10.4} />
      <path
        fill="#fff"
        d="M48.29 54.764V53.54h1.391v.804l.468.456h2.256l.468-.456V52.28l-.468-.468H48.41V47.6h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904L53.03 56h-3.504l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={140.539}
        y={2.08}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M147.849 15.844V9.916l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008V15.4l.48.48h2.304Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={208.883}
        y={62.698}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M216.039 76.462v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={160}
        y={47}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M171.561 55.028V54.8h-4.248v-1.2h5.688v1.464L169.653 62h-1.464l3.372-6.972Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={214}
        y={243}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M221.34 256.764v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928L226.032 258h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={266}
        y={214}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m272.241 222.076-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736L280.459 229h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={323.879}
        y={330.428}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m331.737 338.504-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.739 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={245.432}
        y={352.714}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m252.136 360.79-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.07 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={235}
        y={286}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m241.534 294.076-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34L249.32 301h-3.528l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={203.535}
        y={339.639}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m210.209 347.715-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="m167.908 254.684 2.848 2.848-3.2 3.2-2.848-2.848zM161.507 261.084l2.848 2.848-3.2 3.2-2.848-2.848zM155.107 267.485l2.848 2.848-3.2 3.2-2.848-2.848zM148.707 273.885l2.848 2.848-3.2 3.2-2.848-2.848zM142.306 280.286l2.848 2.848-3.2 3.2-2.848-2.848zM167.556 260.732l2.848 2.848-3.2 3.2-2.848-2.848zM161.155 267.133l2.848 2.848-3.2 3.2-2.848-2.848zM154.755 273.533l2.848 2.848-3.2 3.2-2.848-2.848zM148.354 279.934l2.848 2.848-3.2 3.2-2.848-2.848z"
      />
      <path
        fill="#000"
        d="m170.756 257.532 2.848 2.848-3.2 3.2-2.848-2.848zM164.355 263.932l2.848 2.848-3.2 3.2-2.848-2.848zM157.955 270.333l2.848 2.848-3.2 3.2-2.848-2.848zM151.555 276.733l2.848 2.848-3.2 3.2-2.848-2.848zM145.154 283.134l2.848 2.848-3.2 3.2-2.848-2.848zM164.708 257.884l2.848 2.848-3.2 3.2-2.848-2.848zM158.307 264.284l2.848 2.848-3.2 3.2-2.848-2.848zM151.907 270.685l2.848 2.848-3.2 3.2-2.848-2.848zM145.506 277.085l2.848 2.848-3.2 3.2-2.848-2.848z"
      />
      <path
        fill="#E2645A"
        d="m116.774 269.144 3.51 17.448 13.973-13.973-17.483-3.475Zm-1.601-1.601c.515-.515 1.207-.784 1.905-.636l18.305 3.68c1.429.262 1.799 2.005.667 3.138l-14.695 14.695c-1.098 1.098-2.876.762-3.172-.633l-3.646-18.339a2.214 2.214 0 0 1 .636-1.905Z"
      />
    </svg>
  )
);
export default SvgIconMelbourneMinimapMarkers;
