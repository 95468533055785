import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUnlocked = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={85}
    height={97}
    fill="none"
    viewBox="0 0 85 97"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M72.5 42.5c6.563 0 12 5.438 12 12.188v30c0 6.562-5.438 11.812-12 11.812h-60c-6.75 0-12-5.25-12-12v-30c0-6.563 5.25-12 12-12h6v-18c0-13.125 10.688-24 24-24 13.125 0 24 10.875 24 24 0 .938-.75 1.5-1.5 1.5-.938 0-1.5-.563-1.5-1.5 0-11.438-9.563-21-21-21-11.625 0-21 9.563-21 21v18h51Zm9 42v-30c0-4.875-4.125-9-9-9h-60c-5.063 0-9 4.125-9 9v30c0 5.063 3.938 9 9 9h60c4.875 0 9-3.938 9-9Z"
    />
  </svg>
));
export default SvgIconUnlocked;
