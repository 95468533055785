import { useRootContext } from 'context';
import useUserSettings from './useUserSettings';

interface useGetUnityUrlProps {
  raceId: string;
  userId?: string;
}

const useGetUnityUrl = ({ raceId, userId }: useGetUnityUrlProps) => {
  const { subdomain } = useRootContext();
  const { getUserSettings } = useUserSettings();

  let unitySubdomain = 'preview';
  if (subdomain === 'dev') {
    unitySubdomain = 'dev';
  } else if (subdomain == 'game' || subdomain === '') {
    unitySubdomain = 'prod';
  }

  const url = (mode: '2D' | '3D') => {
    const jsonPayload = {
      mode,
      raceId,
      userId,
      distanceReadings:
        getUserSettings.data?.getUser?.distanceReadings || 'MPH',
    };

    const encodedPayload = encodeURIComponent(JSON.stringify(jsonPayload));

    return `https://watch-${unitySubdomain}.racino.io/race/#${encodedPayload}`;
  };

  return { url };
};

export default useGetUnityUrl;
