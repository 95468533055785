import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconAmericanExpress = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={20}
    fill="none"
    viewBox="0 0 24 20"
    aria-hidden="true"
    {...props}
  >
    <rect width={24} height={20} fill="#fff" rx={2} />
    <path
      fill="#006FCF"
      fillRule="evenodd"
      d="M22 10.362h-1.04c-.315 0-.543.075-.704.192v-.192h-1.539c-.246 0-.535.061-.671.192v-.192h-2.748v.192c-.22-.158-.588-.192-.758-.192h-1.813v.192c-.173-.167-.558-.192-.792-.192H9.906l-.464.501-.435-.5h-3.03v3.272H8.95l.479-.51.45.51 1.833.001v-.77h.18c.243.004.53-.006.783-.115v.884h1.512v-.853h.073c.093 0 .102.004.102.096v.756h4.592c.291 0 .596-.074.765-.209v.21h1.456c.304 0 .6-.043.825-.151v-3.122ZM2.96 9.598l.217-.52h.485l.216.52h1.89V9.2l.168.4h.981l.17-.406v.404h4.696l-.002-.855h.09c.064.003.082.008.082.113v.742h2.43V9.4c.196.105.5.2.901.2h1.022l.219-.521h.485l.214.52h1.969v-.494l.298.494h1.578v-3.27h-1.562v.386l-.218-.386h-1.603v.386l-.2-.386H15.32c-.363 0-.681.05-.938.191v-.19h-1.494v.19c-.164-.145-.387-.19-.635-.19H6.797l-.367.844-.376-.845H4.336v.386l-.19-.386H2.682L2 7.883v1.715h.96Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.917 12.384c.492 0 .979-.135.979-.786 0-.65-.501-.773-.947-.773h-1.797l-.715.769-.691-.769H6.485v2.346h2.227l.719-.777.692.777h1.093v-.787h.701Zm-.701-.476h.726c.216 0 .35-.107.35-.31 0-.205-.14-.288-.343-.288h-.733v.598Zm-2.79.774H7.046v-.467h1.234v-.478H7.045v-.427h1.41l.614.684-.642.688Zm2.23.275-.863-.955.863-.925v1.88ZM15.3 12.278a.61.61 0 0 0-.275-.197.657.657 0 0 0 .398-.624c0-.268-.111-.414-.289-.521-.184-.098-.392-.11-.676-.11H13.16v2.345h.565v-.857h.6c.206 0 .33.02.413.104.094.098.092.277.091.415V13.171h.564v-.465c-.002-.207-.014-.314-.093-.428Zm-.615-.49c-.075.045-.168.05-.277.05h-.683v-.528h.692c.1 0 .2.002.268.043.073.038.117.11.117.21 0 .101-.044.182-.117.226Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M19.758 11.934c.11.113.168.255.168.497 0 .504-.316.74-.883.74h-1.095v-.503h1.09c.107 0 .183-.014.23-.058a.208.208 0 0 0 .066-.153.201.201 0 0 0-.068-.155c-.043-.036-.102-.053-.2-.053l-.105-.003c-.505-.013-1.08-.029-1.08-.722 0-.34.215-.699.804-.699h1.127v.5h-1.031c-.102 0-.169.003-.225.042-.062.038-.085.094-.085.169a.18.18 0 0 0 .123.174c.06.02.123.027.22.027l.302.008c.305.007.514.06.642.189ZM22 11.324h-1.024c-.103 0-.17.004-.228.043-.06.038-.082.094-.082.168 0 .089.05.15.123.175.06.02.123.027.216.027l.305.008c.308.007.513.06.638.189a.25.25 0 0 1 .045.048l.003.005.003.003.001.002v.879c-.137.199-.403.3-.763.3h-1.085v-.503h1.08c.108 0 .183-.014.228-.058a.206.206 0 0 0 .067-.153.193.193 0 0 0-.069-.155c-.04-.036-.1-.053-.198-.053l-.105-.003c-.506-.013-1.081-.029-1.081-.722 0-.34.216-.699.806-.699H22v.5ZM15.735 10.825h1.876v.485h-1.316v.427h1.284v.478h-1.284v.467l1.316.002v.487h-1.876v-2.346Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m3.803 8.123-.385-.935-.382.935h.767Zm8.476-.373c-.077.047-.168.049-.278.049h-.683v-.521h.692c.098 0 .2.004.267.042.073.034.118.107.118.207 0 .103-.043.185-.116.223Zm4.872.373-.39-.935-.386.935h.776ZM8.07 9.134h-.577l-.002-1.836-.815 1.836h-.493l-.818-1.838v1.838H4.222l-.216-.523h-1.17l-.219.523h-.61L3.013 6.79h.836l.956 2.22V6.79h.917l.736 1.59.676-1.59h.936v2.344Zm2.296 0H8.49V6.79h1.877v.488H9.052V7.7h1.284v.481H9.051v.468h1.316v.485Zm2.648-1.713a.66.66 0 0 1-.396.625c.123.047.228.13.278.197.08.117.093.221.093.43v.461h-.567l-.002-.295c0-.141.014-.344-.089-.457-.082-.083-.207-.1-.41-.1h-.603v.852h-.562V6.79h1.293c.287 0 .5.007.68.112.179.105.285.258.285.52Zm.9 1.713h-.574V6.79h.574v2.344Zm6.654 0h-.796l-1.066-1.76v1.76h-1.145l-.218-.523h-1.168l-.213.523h-.657c-.274 0-.62-.06-.815-.259-.198-.2-.3-.469-.3-.895 0-.348.06-.666.302-.917.182-.187.467-.273.854-.273h.544v.502h-.533c-.205 0-.32.03-.432.139-.096.099-.162.285-.162.531 0 .251.05.432.155.55.086.093.244.122.392.122h.252l.792-1.844h.843l.951 2.217V6.79h.856l.988 1.633V6.79h.576v2.344Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconAmericanExpress;
