import { Button, Flex, Heading, UseDisclosureProps } from '@chakra-ui/react';
import { Races } from 'api/generated/graphql';

import { RDrawer, RRaceCard } from 'components';
import { IconBarsFilter } from 'icons';

interface RaceHistoryDrawerProps extends UseDisclosureProps {
  pastRaces?: Races[];
  onClickRace: (raceId: string) => void;
}

const RaceHistoryDrawer = ({
  pastRaces = [] as Races[],
  isOpen = false,
  onClickRace,
  onClose = () => null,
}: RaceHistoryDrawerProps) => {
  const sortedRaces = pastRaces.sort((a, b) => {
    return Date.parse(b.startTime) - Date.parse(a.startTime);
  });

  return (
    <RDrawer
      heading="Race History"
      isOpen={isOpen}
      onClose={onClose}
      drawerBody={
        <Flex w="full" flexDirection="column">
          <Flex
            m="1rem 0"
            alignItems="center"
            justifyContent="space-between"
            h="2.25rem"
            w="full"
          >
            <Heading
              as="h1"
              fontSize="1.5rem"
              textTransform="uppercase"
              noOfLines={2}
              mb="2"
            >
              Recent and Past Races
            </Heading>

            <Button variant="secondary-outlined" isDisabled>
              Filters <IconBarsFilter ml="2" />
            </Button>
          </Flex>

          <Flex flexDirection="column" gap="2" mb="20">
            {sortedRaces.map((race) => {
              return (
                <RRaceCard
                  overflow="hidden"
                  aspectRatio={16 / 8}
                  key={race.id}
                  variant="garage"
                  h="11.75rem"
                  race={race as Races}
                  onClick={() => {
                    onClickRace(race.id);
                  }}
                />
              );
            })}
          </Flex>
        </Flex>
      }
    />
  );
};

export default RaceHistoryDrawer;
