import { defineStyleConfig } from '@chakra-ui/react';

import { colors } from 'utils/themeConstants';

const successToastBg = `linear-gradient(270deg,
  ${colors.aphroditeAqua[40]} 0%,
  transparent 100%),
  linear-gradient(0deg, ${colors.baltic[10]}, ${colors.baltic[10]}),
  ${colors.black[40]}`;

const errorToastBg = `linear-gradient(270deg,
  ${colors.cuteCrab[40]} 0%,
  transparent 100%),
  linear-gradient(0deg, ${colors.freshAuburn[10]}, ${colors.freshAuburn[10]}),
  ${colors.black[40]}`;

const warningToastBg = `linear-gradient(270deg,
 ${colors.firstColorsOfSpring[40]} 0%,
  transparent 100%),
  linear-gradient(0deg, ${colors.rapeseedOil[10]}, ${colors.rapeseedOil[10]}),
  ${colors.black[40]}`;

const infoToastBg = `linear-gradient(270deg,
  ${colors.thalassophile[40]} 0%,
  transparent 100%),
  linear-gradient(0deg, ${colors.bluePaisley[10]}, ${colors.bluePaisley[10]}),
  ${colors.black[40]}`;

export const toastTheme = defineStyleConfig({
  baseStyle: {
    zIndex: 5,
    position: 'relative',
    display: 'flex',
    backdropFilter: 'blur(3px)',
    boxShadow: 'dark-lg',
    marginBottom: { base: '4rem', md: '0' },
    w: '21.5rem',
    '.leftBox': {
      minH: 'full',
      w: '2.5rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.rightBox': {
      px: '2.5',
      py: '3.5',
      w: 'full',
      justifyContent: 'space-between',
      alignItems: 'center',
      '.message': {
        fontFamily: 'body',
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        color: colors.white[95],
      },
    },
    '.lastContainer': {
      justifyContent: 'flex-end',
      alignItems: 'center',
      '.action': {
        p: '0.3125rem 0.625rem',
        cursor: 'pointer',
        color: 'white.80',
      },
    },
  },
  variants: {
    success: {
      borderBottom: `1px solid ${colors.mintie[100]}`,
      bg: successToastBg,
      '.leftBox': {
        bg: colors.mintie[100],
      },
      '.action': {
        color: colors.mintie[100],
      },
    },
    error: {
      borderBottom: `1px solid ${colors.fieryCoral[100]}`,
      bg: errorToastBg,
      '.leftBox': {
        bg: colors.fieryCoral[100],
      },
      '.action': {
        color: colors.fieryCoral[100],
      },
    },
    warning: {
      borderBottom: `1px solid ${colors.cowbell[100]}`,
      bg: warningToastBg,
      '.leftBox': {
        bg: colors.cowbell[100],
      },
      '.action': {
        color: colors.cowbell[100],
      },
    },
    info: {
      borderBottom: `1px solid ${colors.skyDancer[100]}`,
      bg: infoToastBg,
      '.leftBox': {
        bg: colors.skyDancer[100],
      },
      '.action': {
        color: colors.skyDancer[100],
      },
    },
  },
  defaultProps: {
    variant: 'success',
  },
});
