import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFilters = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M9.25 12.25A.76.76 0 0 1 10 13c0 .438-.344.75-.781.75h-2.5C6.312 13.75 6 13.437 6 13c0-.406.313-.75.719-.75H9.25Zm5-10c.406 0 .75.406.75.781 0 .406-.344.719-.75.719H1.719C1.312 3.75 1 3.375 1 3c0-.406.313-.75.719-.75H14.25Zm-2 5A.76.76 0 0 1 13 8c0 .438-.344.75-.781.75h-8.5C3.313 8.75 3 8.437 3 8c0-.406.313-.75.719-.75h8.531Z"
    />
  </svg>
));
export default SvgIconFilters;
