import {
  Button,
  Box,
  Text,
  Flex,
  ButtonGroup,
  Checkbox,
} from '@chakra-ui/react';

import { IconWarningSquare } from 'icons';
import { RInput, RLink } from 'components';
import routes from 'routes';
import { AuthProvider } from 'types';
import { ChangeEvent, useState } from 'react';

const LoginForm = ({
  currentView,
  email,
  password,
  termsUrl,
  privacyPolicyUrl,
  setEmail,
  setPassword,
  handleSubmit,
}: {
  currentView: 'login' | 'signup';
  email: string;
  password: string;
  termsUrl?: string;
  privacyPolicyUrl?: string;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
    authProvider?: AuthProvider
  ) => void;
}) => {
  const uppercase = /[A-Z]/g;
  const lowercase = /[a-z]/g;
  const numeric = /[0-9]/g;
  const special = /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/g;

  const validLength = password.length >= 8;
  const hasUppercase = uppercase.test(password);
  const hasLowercase = lowercase.test(password);
  const hasNumeric = numeric.test(password);
  const hasSpecialChar = special.test(password);
  const isValidPassword =
    hasUppercase && hasLowercase && hasNumeric && hasSpecialChar && validLength;

  const [ageCheckbox, setAgeCheckbox] = useState(false);
  const confirmAge = (e: ChangeEvent<HTMLInputElement>) => {
    setAgeCheckbox(e.target.checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mx="0">
        <Box w="full">
          <RInput
            onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
            value={email}
            isRequired
            label="Enter email"
          />
        </Box>

        <Box w="full" mt="1rem">
          <RInput
            type="password"
            isRequired
            onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
            value={password}
            label="Password"
          />
          {currentView === 'signup' && (
            <Text
              fontSize="0.75rem"
              color={password.length === 0 ? 'paleSlate.100' : 'fieryCoral.100'}
              textAlign="left"
              display="flex"
              alignItems="center"
            >
              {password.length === 0 ? (
                <>Password required!</>
              ) : !validLength ? (
                <>
                  <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                  Password must be 8 characters or longer!
                </>
              ) : !hasUppercase ? (
                <>
                  <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                  Please use at least one capital letter!
                </>
              ) : !hasLowercase ? (
                <>
                  <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                  Please use at least one lowercase letter!
                </>
              ) : !hasNumeric ? (
                <>
                  <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                  Please use at least one number!
                </>
              ) : (
                !hasSpecialChar && (
                  <>
                    <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                    Please use at least one special character!
                  </>
                )
              )}
            </Text>
          )}
        </Box>

        {currentView === 'login' && (
          <RLink
            variant="secondary"
            my="1.5rem"
            display="inline-block"
            href={routes.resetPassword()}
          >
            Forgot Password?
          </RLink>
        )}

        {currentView === 'login' && (
          <>
            <Button
              id="btn-login"
              w="full"
              type="submit"
              isDisabled={!email || !password}
            >
              Log In
            </Button>
            <Flex
              mt="0.75rem"
              fontSize="0.75rem"
              justifyContent="center"
              textAlign="left"
              color="white.60"
            >
              <Text display="inline-block" fontSize="0.75rem">
                Click &apos;Login&apos; to agree to the{' '}
              </Text>
              <RLink
                about="_blank"
                target="noopener noreferrer"
                variant="secondary"
                href={termsUrl}
                display="inline-block"
                mx="0.25rem"
              >
                Terms of Service
              </RLink>
              <Text display="inline-block" fontSize="0.75rem" mr="0.25rem">
                {' '}
                and{' '}
              </Text>
              <RLink
                about="_blank"
                target="noopener noreferrer"
                variant="secondary"
                href={privacyPolicyUrl}
                display="inline-block"
              >
                Privacy Policy
              </RLink>
            </Flex>
          </>
        )}

        {currentView === 'signup' && (
          <>
            <Button
              id="btn-signup"
              w="full"
              type="submit"
              mt="1.5rem"
              isDisabled={!email || !isValidPassword || !ageCheckbox}
            >
              Sign Up
            </Button>
            <Flex
              mt="0.75rem"
              fontSize="0.75rem"
              justifyContent="center"
              textAlign="left"
              flexDir="column"
              color="white.60"
            >
              {/* <Checkbox
                colorScheme="red"
                onChange={(e) => confirmAge(e)}
                isChecked={ageCheckbox}
                size="sm"
              >
                I certify that I am 18 years of age or older.
                By clicking &apos;Sign Up&apos;, I agree to the{' '}
                <RLink
                  about="_blank"
                  target="noopener noreferrer"
                  variant="secondary"
                  href={termsUrl}
                  display="inline-block"
                  mx="0.25rem"
                >
                  Terms of Service
                </RLink>
                {' '}
                and{' '}
                <RLink
                  about="_blank"
                  target="noopener noreferrer"
                  variant="secondary"
                  href={privacyPolicyUrl}
                  display="inline-block"
                >
                  Privacy Policy
                </RLink>
              </Checkbox> */}
              <Box>
                <Checkbox
                  colorScheme="red"
                  onChange={(e) => confirmAge(e)}
                  isChecked={ageCheckbox}
                  size="sm"
                >
                  I certify that I am 18 years of age or older.
                </Checkbox>
                <Box as="span" display="block" fontSize="sm" mt="1">
                  By clicking &apos;Sign Up&apos;, I agree to the{' '}
                  <RLink
                    about="_blank"
                    target="noopener noreferrer"
                    variant="secondary"
                    href={termsUrl}
                    display="inline-block"
                    mx="0.25rem"
                  >
                    Terms of Service
                  </RLink>{' '}
                  and{' '}
                  <RLink
                    about="_blank"
                    target="noopener noreferrer"
                    variant="secondary"
                    href={privacyPolicyUrl}
                    display="inline-block"
                  >
                    Privacy Policy
                  </RLink>
                </Box>
              </Box>
            </Flex>
          </>
        )}

        <Flex justifyContent="space-between">
          <ButtonGroup gap="0" w="full">
            {/* <Button
              variant="secondary-outlined"
              title="Log in with Google"
              w="full"
              border="none"
              onClick={(e) => {
                handleSubmit(e, 'google');
              }}
            >
              <IconGoogle />
            </Button> */}

            {/* <Button
              variant="secondary-outlined"
              title="Log in with Facebook"
              w="full"
              border="none"
              isDisabled
            >
              <IconFacebook />
            </Button> */}
          </ButtonGroup>
        </Flex>
      </Box>
    </form>
  );
};

export default LoginForm;
