import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconThumbsUpGarage = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M3 6c.531 0 1 .469 1 1v6.938c0 .53-.469.968-1 .968H1a.98.98 0 0 1-1-.969V6.97A.98.98 0 0 1 1 6h2Zm13 1.094c0 .75-.406 1.375-.969 1.75.031.187.063.344.063.5 0 .719-.375 1.344-.906 1.719v.28c0 .72-.344 1.345-.907 1.72A2.057 2.057 0 0 1 11.22 15H9.563a5.14 5.14 0 0 1-3.126-1.031l-1.156-.844a.816.816 0 0 1-.312-.625c0-.375.343-.75.781-.75.156 0 .313.063.438.156l1.156.875c.625.469 1.406.75 2.218.75h1.657a.615.615 0 0 0 .594-.594c0-.124-.063-.156-.063-.312 0-.844.938-.563.938-1.281 0-.281-.188-.344-.188-.688 0-.937 1.094-.531 1.094-1.312 0-.406-.375-.406-.375-.906 0-.376.312-.72.719-.75.312 0 .562-.282.562-.594 0-.313-.281-.563-.625-.594H9.594c-.406 0-.75-.281-.75-.719 0-.125.031-.25.094-.375.562-1 .812-2 .812-2.25s-.188-.625-.719-.625c-1.031 0-.437 1.906-2.844 3.813-.125.125-.28.156-.437.156A.74.74 0 0 1 5 5.75c0-.844.875-.375 1.875-2.906.313-.813.719-1.813 2.156-1.813 1.375 0 2.219 1.063 2.219 2.125 0 .406-.188 1.094-.469 1.875h3.125c1.125 0 2.094.938 2.094 2.063Z"
    />
  </svg>
));
export default SvgIconThumbsUpGarage;
