import * as AWS from 'aws-sdk';

import { UserAttributes } from 'types';

const NEXT_URL = process.env.NEXT_URL;
const USER_POOL_CLIENT_ID = process.env.USER_POOL_CLIENT_ID || '';
const IDP_DOMAIN = process.env.IDP_DOMAIN;

const loginGoogle = async () => {
  let userAttributes: UserAttributes = null;
  let accessToken = '';
  let refreshToken = '';
  let isProcessing = false; // used to prevent multiple requests to the token endpoint, which creates errors

  await new Promise<void>((resolve, reject) => {
    const identityProvider = 'Google';
    const redirectUri = `${NEXT_URL}/auth/social`;
    const responseType = 'code';
    const clientId = USER_POOL_CLIENT_ID;
    const scope = 'profile email openid aws.cognito.signin.user.admin';
    AWS.config.region = 'us-east-2';

    const authUrl = `${IDP_DOMAIN}/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${redirectUri}&response_type=${responseType}&client_id=${clientId}&scope=${scope}`;

    window.open(
      authUrl,
      'awsGoogleAuth',
      'location,toolbar,resizable,scrollbars,status,width=600,height=600'
    );

    const channel = new BroadcastChannel('auth');

    channel.addEventListener('message', async (res) => {
      const { code } = res.data;
      if (!code) return;

      if (!isProcessing) {
        isProcessing = true;

        const response = await fetch(`${IDP_DOMAIN}/oauth2/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: clientId,
            code,
            redirect_uri: redirectUri,
          }),
        });

        response.json().then(async ({ access_token, refresh_token }) => {
          const user = await fetch(`${IDP_DOMAIN}/oauth2/userInfo`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          });

          userAttributes = await user.json();
          accessToken = access_token;
          refreshToken = refresh_token;
          resolve();
        });
      }
    });
  });

  return {
    userAttributes,
    accessToken,
    refreshToken,
  };
};

export { loginGoogle };
