import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMoneyBag = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#D83832"
      d="M11.156 3.625c6.094 3.719 5.813 8.656 5.813 9.25 0 1.75-1.532 3.125-3.407 3.125H4.406C2.531 16 1 14.625 1 12.875c0-.625-.313-5.5 5.813-9.25l-1.75-2.531c-.125-.219-.094-.5 0-.719C5.218.156 5.438 0 5.718 0h6.5c.281 0 .5.156.625.375s.156.5.031.719l-1.719 2.531ZM6.22 1l1.718 2.5h2.094L11.75 1H6.219Zm9.75 11.875.031-.063c0-1.874-.656-5.437-5.313-8.312H7.281C2.625 7.375 1.97 10.938 2 12.781v.094C2 14.063 3.063 15 4.406 15h9.157c1.343 0 2.406-.938 2.406-2.125ZM9.125 9.531c.906.25 2.25.656 1.969 2.094-.125.75-.75 1.25-1.625 1.375v.5c0 .281-.219.5-.5.5a.494.494 0 0 1-.5-.5v-.531c-.406-.063-.813-.219-1.188-.344l-.156-.063c-.25-.093-.406-.374-.313-.624a.524.524 0 0 1 .657-.313l.156.063c.313.124.688.25.969.28.687.126 1.437 0 1.531-.53.063-.438-.156-.626-1.313-.938l-.218-.063c-.656-.187-2.031-.593-1.781-2.03.125-.75.75-1.25 1.656-1.376V6.5c0-.25.219-.5.5-.5s.5.25.5.5l.031.563c-.031 0 0 0 0 0 .25.03.531.125.906.25.25.093.375.375.313.625-.094.28-.375.406-.656.312-.188-.063-.47-.156-.72-.188-.687-.124-1.437 0-1.5.532-.062.344 0 .562 1.063.875l.219.062Z"
    />
  </svg>
));
export default SvgIconMoneyBag;
