import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSpielbergMinimapMarkers = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={376}
      height={375}
      fill="none"
      viewBox="0 0 376 375"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#000"
        fillOpacity={0.2}
        d="M49.891 80.527c-13.074 0-27.832 2.08-33.577 3.12-6.686.743-5.2 4.606-4.16 5.943 1.04 1.337 3.824 5.098 24.663 25.109 22.435 21.543 28.08 31.349 34.023 40.709 5.943 9.36 16.195 35.806 27.784 58.835 11.588 23.029 38.183 58.241 44.275 65.967 6.091 7.726 10.251 15.749 12.48 20.057 1.783 3.447 4.407 3.616 5.497 3.269 9.311-2.526 28.764-7.815 32.092-8.766 4.16-1.188 124.356-33.875 140.996-38.926 16.641-5.052 24.515-12.035 27.932-14.56 2.734-2.021 2.625-5.696 2.229-7.281-3.368-11.935-10.371-36.43-11.44-38.926-1.337-3.12-1.189-6.24-7.875-11.589-6.686-5.348-18.571-3.12-23.92-3.12s-98.207 1.783-115.888 2.823c-17.68 1.04-31.646 16.492-36.103 22.881-4.457 6.388-9.36 10.846-21.097 10.103-9.39-.595-15.006-7.677-16.641-11.143-5.051-8.964-15.986-28.348-19.314-34.172-4.16-7.28-4.361-14.754-1.337-20.801 4.011-8.023 7.577-10.515 13.52-12.331 5.349-1.635 7.131-1.294 11.143-.743 6.487.891 22.435 3.714 38.183 5.943 18.816 2.662 26.149.594 37.887-4.903 11.737-5.497 23.474-19.018 25.851-27.486.743-2.972-.736-5.343-2.525-7.132-2.08-2.08-5.2-2.228-5.795-2.228-.475 0-7.924-.298-11.589-.446-5.051 0-34.469-.892-54.08-3.566-19.612-2.674-68.196-12.777-78.002-14.412-9.805-1.634-18.868-2.228-35.212-2.228Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.2}
        strokeWidth={10}
        d="M49.892 80.527c-13.075 0-27.833 2.08-33.578 3.12-6.686.743-5.2 4.606-4.16 5.943 1.04 1.337 3.824 5.098 24.663 25.109 22.435 21.543 28.08 31.349 34.023 40.709 5.943 9.36 16.195 35.806 27.784 58.835 11.588 23.029 38.183 58.241 44.275 65.967 6.091 7.726 10.251 15.749 12.48 20.057 1.783 3.447 4.408 3.616 5.497 3.269 9.311-2.526 28.764-7.815 32.092-8.766 4.16-1.188 124.356-33.875 140.997-38.926 16.64-5.052 24.514-12.035 27.931-14.56 2.734-2.021 2.625-5.696 2.229-7.281-3.368-11.935-10.371-36.43-11.44-38.926-1.337-3.12-1.189-6.24-7.875-11.589-6.685-5.348-18.571-3.12-23.92-3.12s-98.207 1.783-115.888 2.823c-17.68 1.04-31.646 16.492-36.103 22.881-4.457 6.388-9.36 10.846-21.097 10.103-9.39-.595-15.006-7.677-16.641-11.143-5.051-8.964-15.986-28.348-19.314-34.172-4.16-7.28-4.361-14.754-1.337-20.801 4.011-8.023 7.577-10.515 13.52-12.331 5.349-1.635 7.131-1.294 11.143-.743 6.488.891 22.435 3.714 38.183 5.943 18.816 2.662 26.149.594 37.887-4.903 11.737-5.497 23.474-19.018 25.851-27.486.743-2.972-.736-5.343-2.525-7.132-2.08-2.08-5.2-2.228-5.795-2.228-.475 0-7.924-.298-11.588-.446-5.052 0-34.47-.892-54.081-3.566-19.612-2.674-68.196-12.777-78.001-14.412-9.806-1.634-18.87-2.228-35.212-2.228Z"
      />
      <path
        stroke="#fff"
        strokeOpacity={0.95}
        strokeWidth={4}
        d="M49.892 80.527c-13.075 0-27.833 2.08-33.578 3.12-6.686.743-5.2 4.606-4.16 5.943 1.04 1.337 3.824 5.098 24.663 25.109 22.435 21.543 28.08 31.349 34.023 40.709 5.943 9.36 16.195 35.806 27.784 58.835 11.588 23.029 38.183 58.241 44.275 65.967 6.091 7.726 10.251 15.749 12.48 20.057 1.783 3.447 4.408 3.616 5.497 3.269 9.311-2.526 28.764-7.815 32.092-8.766 4.16-1.188 124.356-33.875 140.997-38.926 16.64-5.052 24.514-12.035 27.931-14.56 2.734-2.021 2.625-5.696 2.229-7.281-3.368-11.935-10.371-36.43-11.44-38.926-1.337-3.12-1.189-6.24-7.875-11.589-6.685-5.348-18.571-3.12-23.92-3.12s-98.207 1.783-115.888 2.823c-17.68 1.04-31.646 16.492-36.103 22.881-4.457 6.388-9.36 10.846-21.097 10.103-9.39-.595-15.006-7.677-16.641-11.143-5.051-8.964-15.986-28.348-19.314-34.172-4.16-7.28-4.361-14.754-1.337-20.801 4.011-8.023 7.577-10.515 13.52-12.331 5.349-1.635 7.131-1.294 11.143-.743 6.488.891 22.435 3.714 38.183 5.943 18.816 2.662 26.149.594 37.887-4.903 11.737-5.497 23.474-19.018 25.851-27.486.743-2.972-.736-5.343-2.525-7.132-2.08-2.08-5.2-2.228-5.795-2.228-.475 0-7.924-.298-11.588-.446-5.052 0-34.47-.892-54.081-3.566-19.612-2.674-68.196-12.777-78.001-14.412-9.806-1.634-18.87-2.228-35.212-2.228Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={154.339}
        y={273.97}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m164.566 282.046-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={36.669}
        y={142.036}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m44.074 155.272 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={31.617}
        y={87.064}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M38.804 100.828V99.64h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444V95.32l-.468-.456h-2.208l-.468.456v.768h-1.392V94.9l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236H40.04l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={203.666}
        y={105.487}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M214.773 118.879h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={171.276}
        y={114.699}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M178.566 128.463v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={119.275}
        y={143.225}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M126.585 156.989v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={192.077}
        y={191.957}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M199.233 205.721v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={141.858}
        y={185.42}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M153.42 193.448v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936h-1.464l3.372-6.972Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={324.604}
        y={187.5}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="M331.945 201.264v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
      />
      <rect
        width={20.8}
        height={20.8}
        x={335.005}
        y={221.672}
        fill="#26A69A"
        rx={10.4}
      />
      <path
        fill="#fff"
        d="m341.246 229.748-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="m237.845 261.464 3.835-1.232 1.384 4.31-3.835 1.231zM240.613 270.082l3.835-1.232 1.384 4.31-3.835 1.231zM243.381 278.699l3.835-1.232 1.384 4.31-3.835 1.231zM246.149 287.317l3.835-1.232 1.384 4.31-3.835 1.231zM248.918 295.935l3.835-1.232 1.384 4.31-3.835 1.231z"
      />
      <path
        fill="#fff"
        fillOpacity={0.95}
        d="m243.063 264.541 3.835-1.232 1.384 4.31-3.835 1.231zM245.832 273.159l3.835-1.232 1.384 4.31-3.835 1.231zM248.601 281.777l3.835-1.232 1.384 4.31-3.835 1.231zM251.369 290.395l3.835-1.232 1.384 4.31-3.835 1.231z"
      />
      <path
        fill="#000"
        d="m241.68 260.232 3.835-1.232 1.384 4.31-3.835 1.231zM244.448 268.85l3.835-1.232 1.384 4.31-3.835 1.231zM247.216 277.468l3.835-1.232 1.384 4.31-3.835 1.231zM249.984 286.085l3.835-1.232 1.384 4.31-3.835 1.231zM252.753 294.703l3.835-1.232 1.384 4.31-3.835 1.231zM239.229 265.773l3.835-1.232 1.384 4.31-3.835 1.231zM241.997 274.391l3.835-1.232 1.384 4.31-3.835 1.231z"
      />
      <path
        fill="#000"
        d="m244.766 283.009 3.835-1.232 1.384 4.31-3.835 1.231zM247.534 291.626l3.835-1.232 1.384 4.31-3.835 1.231z"
      />
      <path
        fill="#E2645A"
        d="m210.73 261.847 17.124 4.852-6.044-18.814-11.08 13.962Zm-2.155.693c-.223-.694-.146-1.432.305-1.985l11.637-14.601c.887-1.151 2.607-.684 3.097.842l6.355 19.784c.475 1.48-.636 2.908-2.012 2.534l-17.979-5.138a2.213 2.213 0 0 1-1.403-1.436Z"
      />
    </svg>
  )
);
export default SvgIconSpielbergMinimapMarkers;
