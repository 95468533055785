import * as React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IconTiersRarity } from 'icons';
import { Rarity } from 'api/generated/graphql';

interface BackgroundTagProps extends React.SVGProps<SVGSVGElement> {
  bgGradientColor?: string;
}

export const BackgroundTag = ({
  bgGradientColor = '#353535',
  ...props
}: BackgroundTagProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 97 22"
    fill="none"
    preserveAspectRatio="none"
    {...props}
  >
    <rect width="100%" height="100%" fill="url(#a)" rx={4} />
    <rect
      width="calc(100% - 1px)"
      height="calc(100% - 1px)"
      x={0.5}
      y={0.5}
      stroke="url(#b)"
      strokeOpacity={0.2}
      rx={3.5}
    />
    <defs>
      <linearGradient
        id="a"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.443} />
        <stop offset={0.76} stopColor="#272727" />
        <stop offset={1} stopColor={bgGradientColor} />
      </linearGradient>
      <linearGradient
        id="b"
        x1="0%"
        x2="100%"
        y1="50%"
        y2="50%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

interface RarityTagProps {
  tier: Rarity;
  showText?: boolean;
  showIcon?: boolean;
}

const bgColors: Record<Rarity, string> = {
  [Rarity.Common]: '#353535',
  [Rarity.Uncommon]: '#3F2517',
  [Rarity.Rare]: '#3F3B17',
  [Rarity.Epic]: '#17293F',
  [Rarity.Legendary]: '#21173F',
  [Rarity.Trial]: '#353535',
};

const textColors: Record<Rarity, string> = {
  [Rarity.Common]: '#CDCDCD',
  [Rarity.Uncommon]: '#FF7452',
  [Rarity.Rare]: '#FFC400',
  [Rarity.Epic]: '#00C7E6',
  [Rarity.Legendary]: '#8777D9',
  [Rarity.Trial]: '#CDCDCD',
};

const RarityTag: React.FC<RarityTagProps> = ({
  tier,
  showText = true,
  showIcon = true,
}) => {
  return (
    <Box position="relative" display="inline-flex" alignItems="center">
      <Box
        as={BackgroundTag}
        position="absolute"
        top={0}
        bgGradientColor={bgColors[tier]}
        left={0}
        w="full"
        h="full"
      />
      <Flex
        position="relative"
        px={showText ? 3 : 1}
        py={1}
        alignItems="center"
        gap={1}
        minH="22px"
      >
        {showIcon && (
          <Box
            w="16px"
            h="16px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconTiersRarity
              __css={{
                width: '13px',
                height: '13px',
                path: { strokeWidth: 2.2, stroke: textColors[tier] },
              }}
            />
          </Box>
        )}
        {showText && (
          <Text
            fontSize="sm"
            fontWeight="medium"
            color={textColors[tier]}
            whiteSpace="nowrap"
          >
            {tier.charAt(0).toUpperCase() + tier.slice(1)}
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default RarityTag;
