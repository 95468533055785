import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconStar = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={17}
    fill="none"
    viewBox="0 0 19 17"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M17 5.375c.813.125 1.156 1.125.563 1.75l-3.313 3.188.781 4.53a1.024 1.024 0 0 1-1 1.188c-.156 0-.312-.062-.469-.125L9.5 13.75l-4.094 2.156a1.252 1.252 0 0 1-.468.094.998.998 0 0 1-1-1.156l.78-4.563-3.312-3.187C.813 6.5 1.156 5.5 1.97 5.375l4.562-.656L8.594.562A.975.975 0 0 1 9.469 0c.375 0 .719.188.906.563l2.063 4.156L17 5.375Zm-4.313 4.656c-.03-.156.032-.312.126-.437l2.968-2.875-4.094-.594c-.156-.031-.312-.125-.374-.281L9.5 2.125 7.656 5.844c-.062.156-.218.25-.375.281l-4.093.594 2.968 2.875c.094.125.157.281.125.437l-.687 4.063 3.656-1.906a.426.426 0 0 1 .469 0l3.656 1.906-.688-4.063Z"
    />
  </svg>
));
export default SvgIconStar;
