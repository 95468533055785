import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconAudio = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={17}
    fill="none"
    viewBox="0 0 20 17"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M10.582.98a1.214 1.214 0 0 0-.457-.105 1.09 1.09 0 0 0-.773.316L4.605 5.375H1.687C.739 5.375 0 6.148 0 7.063v3.34c0 .913.738 1.652 1.688 1.652h2.917l4.747 4.218c.21.282.492.352.773.352.14 0 .281 0 .457-.07.387-.211.668-.598.668-1.055V2c0-.422-.281-.844-.668-1.02Zm-1.02 13.29-4.289-3.832H1.688v-3.34h3.585l4.29-3.832V14.27Zm4.922-8.122a.947.947 0 0 0-.527-.175.866.866 0 0 0-.668.316.848.848 0 0 0 .14 1.195c.387.317.633.774.633 1.266 0 .527-.246.984-.632 1.3a.848.848 0 0 0-.14 1.196c.175.211.42.316.667.316.176 0 .387-.07.527-.175.809-.668 1.266-1.617 1.266-2.637 0-.984-.457-1.934-1.266-2.602Zm2.145-2.601a1.063 1.063 0 0 0-.563-.176c-.246 0-.457.106-.632.317-.317.351-.246.878.105 1.195 1.195.949 1.899 2.39 1.899 3.867a5 5 0 0 1-1.899 3.902c-.351.317-.422.844-.105 1.196.175.21.386.316.632.316.211 0 .387-.07.563-.176 1.582-1.3 2.496-3.199 2.496-5.238 0-2.004-.914-3.902-2.496-5.203Z"
    />
  </svg>
));
export default SvgIconAudio;
