import { useMutation } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useApplyLivery = (rest?: { [key: string]: unknown }) => {
  const { graphql, graphQLClient } = useGraphQLClient();

  const applyLiveryGQL = graphql(`
    mutation ApplyLivery($carId: String!, $liveryId: String!) {
      applyLivery(carId: $carId, liveryId: $liveryId) {
        id
        name
      }
    }
  `);

  const applyLivery = useMutation({
    mutationKey: ['applyLivery'],
    mutationFn: ({ carId, liveryId }: { carId: string; liveryId: string }) => {
      return graphQLClient.request(applyLiveryGQL, {
        carId,
        liveryId,
      });
    },
    ...rest,
  });

  return applyLivery;
};

export default useApplyLivery;
