import {
  Button,
  Modal as ChakraModal,
  Flex,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text
} from '@chakra-ui/react';
import { IconFeatureLock } from 'icons';

interface FeaturesModalProps extends Omit<ModalProps, 'children' | 'onOpen'> {
  onClose: () => void;
}

const FeaturesModal = ({
  onClose,
  isOpen
}: FeaturesModalProps)=> {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={0}>
        <ModalBody p={0} flexDir="column">
          <Flex 
            flexDir="column"
            justifyContent="space-between"
          >
            <Flex bg="darkVoid.100" flexDir="column">
              <Flex flexDir="row" fontFamily="heading" gap={2} p="4" >
                <IconFeatureLock />
                Feature Locked
              </Flex>
              <Text p="4" color="white.60">
                SALE option is unfortunately only available on purchased cars. Purchase from the Market or win a car to unlock this feature.
              </Text>
            </Flex>
            <Flex
              bg="linear-gradient(180deg, #1E1E1E 0%, #121212 100%)"
              p="4"
            >
              <Button
                w="full"
                onClick={onClose}
                variant="primary"
              >
                OK
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}

export default FeaturesModal;