import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTiersRarity = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    viewBox="0 0 18 19"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeOpacity={0.8}
      strokeWidth={1.25}
      d="M9.313 1.25 1.5 7.5m7.813-6.25L16.5 7.5M9.312 1.25v9.063M1.5 7.5l6.956 10.017a1 1 0 0 0 1.665-.032L16.5 7.5m-15 0 7.813 2.813M16.5 7.5l-7.188 2.813"
    />
  </svg>
));
export default SvgIconTiersRarity;
