import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLogout = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M15.844 7.656A.502.502 0 0 1 16 8a.518.518 0 0 1-.156.375l-4.5 4.5a.53.53 0 0 1-.719 0 .53.53 0 0 1 0-.719L14.281 8.5H5.5A.494.494 0 0 1 5 8c0-.25.219-.5.5-.5h8.781l-3.656-3.625a.53.53 0 0 1 0-.719.53.53 0 0 1 .719 0l4.5 4.5ZM5.5 14c.25 0 .5.25.5.5 0 .281-.25.5-.5.5h-3A2.468 2.468 0 0 1 0 12.5v-9C0 2.125 1.094 1 2.5 1h3c.25 0 .5.25.5.5 0 .281-.25.5-.5.5h-3A1.5 1.5 0 0 0 1 3.5v9c0 .844.656 1.5 1.5 1.5h3Z"
    />
  </svg>
));
export default SvgIconLogout;
