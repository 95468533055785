import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPartRearWing = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={13}
    fill="none"
    viewBox="0 0 20 13"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="m7.173 1-3.898.243A2 2 0 0 0 1.4 3.24V7.3A2 2 0 0 0 4.19 9.135l1.774-.764a2 2 0 0 0 1.208-1.837v-.92m0-4.615 12.227.36M7.173 1l-.636.858c-.467.63-.13 1.533.636 1.702M19.4 1.36l-3.144.688a2 2 0 0 0-1.569 1.83l-.043.692M19.4 1.36l-1.18 9.183c-.05.384-.212.746-.465 1.039-1.235 1.427-3.577.47-3.46-1.413l.183-2.931M7.173 5.615c-1.085-.257-1.087-1.806 0-2.055m0 2.055 7.305 1.623M7.173 3.56l7.471 1.01m-.166 2.668.166-2.668"
    />
  </svg>
));
export default SvgIconPartRearWing;
