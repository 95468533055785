import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLockBadge = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    aria-hidden="true"
    {...props}
  >
    <path fill="#fff" fillOpacity={0.1} d="M28 4H0v12l16 16h12V4Z" />
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M22 13c1.094 0 2 .906 2 2v6c0 1.094-.906 2-2 2H12c-1.125 0-2-.906-2-2v-6c0-1.094.875-2 2-2h1v-2c0-2.188 1.781-4 4-4 2.188 0 4 1.781 4 4v2h1Zm-7.5-2v2h5v-2c0-1.375-1.125-2.5-2.5-2.5a2.487 2.487 0 0 0-2.5 2.5Zm8 10v-6c0-.25-.25-.5-.5-.5H12c-.281 0-.5.25-.5.5v6c0 .281.219.5.5.5h10c.25 0 .5-.219.5-.5Z"
      opacity={0.4}
    />
  </svg>
));
export default SvgIconLockBadge;
