import { Flex, FlexProps } from '@chakra-ui/react';

const BaseCardBottomLeft = ({ children, ...rest }: FlexProps) => {
  return (
    <Flex
      className="BaseCardBottomLeft"
      position="absolute"
      bottom="6"
      left="6"
      gap="2"
      maxHeight="35%"
      maxWidth="44%"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default BaseCardBottomLeft;
