import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';
import { exponentialBackoff } from 'utils/backoff';

const useGetDefaultCar = () => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const getDefaultCarGql = graphql(`
    query GetDefaultCar {
      getDefaultCar {
        id
        livery
        description {
          part
          description
          avgComparison
        }
      }
    }
  `);

  // the create car mutation returns a success response while Crossmint API works in the background
  // but there's currently no way for the frontend to be notified when minting is complete (backend is already using available webhook)
  // here we poll our graphql backend for car data when it becomes available
  // Crossmint typically takes 3-10 seconds to complete minting, but can take over 20 seconds
  const getDefaultCar = useQuery({
    enabled: Boolean(hasToken),
    retryDelay: exponentialBackoff, // interval works out to: 1s, 4s, 8s, 16s, max 30s
    queryKey: ['defaultCarQuery'],
    retry: 5,
    queryFn: async () => {
      return graphQLClient.request(getDefaultCarGql);
    },
  });

  return getDefaultCar;
};

export default useGetDefaultCar;
