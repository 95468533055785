import {
  Box,
  Flex,
  Grid,
  GridItem,
  ListItem,
  Text,
  UnorderedList,
  Heading,
  FlexProps,
} from '@chakra-ui/react';

import { colors, defaultGradient } from 'utils/themeConstants';
import { formatOrdinal } from 'utils/helpers';
import { IconTooltipArrow } from 'icons';
import { RaceParticipants } from 'api/generated/graphql';
import AidrianaImage from 'components/Aidriana/Image';

const ResultsAndTips = ({
  firstPlacePlayer,
  comparisonPlayer,
  raceTips,
  ...rest
}: FlexProps & {
  firstPlacePlayer: RaceParticipants | undefined;
  comparisonPlayer: RaceParticipants | undefined;
  raceTips: string[];
}) => {
  return (
    <Flex
      flexGrow="1"
      flexDirection="column"
      color="white.80"
      justifyContent="space-between"
      {...rest}
    >
      <Box w="full">
        <Grid
          templateColumns="3fr 1fr 1fr"
          w="full"
          gap="1"
          fontFamily="heading"
        >
          <GridItem
            w="full"
            px="3"
            py="2"
            fontSize="sm"
            textTransform="uppercase"
            bg="white.05"
          >
            Car
          </GridItem>
          <GridItem
            w="full"
            px="3"
            py="2"
            fontSize="sm"
            textTransform="uppercase"
            bg="white.05"
            textAlign="right"
          >
            Start
          </GridItem>
          <GridItem
            w="full"
            px="3"
            py="2"
            fontSize="sm"
            textTransform="uppercase"
            bg="white.05"
            textAlign="right"
          >
            Finished
          </GridItem>

          <GridItem px="3" py="2" fontSize="sm" color={colors.jitteryJade[100]}>
            {firstPlacePlayer?.car.name || 'No Name'}
          </GridItem>
          <GridItem px="3" py="2" textAlign="right" textTransform="uppercase">
            {formatOrdinal(firstPlacePlayer?.qualifyingGridPosition || 0)}
          </GridItem>
          <GridItem px="3" py="2" textAlign="right" textTransform="uppercase">
            {formatOrdinal(firstPlacePlayer?.position || 1)}
          </GridItem>

          <GridItem px="3" py="2" fontSize="sm" color={colors.fieryCoral[100]}>
            {comparisonPlayer?.car?.name || 'No Name'}
          </GridItem>
          <GridItem px="3" py="2" textAlign="right" textTransform="uppercase">
            {formatOrdinal(comparisonPlayer?.qualifyingGridPosition || 0)}
          </GridItem>
          <GridItem px="3" py="2" textAlign="right" textTransform="uppercase">
            {formatOrdinal(comparisonPlayer?.position || 0)}
          </GridItem>
        </Grid>
      </Box>

      <Flex flexDirection="column" alignItems="center" mt="12">
        <IconTooltipArrow />
        <Flex
          minH="200px"
          borderTop={`1px solid ${colors.treasuredTeal[100]}`}
          borderBottom={`1px solid ${colors.treasuredTeal[100]}`}
        >
          <Flex
            minH="200px"
            bg="black.100"
            justifyContent="center"
            flexBasis="25%"
            alignItems="flex-end"
            overflow="hidden"
            position="relative"
            _before={{
              content: `""`,
              display: 'block',
              position: 'absolute',
              top: '0',
              left: '0',
              w: 'full',
              h: 'full',
              zIndex: '1',
              background:
                'linear-gradient(302deg, #D83832 -21.88%, rgba(216, 56, 50, 0.00) 58.12%)',
            }}
          >
            <AidrianaImage
              width={0}
              height={0}
              style={{
                width: 'auto',
                height: '125%',
                position: 'absolute',
                top: '-12%',
                left: '0',
              }}
              alt="Aidriana"
            />
          </Flex>

          <Box flexBasis="75%" bg={defaultGradient(80)}>
            <Heading
              as="h3"
              w="full"
              py="2"
              px="4"
              fontSize="sm"
              fontWeight="normal"
              textTransform="uppercase"
              borderBottom={`1px solid ${colors.white[20]}`}
              bg="white.05"
            >
              Garry
            </Heading>

            <Box p="4">
              <Text fontSize="md" mb="4">
                Here&apos;s some general feedback from the race and a comparison
                of stats and parts.
              </Text>
              <UnorderedList>
                {raceTips && raceTips?.length > 0 ? (
                  <>
                    {raceTips?.map((tip) => (
                      <ListItem key={tip}>{tip}</ListItem>
                    ))}
                  </>
                ) : (
                  <>No race tips.</>
                )}
              </UnorderedList>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ResultsAndTips;
