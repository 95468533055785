import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

const Tooltip = ({
  label,
  children,
  openDelay = 500,
  hasArrow = true,
  ...rest
}: TooltipProps) => {
  return (
    <ChakraTooltip
      openDelay={openDelay}
      hasArrow
      label={label}
      gutter={12}
      {...rest}
    >
      {children}
    </ChakraTooltip>
  );
};

export default Tooltip;
