import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconVisa = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={20}
    fill="none"
    viewBox="0 0 24 20"
    aria-hidden="true"
    {...props}
  >
    <rect width={24} height={20} fill="#fff" rx={2} />
    <path
      fill="#1434CB"
      d="m11.903 6.872-1.337 6.247H8.95l1.337-6.247h1.616Zm6.799 4.034.85-2.346.49 2.346h-1.34Zm1.804 2.213H22l-1.305-6.247h-1.379c-.31 0-.572.18-.688.458l-2.425 5.789H17.9l.337-.933h2.073l.196.933Zm-4.22-2.04c.008-1.648-2.278-1.74-2.263-2.476.005-.224.219-.463.685-.523.232-.03.87-.054 1.594.28l.283-1.326a4.355 4.355 0 0 0-1.512-.276c-1.597 0-2.721.848-2.73 2.065-.01.899.803 1.4 1.414 1.7.63.306.842.502.839.776-.005.42-.503.605-.967.612-.813.012-1.284-.22-1.66-.395l-.293 1.37c.378.173 1.075.323 1.797.33 1.698 0 2.809-.838 2.814-2.137ZM9.594 6.872l-2.618 6.247H5.267L3.978 8.133c-.078-.306-.146-.419-.384-.548-.388-.212-1.03-.409-1.594-.532l.038-.181h2.75c.35 0 .666.233.746.637l.68 3.615 1.682-4.252h1.697Z"
    />
  </svg>
));
export default SvgIconVisa;
