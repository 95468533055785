import { Flex, FlexProps } from '@chakra-ui/react';

const BaseCardBottomRight = ({ children, ...rest }: FlexProps) => {
  return (
    <Flex
      className="BaseCardBottomRight"
      position="absolute"
      bottom="6"
      right="6"
      gap="2"
      maxHeight="35%"
      maxWidth="44%"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default BaseCardBottomRight;
