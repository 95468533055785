import {
  ListItem,
  Link,
  type ListItemProps,
  Text,
  Flex,
  Box,
  type LinkProps,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';

import { defaultTransition } from 'utils/themeConstants';
import { SVGIcon } from 'types';

type NavItemProps = {
  isActive?: boolean;
  text: string;
  href?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  Icon: SVGIcon;
} & ListItemProps;

const NavItem = ({
  // active item gets set when the current path matches an item's href
  // but can also be set manually via isActive prop
  isActive,
  text,
  href,
  onClick,
  isDisabled,
  Icon,
  ...rest
}: NavItemProps) => {
  const height = { base: '3rem', md: '3.5rem' };
  const pathname = usePathname();
  const hrefWithoutParams = href?.split('?')[0];

  const linkProps: LinkProps = {};

  if (!isDisabled && onClick) {
    linkProps.onClick = onClick;
  }

  if (!isDisabled && href) {
    linkProps.as = NextLink;
    linkProps.href = href;
  }

  return (
    <ListItem
      transition={defaultTransition}
      width="100%"
      mb="2px"
      borderRightRadius={'8px'}
      overflow="hidden"
      {...rest}
    >
      <Link
        variant="unstyled"
        display="flex"
        justifyContent="center"
        alignItems="middle"
        height={height}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        _hover={{
          '.icon': {
            opacity: 0,
          },
          '.text': {
            opacity: 1,
          },
        }}
        {...linkProps}
      >
        <Box position="relative" height={height} width="100%">
          <Flex
            className="icon"
            height="full"
            width="full"
            position="absolute"
            top="0"
            left="0"
            transition={defaultTransition}
          >
            <Icon
              marginX="auto"
              w={{ base: '1.25rem', md: '1.5rem' }}
              h="auto"
              opacity={isDisabled ? 0.2 : 0.6}
              __css={{
                path: { fill: 'white' },
              }}
            />
          </Flex>

          <Flex
            bg={isDisabled ? 'white.10' : 'bloodMoon.100'}
            borderLeftRadius={2}
            overflow="hidden"
            className="text"
            height="full"
            width="full"
            position="absolute"
            top="0"
            left="0"
            alignItems="center"
            padding=".25rem"
            opacity={pathname === hrefWithoutParams || isActive ? 1 : 0}
            transition={defaultTransition}
          >
            <Text
              fontSize="11px"
              fontWeight="500"
              margin="0 auto"
              width="100%"
              color={isDisabled ? 'white.40' : 'white'}
            >
              {text}
            </Text>
          </Flex>
        </Box>
      </Link>
    </ListItem>
  );
};

export default NavItem;
