import { Box, useStyleConfig, Flex, BoxProps, Heading, Text } from '@chakra-ui/react';

import { partsData } from 'utils/partData';
import {
  IconCheckBadge,
  IconLockBadge,
  IconThumbsUp,
  IconTyreC1,
  IconTyreC2,
  IconTyreC3,
  IconTyreC4,
  IconTyreC5,
  IconTyreInter,
  IconTyreWet,
  IconWarning,
  IconWarningOutline,
} from 'icons';
import { RTiers, RImage, RTooltip } from 'components';
import { CarTier, DriverTier, CarPart, Tyres } from 'types';
import PartInfo from 'components/PartInfo/PartInfo';
import PartInfoRaceDrawer from 'components/PartInfoRaceDrawer/PartInfoRaceDrawer';

export interface PartCardProps {
  showPartUsage?: boolean;
  carPart: CarPart;
  variant?: 'success' | 'fail' | 'default';
  tier?: CarTier | DriverTier;
  showPartInfo?: boolean;
  showPartRaceInfo?: boolean;
  isSuggested?: boolean;
}

const RPartCard = ({
  showPartUsage,
  carPart,
  variant,
  tier = 'bronze',
  showPartInfo,
  showPartRaceInfo,
  isSuggested,
  ...rest
}: BoxProps & PartCardProps) => {
  const getVariant = () => {
    if (variant) {
      return variant;
    }

    if (carPart.isApplied) {
      return 'success';
    }

    return 'default';
  };

  const styles = useStyleConfig('PartCard', { variant: getVariant() });
  let partMetadata = partsData.find((p) => p.name === carPart?.partType);

  if (carPart?.partType === 'tyres') {
    partMetadata = {
      icon: IconTyreC1,
      name: 'tyres',
      tyreId: carPart.id,
      screenName: `${carPart.id} - ${carPart.name}`,
    };
  }

  const tyreIcon = {
    C1: <IconTyreC1 />,
    C2: <IconTyreC2 />,
    C3: <IconTyreC3 />,
    C4: <IconTyreC4 />,
    C5: <IconTyreC5 />,
    INTER: <IconTyreInter />,
    WET: <IconTyreWet />,
  };

  const partInfos: any = {
    "frontWing": { title1: "HANDLING" },
    "rearWing": { title1: "GRIP", title2: "SPEED" },
    "engineCooling": { title1: "COOLING", title2: "GRIP" },
    "brakeCooling": { title1: "BRAKING", title2: "GRIP" },
    "transmissionRatios": { title1: "SPEED", title2: "ACCELL" }
  }

  const getStatus = (partType: string, highSetting: number) => {
    switch (partType) {
      case "frontWing":
        if (highSetting > 99)
          return { status1: "positive-1" };
        else if (highSetting > 59)
          return { status1: "neutral" }
        else return { status1: "negative-1" }

      case "rearWing":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59)
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }

      case "engineCooling":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59)
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }

      case "brakeCooling":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59)
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }

      case "transmissionRatios":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59)
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }
    }
    return "neutral"
  }

  // enum RarityEnum {
  //   common = 1,
  //   uncommon,
  //   rare,
  //   epic,
  //   legendary,
  // }
  enum TierEnum {
    bronze = 1,
    silver,
    gold,
    platinum,
  }

  const getTopRightIcon = () => {
    if (carPart.isApplied) {
      return <IconCheckBadge position="absolute" top="1" right="1" />;
    }
    if (carPart.locked) {
      return <IconLockBadge position="absolute" top="0" right="0" />;
    }
  };

  const getCarPartUsage = () => {
    if (
      carPart?.timesUsed === undefined ||
      carPart?.maximumTimesUsed === undefined
    )
      return null;

    return (
      <Flex pos="absolute" top="0.625rem" left="0.625rem" alignItems="center">
        {carPart.timesUsed === carPart.maximumTimesUsed ? (
          <IconWarning
            width="1.25rem"
            height="1.25rem"
            __css={{
              path: {
                fill: 'fieryCoral.100',
              },
            }}
          />
        ) : (
          <IconWarningOutline width="1.25rem" height="1.25rem" />
        )}
        <Heading
          ml="0.375rem"
          fontSize="0.75rem"
          fontWeight="normal"
          color={
            carPart.timesUsed === carPart.maximumTimesUsed
              ? 'fieryCoral.100'
              : 'white.100'
          }
        >
          {carPart.timesUsed} / {carPart.maximumTimesUsed}
        </Heading>
      </Flex>
    );
  };

  const partInfo = partInfos[carPart.partType || "frontWing"] || { title1: "", title2: "" };
  const { status1, status2 }: any = getStatus(carPart.partType || "", carPart.highSettingRange || 100);
  return (
    <Flex
      __css={styles}
      cursor={carPart?.locked ? 'not-allowed' : 'pointer'}
      {...rest}
    >
      <Box className="border-bottom" />
      {showPartInfo && !carPart.locked && <PartInfo title1={partInfo.title1} title2={partInfo.title2} status1={status1} status2={status2} />}
      {showPartRaceInfo && !carPart.locked && <PartInfoRaceDrawer title1={partInfo.title1} title2={partInfo.title2} status1={status1} status2={status2} />}
      {!showPartUsage
        ? carPart?.locked && (
          <Box
            position="absolute"
            top="0.3rem"
            left="0.3rem"
            className="tier"
          >
            <RTiers
              size="sm"
              showTitle
              inlineTitle
              tierType="car"
              tier={TierEnum[carPart.rankRequired || 1] as CarTier}
            />
          </Box>
        )
        : getCarPartUsage()}

      {/* tyres use React icons */}
      {partMetadata?.name === 'tyres' && (
        <Flex
          position="absolute"
          h="full"
          w="full"
          p="1"
          alignItems="center"
          justifyContent="center"
        >
          <RTooltip
            label={`${carPart.id} - ${carPart.name}`}
            shouldWrapChildren
            display={carPart?.locked ? 'none' : undefined}
          >
            {tyreIcon[partMetadata.tyreId as Tyres]}
          </RTooltip>
        </Flex>
      )}

      {/* other parts use PNG files via RImage */}
      {partMetadata?.name !== 'tyres' && (
        <>
          {/* this works around positioning issues when wrapping image in tooltip */}
          <RTooltip
            display={carPart?.locked ? 'none' : undefined}
            label={`${carPart.id} - ${carPart.name}`}
            gutter={-16}
          >
            <Flex
              position="absolute"
              h="full"
              w="full"
              p="1"
              alignItems="center"
              justifyContent="center"
              zIndex="2"
            >
              <Box position="absolute" width="10rem" height="5rem" />
            </Flex>
          </RTooltip>

          <Box position="relative" height="100%" width="100%">
            <RImage
              src={partMetadata?.path || ''}
              alt={partMetadata?.name || 'part'}
              filter={carPart?.locked ? 'blur(2px)' : ''}
              opacity={carPart?.locked ? '0.5' : ''}
              width={0}
              height={0}
              sizes="100vw"
              margin="0 auto"
              position={showPartRaceInfo ? 'absolute' : undefined}
              right={showPartRaceInfo ? '5' : undefined}
              style={{ width: 'auto', height: '85%' }}
              p="1"
            />
          </Box>
        </>
      )}

      <Heading as="h3" opacity={carPart?.locked ? 0.75 : 1}>
        {carPart?.locked ? 'Unknown' : carPart?.id}
      </Heading>

      {getTopRightIcon()}

      {isSuggested && (
        <Flex
          backgroundColor="rgba(178, 166, 61, 1)"
          position="absolute"
          bottom="0"
          right="0"
          alignItems="center"
          justifyContent="center"
          gap="1"
          pr="0.5rem"
          pl="0.25rem"
          py="0.25rem"
          _before={{
            content: '""',
            position: 'absolute',
            left: '-18px', // Adjust this value to change the size of the triangle
            bottom: '0',
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '0 0 26px 18px', // Adjust these values to match your desired triangle size
            borderColor: 'transparent transparent rgba(178, 166, 61, 1) transparent'
          }}
        >
          <IconThumbsUp w="0.75rem" h="0.75rem" />
          <Text
            fontFamily="body"
            color="black.60"
            fontWeight="bold"
            fontSize="xs"
          >
            SUGGESTED
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default RPartCard;
