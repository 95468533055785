import { Flex, FlexProps } from '@chakra-ui/react';
import { IconCup } from 'icons';

const TournamentBadge = (props: FlexProps) => {
  return (
    <Flex
      background="linear-gradient(225deg, #FCF4BF 0%, #EFC862 18%, #CD9832 73%, #BB811B 100%)"
      p="6px"
      borderRadius="0.25rem"
      {...props}
    >
      <IconCup />
    </Flex>
  );
};

export default TournamentBadge;
