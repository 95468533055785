import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTicketCard = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={192}
    height={192}
    fill="none"
    viewBox="0 0 192 192"
    aria-hidden="true"
    {...props}
  >
    <g filter="url(#a)" shapeRendering="crispEdges">
      <path
        fill="#FFF38C"
        fillOpacity={0.3}
        d="m100.651 104.077-6.242 15.788L70.544 96l6.242-15.787 13.584-4.04L94.41 62.59l23.865 23.865-4.039 13.585-13.584 4.038Z"
      />
      <path
        stroke="#FFE380"
        d="m94.409 119.865 9.546 9.546m-9.546-9.546 6.242-15.788 13.584-4.038 4.039-13.585L94.409 62.59 90.37 76.174l-13.584 4.038L70.544 96m23.865 23.865L70.544 96m-7.955-7.955L70.544 96m14.32 90.686H65.77l-3.182-3.181a9 9 0 0 0-12.728-12.728l-7.955-7.955a9 9 0 0 0 0-12.728 9 9 0 0 0-12.728 0l-7.954-7.955a9 9 0 0 0-12.728-12.728l-3.182-3.182v-19.092l19.091-19.092 9.546-9.546 73.186-73.185h19.092l3.182 3.182a9 9 0 0 0 12.728 12.728l7.955 7.954a9 9 0 0 0 12.728 12.728l7.955 7.955a9 9 0 0 0 0 12.728 8.999 8.999 0 0 0 12.727 0l3.182 3.182v19.092l-73.185 73.186-9.546 9.546-19.092 19.091Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={190.373}
        height={190.373}
        x={0.813}
        y={0.813}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.890196 0 0 0 0 0.501961 0 0 0 0.3 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6679_12155"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_6679_12155"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
));
export default SvgIconTicketCard;
