import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMiamiMinimapMarkers = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M70.253 267.284c.327-.002 155.86-.809 161.796-.891 5.943-.083 17.621-.208 31.052.148 16.789.446 21.622-5.794 26.001-11.886 6.834-9.508 4.506-24.762 3.12-33.132-.446-4.209-2.348-17.353-6.389-36.252-5.052-23.623-3.714-33.726-2.972-44.72a20.453 20.453 0 0 0-4.457-14.263c-3.139-3.912-7.476-5.586-11.143-5.943-5.2-.508-4.16-.149-10.103-3.12-3.268-1.932-7.28-4.755-11.291-6.983-5.171-2.873-11.143-5.498-16.195-5.052-9.136.806-17.334 16.096-20.355 24.515-9.36 21.394-17.977 29.863-38.332 41.006-6.89 3.772-21.129 8.263-30.606 9.806-19.265 3.136-40.412 2.526-56.458 3.269-29.269 1.355-57.795-.446-64.63-.743-6.834-.297-3.12-7.875-1.485-10.252 1.634-2.377 3.714-7.726 4.16-13.371.357-4.517 2.922-8.122 4.16-9.361 2.13-1.931 7.726-8.617 13.669-14.411 10.697-10.43 37.44-18.721 43.086-20.652 6.686-2.287 19.761-4.339 33.875-16.937 17.977-16.046 45.612-46.504 51.109-52.595 1.635-1.783 5.498-5.468 7.875-5.943.951-.12 1.486-.093 1.931 0 3.566.742 7.726 8.171 11.589 13.074 2.815 3.574 7.478 5.794 9.806 6.834 1.684.694 5.765 2.526 10.994 4.31 6.538 2.228 19.612 3.862 33.578-3.567 13.966-7.428 23.029-9.211 31.349-9.657 8.32-.446 20.32-.328 26.149 1.337 1.659.474 3.628 1.486 4.606 3.12 1.276 2.133 1.575 4.9 1.486 6.538.099 5.992.237 21.424.594 35.212.446 17.234-.297 49.475-.743 58.537-.446 9.063-2.823 17.681 4.754 37.293 7.578 19.611 17.086 32.389 31.349 45.166 14.263 12.777 14.412 14.56 16.641 18.72 2.228 4.16-.892 9.36-1.783 11.143-.713 1.427-4.557 6.438-6.389 8.766-5.497 6.834-5.794 16.789-6.389 24.218-.594 7.428-1.931 12.628-5.348 19.166-2.734 5.229-16.69 6.141-23.326 5.943-19.315 0-219.444 1.485-230.29 2.228-8.676.594-16.194.248-18.868 0-13.966-2.08-29.864-16.64-34.767-23.029-4.902-6.388-1.485-16.937 1.189-21.989 2.674-5.051 6.24-9.36 13.223-12.926 5.581-2.85 17.758-2.971 23.163-2.675l.015.001Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={6}
      d="M70.252 267.284s155.854-.809 161.797-.891c5.943-.083 17.621-.208 31.052.148 16.789.446 21.622-5.794 26.001-11.886 6.834-9.508 4.506-24.762 3.12-33.132-.446-4.209-2.348-17.353-6.389-36.252-5.052-23.623-3.714-33.726-2.972-44.72a20.453 20.453 0 0 0-4.457-14.263c-3.139-3.912-7.476-5.586-11.143-5.943-5.2-.508-4.16-.149-10.103-3.12-3.268-1.932-7.28-4.755-11.291-6.983-5.171-2.873-11.143-5.498-16.195-5.052-9.136.806-17.334 16.096-20.355 24.515-9.36 21.394-17.977 29.863-38.332 41.006-6.89 3.772-21.129 8.263-30.606 9.806-19.265 3.136-40.412 2.526-56.458 3.269-29.269 1.355-57.795-.446-64.63-.743-6.834-.297-3.12-7.875-1.485-10.252 1.634-2.377 3.714-7.726 4.16-13.371.357-4.517 2.922-8.122 4.16-9.361 2.13-1.931 7.726-8.617 13.669-14.411 10.697-10.43 37.44-18.721 43.086-20.652 6.686-2.287 19.761-4.339 33.875-16.937 17.977-16.046 45.612-46.504 51.109-52.595 1.635-1.783 5.498-5.468 7.875-5.943.951-.12 1.486-.093 1.931 0 3.566.742 7.726 8.171 11.589 13.074 2.815 3.574 7.478 5.794 9.806 6.834 1.684.694 5.765 2.526 10.994 4.31 6.538 2.228 19.612 3.862 33.578-3.567 13.966-7.428 23.029-9.211 31.349-9.657 8.32-.446 20.32-.328 26.149 1.337 1.659.474 3.628 1.486 4.606 3.12 1.276 2.133 1.575 4.9 1.486 6.538.099 5.992.237 21.424.594 35.212.446 17.234-.297 49.475-.743 58.537-.446 9.063-2.823 17.681 4.754 37.293 7.578 19.611 17.086 32.389 31.349 45.166 14.263 12.777 14.412 14.56 16.641 18.72 2.228 4.16-.892 9.36-1.783 11.143-.713 1.427-4.557 6.438-6.389 8.766-5.497 6.834-5.794 16.789-6.389 24.218-.594 7.428-1.931 12.628-5.348 19.166-2.734 5.229-16.69 6.141-23.326 5.943-19.315 0-219.444 1.485-230.29 2.228-8.676.594-16.194.248-18.868 0-13.966-2.08-29.864-16.64-34.767-23.029-4.902-6.388-1.485-16.937 1.189-21.989 2.674-5.051 6.24-9.36 13.223-12.926 5.586-2.852 17.78-2.971 23.177-2.674Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={2}
      d="M70.252 267.284s155.854-.809 161.797-.891c5.943-.083 17.621-.208 31.052.148 16.789.446 21.622-5.794 26.001-11.886 6.834-9.508 4.506-24.762 3.12-33.132-.446-4.209-2.348-17.353-6.389-36.252-5.052-23.623-3.714-33.726-2.972-44.72a20.453 20.453 0 0 0-4.457-14.263c-3.139-3.912-7.476-5.586-11.143-5.943-5.2-.508-4.16-.149-10.103-3.12-3.268-1.932-7.28-4.755-11.291-6.983-5.171-2.873-11.143-5.498-16.195-5.052-9.136.806-17.334 16.096-20.355 24.515-9.36 21.394-17.977 29.863-38.332 41.006-6.89 3.772-21.129 8.263-30.606 9.806-19.265 3.136-40.412 2.526-56.458 3.269-29.269 1.355-57.795-.446-64.63-.743-6.834-.297-3.12-7.875-1.485-10.252 1.634-2.377 3.714-7.726 4.16-13.371.357-4.517 2.922-8.122 4.16-9.361 2.13-1.931 7.726-8.617 13.669-14.411 10.697-10.43 37.44-18.721 43.086-20.652 6.686-2.287 19.761-4.339 33.875-16.937 17.977-16.046 45.612-46.504 51.109-52.595 1.635-1.783 5.498-5.468 7.875-5.943.951-.12 1.486-.093 1.931 0 3.566.742 7.726 8.171 11.589 13.074 2.815 3.574 7.478 5.794 9.806 6.834 1.684.694 5.765 2.526 10.994 4.31 6.538 2.228 19.612 3.862 33.578-3.567 13.966-7.428 23.029-9.211 31.349-9.657 8.32-.446 20.32-.328 26.149 1.337 1.659.474 3.628 1.486 4.606 3.12 1.276 2.133 1.575 4.9 1.486 6.538.099 5.992.237 21.424.594 35.212.446 17.234-.297 49.475-.743 58.537-.446 9.063-2.823 17.681 4.754 37.293 7.578 19.611 17.086 32.389 31.349 45.166 14.263 12.777 14.412 14.56 16.641 18.72 2.228 4.16-.892 9.36-1.783 11.143-.713 1.427-4.557 6.438-6.389 8.766-5.497 6.834-5.794 16.789-6.389 24.218-.594 7.428-1.931 12.628-5.348 19.166-2.734 5.229-16.69 6.141-23.326 5.943-19.315 0-219.444 1.485-230.29 2.228-8.676.594-16.194.248-18.868 0-13.966-2.08-29.864-16.64-34.767-23.029-4.902-6.388-1.485-16.937 1.189-21.989 2.674-5.051 6.24-9.36 13.223-12.926 5.586-2.852 17.78-2.971 23.177-2.674Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={256.118}
      y={237.421}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m266.345 245.497-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={256.118}
      y={150.951}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m263.523 164.187 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={251.958}
      y={124.802}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M259.145 138.566v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={248.096}
      y={88.55}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M259.203 101.942h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={220.461}
      y={115.59}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M227.75 129.354v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={161.625}
      y={136.391}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M168.935 150.155v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={30.571}
      y={156}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M42.132 164.028v-.228h-4.248v-1.2h5.688v1.464L40.224 171H38.76l3.372-6.972Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={31.178}
      y={100.139}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M38.334 113.903v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236H39.57l-1.236-1.236Zm4.248-3.612.48-.48v-1.392l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={88.824}
      y={74.287}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M96.165 88.05v-1.02h1.391v.577l.48.48h2.185l.48-.48v-2.172l-.744.744H97.28l-1.237-1.236v-2.82l1.237-1.236h3.576l1.236 1.236v5.928l-1.236 1.236H97.4l-1.236-1.236Zm3.647-3.071.889-.888v-1.524l-.48-.48h-2.305l-.48.48v1.932l.48.48h1.897Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={164.597}
      y={53.487}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m170.837 61.563-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.263 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={205.604}
      y={37.738}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m213.461 45.814-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.739 0-1.836.948v-1.284l2.076-1.14h1.152v8.4H218.2v-6.924Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={256.118}
      y={58.538}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m262.822 66.614-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.07 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={281.376}
      y={70.127}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m287.91 78.203-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={317.925}
      y={155.705}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m324.599 163.781-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.802 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={325.057}
      y={248.712}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m331.573 256.788-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.142 5.688v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={312.576}
      y={295.959}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m318.993 304.035-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.688v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={41.571}
      y={285}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m48.591 293.076-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.91-.048v-.228h-4.247v-1.2h5.688v1.464L53.594 300H52.13l3.372-6.972Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M130.16 287.639h-4.028v-4.526h4.028zM130.16 278.587h-4.028v-4.526h4.028zM130.16 269.536h-4.028v-4.526h4.028zM130.16 260.484h-4.028v-4.526h4.028zM130.16 251.432h-4.028v-4.526h4.028zM126.132 283.113h-4.028v-4.526h4.028z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M126.132 274.061h-4.028v-4.526h4.028zM126.132 265.01h-4.028v-4.526h4.028zM126.132 255.958h-4.028v-4.526h4.028z"
    />
    <path
      fill="#000"
      d="M126.132 287.639h-4.028v-4.526h4.028zM126.132 278.587h-4.028v-4.526h4.028zM126.132 269.536h-4.028v-4.526h4.028zM126.132 260.484h-4.028v-4.526h4.028zM126.132 251.432h-4.028v-4.526h4.028zM130.16 283.113h-4.028v-4.526h4.028z"
    />
    <path
      fill="#000"
      d="M130.16 274.061h-4.028v-4.526h4.028zM130.16 265.01h-4.028v-4.526h4.028zM130.16 255.958h-4.028v-4.526h4.028z"
    />
    <path
      fill="#E2645A"
      d="m156.093 241.257-14.82-9.857v19.761l14.82-9.904Zm2.263 0c0 .728-.299 1.408-.896 1.796l-15.546 10.342c-1.196.825-2.691-.146-2.691-1.748v-20.781c0-1.553 1.495-2.573 2.691-1.796l15.546 10.39c.597.437.896 1.117.896 1.797Z"
    />
  </svg>
));
export default SvgIconMiamiMinimapMarkers;
