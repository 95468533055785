import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSuspensionRide = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <g stroke="#fff" strokeOpacity={0.95} opacity={0.6}>
      <path d="M4 13.1c-.162 0-.359-.075-.583-.296-.226-.223-.453-.572-.654-1.041C2.36 10.825 2.1 9.495 2.1 8s.26-2.825.663-3.763c.2-.47.428-.818.654-1.041.224-.221.42-.296.583-.296.162 0 .359.075.583.296.227.223.453.572.655 1.041.401.938.662 2.268.662 3.763s-.26 2.825-.662 3.763c-.202.47-.428.818-.655 1.041-.224.221-.42.296-.583.296Z" />
      <path d="M6.4 13.1c-.162 0-.359-.075-.583-.296-.226-.223-.453-.572-.654-1.041C4.76 10.825 4.5 9.495 4.5 8s.26-2.825.663-3.763c.2-.47.428-.818.654-1.041.224-.221.42-.296.583-.296.162 0 .359.075.583.296.226.223.453.572.655 1.041.401.938.662 2.268.662 3.763s-.26 2.825-.662 3.763c-.202.47-.429.818-.655 1.041-.224.221-.42.296-.583.296Z" />
      <path d="M8.8 13.1c-.162 0-.359-.075-.583-.296-.226-.223-.453-.572-.654-1.041C7.16 10.825 6.9 9.495 6.9 8s.26-2.825.663-3.763c.201-.47.428-.818.654-1.041.224-.221.42-.296.583-.296.162 0 .359.075.583.296.227.223.453.572.655 1.041.402.938.662 2.268.662 3.763s-.26 2.825-.662 3.763c-.202.47-.428.818-.655 1.041-.224.221-.42.296-.583.296Z" />
      <path d="M11.2 13.1c-.162 0-.359-.075-.583-.296-.226-.223-.453-.572-.654-1.041C9.56 10.825 9.3 9.495 9.3 8s.26-2.825.663-3.763c.2-.47.428-.818.654-1.041.224-.221.42-.296.583-.296.162 0 .359.075.583.296.226.223.453.572.655 1.041.401.938.662 2.268.662 3.763s-.26 2.825-.662 3.763c-.202.47-.429.818-.655 1.041-.224.221-.42.296-.583.296Z" />
    </g>
  </svg>
));
export default SvgIconSuspensionRide;
