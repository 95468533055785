import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconToneFilled = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="m6.352 17.992 5.507-5.508 1.329 1.329L7.68 19.32a2.485 2.485 0 0 1-1.758.742H4.125l-1.406.82a1.247 1.247 0 0 1-1.406-.195 1.248 1.248 0 0 1-.196-1.406l.82-1.406v-1.797c0-.664.274-1.289.743-1.758l5.508-5.508 1.328 1.329-5.508 5.507a.627.627 0 0 0-.196.43v2.11h2.11a.627.627 0 0 0 .43-.196Zm13.515-15.86a3.679 3.679 0 0 1 0 5.274l-4.336 4.336.508.469c.352.39.352.976 0 1.328a.915.915 0 0 1-.664.274c-.273 0-.508-.079-.664-.274L8.46 7.29c-.39-.351-.39-.937 0-1.328.351-.352.937-.352 1.328 0l.469.508 4.336-4.336a3.679 3.679 0 0 1 5.273 0Z"
    />
  </svg>
));
export default SvgIconToneFilled;
