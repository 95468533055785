import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRaincloud = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M17.083 5a3.751 3.751 0 0 1 0 7.5h-12.5c-2.07 0-3.75-1.68-3.75-3.75a3.657 3.657 0 0 1 2.578-3.516c-.078-.273-.078-.546-.078-.859C3.333 1.992 5.286 0 7.708 0c1.68 0 3.125.977 3.867 2.383a3.104 3.104 0 0 1 2.383-1.133 3.134 3.134 0 0 1 3.125 3.125c0 .234-.039.43-.078.664 0 0 .04-.039.078-.039Zm-2.5 11.563a.95.95 0 0 1 .938.937v1.563c0 .546-.43.937-.938.937-.547 0-.937-.39-.937-.938V17.5c0-.508.39-.938.937-.938Zm3.75-2.813a.95.95 0 0 1 .938.938v1.562c0 .547-.43.938-.938.938-.547 0-.937-.391-.937-.938v-1.563c0-.507.39-.937.937-.937Zm-11.25 2.813a.95.95 0 0 1 .938.937v1.563c0 .546-.43.937-.938.937-.547 0-.937-.39-.937-.938V17.5c0-.508.39-.938.937-.938Zm-3.75-2.813a.95.95 0 0 1 .938.938v1.562c0 .547-.43.938-.938.938-.547 0-.937-.391-.937-.938v-1.563c0-.507.39-.937.937-.937Zm7.5 0a.95.95 0 0 1 .938.938v1.562c0 .547-.43.938-.938.938-.547 0-.937-.391-.937-.938v-1.563c0-.507.39-.937.937-.937Z"
    />
  </svg>
));
export default SvgIconRaincloud;
