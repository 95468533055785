import React from 'react';
import afghanistanFlag from 'public/images/flags/afghanistan.svg';
import albaniaFlag from 'public/images/flags/albania.svg';
import algeriaFlag from 'public/images/flags/algeria.svg';
import andorraFlag from 'public/images/flags/andorra.svg';
import angolaFlag from 'public/images/flags/angola.svg';
import antiguaAndBarbudaFlag from 'public/images/flags/antiguaAndBarbuda.svg';
import argentinaFlag from 'public/images/flags/argentina.svg';
import armeniaFlag from 'public/images/flags/armenia.svg';
import arubaFlag from 'public/images/flags/aruba.svg';
import australiaFlag from 'public/images/flags/australia.svg';
import austriaFlag from 'public/images/flags/austria.svg';
import azerbaijanFlag from 'public/images/flags/azerbaijan.svg';
import bahamasFlag from 'public/images/flags/bahamas.svg';
import bahrainFlag from 'public/images/flags/bahrain.svg';
import bangladeshFlag from 'public/images/flags/bangladesh.svg';
import barbadosFlag from 'public/images/flags/barbados.svg';
import belarusFlag from 'public/images/flags/belarus.svg';
import belgiumFlag from 'public/images/flags/belgium.svg';
import belizeFlag from 'public/images/flags/belize.svg';
import beninFlag from 'public/images/flags/benin.svg';
import bhutanFlag from 'public/images/flags/bhutan.svg';
import boliviaFlag from 'public/images/flags/bolivia.svg';
import bosniaAndHerzegovinaFlag from 'public/images/flags/bosniaAndHerzegovina.svg';
import botswanaFlag from 'public/images/flags/botswana.svg';
import bouvetIslandFlag from 'public/images/flags/bouvetIsland.svg';
import brazilFlag from 'public/images/flags/brazil.svg';
import bruneiDarussalamFlag from 'public/images/flags/bruneiDarussalam.svg';
import bulgariaFlag from 'public/images/flags/bulgaria.svg';
import burkinaFasoFlag from 'public/images/flags/burkinaFaso.svg';
import burundiFlag from 'public/images/flags/burundi.svg';
import cambodiaFlag from 'public/images/flags/cambodia.svg';
import cameroonFlag from 'public/images/flags/cameroon.svg';
import canadaFlag from 'public/images/flags/canada.svg';
import capeVerdeFlag from 'public/images/flags/capeVerde.svg';
import centralAfricanRepublicFlag from 'public/images/flags/centralAfricanRepublic.svg';
import chadFlag from 'public/images/flags/chad.svg';
import chileFlag from 'public/images/flags/chile.svg';
import chinaFlag from 'public/images/flags/china.svg';
import colombiaFlag from 'public/images/flags/colombia.svg';
import comorosFlag from 'public/images/flags/comoros.svg';
import congoFlag from 'public/images/flags/congo.svg';
import costaRicaFlag from 'public/images/flags/costaRica.svg';
import croatiaFlag from 'public/images/flags/croatia.svg';
import cubaFlag from 'public/images/flags/cuba.svg';
import curacaoFlag from 'public/images/flags/curacao.svg';
import cyprusFlag from 'public/images/flags/cyprus.svg';
import czechRepublicFlag from 'public/images/flags/czechRepublic.svg';
import defaultFlag from 'public/images/flags/default.svg';
import denmarkFlag from 'public/images/flags/denmark.svg';
import djiboutiFlag from 'public/images/flags/djibouti.svg';
import dominicaFlag from 'public/images/flags/dominica.svg';
import dominicanRepublicFlag from 'public/images/flags/dominicanRepublic.svg';
import drCongoFlag from 'public/images/flags/drCongo.svg';
import ecuadorFlag from 'public/images/flags/ecuador.svg';
import egyptFlag from 'public/images/flags/egypt.svg';
import elSalvadorFlag from 'public/images/flags/elSalvador.svg';
import equatorialGuineaFlag from 'public/images/flags/equatorialGuinea.svg';
import eritreaFlag from 'public/images/flags/eritrea.svg';
import estoniaFlag from 'public/images/flags/estonia.svg';
import eswatiniFlag from 'public/images/flags/eswatini.svg';
import ethiopiaFlag from 'public/images/flags/ethopia.svg';
import europeFlag from 'public/images/flags/europe.svg';
import fijiFlag from 'public/images/flags/fiji.svg';
import finlandFlag from 'public/images/flags/finland.svg';
import franceFlag from 'public/images/flags/france.svg';
import gabonFlag from 'public/images/flags/gabon.svg';
import gambiaFlag from 'public/images/flags/gambia.svg';
import georgiaFlag from 'public/images/flags/georgia.svg';
import germanyFlag from 'public/images/flags/germany.svg';
import ghanaFlag from 'public/images/flags/ghana.svg';
import greeceFlag from 'public/images/flags/greece.svg';
import grenadaFlag from 'public/images/flags/grenada.svg';
import guatemalaFlag from 'public/images/flags/guatamala.svg';
import guineaFlag from 'public/images/flags/guinea.svg';
import guineaBissauFlag from 'public/images/flags/guineaBissau.svg';
import guyanaFlag from 'public/images/flags/guyana.svg';
import haitiFlag from 'public/images/flags/haiti.svg';
import holySeeFlag from 'public/images/flags/holySee.svg';
import hondurasFlag from 'public/images/flags/honduras.svg';
import hongKongFlag from 'public/images/flags/hongKong.svg';
import hungaryFlag from 'public/images/flags/hungary.svg';
import icelandFlag from 'public/images/flags/iceland.svg';
import indiaFlag from 'public/images/flags/india.svg';
import indonesiaFlag from 'public/images/flags/indonesia.svg';
import iranFlag from 'public/images/flags/iran.svg';
import iraqFlag from 'public/images/flags/iraq.svg';
import irelandFlag from 'public/images/flags/ireland.svg';
import israelFlag from 'public/images/flags/israel.svg';
import italyFlag from 'public/images/flags/italy.svg';
import ivoryCoastFlag from 'public/images/flags/ivoryCoast.svg';
import jamaicaFlag from 'public/images/flags/jamaica.svg';
import japanFlag from 'public/images/flags/japan.svg';
import jordanFlag from 'public/images/flags/jordan.svg';
import kazakhstanFlag from 'public/images/flags/kazakhstan.svg';
import kenyaFlag from 'public/images/flags/kenya.svg';
import kiribatiFlag from 'public/images/flags/kiribati.svg';
import kuwaitFlag from 'public/images/flags/kuwait.svg';
import kyrgyzstanFlag from 'public/images/flags/kyrgyzstan.svg';
import laosFlag from 'public/images/flags/laos.svg';
import latviaFlag from 'public/images/flags/latvia.svg';
import lebanonFlag from 'public/images/flags/lebanon.svg';
import lesothoFlag from 'public/images/flags/lesotho.svg';
import liberiaFlag from 'public/images/flags/liberia.svg';
import libyaFlag from 'public/images/flags/libya.svg';
import liechtensteinFlag from 'public/images/flags/liechtenstein.svg';
import lithuaniaFlag from 'public/images/flags/lithuania.svg';
import luxembourgFlag from 'public/images/flags/luxembourg.svg';
import madagascarFlag from 'public/images/flags/madagascar.svg';
import malawiFlag from 'public/images/flags/malawi.svg';
import malaysiaFlag from 'public/images/flags/malaysia.svg';
import maldivesFlag from 'public/images/flags/maldives.svg';
import maliFlag from 'public/images/flags/mali.svg';
import maltaFlag from 'public/images/flags/malta.svg';
import moroccoFlag from 'public/images/flags/marocco.svg';
import marshallIslandsFlag from 'public/images/flags/marshallIslands.svg';
import mauritaniaFlag from 'public/images/flags/mauritania.svg';
import mauritiusFlag from 'public/images/flags/mauritius.svg';
import mexicoFlag from 'public/images/flags/mexico.svg';
import micronesiaFlag from 'public/images/flags/micronesia.svg';
import moldovaFlag from 'public/images/flags/moldova.svg';
import monacoFlag from 'public/images/flags/monaco.svg';
import mongoliaFlag from 'public/images/flags/mongolia.svg';
import montenegroFlag from 'public/images/flags/montenegro.svg';
import mozambiqueFlag from 'public/images/flags/mozambique.svg';
import myanmarFlag from 'public/images/flags/myanmar.svg';
import namibiaFlag from 'public/images/flags/namibia.svg';
import nauruFlag from 'public/images/flags/nauru.svg';
import nepalFlag from 'public/images/flags/nepal.svg';
import netherlandsFlag from 'public/images/flags/netherlands.svg';
import newZealandFlag from 'public/images/flags/newZealand.svg';
import nicaraguaFlag from 'public/images/flags/nicaragua.svg';
import nigerFlag from 'public/images/flags/niger.svg';
import nigeriaFlag from 'public/images/flags/nigeria.svg';
import northKoreaFlag from 'public/images/flags/northKorea.svg';
import northMacedoniaFlag from 'public/images/flags/northMacedonia.svg';
import norwayFlag from 'public/images/flags/norway.svg';
import omanFlag from 'public/images/flags/oman.svg';
import pakistanFlag from 'public/images/flags/pakistan.svg';
import palauFlag from 'public/images/flags/palau.svg';
import palestineFlag from 'public/images/flags/palestine.svg';
import panamaFlag from 'public/images/flags/panama.svg';
import papuaNewGuineaFlag from 'public/images/flags/papuaNewGuinea.svg';
import paraguayFlag from 'public/images/flags/paraguay.svg';
import peruFlag from 'public/images/flags/peru.svg';
import philippinesFlag from 'public/images/flags/philippines.svg';
import polandFlag from 'public/images/flags/poland.svg';
import portugalFlag from 'public/images/flags/portugal.svg';
import qatarFlag from 'public/images/flags/qatar.svg';
import romaniaFlag from 'public/images/flags/romania.svg';
import russianFederationFlag from 'public/images/flags/russianFederation.svg';
import rwandaFlag from 'public/images/flags/rwanda.svg';
import saintLuciaFlag from 'public/images/flags/saintLucia.svg';
import saintVincentAndTheGrenadinesFlag from 'public/images/flags/saintVincentAndTheGrenadines.svg';
import samoaFlag from 'public/images/flags/samoa.svg';
import sanMarinoFlag from 'public/images/flags/sanMarino.svg';
import saoTomeAndPrincipeFlag from 'public/images/flags/saoTomeAndPrincipe.svg';
import saudiArabiaFlag from 'public/images/flags/saudiArabia.svg';
import seychellesFlag from 'public/images/flags/saychelles.svg';
import senegalFlag from 'public/images/flags/senegal.svg';
import serbiaFlag from 'public/images/flags/serbia.svg';
import sierraLeoneFlag from 'public/images/flags/sierraLeone.svg';
import singaporeFlag from 'public/images/flags/singapore.svg';
import sintMaartenFlag from 'public/images/flags/sintMaarten.svg';
import slovakiaFlag from 'public/images/flags/slovakia.svg';
import sloveniaFlag from 'public/images/flags/slovenia.svg';
import solomonIslandsFlag from 'public/images/flags/solomonIslands.svg';
import somaliaFlag from 'public/images/flags/somalia.svg';
import southAfricaFlag from 'public/images/flags/southAfrica.svg';
import southKoreaFlag from 'public/images/flags/southKorea.svg';
import southSudanFlag from 'public/images/flags/southSudan.svg';
import spainFlag from 'public/images/flags/spain.svg';
import sriLankaFlag from 'public/images/flags/sriLanka.svg';
import sudanFlag from 'public/images/flags/sudan.svg';
import surinameFlag from 'public/images/flags/suriname.svg';
import swedenFlag from 'public/images/flags/sweden.svg';
import switzerlandFlag from 'public/images/flags/switzerland.svg';
import syrianArabRepublicFlag from 'public/images/flags/syrianArabRepublic.svg';
import tajikistanFlag from 'public/images/flags/tajistan.svg';
import tanzaniaFlag from 'public/images/flags/tanzania.svg';
import thailandFlag from 'public/images/flags/thailand.svg';
import timorLesteFlag from 'public/images/flags/timorLeste.svg';
import togoFlag from 'public/images/flags/togo.svg';
import tongaFlag from 'public/images/flags/tonga.svg';
import trinidadAndTobagoFlag from 'public/images/flags/trinidadAndTobago.svg';
import tunisiaFlag from 'public/images/flags/tunisia.svg';
import turkeyFlag from 'public/images/flags/turkey.svg';
import turkmenistanFlag from 'public/images/flags/turkmenistan.svg';
import tuvaluFlag from 'public/images/flags/tuvalu.svg';
import ugandaFlag from 'public/images/flags/uganda.svg';
import ukraineFlag from 'public/images/flags/ukraine.svg';
import unitedArabEmiratesFlag from 'public/images/flags/unitedArabEmirates.svg';
import unitedKingdomFlag from 'public/images/flags/unitedKingdom.svg';
import unitedStatesOfAmericaFlag from 'public/images/flags/unitedStatesOfAmerica.svg';
import uruguayFlag from 'public/images/flags/uruguay.svg';
import uzbekistanFlag from 'public/images/flags/uzbekistan.svg';
import vanuatuFlag from 'public/images/flags/vanuaty.svg';
import venezuelaFlag from 'public/images/flags/venezuela.svg';
import vietnamFlag from 'public/images/flags/vietnam.svg';
import virginIslandsFlag from 'public/images/flags/virginIslands.svg';
import yemenFlag from 'public/images/flags/yemen.svg';
import zambiaFlag from 'public/images/flags/zambia.svg';
import zimbabweFlag from 'public/images/flags/zimbabwe.svg';
import { RImage } from 'components';

export interface FlagsProps {
  // use the ISO 3166-1 alpha-2 country codes
  countryCode?:
    | 'default'
    | 'AF'
    | 'AL'
    | 'DZ'
    | 'AD'
    | 'AO'
    | 'AG'
    | 'AR'
    | 'AM'
    | 'AW'
    | 'AU'
    | 'AT'
    | 'AZ'
    | 'BS'
    | 'BH'
    | 'BD'
    | 'BB'
    | 'BY'
    | 'BE'
    | 'BZ'
    | 'BJ'
    | 'BT'
    | 'BO'
    | 'BA'
    | 'BW'
    | 'BV'
    | 'BR'
    | 'BN'
    | 'BG'
    | 'BF'
    | 'BI'
    | 'KH'
    | 'CM'
    | 'CA'
    | 'CV'
    | 'CF'
    | 'TD'
    | 'CL'
    | 'CN'
    | 'CO'
    | 'KM'
    | 'CG'
    | 'CR'
    | 'HR'
    | 'CU'
    | 'CW'
    | 'CY'
    | 'CZ'
    | 'DK'
    | 'DJ'
    | 'DM'
    | 'DO'
    | 'CD'
    | 'EC'
    | 'EG'
    | 'SV'
    | 'GQ'
    | 'ER'
    | 'EE'
    | 'SZ'
    | 'ET'
    | 'EU'
    | 'FJ'
    | 'FI'
    | 'FR'
    | 'GA'
    | 'GM'
    | 'GE'
    | 'DE'
    | 'GH'
    | 'GR'
    | 'GD'
    | 'GT'
    | 'GN'
    | 'GW'
    | 'GY'
    | 'HT'
    | 'VA'
    | 'HN'
    | 'HK'
    | 'HU'
    | 'IS'
    | 'IN'
    | 'ID'
    | 'IR'
    | 'IQ'
    | 'IE'
    | 'IL'
    | 'IT'
    | 'CI'
    | 'JM'
    | 'JP'
    | 'JO'
    | 'KZ'
    | 'KE'
    | 'KI'
    | 'KW'
    | 'KG'
    | 'LA'
    | 'LV'
    | 'LB'
    | 'LS'
    | 'LR'
    | 'LY'
    | 'LI'
    | 'LT'
    | 'LU'
    | 'MG'
    | 'MW'
    | 'MY'
    | 'MV'
    | 'ML'
    | 'MT'
    | 'MA'
    | 'MH'
    | 'MR'
    | 'MU'
    | 'MX'
    | 'FM'
    | 'MD'
    | 'MC'
    | 'MN'
    | 'ME'
    | 'MZ'
    | 'MM'
    | 'NA'
    | 'NR'
    | 'NP'
    | 'NL'
    | 'NZ'
    | 'NI'
    | 'NE'
    | 'NG'
    | 'KP'
    | 'MK'
    | 'NO'
    | 'OM'
    | 'PK'
    | 'PW'
    | 'PS'
    | 'PA'
    | 'PG'
    | 'PY'
    | 'PE'
    | 'PH'
    | 'PL'
    | 'PT'
    | 'QA'
    | 'RO'
    | 'RU'
    | 'RW'
    | 'LC'
    | 'VC'
    | 'WS'
    | 'SM'
    | 'ST'
    | 'SA'
    | 'SC'
    | 'SN'
    | 'RS'
    | 'SL'
    | 'SG'
    | 'SX'
    | 'SK'
    | 'SI'
    | 'SB'
    | 'SO'
    | 'ZA'
    | 'KR'
    | 'SS'
    | 'ES'
    | 'LK'
    | 'SD'
    | 'SR'
    | 'SE'
    | 'CH'
    | 'SY'
    | 'TJ'
    | 'TZ'
    | 'TH'
    | 'TL'
    | 'TG'
    | 'TO'
    | 'TT'
    | 'TN'
    | 'TR'
    | 'TM'
    | 'TV'
    | 'UG'
    | 'UA'
    | 'AE'
    | 'GB'
    | 'US'
    | 'UY'
    | 'UZ'
    | 'VU'
    | 'VE'
    | 'VN'
    | 'VI'
    | 'YE'
    | 'ZM'
    | 'ZW';
  size?: 'small' | 'medium' | 'large' | 'full';
}

const Flags: React.FC<FlagsProps> = ({
  // use the ISO 3166-1 alpha-2 country codes
  countryCode = 'default',
  size = 'medium',
}) => {
  const flags = {
    default: defaultFlag,
    AF: afghanistanFlag,
    AL: albaniaFlag,
    DZ: algeriaFlag,
    AD: andorraFlag,
    AO: angolaFlag,
    AG: antiguaAndBarbudaFlag,
    AR: argentinaFlag,
    AM: armeniaFlag,
    AW: arubaFlag,
    AU: australiaFlag,
    AT: austriaFlag,
    AZ: azerbaijanFlag,
    BS: bahamasFlag,
    BH: bahrainFlag,
    BD: bangladeshFlag,
    BB: barbadosFlag,
    BY: belarusFlag,
    BE: belgiumFlag,
    BZ: belizeFlag,
    BJ: beninFlag,
    BT: bhutanFlag,
    BO: boliviaFlag,
    BA: bosniaAndHerzegovinaFlag,
    BW: botswanaFlag,
    BV: bouvetIslandFlag,
    BR: brazilFlag,
    BN: bruneiDarussalamFlag,
    BG: bulgariaFlag,
    BF: burkinaFasoFlag,
    BI: burundiFlag,
    KH: cambodiaFlag,
    CM: cameroonFlag,
    CA: canadaFlag,
    CV: capeVerdeFlag,
    CF: centralAfricanRepublicFlag,
    TD: chadFlag,
    CL: chileFlag,
    CN: chinaFlag,
    CO: colombiaFlag,
    KM: comorosFlag,
    CG: congoFlag,
    CR: costaRicaFlag,
    HR: croatiaFlag,
    CU: cubaFlag,
    CW: curacaoFlag,
    CY: cyprusFlag,
    CZ: czechRepublicFlag,
    DK: denmarkFlag,
    DJ: djiboutiFlag,
    DM: dominicaFlag,
    DO: dominicanRepublicFlag,
    CD: drCongoFlag,
    EC: ecuadorFlag,
    EG: egyptFlag,
    SV: elSalvadorFlag,
    GQ: equatorialGuineaFlag,
    ER: eritreaFlag,
    EE: estoniaFlag,
    SZ: eswatiniFlag,
    ET: ethiopiaFlag, // Note that "ethopia" was misspelled. Corrected to "ethiopia".
    EU: europeFlag,
    FJ: fijiFlag,
    FI: finlandFlag,
    FR: franceFlag,
    GA: gabonFlag,
    GM: gambiaFlag,
    GE: georgiaFlag,
    DE: germanyFlag,
    GH: ghanaFlag,
    GR: greeceFlag,
    GD: grenadaFlag,
    GT: guatemalaFlag, // Corrected the misspelling "guatamala".
    GN: guineaFlag,
    GW: guineaBissauFlag,
    GY: guyanaFlag,
    HT: haitiFlag,
    VA: holySeeFlag,
    HN: hondurasFlag,
    HK: hongKongFlag,
    HU: hungaryFlag,
    IS: icelandFlag,
    IN: indiaFlag,
    ID: indonesiaFlag,
    IR: iranFlag,
    IQ: iraqFlag,
    IE: irelandFlag,
    IL: israelFlag,
    IT: italyFlag,
    CI: ivoryCoastFlag, // Note that Ivory Coast is commonly known as Côte d'Ivoire, with the code "CI".
    JM: jamaicaFlag,
    JP: japanFlag,
    JO: jordanFlag,
    KZ: kazakhstanFlag,
    KE: kenyaFlag,
    KI: kiribatiFlag,
    KW: kuwaitFlag,
    KG: kyrgyzstanFlag,
    LA: laosFlag,
    LV: latviaFlag,
    LB: lebanonFlag,
    LS: lesothoFlag,
    LR: liberiaFlag,
    LY: libyaFlag,
    LI: liechtensteinFlag,
    LT: lithuaniaFlag,
    LU: luxembourgFlag,
    MG: madagascarFlag,
    MW: malawiFlag,
    MY: malaysiaFlag,
    MV: maldivesFlag,
    ML: maliFlag,
    MT: maltaFlag,
    MA: moroccoFlag, // Corrected the misspelling "marocco".
    MH: marshallIslandsFlag,
    MR: mauritaniaFlag,
    MU: mauritiusFlag,
    MX: mexicoFlag,
    FM: micronesiaFlag,
    MD: moldovaFlag,
    MC: monacoFlag,
    MN: mongoliaFlag,
    ME: montenegroFlag,
    MZ: mozambiqueFlag,
    MM: myanmarFlag,
    NA: namibiaFlag,
    NR: nauruFlag,
    NP: nepalFlag,
    NL: netherlandsFlag,
    NZ: newZealandFlag,
    NI: nicaraguaFlag,
    NE: nigerFlag,
    NG: nigeriaFlag,
    KP: northKoreaFlag,
    MK: northMacedoniaFlag,
    NO: norwayFlag,
    OM: omanFlag,
    PK: pakistanFlag,
    PW: palauFlag,
    PS: palestineFlag,
    PA: panamaFlag,
    PG: papuaNewGuineaFlag,
    PY: paraguayFlag,
    PE: peruFlag,
    PH: philippinesFlag,
    PL: polandFlag,
    PT: portugalFlag,
    QA: qatarFlag,
    RO: romaniaFlag,
    RU: russianFederationFlag,
    RW: rwandaFlag,
    LC: saintLuciaFlag,
    VC: saintVincentAndTheGrenadinesFlag,
    WS: samoaFlag,
    SM: sanMarinoFlag,
    ST: saoTomeAndPrincipeFlag,
    SA: saudiArabiaFlag,
    SC: seychellesFlag,
    SN: senegalFlag,
    RS: serbiaFlag,
    SL: sierraLeoneFlag,
    SG: singaporeFlag,
    SX: sintMaartenFlag,
    SK: slovakiaFlag,
    SI: sloveniaFlag,
    SB: solomonIslandsFlag,
    SO: somaliaFlag,
    ZA: southAfricaFlag,
    KR: southKoreaFlag,
    SS: southSudanFlag,
    ES: spainFlag,
    LK: sriLankaFlag,
    SD: sudanFlag,
    SR: surinameFlag,
    SE: swedenFlag,
    CH: switzerlandFlag,
    SY: syrianArabRepublicFlag,
    TJ: tajikistanFlag, // Corrected the misspelling "tajistan".
    TZ: tanzaniaFlag,
    TH: thailandFlag,
    TL: timorLesteFlag,
    TG: togoFlag,
    TO: tongaFlag,
    TT: trinidadAndTobagoFlag,
    TN: tunisiaFlag,
    TR: turkeyFlag,
    TM: turkmenistanFlag,
    TV: tuvaluFlag,
    UG: ugandaFlag,
    UA: ukraineFlag,
    AE: unitedArabEmiratesFlag,
    GB: unitedKingdomFlag,
    US: unitedStatesOfAmericaFlag,
    UY: uruguayFlag,
    UZ: uzbekistanFlag,
    VU: vanuatuFlag,
    VE: venezuelaFlag,
    VN: vietnamFlag,
    VI: virginIslandsFlag,
    YE: yemenFlag,
    ZM: zambiaFlag,
    ZW: zimbabweFlag,
  };
  const flagSelected = flags[countryCode] || 'default';
  const flagSize = {
    small: 15,
    medium: 25,
    large: 30,
    full: '100%',
  };

  return (
    <RImage
      src={flagSelected}
      alt="Tier"
      width={flagSize[size]}
      height={flagSize[size]}
    />
  );
};

export default Flags;
