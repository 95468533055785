import { RInput } from 'components';
import { useState } from 'react';

export const SenderWalletInput = ({
  value,
  onChange,
  setCanSubmit,
}: {
  value: string;
  onChange: (e: string) => void;
  setCanSubmit: (e: boolean) => void;
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    setErrorMessage(undefined);
    if (!e.target.value.startsWith('0x')) {
      setErrorMessage('Invalid wallet address');
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  };

  return (
    <RInput
      label="Enter address"
      value={value}
      onChange={handleChange}
      errorMessage={errorMessage}
      isInvalid={errorMessage !== undefined}
    />
  );
};
