import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconVext = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    viewBox="0 0 20 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 7.223 0 4.361l7.513 7.922-3.016 4.856L10 14.683l5.503 2.456-3.016-4.856L20 4.36 10 7.223Zm0 1.404L3.28 6.374 10 10.436l6.72-4.062L10 8.627ZM2.928 6.41 10 11.304l7.072-4.893-5.855 5.798 3.35 4.025L10 13.04l-4.568 3.194 3.351-4.025L2.928 6.41Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconVext;
