import { Dispatch, SetStateAction, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import {
  IconCheckGreen,
  IconCheckSquare,
  IconMedal,
  IconMoneyBag,
  IconTicket,
  IconTicketTournament,
  IconUncheckGrey,
} from 'icons';
import {
  activeCarSelectBgGradient,
  carSelectBgGradient,
} from 'utils/themeConstants';
import { RaceThumbnail } from 'components/Races2/RaceThumbnail/RaceThumbnail';
import { Races } from 'api/generated/graphql';
import { SVGIcon } from 'types';
import RImage from 'components/Image/Image';

interface RaceTicketProps {
  race?: Races;
  numberOfTickets?: number;
  type?: 'racino' | 'leaderboard' | 'tournament';
  isSelected?: boolean;
  setIsSelected?: Dispatch<SetStateAction<boolean | undefined>>;
}

export const RaceTicket = ({
  race,
  numberOfTickets = 0,
  type = 'racino',
  isSelected = true,
  setIsSelected,
}: RaceTicketProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const borderColor = isSelected
    ? 'treasuredTeal.100'
    : isHovered
    ? 'cerebralGrey.100'
    : 'carbon.100';

  return (
    <Flex
      w="full"
      h={{ base: '4rem', md: '4.5rem' }}
      cursor="pointer"
      borderWidth={2}
      borderColor={borderColor}
      borderRadius="0.25rem"
      mb="0.25rem"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (setIsSelected) setIsSelected((oldState) => !oldState);
      }}
    >
      <Flex
        pos="relative"
        w={{ base: '6.875rem', md: '7.5rem' }}
        h="full"
        bg="black"
        alignItems="center"
        justifyContent="center"
      >
        {type === 'leaderboard' ? (
          <RImage
            alt="leaderboard jackpot"
            src={require('public/images/tickets/leaderboard-jackpot.png')}
            w="full"
            h="full"
          />
        ) : (
          race && (
            <RaceThumbnail
              trackName={race.track.frontendName}
              tierName={race.tier.name}
            />
          )
        )}
      </Flex>
      <Flex
        h="full"
        w={{ base: 'calc(100% - 110px)', md: 'calc(100% - 120px)' }}
        py={{ base: '0.5rem', md: '0.75rem' }}
        px={{ base: '0.5rem', md: '1rem' }}
        justifyContent="space-between"
        bg={isSelected ? activeCarSelectBgGradient : carSelectBgGradient}
      >
        <Flex h="full" flexDir="column" justifyContent="space-between">
          <BulletPoint
            Icon={IconMedal}
            text={type === 'leaderboard' ? 'Earn Points' : 'Defeat Rivals'}
            subtitle={type === 'leaderboard' ? 'Daily' : null}
          />
          <BulletPoint
            Icon={IconMoneyBag}
            text={
              type === 'leaderboard'
                ? 'Win Bigger'
                : type === 'tournament'
                ? 'Jackpot Payout'
                : 'Win Big'
            }
            subtitle={type !== 'racino' ? '$500.00' : null}
          />
        </Flex>

        <Flex
          h="full"
          alignItems="center"
          gap={{ base: '0.25rem', md: '0.5rem' }}
        >
          {type === 'tournament' ? (
            <IconTicketTournament
              w={{ base: '1.5rem', md: '2.5rem' }}
              h={{ base: '1.5rem', md: '2.5rem' }}
            />
          ) : (
            <IconTicket
              w={{ base: '1.5rem', md: '2.5rem' }}
              h={{ base: '1.5rem', md: '2.5rem' }}
            />
          )}
          <Text
            fontSize={{
              base: '1.5rem',
              md: '2rem',
            }}
            color="white.100"
            fontWeight={600}
            mr={{ base: '0.25rem', md: '0.5rem' }}
          >
            {numberOfTickets}
          </Text>
          {isSelected && setIsSelected ? (
            <IconCheckSquare h="1rem" />
          ) : isSelected ? (
            <IconCheckGreen h="1rem" />
          ) : (
            <IconUncheckGrey h="1rem" />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const BulletPoint = ({
  Icon,
  text,
  subtitle,
}: {
  text: string;
  Icon: SVGIcon;
  subtitle?: string | null;
}) => {
  return (
    <Flex alignItems="center" gap="0.5rem">
      <Icon w="1rem" h="1rem" />
      <Text
        fontSize={{ base: '0.625rem', md: '0.75rem' }}
        lineHeight={{ base: '0.75rem', md: '0.875rem' }}
        color="white.80"
        textTransform="capitalize"
        fontWeight={600}
      >
        {text}
      </Text>
      {subtitle && (
        <Text
          fontSize={{ base: '0.625rem', md: '0.75rem' }}
          lineHeight={{ base: '0.75rem', md: '0.875rem' }}
          color="treasuredTeal.100"
          textTransform="uppercase"
          fontWeight={600}
        >
          {subtitle}
        </Text>
      )}
    </Flex>
  );
};
