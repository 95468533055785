import { Flex, FlexProps } from '@chakra-ui/react';

import { colors as themeColors, defaultTransition } from 'utils/themeConstants';

type Props = {
  variant?: 'gradientToBlack' | 'gradientToTransparent';

  gradientStart?: 'right' | 'bottom' | 'left'; // side where border appears and gradient starts
  // both use these colors
  gradientColor?:
    | 'grey'
    | 'green'
    | 'orange'
    | 'red'
    | 'yellow'
    | 'blue'
    | 'violet';
  isHovering?: boolean;
  showNoOpacityBorder?: boolean;
  hasInsetBorder?: boolean;
  backgroundProps?: FlexProps;
};

const BaseCardGradient = ({
  variant = 'gradientToBlack',
  gradientColor = 'grey',
  showNoOpacityBorder = false,
  gradientStart = 'bottom',
  hasInsetBorder,
  isHovering,
  backgroundProps,
  ...rest
}: FlexProps & Props) => {
  // gradient starts from the bottom by default, within parent container
  let styles: any = {
    gradientAngle: 0,
    after: {
      height: '.35rem',
      borderTop: 0,
      left: '1',
      right: '1',
      bottom: '1',
    },
  };

  // right and left options place the gradient outside of parent
  if (gradientStart === 'right') {
    styles = {
      gradientAngle: 270,
      after: {
        height: 'calc(98% - 9px)',
        width: '.5rem',
        borderLeft: 0,
        right: '4px',
        bottom: '3px',
      },
    };
  }

  if (gradientStart === 'left') {
    styles = {
      gradientAngle: 90,
      after: {
        height: 'calc(98% - 9px)',
        width: '.5rem',
        borderRight: 0,
        left: '4px',
        bottom: '3px',
      },
    };
  }

  const colors = {
    grey: {
      transparentBg: `linear-gradient(${styles.gradientAngle}deg, ${themeColors.white[20]}, transparent)`,
      gradient: `linear-gradient(${
        styles.gradientAngle + 180
      }deg, #000 44.27%, #5B5B5B 72.92%, #353535 100%)`,
      border: isHovering ? themeColors.white[100] : themeColors.white[30],
      hoverColor: themeColors.white['05'],
    },
    green: {
      transparentBg: `linear-gradient(${styles.gradientAngle}deg, ${themeColors.aphroditeAqua[20]} , transparent)`,
      gradient: `linear-gradient(${
        styles.gradientAngle + 180
      }deg, #000 44.27%, #31584F 72.92%, #17322B 100%)`,
      border: isHovering
        ? themeColors.aphroditeAqua[100]
        : themeColors.aphroditeAqua[30],
      hoverColor: themeColors.aphroditeAqua['05'],
    },
    orange: {
      transparentBg: `linear-gradient(${styles.gradientAngle}deg, ${themeColors.coralQuartz[20]} , transparent)`,
      gradient: `linear-gradient(${
        styles.gradientAngle + 180
      }deg, #000 44.27%, #704743 72.92%, #3F2517 100%)`,
      border: isHovering
        ? themeColors.coralQuartz[100]
        : themeColors.coralQuartz[30],
      hoverColor: themeColors.coralQuartz['05'],
    },
    red: {
      transparentBg: `linear-gradient(${styles.gradientAngle}deg, ${themeColors.bloodMoon[20]} , transparent)`,
      gradient: `linear-gradient(${
        styles.gradientAngle + 180
      }deg, #000 44.27%, #583131 72.92%, #321717 100%)`,
      border: isHovering
        ? themeColors.bloodMoon[100]
        : themeColors.bloodMoon[30],
      hoverColor: themeColors.bloodMoon['05'],
    },
    yellow: {
      transparentBg: `linear-gradient(${styles.gradientAngle}deg, ${themeColors.cowbell[20]} , transparent)`,
      gradient: `linear-gradient(${
        styles.gradientAngle + 180
      }deg, #000 40%, #2C2B26 70%, #3F3B17 100%)`,
      border: isHovering ? themeColors.cowbell[100] : themeColors.cowbell[30],
      hoverColor: themeColors.cowbell['05'],
    },

    blue: {
      transparentBg: `linear-gradient(${styles.gradientAngle}deg, ${themeColors.bleuchatel[20]} , transparent)`,
      gradient: `linear-gradient(${
        styles.gradientAngle + 180
      }deg, #000 44.27%, #37537B 72.92%, #17293F 100%)`,
      border: isHovering
        ? themeColors.bleuchatel[100]
        : themeColors.bleuchatel[30],
      hoverColor: themeColors.bleuchatel['05'],
    },
    violet: {
      transparentBg: `linear-gradient(${styles.gradientAngle}deg, ${themeColors.perrywinkle[20]} , transparent)`,
      gradient: `linear-gradient(${
        styles.gradientAngle + 180
      }deg, #000 44.27%, #4C4C6C 72.92%, #21173F 100%)`,
      border: isHovering
        ? themeColors.perrywinkle[100]
        : themeColors.perrywinkle[30],
      hoverColor: themeColors.perrywinkle['05'],
    },
  };

  return (
    <>
      <Flex
        position="absolute"
        bottom="0"
        left="0"
        width="full"
        height="full"
        zIndex="2 !important" // important to override zIndex applied to children by BaseCard
        _after={
          hasInsetBorder && {
            transition: defaultTransition,
            content: '""',
            border: 'solid 2px',
            borderColor: colors[gradientColor].border,
            position: 'absolute',
            ...styles.after,
          }
        }
        {...rest}
      />

      <Flex
        position="absolute"
        bottom="0"
        left="0"
        zIndex="-3"
        backgroundColor={isHovering ? colors[gradientColor].hoverColor : ''}
        background={
          variant === 'gradientToTransparent'
            ? colors[gradientColor].transparentBg
            : colors[gradientColor].gradient
        }
        width="full"
        height="full"
        _after={{
          transition: defaultTransition,
          content: '""',
          w: 'full',
          h: 'full',
          backgroundColor:
            !showNoOpacityBorder && isHovering
              ? colors[gradientColor].hoverColor
              : '',
        }}
        {...backgroundProps}
      />
    </>
  );
};

export default BaseCardGradient;
