import { Flex, Text } from "@chakra-ui/react"
import { useResults } from "context";
import { IconCar, IconEyeHidden, IconEyeShow } from "icons"
import { useState } from "react";
import { formatOrdinal } from "utils/helpers";

const PlacingResults = ({ playerName, playerPosition, alreadyOpen }: { playerName?: string, playerPosition?: number, alreadyOpen?: boolean }) => {
  const [showResults, setShowResults] = useState(alreadyOpen || false);
  const { showAllResults, setShowAllResults } = useResults();

  const handleClick = (e: any) => {
    e.stopPropagation();
    console.log("show clicked")
    setShowResults(!showResults);
    setShowAllResults(!showAllResults);
  }

  return (
    <Flex
      alignItems="flex-end"
      gap={2}
      cursor="pointer"
    >
      <Flex 
        flexDir="column"
        alignItems="center"
      >
        <Text
          fontFamily={"heading"}
          textTransform={"uppercase"}
          fontSize="0.875rem"
        >
          {showResults && playerPosition ? formatOrdinal(playerPosition) : ""}
        </Text>
        <IconCar width="1.5rem" height="1.5rem" />
      </Flex>
      <Flex
        alignItems="center"
        onClick={handleClick}
        gap={1}
      >
        <Text
          fontFamily={"body"}
          fontSize={"md"}
        >
          {showResults ? playerName : "Show Result"}
        </Text>
        {showResults ? <IconEyeHidden width="1.5rem" height="1.5rem" /> :  <IconEyeShow /> }
      </Flex>
    </Flex>
  )
}

export default PlacingResults