import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCarMint = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="m1.625 6.43.797-2.297A2.073 2.073 0 0 1 4.367 2.75H8.61c.868 0 1.664.563 1.946 1.383l.797 2.297c.07.047.14.07.187.117a4.475 4.475 0 0 0-1.805.703H2.75c-.633 0-1.125.516-1.125 1.125v1.875H8v.375c0 .258 0 .516.047.75H1.625v1.313a.555.555 0 0 1-.563.562.542.542 0 0 1-.562-.563V8.375c0-.82.445-1.547 1.125-1.945Zm1.29-.305h7.147L9.5 4.508a.96.96 0 0 0-.89-.633H4.366a.96.96 0 0 0-.89.633l-.563 1.617Zm-.54 2.625c0-.398.328-.75.75-.75.398 0 .75.352.75.75 0 .422-.352.75-.75.75a.74.74 0 0 1-.75-.75Zm6.375 1.875c0-1.852 1.5-3.375 3.375-3.375a3.391 3.391 0 0 1 3.375 3.375A3.376 3.376 0 0 1 12.125 14a3.361 3.361 0 0 1-3.375-3.375Zm4.125-1.898-2.25 1.687c-.094.07-.14.188-.094.305.047.117.14.187.281.187h.82l-.702 1.29c-.07.117-.047.257.07.327.094.094.234.094.352.024l2.25-1.688c.093-.07.14-.187.093-.304-.047-.117-.14-.211-.258-.211h-.843l.703-1.266c.07-.117.047-.258-.07-.351a.281.281 0 0 0-.352 0Z"
    />
  </svg>
));
export default SvgIconCarMint;
