import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUpgrade = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.95}
      d="M8 13c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1Zm-6 0c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1Zm3 0c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1Zm6 0c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1Zm3 0c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1ZM8.531 1.25l4 4.219c.281.312.281.781-.031 1.062-.156.125-.344.219-.5.219a.753.753 0 0 1-.563-.219L8.75 3.656v6.594A.74.74 0 0 1 8 11a.722.722 0 0 1-.75-.75V3.656L4.531 6.531c-.281.313-.75.313-1.062.032-.313-.282-.313-.75-.031-1.063l4-4.25a.755.755 0 0 1 1.093 0Z"
    />
  </svg>
));
export default SvgIconUpgrade;
