import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { defaultTransition } from 'utils/themeConstants';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const primary = definePartsStyle({
  field: {
    bgColor: 'white.10',
    color: 'white.80',
    borderRadius: '0',
    border: 'none',
    fontFamily: 'heading',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    transition: `${defaultTransition}, width 0s`,
    _disabled: {
      opacity: 0.6,
    },
    _hover: {
      bgColor: 'white.20',
      _disabled: {
        bgColor: 'white.10',
      },
    },
    _focus: {
      bgColor: 'white.20',
      _disabled: {
        bgColor: 'white.10',
      },
    },
  },

  icon: {
    w: '0.5rem',
    h: 'auto',
    mr: '0.5rem',
  },
});

export const selectTheme = defineMultiStyleConfig({
  defaultProps: {
    variant: 'primary',
  },
  variants: { primary },
});
