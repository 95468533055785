import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCollect = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M12.813 7.688a3.86 3.86 0 0 1-1.25.593c-.938.313-2.22.469-3.563.469-1.375 0-2.656-.156-3.594-.469a3.86 3.86 0 0 1-1.25-.594C2.844 7.47 2.5 7.063 2.5 6.5c0-.531.344-.938.656-1.156a3.86 3.86 0 0 1 1.25-.594c.938-.313 2.219-.5 3.594-.5 1.344 0 2.625.188 3.563.5a3.86 3.86 0 0 1 1.25.594c.312.218.687.625.687 1.156 0 .563-.375.969-.688 1.188Zm-7.97-1.532c-.374.125-.624.25-.75.344.126.125.376.25.75.375.782.219 1.876.375 3.157.375 1.25 0 2.344-.156 3.125-.375.375-.125.625-.25.75-.375-.125-.094-.375-.219-.75-.344A11.859 11.859 0 0 0 8 5.75a11.93 11.93 0 0 0-3.156.406ZM16 6.5V10c0 2.219-3.594 4-8 4-4.438 0-8-1.781-8-4V6.5C0 4.031 3.563 2 8 2c4.406 0 8 2.031 8 4.5ZM3.062 8.375C4.25 9.063 5.97 9.5 8 9.5c2 0 3.719-.438 4.906-1.125C14.125 7.687 14.5 6.969 14.5 6.5c0-.438-.375-1.156-1.594-1.875C11.72 3.969 10 3.5 8 3.5c-2.031 0-3.75.469-4.938 1.125C1.845 5.344 1.5 6.063 1.5 6.5c0 .469.344 1.188 1.563 1.875Zm3.688 4.094c.375.031.813.031 1.25.031.406 0 .844 0 1.25-.031v-1.5C8.812 11 8.406 11 8 11c-.438 0-.844 0-1.25-.031v1.5Zm-1-1.625a11.647 11.647 0 0 1-2-.531v1.5c.563.218 1.25.406 2 .53v-1.5ZM1.5 10c0 .219.188.781 1.25 1.375V9.906a6.088 6.088 0 0 1-1.25-.781V10Zm10.75 1.813v-1.5c-.625.218-1.313.406-2 .53v1.5a11.647 11.647 0 0 0 2-.53Zm1-.438c1.031-.594 1.25-1.156 1.25-1.375v-.875a8.425 8.425 0 0 1-1.25.781v1.469Z"
    />
  </svg>
));
export default SvgIconCollect;
