import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetGarage = (refetchInterval?: number | false) => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const getGarageGql = graphql(`
    query GetGarage {
      getGarage {
        id
        orderIdentifier
        name
        points
        earnings
        wins
        rarity
        locked
        livery
        description {
          part
          description
          avgComparison
        }
        stats {
          stats
          bars
          description
        }
        frontWingTuning
        rearWingTuning
        engineCoolingTuning
        brakeCoolingTuning
        transmissionTuning
        timesUsed
        maximumTimesUsed
        nextRepairAt
        usageSinceLastRepair
        repairMessage
        requiredRepairPart
        upgradeCountdownSince
        trialLastUpgradedAt
        appliedLivery {
          id
          name
          imageUrl
        }
        stats {
          bars
          stats
          description
          winnerDiff
        }
        nftTokenId
        nftContractAddress

        listings {
          objectType
          id
          status
          price
          endPrice
          reservoirOrderId
          objectType
        }

        crossmintOrder {
          actionId
        }

        frontWing {
          id
          name
          description
          rankRequired
          lowSettingRange
          highSettingRange
          usage
          rankRequired
          timesUsed
          maximumTimesUsed
        }
        rearWing {
          id
          name
          description
          rankRequired
          lowSettingRange
          highSettingRange
          usage
          rankRequired
          timesUsed
          maximumTimesUsed
        }
        engineCooling {
          id
          name
          description
          rankRequired
          lowSettingRange
          highSettingRange
          usage
          rankRequired
          timesUsed
          maximumTimesUsed
        }
        brakeCooling {
          id
          name
          description
          rankRequired
          lowSettingRange
          highSettingRange
          usage
          rankRequired
          timesUsed
          maximumTimesUsed
        }
        transmissionRatio {
          id
          name
          description
          rankRequired
          lowSettingRange
          highSettingRange
          usage
          rankRequired
          timesUsed
          maximumTimesUsed
        }
        tyres {
          id
        }
        tyresId
        tier {
          id
          name
          rank
          pointsRequired
        }
      }
    }
  `);

  const getGarage = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['garageQuery'],
    queryFn: async () => {
      return graphQLClient.request(getGarageGql);
    },
    refetchInterval: refetchInterval || false,
  });
  return getGarage;
};

export default useGetGarage;
