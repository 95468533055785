import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUnlockedUpgrade = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    viewBox="0 0 64 64"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M50.75 28.75c4.102 0 7.5 3.398 7.5 7.617v18.75c0 4.102-3.398 7.383-7.5 7.383h-37.5c-4.219 0-7.5-3.281-7.5-7.5V36.25c0-4.102 3.281-7.5 7.5-7.5H17V17.5c0-8.906 7.852-16.055 17.11-14.766C41.61 3.79 47 10.586 47 18.086v1.289c0 .938-.938 1.875-1.875 1.875a1.851 1.851 0 0 1-1.875-1.875V17.5c0-6.68-5.977-11.953-12.773-11.133-5.743.82-9.844 5.86-9.844 11.602l.117 10.781h30ZM54.5 55V36.25c0-1.992-1.758-3.75-3.75-3.75h-37.5c-2.11 0-3.75 1.758-3.75 3.75V55c0 2.11 1.64 3.75 3.75 3.75h37.5c1.992 0 3.75-1.64 3.75-3.75Z"
    />
  </svg>
));
export default SvgIconUnlockedUpgrade;
