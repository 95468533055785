import { defineStyleConfig } from '@chakra-ui/react';

const sizeStyles = (fontSize: string) => {
  return {
    '& .title': {
      fontSize,
    },
    svg: {
      height: 'auto',
      width: fontSize,
    },
  };
};

export const tiersTheme = defineStyleConfig({
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    gap: '1',
    flexDirection: 'column',
    '& .title': {
      fontSize: '0.75rem',
    },
    svg: {
      w: '1rem',
      height: 'full',
    },
  },
  sizes: {
    sm: sizeStyles('0.75rem'),
    md: {
      ...sizeStyles('1rem'),
      flexDirection: 'row',
    },
    lg: {
      ...sizeStyles('1.5rem'),
      flexDirection: 'row',
    },
    xl: {
      ...sizeStyles('2.25rem'),
      flexDirection: 'row',
    },
  },
  variants: {
    //for responsive styling
    base: {},
    md: {},
  },
  defaultProps: {
    size: 'md',
  },
});
