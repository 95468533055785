import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconShoppingCart = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={20}
    fill="none"
    viewBox="0 0 24 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M7.586 16.25c1.055 0 1.836.86 1.836 1.875 0 1.055-.86 1.875-1.836 1.875-1.016 0-1.836-.82-1.836-1.875 0-1.016.781-1.875 1.836-1.875Zm11.25 0c1.055 0 1.836.86 1.836 1.875 0 1.055-.86 1.875-1.836 1.875C17.82 20 17 19.18 17 18.125c0-1.016.781-1.875 1.836-1.875Zm4.14-14.492c.235.312.313.742.196 1.133l-2.149 7.46a1.208 1.208 0 0 1-1.171.899H7.39l.351 1.875h12.07c.508 0 .938.43.938.977 0 .507-.43.898-.938.898H7c-.469 0-.86-.313-.977-.742L3.68 1.875H1.648c-.507 0-.898-.39-.898-.938C.75.43 1.14 0 1.648 0H4.5c.43 0 .82.352.898.781l.079.469H21.96c.43 0 .781.195 1.016.508Zm-3.593 7.617 1.758-6.25H5.867l1.172 6.25h12.344Z"
    />
  </svg>
));
export default SvgIconShoppingCart;
