import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRepair = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.969 4.031V3.5h-.032c.22-.375.47-.719.813-1.031C9.938 1.25 11.656.78 13.313 1.188c.374.093.687.375.78.78.126.376 0 .782-.25 1.032L12 4.813l.156 1.03L13.187 6 15 4.156a1.15 1.15 0 0 1 1.063-.281 1.1 1.1 0 0 1 .75.813c.406 1.625-.094 3.406-1.282 4.562a4.815 4.815 0 0 1-.906.719L13.656 9a.682.682 0 0 1-.156-.125c.344-.156.688-.375.969-.688.656-.624 1-1.5 1-2.374l-1.813 1.78-2.812-.468-.469-2.813 1.813-1.78h-.032c-.875 0-1.719.312-2.344.968a3.262 3.262 0 0 0-.906 2.969L7.97 5.53v-1.5ZM4.312 15.188 8 11.438c0 .593.188 1.156.5 1.656l-3.156 3.125c-.5.5-1.157.75-1.813.75-.656 0-1.312-.25-1.781-.75A2.385 2.385 0 0 1 1 14.406c0-.625.25-1.281.75-1.781l4.188-4.188L7 9.5l-4.219 4.25a1.017 1.017 0 0 0-.312.719c0 .281.125.531.312.719.406.406 1.125.406 1.531 0Zm12.344-1.813c.438.469.438 1.188 0 1.594L15 16.625a1.209 1.209 0 0 1-1.656 0l-3.656-3.656c-.72-.719-.844-1.813-.438-2.688L5.937 6.97H4L1 3l2-2 3.969 3v1.938l3.343 3.343C11.188 8.844 12.25 9 13 9.72l3.656 3.656Z"
    />
  </svg>
));
export default SvgIconRepair;
