import { useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  UseDisclosureProps,
  useDisclosure,
} from '@chakra-ui/react';

import { RAlert, RCurrency, RInput, RModal } from 'components';
import TicketsBundleDrawer from 'components/TicketsBundleDrawer/TicketsBundleDrawer';
import { IconChevron, IconInfoCircle, IconTicket, IconTicketVext } from 'icons';
import { SVGIcon } from 'types';
import {
  cssScrollBarStyle,
  ticketRacinoBgGradient,
  ticketVextBgGradient,
} from 'utils/themeConstants';
import { CurrencyType } from 'api/generated/graphql';

type TicketType = 'Racino' | 'VEXT' | 'USDC';

const TicketBase = ({
  ticketType = 'Racino',
  amount,
  discountInPercentage = 0,
  packName,
}: {
  ticketType?: TicketType;
  amount?: number;
  packName?: string;
  discountInPercentage?: number;
}) => {
  const [selectedAmount, setSelectedAmount] = useState(amount || 1);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const currency = {
    Racino: CurrencyType.Usd,
    VEXT: CurrencyType.Vext,
    USDC: CurrencyType.Usd,
  };

  const totalCost =
    selectedAmount * 400 - (selectedAmount * 400 * discountInPercentage) / 100;

  return (
    <Flex
      pos="relative"
      flexDir="column"
      justifyContent="flex-end"
      bg={
        ticketType === 'Racino' ? ticketRacinoBgGradient : ticketVextBgGradient
      }
      bgColor="black.95"
      minW="21.25rem"
      h="full"
      mr="0.5rem"
      p="1rem"
    >
      <Flex
        pos="absolute"
        w="full"
        left={0}
        top={0}
        flexDir="column"
        py="1.25rem"
        px="1.25rem"
        bg={!amount ? 'black.80' : 'transparent'}
        zIndex={1}
      >
        <Heading
          fontSize="1.125rem"
          fontWeight={400}
          textTransform="uppercase"
          mb={!amount ? '0.5rem' : 0}
        >
          {amount || '1+'} Tickets
        </Heading>
        <Heading
          fontSize="0.75rem"
          fontWeight={400}
          color="white.80"
          textTransform="uppercase"
        >
          {packName}
        </Heading>
        {!amount && (
          <RInput
            label="Enter amount"
            h="3.25rem"
            value={selectedAmount}
            onChange={(e) => {
              const value = Number(e.target.value);

              if (!isNaN(Number(value))) {
                setSelectedAmount(value);
              } else {
                setSelectedAmount(1);
              }
            }}
          />
        )}
      </Flex>

      <Flex
        pos="absolute"
        w="full"
        h="full"
        alignItems="center"
        justifyContent="center"
      >
        {!amount ? (
          ticketType === 'Racino' ? (
            <Image
              src="/images/icons/icon-packs-generic.svg"
              alt="Ticket Pack"
            />
          ) : (
            <Image
              src="/images/icons/icon-packs-generic-vext.svg"
              alt="Ticket Pack"
            />
          )
        ) : ticketType === 'Racino' ? (
          <Image src="/images/icons/icon-packs.svg" alt="Ticket Pack" />
        ) : (
          <Image src="/images/icons/icon-packs-vext.svg" alt="Ticket Pack" />
        )}
      </Flex>

      <Flex
        w="full"
        flexDir="column"
        alignItems="flex-end"
        textTransform="uppercase"
      >
        <Heading
          display="flex"
          alignItems="center"
          fontSize="0.75rem"
          fontWeight={400}
          color="white.80"
        >
          COST
          <IconInfoCircle
            h="0.75rem"
            ml="0.25rem"
            __css={{
              path: {
                fill: 'white.100',
              },
            }}
          />
        </Heading>
        <RCurrency
          amount={totalCost}
          size="2xl"
          currency={currency[ticketType]}
        />
      </Flex>
      <Button
        variant="tertiary"
        w="full"
        mt="1rem"
        onClick={onOpen}
        isDisabled={selectedAmount === 0}
      >
        Buy Now
      </Button>

      <TicketsBundleDrawer
        totalPrice={2}
        quantity={selectedAmount}
        isOpen={isOpen}
        currency={currency[ticketType]}
        onClose={onClose}
        handleFinish={onClose}
      />
    </Flex>
  );
};

const TicketsBundleModal = ({
  isOpen = false,
  onOpen = () => undefined,
  onClose = () => undefined,
}: UseDisclosureProps) => {
  const [activeSection, setActiveSection] = useState<TicketType>('Racino');
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 348;
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 348;
    }
  };

  const TicketButton = ({
    sectionName,
    IconComponent,
    ...props
  }: ButtonProps & {
    sectionName: TicketType;
    IconComponent: SVGIcon;
  }) => (
    <GridItem>
      <Button
        variant={
          activeSection === sectionName ? 'primary-ghost' : 'secondary-outlined'
        }
        w="full"
        py="0.5rem"
        color={activeSection === sectionName ? 'white.95' : 'white.40'}
        onClick={() => setActiveSection(sectionName)}
        {...props}
      >
        <IconComponent
          h="1.25rem"
          w="auto"
          mr="0.5rem"
          __css={{
            path: {
              stroke: activeSection === sectionName ? 'white.95' : 'white.40',
            },
          }}
        />
        {sectionName}
      </Button>
    </GridItem>
  );

  return (
    <RModal
      variant={activeSection === 'VEXT' ? 'vext' : 'tickets'}
      size="5xl"
      title="Buy Tickets"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <Box padding="1.5rem" w="full">
        <RAlert
          variant="info"
          description="Please note: Racino tickets are non-refundable and can only be used on the Racino platform."
        />

        <Grid templateColumns="repeat(3, 1fr)" gap={3} w="full" my="1rem">
          <TicketButton sectionName="Racino" IconComponent={IconTicket} />
          <TicketButton sectionName="VEXT" IconComponent={IconTicketVext} />
          <TicketButton
            sectionName="USDC"
            IconComponent={IconTicket}
            isDisabled={true}
          />
        </Grid>

        <Flex h="26.25rem" w="full">
          <Button
            variant="secondary-outlined"
            width="2.5rem"
            h="full"
            transform="rotate(180deg)"
            mr="1rem"
            onClick={scrollLeft}
          >
            <IconChevron />
          </Button>
          <Flex
            ref={scrollRef}
            overflowX="auto"
            className="white-scrollbar"
            pb="0.5rem"
            css={cssScrollBarStyle}
          >
            <TicketBase ticketType={activeSection} />
            <TicketBase
              ticketType={activeSection}
              amount={5}
              packName="Standard pack"
            />
            <TicketBase
              ticketType={activeSection}
              amount={10}
              packName="Champion Pack: 10% Discount"
              discountInPercentage={10}
            />
            <TicketBase
              ticketType={activeSection}
              amount={50}
              packName="Pro pack: 30% Discount"
              discountInPercentage={30}
            />
          </Flex>
          <Button
            variant="secondary-outlined"
            ml="1rem"
            width="2.5rem"
            h="full"
            onClick={scrollRight}
          >
            <IconChevron />
          </Button>
        </Flex>
      </Box>
    </RModal>
  );
};

export default TicketsBundleModal;
