import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconGripConditions = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={12}
    fill="none"
    viewBox="0 0 13 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M4.9 6.82c.188.305.516.54.891.633v1.524c-1.055-.141-1.922-.774-2.344-1.688L4.9 6.82ZM4.666 6v.094l-1.453.468c-.047-.164-.047-.351-.047-.562 0-.844.352-1.617.938-2.18l.89 1.243c-.21.257-.328.585-.328.937Zm1.5-1.5c-.21 0-.398.047-.562.117l-.891-1.219A2.99 2.99 0 0 1 6.166 3c.516 0 1.008.164 1.43.375l-.89 1.219a1.375 1.375 0 0 0-.54-.094Zm1.148.563.891-1.243c.586.563.961 1.336.961 2.18 0 .21-.023.398-.07.563l-1.453-.47c0-.023.023-.046.023-.093 0-.352-.14-.68-.352-.938ZM6.166 0c3.305 0 6 2.695 6 6 0 3.328-2.695 6-6 6-3.328 0-6-2.672-6-6 0-3.305 2.672-6 6-6Zm0 9.75A3.746 3.746 0 0 0 9.916 6a3.761 3.761 0 0 0-3.75-3.75A3.746 3.746 0 0 0 2.416 6a3.731 3.731 0 0 0 3.75 3.75Zm.375-2.297c.352-.094.68-.328.867-.633l1.453.47c-.422.913-1.289 1.546-2.32 1.687V7.453ZM6.916 6c0 .422-.352.75-.75.75a.74.74 0 0 1-.75-.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75Z"
    />
  </svg>
));
export default SvgIconGripConditions;
