import { useMutation } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useRemoveLivery = (rest?: { [key: string]: unknown }) => {
  const { graphql, graphQLClient } = useGraphQLClient();

  const removeLiveryGQL = graphql(`
    mutation RemoveLivery($liveryId: String!) {
      removeLivery(liveryId: $liveryId) {
        id
        name
      }
    }
  `);

  const removeLivery = useMutation({
    mutationKey: ['removeLivery'],
    mutationFn: ({ liveryId }: { liveryId: string }) => {
      return graphQLClient.request(removeLiveryGQL, {
        liveryId,
      });
    },
    ...rest,
  });

  return removeLivery;
};

export default useRemoveLivery;
