/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  Heading,
  Box,
  Button,
  Text,
  useToast,
  ToastId,
} from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { CrossmintEvent } from '@crossmint/client-sdk-react-ui';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import {
  Cars,
  CurrencyType,
  MutationTicketPaymentProcessSucceededEventArgs,
  Races,
  Tier,
} from 'api/generated/graphql';
import { CarSelect } from 'components/RSelect/CarSelect';
import { CheckoutModal } from 'components/CheckoutModal/CheckoutModal';
import { CryptoTabTicket } from 'components/CheckoutModal/Crypto/CryptoTabTicket';
import { useAnimatedTransition, useTuning } from 'context';
import { useGetTicketPrice, useTicketPaymentProcessSucceeded } from 'hooks';
import { IconInfo, IconMinus, IconPlus, IconUnlocked } from 'icons';
import { RaceTicket } from '../_components/RaceTicket';
import { RToast, RTooltip } from 'components';
import { RaceTimer } from '../_components/RaceTimer';
import { FusionCarStats } from 'components/Salvage/new/FusionCarStats';

export type LeaderboardAvailable = {
  available: boolean;
  car: Cars;
};

interface SelectCarProps {
  admissionFee?: number;
  cars: Cars[] | undefined;
  enterRaceIsSuccess?: boolean;
  raceStartTime?: string;
  race?: Races;
  isOpen: boolean;
  withLeaderboardAvailable?: LeaderboardAvailable[];
  withLeaderboardEntry: boolean;
  setWithLeaderboardEntry: Dispatch<SetStateAction<boolean | undefined>>;
  setSelectedCarId: Dispatch<SetStateAction<string | undefined>>;
  onClose: () => void;
}

const SelectCar = ({
  admissionFee = 0,
  cars,
  raceStartTime,
  race,
  isOpen,
  withLeaderboardAvailable,
  withLeaderboardEntry,
  setWithLeaderboardEntry,
  setSelectedCarId,
  onClose,
}: SelectCarProps) => {
  // Toast
  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);

  // Car Selection
  const [selectedCarIndex, setSelectedCarIndex] = useState(0);
  const [showCarStats, setShowCarStats] = useState(false);

  const {
    setEngineCoolingTuning,
    setFrontWingTuning,
    setRearWingTuning,
    setTransmissionTuning,
    setEngineModeTuning,
    setSuspensionRideHeightTuning,
    setSuspensionStiffnessTuning,
  } = useTuning();

  const raceTier = race?.tier?.name?.toLowerCase();
  const selectedCar = cars?.[selectedCarIndex];
  const selectedCarAvailable = withLeaderboardAvailable?.find(
    (car) => car.car.id === selectedCar?.id
  )?.available;
  const lockedCarIds = cars
    ?.filter((car) => {
      return car.locked;
    })
    .map((car) => car.id);

  useEffect(() => {
    if (selectedCar) {
      setSelectedCarId(selectedCar.id);
    }
  }, [selectedCar, setSelectedCarId]);

  // Admission Tickets
  const queryClient = useQueryClient();

  const paymentEnv =
    process.env.NEXT_PUBLIC_ENV === 'dev' ? 'staging' : 'production';
  const ticketsContractId = process.env.NEXT_PUBLIC_TICKETS_CONTRACT_ID || '';
  const projectId = process.env.NEXT_PUBLIC_CROSSMINT_API_ID || '';

  const { showAnimatedTransition } = useAnimatedTransition();
  const { mutateAsync } = useTicketPaymentProcessSucceeded();

  const { data: bundle1Data } = useGetTicketPrice(1);

  const bundle1 = bundle1Data?.ticketPrice;

  const onSuccessBuy = async (e: CrossmintEvent, bundleId: number) => {
    showAnimatedTransition({
      Icon: IconUnlocked,
      title: 'Ticket',
      id: 'ticket-purchased-success',
      subtitle: 'Purchased',
      color: 'treasuredTeal.100',
      bgColor:
        'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
      gradientColor: {
        from: 'rgba(72, 223, 187, 0.2)',
        to: 'rgba(72, 223, 187, 0)',
      },
    });

    console.log('==> Mutate ticket events <==');
    const input = {
      succeededInput: {
        currency: CurrencyType.Usd,
        bundleId,
      },
    } as MutationTicketPaymentProcessSucceededEventArgs;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const orderIdentifier = e?.payload?.orderIdentifier;
    if (orderIdentifier) {
      console.log('orderIdentifier', orderIdentifier);
      //todo: cannot use individual fields as they are not included with crossmint types; need different type wrappers?
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if (uuidRegex.test(orderIdentifier)) {
        input.succeededInput.orderIdentifier = orderIdentifier;
      } else {
        input.succeededInput.actionId = orderIdentifier;
      }
    }
    await mutateAsync(input);
    await queryClient.refetchQueries({
      queryKey: ['ticketsAvailable'],
    });
    onClose();
  };

  const getNextDay3PMEST = () => {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setUTCHours(19, 0, 0, 0);
    if (now > nextDay) {
      nextDay.setDate(now.getDate() + 1);
    }
    return nextDay.toISOString();
  };

  const setLeaderboardEntry = (
    value: boolean | ((prevState: boolean | undefined) => boolean | undefined)
  ) => {
    if (
      selectedCar?.tier?.rank === 0 ||
      selectedCar?.tier?.name?.toLowerCase() === 'common'
    ) {
      setWithLeaderboardEntry(false);
      toastIdRef.current = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title="Your car must be minted to enter the leaderboard"
            onClose={() => toast.close(toastIdRef.current as ToastId)}
          />
        ),
      });
      return;
    }

    setWithLeaderboardEntry((prev) =>
      typeof value === 'function' ? value(prev) : value
    );
  };

  const getFirstAvailableCar = () => {
    const availableCar = withLeaderboardAvailable?.find(
      (leaderboard) => leaderboard.available
    );
    if (availableCar) {
      setSelectedCarIndex(
        cars?.findIndex((car) => car.id === availableCar.car.id) || 0
      );
    }
  };

  const getCarStats = () => {
    const [handling, speed, fastTurns, slowTurns, braking, cooling] =
      selectedCar?.stats?.map((stat) => stat.bars) || [];

    return { handling, speed, fastTurns, slowTurns, braking, cooling };
  };

  useEffect(() => {
    if (withLeaderboardAvailable && cars) {
      const hasAvailableCar = withLeaderboardAvailable?.some(
        (leaderboard) => leaderboard.available
      );

      if (hasAvailableCar) getFirstAvailableCar();
    }
  }, [withLeaderboardAvailable, cars]);

  useEffect(() => {
    setFrontWingTuning(selectedCar?.frontWingTuning || 0);
    setRearWingTuning(selectedCar?.rearWingTuning || 0);
    setEngineCoolingTuning(selectedCar?.engineCoolingTuning || 0);
    setTransmissionTuning(selectedCar?.transmissionTuning || 0);
    setEngineModeTuning(selectedCar?.engineModeTuning || 0);
    setSuspensionRideHeightTuning(selectedCar?.suspensionRideHeightTuning || 0);
    setSuspensionStiffnessTuning(selectedCar?.suspensionStiffnessTuning || 0);
  }, [selectedCar]);

  return (
    <>
      <Box height="fit-content" pb="20">
        <Flex
          w="full"
          mt="0.5rem"
          mb="0.875rem"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading
            as="h1"
            fontSize="1.125rem"
            fontWeight={400}
            textTransform="uppercase"
            color="white.80"
            mr="6"
          >
            Select Car
          </Heading>

          <Button
            variant="secondary"
            borderRadius="3.125rem"
            gap="0.5rem"
            px="0.5rem"
            h="1.75rem"
            onClick={() => setShowCarStats((oldState) => !oldState)}
          >
            <Text
              fontFamily="heading"
              color="white.80"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              textTransform="capitalize"
              fontWeight={400}
            >
              Stats
            </Text>
            {!showCarStats ? (
              <IconPlus w="1rem" h="1rem" />
            ) : (
              <IconMinus w="1rem" h="1rem" />
            )}
          </Button>
        </Flex>

        <Flex flexDir="column" gap="0.75rem">
          {cars && (
            <CarSelect
              cars={cars}
              withLeaderboardAvailable={withLeaderboardAvailable}
              selectedCarIndex={selectedCarIndex}
              setSelectedCarIndex={(value) => {
                setSelectedCarIndex(value);
                setWithLeaderboardEntry(false);
              }}
              lockedCarIds={lockedCarIds}
            />
          )}
          {showCarStats && selectedCar?.stats && (
            <Flex alignItems="center" justifyContent="center" w="full" mb="4">
              <FusionCarStats
                stats={getCarStats()}
                isLocked={selectedCar?.tier?.rank === 0}
                px="0.5rem"
                py="0.25rem"
              />
            </Flex>
          )}
        </Flex>

        <Flex
          w="full"
          mt="1.5rem"
          mb="0.875rem"
          alignItems="center"
          gap="0.5rem"
        >
          <RTooltip
            label="If a race has only one racer, it will be cancelled and the Ticket refunded."
            openDelay={500}
            shouldWrapChildren
            placement="top"
          >
            <IconInfo
              width="0.75rem"
              height="0.75rem"
              __css={{
                path: {
                  fill: 'white.80',
                  opacity: 1,
                },
              }}
            />
          </RTooltip>
          <Flex alignItems="center" gap="0.5rem">
            <Heading
              as="h1"
              fontSize="0.875rem"
              fontWeight={400}
              textTransform="uppercase"
              color="white.80"
            >
              {raceTier !== Tier.Tournament ? 'Race' : 'Tournament'} Entry
            </Heading>
            <RaceTimer
              dateTime={dayjs(race?.startTime)
                .subtract(15, 'minute')
                .toISOString()}
            />
          </Flex>
        </Flex>

        <RaceTicket
          race={race}
          numberOfTickets={admissionFee}
          type={raceTier !== Tier.Tournament ? 'racino' : 'tournament'}
        />

        {raceTier !== Tier.Tournament && selectedCarAvailable && (
          <>
            <Flex
              w="full"
              mt="1.5rem"
              mb="0.875rem"
              alignItems="center"
              gap="0.5rem"
            >
              <RTooltip
                label="If a race has only one racer, it will be cancelled and the Ticket refunded."
                openDelay={500}
                shouldWrapChildren
                placement="top"
              >
                <IconInfo
                  width="0.75rem"
                  height="0.75rem"
                  __css={{
                    path: {
                      fill: 'white.80',
                      opacity: 1,
                    },
                  }}
                />
              </RTooltip>
              <Flex alignItems="center" gap="0.5rem">
                <Heading
                  as="h1"
                  fontSize="0.875rem"
                  fontWeight={400}
                  textTransform="uppercase"
                  color="white.80"
                >
                  Leaderboard Jackpot
                </Heading>
                <RaceTimer
                  dateTime={dayjs(getNextDay3PMEST())
                    .subtract(15, 'minute')
                    .toISOString()}
                />
              </Flex>
            </Flex>

            <RaceTicket
              type="leaderboard"
              race={race}
              numberOfTickets={selectedCarAvailable ? 1 : 0}
              isSelected={selectedCarAvailable ? withLeaderboardEntry : true}
              setIsSelected={
                selectedCarAvailable
                  ? (setLeaderboardEntry as Dispatch<
                      SetStateAction<boolean | undefined>
                    >)
                  : () => null
              }
            />
          </>
        )}
      </Box>

      <CheckoutModal
        isOpen={isOpen}
        onClose={onClose}
        checkoutValue={'100'}
        collectionId={ticketsContractId}
        environment={paymentEnv}
        mintConfig={{
          totalPrice: String((bundle1?.price || 5) * admissionFee),
          bundleId: 1,
          quantity: admissionFee,
        }}
        CryptoTab={CryptoTabTicket}
        cryptoTabProps={{
          onSuccess: (e) => onSuccessBuy(e, 1),
          bundleId: 1,
        }}
        projectId={projectId}
        title="Buy Racino Tickets"
        onSuccessfulPurchase={(e) => onSuccessBuy(e, 1)}
      />
    </>
  );
};

export default SelectCar;
