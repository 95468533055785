import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUser = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M11.574 11.875c3.79 0 6.875 3.086 6.875 6.875 0 .703-.586 1.25-1.25 1.25h-15c-.703 0-1.25-.547-1.25-1.25a6.86 6.86 0 0 1 6.875-6.875h3.75Zm-8.75 6.25h13.711c-.312-2.46-2.422-4.375-4.96-4.375h-3.75a5.027 5.027 0 0 0-5 4.375ZM9.7 10c-2.773 0-5-2.227-5-5 0-2.734 2.227-5 5-5 2.735 0 5 2.266 5 5 0 2.773-2.265 5-5 5Zm0-8.125A3.11 3.11 0 0 0 6.574 5c0 1.758 1.367 3.125 3.125 3.125A3.11 3.11 0 0 0 12.824 5 3.134 3.134 0 0 0 9.7 1.875Z"
    />
  </svg>
));
export default SvgIconUser;
