import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBell = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={20}
    fill="none"
    viewBox="0 0 19 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M10.75 1.25v.703c2.813.469 5 2.93 5 5.86V9.14c0 1.757.586 3.476 1.68 4.882l.586.704c.234.312.273.703.117 1.015a.91.91 0 0 1-.82.508H1.688c-.391 0-.704-.195-.86-.508-.156-.312-.117-.703.117-1.015l.586-.704c1.094-1.406 1.719-3.125 1.719-4.882V7.812c0-2.93 2.148-5.39 5-5.859V1.25C8.25.586 8.797 0 9.5 0c.664 0 1.25.586 1.25 1.25Zm-1.563 2.5a4.06 4.06 0 0 0-4.062 4.063V9.14a9.56 9.56 0 0 1-1.563 5.234h11.836c-1.015-1.563-1.523-3.36-1.523-5.234V7.812c0-2.226-1.836-4.062-4.063-4.062h-.624ZM12 17.5c0 .664-.273 1.328-.742 1.797-.469.469-1.133.703-1.758.703-.664 0-1.328-.234-1.797-.703C7.234 18.828 7 18.164 7 17.5h5Z"
    />
  </svg>
));
export default SvgIconBell;
