import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetCarPrices = () => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();
  const getCarPricesFn = graphql(`
    query GetCarPrices {
      getCarPrice {
        price
        maticPrice
        vextPrice
        usdcPrice
        readableUsdcPrice
        readableMaticPrice
      }
    }
  `);

  return useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['featuredRace'],
    queryFn: async () => {
      return await graphQLClient.request(getCarPricesFn);
    },
  });
};

export default useGetCarPrices;
