import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface GradientBorderProps {
  color: string;
  children?: ReactNode;
}

const GradientBorder = ({ color, children }: GradientBorderProps) => {
  return (
    <Flex pos="absolute" w="full" h="full">
      {children}

      <Box
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        width="0.25rem"
        background={`linear-gradient(to bottom, ${color} 0%, transparent 100%)`}
        zIndex={5}
      />

      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        height="0.25rem"
        background={`linear-gradient(to right, transparent 0%, transparent 40%, ${color} 75%, ${color} 100%)`}
        zIndex={5}
      />
    </Flex>
  );
};

export default GradientBorder;
