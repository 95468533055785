import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconVextBorder = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M21.303 12a9.3 9.3 0 1 1-18.6 0 9.3 9.3 0 0 1 18.6 0Zm1.5 0c0 5.965-4.835 10.8-10.8 10.8-5.965 0-10.8-4.835-10.8-10.8 0-5.964 4.835-10.8 10.8-10.8 5.965 0 10.8 4.836 10.8 10.8Zm-18.6-4.2 7.8 2.29 7.8-2.29-5.86 6.336 2.352 3.885-4.292-1.965-4.292 1.965 2.352-3.885L4.203 7.8Zm2.559 1.61 5.241 1.802 5.241-1.802-5.24 3.25-5.242-3.25Zm5.241 3.944L6.487 9.44l4.567 4.637-2.614 3.22 3.563-2.555 3.563 2.555-2.614-3.22L17.52 9.44l-5.517 3.914Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconVextBorder;
