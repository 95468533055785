import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRetry = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    viewBox="0 0 19 18"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M18.29.328c.35.156.585.508.585.86v5.625c0 .546-.43.937-.977.937h-5.585c-.391 0-.743-.195-.899-.547-.117-.351-.039-.781.234-1.016l2.305-2.343C12.743 2.789 11.18 2.125 9.5 2.125c-3.047 0-5.781 2.07-6.64 4.96-.118.509-.626.782-1.133.626-.508-.117-.782-.664-.665-1.133C2.157 2.868 5.633.25 9.5.25c2.188 0 4.219.86 5.781 2.266L17.273.562c.235-.273.665-.351 1.016-.234Zm-1.056 9.961a.923.923 0 0 1 .625 1.172c-1.093 3.71-4.53 6.289-8.398 6.289a8.611 8.611 0 0 1-5.781-2.227l-1.993 1.954c-.156.195-.39.273-.664.273-.117 0-.234 0-.351-.04a.987.987 0 0 1-.586-.898v-5.625c0-.507.43-.937.977-.937h5.585c.391 0 .743.234.86.586.117.351.039.781-.196 1.016L4.97 14.195a6.794 6.794 0 0 0 4.492 1.68c3.047 0 5.781-2.031 6.601-4.96a.923.923 0 0 1 1.172-.626Z"
    />
  </svg>
));
export default SvgIconRetry;
