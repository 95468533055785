import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHammer = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={11}
    fill="none"
    viewBox="0 0 12 11"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M6.078 5.867a.29.29 0 0 1 .43-.02.29.29 0 0 1 .02.43l-3.77 4.063c-.274.273-.625.41-.996.41s-.723-.137-.977-.41C.238 9.813.238 8.914.765 8.367l4.083-3.77a.29.29 0 0 1 .43.02.29.29 0 0 1-.02.43l-4.043 3.77a.79.79 0 0 0 0 1.093.79.79 0 0 0 1.094 0l3.77-4.043Zm5.45-1.27a.313.313 0 0 1-.02.45L9.32 7.234a.325.325 0 0 1-.215.079.294.294 0 0 1-.214-.079.331.331 0 0 1 0-.449l.234-.215-.918-.84a1.635 1.635 0 0 1-1.289-.468l-.898-.899a1.533 1.533 0 0 1-.47-1.133v-.058L3.97 2.39a.389.389 0 0 1-.156-.235c-.04-.195.097-.273.214-.41A3.34 3.34 0 0 1 6.41.75a3.34 3.34 0 0 1 2.383.996l.82.82c.332.332.489.801.45 1.27l.917.86.098-.098a.33.33 0 0 1 .45 0ZM9.573 6.122l.957-.976-1.015-.957c-.079-.079-.098-.176-.079-.293a1.01 1.01 0 0 0-.273-.899l-.82-.82a2.751 2.751 0 0 0-1.934-.801c-.644 0-1.27.234-1.758.645L6 2.703c.098.04.176.156.176.274v.253c0 .254.097.508.293.704l.879.878c.234.235.566.333.898.274a.29.29 0 0 1 .274.078l1.054.957Z"
    />
  </svg>
));
export default SvgIconHammer;
