import { useMutation } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

import { MutationSaveSavedSetupArgs } from 'api/generated/graphql';

const useSaveSavedSetups = (rest?: { [key: string]: unknown }) => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();

  const saveSavedSetupsFn = graphql(`
    mutation SaveSavedSetups($SavedSetupsInput: SavedSetupsInput!) {
      saveSavedSetup(SavedSetupsInput: $SavedSetupsInput) {
        id
        name
        car {
          id
          name
        }
      }
    }
  `);

  const saveSavedSetups = useMutation({
    mutationFn: async ({ SavedSetupsInput }: MutationSaveSavedSetupArgs) => {
      return graphQLClient.request(saveSavedSetupsFn, {
        SavedSetupsInput,
      });
    },
    ...rest,
  });

  return saveSavedSetups;
};

export default useSaveSavedSetups;
