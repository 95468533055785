import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMastercard = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={20}
    fill="none"
    viewBox="0 0 24 20"
    aria-hidden="true"
    {...props}
  >
    <rect width={24} height={20} fill="#fff" rx={2} />
    <path fill="#FF5F00" d="M14.704 5.15H9.296v9.718h5.409V5.149Z" />
    <path
      fill="#EB001B"
      d="M9.64 10.009A6.17 6.17 0 0 1 12 5.149a6.18 6.18 0 1 0 0 9.719 6.17 6.17 0 0 1-2.36-4.86Z"
    />
    <path
      fill="#F79E1B"
      d="M22 10.009a6.18 6.18 0 0 1-10 4.859 6.18 6.18 0 0 0 0-9.718 6.18 6.18 0 0 1 10 4.859ZM21.41 13.838v-.198h.08v-.041h-.204v.04h.08v.2h.044Zm.397 0v-.24h-.063l-.072.166-.072-.165h-.063v.24h.045v-.181l.067.155h.046l.068-.156v.181h.044Z"
    />
  </svg>
));
export default SvgIconMastercard;
