import { ChakraComponent, Flex, Text } from '@chakra-ui/react';
import { SVGProps } from 'react';

interface SalvageStatProps {
  title: string;
  Icon: ChakraComponent<(props: SVGProps<SVGSVGElement>) => React.JSX.Element>;
  stat?: string | number;
}

const SalvageStat = ({ title, Icon, stat }: SalvageStatProps) => {
  return (
    <Flex flexDir="column" alignItems="center" h="fit-content">
      <Text textTransform="uppercase" color="white.60" fontSize="0.625rem">
        {title}
      </Text>
      <Text
        display="flex"
        fontSize="1.25rem"
        alignItems="center"
        textTransform="uppercase"
      >
        <Icon w="1rem" h="1rem" mr="0.375rem" />
        {stat || '--'}
      </Text>
    </Flex>
  );
};

export default SalvageStat;
