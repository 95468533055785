import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconChevron = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={14}
    fill="none"
    viewBox="0 0 8 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M1.531.5 7.25 6.469c.125.156.219.343.219.531a.737.737 0 0 1-.219.5l-5.719 5.969A.746.746 0 0 1 .47 13.5c-.313-.281-.313-.75-.031-1.063l5.25-5.468L.438 1.53A.746.746 0 0 1 .468.47.746.746 0 0 1 1.532.5Z"
    />
  </svg>
));
export default SvgIconChevron;
