import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWarningSquare = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={14}
    fill="none"
    viewBox="0 0 15 14"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#E2645A"
      d="M7.5 8.5c.398 0 .75.328.75.75 0 .398-.352.75-.75.75a.755.755 0 0 1-.75-.75.74.74 0 0 1 .75-.75Zm-.563-3.938c0-.304.235-.562.563-.562.305 0 .563.234.563.563v2.625a.57.57 0 0 1-.563.562.555.555 0 0 1-.563-.563V4.563Zm6.211 1.665a1.09 1.09 0 0 1 0 1.57l-4.875 4.875a1.09 1.09 0 0 1-1.57 0L1.828 7.797a1.09 1.09 0 0 1 0-1.57L6.68 1.328c.422-.422 1.148-.422 1.593 0l4.875 4.899ZM7.5 11.875l4.852-4.852L7.5 2.148c-.023 0-.023-.023-.023-.023h-.024L2.625 7.023 7.5 11.875Z"
    />
  </svg>
));
export default SvgIconWarningSquare;
