import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetUserNotificationSettings = () => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const getUserSettingsGql = graphql(`
    query getUserNotificationsSettings {
      getUser {
        marketplaceEmailNotification
        marketplaceBrowserNotification
        raceStartingEmailNotification
        raceStartingBrowserNotification
      }
    }
  `);

  const getNoticationSettings = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['userSettingsQuery'],
    queryFn: async () => {
      return graphQLClient.request(getUserSettingsGql);
    },
  });
  return getNoticationSettings;
};

export default useGetUserNotificationSettings;
