import { useState, useEffect } from 'react';
import { RaceStatus } from 'types';

interface UseRaceCountdownReturn {
  raceStatus: RaceStatus;
}

interface useRaceCountdownProps {
  startTime: string;
  currentStatus: string;
}

export const raceRefetchInterval = 1 * 60 * 1000;
export const ticketsRefetchInterval = 20000; // Just for the demo

/**
 * Monitors the time remaining until a race's start time and updates the race status to 'live' when the race starts.
 */
const useRaceCountdown = ({
  startTime,
  currentStatus,
}: useRaceCountdownProps): UseRaceCountdownReturn => {
  const [raceStatus, setRaceStatus] = useState<RaceStatus>(
    (currentStatus as RaceStatus) || 'open'
  );

  useEffect(() => {
    const now = Date.now();
    const raceStartTime = new Date(startTime).getTime();
    const timeDifference = raceStartTime - now;

    setRaceStatus(currentStatus as RaceStatus);

    if (timeDifference > 0) {
      const timer = setTimeout(() => {
        setRaceStatus('live');
      }, timeDifference);

      return () => clearTimeout(timer);
    }
  }, [startTime, currentStatus]);

  return {
    raceStatus,
  };
};

export default useRaceCountdown;
