import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUsdcTrial = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2775CA"
      fillRule="evenodd"
      d="M8.533 21.225C3.393 19.38.757 13.647 2.668 8.573c.989-2.767 3.163-4.876 5.865-5.864.263-.132.395-.33.395-.66v-.922c0-.264-.132-.461-.395-.527-.066 0-.198 0-.264.066C2.01 2.643-1.418 9.298.56 15.558a11.854 11.854 0 0 0 7.71 7.71c.264.132.527 0 .593-.263.066-.066.066-.132.066-.264v-.922c0-.198-.198-.462-.395-.594ZM15.518.665c-.264-.131-.527 0-.593.264-.066.066-.066.132-.066.264v.923c0 .263.198.527.395.659 5.14 1.845 7.776 7.577 5.865 12.652-.988 2.767-3.163 4.876-5.865 5.864-.263.132-.395.33-.395.66v.922c0 .264.132.461.395.527.066 0 .198 0 .264-.066 6.26-1.977 9.687-8.632 7.71-14.892a11.952 11.952 0 0 0-7.71-7.776ZM12.09 10.88c2.768.33 4.152 1.12 4.152 3.426 0 1.78-1.318 3.163-3.295 3.493v1.581c-.066.33-.264.527-.527.527h-.989c-.33-.065-.527-.263-.527-.527V17.8c-2.175-.33-3.229-1.516-3.492-3.163v-.066a.45.45 0 0 1 .46-.461h1.121c.198 0 .395.132.461.395.198.988.791 1.713 2.504 1.713 1.252 0 2.175-.724 2.175-1.779 0-1.054-.593-1.45-2.438-1.779-2.768-.33-4.086-1.186-4.086-3.36 0-1.648 1.252-2.966 3.23-3.23V4.554c.065-.33.263-.528.526-.528h.989c.33.066.527.264.527.528v1.581a3.292 3.292 0 0 1 2.965 2.702v.066a.45.45 0 0 1-.461.461h-1.054a.496.496 0 0 1-.462-.33c-.329-.988-.988-1.383-2.174-1.383-1.318 0-1.977.593-1.977 1.515 0 .923.395 1.45 2.372 1.714Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconUsdcTrial;
