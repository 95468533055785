import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconResults = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M16.375 1.25c1.367 0 2.5 1.133 2.5 2.46v12.5c0 1.368-1.133 2.5-2.5 2.5h-12.5a2.492 2.492 0 0 1-2.5-2.5V3.71c0-1.366 1.094-2.46 2.5-2.46h12.5Zm.625 15V3.75c0-.313-.313-.625-.625-.625h-12.5a.642.642 0 0 0-.625.625v12.5c0 .352.273.625.625.625h12.5A.642.642 0 0 0 17 16.25ZM10.125 5a.95.95 0 0 1 .938.938v8.125c0 .546-.43.937-.938.937-.547 0-.938-.39-.938-.938V5.938c0-.508.391-.938.938-.938Zm3.75 6.25a.95.95 0 0 1 .938.938v1.874c0 .547-.43.938-.938.938-.547 0-.938-.39-.938-.938v-1.874c0-.508.391-.938.938-.938Zm-7.5-2.5a.95.95 0 0 1 .938.938v4.374c0 .547-.43.938-.938.938-.547 0-.938-.39-.938-.938V9.689c0-.508.391-.938.938-.938Z"
    />
  </svg>
));
export default SvgIconResults;
