import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMelbourneMinimap = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="m67 106.5-16.731 3.354c-4.903 1.486-3.764 4.094-3.269 5.646 8.526 18.24 19.582 34.995 30.5 48 14.345 17.086 24.094 24.217 27.084 26.971 7.696 7.089 8.411 10.503 9.496 15.689l.013.06c1.04 6.835-2.796 13.351-3.984 15.728-1.189 2.378-.446 3.863.891 5.052 1.337 1.189 45.232 45.083 48.5 48.5 3.269 3.417 45.314 45.517 52 52.5 2.863 2.99 4.606 5.394 9.509 6.092 4.651.661 8.333-2.689 8.823-3.218 3.714-4.012 7.699-8.7 10.671-12.117 2.971-3.417 5.2.297 5.497.743.297.446 6.118 9.294 7.752 12.265 1.635 2.972 5.943 11.292 9.657 17.086 1.293 2.016 3.179 4.489 5.646 5.497 3.206 1.311 7.087.801 8.766.297C288.214 351.764 309.8 345.9 313 345.5c3.158-.395 3.654-2.445 3.506-3.46a.477.477 0 0 1-.006-.076c-.041-4.622-8.018-35.021-15-56.964-7-22-21-28.5-22.5-30s-7-5-15.5-13.5c-6.76-6.76-14.49-7.427-17.568-6.911a.499.499 0 0 1-.109.006c-2.847-.117-8.918 2.069-14.823.003-1.333-.533-5.2-2.698-10-7.098s-11-9.833-13.5-12c-8.171-7.429-14.271-15.554-18.5-28-10.873-32 5.532-57.5 11.5-73.5 5.832-15.635 2.068-32.206 2.001-32.496l-.002-.01c-.051-.292-2.012-11.566-2.999-16.994-1-5.5-4.5-14-13-21-11.194-7.842-15.652-6.802-25.309-7.842-9.657-1.04-15.314-7.578-17.691-9.658-1.783-1.56-5.366-.855-7 .5-6.092 5.052-16.491 8.731-26 12-7.607 2.615-14.667 6.564-16.5 8C88.871 49.918 77.8 58 75 60c-2.8 2-3.166 6.167-3 8l1 31.5c.238 4.873-3.87 6.752-6 7Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={10}
      d="m67 106.5-16.731 3.354c-4.903 1.486-3.764 4.094-3.269 5.646 8.526 18.24 19.582 34.995 30.5 48 14.345 17.086 24.094 24.217 27.084 26.971 7.696 7.089 8.411 10.503 9.496 15.689l.013.06c1.04 6.835-2.796 13.351-3.984 15.728-1.189 2.378-.446 3.863.891 5.052 1.337 1.189 45.232 45.083 48.5 48.5 3.269 3.417 45.314 45.517 52 52.5 2.863 2.99 4.606 5.394 9.509 6.092 4.651.661 8.333-2.689 8.823-3.218 3.714-4.012 7.699-8.7 10.671-12.117 2.971-3.417 5.2.297 5.497.743.297.446 6.118 9.294 7.752 12.265 1.635 2.972 5.943 11.292 9.657 17.086 1.293 2.016 3.179 4.489 5.646 5.497 3.206 1.311 7.087.801 8.766.297C288.214 351.764 309.8 345.9 313 345.5c3.2-.4 3.667-2.5 3.5-3.5 0-4.5-8-35-15-57s-21-28.5-22.5-30-7-5-15.5-13.5c-6.8-6.8-14.581-7.435-17.622-6.902-2.823-.149-8.935 2.08-14.878 0-1.333-.533-5.2-2.698-10-7.098s-11-9.833-13.5-12c-8.171-7.429-14.271-15.554-18.5-28-10.873-32 5.532-57.5 11.5-73.5 5.884-15.774 2-32.5 2-32.5s-2-11.5-3-17-4.5-14-13-21c-11.194-7.842-15.652-6.802-25.309-7.842-9.657-1.04-15.314-7.578-17.691-9.658-1.783-1.56-5.366-.855-7 .5-6.092 5.052-16.491 8.731-26 12-7.607 2.615-14.667 6.564-16.5 8C88.871 49.918 77.8 58 75 60c-2.8 2-3.166 6.167-3 8l1 31.5c.238 4.873-3.87 6.752-6 7Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={4}
      d="m67 106.5-16.731 3.354c-4.903 1.486-3.764 4.094-3.269 5.646 8.526 18.24 19.582 34.995 30.5 48 14.345 17.086 24.094 24.217 27.084 26.971 7.696 7.089 8.411 10.503 9.496 15.689l.013.06c1.04 6.835-2.796 13.351-3.984 15.728-1.189 2.378-.446 3.863.891 5.052 1.337 1.189 45.232 45.083 48.5 48.5 3.269 3.417 45.314 45.517 52 52.5 2.863 2.99 4.606 5.394 9.509 6.092 4.651.661 8.333-2.689 8.823-3.218 3.714-4.012 7.699-8.7 10.671-12.117 2.971-3.417 5.2.297 5.497.743.297.446 6.118 9.294 7.752 12.265 1.635 2.972 5.943 11.292 9.657 17.086 1.293 2.016 3.179 4.489 5.646 5.497 3.206 1.311 7.087.801 8.766.297C288.214 351.764 309.8 345.9 313 345.5c3.2-.4 3.667-2.5 3.5-3.5 0-4.5-8-35-15-57s-21-28.5-22.5-30-7-5-15.5-13.5c-6.8-6.8-14.581-7.435-17.622-6.902-2.823-.149-8.935 2.08-14.878 0-1.333-.533-5.2-2.698-10-7.098s-11-9.833-13.5-12c-8.171-7.429-14.271-15.554-18.5-28-10.873-32 5.532-57.5 11.5-73.5 5.884-15.774 2-32.5 2-32.5s-2-11.5-3-17-4.5-14-13-21c-11.194-7.842-15.652-6.802-25.309-7.842-9.657-1.04-15.314-7.578-17.691-9.658-1.783-1.56-5.366-.855-7 .5-6.092 5.052-16.491 8.731-26 12-7.607 2.615-14.667 6.564-16.5 8C88.871 49.918 77.8 58 75 60c-2.8 2-3.166 6.167-3 8l1 31.5c.238 4.873-3.87 6.752-6 7Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="m167.908 254.684 2.848 2.848-3.2 3.2-2.848-2.848zM161.507 261.084l2.848 2.848-3.2 3.2-2.848-2.848zM155.107 267.485l2.848 2.848-3.2 3.2-2.848-2.848zM148.707 273.885l2.848 2.848-3.2 3.2-2.848-2.848zM142.306 280.286l2.848 2.848-3.2 3.2-2.848-2.848zM167.556 260.732l2.848 2.848-3.2 3.2-2.848-2.848zM161.155 267.133l2.848 2.848-3.2 3.2-2.848-2.848zM154.755 273.533l2.848 2.848-3.2 3.2-2.848-2.848zM148.354 279.934l2.848 2.848-3.2 3.2-2.848-2.848z"
    />
    <path
      fill="#000"
      d="m170.756 257.532 2.848 2.848-3.2 3.2-2.848-2.848zM164.355 263.932l2.848 2.848-3.2 3.2-2.848-2.848zM157.955 270.333l2.848 2.848-3.2 3.2-2.848-2.848zM151.555 276.733l2.848 2.848-3.2 3.2-2.848-2.848zM145.154 283.134l2.848 2.848-3.2 3.2-2.848-2.848zM164.708 257.884l2.848 2.848-3.2 3.2-2.848-2.848zM158.307 264.284l2.848 2.848-3.2 3.2-2.848-2.848zM151.907 270.685l2.848 2.848-3.2 3.2-2.848-2.848zM145.506 277.085l2.848 2.848-3.2 3.2-2.848-2.848z"
    />
    <path
      fill="#E2645A"
      d="m116.774 269.144 3.51 17.448 13.973-13.973-17.483-3.475Zm-1.601-1.601c.515-.515 1.207-.784 1.905-.636l18.305 3.68c1.429.262 1.799 2.005.667 3.138l-14.695 14.695c-1.098 1.098-2.876.762-3.172-.633l-3.646-18.339a2.214 2.214 0 0 1 .636-1.905Z"
    />
  </svg>
));
export default SvgIconMelbourneMinimap;
