import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHelmet = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="M14.118 7.474h.63a2 2 0 0 1 1.99 1.787l.057.54a2 2 0 0 1-1.193 2.047l-3.508 1.52m2.024-5.894-2.024 5.894m2.024-5.894-7.08-.335m5.056 6.23L6.745 14.56c-.21.047-.427.06-.642.037L2.38 14.21m0 0-.23-.714a2 2 0 0 1-.032-1.107L3.49 7.037a.417.417 0 0 1 .509-.3m-1.62 7.473-.404 1.053M4 6.737 5.012 7a2 2 0 0 0 .409.062l1.618.077m-3.04-.402L5.03 4.858a2 2 0 0 1 .473-.574l1.177-.98a2 2 0 0 1 1.28-.462h.2a2 2 0 0 0 .57-.083l2.048-.609a2 2 0 0 1 1.02-.031l2.55.59a2 2 0 0 1 .99.562l2.673 2.78a2 2 0 0 1 .558 1.386v2.95a2 2 0 0 1-.197.866l-1.803 3.751a2 2 0 0 1-1.396 1.092l-.515.107a2 2 0 0 1-1.063-.068l-1.16-.402a2 2 0 0 0-1.469.063l-1.452.647-2.88 1.284a2 2 0 0 1-1.265.122l-2.628-.607a1.252 1.252 0 0 1-.886-1.669l.12-.31M7.038 7.14l3.031-3.455m-8.095 11.58 2.833 1.052"
    />
  </svg>
));
export default SvgIconHelmet;
