import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWarning = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={15}
    fill="none"
    viewBox="0 0 17 15"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M16.313 13.031c.5.875-.125 1.969-1.157 1.969H1.812a1.316 1.316 0 0 1-1.156-1.969L7.344 1.656c.5-.875 1.781-.875 2.312 0l6.656 11.375ZM7.75 5.25v4c0 .438.375.75.75.75a.74.74 0 0 0 .75-.75v-4a.76.76 0 0 0-.75-.75.74.74 0 0 0-.75.75ZM8.5 13a.974.974 0 0 0 .969-.969.974.974 0 0 0-.969-.969.98.98 0 0 0-1 .97.98.98 0 0 0 1 .968Z"
    />
  </svg>
));
export default SvgIconWarning;
