import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCheckmarkCircle = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M10.469 5.469 7 8.969l-1.5-1.5c-.313-.282-.781-.282-1.063 0-.312.312-.312.781 0 1.062l2 2A.753.753 0 0 0 7 10.75a.622.622 0 0 0 .5-.219l4-4a.684.684 0 0 0 0-1.031.709.709 0 0 0-1.031-.031ZM8 0C3.562 0 0 3.594 0 8c0 4.438 3.563 8 8 8 4.406 0 8-3.563 8-8 0-4.406-3.594-8-8-8Zm0 14.5A6.495 6.495 0 0 1 1.5 8c0-3.563 2.906-6.5 6.5-6.5 3.563 0 6.5 2.938 6.5 6.5 0 3.594-2.938 6.5-6.5 6.5Z"
    />
  </svg>
));
export default SvgIconCheckmarkCircle;
