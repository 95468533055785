import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconInfo = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    viewBox="0 0 16 17"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M8 .5c-4.438 0-8 3.594-8 8 0 4.438 3.563 8 8 8 4.406 0 8-3.563 8-8 0-4.406-3.594-8-8-8ZM8 15a6.495 6.495 0 0 1-6.5-6.5C1.5 4.937 4.406 2 8 2c3.563 0 6.5 2.938 6.5 6.5 0 3.594-2.938 6.5-6.5 6.5Zm1.25-4h-.5V8.25A.76.76 0 0 0 8 7.5H7a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h.25v2h-.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h2.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75ZM8 6.5c.531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1Z"
    />
  </svg>
));
export default SvgIconInfo;
