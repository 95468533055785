import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFeedback = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    viewBox="0 0 21 21"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M18.46.04c1.407 0 2.5 1.093 2.5 2.46v11.21c0 1.33-1.132 2.462-2.5 2.462h-5.624l-4.883 3.633c-.312.234-.742.039-.742-.352v-3.281H3.46c-1.406 0-2.5-1.094-2.5-2.461V2.5C.96 1.133 2.055.04 3.46.04h15Zm.665 13.71V2.5c0-.313-.313-.625-.625-.625h-15a.642.642 0 0 0-.625.625v11.25c0 .352.273.625.625.625h5.625v2.344l3.125-2.344h6.25a.642.642 0 0 0 .625-.625ZM13.5 6.875c-.703 0-1.21-.547-1.21-1.25 0-.664.585-1.25 1.21-1.25.664 0 1.25.625 1.25 1.25 0 .664-.586 1.25-1.25 1.25Zm-5 0c-.703 0-1.21-.547-1.21-1.25 0-.664.585-1.25 1.21-1.25.664 0 1.25.625 1.25 1.25 0 .664-.586 1.25-1.25 1.25Zm5.195 2.54c.352-.392.938-.47 1.328-.118.391.351.43.937.118 1.328-1.055 1.21-2.54 1.914-4.141 1.914a5.49 5.49 0 0 1-4.18-1.914.974.974 0 0 1 .117-1.328c.391-.352.977-.274 1.329.117.664.781 1.64 1.211 2.734 1.211 1.055 0 2.031-.43 2.695-1.21Z"
    />
  </svg>
));
export default SvgIconFeedback;
