/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from 'react';
import {
  useGetPushNotificationKey,
  useRegisterNotificationSubscription,
} from 'hooks';

interface NotificationProviderProps {
  children: ReactNode;
}

interface NotificationContextProps {
  requestNotificationPermission: () => Promise<void>;
  isNotificationPermissionGranted: boolean;
}

const Context = createContext<NotificationContextProps>(
  {} as NotificationContextProps
);

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [isNotificationPermissionGranted, setIsNotificationPermissionGranted] =
    useState(false);
  const { data: getPushNotificationKeyData } = useGetPushNotificationKey();
  const registerNotificationSubscription =
    useRegisterNotificationSubscription();

  const publicKey =
    getPushNotificationKeyData?.getPushNotificationKey?.publicKey;

  const requestNotificationPermission = useCallback(async () => {
    if (!publicKey) return;

    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        setIsNotificationPermissionGranted(true);

        await navigator.serviceWorker.register('/service-worker.js', {
          scope: '/',
        });

        const subscription = await navigator.serviceWorker.ready.then(
          (registration) => {
            return registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: publicKey,
            });
          }
        );

        registerNotificationSubscription.mutate({
          subscription: {
            endpoint: subscription.endpoint,
            keys: {
              p256dh: subscription.toJSON().keys?.p256dh || '',
              auth: subscription.toJSON().keys?.auth || '',
            },
          },
        });
      } else {
        setIsNotificationPermissionGranted(false);
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  }, [registerNotificationSubscription, publicKey]);

  return (
    <Context.Provider
      value={{ requestNotificationPermission, isNotificationPermissionGranted }}
    >
      {children}
    </Context.Provider>
  );
}

export function useNotification() {
  return useContext(Context);
}
