import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconEngineMode = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      strokeOpacity={0.8}
      d="M3.2 7.2 1.6 5.6l1.2-1.2L4 5.6l.4.4L6 4.4m0 0a2 2 0 1 0 4 0m-4 0a2 2 0 1 1 4 0m2.8 2.8 1.6-1.6-1.2-1.2L12 5.6l-.4.4L10 4.4M6.4 7.6h3.2m-5.2 2v1.2H6m4 0h1.6V9.6M6.4 8a1.6 1.6 0 1 1-3.2 0 1.6 1.6 0 0 1 3.2 0Zm6.4 0a1.6 1.6 0 1 1-3.2 0 1.6 1.6 0 0 1 3.2 0Zm-2.4 4a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0Z"
    />
  </svg>
));
export default SvgIconEngineMode;
