import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMedal = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#D83832"
      d="m10.375 8.625 1.375.188c.313.062.594.28.688.593.093.313.03.656-.22.875l-1 .969.25 1.375c.095.563-.374 1-.874 1a.83.83 0 0 1-.375-.094L9 12.875l-1.25.656a.864.864 0 0 1-.406.094c-.469 0-.938-.438-.844-1l.25-1.375-1-.969c-.25-.219-.313-.562-.219-.875.094-.312.375-.531.688-.594l1.375-.187.625-1.25A.891.891 0 0 1 9 6.937c.281 0 .594.125.75.438l.625 1.25Zm-.219 2.281 1.156-1.125-1.593-.25-.75-1.469-.719 1.47-1.594.25 1.157 1.124-.282 1.625 1.438-.781 1.469.781-.282-1.625ZM9 5c3.031 0 5.5 2.469 5.5 5.5 0 3.063-2.469 5.5-5.5 5.5a5.468 5.468 0 0 1-5.5-5.5C3.5 7.469 5.938 5 9 5Zm0 10c2.469 0 4.5-2 4.5-4.5C13.5 8.031 11.469 6 9 6c-2.5 0-4.5 2.031-4.5 4.5C4.5 13 6.5 15 9 15ZM4.5 6c-.188 0-.344-.063-.438-.219L1.156 1.47C.97 1.187.938.812 1.094.53 1.25.22 1.562 0 1.906 0h3.282C5.5 0 5.813.188 6 .469l2 3.344a.518.518 0 0 1-.188.687.518.518 0 0 1-.687-.188l-2-3.343L2.031 1l2.875 4.25a.48.48 0 0 1-.156.688C4.687 6 4.594 6 4.5 6ZM16.875.531a.913.913 0 0 1-.063.969l-2.906 4.281A.455.455 0 0 1 13.5 6c-.125 0-.219 0-.281-.063a.51.51 0 0 1-.156-.718L15.937 1h-3.156l-1.937 3.313a.518.518 0 0 1-.688.187.518.518 0 0 1-.187-.688l2-3.343c.187-.281.5-.469.812-.469h3.281c.344 0 .657.219.813.531Z"
    />
  </svg>
));
export default SvgIconMedal;
