import { Flex, FlexProps } from '@chakra-ui/react';

const PageHeading = ({ children, ...rest }: FlexProps) => {
  return (
    <Flex
      paddingX={["12, 6"]}
      paddingY="4"
      borderBottom="solid 1px"
      borderColor="white.40"
      mb="6"
      width="full"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default PageHeading;
