import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconEngineCooling = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="m8.304 19-4.895-3.443a2 2 0 0 1 .053-3.308l.956-.626M8.304 19l8.45-5.828a2 2 0 0 0 .092-3.225L14 7.734M8.304 19l-.648-6.136M4.418 4h4.447a2 2 0 0 1 1.294.475L14 7.734M4.418 4C3.166 4 2.15 5.27 2.15 6.836c0 1.567 1.015 3.014 2.267 3.014m0-5.85C5.67 4 6.685 5.27 6.685 6.836c0 1.567-1.015 3.014-2.267 3.014m0 0v1.773m0-1.773 3.238.177m-3.238 1.596 3.238 1.24m0-2.836v2.837m0-2.837L14 7.734"
    />
  </svg>
));
export default SvgIconEngineCooling;
