import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconLusailMinimapMarkers = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={376}
    height={375}
    fill="none"
    viewBox="0 0 376 375"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M50.494 69.83c-4.154-1.9-9.542-1.29-11.727-.746l-.016.004c-.213.064-6.64 1.97-13.068 4.308-6.155 2.238-9.15 3.422-9.479 3.553a.491.491 0 0 0-.06.03c-5.471 3.113-6.363 5.935-8.29 11.125-1.546 4.16.247 9.658 1.337 11.886 7.676 22.336 23.98 69.741 27.783 80.676 4.754 13.669 11.589 15.451 13.372 16.789 1.783 1.337 24.96 13.223 31.646 17.234 6.686 4.012 9.063 8.617 8.914 17.532-.119 7.131-8.27 12.777-12.331 14.709-6.934 3.863-16.343 9.211-23.03 12.777-5.2 2.773-9.656 4.16-16.342 10.252-4.912 4.474-4.359 13.124-3.418 16.491 4.606 12.332 15.155 11.886 19.464 11.886 4.308 0 282.735.594 292.987.594 10.251 0 13.372-5.2 14.412-6.388 1.04-1.189 2.971-3.566 3.714-10.103.594-5.23-5.596-14.858-8.766-19.018-16.195-22.88-43.086-64.332-46.949-69.829-3.863-5.498-5.052-7.578-5.795-14.115-.594-5.23 3.219-10.697 5.2-12.777 12.679-14.808 38.6-45.107 40.858-47.841 2.823-3.417 4.606-6.983 5.497-11.589.714-3.684-1.386-8.666-2.525-10.697-5.844-10.598-17.799-32.27-18.869-34.172-1.337-2.377-3.154-4.881-6.537-6.686-4.457-2.377-8.073-2.179-9.955-2.228h-22.286c-4.16 0-9.459.891-11.589 1.337-8.914 1.783-15.303 11.291-17.086 15.897-1.783 4.606-10.4 25.852-15.006 37.738-4.605 11.886-13.223 33.429-30.606 47.543-17.383 14.115-29.715 16.789-35.657 18.572-5.943 1.783-9.361 1.635-12.184 1.04-2.822-.594-5.348-1.931-7.725-5.497-2.802-4.203-1.783-8.469-1.486-10.697 2.873-10.599 8.587-32.033 9.063-33.578.594-1.931 1.783-6.091 1.189-10.846-.595-4.754-2.378-7.726-9.212-13.371-6.834-5.646-10.846-10.55-16.046-18.126-5.2-7.578-8.914-19.76-9.806-23.475-.891-3.715-1.931-9.212-4.903-12.777-2.971-3.566-8.171-6.092-13.223-6.092-4.041 0-7.825 2.08-9.211 3.12-5.111 3.21-7.082 10.747-7.429 14.115-3.17 25.059-9.568 75.386-9.806 76.218l-.006.021c-.311 1.091-1.818 6.369-7.868 6.516-4.874.119-7.677-3.714-8.47-5.646-6.14-17.779-18.303-54.25-19.76-57.052-1.93-3.714-3.714-6.24-8.914-8.617Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.2}
      strokeWidth={6}
      d="M50.494 69.83c-4.16-1.902-9.558-1.288-11.737-.743 0 0-6.537 1.931-13.074 4.308-6.538 2.378-9.51 3.566-9.51 3.566-5.497 3.12-6.388 5.943-8.32 11.143-1.545 4.16.248 9.658 1.338 11.886 7.676 22.336 23.98 69.741 27.783 80.676 4.754 13.669 11.589 15.451 13.372 16.789 1.783 1.337 24.96 13.223 31.646 17.234 6.686 4.012 9.063 8.617 8.914 17.532-.119 7.131-8.27 12.777-12.331 14.709-6.934 3.863-16.343 9.211-23.03 12.777-5.2 2.773-9.656 4.16-16.342 10.252-4.912 4.474-4.359 13.124-3.418 16.491 4.606 12.332 15.155 11.886 19.464 11.886 4.308 0 282.735.594 292.987.594 10.251 0 13.372-5.2 14.412-6.388 1.04-1.189 2.971-3.566 3.714-10.103.594-5.23-5.596-14.858-8.766-19.018-16.195-22.88-43.086-64.332-46.949-69.829-3.863-5.498-5.052-7.578-5.795-14.115-.594-5.23 3.219-10.697 5.2-12.777 12.679-14.808 38.6-45.107 40.858-47.841 2.823-3.417 4.606-6.983 5.497-11.589.714-3.684-1.386-8.666-2.525-10.697-5.844-10.598-17.799-32.27-18.869-34.172-1.337-2.377-3.154-4.881-6.537-6.686-4.457-2.377-8.073-2.179-9.955-2.228h-22.286c-4.16 0-9.459.891-11.589 1.337-8.914 1.783-15.303 11.291-17.086 15.897-1.783 4.606-10.4 25.852-15.006 37.738-4.605 11.886-13.223 33.429-30.606 47.543-17.383 14.115-29.715 16.789-35.657 18.572-5.943 1.783-9.361 1.635-12.184 1.04-2.822-.594-5.348-1.931-7.725-5.497-2.802-4.203-1.783-8.469-1.486-10.697 2.873-10.599 8.587-32.033 9.063-33.578.594-1.931 1.783-6.091 1.189-10.846-.595-4.754-2.378-7.726-9.212-13.371-6.834-5.646-10.846-10.55-16.046-18.126-5.2-7.578-8.914-19.76-9.806-23.475-.891-3.715-1.931-9.212-4.903-12.777-2.971-3.566-8.171-6.092-13.223-6.092-4.041 0-7.825 2.08-9.211 3.12-5.111 3.21-7.082 10.747-7.429 14.115-3.17 25.059-9.568 75.386-9.806 76.218l-.006.021c-.311 1.091-1.818 6.369-7.868 6.516-4.874.119-7.677-3.714-8.47-5.646-6.14-17.779-18.303-54.25-19.76-57.052-1.93-3.714-3.714-6.24-8.914-8.617Z"
    />
    <path
      stroke="#fff"
      strokeOpacity={0.95}
      strokeWidth={2}
      d="M50.494 69.83c-4.16-1.902-9.558-1.288-11.737-.743 0 0-6.537 1.931-13.074 4.308-6.538 2.378-9.51 3.566-9.51 3.566-5.497 3.12-6.388 5.943-8.32 11.143-1.545 4.16.248 9.658 1.338 11.886 7.676 22.336 23.98 69.741 27.783 80.676 4.754 13.669 11.589 15.451 13.372 16.789 1.783 1.337 24.96 13.223 31.646 17.234 6.686 4.012 9.063 8.617 8.914 17.532-.119 7.131-8.27 12.777-12.331 14.709-6.934 3.863-16.343 9.211-23.03 12.777-5.2 2.773-9.656 4.16-16.342 10.252-4.912 4.474-4.359 13.124-3.418 16.491 4.606 12.332 15.155 11.886 19.464 11.886 4.308 0 282.735.594 292.987.594 10.251 0 13.372-5.2 14.412-6.388 1.04-1.189 2.971-3.566 3.714-10.103.594-5.23-5.596-14.858-8.766-19.018-16.195-22.88-43.086-64.332-46.949-69.829-3.863-5.498-5.052-7.578-5.795-14.115-.594-5.23 3.219-10.697 5.2-12.777 12.679-14.808 38.6-45.107 40.858-47.841 2.823-3.417 4.606-6.983 5.497-11.589.714-3.684-1.386-8.666-2.525-10.697-5.844-10.598-17.799-32.27-18.869-34.172-1.337-2.377-3.154-4.881-6.537-6.686-4.457-2.377-8.073-2.179-9.955-2.228h-22.286c-4.16 0-9.459.891-11.589 1.337-8.914 1.783-15.303 11.291-17.086 15.897-1.783 4.606-10.4 25.852-15.006 37.738-4.605 11.886-13.223 33.429-30.606 47.543-17.383 14.115-29.715 16.789-35.657 18.572-5.943 1.783-9.361 1.635-12.184 1.04-2.822-.594-5.348-1.931-7.725-5.497-2.802-4.203-1.783-8.469-1.486-10.697 2.873-10.599 8.587-32.033 9.063-33.578.594-1.931 1.783-6.091 1.189-10.846-.595-4.754-2.378-7.726-9.212-13.371-6.834-5.646-10.846-10.55-16.046-18.126-5.2-7.578-8.914-19.76-9.806-23.475-.891-3.715-1.931-9.212-4.903-12.777-2.971-3.566-8.171-6.092-13.223-6.092-4.041 0-7.825 2.08-9.211 3.12-5.111 3.21-7.082 10.747-7.429 14.115-3.17 25.059-9.568 75.386-9.806 76.218l-.006.021c-.311 1.091-1.818 6.369-7.868 6.516-4.874.119-7.677-3.714-8.47-5.646-6.14-17.779-18.303-54.25-19.76-57.052-1.93-3.714-3.714-6.24-8.914-8.617Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={42.621}
      y={270.107}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m52.848 278.183-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={63.422}
      y={219.592}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m70.827 232.828 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={44.404}
      y={169.968}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M51.591 183.732v-1.188h1.392v.768l.468.456h2.28l.468-.456v-1.524l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={14.986}
      y={82.31}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M26.094 95.702h-4.08V94.13l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={46.781}
      y={44.869}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M54.07 58.633V57.41h1.392v.804l.468.456h2.257l.468-.456V56.15l-.468-.468H54.19V51.47h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904L58.81 59.87h-3.505l-1.235-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={84.221}
      y={146.791}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M91.53 160.555v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={155.834}
      y={65.669}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M162.99 79.433v-2.34l.756-.756-.636-.636v-2.196l1.236-1.236h3.528l1.236 1.236v2.196l-.636.636.756.756v2.34l-1.236 1.236h-3.768l-1.236-1.236Zm4.248-3.612.48-.48V73.95l-.48-.48h-2.256l-.48.48v1.392l.48.48h2.256Zm.12 3.648.48-.48v-1.524l-.48-.468h-2.496l-.48.468v1.524l.48.48h2.496Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={111.855}
      y={48.138}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M123.417 56.166v-.228h-4.248v-1.2h5.688v1.464l-3.348 6.936h-1.464l3.372-6.972Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={147.811}
      y={107.27}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="M155.151 121.034v-1.02h1.392v.576l.48.48h2.184l.48-.48v-2.172l-.744.744h-2.676l-1.236-1.236v-2.82l1.236-1.236h3.576l1.236 1.236v5.928l-1.236 1.236h-3.456l-1.236-1.236Zm3.648-3.072.888-.888v-1.524l-.48-.48h-2.304l-.48.48v1.932l.48.48h1.896Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={172.475}
      y={147.979}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m178.715 156.055-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.263 5.592v-5.736l1.332-1.332h3.624l1.332 1.332v5.736l-1.332 1.332h-3.624l-1.332-1.332Zm4.308.132.588-.576v-4.848l-.588-.576h-2.328l-.588.576v4.848l.588.576h2.328Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={220.91}
      y={107.27}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m228.768 115.346-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm4.738 0-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={279.15}
      y={61.509}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m285.854 69.585-1.836.948V69.25l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.07 5.16 4.344-4.056v-.924l-.468-.456h-2.064l-.468.456v.78h-1.392v-1.2l1.236-1.236h3.312l1.236 1.236v1.752l-4.308 3.972v.252h4.356v1.188h-5.784v-1.764Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={310.648}
      y={61.509}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m317.182 69.585-1.836.948V69.25l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.022 5.688v-1.188h1.392v.768l.468.456h2.28l.468-.456V73.33l-.444-.444h-2.4v-1.2h2.4l.444-.444v-1.476l-.468-.456h-2.208l-.468.456v.768h-1.392v-1.188l1.236-1.236h3.456l1.236 1.236v2.304l-.648.636.648.648v2.34l-1.236 1.236h-3.528l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={327.586}
      y={93.898}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m334.26 101.974-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm6.803 5.316h-4.08v-1.572l3.684-5.22h1.788v5.592h1.02v1.2h-1.02v1.608h-1.392v-1.608Zm.048-1.164v-4.152h-.06l-2.712 3.876v.276h2.772Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={313.916}
      y={167.591}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m320.432 175.667-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.142 5.688v-1.224h1.392v.804l.468.456h2.256l.468-.456v-2.064l-.468-.468h-3.996v-4.212h5.688v1.2h-4.32v1.812h3.24l1.248 1.248v2.904l-1.236 1.236h-3.504l-1.236-1.236Z"
    />
    <rect
      width={20.8}
      height={20.8}
      x={337.986}
      y={270.998}
      fill="#26A69A"
      rx={10.4}
    />
    <path
      fill="#fff"
      d="m344.403 279.074-1.836.948v-1.284l2.076-1.14h1.152v8.4h-1.392v-6.924Zm3.262 5.688v-5.928l1.236-1.236h3.528l1.236 1.236v1.104h-1.392v-.66l-.48-.48h-2.256l-.48.48v2.256l.792-.78h2.628l1.236 1.236v2.772l-1.236 1.236h-3.576l-1.236-1.236Zm4.176.036.48-.48v-1.884l-.48-.48h-1.8l-.984 1.008v1.356l.48.48h2.304Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M147.777 278.13h4.028v4.526h-4.028zM147.777 287.181h4.028v4.526h-4.028zM147.777 296.233h4.028v4.526h-4.028zM147.777 305.285h4.028v4.526h-4.028zM147.777 314.336h4.028v4.526h-4.028zM151.805 282.656h4.028v4.526h-4.028zM151.805 291.707h4.028v4.526h-4.028zM151.805 300.759h4.028v4.526h-4.028zM151.805 309.81h4.028v4.526h-4.028z"
    />
    <path
      fill="#000"
      d="M151.805 278.13h4.028v4.526h-4.028zM151.805 287.181h4.028v4.526h-4.028zM151.805 296.233h4.028v4.526h-4.028zM151.805 305.285h4.028v4.526h-4.028zM151.805 314.336h4.028v4.526h-4.028zM147.777 282.656h4.028v4.526h-4.028zM147.777 291.707h4.028v4.526h-4.028zM147.777 300.759h4.028v4.526h-4.028zM147.777 309.81h4.028v4.526h-4.028z"
    />
    <path
      fill="#E2645A"
      d="m121.846 324.512 14.819 9.856v-19.761l-14.819 9.905Zm-2.264 0c0-.728.299-1.408.897-1.797l15.545-10.341c1.196-.826 2.691.145 2.691 1.748v20.78c0 1.554-1.495 2.574-2.691 1.797l-15.545-10.391a2.207 2.207 0 0 1-.897-1.796Z"
    />
  </svg>
));
export default SvgIconLusailMinimapMarkers;
