import {
  ChakraComponent,
  Flex,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { PriceLabel } from 'components/Balance/PriceLabel';
import { WalletModal } from 'components/Wallet/WalletModal/WalletModal';
import { TokenPossibilities } from 'context/fundsContext';
import { IconAdd, IconDollarBalance } from 'icons';
import { SVGProps } from 'react';

type Props = {
  balance: number;
  currency?: string;
  color?: string;
  icon?: ChakraComponent<
    (props: SVGProps<SVGSVGElement>) => React.JSX.Element,
    object
  >;
};

export const BalanceComponent = ({
  balance = 0,
  currency = 'MATIC',
  color = 'matic.100',
  icon = IconDollarBalance,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex flexDir="column" gap={2} w="full">
      <WalletModal
        isOpen={isOpen}
        onClose={onClose}
        token={currency as TokenPossibilities}
        fiatAmount={0}
      />
      <Heading fontSize="12px" fontWeight={400} textTransform="uppercase">
        Balance
      </Heading>
      <Flex
        bg="white.10"
        flexDir="row"
        justify="space-between"
        p={3}
        alignItems="center"
        w="full"
      >
        <PriceLabel
          value={balance.toString()}
          currency={currency}
          color={color}
          numberSize="1.4rem"
          iconSize="0.75remrem"
          currencySize="16px"
          icon={icon}
        />

        <IconAdd onClick={onOpen} cursor="pointer" />
      </Flex>
    </Flex>
  );
};
