import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTemperatureConditions = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
      viewBox="0 0 12 12"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#fff"
        fillOpacity={0.4}
        d="M6.375 7.57c.422.164.75.586.75 1.078A1.14 1.14 0 0 1 6 9.773a1.125 1.125 0 0 1-1.125-1.125c0-.492.305-.914.75-1.078V4.875c0-.188.164-.375.375-.375.188 0 .375.188.375.375V7.57Zm2.25-1.03c.445.585.75 1.288.75 2.085A3.376 3.376 0 0 1 6 12a3.361 3.361 0 0 1-3.375-3.375c0-.797.281-1.5.75-2.086V2.625C3.375 1.195 4.57 0 6 0s2.625 1.195 2.625 2.625v3.914ZM6 10.5a1.86 1.86 0 0 0 1.875-1.852c0-.585-.305-1.148-.75-1.476V2.648A1.14 1.14 0 0 0 6 1.523c-.633 0-1.125.516-1.125 1.125v4.524A1.843 1.843 0 0 0 6 10.5Z"
      />
    </svg>
  )
);
export default SvgIconTemperatureConditions;
