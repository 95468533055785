import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWindConditions = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={12}
    fill="none"
    viewBox="0 0 13 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M3.667 2.25V1.219l1.875.375V8.18l-1.875.375V7.5h-1.5v4.125c0 .21-.188.375-.375.375h-.75a.37.37 0 0 1-.375-.375V.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75v1.5h1.5Zm0 4.125v-3h-1.5v3h1.5Zm3.375 1.5v-6l2.25.469v5.062l-2.25.469Zm5.156-4.945c.258.047.469.28.469.539v2.836a.53.53 0 0 1-.469.515l-1.406.305v-4.5l1.406.305Z"
    />
  </svg>
));
export default SvgIconWindConditions;
