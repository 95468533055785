import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMoneyBagUpgrade = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M11.156 3.625c6.094 3.719 5.813 8.656 5.813 9.25 0 1.75-1.532 3.125-3.407 3.125H4.406C2.531 16 1 14.625 1 12.875c0-.625-.313-5.5 5.813-9.25l-1.75-2.531a.736.736 0 0 1 0-.719C5.218.156 5.438 0 5.718 0h6.5c.281 0 .5.156.625.375s.156.5.031.719l-1.719 2.531ZM10.812 1.5H7.156l1.188 1.75h1.25l1.219-1.75Zm2.75 13c1.063 0 1.907-.719 1.907-1.625 0-2.531-1.063-5.469-5.344-8.125H7.812C3.5 7.406 2.47 10.344 2.5 12.875c0 .906.844 1.625 1.906 1.625h9.157Zm-2.343-3.25c.25-1.563-1.219-1.969-2.031-2.219L9 9c-.781-.25-.781-.344-.75-.469.031-.219.469-.312 1-.25.156.031.344.094.625.188A.734.734 0 0 0 10.813 8c.156-.375-.063-.813-.47-.969-.218-.062-.405-.125-.593-.156V6.5A.76.76 0 0 0 9 5.75a.74.74 0 0 0-.75.75v.344c-.813.187-1.344.687-1.469 1.437C6.5 9.812 8 10.25 8.563 10.437l.218.063c1 .281.969.344.938.5-.031.219-.469.313-1 .25-.25-.063-.563-.156-.844-.25l-.125-.063a.74.74 0 0 0-.969.438.732.732 0 0 0 .469.969l.125.062c.281.094.563.188.875.25V13c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75v-.313c.781-.187 1.313-.687 1.469-1.437Z"
    />
  </svg>
));
export default SvgIconMoneyBagUpgrade;
