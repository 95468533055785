import { UserSettingsInput } from './../api/generated/graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useUserSettings = () => {
  const queryClient = useQueryClient();
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const queryUserSettings = graphql(`
    query GetUserSettings {
      getUser {
        id
        email
        playerName
        avatarId
        rpmUserId
        rpmAuthToken
        distanceReadings
        temperatureReadings
        totalEarnings
        rewardsDefault
        walletAddress
        walletChain
        blockPaidRaces
        loginVerified
        testRacesAllowed
        totalRaces
        wearables {
          name
          id
          catalog {
            id
            externalInfo {
              maleId
              femaleId
            }
          }
        }
      }
    }
  `);

  const mutateUserSettings = graphql(`
    mutation ChangeUserSettings($settings: UserSettingsInput!) {
      setUserSettings(userSettingsInput: $settings) {
        playerName
        avatarId
        rpmUserId
        rpmAuthToken
        walletAddress
        walletChain
      }
    }
  `);

  const getUserSettings = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['userSettings'],
    queryFn: async () => {
      return graphQLClient.request(queryUserSettings);
    },
  });

  const changeUserSettings = useMutation({
    mutationKey: ['changeUserSettings'],
    mutationFn: async (settings: UserSettingsInput) => {
      return graphQLClient.request(mutateUserSettings, {
        settings,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userSettings'] });
    },
    onError: (e) => {
      console.error(e);
    },
  });

  return {
    getUserSettings,
    changeUserSettings,
  };
};

export default useUserSettings;
