import React from 'react';
import { Box, useTheme } from '@chakra-ui/react';
import { IconCheckeredFlag } from 'icons';

interface IconCornerFlagProps {
  isVisible?: boolean | null;
}

const IconCornerFlag = ({ isVisible }: IconCornerFlagProps) => {
  const theme = useTheme();

  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      w="2.5rem"
      h="2.5rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={1}
      opacity={isVisible ? 1 : 0}
    >
      <IconCheckeredFlag
        pos="absolute"
        top="0.625rem"
        right="0.625rem"
        w="1rem"
        h="1rem"
        zIndex={5}
        __css={{
          path: {
            fill: 'black.60',
          },
        }}
      />

      <Box
        zIndex={4}
        pos="absolute"
        width="100%"
        height="100%"
        bg={theme.colors.bloodMoon[100]}
        clipPath="polygon(100% 0, 100% 100%, 50% 100%, 0 50%, 0 0)"
      />
    </Box>
  );
};

export default IconCornerFlag;
