import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPause = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={30}
    fill="none"
    viewBox="0 0 21 30"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M2.375 0C3.391 0 4.25.86 4.25 1.875v26.25C4.25 29.219 3.39 30 2.375 30 1.281 30 .5 29.219.5 28.125V1.875C.5.859 1.281 0 2.375 0Zm16.25 0C19.641 0 20.5.86 20.5 1.875v26.25c0 1.094-.86 1.875-1.875 1.875-1.094 0-1.875-.781-1.875-1.875V1.875C16.75.859 17.531 0 18.625 0Z"
    />
  </svg>
));
export default SvgIconPause;
