import { Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Icon2Cars, IconCheckeredFlag, IconRenew, IconUpgrade } from 'icons';
import UpgradeDrawer from './UpgradeDrawer/UpgradeDrawer';
import RenewDrawer from './RenewDrawer/RenewDrawer';
import { useUpgradeModal } from 'context';
import { Router, useRouter } from 'next/router';

const UpgradeCounter = ({
  timesUsed,
  maximumTimesUsed,
  isGarage,
  isTrialCar,
  trialLastUpgradedAt,
}: {
  timesUsed: number;
  maximumTimesUsed: number | null;
  isGarage?: boolean;
  isTrialCar?: boolean;
  trialLastUpgradedAt?: Date;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isUpgradeDrawerOpen, onUpgradeDrawerOpen, onUpgradeDrawerClose } =
    useUpgradeModal();
  const { isRenewDrawerOpen, onRenewDrawerOpen, onRenewDrawerClose } =
    useUpgradeModal();
  const percentageUsed = maximumTimesUsed
    ? (timesUsed / maximumTimesUsed) * 100
    : 0;

  const router = useRouter();

  return (
    <>
      <Tooltip
        label="Races Completed Within Limit"
        openDelay={500}
        isOpen={isOpen}
      >
        <Flex
          justifyContent={{
            base: isGarage ? 'center' : 'space-between',
            md: 'space-between',
          }}
          minW={isGarage ? '6rem' : { base: '10rem', md: '20rem' }}
          ml={isGarage ? 0 : { base: 0, md: 'auto' }}
          alignItems="center"
          borderRadius="4px"
          zIndex={10}
          onClick={(e) => {
            e.stopPropagation();

            isTrialCar
              ? trialLastUpgradedAt
                ? onRenewDrawerOpen()
                : onUpgradeDrawerOpen()
              : router.push('/car-fusion');

            onClose();
          }}
          px={{ base: '1', md: '3' }}
          py={{ base: '1', md: '2' }}
          position="relative"
          bg="rgba(0, 0, 0, 0.2);"
          cursor={'pointer'}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          backgroundImage={`linear-gradient(to right, ${
            timesUsed >= (maximumTimesUsed ?? 0)
              ? isOpen
                ? 'baltic.100'
                : 'rgba(26, 116, 107, 1)'
              : 'rgba(26, 116, 107, 1)'
          } ${percentageUsed}%, rgba(0, 0, 0, 0.2) ${percentageUsed}%)`}
          _hover={{
            backgroundImage: `linear-gradient(to right, ${
              timesUsed >= (maximumTimesUsed ?? 0)
                ? 'baltic.100'
                : 'treasuredTeal.100'
            } ${percentageUsed}%, rgba(0, 0, 0, 0.2) ${percentageUsed}%)`,
            transition: 'all 0.3s ease',
            cursor: 'pointer',
          }}
        >
          {!isGarage && (
            <Text
              fontFamily={'heading'}
              fontSize={{ base: 'xs', md: 'md' }}
              color={
                timesUsed >= (maximumTimesUsed ?? 0) ? 'black.95' : 'white.80'
              }
              zIndex={1} // Keep text above the background
            >
              {isTrialCar
                ? trialLastUpgradedAt
                  ? 'RENEW'
                  : 'UPGRADE'
                : 'FUSION'}
            </Text>
          )}

          <Flex
            alignItems="center"
            gap={{ base: '1', md: '2' }}
            zIndex={1} // Keep text above the background
          >
            <Text
              fontFamily={'body'}
              fontSize={{ base: 'md', md: 'lg' }}
              color={
                timesUsed >= (maximumTimesUsed ?? 0) ? 'black.95' : 'white.80'
              }
            >
              {timesUsed}/{maximumTimesUsed}
            </Text>
            {timesUsed >= (maximumTimesUsed ?? 0) ? (
              isTrialCar ? (
                trialLastUpgradedAt ? (
                  <IconRenew
                    w={{ base: '1rem', md: '1.25rem' }}
                    sx={{
                      '& path': {
                        fill: 'black.95',
                      },
                    }}
                  />
                ) : (
                  <IconUpgrade
                    w={{ base: '1rem', md: '1.25rem' }}
                    sx={{
                      '& path': {
                        fill: 'black.95',
                      },
                    }}
                  />
                )
              ) : (
                <Icon2Cars
                  w={{ base: '1rem', md: '1.25rem' }}
                  sx={{
                    '& path': {
                      fill: 'black.95',
                    },
                  }}
                />
              )
            ) : (
              <IconCheckeredFlag
                w={{ base: '1rem', md: '1.25rem' }}
                sx={{
                  '& path': {
                    stroke: 'white.80',
                    fill: 'white.80',
                  },
                }}
              />
            )}
          </Flex>
        </Flex>
      </Tooltip>
      <UpgradeDrawer
        isOpen={isUpgradeDrawerOpen}
        onClose={onUpgradeDrawerClose}
        maximumTimesUsed={maximumTimesUsed ?? 0}
        timesUsed={timesUsed}
      />
      <RenewDrawer isOpen={isRenewDrawerOpen} onClose={onRenewDrawerClose} />
    </>
  );
};

export default UpgradeCounter;
