import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetNextUpRaces = () => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const racesGql = graphql(`
    query GetNextUpRaces {
      getRaces(filter: { status: [open, tuning, closed, qualifying, live] }) {
        id
        laps
        prizePool
        status
        userInRace

        createdAt
        executedAt
        tuningPeriodEndTime
        qualifyingAnnouncementTime
        startTime
        numberOfTickets

        tier {
          name
        }

        track {
          id
          frontendName
          length
          description
          has2D
          has3D
        }
      }
    }
  `);

  const getNextUpRaces = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['nextUpRacesQuery'],
    queryFn: async () => {
      return graphQLClient.request(racesGql);
    },
  });

  return getNextUpRaces;
};

export default useGetNextUpRaces;
