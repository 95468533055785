import { Box, Flex, FlexProps, Text, ResponsiveValue } from "@chakra-ui/react"

interface PartInfoProps extends FlexProps {
  title1: string;
  status1: "negative-1" | "negative-2" | "neutral" | "positive-1" | "positive-2";
  title2?: string;
  status2?: "negative-1" | "negative-2" | "neutral" | "positive-1" | "positive-2";
  barWidth?: ResponsiveValue<number>;
}

const PartInfo = ({ title1, status1, title2, status2, barWidth, ...rest }: PartInfoProps) => {
  const getBoxConfig = (status: string) => {
    const boxes = [
      { color: "carbon.100", content: "" },
      { color: "carbon.100", content: "" },
      { color: "white.80", content: "=", rounded: true },
      { color: "carbon.100", content: "" },
      { color: "carbon.100", content: "" },
    ];

    if (status === "negative-1") {
      boxes[1].color = "bloodMoon.100";
      boxes[2].color = "bloodMoon.100";
      boxes[2].content = "-"
    } else if (status === "negative-2") {
      boxes[0].color = "bloodMoon.100";
      boxes[1].color = "bloodMoon.100";
      boxes[2].color = "bloodMoon.100";
      boxes[2].content = "-"
    } else if (status === "positive-1") {
      boxes[2].color = "baltic.100";
      boxes[2].content = "+"
      boxes[3].color = "baltic.100";
    } else if (status === "positive-2") {
      boxes[2].color = "baltic.100";
      boxes[3].color = "baltic.100";
      boxes[2].content = "+"
      boxes[4].color = "baltic.100";
    }

    return boxes;
  };

  const boxConfig = getBoxConfig(status1);
  const boxConfig2 = getBoxConfig(status2 || "");
  return (
    <Flex position="absolute" top="1" px="1" flexDir="column" zIndex={1} justifyContent="flex-start" {...rest}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="xs">
          {title1.toUpperCase()}
        </Text>
        <Flex gap={1} alignItems="center" ml="3">
          {boxConfig.map((box, index) => (
            <Flex
              key={index}
              justifyContent="center"
              alignItems="center"
              color="black.60"
              fontSize="md"
              w={box.rounded ? "4" : (barWidth || "4")}
              h={box.rounded ? "4" : "1"}
              bg={box.color}
              rounded={box.rounded ? "full" : "undefined"}
            >
              {box.content}
            </Flex>
          ))}
        </Flex>
      </Flex>
      {title2 && (
        <Flex mt="1" alignItems="center">
          <Text fontSize="xs">
            {title2}
          </Text>
          <Flex gap={1} alignItems="center" ml="3">
            {boxConfig2.map((box, index) => (
              <Flex
                key={index}
                justifyContent="center"
                alignItems="center"
                color="black.60"
                fontSize="md"
                w={box.rounded ? "4" : (barWidth || "4")}
                h={box.rounded ? "4" : "1"}
                bg={box.color}
                rounded={box.rounded ? "full" : "undefined"}
              >
                {box.content}
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default PartInfo;