/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DrawerProps, Flex, Heading } from '@chakra-ui/react';
import { CrossmintEvent } from '@crossmint/client-sdk-react-ui';
import { useQueryClient } from '@tanstack/react-query';
import { CurrencyType } from 'api/generated/graphql';

import { RCrossmintPayment, RDrawer } from 'components';
import { useTicketPaymentProcessSucceeded } from 'hooks';
import { IconTicket } from 'icons';
import { useState } from 'react';

interface BuyTicketsProps extends DrawerProps {
  quantity: number;
  totalPrice: number;
  currency?: CurrencyType;
  handleFinish: () => void;
}

const paymentEnv = process.env.NEXT_PUBLIC_ENV === 'dev' ? 'staging' : 'production';
const ticketsContractId = process.env.NEXT_PUBLIC_TICKETS_CONTRACT_ID || '';
const projectId = process.env.NEXT_PUBLIC_CROSSMINT_API_ID || '';

const TicketsBundleDrawer = ({
  quantity,
  totalPrice,
  currency = CurrencyType.Usd,
  handleFinish,
  ...rest
}: Omit<BuyTicketsProps, 'children'>) => {
  const queryClient = useQueryClient();
  const mutation = useTicketPaymentProcessSucceeded();
  const [isLoading, setIsLoading] = useState(false);

  const onEvent = async (event: CrossmintEvent) => {
    const paymentStatus = event.type;

    if (
      paymentStatus === 'payment:process.started' ||
      paymentStatus === 'order:process.started'
    ) {
      setIsLoading(true);
    }

    if (
      paymentStatus === 'payment:process.rejected' ||
      paymentStatus === 'payment:process.canceled' ||
      paymentStatus === 'payment:preparation.failed' ||
      paymentStatus === 'transaction:fulfillment.failed'
    ) {
      setIsLoading(false);
    }

    if (
      paymentStatus === 'payment:process.succeeded' ||
      paymentStatus === 'order:process.finished' ||
      paymentStatus === 'transaction:fulfillment.succeeded'
    ) {
      await mutation.mutateAsync({
        succeededInput: {
          // @ts-ignore
          orderIdentifier: event.payload.orderIdentifier,
          currency,
        },
      });

      queryClient.refetchQueries({
        queryKey: ['ticketsAvailable'],
      });
      setIsLoading(false);
    }
  };

  return (
    <RDrawer
      heading={
        <Heading size="md" fontWeight="normal">
          Buy {quantity} Ticket{quantity > 1 && 's'}
        </Heading>
      }
      drawerBody={
        <Flex
          flexDirection="column"
          width="full"
          minHeight="60vh"
          paddingY="1rem"
        >
          <RCrossmintPayment
            collectionId={ticketsContractId}
            projectId={projectId}
            environment={paymentEnv}
            bundleId="1"
            totalPrice="1" // todo: temporarily hardcoded: need to detect the price from the backend
            type="erc-1155"
            SuccessIcon={IconTicket}
            isLoading={isLoading}
            quantity={quantity}
            onEvent={onEvent}
            handleContinue={handleFinish}
          />
        </Flex>
      }
      {...rest}
    />
  );
};

export default TicketsBundleDrawer;
