import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMaticCircle = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="M21.9 12c0-5.468-4.432-9.9-9.9-9.9S2.1 6.532 2.1 12s4.432 9.9 9.9 9.9 9.9-4.432 9.9-9.9Zm1.5 0C23.4 5.704 18.296.6 12 .6S.6 5.704.6 12 5.704 23.4 12 23.4 23.4 18.296 23.4 12Zm-10.765 3.031a.893.893 0 0 1-.413.717L9.449 17.37a.831.831 0 0 1-.822.002l-2.783-1.606a.822.822 0 0 1-.409-.714l.02-3.212a.893.893 0 0 1 .413-.717L8.64 9.502a.831.831 0 0 1 .822-.003l.967.558-.01 1.506-.938-.541a.822.822 0 0 0-.822.003l-1.473.86a.893.893 0 0 0-.414.717l.01 1.676a.831.831 0 0 0 .407.714l1.465.845a.822.822 0 0 0 .822-.003l1.474-.86a.893.893 0 0 0 .413-.717l.01-2.182.01-1.507.011-2.183a.893.893 0 0 1 .414-.716l2.773-1.623a.831.831 0 0 1 .822-.003l2.753 1.59c.235.136.4.39.38.697l-.007 3.26a.893.893 0 0 1-.414.715l-2.755 1.593a.831.831 0 0 1-.822.003l-.997-.575.01-1.506.967.558a.822.822 0 0 0 .822-.003l1.503-.844a.893.893 0 0 0 .414-.716l.008-1.706a.831.831 0 0 0-.408-.714l-1.465-.845a.822.822 0 0 0-.822.003l-1.502.843a.893.893 0 0 0-.414.717l.019 2.2-.04 1.489.002 2.23Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="a"
        x1={23.4}
        x2={0.6}
        y1={0.6}
        y2={23.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7D3EE2" />
        <stop offset={1} stopColor="#A527C2" />
      </linearGradient>
    </defs>
  </svg>
));
export default SvgIconMaticCircle;
