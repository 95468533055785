import { createContext, useContext, useState, ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';

import { RAnimatedTransition } from 'components';
import { SVGIcon } from 'types';

export interface AnimatedTransitionProps {
  bgColor?: string;
  Icon?: SVGIcon;
  title?: string;
  color?: string;
  gradientColor?: {
    from: string;
    to: string;
  };
  subtitle?: string;
  isVisible?: boolean;
  id?: string;
}

interface AnimatedTransitionContextType {
  showAnimatedTransition: (
    props: Omit<AnimatedTransitionProps, 'isVisible'>
  ) => void;
  hideAnimatedTransition: () => void;
}

const AnimatedTransitionContext = createContext<
  AnimatedTransitionContextType | undefined
>(undefined);

export const useAnimatedTransition = () => {
  const context = useContext(AnimatedTransitionContext);
  if (context === undefined) {
    throw new Error(
      'useAnimatedTransition must be used within a AnimatedTransitionProvider'
    );
  }
  return context;
};

export const AnimatedTransitionProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [transitionProps, setTransitionProps] =
    useState<AnimatedTransitionProps | null>(null);

  const showAnimatedTransition = (
    props: Omit<AnimatedTransitionProps, 'isVisible'>
  ) => {
    setTransitionProps({ ...props, isVisible: true });

    setTimeout(() => {
      setTransitionProps((currentProps) =>
        currentProps ? { ...currentProps, isVisible: false } : null
      );
    }, 2000);
  };

  const hideAnimatedTransition = () => setTransitionProps(null);

  return (
    <AnimatedTransitionContext.Provider
      value={{ showAnimatedTransition, hideAnimatedTransition }}
    >
      <AnimatePresence>
        {transitionProps?.isVisible && (
          <RAnimatedTransition {...transitionProps} />
        )}
      </AnimatePresence>
      {children}
    </AnimatedTransitionContext.Provider>
  );
};
