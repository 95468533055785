import { Flex, FlexProps, Heading, HeadingProps } from '@chakra-ui/react';
import { Races } from 'api/generated/graphql';
import { RDateWithLabel, RRaceStatus } from 'components';


const BaseCardTopLeft = ({
  heading,
  headingProps,
  children,
  race,
  variant,
  raceStatus,
  ...rest
}: FlexProps & {
  heading?: string;
  headingProps?: HeadingProps;
  race?: Races;
  variant?: string;
  raceStatus?: any;
}) => {
  return (
    <Flex
      className="BaseCardTopLeft"
      position="absolute"
      top="5"
      left="5"
      maxWidth="calc(100% - 8rem)"
      maxHeight="60%"
      flexDirection="column"
      {...rest}
    >
      {race && (
        <Flex flexDir={["column", "row"]} gap={[1, 2]} mb={1}>
          <RRaceStatus
            size={variant === 'garage' ? 'sm' : 'md'}
            status={raceStatus}
          />
          <RDateWithLabel
            fontSize={{ base: 'sm', md: variant === 'garage' ? 'sm' : 'md' }}
            time={(race?.startTime as string) || (race?.executedAt as string)}
            status={raceStatus}
          />
        </Flex>
      )}
      {heading && (
        <Heading as="h1" size="lg" noOfLines={1} mb={[0, 1.5]} {...headingProps}>
          {heading}
        </Heading>
      )}

      {children}
    </Flex>
  );
};

export default BaseCardTopLeft;
