import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconAdd = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M13.75 9.063a.95.95 0 0 1 .938.937c0 .547-.43.938-.938.938h-2.813v2.812c0 .547-.43.938-.937.938-.547 0-.938-.391-.938-.938v-2.813H6.25c-.547 0-.938-.39-.938-.937 0-.508.391-.938.938-.938h2.813V6.25c0-.508.468-.938.937-.938a.95.95 0 0 1 .938.938v2.813h2.812ZM10 0c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10A9.97 9.97 0 0 1 0 10C0 4.492 4.453 0 10 0Zm0 18.125c4.453 0 8.125-3.633 8.125-8.125 0-4.453-3.672-8.125-8.125-8.125-4.492 0-8.125 3.672-8.125 8.125A8.119 8.119 0 0 0 10 18.125Z"
    />
  </svg>
));
export default SvgIconAdd;
