import { Heading, Flex, IconButton } from '@chakra-ui/react';

import { SVGIcon } from 'types';
import { defaultTransition } from 'utils/themeConstants';

type HeadingProps = {
  children: React.ReactNode;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
};

const RAuthHeading = ({ children, leftButton, rightButton }: HeadingProps) => {
  return (
    <Flex
      w="full"
      mb="2"
      bg="linear-gradient(90deg, rgba(128, 128, 128, 0.8) 0%, rgba(163, 163, 163, 0) 100%)"
    >
      {leftButton}

      <Heading
        as="h2"
        fontWeight="400"
        backdropFilter="blur(10px)"
        color="white.80"
        fontSize="md"
        height="3rem"
        px="1rem"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        w="full"
      >
        {children}
      </Heading>

      {rightButton}
    </Flex>
  );
};

export default RAuthHeading;

type IconProps = {
  Icon: SVGIcon;
  alt: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const RAuthHeadingButton = ({ Icon, alt, onClick }: IconProps) => {
  return (
    <IconButton
      aria-label={alt}
      background="white.10"
      onClick={onClick}
      height="3rem"
      width="3.5rem"
      variant="unstyled"
      tabIndex={0}
      _focus={{
        borderColor: 'fieryCoral.40',
      }}
      icon={
        <Icon
          transition={defaultTransition}
          width="100%"
          height="100%"
          padding="1rem"
          opacity="0.6"
          _hover={{
            opacity: 1,
          }}
        />
      }
    />
  );
};

export { RAuthHeadingButton };
