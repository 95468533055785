import { useState, useRef, useEffect } from 'react';
import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

import { Cars, Rarity } from 'api/generated/graphql';
import { RCarCard, RTiers } from 'components';
import {
  IconCar,
  IconCheckGreen,
  IconChevron,
  IconFlag,
  IconLock,
} from 'icons';
import { AppliedLivery, CarTier, Livery } from 'types';
import {
  activeCarSelectBgGradient,
  carSelectBgGradient,
} from 'utils/themeConstants';
import { LeaderboardAvailable } from 'components/RacePreviewDrawer/DrawerPages/SelectCar';
import RarityTag from 'components/MarketplaceV2/RarityTag/RarityTag';

interface CarSelectProps {
  cars: Cars[];
  withLeaderboardAvailable?: LeaderboardAvailable[];
  selectedCarIndex?: number;
  setSelectedCarIndex?: (index: number) => void;
  lockedCarIds?: string[];
}

const cssScrollBarStyle = css({
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    width: '10px',
    backgroundColor: 'transparent',
  },

  '&::-webkit-scrollbar-track': {
    background:
      'linear-gradient(to right, rgba(255, 255, 255, 0.16) 0px, rgba(255, 255, 255, 0.16) 2px, transparent 2px, transparent 18px)',
    borderRadius: '1px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '1px',
    width: '2px',
    margin: '0',
    boxSizing: 'content-box',
    backgroundClip: 'content-box',
    borderRight: '8px solid transparent',
  },

  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(255, 255, 255, 0.8) rgba(255, 255, 255, 0.16)',
});

export const CarSelect = ({
  cars,
  withLeaderboardAvailable,
  selectedCarIndex = 0,
  setSelectedCarIndex,
  lockedCarIds = [],
}: CarSelectProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hasMultipleCars = cars.length > 1;

  const selectedCar = cars[selectedCarIndex];

  const variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { ease: 'easeInOut', duration: 0.3 },
      display: 'block',
    },
    closed: {
      opacity: 0,
      y: -10,
      transition: { ease: 'easeInOut', duration: 0.3, display: { delay: 0.3 } },
      display: 'none',
    },
  };

  const handleSelectCar = (index: number) => {
    setSelectedCarIndex && setSelectedCarIndex(index);
    setIsOpened(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpened(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Flex ref={containerRef} pos="relative" w="full">
      <CarSelectCard
        car={selectedCar}
        showArrow={hasMultipleCars}
        borderWidth="1.5px"
        borderColor="white.20"
        borderRadius="0.25rem"
        cursor="pointer"
        onClick={hasMultipleCars ? () => setIsOpened((prev) => !prev) : () => null}
      />

      <Flex
        as={motion.div}
        initial="closed"
        animate={isOpened ? 'open' : 'closed'}
        variants={variants}
        pos="absolute"
        overflowY="auto"
        flexDir="column"
        top="5.5rem"
        left="0"
        w="full"
        maxH="30rem"
        bgColor="darkVoid.100"
        p="0.5rem"
        zIndex={100}
        css={cssScrollBarStyle}
      >
        {cars.map((car, index) => {
          const isLocked = lockedCarIds.includes(car.id);

          return (
            <CarSelectCard
              bgColor="neutralDark.80"
              key={car.id}
              car={car}
              isSelected={selectedCarIndex === index}
              isBlocked={isLocked}
              onClick={() => {
                if (isLocked) return;
                handleSelectCar(index);
              }}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

const CarSelectCard = ({
  car,
  showArrow = false,
  isSelected = false,
  isBlocked = false,
  ...props
}: {
  car: Cars;
  showArrow?: boolean;
  isSelected?: boolean;
  isBlocked?: boolean;
} & FlexProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const usagePercentage =
    ((car?.timesUsed || 0) / (car?.maximumTimesUsed || 100)) * 100;
  const repairPercentage =
    ((car?.usageSinceLastRepair || 0) / (car?.nextRepairAt || 100)) * 100;

  const borderColor = isSelected
    ? 'treasuredTeal.100'
    : isHovered
    ? 'cerebralGrey.100'
    : 'carbon.100';

  return (
    <Flex
      w="full"
      h="5.375rem"
      borderWidth="0.094px"
      borderColor={borderColor}
      borderRadius="0.25rem"
      mb="0.25rem"
      opacity={isBlocked ? 0.4 : 1}
      cursor={isBlocked ? 'not-allowed' : 'pointer'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <RCarCard
        carId={car?.id}
        hasInsetBorder={false}
        locked={car?.locked}
        livery={(car?.livery || '') as Livery}
        appliedLivery={(car?.appliedLivery || '') as AppliedLivery}
        backgroundPreload
        mb="6"
        imageProps={{
          paddingTop: '1rem',
          transform: 'scale(0.95)',
        }}
        w="7.5rem"
        h="full"
        cursor="pointer"
      />
      <Flex
        h="full"
        w="calc(100% - 120px)"
        py="0.75rem"
        px="1rem"
        justifyContent="space-between"
        bg={isSelected ? activeCarSelectBgGradient : carSelectBgGradient}
      >
        <Flex h="full" flexDir="column" justifyContent="space-between">
          <Flex alignItems="center" gap="0.5rem">
            <RarityTag tier={car?.rarity as Rarity} showText={false} />
            <Text
              fontFamily="heading"
              fontSize="1.125rem"
              lineHeight="1.5rem"
              color="white.60"
              fontWeight={400}
            >
              {car?.name}
            </Text>
          </Flex>
          <Flex gap="0.25rem">
            <Flex
              pos="relative"
              py="0.25rem"
              px="0.475rem"
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
              bg="black.40"
              borderRadius="0.25rem"
            >
              <Text fontSize="0.75rem" zIndex={1}>
                {car?.usageSinceLastRepair}/{car?.nextRepairAt}
              </Text>
              <IconCar h="0.75rem" w="0.75rem" zIndex={1} />
              <Box
                pos="absolute"
                h="full"
                bg="treasuredTeal.40"
                borderRadius="0.25rem"
                w={`${repairPercentage}%`}
                left={0}
                top={0}
                zIndex={0}
              />
            </Flex>
            <Flex
              pos="relative"
              py="0.25rem"
              px="0.475rem"
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
              bg="black.40"
              borderRadius="0.25rem"
            >
              <Text fontSize="0.75rem" zIndex={1}>
                {car?.timesUsed}/{car?.maximumTimesUsed}
              </Text>
              <IconFlag
                __css={{
                  path: { fill: 'white.80' },
                }}
                h="0.75rem"
                w="0.75rem"
                zIndex={1}
              />
              <Box
                pos="absolute"
                h="full"
                bg="bloodMoon.40"
                borderRadius="0.25rem"
                w={`${usagePercentage}%`}
                left={0}
                top={0}
                zIndex={0}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex h="full" alignItems="center">
          {showArrow ? (
            <IconChevron
              transform="rotate(90deg)"
              __css={{
                path: { fill: 'white.60' },
              }}
            />
          ) : isSelected ? (
            <IconCheckGreen w="1rem" />
          ) : (
            isBlocked && (
              <IconLock
                w="1rem"
                __css={{
                  path: { fill: 'white.100' },
                }}
              />
            )
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
