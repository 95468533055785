import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHeart = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={11}
    fill="none"
    viewBox="0 0 12 11"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M10.023 1.98a2.807 2.807 0 0 1 .196 4.063l-3.79 3.906a.619.619 0 0 1-.449.176.606.606 0 0 1-.43-.176L1.763 6.043a2.807 2.807 0 0 1 .195-4.063c1.25-1.054 2.852-.566 3.652.254l.391.41.371-.41c.996-1.015 2.578-1.152 3.652-.254ZM9.77 5.613c.742-.781.898-2.246-.157-3.144-1.074-.918-2.343-.254-2.793.215L6 3.543l-.84-.86c-.469-.468-1.719-1.132-2.793-.214-1.054.898-.898 2.363-.156 3.144L6 9.52l3.77-3.907Z"
    />
  </svg>
));
export default SvgIconHeart;
