import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMinus = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M14.5 8a.74.74 0 0 1-.75.75H2.25c-.438 0-.75-.313-.75-.719 0-.437.313-.781.75-.781h11.5a.76.76 0 0 1 .75.75Z"
    />
  </svg>
));
export default SvgIconMinus;
