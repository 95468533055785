import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconCopyPaste = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M20.61 2.773c.234.235.39.547.39.899V12.5c0 1.406-1.133 2.5-2.5 2.5H11c-1.406 0-2.5-1.094-2.54-2.5v-10c0-1.367 1.095-2.5 2.5-2.5h6.368c.352 0 .664.156.899.39l2.382 2.383ZM19.124 12.5h-.04V5H17.25C16.547 5 16 4.453 16 3.75l-.04-1.836h-5a.617.617 0 0 0-.624.625V12.5c0 .352.273.625.625.625H18.5a.642.642 0 0 0 .625-.625Zm-7.5 5v-1.25H13.5v1.25c0 1.406-1.133 2.5-2.5 2.5H3.5A2.468 2.468 0 0 1 1 17.5l-.04-10C.96 6.133 2.095 5 3.46 5h3.79v1.914H3.46a.642.642 0 0 0-.624.625V17.5c0 .352.273.625.625.625H11a.642.642 0 0 0 .625-.625Z"
    />
  </svg>
));
export default SvgIconCopyPaste;
