import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconChequeredTrial = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#D83832"
      d="M22.313 0C23.203 0 24 .61 24 1.453V15.61c0 .563-.375 1.079-1.031 1.313-2.016.797-3.703 1.078-5.203 1.078-3.47 0-5.86-1.453-9.328-1.453-1.688 0-3.704.375-6.188 1.453v4.875A1.11 1.11 0 0 1 1.125 24C.469 24 0 23.531 0 22.875V1.125C0 .515.469 0 1.125 0c.61 0 1.125.516 1.125 1.125v.422C5.016.422 7.031.047 8.672.047c3.234 0 4.875 1.5 7.687 1.5 1.313 0 2.907-.328 5.016-1.313.328-.14.61-.234.938-.234ZM5.625 14.578v-2.437a16.01 16.01 0 0 0-2.531.843l-.844.329v2.25a15.308 15.308 0 0 1 3.375-.985Zm0-4.781V7.64a16.01 16.01 0 0 0-2.531.843l-.844.329v2.109a17.745 17.745 0 0 1 3.375-1.125Zm0-4.5V2.766c-.797.234-1.64.468-2.531.843l-.844.375v2.438a17.748 17.748 0 0 1 3.375-1.125Zm5.625 9.234v-2.484c-.844-.235-1.594-.422-2.578-.422-.281 0-.563.047-.844.094v2.578h.61a17.5 17.5 0 0 1 2.812.234Zm0-4.781V7.547c-.844-.234-1.594-.422-2.578-.422-.281 0-.563.047-.797.094v2.203c.234 0 .516-.047.797-.047.984 0 1.781.188 2.578.375Zm0-4.5V2.719a7.627 7.627 0 0 0-2.578-.422c-.281 0-.563 0-.797.047v2.578c.234 0 .516-.047.797-.047.984 0 1.781.188 2.578.375Zm5.625 10.453v-2.578h-.516c-1.125 0-2.015-.188-2.859-.375v2.297c.047 0 .094.047.14.047 1.079.281 2.11.515 3.235.61Zm0-4.828v-2.25h-.516c-1.125 0-2.015-.188-2.859-.375v2.156a8.909 8.909 0 0 0 2.86.469h.515Zm0-4.5V3.75c-.188 0-.375.047-.516.047-1.125 0-2.015-.188-2.859-.422v2.531a8.91 8.91 0 0 0 2.86.469h.515ZM21.75 15v-3.094c-.938.375-1.828.656-2.625.89v2.86c.797-.094 1.688-.328 2.625-.656Zm0-5.531V7.406c-.938.375-1.828.657-2.625.89v2.157c.656-.234 1.406-.469 2.25-.89.14-.047.234-.047.375-.094Zm0-4.5V2.53c-.938.375-1.828.703-2.625.89v2.532c.656-.234 1.406-.469 2.25-.89.14-.047.234-.047.375-.094Z"
    />
  </svg>
));
export default SvgIconChequeredTrial;
