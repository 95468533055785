import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconFacebook = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={16}
    fill="none"
    viewBox="0 0 10 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="m8.469 9 .437-2.875H6.125V4.25c0-.813.375-1.563 1.625-1.563h1.281V.22S7.875 0 6.781 0C4.5 0 3 1.406 3 3.906v2.219H.437V9H3v7h3.125V9h2.344Z"
    />
  </svg>
));
export default SvgIconFacebook;
