import { Button, Flex, FlexProps, Box, Show } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

import { RCarousel, RPartCard } from 'components';
import { PartGroup, PartNames, SVGIcon, CarPart } from 'types';
import {
  IconBrakeCooling,
  IconEngineCooling,
  IconSpoilerFront,
  IconSpoilerRear,
  IconTransmissionRatio,
} from 'icons';

interface PartCarouselProps {
  partGroups: PartGroup[];
  onClickPart?: (part: CarPart) => void;
  setSelectedPart: Dispatch<SetStateAction<CarPart | null | undefined>>;
  activeGroupName: PartNames;
  setActiveGroupName: Dispatch<SetStateAction<PartNames>>;
}

const RPartsCarousel = ({
  partGroups,
  onClickPart,
  activeGroupName,
  setSelectedPart,
  setActiveGroupName,
  ...rest
}: FlexProps & PartCarouselProps) => {
  const PartGroupButton = ({
    name,
    activeGroupName,
    setActiveGroupName,
    leftIcon,
  }: {
    name: PartNames;
    activeGroupName: PartNames;
    setActiveGroupName: Dispatch<SetStateAction<PartNames>>;
    leftIcon: SVGIcon;
  }) => {
    const isAboveMd = window.innerWidth > 1024;
    return (
      <Button
        key={name}
        iconSpacing={isAboveMd ? '2' : '0'}
        leftIcon={leftIcon}
        variant={
          activeGroupName === name ? 'primary-ghost' : 'secondary-outlined'
        }
        name={name}
        onClick={() => {
          setActiveGroupName(name);
        }}
        width="100%"
        fontSize={{ base: 'sm', xl: 'md' }}
        id={`${name}-button`}
      >
        <Show above="lg">{name === 'Transmission Ratios' ? 'Transmission' : name}</Show>
      </Button>
    );
  };

  const activePartGroup = partGroups.find(
    (group) => group.partGroupName === activeGroupName
  );

  return (
    <Flex
      width="100%"
      height="fit-content"
      background="black.40"
      backdropFilter="blur(10px)"
      padding="4"
      pb="6"
      flexDirection="column"
      gap="4"
      {...rest}
    >
      <Flex justifyContent="space-between" width="100%" gap="2">
        <PartGroupButton
          name="Front Wing"
          key="frontWing"
          activeGroupName={activeGroupName}
          setActiveGroupName={setActiveGroupName}
          leftIcon={<IconSpoilerFront />}
        />

        <PartGroupButton
          name="Rear Wing"
          key="rearWing"
          activeGroupName={activeGroupName}
          setActiveGroupName={setActiveGroupName}
          leftIcon={<IconSpoilerRear />}
        />

        <PartGroupButton
          name="Engine Cooling"
          key="engine"
          activeGroupName={activeGroupName}
          setActiveGroupName={setActiveGroupName}
          leftIcon={<IconEngineCooling />}
        />

        <PartGroupButton
          name="Brake Cooling"
          key="brake"
          activeGroupName={activeGroupName}
          setActiveGroupName={setActiveGroupName}
          leftIcon={<IconBrakeCooling />}
        />

        <PartGroupButton
          name="Transmission Ratios"
          key="transmission"
          activeGroupName={activeGroupName}
          setActiveGroupName={setActiveGroupName}
          leftIcon={<IconTransmissionRatio />}
        />
      </Flex>

      <RCarousel
        carouselItems={
          activePartGroup?.partsInGroup
            .sort((a, b) => {
              if (a.locked !== b.locked) {
                return a.locked ? 1 : -1; // Peças não bloqueadas primeiro
              }
              if (a?.rankRequired && b?.rankRequired) {
                return a.rankRequired - b.rankRequired;
              }

              return 0;
            })
            .map((part) => {
              return (
                <Box key={part?.id} w="14rem" px="2">
                  <RPartCard
                    id={`part-${part.id}`}
                    carPart={part}
                    showPartInfo
                    onClick={() => {
                      if (!part.locked) {
                        onClickPart && onClickPart(part);
                        setSelectedPart(part)
                      }
                    }}
                  />
                </Box>
              );
            }) || []
        }
      />
    </Flex>
  );
};

export default RPartsCarousel;
