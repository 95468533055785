import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSpoilerRear = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      d="m6.774 3-3.899.243A2 2 0 0 0 1 5.24V9.3a2 2 0 0 0 2.791 1.836l1.774-.764a2 2 0 0 0 1.209-1.837v-.92m0-4.615L19 3.36M6.774 3l-.636.858c-.468.63-.13 1.533.636 1.702M19 3.36l-3.144.688a2 2 0 0 0-1.569 1.83l-.043.692M19 3.36l-1.18 9.183c-.05.384-.212.746-.465 1.039-1.235 1.427-3.577.47-3.46-1.413l.183-2.931M6.774 7.615c-1.086-.257-1.088-1.806 0-2.055m0 2.055 7.304 1.623M6.774 5.56l7.47 1.01m-.166 2.668.166-2.668"
    />
  </svg>
));
export default SvgIconSpoilerRear;
