/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Slider as ChakraSlider,
  SliderThumb,
  SliderTrack,
  SliderProps,
  Flex,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { IconThumbsUp } from 'icons';
import { getTuningSliderGradient, sliderGradient } from 'utils/themeConstants';

const RSlider = ({
  suggestedMin = 0,
  suggestedMax = 0,
  min = 0,
  max = 100,
  onChangeEnd,
  onChange,
  defaultValue,
  ...rest
}: SliderProps & {
  suggestedMin?: number;
  suggestedMax?: number;
}) => {
  const [lastValue, setLastValue] = useState<number>(defaultValue || 0);
  const suggestionWidth = ((suggestedMax - suggestedMin) / (max - min)) * 100;
  const suggestionLeft = ((suggestedMin - min) / (max - min)) * 100;

  const isInsideSuggestion =
    lastValue >= suggestedMin && lastValue <= suggestedMax;
  const lastValueInPercentage = ((lastValue - min) / (max - min)) * 100;

  const handleSliderChange = (value: number) => {
    setLastValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    handleSliderChange(defaultValue || 0);
  }, [defaultValue]);

  return (
    <ChakraSlider
      pos="relative"
      h="8rem"
      w="1.5rem"
      orientation="vertical"
      aria-label="tuning-slider"
      min={min}
      max={max}
      defaultValue={defaultValue}
      onChangeEnd={onChangeEnd}
      onChange={handleSliderChange}
      {...rest}
    >
      <SliderTrack
        pos="relative"
        w="1.5rem"
        h="8rem"
        borderRadius="0.25rem"
        borderWidth="0.094rem"
        borderColor="white.10"
        bg={sliderGradient}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          w="1.5rem"
          bg={isInsideSuggestion ? 'secondaryGreen.100' : 'white.10'}
          position="absolute"
          left="0"
          style={{
            height: `${suggestionWidth}%`,
            bottom: `${suggestionLeft}%`,
          }}
          zIndex={2}
        >
          <IconThumbsUp
            w="0.625rem"
            h="0.625rem"
            __css={{
              path: {
                fill: isInsideSuggestion ? 'treasuredTeal.100' : 'white.60',
              },
            }}
          />
        </Flex>
        <Box
          pos="absolute"
          left="0"
          bottom={lastValueInPercentage > 50 ? '50%' : 'auto'}
          top={lastValueInPercentage < 50 ? '50%' : 'auto'}
          width="full"
          height={`${Math.abs(lastValueInPercentage - 50)}%`}
          bg={getTuningSliderGradient({
            value: lastValueInPercentage,
            isInsideSuggestion,
          })}
          zIndex={1}
        />

        {[...Array(7)].map((_, i) => (
          <Box
            key={i}
            position="absolute"
            left="-0.25rem"
            w="0.25rem"
            h="0.063rem"
            bg="white.20"
            bottom={`${i * 16.67}%`}
            zIndex={1}
          />
        ))}
        <Box
          position="absolute"
          left="0"
          w="full"
          h="0.125rem"
          bg="secondaryGrey.100"
          bottom="50%"
          zIndex={1}
        />
        {[...Array(7)].map((_, i) => (
          <Box
            key={i}
            position="absolute"
            right="-0.25rem"
            w="0.25rem"
            h="0.063rem"
            bg="white.20"
            bottom={`${i * 16.67}%`}
            zIndex={1}
          />
        ))}
      </SliderTrack>
      {!rest.isReadOnly && (
        <SliderThumb
          display="flex"
          pos="relative"
          alignItems="center"
          justifyContent="center"
          w="4.5rem"
          h="0.25rem"
          borderRadius="0.25rem"
          bg={isInsideSuggestion ? 'treasuredTeal.100' : 'secondaryYellow.100'}
          zIndex={3}
        >
          <Box
            pos="absolute"
            bottom="0"
            left="-1.75rem"
            h="0.75rem"
            w="0.5625rem"
            borderRadius="0.0625rem"
            bg={
              isInsideSuggestion ? 'treasuredTeal.100' : 'secondaryYellow.100'
            }
            clipPath="polygon(0% 100%, 100% 50%, 0% 0%)"
            position="relative"
          />

          <Box
            pos="absolute"
            bottom="0"
            right="-1.75rem"
            h="0.75rem"
            w="0.5625rem"
            borderRadius="0.0625rem"
            bg={
              isInsideSuggestion ? 'treasuredTeal.100' : 'secondaryYellow.100'
            }
            clipPath="polygon(0% 100%, 100% 50%, 0% 0%)"
            transform="rotate(180deg)"
            position="relative"
          />
        </SliderThumb>
      )}
    </ChakraSlider>
  );
};

export default RSlider;
