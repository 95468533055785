import { UserActivityFilter } from './../api/generated/graphql';
import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useUserActivity = ({ limit, maxDate, minDate }: UserActivityFilter) => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();
  const getActivitiesFn = graphql(`
    query GetActivities($Filter: UserActivityFilter!) {
      getUserActivity(filter: $Filter) {
        id
        timestamp
        action
        value
        message
        currency
      }
    }
  `);

  return useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['userActivity'],
    queryFn: async () => {
      return graphQLClient.request(getActivitiesFn, {
        Filter: { limit, maxDate, minDate },
      });
    },
  });
};

export default useUserActivity;
