import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuningHandling = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.6}
      d="M6 .375c3.094 0 5.625 2.531 5.625 5.625A5.626 5.626 0 0 1 6 11.625 5.611 5.611 0 0 1 .375 6 5.626 5.626 0 0 1 6 .375Zm0 1.5A4.13 4.13 0 0 0 1.945 5.25h1.992c.258-.445.75-.75 1.313-.75h1.477c.562 0 1.03.305 1.312.75h1.992C9.68 3.352 8.016 1.875 6 1.875ZM1.945 6.75a4.09 4.09 0 0 0 3.305 3.305V8.25l-1.148-1.5H1.945Zm4.782 3.305A4.09 4.09 0 0 0 10.03 6.75h-2.18l-1.124 1.5v1.805Z"
    />
  </svg>
));
export default SvgIconTuningHandling;
