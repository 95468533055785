import { Box, Flex, Button } from '@chakra-ui/react';
import React, { useRef, ReactNode } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { IconChevron } from 'icons';

const RCarousel = ({ carouselItems = [] }: { carouselItems: ReactNode[] }) => {
  const carouselRef = useRef<AliceCarousel>(null);

  return (
    <Flex justifyContent="space-between" width="100%">
      <Button
        display="flex"
        onClick={(e) => carouselRef?.current?.slidePrev(e)}
        w="10"
        h="105%"
        variant="secondary-outlined"
        borderColor="white.40"
        opacity="0.6"
        id="prev-button"
      >
        <Box>
          <IconChevron transform="rotate(180deg)" />
        </Box>
      </Button>

      <Box
        width="calc(100% - 6rem)"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <AliceCarousel
          autoWidth
          items={carouselItems}
          ref={carouselRef}
          animationType="fadeout"
          disableDotsControls
          disableButtonsControls
        />

        <Box
          borderBottom="solid 2px"
          borderColor="white.10"
          transform="translateY(7px)"
          width="98.75%"
          marginX="auto"
        />
      </Box>

      <Button
        onClick={(e) => carouselRef?.current?.slideNext(e)}
        w="10"
        h="105%"
        variant="secondary-outlined"
        borderColor="white.40"
        opacity="0.6"
        id="next-button"
      >
        <Box>
          <IconChevron />
        </Box>
      </Button>
    </Flex>
  );
};

export default RCarousel;
