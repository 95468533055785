import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDollarCircle = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.6}
      d="M8.188 7.281c1.062.282 2.843.75 2.5 2.563-.157.875-.907 1.469-1.938 1.625V12a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75v-.563c-.469-.062-.906-.218-1.313-.343l-.187-.063a.734.734 0 0 1-.469-.937.739.739 0 0 1 .938-.5l.187.094c.375.124.813.25 1.157.312.875.125 1.593-.063 1.656-.438.062-.312-.063-.468-1.438-.812l-.25-.094c-.781-.187-2.593-.687-2.25-2.469.157-.875.907-1.468 1.969-1.625V4A.74.74 0 0 1 8 3.25a.76.76 0 0 1 .75.75v.594c.281.031.563.125.969.218a.795.795 0 0 1 .469.97.746.746 0 0 1-.938.468c-.344-.094-.625-.188-.875-.219-.844-.125-1.563.063-1.625.438-.031.218-.094.406 1.156.75l.282.062ZM8 0c4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8Zm0 14.5c3.563 0 6.5-2.906 6.5-6.5 0-3.563-2.938-6.5-6.5-6.5-3.594 0-6.5 2.938-6.5 6.5 0 3.594 2.906 6.5 6.5 6.5Z"
    />
  </svg>
));
export default SvgIconDollarCircle;
