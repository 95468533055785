import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconArrowRepeat = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    viewBox="0 0 18 19"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M17.156 8.406c.457 0 .844.387.844.844 0 2.953-2.426 5.344-5.344 5.344H5.098l2.215 2.25a.77.77 0 0 1 0 1.16c-.141.176-.352.246-.563.246a.847.847 0 0 1-.633-.246l-3.656-3.656a.828.828 0 0 1 0-1.16L6.117 9.53c.352-.351.88-.351 1.196 0a.77.77 0 0 1 0 1.16l-2.215 2.215h7.558a3.653 3.653 0 0 0 3.656-3.656c0-.457.352-.844.844-.844ZM5.344 5.594A3.653 3.653 0 0 0 1.687 9.25a.833.833 0 0 1-.843.844C.352 10.094 0 9.742 0 9.25c0-2.918 2.39-5.344 5.344-5.344h7.523l-2.215-2.215a.77.77 0 0 1 0-1.16.77.77 0 0 1 1.16 0l3.657 3.656a.77.77 0 0 1 0 1.16l-3.656 3.657c-.141.176-.352.246-.563.246-.246 0-.457-.07-.598-.246a.77.77 0 0 1 0-1.16l2.215-2.25H5.344Z"
    />
  </svg>
));
export default SvgIconArrowRepeat;
