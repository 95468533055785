import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHubChallenges = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M15.773 7.852c.586.078.782.78.391 1.21l-2.344 2.266.547 3.164a.686.686 0 0 1-.664.82c-.117 0-.234 0-.351-.078L10.5 13.75l-2.93 1.484c-.117.079-.234.079-.312.079a.686.686 0 0 1-.664-.82l.547-3.165-2.344-2.265c-.39-.43-.195-1.133.39-1.211l3.243-.47 1.367-2.89c.156-.273.39-.43.664-.43.273 0 .508.157.664.391l1.406 2.93 3.242.469ZM12.141 12.5l-.274-1.523c-.039-.196.04-.391.195-.547l1.094-1.055-1.523-.234c-.195-.04-.39-.157-.469-.352L10.5 7.422l-.703 1.367c-.078.195-.274.313-.469.352l-1.523.234 1.093 1.055c.157.156.235.351.196.547L8.82 12.5l1.367-.742a.762.762 0 0 1 .586 0l1.368.742ZM10.5 0c5.508 0 10 4.492 10 10 0 5.547-4.492 10-10 10a9.97 9.97 0 0 1-10-10c0-5.508 4.453-10 10-10Zm0 18.125c4.453 0 8.125-3.633 8.125-8.125 0-4.453-3.672-8.125-8.125-8.125-4.492 0-8.125 3.672-8.125 8.125a8.119 8.119 0 0 0 8.125 8.125Z"
    />
  </svg>
));
export default SvgIconHubChallenges;
