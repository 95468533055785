import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconBeard = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={11}
    fill="none"
    viewBox="0 0 18 11"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      d="M2.996 3.19C2 3.57.918 2.914.502 2.54c-.046 1.992.6 6.215 3.556 7.162C7.014 10.65 8.584 9.11 9 8.222c.416.888 1.986 2.428 4.942 1.48 2.955-.947 3.602-5.17 3.556-7.163-.416.375-1.497 1.03-2.494.651-.998-.378-1.247-.71-1.617-1.124C12.833 1.296 12.14 1 11.494 1 10.312 1 9.339 2.026 9 2.54 8.661 2.025 7.688 1 6.506 1c-.646 0-1.34.296-1.893 1.066-.37.414-.62.746-1.617 1.124Z"
    />
  </svg>
));
export default SvgIconBeard;
