import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MutationEnterRaceArgs } from 'api/generated/graphql';
import { useGraphQLClient } from 'hooks';

const useEnterRace = () => {
  const { graphql, graphQLClient } = useGraphQLClient();
  const queryClient = useQueryClient();

  const enterRaceGql = graphql(`
    mutation EnterRace($enterRaceInput: EnterRaceInput!) {
      enterRace(enterRaceInput: $enterRaceInput) {
        status
      }
    }
  `);

  const enterRace = useMutation({
    mutationKey: ['EnterRace'],
    mutationFn: ({ enterRaceInput }: MutationEnterRaceArgs) => {
      return graphQLClient.request(enterRaceGql, {
        enterRaceInput,
      });
    },
    onSuccess: (_, { enterRaceInput }) => {
      queryClient.invalidateQueries({
        queryKey: ['raceByIdQuery', enterRaceInput.raceId],
      });
      queryClient.invalidateQueries({ queryKey: ['nextUpRacesQuery'] });
      queryClient.invalidateQueries({
        queryKey: ['nextUpRacesByTierAndStatusQuery'],
      });
      queryClient.invalidateQueries({
        queryKey: ['pastRacesQuery'],
      });
    },
  });

  return {
    enterRace,
  };
};

export default useEnterRace;
