import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconDeposit = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#4FC5BA"
      d="M15.75 7.5c.188 0 .375.094.516.234.28.282.28.797 0 1.079L10.03 15h4.219c.375 0 .75.375.75.75-.047.422-.375.75-.797.75h-6c-.375 0-.703-.328-.703-.75v-6c0-.375.328-.75.75-.75.375 0 .75.375.75.75v4.219l6.188-6.235a.776.776 0 0 1 .562-.234ZM12 0c6.61 0 12 5.39 12 12 0 6.656-5.39 12-12 12-6.656 0-12-5.344-12-12C0 5.39 5.344 0 12 0Zm0 22.5c5.766 0 10.5-4.688 10.5-10.5 0-5.766-4.734-10.5-10.5-10.5C6.187 1.5 1.5 6.234 1.5 12c0 5.813 4.688 10.5 10.5 10.5Z"
    />
  </svg>
));
export default SvgIconDeposit;
