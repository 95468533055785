import { Heading, Text, Flex } from '@chakra-ui/react';

import { IconCheckeredFlag } from 'icons';
import { formatOrdinal } from 'utils/helpers';

const PlacingPosition = ({ position }: { position: number }) => {
  return (
    <Heading
      fontWeight="normal"
      as="h4"
      size="xs"
      textTransform="uppercase"
      color="white.80"
    >
      Placing
      <Flex mt="1" gap="2">
        <IconCheckeredFlag w="1.25rem" h="1.25rem" />
        <Text>{formatOrdinal(position)}</Text>
      </Flex>
    </Heading>
  );
};

export default PlacingPosition;
