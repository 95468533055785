import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMatic = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="M14.872 6.737c-.31-.184-.71-.184-1.065 0L11.32 8.255l-1.687.966-2.44 1.518c-.311.184-.711.184-1.066 0L4.219 9.543a1.13 1.13 0 0 1-.532-.966v-2.3c0-.367.177-.735.532-.965l1.909-1.15c.31-.184.71-.184 1.065 0l1.909 1.196c.31.184.532.552.532.965v1.518l1.687-1.012V5.266c0-.368-.177-.736-.532-.966L7.238 2.138c-.311-.184-.71-.184-1.066 0l-3.64 2.208c-.354.184-.532.552-.532.92v4.323c0 .368.178.736.533.966l3.595 2.162c.31.184.71.184 1.065 0l2.441-1.472 1.687-1.012 2.441-1.472c.311-.184.71-.184 1.066 0l1.908 1.15c.311.184.533.552.533.966v2.3c0 .367-.178.736-.533.965l-1.864 1.15c-.31.184-.71.184-1.065 0l-1.909-1.15a1.13 1.13 0 0 1-.532-.965v-1.472l-1.687 1.012v1.517c0 .368.177.736.532.966l3.596 2.162c.31.184.71.184 1.065 0l3.595-2.162a1.13 1.13 0 0 0 .533-.966V9.865c0-.368-.177-.736-.533-.966l-3.595-2.162Z"
    />
  </svg>
));
export default SvgIconMatic;
