import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMenuHamburger = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={20}
    fill="none"
    viewBox="0 0 22 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M20.375 16.375c.61 0 1.125.516 1.125 1.172 0 .61-.516 1.078-1.125 1.078H1.578C.968 18.625.5 18.156.5 17.5c0-.61.469-1.125 1.078-1.125h18.797Zm0-15c.61 0 1.125.61 1.125 1.172 0 .61-.516 1.078-1.125 1.078H1.578C.968 3.625.5 3.062.5 2.5c0-.61.469-1.125 1.078-1.125h18.797Zm0 7.5c.61 0 1.125.516 1.125 1.125a1.11 1.11 0 0 1-1.125 1.125H1.578C.968 11.125.5 10.656.5 10c0-.61.469-1.125 1.078-1.125h18.797Z"
    />
  </svg>
));
export default SvgIconMenuHamburger;
